
//import FormField from 'components/form/FormField';
import GridEx from 'components/form/GridEx';
import { getEntityDescriptor } from 'global/entityDescriptors';
import { FormControl } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { toItalianDateString, toDecimalString } from 'global/appGlobal';
import FormFieldHigh from '../../components/form/FormFieldHigh';

const styles = theme => ({
    label: {
        ...theme.typography.body2,
        marginRight: theme.spacing.unit,
        color: theme.palette.text.secondary,
        whiteSpace: 'nowrap',
        
    },
    value: {
        ...theme.typography.body2,
        color: theme.palette.text.primary,
        
    },
    formControl: {
        margin: theme.spacing.unit / 4,
        flexDirection: 'row',
        whiteSpace: 'normal',
        
    },
});

class ReadOnlyInLine extends FormFieldHigh {

    render() {
        const { classes } = this.props;
        let value = '';
        if (this.props.f && this.context.dataSource) {
            value = this.context.dataSource[this.props.f];
        }
        if (this.props.v) {
            value = this.props.v;
        }

        const objDescriptor = getEntityDescriptor(this.context.objType);
        const propDescriptor = objDescriptor[this.props.f];
        const propType = propDescriptor ? propDescriptor.type : this.props.type;

        if (this.context.fields && this.context.fields.all.length > 0) {
            if (!this.context.fields.byId[this.props.f]) {
                return '';
            }
        }

        let text;
        if (propType === 'BOOLEAN') {
            text = value === 'true' ? 'si' : 'no';
            if (this.props.stringValue) {
                if (value === '1' ||
                    value === 'si' ||
                    value === 'true' ||
                    value === true) {
                    text = 'si';
                } else {
                    text = 'no';
                }
            }


        } else {
            text = this.convertToControl(value, propType, true);
        }

        if (propType === 'DATEONLY' && value) {
            text = moment(value).format('DD/MM/YYYY');
        }
        if (propType === 'DECIMAL' && value) {
            text = toDecimalString(value);
        }

        const label = this.getLabel();
        if (this.props.pdf) {
            const latestGroup = window.requestDetailsPdfData[window.requestDetailsPdfData.length - 1];
            const latestFields = latestGroup.fields[latestGroup.fields.length - 1];
            latestFields.value = text || '';
        }

        return (

            <GridEx item sm={this.props.sm || 12} xs={this.props.xs || 12} >
                <FormControl className={classes.formControl}>
                    <span className={classes.label} >
                        {label}
                    </span>
                    <div className={classes.value}>
                        {text}
                    </div>
                </FormControl>
            </GridEx>

        );
    }
}

ReadOnlyInLine.contextTypes = {
    onChange: PropTypes.func,
    registerControl: PropTypes.func,
    objType: PropTypes.string,
    dataSource: PropTypes.object,
    unRegisterControl: PropTypes.func,
    fields: PropTypes.Object,
};

export default withStyles(styles, { withTheme: true })(ReadOnlyInLine);