import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';
import { Paper } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { AppBar } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { TextField } from '@material-ui/core';
import { Tabs, Tab } from '@material-ui/core';
import * as Icon from '@material-ui/icons';
import { Checkbox } from '@material-ui/core';
import { Button } from '@material-ui/core';
import moment from 'moment';
import { Badge } from '@material-ui/core';
import gql from 'graphql-tag';
import File_Search from 'modules/file/File_Search';
import { HashRouter, BrowserRouter, Route, Link, withRouter } from 'react-router-dom';
import $ from 'jquery';
import { getToken } from 'global/auth';
import { ReportTypes } from 'global/reporting';
import TabContainer from 'components/TabContainer';
import { Menu, MenuItem } from '@material-ui/core';
import { graphql, compose } from 'react-apollo';
import ComponentEx from 'components/ComponentEx';
import Contract_Search from 'modules/contract/Contract_Search';
import ReactSVG from 'react-svg';
import HeaderViewItem from 'components/HeaderViewItem';
import NavPills from 'components/NavPills/NavPills';
import ItemGrid from 'components/Grid/ItemGrid';

import CmdBar from '../../components/form/CmdBar';
import { addAuthListener, authInfo, initFromCookie, logout } from 'global/auth';
import { getTxt } from '../../global/appGlobal';
import Request_Search from '../../modules/request/Request_Search';
import LinearProgressEx from '../../components/LinearProgressEx';
import { getLabel, DictionaryTypes } from '../../global/dictionaryCache';
import Client_Privat_Details_View from '../../modules/client/Client_Privat_Details_View';
import { queryGetCustomerById, customerBriefQuery } from '../../modules/client/clientQueries';
import * as queries from '../../modules/broker/brokerQueries';
import Broker_Details_View from '../../modules/broker/Broker_Details_View';
import RegularCard from '../../components/dashboard/RegularCard';
import CmdBarCustomBtns from '../../components/CmdBarCustomBtns';
import BrokerPercentSearch from '../../modules/company/BrokerPercentSearch';
import Broker_Payments_Search from '../../modules/broker/Broker_Payments_Search';
import Broker_Balance_Search, { oneTimeTokenMutation } from './Broker_Balance_Search';
import Broker_Free_Items_Search from './Broker_Free_Items_Search';
import GridEx from 'components/form/GridEx';


const styles = theme => ({
    root: {
        backgroundColor: theme.palette.grey,
        margin: theme.spacing.unit * 3,
        marginTop: 0,
    },

    button: {
        margin: theme.spacing.unit,
    },

    badge: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        marginTop: 0,
    },

    gridRoot: {
        height: '100%',
    },


    formCaption: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit * 2,
        width: '100%',
        position: 'relative',
        height: 40,
    },
    formCaptionLabel: {
        position: 'absolute',
        backgroundColor: 'transparent',
        left: 25,
        paddingLeft: 10,
        paddingRight: 10,
    },

    rootPaper: theme.mixins.gutters({
        paddingTop: 0,
        paddingBottom: 0,
        marginTop: 0,
    }),

    formHeaderRoot: {
        padding: theme.spacing.unit * 2,
        position: 'relative',
    },

    formHeaderFieldLabel: {
        color: theme.palette.text.disabled,
        fontWeight: 'bold',
    },

    tableHeader: {
        backgroundColor: theme.palette.background.paper,
    },

    headerIcon: {
        width: theme.spacing.unit * 8,
        height: theme.spacing.unit * 9,
        marginRight: theme.spacing.unit * 2,
    },
    cmdBar: {
        position: 'absolute',
        right: theme.spacing.unit * 3,
    },
});

const tabsStateGlobalId = 'broker_view';

class Broker_View extends ComponentEx {
    state = {
        value: window[tabsStateGlobalId] ? window[tabsStateGlobalId] : 0,
        //value: 0,
    };

    componentWillUnmount() {
        window[tabsStateGlobalId] = this.state.value;
    }

    componentDidMount() {
        $("table[class*='Table-headTable']").addClass(this.props.classes.tableHeader);
    }

    handleChange = (event, value) => {
        console.log(value);
        this.setState({ value });
    };

    render() {

        const { classes } = this.props;
        const { value } = this.state;
        const loading = this.props.data.loading;
        const item = this.props.data.broker;

        return (



            <div className={classes.root}>
                <LinearProgressEx loading={loading} />

                <RegularCard

                    headerColor="red"
                    cardTitle={item && item.display_name}
                    cardSubtitle={getTxt('Collaboratore')}
                    content={

                        <Grid container>
                            <ItemGrid xs={12} sm={12} md={3}>
                                <HeaderViewItem l={getTxt('Contacts')}>
                                    <Typography variant="body1" >
                                        {item && item.email}
                                    </Typography>
                                    <Typography variant="body1" > {item && item.mobile_phone} </Typography>

                                </HeaderViewItem>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={12} md={2}>
                                <HeaderViewItem l={getTxt('Type')}>
                                    {item && <Typography variant="body1" >
                                        {getLabel(DictionaryTypes.BrokerType, item.broker_type_cd)}
                                    </Typography>}
                                </HeaderViewItem>
                            </ItemGrid>
                            {(authInfo.isAgencyAdministrator() || authInfo.isMasterBroker()) && <ItemGrid xs={12} sm={12} md={7}>
                                <CmdBarCustomBtns
                                    onClick={((btn, e) => {
                                        if (btn === 'Edit') {
                                            this.props.history.push(`/brokers/edit/${this.props.match.params.id}`);
                                        }
                                    })}
                                >{['Edit']}</CmdBarCustomBtns>

                            </ItemGrid>}
                        </Grid>

                    }

                />



                <NavPills
                    color="warning"
                    alignCenter
                    active={value}

                    onChange={this.handleChange}
                    tabs={[
                        {
                            tabButton: getTxt('Richiesti'),
                            tabIcon: Icon.Dock,
                            tabContent: (
                                <TabContainer>
                                    {item && <Request_Search noCaption noAddBtn brokerId={item.broker_id} quiet={this.state.value !== 0} />}
                                </TabContainer>
                            ),
                        },
                        {
                            tabButton: getTxt('Polizze'),
                            tabIcon: Icon.Schedule,
                            tabContent: (
                                <TabContainer>
                                    {item &&

                                        <Grid container spacing={3} >
                                            <GridEx item sm="auto" xs={6}>

                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => {

                                                        const newWindow = window.open("", "_blank");
                                                        newWindow.document.write(getTxt('Generating PDF Report, please wait...'));
                                                        this.props.getOneTimeToken({

                                                        }, this).then(
                                                            (resp_ott) => {

                                                                if (resp_ott.data && resp_ott.data.oneTimeTokenMutation && resp_ott.data.oneTimeTokenMutation.token) {

                                                                    newWindow.location.href = `${window.apiUrl}files/reports?ott=${resp_ott.data.oneTimeTokenMutation.token}&type=${ReportTypes.BrokerContracts}&params=${JSON.stringify({ brokerId: item.broker_id })}`;
                                                                    newWindow.focus();
                                                                }

                                                            });
                                                    }}
                                                >
                                                    {getTxt('Create PDF')}
                                                </Button>


                                            </GridEx>

                                            <GridEx item sm="auto" xs={6}>

                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => {

                                                        const newWindow = window.open("", "_blank");
                                                        newWindow.document.write(getTxt('Generazione del report XLS, attendere prego...'));
                                                        this.props.getOneTimeToken({

                                                        }, this).then(
                                                            (resp_ott) => {

                                                                if (resp_ott.data && resp_ott.data.oneTimeTokenMutation && resp_ott.data.oneTimeTokenMutation.token) {

                                                                    newWindow.location.href = `${window.apiUrl}files/reports?ott=${resp_ott.data.oneTimeTokenMutation.token}&type=${ReportTypes.BrokerContracts}&params=${JSON.stringify({ brokerId: item.broker_id, xlsx: true })}`;
                                                                    newWindow.focus();
                                                                }

                                                            });
                                                    }}
                                                >
                                                    {getTxt('Genera XLS')}
                                                </Button>



                                            </GridEx>
                                        </Grid>
                                    }
                                    {item && <Contract_Search noCaption brokerId={item.broker_id} quiet={this.state.value !== 1} />}
                                </TabContainer>
                            ),
                        },
                        {
                            tabButton: getTxt('Details'),
                            tabIcon: Icon.Details,
                            tabContent: (
                                <TabContainer>
                                    {item &&
                                        (authInfo.isAgencyAdministrator() || authInfo.isMasterBroker()) &&
                                        <Button color="primary" className={classes.button} onClick={() => { this.props.history.push(`/brokers/edit/${item.broker_id}`); }} >{getTxt('Edit Details')}</Button>}
                                    {item && <Broker_Details_View brokerId={item.broker_id} quiet={this.state.value !== 2} />}
                                </TabContainer>
                            ),
                        },
                        {
                            tabButton: getTxt('Documents'),
                            tabIcon: Icon.AttachFile,
                            tabContent: (
                                <TabContainer>
                                    {item && <File_Search
                                        quiet={this.state.value !== 3}
                                        entityId={item.broker_id}
                                        noDelete={(!authInfo.isAgencyAdministrator() && !authInfo.isMasterBroker())}
                                        noAddBtn={(!authInfo.isAgencyAdministrator() && !authInfo.isMasterBroker())}
                                    />}
                                </TabContainer>
                            ),
                        },
                        {
                            tabButton: getTxt('Provvigioni'),
                            tabIcon: Icon.CropSquare,
                            tabContent: (
                                <TabContainer>
                                    {item && <BrokerPercentSearch noCaption brokerId={item.broker_id} quiet={this.state.value !== 4} />}
                                </TabContainer>
                            ),
                        },
                        {
                            tabButton: getTxt('Payments'),
                            tabIcon: Icon.Payment,
                            tabContent: (
                                <TabContainer>
                                    {item &&
                                        <Broker_Payments_Search
                                            quiet={this.state.value !== 5}
                                            noCaption
                                            showAlterAddBtn={authInfo.isAgencyAdministrator() && !authInfo.isMasterBroker()}
                                            brokerId={item.broker_id}
                                        />}
                                </TabContainer>
                            ),
                        },
                        {
                            tabButton: getTxt('Movimenti Liberi'),
                            tabIcon: Icon.Payment,
                            tabContent: (
                                <TabContainer>
                                    {item &&
                                        <Broker_Free_Items_Search
                                            quiet={this.state.value !== 6}
                                            noCaption
                                            showAlterAddBtn={authInfo.isAgencyAdministrator() && !authInfo.isMasterBroker()}
                                            brokerId={item.broker_id}
                                        />}
                                </TabContainer>
                            ),
                        },
                        {
                            tabButton: getTxt('Balance'),
                            tabIcon: Icon.Payment,
                            tabContent: (
                                <TabContainer>

                                    {item && <Broker_Balance_Search noCaption showAlterAddBtn brokerId={item.broker_id} quiet={this.state.value !== 7} />}

                                </TabContainer>
                            ),
                        },


                    ]}
                />





            </div>

        );
    }
}

export default compose(
    graphql(queries.brokerByIdQuery, {
        options: props => ({
            variables: { id: props.match.params.id },
            fetchPolicy: 'network-only',
            options: {
                errorPolicy: 'all',
            },
        }),
    }),

    graphql(oneTimeTokenMutation, {
        name: 'getOneTimeToken',
        options: {
            errorPolicy: 'all',
            fetchPolicy: 'no-cache',
        },
    }),

)(withStyles(styles)(Broker_View));
