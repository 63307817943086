import React, { Component } from 'react';
import { getTxt } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';
import { withTheme } from '@material-ui/core';

import { Typography } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import $ from 'jquery';
import { Input, InputLabel, InputAdornment } from '@material-ui/core';
import { Paper } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { AppBar } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { TextField } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import gql from 'graphql-tag';
import { graphql, compose } from 'react-apollo';
import { Tabs, Tab } from '@material-ui/core';
import * as Icon from '@material-ui/icons';
import GridEx from 'components/form/GridEx';
import { Button } from '@material-ui/core';
import moment from 'moment';
import { Badge } from '@material-ui/core';

import ComponentEx from 'components/ComponentEx';
import { queryGetCustomerById, customerBriefQuery, allCustomersQuery } from '../../modules/client/clientQueries';
import c from '../../components/form/Components';
import LinearProgressEx from '../../components/LinearProgressEx';
import { getLabel, DictionaryTypes, getDictionary, getValue } from '../../global/dictionaryCache';
import MaterialForm from '../../components/form/MaterialForm';
import MFormText from '../../components/form/MFormText';
import { authInfo, Roles } from '../../global/auth';

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing.unit * 2,
        // margin: theme.spacing.unit * 2,
    },

    button: {
        margin: theme.spacing.unit,
    },

    badge: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        marginTop: 0,
    },

    gridRoot: {
        height: '100%',
    },


    formCaption: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit * 2,
        width: '100%',
        position: 'relative',
        height: 40,
    },
    formCaptionLabel: {
        position: 'absolute',
        backgroundColor: 'transparent',
        left: 25,
        paddingLeft: 10,
        paddingRight: 10,
    },

    rootPaper: theme.mixins.gutters({

        paddingTop: 0,
        paddingBottom: 0,
        marginTop: 0,// theme.spacing.unit * 3,

    }),



    calcCodeBtn: {
        marginTop: theme.spacing.unit,
    },
});

function convertDate(date) {
    function pad(s) { return (s < 10) ? '0' + s : s; }
    const d = new Date(date);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/');
}

class Client_Profile_Edit extends ComponentEx {

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.fornChanged = this.fornChanged.bind(this);
    }

    state = {
        birth_country_cd: undefined,
        birth_province: undefined,
        address_country_cd: undefined,
        address_province: undefined,
        generateFiscalValidationDlgOpen: false,
        fiscalCode: undefined,
    };

    onSubmit(e, { obj, showErrors, hideErrors }) {

        obj.customer_id = this.props.data.customer.customer_id;

        const this1 = this;
        this.props.updateCustomer({
            variables: { customer: obj },

            refetchQueries: [
                {
                    query: queryGetCustomerById,
                    variables: { id: this.props.data.customer.customer_id },
                },
            ],
        }, this).then(
            (resp) => {
                hideErrors();
                const serverErrors = resp.data.updateCustomerMutation.validation;
                
                if (serverErrors && serverErrors.length > 0) {
                    showErrors(serverErrors);
                } else {
                    this1.setState({ okDlg: true });
                }
            });
    }

    fornChanged(field, value, type, prevVal) {
        if (field === 'id_release_date') {
            this.setState({ releaseDate: value });

            if (value && value.getFullYear) {
                const year = value.getFullYear();
                let month = value.getMonth() + 1;
                if (month < 10) {
                    month = '0' + month;
                }
                let day = value.getDate();
                if (day < 10) {
                    day = '0' + day;
                }

                const min = year + '-' + month + '-' + day;
                $('[name="id_card_expiration_date"]').attr('min', min);
            }


        }
        if (field === 'birth_country_cd') {
            const country = getValue(DictionaryTypes.Country, value);
            if (country) {
                this.setState({ birth_country_cd: country });
            }
        }
        if (field === 'birth_province') {
            const prov = getValue(DictionaryTypes.ItalianProvinces, value);
            if (prov) {
                this.setState({ birth_province: prov });
            }
        }
        if (field === 'address_country_cd') {
            const country = getValue(DictionaryTypes.Country, value);

            if (country) {
                this.setState({ address_country_cd: country });
            }
        }
        if (field === 'address_province') {
            const prov = getValue(DictionaryTypes.ItalianProvinces, value);
            if (prov) {
                this.setState({ address_province: prov });
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.data.customer) {
            this.setState({ fiscalCode: nextProps.data.customer.fiscal_code });
        }
    }

    render() {

        const { classes } = this.props;
        const loading = this.props.data.loading;
        const customer = this.props.data.customer;

        let birth_country_cd;
        let birth_province;
        if (customer) {
            birth_country_cd = this.state.birth_country_cd ? this.state.birth_country_cd : customer.birth_country_cd;
            birth_province = this.state.birth_province ? this.state.birth_province : customer.birth_province;
        }

        let address_country_cd;
        let address_province;
        if (customer) {
            address_country_cd = this.state.address_country_cd ? this.state.address_country_cd : customer.address_country_cd;
            address_province = this.state.address_province ? this.state.address_province : customer.address_province;
        }

        let caption;

        if (this.props.match && !this.props.match.params.id) {
            caption = getTxt('Nuovo Privat Cliente');
        } else {
            caption = getTxt('Modifica Privat Cliente');
        }

        if (this.props.isProfile) {
            caption = getTxt('Customer Profile');
        }

        let code;

        if (customer && !this.state.fiscalCode) {
            code = customer.fiscal_code;
        }
        if (customer && this.state.fiscalCode) {
            code = this.state.fiscalCode;
        }

        let releaseDate;
        if (customer) {
            releaseDate = this.state.releaseDate ? this.state.releaseDate : customer.id_release_date;
        }

        if (this.props.match && !this.props.match.params.id) {
            caption = 'Nuovo Privat Cliente';
        }
        if (this.props.match && this.props.match.params.toReal === '1') {
            caption = getTxt('Rendi Reale');
            if (customer) {
                customer.customer_type_cd = 'PRIVATE';
            }
        }

        return (

            <div className={classes.root}>
                <Typography variant="h4" color="inherit" noWrap={false}>{getTxt(caption)}</Typography>
                <LinearProgressEx loading={loading || this.state.loading} />
                {customer &&
                    <div>
                        <MaterialForm dataSource={customer} objType="customer" onSubmit={this.onSubmit} onChange={this.fornChanged}>

                            <c.group l="Dati personali" />

                            <c.field l="Email" f="email" sm={12} />
                            {customer.customer_id === '-1' &&
                                <c.field l={getTxt('Password')} f="password" />
                            }
                            {customer.customer_id === '-1' &&
                                <c.field l={getTxt('Confirm Password')} f="password2" />
                            }

                            {customer.customer_id !== '-1' &&
                                <c.field l={getTxt('New Password')} f="password_new" type="password" />
                            }

                            {customer.customer_id !== '-1' &&
                                <c.field l={getTxt('Confirm New Password')} f="password_new2" type="password" />
                            }


                            <c.formButtons sm={12} onCancel={() => { this.props.history.goBack(); }} />
                        </MaterialForm>

                    </div>

                }

                <Dialog
                    className={classes.dialog}
                    open={this.state.okDlg}
                    onClose={() => {
                        this.setState({ okDlg: undefined });
                    }}
                >
                    <DialogTitle> <span >{getTxt('Client Profile')}</span></DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <span >{getTxt('The client profile saved successfully.')}</span>
                        </DialogContentText>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { this.setState({ okDlg: false }); }} color="primary" >
                            {getTxt('Ok')}
                        </Button>

                    </DialogActions>
                </Dialog>

            </div>

        );
    }
}


const updateCustomerMutation = gql`

mutation UpdateCustomerMutation ($customer: customer_input!) {
    updateCustomerMutation(customer: $customer){
        id
        validation {
            field
            message
        }
    } 
}

`;

export default compose(
    graphql(queryGetCustomerById, {
        options: props => ({ variables: { id: authInfo.loginInfo.userInfo.user_id } }),
    }),
    graphql(updateCustomerMutation, {
        name: 'updateCustomer',
        options: {
            errorPolicy: 'all',
        },
    }),
)(withStyles(styles)(Client_Profile_Edit));
