import React from 'react';
import { getTxt } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import GridEx from 'components/form/GridEx';
import ComponentEx from 'components/ComponentEx';
import { graphql, compose } from 'react-apollo';
import c from '../../components/form/Components';
import LinearProgressEx from '../../components/LinearProgressEx';
import { getLabel, DictionaryTypes, getDictionary, getInternalInsTypeCd } from '../../global/dictionaryCache';
import MaterialForm from '../../components/form/MaterialForm';
import * as queries from '../../modules/contract/contractQueries';
import Request_Details_View from '../request/Request_Details_View';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';

Date.isLeapYear = function (year) {
    return (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0));
};

Date.getDaysInMonth = function (year, month) {
    return [31, (Date.isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
};

Date.prototype.isLeapYear = function () {
    return Date.isLeapYear(this.getFullYear());
};

Date.prototype.getDaysInMonth = function () {
    return Date.getDaysInMonth(this.getFullYear(), this.getMonth());
};

Date.prototype.addMonths = function (value) {
    var n = this.getDate();
    this.setDate(1);
    this.setMonth(this.getMonth() + value);
    this.setDate(Math.min(n, this.getDaysInMonth()));
    return this;
};

let history;
let AfterSubmit;

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing.unit * 2,
        margin: theme.spacing.unit * 2,
    },
    rootRequestDetails: {
        paddingLeft: theme.spacing.unit * 4,
        paddingRight: theme.spacing.unit * 4,
    },

});


class Contract_Edit_Details extends ComponentEx {

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.fornChanged = this.fornChanged.bind(this);
    }

    state = {
        open: false,
        ins_period: undefined,
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleRequestClose = () => {
        this.setState({ open: false });
    };

    onSubmit(e, { obj, showErrors, hideErrors }) {

        const insType = this.props.insType ? this.props.insType : this.props.match.params.insType;
        const originalContract = this.props.data.contract;
        obj.insurance_contract_id = originalContract.insurance_contract_id;
        obj.customer_id = originalContract.customer_id;
        obj.insurance_type_cd = this.props.insType;
        obj.insurance_request_id = originalContract.insurance_request_id;
        obj.insurance_quote_id = originalContract.insurance_quote_id;
        if (obj.ins_period !== 'CUSTOM') {
            const startDate = new Date(Date.parse(obj.contract_start_date));
            if (obj.ins_period === 'ONE_YEAR') {
                obj.contract_end_date = startDate.addMonths(12);
            }
            if (obj.ins_period === '6_MONTHS') {
                obj.contract_end_date = startDate.addMonths(6);
            }
            if (obj.ins_period === '4_MONTHS') {
                obj.contract_end_date = startDate.addMonths(4);
            }
            if (obj.ins_period === '3_MONTHS') {
                obj.contract_end_date = startDate.addMonths(3);
            }
            if (obj.ins_period === '1_MONTH') {
                obj.contract_end_date = startDate.addMonths(1);
            }
        }

        this.props.updateContract({
            variables: { contract: obj },
            refetchQueries: [
                {
                    query: queries.allContractsQuery,
                    variables: { customerId: obj.customer_id },
                },
                {
                    query: queries.contractByIdQuery,
                    variables: {
                        id: this.props.id ? this.props.id : this.props.match.params.id,
                        //requestId: this.props.requestId,
                        insType: getInternalInsTypeCd(this.props.insType ? this.props.insType : this.props.match.params.insType),
                    },
                },
                {
                    query: queries.contractByIdQuery,
                    variables: {
                        id: this.props.id ? this.props.id : this.props.match.params.id,
                        requestId: this.props.requestId,
                        insType: getInternalInsTypeCd(this.props.insType ? this.props.insType : this.props.match.params.insType),
                    },
                },
            ],
        }, this).then(
            (resp) => {
                hideErrors();
                if (resp.data.updateContractMutation &&
                    resp.data.updateContractMutation.validation &&
                    resp.data.updateContractMutation.validation.length > 0) {
                    showErrors(resp.data.updateContractMutation.validation);
                } else {
                    AfterSubmit();

                }
            });
    }

    fornChanged(field, value, type, prevVal) {
        if (field === 'ins_period') {
            this.setState({ ins_period: value });
        }

    }

    render() {

        const insType = this.props.insType ? this.props.insType : this.props.match.params.insType;

        const { classes } = this.props;
        const loading = this.props.data.loading;
        const contract = this.props.data.contract;
        history = this.props.history;
        const caption = getTxt('Edit Insurance Contract');
        AfterSubmit = this.props.AfterSubmit;

        let ins_period;
        if (contract) {
            ins_period = this.state.ins_period ? this.state.ins_period : contract.ins_period;
        }

        return (

            <Dialog onClose={() => { this.props.AfterSubmit(); }} open >
                <DialogTitle> {caption}</DialogTitle>
                <DialogContent>
                    <LinearProgressEx loading={loading} />
                    {contract &&
                        <div>

                            <MaterialForm dataSource={contract} objType="contract" onSubmit={this.onSubmit} onChange={this.fornChanged}>

                                <c.group l={getTxt('Contract details')} />
                                <c.field l={getTxt('Numero Polizza')} f="contract_number" />
                                <c.field l={getTxt('Collaboratore')} f="owner_broker_id" ui="broker_select" no_deleted />

                             


                                {/* <c.field f="tacito_rinnovo" />
                                <c.field f="indicizzazione" />
                                <c.field f="contratto_soggetto_regolazione" /> */}

                                <c.field l={getTxt('Polizze note')} f="contract_notes" sm={12} />

                                <c.field f="tacito_rinnovo" />
                                <c.field f="indicizzazione" />
                                <c.field f="contratto_soggetto_regolazione" />

                                <c.group l={getTxt('Contract Dates')} />
                                <c.field l={getTxt('Insurance Period')} f="ins_period" ui="select" >
                                    {getDictionary(DictionaryTypes.InsPeriod)}
                                </c.field>
                                <c.field l={getTxt('Contract Start Date')} f="contract_start_date" />
                                {
                                    ins_period === 'CUSTOM' &&
                                    <c.field l={getTxt('Contract End Date')} f="contract_end_date" />
                                }


                                {/* <c.group l={getTxt('Contract Paper Shipment')} />
                                <c.field l={getTxt('Tracciamento')} f="contract_delivery_state" ui="select" v="Consegnare al Collaboratore" >
                                    {
                                        [
                                            { label: getTxt('Nessuna Inf.'), value: 'Nessuna Inf.' },
                                            { label: getTxt('Da Consegnare'), value: 'Da Consegnare' },
                                            { label: getTxt('Consegnare al Cliente'), value: 'Consegnare al Cliente' },
                                            { label: getTxt('Consegnare al Collaboratore'), value: 'Consegnare al Collaboratore' },
                                            { label: getTxt('Consegnare alla Compagnia'), value: 'Consegnare alla Compagnia' },
                                        ]
                                    }
                                </c.field> */}

                                {/* <c.field l={getTxt('Data Oper.')} f="contract_delivery_date" />
                                <c.field l={getTxt('Note di Consegna')} f="contract_delivery_note" /> */}


                            
                                 <c.formButtons sm={12} onCancel={() => { this.props.AfterSubmit(); }} />

                            </MaterialForm>

                        </div>
                    }


                </DialogContent>

            </Dialog>

        );
    }
}


export default compose(

    graphql(queries.updateContractMutation, {
        name: 'updateContract',
        options: {
           
            errorPolicy: 'all',
        },
    }),

    graphql(queries.contractByIdQuery, {
        options: props => (
            {
                variables: {
                    id: props.id,
                    requestId: props.requestId,
                    insType: getInternalInsTypeCd(props.insType),
                },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }
        ),
    }),

)(withStyles(styles)(Contract_Edit_Details));
