
import React, { Component } from 'react';
import { getTxt, randomString } from 'global/appGlobal';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
//import FormField from 'components/form/FormField';
import GridEx from 'components/form/GridEx';
import { MenuItem } from '@material-ui/core';
import { Button } from '@material-ui/core';
import {
    FormLabel,
    FormControl,
    FormGroup,
    FormControlLabel,
    FormHelperText,
} from '@material-ui/core';
import { Checkbox } from '@material-ui/core';
import { Input, InputLabel } from '@material-ui/core';
import FormCaption from 'components/form/FormCaption';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import $ from 'jquery';
import { EntityTypes, getEntityDescriptor } from 'global/entityDescriptors';
import schema from 'async-validator';

import  { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

import { Select } from '@material-ui/core';
import { HashRouter, BrowserRouter, Route, Link, withRouter } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';

import MFormControl from '../../components/form/MFormControl';

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: '100%',
    },
    menu: {
        width: 200,
    },
    forFocusHidden: {
        width: '0px',
        height: '0px',
        border: 0,
        color: 'transparent',
        backgroundColor: 'transparent',
        position: 'absolute',
    },
});

class MFormSelect extends MFormControl {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        //this.state.value = '';
    }

    onChange(e) {
        this.setState({ value: e.target.value });
        this.setState({ isDirty: true });
        this.formOnChange(this.props.f, e.target.value, this.props.type);
    }

    focus() {
        $(`#${this.id}for_focus`).focus();
    }

    render() {
        const { classes } = this.props;
        return (
            <GridEx item sm={this.props.sm || 6} xs={this.props.xs || 12}>
                <input type="text" id={`${this.id}for_focus`} tabIndex="-1" className={classes.forFocusHidden} />
                <TextField
                    select
                    label={this.getLabel()}
                    className={classes.textField}
                    SelectProps={{
                        MenuProps: {
                            className: classes.menu,
                        },
                    }}
                    margin="normal"
                    {...this.props}
                    InputLabelProps={{ shrink: this.state.value ? true : false }}

                    value={this.state.value ? this.state.value : ''}
                    disabled={this.props.d}
                    required={this.props.r}
                    onChange={this.onChange}
                    InputProps={{ id: this.id, name: this.props.f }}
                    helperText={this.state.errorMessage}
                    error={this.state.errorMessage ? true : false}
                >
                    {this.props.children && this.props.children.map(mi => (
                        <MenuItem key={mi.value} value={mi.value}>
                            {mi.label}
                        </MenuItem>
                    ))}
                </TextField>
            </GridEx>
        );
    }
}

MFormSelect.contextTypes = {
    onChange: PropTypes.func,
    registerControl: PropTypes.func,
    objType: PropTypes.string,
    dataSource: PropTypes.object,
    unRegisterControl: PropTypes.func,
    fields: PropTypes.Object,
};

export default withStyles(styles, { withTheme: true })(MFormSelect);