import React, { Component } from 'react';
import { getTxt, toDecimalString, toItalianDateString } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';
import { graphql, compose } from 'react-apollo';
import { Typography } from '@material-ui/core';
import gql from 'graphql-tag';
import { p } from 'components/form/Proto';
import $ from 'jquery';
import { HashRouter, BrowserRouter, Route, Link, withRouter } from 'react-router-dom';
import { Button } from '@material-ui/core';
import * as Icon from '@material-ui/icons';
import { authInfo, getToken } from 'global/auth';
import { ReportTypes } from 'global/reporting';
import {
    SortingState, SelectionState, FilteringState, GroupingState,
    IntegratedFiltering, IntegratedGrouping, IntegratedSorting, IntegratedSelection,
} from '@devexpress/dx-react-grid';
import classNames from 'classnames';
import {
    Grid,
    Table,
    VirtualTable, TableHeaderRow, TableFilterRow, TableSelection, TableGroupRow,
    GroupingPanel, DragDropProvider, TableColumnReordering, Toolbar, TableColumnResizing,
} from '@devexpress/dx-react-grid-material-ui';
import LinearProgressEx from '../../components/LinearProgressEx';
import * as queries from '../../modules/contract/contractQueries';
import { getLabel, DictionaryTypes, getDictionary } from '../../global/dictionaryCache';
import RegularCard from '../../components/dashboard/RegularCard';
import MaterialForm from '../../components/form/MaterialForm';
import GridEx from 'components/form/GridEx';
import c from '../../components/form/Components';
import { } from '../../global/dictionaryCache';
import { addDescriptor } from '../../global/entityDescriptors';
import ComponentEx from '../../components/ComponentEx';
import { tableMessages, getClientWidth, resizeService, createGridResizerCols } from '../../global/appGlobal';

let reports_filter_obj_descr;

const OBG_DESCR_KEY = 'reports_filter';

let isPaidFilteItems;

let groupByFilterItems;



let windowOpen = window.open;

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        //margin: theme.spacing.unit * 2,
    },
    button: {
        margin: theme.spacing.unit,
    },
    formButtons: {
        float: 'right',
        margin: '15px',
        whiteSpace: 'normal',
    },

    badge: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        marginTop: 0,

    },
    gridRoot: {
        height: '100%',
    },

    tableHeader: {
        backgroundColor: theme.palette.background.paper,
    },
    leftIcon: {
        marginRight: theme.spacing.unit,
    },
    iconSmall: {
        fontSize: 20,
    },
    filterForemRoot: {
        paddingLeft: theme.spacing.unit * 2,
        paddingRight: theme.spacing.unit * 2,
    },
    totalsRow: {
        backgroundColor: theme.palette.grey['200'],
        fontWeight: 'bold',
    },
});

let gw = '100%';

let contractItemsReportsFilters = {
    movimento_start_date_from: null,
    movimento_start_date_to: null,
    ins_company_id: null,
    broker_id: null,
    is_paid: 'all',
    insurance_type: 'all',
    group_by_subtotal: 'all_items',
    item_payment_type_cd: null,
    payment_account: null,
    show_agency_totals: (authInfo.isMasterBroker() || authInfo.isAgencyAdministrator()) ? true : false,
    show_broker_totals: true,
    visualizza_competenze: false,
    payment_modified_by: null,

};

const onResize = () => {
    const clientW = getClientWidth();
    if (clientW <= 960) {
        $('#ci_gridContainer').width('100%');
        gw = '100%';
    } else {
        const rootW = $('#ci_root').width();
        if (rootW) {
            $('#ci_gridContainer').width(rootW - 50);
            gw = (rootW - 50) + 'px';
        }
    }
};

class ContractItemsReport extends ComponentEx {

    contractItemsReportsFilters = {
        movimento_start_date_from: null,
        movimento_start_date_to: null,
        ins_company_id: null,
        broker_id: null,
        is_paid: 'all',
        insurance_type: 'all',
        group_by_subtotal: 'all_items',
        item_payment_type_cd: null,
        payment_account: null,
        show_agency_totals: (authInfo.isMasterBroker() || authInfo.isAgencyAdministrator()) ? true : false,
        show_broker_totals: true,
        visualizza_competenze: false,
        payment_modified_by: null,
    };

    notcompleted = false;

    constructor(props) {
        super(props);
        this.generateReport = this.generateReport.bind(this);
        this.formChanged = this.formChanged.bind(this);
        windowOpen = windowOpen.bind(this);

        this.notcompleted = this.props.history.location.pathname.includes('non-perfezionati');
        contractItemsReportsFilters.notcompleted = this.notcompleted;

        reports_filter_obj_descr = {
            movimento_start_date_from: {
                id: 'movimento_start_date_from',
                label: this.notcompleted ? getTxt('Data inizio Movimenti') : getTxt('Data Perfezionamento da'),
                type: 'DATEONLY',
                validators: [],
            },
            movimento_start_date_to: {
                id: 'movimento_start_date_to',
                label: this.notcompleted ? getTxt('Data Fine Movimenti') : getTxt('Data Perfezionamento a'),
                type: 'DATEONLY',
                validators: [],
            },
            ins_company_id: {
                id: 'ins_company_id',
                label: getTxt('Company'),
                type: 'STRING',
                validators: [],
            },
            broker_id: {
                id: 'broker',
                label: getTxt('Broker'),
                type: 'STRING',
                validators: [],
            },
            is_paid: {
                id: 'is_paid',
                label: getTxt('Is Paid'),
                type: 'STRING',
                validators: [],
            },
            insurance_type: {
                id: 'insurance_type',
                label: getTxt('Ramo'),
                type: 'STRING',
                validators: [],
            },
            group_by_subtotal: {
                id: 'group_by_subtotal',
                label: getTxt('Subtotals'),
                type: 'STRING',
                validators: [],
            },

            show_agency_totals: {
                id: 'show_agency_totals',
                label: getTxt('Show Agency Totals'),
                type: 'BOOLEAN',
                validators: [],
            },

            show_broker_totals: {
                id: 'show_broker_totals',
                label: getTxt('Visualizza Provvigioni Collaboratore'),
                type: 'BOOLEAN',
                validators: [],
            },


            item_payment_type_cd: {
                id: 'item_payment_type_cd',
                label: getTxt('Tipo pagamento'),
                type: 'STRING',
                validators: [],
            },

            payment_account: {
                id: 'payment_account',
                label: getTxt('Conto pagamento'),
                type: 'STRING',
                validators: [],
            },
            visualizza_competenze: {
                id: 'visualizza_competenze',
                label: getTxt('Visualizza competenze'),
                type: 'BOOLEAN',
                validators: [],
            },
            payment_modified_by: {
                id: 'payment_modified_by',
                label: getTxt('Operatore incasso'),
                type: 'STRING',
                validators: [],
            },
        };


        isPaidFilteItems = [
            { value: 'all', label: getTxt('all') },
            { value: 'yes', label: getTxt('yes') },
            { value: 'no', label: getTxt('no') },
        ];

        groupByFilterItems = [
            { value: 'all_items', label: getTxt('All comtract Items') },
        ];

        //if (authInfo.isAgencyAdministrator() || authInfo.isMasterBroker()) {
        groupByFilterItems.push({ value: 'company', label: getTxt('Group by Company') });
        //}

        groupByFilterItems = groupByFilterItems.concat([
            { value: 'item_type', label: getTxt('Group by Movemento Type') },
            { value: 'insurance_type', label: getTxt('Group by Ramo') }
        ]);

        contractItemsReportsFilters = {
            movimento_start_date_from: null,
            movimento_start_date_to: null,
            ins_company_id: null,
            broker_id: null,
            is_paid: isPaidFilteItems[0].value,
            insurance_type: 'all',
            group_by_subtotal: 'all_items',
            show_agency_totals: (authInfo.isMasterBroker() || authInfo.isAgencyAdministrator()) ? true : false,
            show_broker_totals: true,
            item_payment_type_cd: null,
            payment_account: null,
            visualizza_competenze: false,
            payment_modified_by: null,
        };


        this.state.movimento_start_date_from = null;
        this.state.movimento_start_date_to = null;
        this.state.ins_company_id = null;
        this.state.broker_id = null;
        this.state.is_paid = 'all';
        this.state.group_by_subtotal = 'all_items';
        this.state.insurance_type = 'all';
        this.state.show_agency_totals = (authInfo.isMasterBroker() || authInfo.isAgencyAdministrator()) ? true : false;
        this.show_broker_totals = true;
        this.state.item_payment_type_cd = null;
        this.state.payment_account = null;
        this.state.visualizza_competenze = false;
        this.state.payment_modified_by = null;

        addDescriptor(OBG_DESCR_KEY, reports_filter_obj_descr);

        //this.state.is_paid = isPaidFilteItems[2].value;

        resizeService.addResizable('mainMenu', () => {
            onResize();
        });
    }

    componentDidMount() {
        $("table[class*='Table-headTable']").addClass(this.props.classes.tableHeader);
        onResize();
    }

    state = {

        movimento_start_date_from: null,
        movimento_start_date_to: null,
        ins_company_id: null,
        broker_id: null,
        is_paid: 'all',
        group_by_subtotal: 'all_items',
        insurance_type: 'all',
        show_agency_totals: (authInfo.isMasterBroker() || authInfo.isAgencyAdministrator()) ? true : false,
        show_broker_totals: true,
        payment_account: null,
        visualizza_competenze: false,
        payment_modified_by: null,
    };

    formSetValues = { func: undefined };
    formGetValues = { func: undefined };

    generateReport(xls) {
        const newWindow = window.open("", "_blank");
        newWindow.document.write(xls ? getTxt('Generazione del report XLS, attendere prego...') : getTxt('Generating PDF Report, please wait...'));
        this.props.getOneTimeToken({

        }, this).then(
            (resp) => {


                if (resp.data && resp.data.oneTimeTokenMutation && resp.data.oneTimeTokenMutation.token) {

                    if (xls) {
                        contractItemsReportsFilters.xlsx = true;
                    } else {
                        delete contractItemsReportsFilters.xlsx;
                    }

                    const filters = this.formGetValues.func();
                    contractItemsReportsFilters.show_agency_totals = filters.show_agency_totals;
                    if (contractItemsReportsFilters.show_agency_totals && (!authInfo.isMasterBroker() && !authInfo.isAgencyAdministrator())) {
                        contractItemsReportsFilters.show_agency_totals = false;
                    }

                    newWindow.location.href = `${window.apiUrl}files/reports?ott=${resp.data.oneTimeTokenMutation.token}&type=${ReportTypes.ContractItemsReport}&params=${JSON.stringify(contractItemsReportsFilters)}`;
                    newWindow.focus();
                }


            });
    }


    formChanged(field, value, type, prevVal) {

        contractItemsReportsFilters[field] = value;
        const stateVal = {};
        this.state[field] = value;
    }

    render() {
        let columnsAll = [];
        //if (authInfo.isAgencyAdministrator() || authInfo.isMasterBroker()) {
        columnsAll.push({ name: 'company_name', title: getTxt('Compagnia') });
        columnsAll.push({ name: 'broker_name', title: getTxt('Broker') });


        //}
        columnsAll = columnsAll.concat([
            { name: 'item_start_date', title: getTxt('Movimento Start date') },
            { name: 'item_type_cd', title: getTxt('Tipo Movimento') },
            { name: 'contract_number', title: getTxt('Numero Polizza') },
            { name: 'customer_name', title: getTxt('Cliente') },
            { name: 'ramo', title: getTxt('Ramo') },
        ]);


        if (!this.notcompleted) {
            columnsAll.push({ name: 'data_perfezionamento', title: getTxt('Data perfezionato') });
        }

        columnsAll.push({ name: 'amount_netto', title: getTxt('Importo Premio Netto') });

        let columnsGroupedCompany = [];
        //if (authInfo.isAgencyAdministrator() || authInfo.isMasterBroker()) {
        columnsGroupedCompany.push({ name: 'company_name', title: getTxt('Compagnia') });
        //}

        columnsGroupedCompany = columnsGroupedCompany.concat([
            { name: 'amount_netto', title: getTxt('Importo Premio Netto') },
        ]);

        const columnsGroupedItemType = [
            { name: 'item_type_cd', title: getTxt('Tipo Movimento') },
            { name: 'amount_netto', title: getTxt('Importo Premio Netto') },
        ];

        const columnsGroupedItemInsuranceType = [
            { name: 'ramo', title: getTxt('Ramo') },
            { name: 'amount_netto', title: getTxt('Importo Premio Netto') },
        ];

        const showAgencyTotals = this.state.show_agency_totals;
        const showBrokerTotals = this.state.show_broker_totals;
        const showCompetenze = this.state.visualizza_competenze;

        if (showAgencyTotals) {
            columnsAll.push({ name: 'amount_agency_amount_broker', title: getTxt('Provvigioni Totali') });
            columnsAll.push({ name: 'total_amount_agency', title: getTxt('Provvigioni Agenzia') });
        }
        if (showBrokerTotals) {
            columnsAll.push({ name: 'total_amount_broker', title: getTxt('Provvigioni Collaboratore') });
        }
        columnsAll.push({ name: 'item_payment_type_cd', title: getTxt('Tipo Pagamento') });
        columnsAll.push({ name: 'payment_account', title: getTxt('Conto pagamento') });
        columnsAll.push({ name: 'payment_notes', title: getTxt('Payment Notes') });
        if (!this.notcompleted) {
            columnsAll.push({ name: 'payment_modified_by_name', title: getTxt('Operatore incasso') });
        }

        if (showCompetenze) {
            columnsAll.push({ name: 'competenze', title: getTxt('Competenze') });
        }


        if (showAgencyTotals) {
            columnsGroupedCompany.push({ name: 'total_amount_agency', title: getTxt('Provvigioni Agenzia') });
        }
        if (showBrokerTotals) {
            columnsGroupedCompany.push({ name: 'total_amount_broker', title: getTxt('Provvigioni Collaboratore') });
        }
        if (showAgencyTotals) {
            columnsGroupedItemType.push({ name: 'total_amount_agency', title: getTxt('Provvigioni Agenzia') });
        }
        if (showBrokerTotals) {
            columnsGroupedItemType.push({ name: 'total_amount_broker', title: getTxt('Provvigioni Collaboratore') });
        }
        if (showAgencyTotals) {
            columnsGroupedItemInsuranceType.push({ name: 'total_amount_agency', title: getTxt('Provvigioni Agenzia') });
        }
        if (showBrokerTotals) {
            columnsGroupedItemInsuranceType.push({ name: 'total_amount_broker', title: getTxt('Provvigioni Collaboratore') });
        }



        const tableColumnExtensionsRigtAlign = [
            { columnName: 'amount_netto', align: 'right' },
            { columnName: 'amount_agency_amount_broker', align: 'right' },
            { columnName: 'total_amount_agency', align: 'right' },
            { columnName: 'total_amount_broker', align: 'right' },
        ];

        /////// ITEMS 24_11_2020);
        columnsGroupedItemType.push({ name: 'count', title: getTxt('Count') });
        columnsGroupedItemInsuranceType.push({ name: 'count', title: getTxt('Count') });
        /////// 

        const { classes, theme } = this.props;
        const items = (this.props.data && this.props.data.contractItemsReport) ? [...this.props.data.contractItemsReport] : [];
        let columns = columnsAll;
        if (contractItemsReportsFilters.group_by_subtotal === 'company') {
            columns = columnsGroupedCompany;
        }
        if (contractItemsReportsFilters.group_by_subtotal === 'item_type') {
            columns = columnsGroupedItemType;
        }
        if (contractItemsReportsFilters.group_by_subtotal === 'insurance_type') {
            columns = columnsGroupedItemInsuranceType;
        }

        let globalImportoPremioNetto = 0;
        let globalProvvigioniTotali = 0;
        let globalProvvigioniAgenzia = 0;
        let globalProvvigioniCollaboratore = 0;
        let globalCompetenze = 0;
        if (items) {
            items.forEach(i => {
                if (!i.is_subtotals_row) {
                    globalImportoPremioNetto += i.amount_netto;
                    globalProvvigioniTotali += i.amount_agency_amount_broker;
                    globalProvvigioniAgenzia += i.total_amount_agency;
                    globalProvvigioniCollaboratore += i.total_amount_broker;
                    if (showCompetenze) {
                        globalCompetenze += i.competenze;
                    }
                }

            });

        }


        const colsResizerDefsDefault = [];
        columns.forEach((col) => {

            let existing;
            if (this.state.columnWidths) {
                existing = this.state.columnWidths.find(i => i.columnName === col.name);
            }

            colsResizerDefsDefault.push({
                columnName: col.name,
                width: (existing) ? existing.width : 120,
            });
        }, this);

        this.state.defColumnWidths = colsResizerDefsDefault;
        this.state.columnWidths = [...this.state.defColumnWidths];
        //}

        return (

            <div className={classes.root} id="ci_root">
                <LinearProgressEx loading={this.props.data && this.props.data.loading} />
                {!this.props.noCaption && <RegularCard

                    headerColor="red"
                    cardTitle={this.notcompleted ? getTxt('Report Titoli non perfezionati') : getTxt('Contract Items Report')}
                    cardSubtitle={''}
                    content={
                        <div>

                        </div>

                    }
                />}

                <div className={classes.filterForemRoot}>
                    <MaterialForm
                        dataSource={contractItemsReportsFilters}
                        objType={OBG_DESCR_KEY}
                        onSubmit={this.onSubmit}
                        onChange={this.formChanged}
                        setValues={this.formSetValues}
                        getValues={this.formGetValues}
                    >

                        <c.field f="group_by_subtotal" ui="select" l={getTxt('Report Type')} >
                            {groupByFilterItems}
                        </c.field>

                        {/* {(authInfo.isMasterBroker() || authInfo.isAgencyAdministrator()) && */}
                        <c.field f="ins_company_id" ui="multi-link-to-company" />
                        {/* } */}
                        <c.field f="movimento_start_date_from" />
                        <c.field f="movimento_start_date_to" />

                        {/* {(authInfo.isMasterBroker() || authInfo.isAgencyAdministrator()) &&  */}
                        <c.field f="broker_id" ui="multi-link-to-broker" />
                        {/* } */}

                        {!this.notcompleted &&
                            <c.field f="is_paid" ui="select" >
                                {isPaidFilteItems}
                            </c.field>
                        }

                        <c.field f="insurance_type" ui="select" >
                            {[{ value: 'all', label: getTxt('Tutti') }].concat(getDictionary(DictionaryTypes.InsuranceType))}
                        </c.field>
                        <c.field f="item_payment_type_cd" ui="select" >
                            {[{ value: 'all', label: getTxt('Tutti') }].concat(getDictionary(DictionaryTypes.PublicPaymentType))}
                        </c.field>
                        <c.field f="payment_account" ui="select" >
                            {[{ value: 'all', label: getTxt('Tutti') }].concat(getDictionary(DictionaryTypes.PaymentAccount))}
                        </c.field>
                        {(authInfo.isMasterBroker() || authInfo.isAgencyAdministrator()) && <c.field f="show_agency_totals" />}
                        {!this.notcompleted &&
                            <c.field f="payment_modified_by" ui="multi-link-to-broker" filter="master_and_broker" />
                        }
                        <c.field f="show_broker_totals" />
                        <c.field f="visualizza_competenze" />


                        <GridEx item sm={12} xs={12}>
                            <div className={classes.formButtons} >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        skipQuery = false;
                                        window.apolloClient.resetStore();
                                    }}
                                    className={classes.button}
                                >
                                    {getTxt('Apply Filters')}
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        contractItemsReportsFilters.movimento_start_date_from = '';
                                        contractItemsReportsFilters.movimento_start_date_to = '';
                                        contractItemsReportsFilters.ins_company_id = null;
                                        contractItemsReportsFilters.broker_id = null;
                                        contractItemsReportsFilters.is_paid = isPaidFilteItems[0].value;
                                        contractItemsReportsFilters.group_by_subtotal = null;
                                        contractItemsReportsFilters.insurance_type = null;
                                        this.formSetValues.func({
                                            movimento_start_date_from: '',
                                            movimento_start_date_to: '',
                                            ins_company_id: null,
                                            broker_id: null,
                                            is_paid: isPaidFilteItems[0].value,
                                            group_by_subtotal: 'all_items',
                                            insurance_type: 'all',
                                            show_agency_totals: (authInfo.isMasterBroker() || authInfo.isAgencyAdministrator()) ? true : false,
                                            show_broker_totals: true,
                                            item_payment_type_cd: null,
                                            payment_account: null,
                                            visualizza_competenze: false,
                                            payment_modified_by: null,
                                        });
                                        this.setState({
                                            movimento_start_date_from: '',
                                            movimento_start_date_to: '',
                                            ins_company_id: null,
                                            broker_id: null,
                                            is_paid: isPaidFilteItems[0].value,
                                            group_by_subtotal: 'all_items',
                                            insurance_type: 'all',
                                            show_agency_totals: (authInfo.isMasterBroker() || authInfo.isAgencyAdministrator()) ? true : false,
                                            show_broker_totals: true,
                                            item_payment_type_cd: null,
                                            payment_account: null,
                                            visualizza_competenze: false,
                                            payment_modified_by: null,
                                        });
                                        skipQuery = true;
                                        window.apolloClient.resetStore();
                                    }}
                                    className={classes.button}
                                >
                                    {/* <Icon.Print className={classNames(classes.leftIcon)} /> */}
                                    {getTxt('Reset Filters')}
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        this.generateReport();

                                    }}
                                    className={classes.button}
                                >
                                    <Icon.PictureAsPdf className={classNames(classes.leftIcon)} />
                                    {getTxt('Create PDF')}
                                </Button>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        this.generateReport(true);

                                    }}
                                    className={classes.button}
                                >
                                    {getTxt('Genera XLS')}
                                </Button>


                            </div>

                        </GridEx>


                    </MaterialForm>
                </div>
                <div id="ci_gridContainer" style={{ width: gw }} >
                    <Grid
                        rows={items}
                        columns={columns}
                    >

                        <VirtualTable
                            messages={tableMessages}
                            columnExtensions={tableColumnExtensionsRigtAlign}
                            rowComponent={({ row, ...restProps }) => {
                                if (row.is_subtotals_row) {
                                    return (<VirtualTable.Row
                                        className={classes.totalsRow}
                                        {...restProps}
                                    />);
                                }
                                return (<VirtualTable.Row {...restProps} />);
                            }}
                            cellComponent={({ row, column, style, ...props }) => {
                                if (column.name === 'company_name' && row.insurance_company_id && (authInfo.isAgencyAdministrator() || authInfo.isMasterBroker())) {
                                    return (<VirtualTable.Cell>
                                        { <Link to={`/company/view/${row.insurance_company_id}`}>{row.company_name}</Link>}
                                    </VirtualTable.Cell>);
                                }
                                if (column.name === 'broker_name' && (authInfo.isAgencyAdministrator() || authInfo.isMasterBroker())) {
                                    return (<VirtualTable.Cell>
                                        { <Link to={`/brokers/${row.broker_id}`}>{row.broker_name}</Link>}
                                    </VirtualTable.Cell>);
                                }
                                if (column.name === 'payment_modified_by_name' && (authInfo.isAgencyAdministrator() || authInfo.isMasterBroker())) {
                                    return (<VirtualTable.Cell>
                                        { <Link to={`/brokers/${row.payment_modified_by}`}>{row.payment_modified_by_name}</Link>}
                                    </VirtualTable.Cell>);
                                }
                                if (column.name === 'item_start_date') {
                                    return (<VirtualTable.Cell>{row.item_start_date && toItalianDateString(row.item_start_date)}</VirtualTable.Cell>);
                                }
                                if (column.name === 'item_type_cd') {
                                    let typeName = getLabel(DictionaryTypes.InsuranceItemType, row.item_type_cd ? row.item_type_cd : '');

                                    if (typeName === 'Sostituzione' || typeName === 'sostituzione' || typeName === 'Sostituita') {
                                        typeName = 'Sost';
                                    } else if (typeName === 'Sospensione') {
                                        typeName = 'Sosp';
                                    } else if (typeName.length > 3) {
                                        typeName = typeName.substr(0, 3);
                                    }


                                    return (<VirtualTable.Cell>{typeName}</VirtualTable.Cell>);
                                }
                                if (column.name === 'customer_name') {
                                    return (<VirtualTable.Cell>
                                        {row.customer_type_cd && <Link to={`/customers/${row.customer_type_cd.toLowerCase()}/${row.customer_id}`} >{row.customer_name}</Link>}
                                    </VirtualTable.Cell>);
                                }
                                if (column.name === 'ramo') {
                                    return (<VirtualTable.Cell>
                                        {getLabel(DictionaryTypes.InsuranceType, row.insurance_type_cd)}
                                    </VirtualTable.Cell>);
                                }
                                if (column.name === 'data_perfezionamento') {
                                    return (<VirtualTable.Cell> {row.data_perfezionamento && toItalianDateString(row.data_perfezionamento)}</VirtualTable.Cell>);
                                }
                                if (column.name === 'item_payment_type_cd') {
                                    return (<VirtualTable.Cell> {row.item_payment_type_cd && getLabel(DictionaryTypes.PaymentType, row.item_payment_type_cd)}</VirtualTable.Cell>);
                                }
                                if (column.name === 'payment_account') {
                                    return (<VirtualTable.Cell> {row.payment_account && getLabel(DictionaryTypes.PaymentAccount, row.payment_account)}</VirtualTable.Cell>);
                                }

                                if (column.name === 'amount_netto') {
                                    return (<VirtualTable.Cell style={{ textAlign: 'right' }}>
                                        {toDecimalString(row.amount_netto, true)}
                                    </VirtualTable.Cell>);
                                }

                                if (column.name === 'amount_agency_amount_broker') {
                                    return (<VirtualTable.Cell style={{ textAlign: 'right' }}> {toDecimalString(row.amount_agency_amount_broker, true)}</VirtualTable.Cell>);
                                }

                                if (column.name === 'total_amount_agency') {
                                    return (<VirtualTable.Cell style={{ textAlign: 'right' }}> {toDecimalString(row.total_amount_agency, true)}</VirtualTable.Cell>);
                                }

                                if (column.name === 'total_amount_broker') {
                                    return (<VirtualTable.Cell style={{ textAlign: 'right' }}> {toDecimalString(row.total_amount_broker, true)}</VirtualTable.Cell>);
                                }
                                if (column.name === 'competenze') {
                                    return (<VirtualTable.Cell style={{ textAlign: 'right' }}> {toDecimalString(row.competenze, true)}</VirtualTable.Cell>);
                                }

                                if (column.name === 'contract_number') {
                                    return (<VirtualTable.Cell>{row.contract_number && <Link to={`/contract/view/${row.insurance_type_cd}/${row.insurance_contract_id}`} > {row.contract_number}</Link>} </VirtualTable.Cell>);
                                }


                                return <VirtualTable.Cell {...props} />;
                            }}
                        />
                        <TableColumnResizing
                            columnWidths={this.state.columnWidths}
                            onColumnWidthsChange={(cols) => { this.setState({ columnWidths: cols }); }}
                            defaultColumnWidths={this.state.defColumnWidths}

                        />
                        <TableHeaderRow />

                    </Grid>

                </div>

                <table style={{ marginTop: 10 }}>
                    <tr>
                        <td>
                            <Typography variant="subheading" style={{ whiteSpace: 'normal', marginTop: 30, marginLeft: 30 }} >
                                {getTxt('Importo Premio Netto')}:
                            </Typography>
                        </td>
                        <td>
                            <Typography variant="subheading" style={{ whiteSpace: 'normal', marginTop: 30, marginLeft: 30 }} >
                                {toDecimalString(globalImportoPremioNetto, true)}
                            </Typography>
                        </td>
                    </tr>
                    {showAgencyTotals && <tr>
                        <td>
                            <Typography variant="subheading" style={{ whiteSpace: 'normal', marginLeft: 30 }} >
                                {getTxt('Provvigioni Totali')}:
                            </Typography>
                        </td>
                        <td>
                            <Typography variant="subheading" style={{ whiteSpace: 'normal', marginLeft: 30 }} >
                                {toDecimalString(globalProvvigioniTotali, true)}
                            </Typography>
                        </td>
                    </tr>}
                    {(authInfo.isMasterBroker() || authInfo.isAgencyAdministrator()) && showAgencyTotals && <tr>
                        <td>
                            <Typography variant="subheading" style={{ whiteSpace: 'normal', marginLeft: 30 }} >
                                {getTxt('Provvigioni Agenzia')}:
                            </Typography>
                        </td>
                        <td>
                            <Typography variant="subheading" style={{ whiteSpace: 'normal', marginLeft: 30 }} >
                                {toDecimalString(globalProvvigioniAgenzia, true)}
                            </Typography>
                        </td>
                    </tr>}
                    {showBrokerTotals && <tr>
                        <td>
                            <Typography variant="subheading" style={{ whiteSpace: 'normal', marginLeft: 30 }} >
                                {getTxt('Provvigioni Collaboratore')}:
                            </Typography>
                        </td>
                        <td>
                            <Typography variant="subheading" style={{ whiteSpace: 'normal', marginLeft: 30 }} >
                                {toDecimalString(globalProvvigioniCollaboratore, true)}
                            </Typography>
                        </td>
                    </tr>}
                    {showCompetenze && <tr>
                        <td>
                            <Typography variant="subheading" style={{ whiteSpace: 'normal', marginLeft: 30 }} >
                                {getTxt('Competenze')}:
                            </Typography>
                        </td>
                        <td>
                            <Typography variant="subheading" style={{ whiteSpace: 'normal', marginLeft: 30 }} >
                                {toDecimalString(globalCompetenze, true)}
                            </Typography>
                        </td>
                    </tr>}
                </table>

            </div>
        );
    }
}

const oneTimeTokenMutation = gql`

mutation oneTimeTokenMutation  {
    oneTimeTokenMutation{
        token
    }
}
`;


const contractItemsReport = gql`

query contractItemsReport ($params: String)  {
    contractItemsReport (params: $params) {
        contract_item_id
        company_name
        insurance_company_id
        item_start_date
        item_type_cd
        contract_number
        insurance_contract_id
        customer_name
        customer_id
        customer_type_cd
        ramo
      
        amount_netto
        amount_agency_amount_broker
        total_amount_agency
        total_amount_broker
        item_payment_type_cd
        insurance_type_cd
        payment_notes
        payment_account
        is_subtotals_row
        competenze
        data_perfezionamento
        count
        broker_name
        broker_id
        payment_modified_by
        payment_modified_by_name
    }
}
`;

const getParams = (filters, props) => {
    filters.show_agency_totals = (authInfo.isMasterBroker() || authInfo.isAgencyAdministrator()) ? true : false;
    filters.notcompleted = props.history.location.pathname.includes('non-perfezionati');

    if (filters.movimento_start_date_from === '') {
        filters.movimento_start_date_from = null;
    }
    if (filters.movimento_start_date_to === '') {
        filters.movimento_start_date_to = null;
    }

    return filters;
}

let skipQuery = true;
export default compose(
    graphql(contractItemsReport, {
        options: props => (
            {

                variables: {
                    params: JSON.stringify(getParams(contractItemsReportsFilters, props)),
                },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
                skip: skipQuery,
            }
        ),
    }),

    graphql(oneTimeTokenMutation, {
        name: 'getOneTimeToken',
        options: {
            errorPolicy: 'all',

        },
    }),

)(withRouter(withStyles(styles, { withTheme: true })(ContractItemsReport)));
