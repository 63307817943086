
import { getTxt } from 'global/appGlobal';

import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';

import ComponentEx from 'components/ComponentEx';


//import * as Icon from '@material-ui/icons';







import { graphql, compose } from 'react-apollo';
import { update_Request_Mutation, requestBriefQuery, allRequestsQuery, get_Requests_ById_Query } from './requestQeries';
import ChangeStateDialog from '../../components/ChangeStateDialog';


const styles = theme => ({

});

class ChangeRequestStateDialog extends ComponentEx {

    state = {
        loading: false,
    };

    saveState(newStatus) {
        const obj = {};
        obj.insurance_request_id = this.props.entityId;
        obj.state_cd = newStatus;

        const me = this;
        this.setState({ loading: true });
        const finalObj = {};
        finalObj[this.props.entityType] = obj;
        this.props.updateRequest({
            variables: { request: finalObj },
            refetchQueries: [
                {
                    query: requestBriefQuery,
                    variables: { id: obj.insurance_request_id, insType: this.props.insType },
                    fetchPolicy: 'network-only',
                },
                {
                    query: allRequestsQuery,
                },
                {
                    query: get_Requests_ById_Query,
                    variables: { id: obj.insurance_request_id, insType: this.props.insType },
                },
            ],

        }, this).then(
            (resp) => {
                if (resp.data.update_Request_Mutation &&
                    resp.data.update_Request_Mutation.validation &&
                    resp.data.update_Request_Mutation.validation.length > 0) {
                    //show errors
                } else {
                    me.props.onClose();
                }
                this.setState({ loading: false });
            });
    }

    render() {
        const { classes } = this.props;
        return (
            <ChangeStateDialog {...this.props} loading={this.state.loading} saveState={state => this.saveState(state)} />
        );
    }
}

export default compose(
    graphql(update_Request_Mutation, {
        name: 'updateRequest',
        options: {
            errorPolicy: 'all',
        },
    }),
)(withStyles(styles)(ChangeRequestStateDialog));
