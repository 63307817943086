
//import FormField from 'components/form/FormField';
import GridEx from 'components/form/GridEx';
import { getTxt } from 'global/appGlobal';
import $ from 'jquery';
import DoneIcon from '@material-ui/icons/Done';
import ErrorIcon from '@material-ui/icons/Error';
import { Button } from '@material-ui/core';
import gql from 'graphql-tag';
import { graphql, compose } from 'react-apollo';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { FormControl, FormHelperText } from '@material-ui/core';
import { Input, InputAdornment, InputLabel } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { CircularProgress } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import MFormControl from '../../components/form/MFormControl';
import { randomString } from '../../global/appGlobal';
import { Grid } from '@material-ui/core';

function hexToBase64(str) {
    return btoa(String.fromCharCode.apply(null, str.replace(/\r|\n/g, "").replace(/([\da-fA-F]{2}) ?/g, "0x$1 ").replace(/ +$/, "").split(" ")));
}

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: '100%',
    },

    input: {
        marginTop: theme.spacing.unit * 2,
    },

    dialogText: {
        color: theme.palette.error.main,
    },

    calcCodeBtn: {
        position: 'absolute',
        right: 0,
        bottom: theme.spacing.unit,
        minWidth: theme.spacing.unit * 6,
    },
    findIcon: {
        width: theme.spacing.unit * 2.5,
        height: theme.spacing.unit * 2.5,
    },
    calcProgress: {
        position: 'absolute',
        right: theme.spacing.unit * 8,
        top: theme.spacing.unit,
    },

    validationIconTrue: {
        position: 'absolute',
        right: theme.spacing.unit * 13,
        color: 'green',
    },

    validationIconFalse: {
        position: 'absolute',
        right: theme.spacing.unit * 13,
        color: theme.palette.error.main,
        cursor: 'pointer',
    },
    capchaImage: {
        width: 200,
        height: 100,
    },
});

function convertDate(date) {
    function pad(s) { return (s < 10) ? '0' + s : s; }
    const d = new Date(date);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/');
}

class MFormViecleNumber extends MFormControl {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.getSicData = this.getSicData.bind(this);
        this.scrapId = undefined;
        this.capchaImgId = randomString(5, 'aA#');
        this.capchaCodeInputId = randomString(5, 'aA#');
        this.getCapchaData = this.getCapchaData.bind(this);
        this.submitCaptcha = this.submitCaptcha.bind(this);
    }

    onChange(e) {
        this.setState({ isDirty: true, value: e.target.value, notFoundMsg: undefined });
        this.formOnChange(this.props.f, e.target.value, this.props.type);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.forseRefresh) {
            this.setState({ value: nextProps.v });
        }
    }



    getSicData() {

        this.setState({ notFoundMsg: undefined });
        const id = $(`input[name="${this.props.f}"]`).val();
        if (!id) {
            this.setState({ errorMessage: getTxt('Please input ') + this.getLabel() });
            return;
        }

        this.setState({ loading: true });

        this.scrapId = randomString(5, 'Aa#');



        const this1 = this;
        this.props.sicData({
            variables: { idVeic: id, scrapId: this.scrapId },
        }).then((resp) => {
            this1.setState({ loading: false });
            if (resp.data && resp.data.sicData) {
                const data = JSON.parse(resp.data.sicData);
                $('#sicLog').val(data.log);
                if (data.nothigFoundMsg) {
                    this1.setState({ notFoundMsg: data.nothigFoundMsg });
                } else {
                    if (this1.props.sicDataChanged) {
                        this1.props.sicDataChanged(data);
                    }
                }
            }
        });

       // setTimeout(this.getCapchaData, 2000);


    }

    cycles = 0;
    getCapchaData() {

        const this1 = this;
        async function runQuery() {
            const { data } = await window.apolloClient.query({
                query: capchaDataQuery,
                options: {
                    fetchPolicy: 'network-only',
                },
                variables: { scrapId: this1.scrapId, id: Date.now() },
            });

            if (data && data.capchaData) {

                this1.setState({ captchaDlg: true, captchaInputError: undefined });
                const img = document.getElementById(this1.capchaImgId);
                img.src = 'data:image/jpeg;base64,' + data.capchaData;
                $('#' + this.capchaCodeInputId).val('');
                $('#' + this.capchaCodeInputId).focus();
            } else {

                this1.cycles += 1;

                if (this1.cycles < 2000 * 40) {
                    setTimeout(this1.getCapchaData, 2000);
                }
            }
        }

        runQuery();
    }

    async submitCaptcha(this1) {
        const code = $('#' + this1.capchaCodeInputId).val();
        if (!code) {
            this1.setState({ captchaInputError: getTxt('Please input code') });
            return;
        }

        const resp = await this1.props.setCaptcha({
            variables: { scrapId: this1.scrapId, code },
        });

        if (resp.data && resp.data.setCaptcha && resp.data.setCaptcha === 'OK') {
            this1.setState({ captchaDlg: false });
        }
    }

    render() {
        const { classes } = this.props;
        return (

            <GridEx item sm={this.props.sm || 6} xs={this.props.xs || 12}>
                <FormControl className={classes.textField} >
                    <InputLabel
                        error={this.state.errorMessage ? true : false}

                    >
                        {this.getLabel()}
                    </InputLabel>
                    <Input
                        margin="normal"
                        {...this.props}
                        value={this.state.value}
                        disabled={this.props.d}
                        required={this.props.r}
                        onChange={this.onChange}
                        inputProps={
                            {
                                id: this.props.id ? this.props.id : this.id,
                                name: this.props.f,
                                endAdornment: this.props.endAdornment,
                                maxlength: 27,
                            }}

                        endAdornment={
                            <InputAdornment position="end">

                                {this.state.loading && <CircularProgress className={classes.calcProgress} size={30} />}

                                <Button variant="contained" size="small" onClick={this.getSicData} className={classes.calcCodeBtn} >
                                    <SearchIcon className={classes.findIcon} />
                                </Button>

                            </InputAdornment>
                        }
                    />
                    <FormHelperText error={this.state.errorMessage ? true : false} >{this.state.errorMessage}</FormHelperText>
                </FormControl>




                <Dialog
                    className={classes.dialog}
                    open={this.state.captchaDlg}
                    onClose={() => {
                        this.setState({ captchaDlg: undefined });
                    }}
                >
                    <DialogTitle> <span>{getTxt('Captcha from remote service.')}</span></DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {getTxt('Per proseguire nella richiesta inserire il codice mostrato in figura.')}
                        </DialogContentText>
                        <GridEx container sm={12}>
                            <Grid item sm={12} >
                                <img className={classes.capchaImage} id={this.capchaImgId} />
                            </Grid>
                            <Grid item sm={12} >
                                <Input
                                    margin="normal"
                                    label={getTxt('Code from image')}
                                    required
                                    inputProps={
                                        {
                                            id: this.capchaCodeInputId,
                                            maxlength: 8,
                                        }}
                                    helperText={this.state.captchaInputError}
                                    error={this.state.captchaInputError ? true : false}
                                />
                            </Grid>


                        </GridEx>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { this.submitCaptcha(this); }} color="primary" >
                            {getTxt('Submit Code')}
                        </Button>

                    </DialogActions>
                </Dialog>


                <Dialog
                    className={classes.dialog}
                    open={this.state.notFoundMsg}
                    onClose={() => {
                        this.setState({ notFoundMsg: undefined });
                    }}
                >
                    <DialogTitle> <span>{getTxt('Car Controls Service')}</span></DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {this.state.notFoundMsg}
                        </DialogContentText>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { this.setState({ notFoundMsg: false }); }} color="primary" >
                            Ok
                               </Button>

                    </DialogActions>
                </Dialog>
                <input type="hidden" id="sicLog" />
            </GridEx>

        );
    }
}

MFormViecleNumber.contextTypes = {
    onChange: PropTypes.func,
    registerControl: PropTypes.func,
    objType: PropTypes.string,
    dataSource: PropTypes.object,
    unRegisterControl: PropTypes.func,
    fields: PropTypes.Object,
};


const sicDataMutation = gql`

mutation sicDataMutation($idVeic: String, $scrapId: String) {
    sicData(idVeic: $idVeic, scrapId: $scrapId ) 
  }`;


const setCaptchaMutation = gql`
mutation setCaptchaMutation($scrapId: String, $code: String) {
    setCaptcha(scrapId: $scrapId, code: $code ) 
  }`;



const capchaDataQuery = gql`
    query capchaDataQuery($id: ID!, $scrapId: String) {
        capchaData(id: $id, scrapId: $scrapId) 
    }`;


export default compose(

    graphql(sicDataMutation, {
        name: 'sicData',
        options: {
            errorPolicy: 'all',
        },
    }),

    graphql(setCaptchaMutation, {
        name: 'setCaptcha',
        options: {
            errorPolicy: 'all',
        },
    }),

)(withStyles(styles, { withTheme: true })(MFormViecleNumber));

