
//import FormField from 'components/form/FormField';
import GridEx from 'components/form/GridEx';
import { getTxt } from 'global/appGlobal';
import $ from 'jquery';

import * as Icon from '@material-ui/icons';
import { Button } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { FormControl, FormHelperText } from '@material-ui/core';
import { Input, InputAdornment, InputLabel } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import MFormControl from '../../components/form/MFormControl';


const styles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: '100%',
    },

    input: {
        marginTop: theme.spacing.unit * 2,
    },

    dialogText: {
        color: theme.palette.error.main,
    },

    calcCodeBtn: {
        position: 'absolute',
        right: 0,
        bottom: theme.spacing.unit,
    },
    calcProgress: {
        position: 'absolute',
        right: theme.spacing.unit * 13,
    },

    validationIconTrue: {
        position: 'absolute',
        right: theme.spacing.unit * 13,
        color: 'green',
    },

    validationIconFalse: {
        position: 'absolute',
        right: theme.spacing.unit * 13,
        color: theme.palette.error.main,
        cursor: 'pointer',
    },
});

function convertDate(date) {
    function pad(s) { return (s < 10) ? '0' + s : s; }
    const d = new Date(date);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/');
}

class MFormIbanText extends MFormControl {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.validateIBAN = this.validateIBAN.bind(this);
        this.state.validationMessages = [];
    }

    onChange(e) {
        this.setState({ value: e.target.value, IbanValid: undefined, validationMessages: [] });
        this.setState({ isDirty: true });
        this.formOnChange(this.props.f, e.target.value, this.props.type);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.forseRefresh) {
            this.setState({ value: nextProps.v });
        }
    }

    validateIBAN() {

        this.setState({ IbanValid: undefined, validationMessages: [], errorMessage: undefined });
        let iban = $(`input[name="${this.props.f}"]`).val();

        const emptyMessages = [getTxt('Empty IBAN code.', '#1k799cy')];
        if (!iban) {
            this.setState({ IbanValid: false, validationMessages: emptyMessages, errorMessage: emptyMessages });
            return;
        }

        iban = iban.trim();
        if (iban === '') {
            this.setState({ IbanValid: false, validationMessages: emptyMessages, errorMessage: emptyMessages });
            return;
        }

        this.setState({ loading: true });

        try {

            $.ajax({
                type: 'GET',
                url: 'https://openiban.com/validate/' + iban,
                context: this,
                cache: false,

                success: (data) => {
                    this.setState({
                        loading: false,
                        IbanValid: data.valid,
                        validationMessages: data.messages,
                    });
                    if (data.messages && data.messages.length > 0) {
                        this.setState({ errorMessage: data.messages });
                    }
                },
                error: (err, a, b, c) => {
                    this.setState({ loading: false, validationMessages: [] });
                },
            });
        } catch (err) {
            this.setState({ loading: false, validationMessages: [] });
        }

    }


    render() {
        const { classes } = this.props;
        return (

            <GridEx item sm={this.props.sm || 6} xs={this.props.xs || 12}>
                <FormControl className={classes.textField} >
                    <InputLabel
                        error={this.state.errorMessage ? true : false}
                        shrink
                    >
                        {this.getLabel()}
                    </InputLabel>
                    <Input
                        margin="normal"
                        {...this.props}
                        value={this.state.value}
                        disabled={this.props.d}
                        required={this.props.r}
                        onChange={this.onChange}
                        inputProps={
                            {
                                id: this.props.id ? this.props.id : this.id,
                                name: this.props.f,
                                endAdornment: this.props.endAdornment,
                                placeholder: `${getTxt('Click')} '${getTxt('Validate')}' ${getTxt('to validate IBAN using external WEB service')}`,
                                maxlength: 27,
                            }}

                        endAdornment={
                            <InputAdornment position="end">
                                {this.state.loading && <CircularProgress className={classes.calcProgress} size={30} />}
                                <Button variant="contained" size="small" onClick={this.validateIBAN} className={classes.calcCodeBtn} >{getTxt('Validate')}</Button>
                                {this.state.IbanValid === true &&
                                    <Tooltip title={getTxt('IBAN online validation passed.')}>
                                        <Icon.Done className={classes.validationIconTrue} />
                                    </Tooltip>
                                }
                                {this.state.IbanValid === false &&
                                    <Tooltip title={getTxt('IBAN online validation failed. Click to see more details.')}>
                                        <Icon.Error
                                            className={classes.validationIconFalse}
                                            onClick={() => { this.setState({ ValidationDlgOpen: true }); }}
                                        />
                                    </Tooltip>
                                }
                            </InputAdornment>
                        }
                    />
                    <FormHelperText error={this.state.errorMessage ? true : false} >{this.state.errorMessage}</FormHelperText>
                </FormControl>




                <Dialog
                    className={classes.dialog}
                    open={this.state.ValidationDlgOpen}
                    onClose={() => {
                        this.setState({ serverError: undefined, ValidationDlgOpen: false });
                    }}
                >
                    <DialogTitle> <span>{getTxt('IBAN online validation')}</span></DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {
                                this.state.validationMessages.map((m) => {
                                    return (
                                        <div className={classes.dialogText}>{m}</div>
                                    );
                                })
                            }

                        </DialogContentText>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { this.setState({ ValidationDlgOpen: false }); }} color="primary" >
                            Ok
                               </Button>

                    </DialogActions>
                </Dialog>

            </GridEx>

        );
    }
}

MFormIbanText.contextTypes = {
    onChange: PropTypes.func,
    registerControl: PropTypes.func,
    objType: PropTypes.string,
    dataSource: PropTypes.object,
    unRegisterControl: PropTypes.func,
    fields: PropTypes.Object,
};

export default withStyles(styles, { withTheme: true })(MFormIbanText);