import React, { Component } from 'react';
import { getTxt, toDecimalString, toItalianDateString } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';
import { graphql, compose } from 'react-apollo';
import { Typography } from '@material-ui/core';
import gql from 'graphql-tag';
import { p } from 'components/form/Proto';
import $ from 'jquery';
import { HashRouter, BrowserRouter, Route, Link, withRouter } from 'react-router-dom';
import { Button } from '@material-ui/core';
import * as Icon from '@material-ui/icons';
import { authInfo, getToken } from 'global/auth';
import { ReportTypes } from 'global/reporting';
import ChartCard from 'components/dashboard/ChartCard';
import ChartistGraph from 'react-chartist';

var Chartist = require("chartist");
import {
    SortingState, SelectionState, FilteringState, GroupingState,
    IntegratedFiltering, IntegratedGrouping, IntegratedSorting, IntegratedSelection,
} from '@devexpress/dx-react-grid';
import classNames from 'classnames';
import {
    Grid,
    Table,
    VirtualTable, TableHeaderRow, TableFilterRow, TableSelection, TableGroupRow,
    GroupingPanel, DragDropProvider, TableColumnReordering, Toolbar, TableColumnResizing,
} from '@devexpress/dx-react-grid-material-ui';
import LinearProgressEx from '../../components/LinearProgressEx';
import * as queries from '../../modules/contract/contractQueries';
import { getLabel, DictionaryTypes, getDictionary } from '../../global/dictionaryCache';
import RegularCard from '../../components/dashboard/RegularCard';
import MaterialForm from '../../components/form/MaterialForm';
import GridEx from 'components/form/GridEx';
import c from '../../components/form/Components';
import { } from '../../global/dictionaryCache';
import { addDescriptor } from '../../global/entityDescriptors';
import ComponentEx from '../../components/ComponentEx';
import { getReportDataQuery } from './reportQueries';
import { getYearandMonthLabel, getYearandMonthLabelGrid } from './ReportsRoot';
import { tableMessages, createGridResizerCols } from '../../global/appGlobal';
import CurrencyGridFormatter from 'components/CurrencyGridFormatter';

let reports_filter_obj_descr;

const OBG_DESCR_KEY = 'monthly_inbound_reports_filter';

let reportsFilters = {
    movimento_start_date_from: null,
    movimento_start_date_to: null,
    ins_company_id: null,
    broker_id: 'all',
    is_paid: 'all',
    insurance_type: 'all',
    group_by_subtotal: 'all_items',
    show_agency_totals: authInfo.isMasterBroker() || authInfo.isAgencyAdministrator(),
};

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        //  margin: theme.spacing.unit * 2,
    },
    button: {
        margin: theme.spacing.unit,
        //float: 'right',
        whiteSpace: 'normal',
    },
    formButtons: {
        float: 'right',
        margin: '15px',
        whiteSpace: 'normal',
    },

    badge: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        marginTop: 0,

    },
    gridRoot: {
        height: '100%',
    },

    tableHeader: {
        backgroundColor: theme.palette.background.paper,
    },
    leftIcon: {
        marginRight: theme.spacing.unit,
    },
    iconSmall: {
        fontSize: 20,
    },
    filterForemRoot: {
        paddingLeft: theme.spacing.unit * 2,
        paddingRight: theme.spacing.unit * 2,
    },
    totalsRow: {
        backgroundColor: theme.palette.grey['200'],
        fontWeight: 'bold',
    },
});


class Monthly_Inbound_Report extends ComponentEx {

    constructor(props) {
        super(props);
        this.generateReport = this.generateReport.bind(this);
        this.formChanged = this.formChanged.bind(this);
        this.appplyFilters = this.appplyFilters.bind(this);

        reports_filter_obj_descr = {
            from: {
                id: 'from',
                label: getTxt('Incident Date Time from'),
                type: 'DATEONLY',
                validators: [],
            },
            to: {
                id: 'to',
                label: getTxt('Incident Date Time to'),
                type: 'DATEONLY',
                validators: [],
            },
            companyId: {
                id: 'companyId',
                label: getTxt('Company'),
                type: 'INTEGER',
                validators: [],
            },

        };

        reportsFilters = {
            from: null,
            to: null,
            companyId: null,
        };

        addDescriptor(OBG_DESCR_KEY, reports_filter_obj_descr);

    }

    state = {

        from: null,
        to: null,
        companyId: null,
    };

    formSetValues = { func: undefined };

    generateReport() {
        const newWindow = window.open("", "_blank");
        newWindow.document.write(getTxt('Generating PDF Report, please wait...'));
        this.props.getOneTimeToken({

        }, this).then(
            (resp) => {



                if (resp.data && resp.data.oneTimeTokenMutation && resp.data.oneTimeTokenMutation.token) {

                    newWindow.location.href = `${window.apiUrl}files/reports?ott=${resp.data.oneTimeTokenMutation.token}&type=${ReportTypes.ContractItemsReport}&params=${JSON.stringify(reportsFilters)}`;
                    newWindow.focus();
                }


            });
    }

    formChanged(field, value, type, prevVal) {
        reportsFilters[field] = value;
        const stateVal = {};
        this.state[field] = value;
    }

    appplyFilters() {
        this.props.data.refetch({
            reportType: 'monthly-inbound-cash-flow',
            params: JSON.stringify({
                from: this.state.from,
                to: this.state.to,
                companyId: this.state.companyId,
            }),
        });
    }

    render() {

        const columns = [
            { name: 'finance_month_id', title: getTxt('Month') },
            { name: 'contract_total_amount_client', title: getTxt('Total Client (contract)') },
            { name: 'contract_total_amount_broker', title: getTxt('Total Broker % ') },
            { name: 'contract_total_amount_agency', title: getTxt('Total Agency % ') },
        ];

        createGridResizerCols(this.state, columns, { contract_total_amount_broker: 250, contract_total_amount_agency: 250 });

        const { classes } = this.props;
        const items = this.props.data.reportData ? JSON.parse(this.props.data.reportData) : [];


        return (

            <div className={classes.root}>
                <LinearProgressEx loading={this.props.data.loading} />
                {!this.props.noCaption && <RegularCard

                    headerColor="red"
                    cardTitle={getTxt('Monthly Cash Flow')}
                    cardSubtitle={''}
                    content={
                        <div>{''}</div>
                    }
                />}


                <div>
                    {!this.props.noPrintButton && <Button
                        variant="contained"
                        color="primary"

                        onClick={() => {
                            const url = '/#/reports/monthly-inbound/print';
                            const winReport = window.open(url, '_blank');
                            winReport.focus();
                        }}
                        className={classes.button}
                    >
                        <Icon.Print className={classNames(classes.leftIcon)} />
                        {getTxt('Printable View')}
                    </Button>}
                </div>

                <Grid
                    rows={items}
                    columns={columns}
                >
                    <CurrencyGridFormatter
                        for={['contract_total_amount_client', 'contract_total_amount_broker', 'contract_total_amount_agency']}
                    />
                    <VirtualTable
                        messages={tableMessages}
                        cellComponent={({ row, column, style, ...props }) => {

                            if (column.name === 'finance_month_id') {
                                return (<Table.Cell >
                                    {getYearandMonthLabelGrid(row.m_year, row.month_label)}
                                </Table.Cell>);
                            }






                            return <VirtualTable.Cell {...props} />;
                        }}
                    />
                    <TableColumnResizing
                        columnWidths={this.state.columnWidths}
                        onColumnWidthsChange={(cols) => { this.setState({ columnWidths: cols }); }}
                    />
                    <TableHeaderRow />
                </Grid>


            </div>
        );
    }
}

export default compose(
    graphql(getReportDataQuery, {
        options: props => (
            {
                variables: { reportType: 'monthly-inbound-cash-flow', params: JSON.stringify({}) },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }
        ),
    }),

)(withRouter(withStyles(styles)(Monthly_Inbound_Report)));
