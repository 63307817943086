import gql from 'graphql-tag';

export const allBrokersQuery = gql`
query allBrokersQuery {
    allBrokers{
        broker_id
        broker_type_cd
        state_cd
        first_name
        last_name
        company_name
        address_country_cd
        address_province
        address_city
        address_street
        number_street
        address_cap
        phone
        mobile_phone
        fax
        email
        email_pec
        bank_iban
        birth_date
        birth_country_cd
        birth_province
        birth_city
        fiscal_code
        vat_number
        rui_number
        display_name
        agreement_start_date
        withholdings_percent
        sex_cd
        is_deleted
    }
    }
`;


export const brokersAsDictionary = gql`

query brokersAsDictionaryQuery($id: ID) {
    brokersAsDictionary(id: $id) {
        broker_id
        display_name
        broker_type_cd
        is_deleted
    }
  }

`;

export const brokerBalanceQuery = gql`
query brokerBalanceQuery($i: ID!, $brokerId: Int, $from: String, $to: String) {
    brokerBalance(i: $i, brokerId: $brokerId, from: $from, to: $to )
}`;


export const brokerByIdQuery = gql`

query brokerByIdQuery($id: ID!) {
    broker(id: $id) {
        broker_id
            broker_type_cd
            state_cd
            first_name
            last_name
            company_name
            address_country_cd
            address_province
            address_city
            address_street
            number_street
            address_cap
            phone
            mobile_phone
            fax
            email
            email_pec
            bank_iban
            birth_date
            birth_country_cd
            birth_province
            birth_city
            fiscal_code
            vat_number
        
            rui_number
            sex_cd
            is_deleted

        display_name
        password
        password2
        password_new
        password_new2
        agreement_start_date
        withholdings_percent
    }
   
}
  

`;

export const updateBrokerMutation = gql`

mutation updateBrokerMutation($broker: broker_input!) {
    updateBrokerMutation(broker: $broker) {
      id
      validation {
        field
        message
      }
    }
  }
`;

export const changeBrokerPasswordMutation = gql`

mutation changeBrokerPasswordMutation($broker: broker_input!) {
    changeBrokerPasswordMutation(broker: $broker) {
      id
      validation {
        field
        message
      }
    }
  }
`;

export const allBrokerPaymentsQuery = gql`
query allBrokerPaymentsQuery($brokerId: ID) {
    allBrokerPayments(brokerId: $brokerId ){
        broker_payment_id
        broker_id
        payment_type_cd
        payment_state_cd
        payment_date
        payment_amount
        payment_notes
        payment_account
        broker{
            display_name
        }
        files{
            document_id
        }
    }
}`;


export const brokerPaymentByIdQuery = gql`

query brokerPaymentByIdQuery($id: ID!) {
    brokerPayment(id: $id) {
        broker_payment_id
        broker_id
        payment_type_cd
        payment_state_cd
        payment_date
        payment_amount
        payment_notes
        payment_account
        broker{
            display_name
        }
    }
   
}`;

export const updateBrokerPaymentMutation = gql`

mutation updateBrokerPaymentMutation($brokerPayment: broker_payment_input!) {
    updateBrokerPaymentMutation(brokerPayment: $brokerPayment) {
      id
      validation {
        field
        message
      }
    }
  }`;

export const deleteBrokerPaymentMutation = gql`

mutation deleteBrokerPaymentMutation($id: ID) {
    deleteBrokerPaymentMutation(id: $id) {
      id
      validation {
        field
        message
      }
    }
  }`;

export const allBrokerFreeItemsQuery = gql`
query allBrokerFreeItems($brokerId: ID) {
    allBrokerFreeItems(brokerId: $brokerId ){
        broker_free_item_id
        broker_id
        payment_date
        payment_amount
        payment_notes
        provvigioni
        broker{
            display_name
        }
        files{
            document_id
        }
    }
}`;

export const deleteBrokerFreeItemMutation = gql`

mutation deleteBrokerFreeItemMutation($id: ID) {
    deleteBrokerFreeItemMutation(id: $id) {
      id
      validation {
        field
        message
      }
    }
  }`;

export const brokerFreeItemByIdQuery = gql`

query brokerFreeItemByIdQuery($id: ID!) {
    brokerFreeItem(id: $id) {
        broker_free_item_id
        broker_id
        payment_date
        payment_amount
        payment_notes
        provvigioni
        broker{
            display_name
        }
    }
   
}`;

export const updateBrokerFreeItemMutation = gql`

mutation updateBrokerFreeItemMutation($brokerFreeItem: broker_free_item_input!) {
    updateBrokerFreeItemMutation(brokerFreeItem: $brokerFreeItem) {
      id
      validation {
        field
        message
      }
    }
  }`;


export const deleteBrokerMutation = gql`

mutation deleteBrokerMutation ($id: ID!) {
    deleteBrokerMutation(id: $id){
        id
        validation {
            field
            message
        }
    } 
}`;
