import React from 'react';
import { getTxt, randomPassword } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';



import { graphql, compose } from 'react-apollo';
import GridEx from 'components/form/GridEx';
import Button from '@material-ui/core/Button';


import ComponentEx from 'components/ComponentEx';
import { FormControlLabel, FormGroup } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';

import { globalSettingsQuery, updateSettingsMutation } from './qlobalSettingQueriess';
import c from '../../components/form/Components';
import LinearProgressEx from '../../components/LinearProgressEx';
import MaterialForm from '../../components/form/MaterialForm';
import { getLabel, DictionaryTypes, getDictionary } from '../../global/dictionaryCache';
import { EntityTypes } from '../../global/entityDescriptors';


const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing.unit * 2,
        margin: theme.spacing.unit * 2,
    },

    button: {
        margin: theme.spacing.unit,
    },

    badge: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        marginTop: 0,
    },

    gridRoot: {
        height: '100%',
    },


    formCaption: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit * 2,
        width: '100%',
        position: 'relative',
        height: 40,
    },
    formCaptionLabel: {
        position: 'absolute',
        backgroundColor: 'transparent',
        left: 25,
        paddingLeft: 10,
        paddingRight: 10,
    },

    rootPaper: theme.mixins.gutters({

        paddingTop: 0,
        paddingBottom: 0,
        marginTop: 0,// theme.spacing.unit * 3,

    }),
    checkBox: {
        marginLeft: theme.spacing.unit,
    },
});

class Global_Settings_Edit extends ComponentEx {

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);

    }

    formSetValues = { func: undefined };

    state = {
    };

    onSubmit(e, { obj, showErrors, hideErrors }) {

        obj.settings_id = 1;
        this.props.updateSettings({
            variables: { settings: obj },
            refetchQueries: [

                {
                    query: globalSettingsQuery,
                },
                
            ],
        }, this).then(
            (resp) => {
                hideErrors();
                if (resp.errors && resp.errors.length > 0) {
                    this.showServerErrors(resp.errors);
                }
                const serverErrors = resp.data.updateSettings.validation;
                
                if (serverErrors && serverErrors.length > 0) {
                    showErrors(serverErrors);
                } else {
                  
                    
                    // window.showPopupInfo({
                    //     variant: 'save_ok',
                    //     objName: getTxt('Settings'),
                    // });
                }
            });
    }

    render() {

        const { classes } = this.props;
        const loading = this.props.data.loading;
        const item = this.props.data.globalSettings;

        const caption = getTxt('Global Settings');

        return (

            <div className={classes.root}>
                <Typography variant="h4" color="inherit" noWrap={false}>{caption}</Typography>
                <LinearProgressEx loading={loading} />
                {item &&

                    <MaterialForm
                        dataSource={item}
                        objType={EntityTypes.GlobalSettings}
                        onSubmit={this.onSubmit}
                        onChange={this.formChanged}
                    >
                        <c.group l={getTxt('Mail Notifications')} />

                        <c.field f="new_customer_notification_subject" sm={12} />
                        <c.field f="new_customer_notification_body" multiline sm={12} />
                        
                        <c.formButtons sm={12} onCancel={() => { this.props.history.goBack(); }} />
                    </MaterialForm>

                }


            </div>

        );
    }
}

export default compose(
    graphql(updateSettingsMutation, {
        name: 'updateSettings',
        options: {
            errorPolicy: 'all',
        },
    }),
    graphql(globalSettingsQuery, {
        options: props => ({
            fetchPolicy: 'network-only',
            options: {
                errorPolicy: 'all',
            },
        }),
    }),
)(withStyles(styles)(Global_Settings_Edit));
