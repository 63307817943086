import React, { Component } from 'react';
import gql from 'graphql-tag';
import { graphql, compose } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import $ from 'jquery';
import { HashRouter, BrowserRouter, Route, Link, withRouter } from 'react-router-dom';
import ComponentEx from 'components/ComponentEx';
import {
    SortingState, SelectionState, FilteringState, GroupingState, EditingState,
    IntegratedFiltering, IntegratedGrouping, IntegratedSorting, IntegratedSelection,
} from '@devexpress/dx-react-grid';
import {
    Grid, Table,
    VirtualTable, TableHeaderRow, TableFilterRow, TableEditRow, TableSelection, TableGroupRow,
    GroupingPanel, DragDropProvider, TableColumnReordering, Toolbar, TableEditColumn,
} from '@devexpress/dx-react-grid-material-ui';
import { TableCell } from '@material-ui/core';
import { Button } from '@material-ui/core';
import c from 'components/form/Components';
import { getToken } from 'global/auth';
import { ReportTypes } from 'global/reporting';
import DeleteIcon from '@material-ui/icons/Delete';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
//import pdfMake from 'pdfmake';
import { getTxt, filterRowMessages, tableMessages } from '../../global/appGlobal';
import LinkToCustomerById from '../../components/LinksToObjectById';
import LinearProgressEx from '../../components/LinearProgressEx';
import { getLabel, getDictionary, DictionaryTypes } from '../../global/dictionaryCache';
import { allDictionariesQuery, removeDictionaryMutation } from '../../modules/dictionary/dictionariesQueries';
import { Menu, MenuItem } from '@material-ui/core';
import RegularCard from '../../components/dashboard/RegularCard';


const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        margin: theme.spacing.unit * 2,
    },
    button: {
        margin: theme.spacing.unit,
    },

    tableHeader: {
        backgroundColor: theme.palette.background.paper,
    },
    inGridButton: {
        width: 36,
        height: 30,
        marginRight: theme.spacing.unit * 2,
    },
});

class Dictionary_Search extends ComponentEx {

    state = {
        anchorEl: null,
        open: false,
        delDlgOpen: false,
        delId: undefined,
    };

    constructor(props) {
        super(props);

        this.getRowId = row => row.dictionary_id;
        this.newClick = this.newClick.bind(this);
    }

    componentDidMount() {
        $("table[class*='Table-headTable']").addClass(this.props.classes.tableHeader);
    }

    newClick() {
        this.props.history.push('/dictionaries/new');
    }

    handleDelDictionary(id) {

        this.props.deleteDictionary({
            variables: { id },
            refetchQueries: [
                {
                    query: allDictionariesQuery,
                },
            ],
        });
        this.setState({ delDlgOpen: false });
    }

    render() {
        const { classes } = this.props;

        const items = this.props.data.allDictionaries ? this.props.data.allDictionaries : [];

        const columns = [
            { name: 'dictionary_cd', title: getTxt('Dictionary Nome', '#o7kzn8g') },

        ];

        return (

            <div className={classes.root}>
                <LinearProgressEx loading={this.props.data.loading} />

                <RegularCard

                    headerColor="red"
                    cardTitle={getTxt('Dictionaries')}
                    cardSubtitle={getTxt('List of dictionaries')}
                    content={
                        <div>

                        </div>

                    }
                />

                <Grid
                    rows={items}
                    columns={columns}
                >
                    <FilteringState
                        defaultFilters={[]}
                    />
                    <SortingState
                        defaultSorting={['dictionary_cd']}
                    />
                    <IntegratedSorting />
                    <IntegratedFiltering />
                    <Table
                        messages={tableMessages}
                        cellComponent={({ row, column, style }) => {
                            if (column.name === 'dictionary_cd') {
                                return (<Table.Cell >
                                    <Link to={`/dictionaries/${row.dictionary_cd}/items`} >{row.dictionary_cd}</Link>
                                </Table.Cell>);
                            }
                            return <Table.Cell {...props} />;

                        }}
                    />

                    <TableFilterRow messages={filterRowMessages} />

                    <TableHeaderRow showSortingControls />

                </Grid>
                <Dialog onClose={() => { this.setState({ delDlgOpen: false }); }} open={this.state.delDlgOpen}>
                    <DialogTitle >{getTxt('Delete dictionary.')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <span> {getTxt('Dictionary will be delete on server.')} </span>
                            <br />
                            <span > {getTxt('If this dictionary already used in application logic, the application will be broken.')} </span>
                            <br />
                            <span > {getTxt('Are you sure do you want delete this dictionary?')} </span>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleDelDictionary(this.state.delId)} color="primary">
                            {getTxt('Ok')}
                        </Button>
                        <Button onClick={() => { this.setState({ delDlgOpen: false }); }} color="primary">
                            {getTxt('Annula')}
                        </Button>

                    </DialogActions>
                </Dialog>
            </div >
        );
    }


}


export default compose(
    graphql(allDictionariesQuery, {
        options: {
            fetchPolicy: 'network-only',
            options: {
                errorPolicy: 'all',
            },
        },
    }),

    graphql(removeDictionaryMutation, {
        name: 'deleteDictionary',
        options: {
            errorPolicy: 'all',
        },
    }),
)(withRouter(withStyles(styles)(Dictionary_Search)));
