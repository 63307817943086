
import GridEx from 'components/form/GridEx';
import gql from 'graphql-tag';
import { Button } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { FormControl, FormHelperText } from '@material-ui/core';
import { Input, InputAdornment, InputLabel } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import Search from '@material-ui/icons/Search';
import { graphql, compose } from 'react-apollo';
import MFormControl from '../../components/form/MFormControl';
import Client_Search from '../../modules/client/Client_Search';
import { companiesAsDictionaryQuery } from '../../modules/company/CompanyQueries';
import Company_Search from '../../modules/company/Company_Search';
import { Grid } from '@material-ui/core';
import { getTxt } from '../../global/appGlobal';

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        paddingTop: theme.spacing.unit * 2,
        width: '100%',
        position: 'relative',
    },

    label: {
        marginTop: theme.spacing.unit,
    },

    delim: {
        marginRight: theme.spacing.unit,
    },

    input: {
        color: 'transparent',
    },

    dialogText: {
        color: theme.palette.error.main,
    },

    calcCodeBtn: {
        position: 'absolute',
        right: 0,
        bottom: theme.spacing.unit,
    },
    calcProgress: {
        position: 'absolute',
        right: theme.spacing.unit * 9,
        top: theme.spacing.unit - theme.spacing.unit * 2,
    },

    validationIconTrue: {
        position: 'absolute',
        right: theme.spacing.unit * 13,
        color: 'green',
    },

    validationIconFalse: {
        position: 'absolute',
        right: theme.spacing.unit * 13,
        color: theme.palette.error.main,
        cursor: 'pointer',
    },

    link: {
        position: 'absolute',
        top: theme.spacing.unit * 5,
        left: theme.spacing.unit,
    },
    iconSmall: {
        fontSize: 20,
    },

});

class MFormMultilinkToCompany extends MFormControl {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);

        //this.onKeyDown = this.onKeyDown.bind(this);
    }

    selectedId = undefined;

    onChange(e) {
        this.setState({ value: e.target.value });
        this.setState({ isDirty: true });
        this.formOnChange(this.props.f, e.target.value, this.props.type);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.forseRefresh) {
            this.setState({ value: nextProps.v });
        }
    }

    render() {
        const { classes, theme } = this.props;

        const me = this;
        const loading = this.props.data.loading;
        const items = this.props.data.companyDictionary ? this.props.data.companyDictionary : [];

        let companiesIds = [];
        if (this.state.value && this.state.value.length && this.state.value.length > 0) {
            companiesIds = JSON.parse(this.state.value);
        }

        let text = '';
        const selectedCompanies = [];
        if (items.length > 0 && companiesIds.length > 0) {
            companiesIds.forEach((id, index) => {
                const company = items.find(c => c.value === id);
                if (company) {
                    const link = `/company/view/${id}`;
                    selectedCompanies.push({
                        link,
                        display_name: company.label,
                    });
                    text += company.label;
                    if (companiesIds.length > 1 && index < companiesIds.length) {
                        text += ',____ ';
                    }
                }

            });

        }

        return (

            <GridEx item sm={this.props.sm || 6} xs={this.props.xs || 12}>
                <FormControl className={classes.textField} >
                    <InputLabel
                        error={this.state.errorMessage ? true : false}
                        shrink
                        className={this.label}
                        required={this.props.r}
                    >
                        {this.getLabel()}
                    </InputLabel>
                    <Input
                        margin="normal"
                        {...this.props}
                        value={text}
                        disabled
                        required={this.props.r}
                        multiline
                        className={classes.input}
                        inputProps={
                            {
                                id: this.props.id ? this.props.id : this.id,
                                name: this.props.f,
                                endAdornment: this.props.endAdornment,
                            }}

                        endAdornment={
                            <InputAdornment position="end" style={{ zIndex: 9999 }}>
                                {loading && <CircularProgress className={classes.calcProgress} size={30} />}
                                <Button
                                    variant="contained"
                                    size="small"
                                    onClick={() => { this.setState({ findDlgOpen: true }); }}
                                    className={classes.calcCodeBtn}
                                    style={this.props.d ? { display: 'none' } : {}}
                                >
                                    <Search className={classes.iconSmall} />
                                </Button>
                            </InputAdornment>
                        }
                    />
                    <Grid container spacing={this.props.spacing || 24} className={classes.link} >
                        {/* <div className={classes.link}> */}
                        {selectedCompanies.map((selected, index) => {
                            return (
                                <div style={{ lineHeight: 0.97 }}>

                                    <span style={{ paddingBottom: 0 }}>
                                        <Link to={selected.link} >
                                            {selected ? selected.display_name : ''}
                                        </Link>
                                        {selectedCompanies.length > 1 && index < selectedCompanies.length && <span className={classes.delim} >,</span>}
                                    </span>
                                </div>
                            );
                        })}
                        {/* </div> */}
                    </Grid>

                    <FormHelperText error={this.state.errorMessage ? true : false} >{this.state.errorMessage}</FormHelperText>
                </FormControl>

                <Dialog
                    className={classes.dialog}
                    open={this.state.findDlgOpen}
                    onClose={() => {
                        this.setState({ findDlgOpen: false });
                    }}
                >
                    <DialogTitle> <span> {getTxt('Insurance Companies')} </span></DialogTitle>
                    <DialogContent>
                        <DialogContentText>

                            <Company_Search
                                noCaption
                                showSelects
                                multiselect
                                selectedIds={this.state.value}
                                onSelectedChanged={(c) => {
                                    if (c) {
                                        this.selectedId = c;
                                    } else {
                                        this.selectedId = undefined;
                                    }

                                }}
                            />

                        </DialogContentText>

                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                me.setState({ findDlgOpen: false });

                                if (me.selectedId) {
                                    me.setValue(JSON.stringify(me.selectedId));
                                }

                            }}
                            color="primary"
                        >
                            Ok
                        </Button>

                        <Button onClick={() => { this.setState({ findDlgOpen: false }); }} color="primary" >
                            Annulla
                        </Button>
                    </DialogActions>
                </Dialog>

            </GridEx>

        );
    }
}

MFormMultilinkToCompany.contextTypes = {
    onChange: PropTypes.func,
    registerControl: PropTypes.func,
    objType: PropTypes.string,
    dataSource: PropTypes.object,
    unRegisterControl: PropTypes.func,
    fields: PropTypes.Object,
};


export default compose(
    graphql(companiesAsDictionaryQuery, {
        options: props => ({}),
    }),
)(withStyles(styles, { withTheme: true })(MFormMultilinkToCompany));

