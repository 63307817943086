import React, { Component } from 'react';
import { getTxt } from 'global/appGlobal';

import { withStyles } from '@material-ui/core/styles';
import { withTheme } from '@material-ui/core';

import { Typography } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';
import ReactSVG from 'react-svg';


import { Paper } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { AppBar } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { TextField } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import { graphql, compose } from 'react-apollo';
import TabContainer from 'components/TabContainer';

import { Tabs, Tab } from '@material-ui/core';

import HeaderViewItem from 'components/HeaderViewItem';
import { authInfo } from 'global/auth';
import * as Icon from '@material-ui/icons';
import { Checkbox } from '@material-ui/core';
//import TabsEx, { Tab } from 'global/components/tabs/TabsEx';


import { Button } from '@material-ui/core';
import moment from 'moment';
import { Badge } from '@material-ui/core';

import ComponentEx from 'components/ComponentEx';
import { EntityTypes, getEntityDescriptor } from 'global/entityDescriptors';


import $ from 'jquery';
import CmdBar from 'components/form/CmdBar';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';



import File_Search from 'modules/file/File_Search';
import NavPills from 'components/NavPills/NavPills';
import ItemGrid from 'components/Grid/ItemGrid';

import Quote_Search from '../../modules/quote/Quote_Search';
import { requestBriefQuery } from '../../modules/request/requestQeries';
import ChangeRequestStateDialog from '../../modules/request/ChangeRequestStateDialog';


import { HashRouter, BrowserRouter, Route, Link, withRouter } from 'react-router-dom';
import { getLabel, DictionaryTypes, getDictionary, getDictionaryItem, getValue, getInternalInsTypeCd } from '../../global/dictionaryCache';
import RequestGuarantiesList from '../../modules/request/RequestGuarantiesList';
import Request_Details_View from '../../modules/request/Request_Details_View';
import RegularCard from '../../components/dashboard/RegularCard';
import CmdBarCustomBtns from '../../components/CmdBarCustomBtns';
import ChangeLog from '../ChangeLog/ChangeLog';


const styles = theme => ({

    root: {
        backgroundColor: theme.palette.grey,
        margin: theme.spacing.unit * 3,
        marginTop: 0,
    },

    button: {
        margin: theme.spacing.unit,
    },

    badge: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        marginTop: 0,
    },

    gridRoot: {
        height: '100%',
    },


    formCaption: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit * 2,
        width: '100%',
        position: 'relative',
        height: 40,
    },
    formCaptionLabel: {
        position: 'absolute',
        //color: theme.palette.primary1,
        backgroundColor: 'transparent',
        left: 25,
        paddingLeft: 10,
        paddingRight: 10,
    },

    rootPaper: theme.mixins.gutters({

        paddingTop: 0,
        paddingBottom: 0,
        marginTop: 0,// theme.spacing.unit * 3,

    }),

    formHeaderRoot: {
        padding: theme.spacing.unit * 2,
        position: 'relative',
        overflowX: 'auto',
    },

    formHeaderFieldLabel: {
        color: theme.palette.text.disabled,
        fontWeight: 'bold',

    },

    tableHeader: {
        backgroundColor: theme.palette.background.paper,
    },

    headerIcon: {
        width: theme.spacing.unit * 8,
        height: theme.spacing.unit * 9,
        marginRight: theme.spacing.unit * 2,
    },

    cmdBar: {
        position: 'absolute',
        right: theme.spacing.unit * 3,
    },
    dialogText: {
        color: theme.palette.error.main,
    },

    noGuarantyErrorText: {
        ...theme.typography.body1,
        color: theme.palette.error.main,
        paddingLeft: theme.spacing.unit * 2,
    },

});

const tabsStateGlobalId = 'Request_View_active_Tab';

class Request_View extends ComponentEx {

    innerID = 'Request_View';

    state = {
        value: window[tabsStateGlobalId] ? window[tabsStateGlobalId] : 0,
        currentSate: undefined,
        noOneGuarantyDialogOpen: false,
    };

    componentDidMount() {
        $("table[class*='Table-headTable']").addClass(this.props.classes.tableHeader);
    }

    handleChange = (event, value) => {
        this.setState({ value });
    };

    componentWillUnmount() {
        getTxt('Transform to Contract');
        getTxt('Edit');
        getTxt('New Quote');
        window[tabsStateGlobalId] = this.state.value;
    }

    render() {

        const { classes, theme } = this.props;
        const { value } = this.state;

        let guarantiesCount = 0;
        let request;
        let insEntityType;
        if (this.props.data.get_request_by_id) {
            guarantiesCount = this.props.data.get_request_by_id.guarantiesCount;
            Object.keys(this.props.data.get_request_by_id).forEach((key) => {
                if (this.props.data.get_request_by_id[key] && this.props.data.get_request_by_id[key].insurance_request_id) {
                    request = this.props.data.get_request_by_id[key];
                    insEntityType = key;
                }
            });

        }

        let buttons = ['Edit', 'New Quote'];
        if (request && request.customer.customer_type_cd !== 'PRIVATE_PROSPECT') {
            buttons = ['Transform to Contract', ...buttons];
        }
        if (authInfo.isCustomer()) {
            buttons = ['Edit', 'New Quote'];
        }

        const tabs = [
            {
                tabButton: getTxt('Guaranties'),
                tabIcon: Icon.Dashboard,
                tabContent: (
                    <TabContainer>
                        {request && <RequestGuarantiesList
                            requestId={this.props.match.params.id}
                            insType={this.props.match.params.insType}
                            history={this.props.history}
                            requestState={request.state_cd}
                        />}
                    </TabContainer>
                ),
            },
            {
                tabButton: getTxt('Details'),
                tabIcon: Icon.Schedule,
                tabContent: (
                    <TabContainer>
                        {request &&
                            <Request_Details_View
                                id={request.insurance_request_id}
                                history={this.props.history}
                                insType={this.props.match.params.insType}
                            />
                        }
                    </TabContainer>
                ),
            },
            {
                tabButton: getTxt('Quotations'),
                tabIcon: Icon.List,
                tabContent: (
                    <TabContainer>
                        {request &&
                            <Quote_Search
                                requestId={request.insurance_request_id}
                                history={this.props.history}
                                noAddBtn={request.state_cd === 'TRANSFERRED_TO_CONTRACT'}
                            />
                        }
                    </TabContainer>
                ),
            },
            {
                tabButton: getTxt('Documents'),
                tabIcon: Icon.AttachFile,
                tabContent: (
                    <TabContainer>
                        {request &&
                            <File_Search
                                entityId={request.insurance_request_id}
                                history={this.props.history}
                                noAddBtn={(request.state_cd === 'TRANSFERRED_TO_CONTRACT' || authInfo.isCustomer())}
                                noDelete={(!authInfo.isAgencyAdministrator() && !authInfo.isMasterBroker())}
                            />
                        }
                    </TabContainer>
                ),
            },

        ];

        if (!authInfo.isCustomer()) {
            tabs.push({
                tabButton: getTxt('Change log'),
                tabIcon: Icon.List,
                tabContent: (
                    <TabContainer>
                        {request && <ChangeLog entityId={request.insurance_request_id} noCaption />}
                    </TabContainer>
                ),
            });
        }

        if (request) {
            window.pdfDetailscaption = getLabel(DictionaryTypes.InsuranceType, request.insurance_type_cd);
        }


        return (


            <div className={classes.root}>
                <RegularCard

                    headerColor="red"
                    cardTitle={request && getLabel(DictionaryTypes.InsuranceType, request.insurance_type_cd)}
                    cardSubtitle={getTxt('Insurance Request')}
                    content={

                        <Grid container>
                            <ItemGrid xs={12} sm={12} md={2}>
                                <HeaderViewItem l={getTxt('Cliente')}>
                                    {request && !authInfo.isCustomer() &&
                                        <Link to={`/customers/${request.customer.customer_type_cd.toLowerCase()}/${request.customer.customer_id}`} >{request.customer.display_name}</Link>
                                    }
                                    {request && authInfo.isCustomer() &&
                                        <span>{request.customer.display_name}</span>
                                    }
                                </HeaderViewItem>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={12} md={2}>
                                <HeaderViewItem l={getTxt('Collaboratore')}>
                                    {request && !authInfo.isCustomer() &&
                                        <Link to={`/brokers/${request.customer.broker.broker_id}`} >{request.customer.broker.display_name}</Link>
                                    }
                                    {request && authInfo.isCustomer() &&
                                        <span>{request.customer.broker.display_name}</span>
                                    }
                                </HeaderViewItem>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={12} md={2}>
                                <HeaderViewItem l={getTxt('Stato')}>
                                    {request && !authInfo.isCustomer() &&
                                        <div>
                                            <Link
                                                to={this.props.location.pathname}
                                                onClick={() => { this.setState({ stateDialogOpen: true }); }}
                                            >
                                                {getLabel(DictionaryTypes.InsuranceRequestState, request.state_cd)}
                                            </Link>
                                            <ChangeRequestStateDialog
                                                entityType={insEntityType}
                                                type={DictionaryTypes.InsuranceRequestState}
                                                open={this.state.stateDialogOpen}
                                                entityId={request.insurance_request_id}
                                                state={request.state_cd}
                                                insType={getInternalInsTypeCd(this.props.match.params.insType)}
                                                onClose={() => { this.setState({ stateDialogOpen: false }); }}
                                            />
                                        </div>
                                    }
                                    {request && authInfo.isCustomer() &&
                                        <div>
                                            <span>
                                                {getLabel(DictionaryTypes.InsuranceRequestState, request.state_cd)}
                                            </span>
                                        </div>
                                    }
                                </HeaderViewItem>
                            </ItemGrid>



                            <ItemGrid xs={12} sm={12} md={6}>

                                {request && request.state_cd !== 'TRANSFERRED_TO_CONTRACT' && !authInfo.isCustomer() && <div>
                                    <div style={{ _float: 'right' }}>
                                        {request && request.state_cd !== 'TRANSFERRED_TO_CONTRACT' && <CmdBarCustomBtns
                                            onClick={((btn) => {
                                                if (btn === 'Edit') {
                                                    this.props.history.push(`/requests/${this.props.match.params.insType}/edit/${request.customer.customer_id}/${request.insurance_request_id}`);
                                                }
                                                if (btn === 'New Quote') {
                                                    this.props.history.push(`/quotes/edit/${request.insurance_request_id}/new`);
                                                }
                                                if (btn === 'Transform to Contract') {
                                                    if (guarantiesCount === 0) {
                                                        this.setState({ noOneGuarantyDialogOpen: true });
                                                    }
                                                    else {
                                                        this.props.history.push(`/contract/edit/${request.insurance_type_cd}/${request.insurance_request_id}`);
                                                    }

                                                }
                                            })}
                                        >{buttons}</CmdBarCustomBtns>}

                                        {request.state_cd !== 'TRANSFERRED_TO_CONTRACT' && guarantiesCount === 0 && <span className={classes.noGuarantyErrorText} >{getTxt('Request has no guaranties')}</span>}
                                    </div>
                                </div>}

                                {request && request.state_cd === 'TRANSFERRED_TO_CONTRACT' && request.contract && <div>
                                    <div style={{ _float: 'right' }}>
                                        <CmdBarCustomBtns
                                            onClick={((btn) => {
                                                if (btn === getTxt('Open Contract')) {
                                                    this.props.history.push(`/contract/view/${this.props.match.params.insType}/${request.contract.insurance_contract_id}`);
                                                }
                                            })}
                                        >{[getTxt('Open Contract')]}</CmdBarCustomBtns>
                                    </div>
                                </div>}

                            </ItemGrid>
                        </Grid>

                    }

                />


                <NavPills
                    color="warning"

                    alignCenter
                    active={value}
                    onChange={this.handleChange}
                    tabs={tabs}
                />

                <Dialog
                    className={classes.dialog}
                    open={this.state.noOneGuarantyDialogOpen}
                    onClose={() => {
                        this.setState({ serverError: undefined, generateFiscalValidationDlgOpen: false });
                    }}
                >
                    <DialogTitle> <span className={classes.dialogText}>{getTxt('The request has no guaranties')}</span></DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <span className={classes.dialogText}>{getTxt('To create Contract the Request must have at least one guarantee.')}</span>
                        </DialogContentText>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { this.setState({ noOneGuarantyDialogOpen: false }); }} color="primary" >
                            {getTxt('Ok')}
                        </Button>

                    </DialogActions>
                </Dialog>

            </div >
        );
    }
}


export default compose(
    graphql(requestBriefQuery, {
        options: props => ({
            variables: { id: props.match.params.id, insType: getInternalInsTypeCd(props.match.params.insType) },
            fetchPolicy: 'network-only',
            options: {
                errorPolicy: 'all',
            },
        }),
    }),
)(withStyles(styles, { withTheme: true })(Request_View));

