import React from 'react';
import { getTxt } from 'global/appGlobal';
import { Menu, MenuItem } from '@material-ui/core';
import { getLabel, DictionaryTypes, getDictionary, getValue } from '../../global/dictionaryCache';

const NewRequestMenuItems = props => (
    getDictionary(DictionaryTypes.InsuranceType).map(d => (<div>
        {!d.deleted && <MenuItem onClick={() => { props.history.push(`/requests/${d.value}/${props.customerId}/new`); }}>{d.label}</MenuItem>}
    </div>))

);
export default NewRequestMenuItems;
