import { IntegratedSorting, SortingState } from '@devexpress/dx-react-grid';
import { Grid, Table, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import ComponentEx from 'components/ComponentEx';
import { getTxt } from 'global/appGlobal';
import { Button } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { compose, graphql } from 'react-apollo';
import LinearProgressEx from '../../components/LinearProgressEx';
import { DictionaryTypes, getDictionaryItem } from '../../global/dictionaryCache';
import { getEntityDescriptor } from '../../global/entityDescriptors';
import * as queries from '../../modules/publicInsType/publicInsTypeQueries';
import { tableMessages } from '../../global/appGlobal';


let history;
//let AfterSubmit;

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
});

class Custom_Fields_View extends ComponentEx {

    state = {
        open: false,
    };

    render() {

        const columns = [

            { name: 'origLabel', title: getTxt('Original label') },
            { name: 'customLabel', title: getTxt('Custom label') },
        ];

        const { classes } = this.props;

        const loading = this.props.data.loading;
        const item = this.props.data.public_Ins_Type_By_Id;
        history = this.props.history;
        //AfterSubmit = this.props.AfterSubmit;
        const caption = 'Insurance Type Fields';
        const id = this.props.id ? this.props.id : this.props.match.params.id;


        const fieldsFinal = [];

        if (item) {
            let insEntityDescr = {};
            const actualInsIypeItem = getDictionaryItem(DictionaryTypes.InternalInsuranceType, item.actual_ins_type_cd);
            insEntityDescr = getEntityDescriptor(actualInsIypeItem.entityType);
            const customFields = (item && item.custom_fields) ? JSON.parse(item.custom_fields) : [];
            const customFieldsMap = {};
            customFields.forEach((f) => {
                customFieldsMap[f.f] = f;
            });


            if (customFields.length === 0) {
                Object.keys(insEntityDescr).forEach((i) => {
                    const originalField = insEntityDescr[i];
                    if (originalField && originalField.id) {
                        if (originalField.custom === false) return;
                        fieldsFinal.push({ id: originalField.id, origLabel: originalField.label, customLabel: originalField.label });
                    }
                });
            } else {
                customFields.forEach((customField) => {
                    const originalField = insEntityDescr[customField.f];
                    fieldsFinal.push({ id: customField.f, origLabel: originalField.label, customLabel: customField.l ? customField.l : originalField.label });
                });
            }
        }

        return (


            <div className={classes.root}>
                <LinearProgressEx loading={loading} />
                {item && !this.props.noEditBtn &&
                    <Button
                        color="primary"
                        className={classes.button}
                        onClick={() => { this.props.history.push(`/public_ins_type/${id}/edit_fields`); }}
                    >{getTxt('Add / Remove Fields')} </Button>
                }

                {!this.props.noCaption && <Typography variant="h4" color="inherit" noWrap={false}>{getTxt(caption)}</Typography>}

                {item &&

                    <Grid

                        rows={fieldsFinal}
                        columns={columns}

                    >

                        <SortingState
                            defaultSorting={[]}
                        />


                        <IntegratedSorting />

                        <Table
                            messages={tableMessages}
                            cellComponent={({ row, column, style, ...props }) => {
                                return <Table.Cell {...props} />;
                            }}
                        />

                        <TableHeaderRow showSortingControls />

                    </Grid>


                }

            </div>

        );
    }
}

export default compose(
    graphql(queries.typeByIdQuery, {
        options: props => (
            {
                variables: {
                    id: (!props.id ? (props.match.params.id ? props.match.params.id : 'new') : props.id),
                    customFields: true,
                },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }
        ),
    }),
)(withStyles(styles)(Custom_Fields_View));
