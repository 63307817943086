import gql from 'graphql-tag';

export const managementDashboardData = gql`

query managementDashboardData  {
    managementDashboardData  {
            clientsCount
               contractsCount
               prospectCount
               customersNoContracts
        },
    }
`;


export const operationalDashboardData = gql`

query operationalDashboardData  {
    operationalDashboardData  {
            receivedRequests
            expiryInNext30Days
            expiredPrev30DayNotPaid
            receivedClaims
            clientDocsexparyNext60Days
        },
    }
`;


export const updateStatisticsMutation = gql`

mutation updateStatisticsMutation($brokerId: Int!, $companyId: Int!, $contractItemId: Int  ) {
    updateStatisticsMutation(brokerId: $brokerId, companyId: $companyId, contractItemId: $contractItemId ) {
      id
      validation {
        field
        message
      }
    }
  }

  `;

