import React, { Component } from 'react';
import { getTxt } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import { Paper } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { AppBar } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { TextField } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';

import * as Icon from '@material-ui/icons';
import { Checkbox } from '@material-ui/core';

import { Button } from '@material-ui/core';
import moment from 'moment';
import { Badge } from '@material-ui/core';

import ComponentEx from 'components/ComponentEx';



import { LinearProgress } from '@material-ui/core';
import gql from 'graphql-tag';
import { graphql, compose } from 'react-apollo';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import { EntityTypes, getEntityDescriptor } from 'global/entityDescriptors';

import c from '../../../components/form/Components';
import LinearProgressEx from '../../../components/LinearProgressEx';
import { getLabel, DictionaryTypes, getDictionary, getValue } from '../../../global/dictionaryCache';
import MaterialForm from '../../../components/form/MaterialForm';
import MFormText from '../../../components/form/MFormText';
import { get_Requests_ById_Query, allRequestsQuery } from '../../../modules/request/requestQeries';


import GridEx from 'components/form/GridEx';
import RequestGuarantiesList from '../../../modules/request/RequestGuarantiesList';
import { ReportTypes } from '../../../global/reporting';

const styles = theme => ({
});


class Cose_View_Detail_Form extends ComponentEx {

    constructor(props) {
        super(props);
        this.generatePdf = this.generatePdf.bind(this);
        props.generatePdfFunc.func = this.generatePdf;
    }

    generatePdf() {
        const newWindow = window.open("", "_blank");
        newWindow.document.write(getTxt('Generating PDF, please wait...'));
        this.props.getOneTimeToken({

        }, this).then(
            (resp) => {

                if (resp.data && resp.data.oneTimeTokenMutation && resp.data.oneTimeTokenMutation.token) {
                    newWindow.location.href = `${window.apiUrl}files/reports?ott=${resp.data.oneTimeTokenMutation.token}&type=${ReportTypes.Cose_View_Details}&params=${btoa(JSON.stringify(window.requestDetailsPdfData))}`;
                    newWindow.focus();
                }

            });
    }

    state = {
        address_country_cd: undefined,
        address_province: undefined,
    };

    render() {



        const { classes } = this.props;

        const request = this.props.request;
        window.requestDetailsPdfData = [];
        return (

            <MaterialForm dataSource={request} objType={EntityTypes.InsuranceRequestCose} noFocusOnFirstCtrl spacing={2} fields={this.props.fields} >
                <GridEx item sm={3} xs={12}>
                    <MaterialForm spacing={12} dataSource={request} objType={EntityTypes.InsuranceRequestCose} noFocusOnFirstCtrl fields={this.props.fields} >
                        <c.group
                            pdf
                            l=""
                            fields={this.props.fields}
                            child={[
                                'piani_fuori_terra',
                                'numero_fabbricati',
                                'numero_unità_abitative',
                                'anno_di_costruzione',
                                'anno_di_ricostruzione',
                                'somma_assicurata',
                                'franchigia_frontale',
                                'quota_assicurata',
                                'tipo_fabbricato',
                                'indicizzazione',
                                'inizio_lavori',
                                'fine_lavori',
                                'decorrenza_polizza_decennale',
                                'committente',
                                'appaltatore',
                                'tipologia_lavori',
                                'descrizione_lavori',
                                'numero_contratto_appalto',
                                'costo_complessivo_opera',
                                'numero_questionario',
                                'ubicazione_del_rischio',
                                'valore_fabbricat',
                                'valore_contenuto',
                                'impianto_di_allarme',
                                'contratto_soggetto_regolazione',

                            ]}
                        />
                        <c.readOnlyInLine f="piani_fuori_terra" pdf />
                        <c.readOnlyInLine f="numero_fabbricati" pdf />
                        <c.readOnlyInLine f="numero_unita_abitative" pdf />
                        <c.readOnlyInLine f="anno_di_costruzione" pdf />
                        <c.readOnlyInLine f="anno_di_ricostruzione" pdf />
                        <c.readOnlyInLine f="somma_assicurata" pdf />
                        <c.readOnlyInLine f="franchigia_frontale" pdf />
                        <c.readOnlyInLine f="quota_assicurata" pdf />
                        <c.readOnlyInLine f="tipo_fabbricato" v={getLabel(DictionaryTypes.PropertyType, request.tipo_fabbricato)} pdf />
                        <c.readOnlyInLine f="indicizzazione" pdf />
                        <c.readOnlyInLine f="inizio_lavori" pdf />
                        <c.readOnlyInLine f="fine_lavori" pdf />
                        <c.readOnlyInLine f="decorrenza_polizza_decennale" pdf />
                        <c.readOnlyInLine f="committente" pdf />
                        <c.readOnlyInLine f="appaltatore" pdf />
                        <c.readOnlyInLine f="tipologia_lavori" v={getLabel(DictionaryTypes.WorkType, request.tipologia_lavori)} pdf />
                        <c.readOnlyInLine f="descrizione_lavori" pdf />
                        <c.readOnlyInLine f="numero_contratto_appalto" pdf />
                        <c.readOnlyInLine f="costo_complessivo_opera" pdf />
                        <c.readOnlyInLine f="numero_questionario" pdf />
                        <c.readOnlyInLine f="ubicazione_del_rischio" pdf />
                        <c.readOnlyInLine f="valore_fabbricat" pdf />
                        <c.readOnlyInLine f="valore_contenuto" pdf />
                        <c.readOnlyInLine f="impianto_di_allarme" pdf />
                        <c.readOnlyInLine f="contratto_soggetto_regolazione" pdf />


                    </MaterialForm>
                </GridEx>



                <GridEx item sm={3} xs={12}>
                    <MaterialForm spacing={12} dataSource={request} objType={EntityTypes.InsuranceRequestCausione} noFocusOnFirstCtrl fields={this.props.fields} >
                        <c.group
                            pdf
                            l="Informazioni Aggiuntive"
                            fields={this.props.fields}
                            child={[
                                'additional_information',
                                'comments',
                            ]}
                        />

                        <c.readOnlyLinkToCustomer f="co_obligated_customer" />
                        <c.readOnlyInLine f="authenticated_contract" pdf />
                        <c.readOnlyInLine f="digital_contract" pdf />
                        <c.readOnlyInLine f="ati_contract" pdf />
                        <c.readOnlyNewLine f="additional_information" multiline pdf />
                        <c.readOnlyNewLine f="comments" multiline pdf />
                    </MaterialForm>
                </GridEx>

            </MaterialForm>

        );
    }
}

export default withStyles(styles)(Cose_View_Detail_Form);
