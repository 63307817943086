import React, { Component } from 'react';
import { getTxt } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import { TextField } from '@material-ui/core';

import GridEx from 'components/form/GridEx';

import { Button } from '@material-ui/core';

import ComponentEx from 'components/ComponentEx';
import { EntityTypes, getEntityDescriptor } from 'global/entityDescriptors';

import { LinearProgress } from '@material-ui/core';
import { graphql, compose } from 'react-apollo';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';

import c from '../../../components/form/Components';
import LinearProgressEx from '../../../components/LinearProgressEx';
import { getLabel, DictionaryTypes, getDictionary, getValue } from '../../../global/dictionaryCache';
import MaterialForm from '../../../components/form/MaterialForm';
import { get_Requests_ById_Query, allRequestsQuery, requestBriefQuery, update_Request_Mutation } from '../../../modules/request/requestQeries';
import RequestFormButtons from '../../request/RequestFormButtons';

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing.unit * 2,
        margin: theme.spacing.unit * 2,
    },

});


class Other_Edit_Form extends ComponentEx {

    state = {
        address_country_cd: undefined,
        address_province: undefined,
    };

    render() {

        const { classes } = this.props;

        return (
            <MaterialForm
                dataSource={this.props.request}
                objType={EntityTypes.InsuranceRequestOther}
                onSubmit={this.props.onSubmit}
                onChange={this.props.fornChanged}
                fields={this.props.fields}
            >
                <c.group
                    l="Informazioni Aggiuntive"
                    fields={this.props.fields}
                    child={[
                        'additional_information',
                        'comments',
                    ]}
                />

                <c.field f="additional_information" multiline sm={12} />
                <c.field f="comments" multiline sm={12} />
                <RequestFormButtons
                    history={this.props.history}
                    transferToContract={this.props.transferToContract}
                    isNew={this.props.isNew}
                    noSave={this.props.oldContractId}
                />
            </MaterialForm>
        );
    }
}

export default withStyles(styles)(Other_Edit_Form);
