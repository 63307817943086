import React, { Component } from 'react';
import { getTxt, toDecimalString, toItalianDateString } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';
import { graphql, compose } from 'react-apollo';
import { Typography } from '@material-ui/core';
import gql from 'graphql-tag';
import { p } from 'components/form/Proto';
import $ from 'jquery';
import { HashRouter, BrowserRouter, Route, Link, withRouter } from 'react-router-dom';
import { Button } from '@material-ui/core';
import * as Icon from '@material-ui/icons';
import { authInfo, getToken } from 'global/auth';
import { ReportTypes } from 'global/reporting';
import ChartCard from 'components/dashboard/ChartCard';
import ChartistGraph from 'react-chartist';
var Chartist = require("chartist");
import {
    SortingState, SelectionState, FilteringState, GroupingState,
    IntegratedFiltering, IntegratedGrouping, IntegratedSorting, IntegratedSelection,
} from '@devexpress/dx-react-grid';
import classNames from 'classnames';
import {
    Grid,
    Table,
    VirtualTable, TableHeaderRow, TableFilterRow, TableSelection, TableGroupRow,
    GroupingPanel, DragDropProvider, TableColumnReordering, Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import LinearProgressEx from '../../components/LinearProgressEx';
import * as queries from '../../modules/contract/contractQueries';
import { getLabel, DictionaryTypes, getDictionary } from '../../global/dictionaryCache';
import RegularCard from '../../components/dashboard/RegularCard';
import MaterialForm from '../../components/form/MaterialForm';
import GridEx from 'components/form/GridEx';
import c from '../../components/form/Components';
import { } from '../../global/dictionaryCache';
import { addDescriptor } from '../../global/entityDescriptors';
import ComponentEx from '../../components/ComponentEx';
import { getReportDataQuery } from './reportQueries';
import DonutChart from 'react-donut-chart';

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
        borderWidth: '2px',
        borderColor: 'rgba(0, 0, 0, 0.2)',
        borderStyle: 'solid',
        overflow: 'auto',
        height: 500,
        //  margin: theme.spacing.unit * 2,
    },
    button: {
        margin: theme.spacing.unit,
    },
    formButtons: {
        float: 'right',
        margin: '15px',
        whiteSpace: 'normal',
    },

    badge: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        marginTop: 0,

    },
    gridRoot: {
        height: '100%',
    },

    tableHeader: {
        backgroundColor: theme.palette.background.paper,
    },
    leftIcon: {
        marginRight: theme.spacing.unit,
    },
    iconSmall: {
        fontSize: 20,
    },
    filterForemRoot: {
        paddingLeft: theme.spacing.unit * 2,
        paddingRight: theme.spacing.unit * 2,
    },
    totalsRow: {
        backgroundColor: theme.palette.grey['200'],
        fontWeight: 'bold',
    },
});


class CollaboratorePerformance_Report extends ComponentEx {

    constructor(props) {
        super(props);
        this.generateReport = this.generateReport.bind(this);
        this.formChanged = this.formChanged.bind(this);
        this.appplyFilters = this.appplyFilters.bind(this);
        this.replaceLegends = this.replaceLegends.bind(this);
    }

    state = {
        from: null,
        to: null,
        companyId: null,
    };

    formSetValues = { func: undefined };

    ROOT_ID = 'collab';
    cData = undefined;

    componentDidMount() {
        $('.donutchart').width(1000);
        $('.donutchart').attr('viewBox', '0 0 1000 500');

    }

    componentDidUpdate() {
        this.replaceLegends();
    }

    generateReport() {
        const newWindow = window.open("", "_blank");
        newWindow.document.write(getTxt('Generating PDF Report, please wait...'));
        this.props.getOneTimeToken({

        }, this).then(
            (resp) => {

                if (resp.data && resp.data.oneTimeTokenMutation && resp.data.oneTimeTokenMutation.token) {

                    newWindow.location.href = `${window.apiUrl}files/reports?ott=${resp.data.oneTimeTokenMutation.token}&type=${ReportTypes.ContractItemsReport}&params=${JSON.stringify(reportsFilters)}`;
                    newWindow.focus();
                }

            });
    }

    formChanged(field, value, type, prevVal) {
        reportsFilters[field] = value;
        const stateVal = {};
        this.state[field] = value;
    }

    appplyFilters() {
        this.props.data.refetch({
            reportType: 'claims',
            params: JSON.stringify({
                from: this.state.from,
                to: this.state.to,
                companyId: this.state.companyId,
            }),
        });
    }

    replaceLegends() {
        if (this.cData) {
            this.cData.forEach((i) => {
                const texts = $('text:contains(' + i.label + ')');
                for (let k = 0; k < texts.length; k += 1) {
                    const t = texts[k];
                    if (t.innerHTML && t.innerHTML.includes(i.value)) {
                        t.innerHTML = i.label + ' - ' + toDecimalString(i.value) + ' €';
                    }

                }
                
            });
        }
    }

    render() {

        const { classes } = this.props;
        const items = this.props.data.reportData ? JSON.parse(this.props.data.reportData) : [];

        const responsiveOptions = [
            ['screen and (min-width: 320px)', {
                chartPadding: 30,
                labelOffset: 75,
                labelDirection: 'explode',
                labelInterpolationFnc: (value) => {
                    return value;
                },
            }],
            ['screen and (min-width: 640px)', {
                chartPadding: 35,
                labelOffset: 70,
                labelDirection: 'explode',
                labelInterpolationFnc: (value) => {
                    return value;
                },
            }],
            ['screen and (min-width: 1024px)', {
                labelOffset: 75,
                chartPadding: 30,
            }],
        ];

        const chartOptions = {
            lineSmooth: Chartist.Interpolation.cardinal({
                tension: 0,
            }),
            low: 0,
            high: 6000,
            responsiveOptions,
        };


        const chartData = [

        ];

        const chart_colors = [

            '#e15746',
            '#f19f3e',
            '#63a059',
            '#59bbd1',
            '#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5', '#2196f3', '#03a9f4', '#00bcd4', '#009688', '#4caf50', '#8bc34a', '#cddc39', '#ffeb3b', '#ffc107', '#ff9800', '#ff5722', '#795548', '#607d8b'];

        let sum = 0;

        items.forEach((i) => {
            //const percent = Number.parseFloat(i.total);

            chartData.push(
                {
                    label: i.name,
                    value: roundNumber(i.total.toString(), 2),
                    isEmpty: i.total === 0.00,
                },
            );

        });


        let chartH;
        if (chartData.length > 15) {
            chartH = 770 * chartData.length / 15;
        }

        if (chartData.length >= 15) {
            const labelY = 45 * chartData.length / 40;
            $('#' + this.ROOT_ID).find('.donutchart-innertext-label').attr('y', labelY + '%');
            $('#' + this.ROOT_ID).find('.donutchart-innertext-value').attr('y', (labelY + 5) + '%');
        }

        this.cData = chartData;
        return (

            <div className={classes.root} id={this.ROOT_ID}>
                <LinearProgressEx loading={this.props.data.loading} />
                {!this.props.noCaption && <RegularCard

                    headerColor="red"
                    cardTitle={getTxt('Collaboratore Performance')}
                    cardSubtitle={''}
                    content={
                        <div>{''}</div>
                    }
                />}

                <DonutChart
                    data={chartData}
                    clickToggle={false}
                    colors={chart_colors}
                    strokeColor='#FFFFFF'
                    height={chartH}
                />

                <Typography variant="subheading" style={{ color: 'rgba(0, 0, 0, 0.38)' }} >{getTxt('Collaboratore Performance')}</Typography>

            </div>
        );
    }
}

function roundToTwo(num) {
    return +(Math.round(num + "e+2") + "e-2");
}

export function roundNumber(num, scale) {
    if (!("" + num).includes("e")) {
        return +(Math.round(num + "e+" + scale) + "e-" + scale);
    } else {
        var arr = ("" + num).split("e");
        var sig = ""
        if (+arr[1] + scale > 0) {
            sig = "+";
        }
        return +(Math.round(+arr[0] + "e" + sig + (+arr[1] + scale)) + "e-" + scale);
    }
}


export default compose(
    graphql(getReportDataQuery, {
        options: props => (
            {
                variables: { reportType: 'collaboratore-performance', params: JSON.stringify({}) },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }
        ),
    }),

)(withRouter(withStyles(styles)(CollaboratorePerformance_Report)));
