import gql from 'graphql-tag';

export const globalSettingsQuery = gql`
query globalSettingsQuery {
        globalSettings {
            settings_id
            new_customer_notification_subject
            new_customer_notification_body
       
        
    }
}
`;



export const updateSettingsMutation = gql`

mutation updateSettingsMutation ($settings: global_settings_input!) {
    updateSettings(settings: $settings){
        id
        validation {
            field
            message
        }
    } 
}

`;


