import React, { Component } from 'react';
import { getTxt } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';
import { withTheme } from '@material-ui/core';

import { Typography } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';



import { Paper } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { AppBar } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { TextField } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import gql from 'graphql-tag';
import { graphql, compose } from 'react-apollo';
import { Tabs, Tab } from '@material-ui/core';
import * as Icon from '@material-ui/icons';
import GridEx from 'components/form/GridEx';
import { Button } from '@material-ui/core';
import moment from 'moment';
import { Badge } from '@material-ui/core';

import ComponentEx from 'components/ComponentEx';



import { getCompanyQuery, allCompaniesQuery } from '../../modules/company/CompanyQueries';
import c from '../../components/form/Components';
import LinearProgressEx from '../../components/LinearProgressEx';
import { getLabel, DictionaryTypes, getDictionary, getValue } from '../../global/dictionaryCache';
import MaterialForm from '../../components/form/MaterialForm';
import MFormText from '../../components/form/MFormText';

import { Dialog } from '@material-ui/core';
import { DialogTitle } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { DialogContentText } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing.unit * 2,
        margin: theme.spacing.unit * 2,
    },

    button: {
        margin: theme.spacing.unit,
    },

    badge: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        marginTop: 0,
    },

    gridRoot: {
        height: '100%',
    },


    formCaption: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit * 2,
        width: '100%',
        position: 'relative',
        height: 40,
    },
    formCaptionLabel: {
        position: 'absolute',
        backgroundColor: 'transparent',
        left: 25,
        paddingLeft: 10,
        paddingRight: 10,
    },

    rootPaper: theme.mixins.gutters({

        paddingTop: 0,
        paddingBottom: 0,
        marginTop: 0,// theme.spacing.unit * 3,

    }),

});


class Company_Details_Edit extends ComponentEx {

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.formChanged = this.formChanged.bind(this);
    }

    state = {

        address_country_cd: undefined,
        address_province: undefined,
        invoice_address_country_cd: undefined,
        invoice_address_province: undefined,
        okDlg: false,
    };

    onSubmit(e, { obj, showErrors, hideErrors }) {

        obj.insurance_company_id = this.props.data.company.insurance_company_id;
        if (!obj.insurance_company_id) {
            obj.insurance_company_id = this.props.data.company.insurance_company_id;
        }


        this.props.updateCompany({
            variables: { company: obj },

            refetchQueries: [

                {
                    query: getCompanyQuery,
                    variables: { id: this.props.data.company.insurance_company_id },
                },
                {
                    query: allCompaniesQuery,
                },
            ],
        }, this).then(
            (resp) => {
                hideErrors();
                const serverErrors = resp.data.updateCompanyMutation.validation;
                const id = resp.data.updateCompanyMutation.id;

                if (serverErrors && serverErrors.length > 0) {
                    showErrors(serverErrors);
                } else {
                    if (!this.props.isProfile) {
                        this.props.history.push(`/company/view/${id}`);
                    } else {
                        this.setState({ okDlg: true });
                    }

                }
            });
    }

    formChanged(field, value, type, prevVal) {
        if (field === 'address_country_cd') {
            //const prevCountry = getValue(DictionaryTypes.Country, prevVal);
            const country = getValue(DictionaryTypes.Country, value);

            if (country) {
                this.setState({ address_country_cd: country });
            }

            // if (!country) {
            //     return;
            // }
            // if ((prevCountry !== 'IT' && country === 'IT') ||
            //     (prevCountry === 'IT' && country !== 'IT')) {
            //     this.setState({ address_country_cd: country });
            // }
        }
        if (field === 'address_province') {
            //const prevProv = getValue(DictionaryTypes.ItalianProvinces, prevVal);
            const prov = getValue(DictionaryTypes.ItalianProvinces, value);

            if (prov) {
                this.setState({ address_province: prov });
            }

            // if (!prov) {
            //     return;
            // }
            // if (prevProv !== prov) {
            //     this.setState({ address_province: prov });
            // }
        }
        if (field === 'invoice_address_country_cd') {
            //const prevCountry = getValue(DictionaryTypes.Country, prevVal);
            const country = getValue(DictionaryTypes.Country, value);

            if (country) {
                this.setState({ invoice_address_country_cd: country });
            }

            // if (!country) {
            //     return;
            // }
            // if ((prevCountry !== 'IT' && country === 'IT') ||
            //     (prevCountry === 'IT' && country !== 'IT')) {
            //         this.setState({ invoice_address_country_cd: country });
            // }
        }
        if (field === 'invoice_address_province') {

            //            const prevProv = getValue(DictionaryTypes.ItalianProvinces, prevVal);
            const prov = getValue(DictionaryTypes.ItalianProvinces, value);

            if (prov) {
                this.setState({ invoice_address_province: prov });
            }

            // if (!prov) {
            //     return;
            // }
            // if (prevProv !== prov) {
            //     this.setState({ invoice_address_province: prov });
            // }
        }
    }

    render() {

        const { classes } = this.props;
        const loading = this.props.data.loading;
        const company = this.props.data.company;

        let address_country_cd;
        let address_province;
        if (company) {
            address_country_cd = this.state.address_country_cd ? this.state.address_country_cd : company.address_country_cd;
            address_province = this.state.address_province ? this.state.address_province : company.address_province;
        }

        let invoice_address_country_cd;
        let invoice_address_province;
        if (company) {
            invoice_address_country_cd = this.state.invoice_address_country_cd ? this.state.invoice_address_country_cd : company.invoice_address_country_cd;
            invoice_address_province = this.state.invoice_address_province ? this.state.invoice_address_province : company.invoice_address_province;
        }

        let caption;

        if (this.props.match && (!this.props.match.params.id || this.props.match.params.id === 'new')) {
            caption = getTxt('Nuovo Company ');
        } else {
            caption = (!loading && company) ? getTxt('Modifica') + ' - ' + company.company_name : getTxt('Modifica Company');
        }
        if (this.props.isProfile) {
            caption = getTxt('Company Profile');
        }


        return (

            <div className={classes.root}>
                <Typography variant="h4" color="inherit" noWrap={false}>{caption}</Typography>
                <LinearProgressEx loading={loading} />
                {company &&

                    <MaterialForm dataSource={company} objType="company" onSubmit={this.onSubmit} onChange={this.formChanged}>

                        <c.group l={getTxt('Dati Compagnia')} />
                        <c.field l={getTxt('Codice Interno')} f="insurance_company_id" d />
                        <c.field l={getTxt('Ragione Sociale Compagnia')} f="company_name" />

                        <c.group l={getTxt('Dati per la Fatturazione')} d />

                        <c.field l={getTxt('Ragione Sociale di Fatturazione')} f="company_billing_name" />

                        <c.field
                            l={getTxt('Nazione')}
                            f="invoice_address_country_cd"
                            ui="auto-select"
                        >
                            {getDictionary(DictionaryTypes.Country)}
                        </c.field>
                        {invoice_address_country_cd === 'IT' &&
                            <c.field
                                l={getTxt('Province')}
                                f="invoice_address_province"
                                ui="auto-select"
                            >
                                {getDictionary(DictionaryTypes.ItalianProvinces)}
                            </c.field>}
                        {invoice_address_country_cd === 'IT' &&
                            <c.field
                                provinceId={invoice_address_province}
                                l={getTxt('Città')}
                                f="invoice_address_city"
                                ui="city-auto-select"
                            />}
                        {invoice_address_country_cd !== 'IT' &&
                            <c.field l={getTxt('Città')} f="invoice_address_city" />
                        }
                        <c.field l={getTxt('CAP')} f="invoice_address_cap" />
                        <c.field l={getTxt('Indirizzo')} f="invoice_address_street" />
                        <c.field l={getTxt('Civico')} f="address_street_number" />


                        <c.field l={getTxt('Partita iva')} f="vat_number" />
                        <c.field f="email_address" />
                        <c.field f="certified_email_address" />
                        <c.field f="phone_number" />
                        <c.field f="sdi_code" />

                        <c.group l={getTxt('Informazioni Aggiuntive')} d />


                        <c.field l={getTxt('IBAN')} f="bank_iban" ui="iban" />
                        <c.field l={getTxt('IBAN Secondario')} f="bank_iban_2" ui="iban" />
                        {/* <c.field l="Codicie Fiscale" f="fiscal_code" />      */}
                        <c.field
                            l={getTxt('Tipo di Rapporto')}
                            f="report_type_cd"
                            ui="select"
                            v=''
                        >
                            {getDictionary(DictionaryTypes.ReportType)}
                        </c.field>
                        <c.field l={getTxt('Codice Agenzia')} f="agency_code" />
                        <c.field l={getTxt('Codice Sub')} f="sub_code" />
                        <c.field
                            l={getTxt('Tipologia Rimessa')}
                            f="remittance_type_cd"
                            ui="select"
                            v=''
                        >
                            {getDictionary(DictionaryTypes.RemittanceType)}
                        </c.field>
                        {!this.props.isProfile && <c.field l={getTxt("Ritenuta d'acconto")} f="withholding_tax" />}

                        {!this.props.isProfile && <c.field l={getTxt('Costo Autentica')} f="authentic_cost" />}
                        {!this.props.isProfile && <c.field l={getTxt('Costo spedizione')} f="shipping_cost" />}
                        <c.field l={getTxt('Username SIC Ania')} f="username_SIC_ania" />
                        <c.field l={getTxt('Password SIC Ania')} f="password_SIC_ania" />
                        <c.field l={getTxt('Codice Ente SIC Ania')} f="code_SIC_ania" />
                        <c.field l={getTxt('Username Company App')} f="username_company_app" />
                        <c.field l={getTxt('Password Company App')} f="password_company_app" />


                        <c.group l={getTxt('Address')} />


                        <c.field
                            l={getTxt('Nazione')}
                            f="address_country_cd"
                            ui="auto-select"
                        >
                            {getDictionary(DictionaryTypes.Country)}
                        </c.field>
                        {address_country_cd === 'IT' &&
                            <c.field
                                l={getTxt('Province')}
                                f="address_province"
                                ui="auto-select"
                            >
                                {getDictionary(DictionaryTypes.ItalianProvinces)}
                            </c.field>}
                        {address_country_cd === 'IT' &&
                            <c.field
                                provinceId={address_province}
                                l={getTxt('Città')}
                                f="address_city"
                                ui="city-auto-select"
                            />}
                        {address_country_cd !== 'IT' &&
                            <c.field l={getTxt('Città')} f="address_city" />
                        }
                        <c.field l={getTxt('CAP')} f="address_cap" />

                        <c.field l={getTxt('Indirizzo')} f="address_street" />
                        <c.field l={getTxt('Civico')} f="invoice_address_street_number" />


                        <c.group l={getTxt('Informazioni Contatti')} />
                        <c.field l={getTxt('Telefono')} f="phone" />

                        <c.field l={getTxt('Cellulare')} f="mobile_phone" />
                        <c.field l={getTxt('Email')} f="email" />
                        <c.field l={getTxt('PEC')} f="email_pec" />










                        <c.formButtons sm={12} onCancel={() => { this.props.history.goBack(); }} />
                    </MaterialForm>


                }

                <Dialog
                    className={classes.dialog}
                    open={this.state.okDlg}
                    onClose={() => {
                        this.setState({ okDlg: undefined });
                    }}
                >
                    <DialogTitle> <span className={classes.dialogText}>{getTxt('Company Profile')}</span></DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <span className={classes.dialogText}>{getTxt('The company profile saved successfully.')}</span>
                        </DialogContentText>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { this.setState({ okDlg: false }); }} color="primary" >
                            {getTxt('Ok')}
                        </Button>

                    </DialogActions>
                </Dialog>



            </div>

        );
    }
}


const updateCompanyMutation = gql`

mutation updateCompanyMutation ($company: company_input!) {
    updateCompanyMutation(company: $company){
        id
        validation {
            field
            message
        }
    } 
}

`;

function getId(props) {
    if (!props.isProfile) {
        return { id: props.match.params.id ? props.match.params.id : -1 };
    }
    return -1;
}

export default compose(
    graphql(getCompanyQuery, {
        options: props => ({ variables: getId(props) }),
    }),
    graphql(updateCompanyMutation, {
        name: 'updateCompany',
        options: {
            errorPolicy: 'all',
        },
    }),
)(withStyles(styles)(Company_Details_Edit));
