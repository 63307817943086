import React, { Component } from 'react';
import gql from 'graphql-tag';
import { graphql, compose } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import $ from 'jquery';


import {
    SortingState, SelectionState, FilteringState, GroupingState,
    IntegratedFiltering, IntegratedGrouping, IntegratedSorting, IntegratedSelection,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    VirtualTable, TableHeaderRow, TableFilterRow, TableSelection, TableGroupRow,
    GroupingPanel, DragDropProvider, TableColumnReordering, Toolbar,
} from '@devexpress/dx-react-grid-material-ui';


import { HashRouter, BrowserRouter, Route, Link, withRouter } from 'react-router-dom';

import ComponentEx from 'components/ComponentEx';

import { TableCell } from '@material-ui/core';
import { Button } from '@material-ui/core';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';

//import * as Icon from '@material-ui/icons';

import c from 'components/form/Components';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { getTxt, tableMessages } from '../../global/appGlobal';
import LinkToCustomerById from '../../components/LinksToObjectById';
import LinearProgressEx from '../../components/LinearProgressEx';
import { getLabel, getDictionary, DictionaryTypes } from '../../global/dictionaryCache';
import * as queries from '../../modules/referent/referentQueries';
import * as customerQueries from '../../modules/client/clientQueries';

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    button: {
        margin: theme.spacing.unit,
    },

    tableHeader: {
        backgroundColor: theme.palette.background.paper,
    },
});

class Referent_Search extends ComponentEx {

    state = {
        delFileDlgOpen: false,
        delId: undefined,
    };

    componentWillMount() {
        if (!this.props.data.loading) {
            this.props.data.refetch();
        }

    }

    handleDelete(id) {

        this.props.deleteReferent({
            variables: { id },
            refetchQueries: [
                {
                    query: queries.allReferentsQuery,
                    variables: { parentId: this.props.parentId },
                },
                {
                    query: customerQueries.customerBriefQuery,
                    variables: { id: this.props.parentId },
                },

            ],
        });
        this.setState({ delFileDlgOpen: false });
    }

    render() {
        const { classes } = this.props;

        const items = this.props.data.allReferents ? this.props.data.allReferents : [];

        const columns = [
            { name: 'display_name', title: getTxt('Name', '#oqzypcx') },
            { name: 'job_title', title: getTxt('Job Title', '#5kg614p') },
            { name: 'email', title: getTxt('email', '#45yn0hd') },
            { name: 'mobile_phone', title: getTxt('Mobile Phone', '#err8c2g') },
            { name: 'phone', title: getTxt('Phone', '#err8c2g') },
            { name: 'referent_id', title: getTxt('Azioni', '#txux93z') },
        ];

        return (

            <div className={classes.root}>
                <LinearProgressEx loading={this.props.data.loading} />
                {!this.props.noAddBtn &&
                    <Button
                        color="primary"
                        className={classes.button}
                        onClick={() => { this.props.history.push(`/referents/edit/${this.props.parentId}/new`); }}
                    >
                        Aggiungi nuova Referente
                    </Button>
                }

                <Grid
                    rows={items}
                    columns={columns}
                >
                    <SortingState
                        defaultSorting={[]}
                    />
                    <IntegratedSorting />

                    <VirtualTable
                        messages={tableMessages}
                        cellComponent={({ row, column, style, ...props }) => {
                            if (column.name === 'display_name') {
                                return (<VirtualTable.Cell> <Link to={`/referents/edit/${row.parent_id}/${row.referent_id}`} >{row.display_name}</Link> </VirtualTable.Cell>);
                            }
                            if (column.name === 'referent_id') {
                                return (<VirtualTable.Cell>

                                    <IconButton size="small" onClick={() => { this.setState({ delFileDlgOpen: true, delId: row.referent_id }); }} className={classes.inGridButton} >
                                        <DeleteIcon />
                                    </IconButton>


                                </VirtualTable.Cell>);
                            }


                            return <VirtualTable.Cell {...props} />;
                        }}
                    />

                    <TableHeaderRow showSortingControls />

                </Grid>
                <Dialog onClose={() => { this.setState({ delFileDlgOpen: false }); }} open={this.state.delFileDlgOpen}>
                    <DialogTitle >Cancella referente.</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <span> Il referente sarà cancellato sul server. </span>
                            <br />
                            <span > Sei sicuro di voler cancellare il referente? </span>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleDelete(this.state.delId)} color="primary">
                            Ok
                        </Button>
                        <Button onClick={() => { this.setState({ delFileDlgOpen: false }); }} color="primary">
                            Annula
                        </Button>

                    </DialogActions>
                </Dialog>
            </div >
        );
    }
}

export default compose(
    graphql(queries.allReferentsQuery, {
        options: {
            options: props => (
                {
                    variables: { parentId: props.match.params.parentId ? props.match.params.parentId : props.parentId },
                    fetchPolicy: 'network-only',
                    errorPolicy: 'all',
                }),
        },
    }),
    graphql(queries.deleteReferentMutation, {
        name: 'deleteReferent',
        options: {
            errorPolicy: 'all',
        },
    }),
)(withStyles(styles)(Referent_Search));

