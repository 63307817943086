// ##############################
// // // Tasks for TasksCard - see Dashboard view
// #############################

var bugs = [
  '2018-05-31 10:00 Broker "Antonio Grasso" created Quote for Request #1234',
  '2018-05-31 09:48 Broker "Antonio Grasso" created Ins. Request #465654',  
  '2018-05-31 09:40 Broker "Corrado" updated Movimento #1234',
  '2018-05-31 09:35 New Client "Jacob Galkin" registered',
];
var website = [
  '2018-05-31 10:00 Broker "Antonio Grasso" created Quote for Request #1234',
  '2018-05-31 09:48 Broker "Antonio Grasso" created Ins. Request #465654',  
  '2018-05-31 09:40 Broker "Corrado" updated Movimento #1234',

];
var server = [
  '2018-05-31 09:35 New Client "Jacob Galkin" registered',

];

module.exports = {
  // these 3 are used to create the tasks lists in TasksCard - Dashboard view
  bugs,
  website,
  server
};
