/* eslint-disable react/no-multi-comp */
import React, { Component } from 'react';
import { getTxt, randomString } from 'global/appGlobal';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
//import FormField from 'components/form/FormField';
import GridEx from 'components/form/GridEx';
import { MenuItem } from '@material-ui/core';
import { Button } from '@material-ui/core';
import {
    FormLabel,
    FormControl,
    FormGroup,
    FormControlLabel,
    FormHelperText,
} from '@material-ui/core';
import { Checkbox } from '@material-ui/core';
import { Input, InputLabel } from '@material-ui/core';
import FormCaption from 'components/form/FormCaption';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import $ from 'jquery';
import { EntityTypes, getEntityDescriptor } from 'global/entityDescriptors';
import schema from 'async-validator';

import  { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

import { Select } from '@material-ui/core';
import { HashRouter, BrowserRouter, Route, Link, withRouter } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';

const styles = theme => ({
    formRoot: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        width: '100%',
    },

});

function convertToObject(value, propType) {
    switch (propType) {
        case 'STRING':
            return value;
        case 'INTEGER':
            if (value !== null && value !== undefined && value.trim) {
                const trimmed = value.trim();
                if (trimmed === '') {
                    return null;
                }
            }
            if (!value) {
                return value;
            }
            return Number.parseInt(value, 10);
        case 'DATEONLY':
            if (!value || value === '') {
                return value;
            }

            const d = moment(value).toDate();
            //d.setMinutes(d.getMinutes() + d.getTimezoneOffset());
            return new Date(d.getTime() - d.getTimezoneOffset() * 60000).toISOString();
        case 'DATE':
            if (!value || value === '') {
                return value;
            }

            const d2 = moment(value).toDate();

            return new Date(d2.getTime() - d2.getTimezoneOffset() * 60000).toISOString();
        case 'BOOLEAN':
            return value;
        case 'DECIMAL':
            return Number.parseFloat(value);
        default: return value;
    }
}

function convertToValidator(value, propType) {
    switch (propType) {
        case 'INTEGER':
            if (value !== null && value !== undefined && value.trim) {
                const trimmed = value.trim();
                if (trimmed === '') {
                    return null;
                }
            }
            if (!value) {
                return value;
            }
            return Number.parseInt(value, 10);
        case 'DECIMAL':
            if (!value || value === '') {
                return value;
            }
            return Number.parseFloat(value);
        default: return value;
    }
}

class MaterialForm extends Component {

    constructor(props) {
        super(props);
        this.formId = randomString(5, 'aA');
        this.childControls = {};
        this.childControlsArray = [];
        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.showServerErrors = this.showServerErrors.bind(this);
        this.hideErrors = this.hideErrors.bind(this);
        this.registerChildControl = this.registerChildControl.bind(this);
        this.typeDescriptor = getEntityDescriptor(this.props.objType);
        this.unRegisterControl = this.unRegisterControl.bind(this);
        this.getValues = this.getValues.bind(this);
        this.setValues = this.setValues.bind(this);
        this.fields = { all: [], byId: {} };
        if (props.fields && props.fields.length && props.fields.length > 0) {
            this.fields.all = props.fields;
            props.fields.forEach((i) => {
                if (i.f) {
                    this.fields.byId[i.f] = i;
                }
            });
        }
    }

    onChange(propName, value, propType, prevValue) {
        const type = this.typeDescriptor[propName] ? this.typeDescriptor[propName].type : propType;
        const converted = convertToObject(value, type);

        const control = this.childControls[propName];
        control.hideErrors();
        const errs = this.validate(propName, convertToValidator(value, type));
        if (errs && errs.length > 0) {
            control.showErrors(errs);
        }
        if (this.props.onChange) {
            this.props.onChange(propName, converted, propType, prevValue);
        }
    }

    getValues() {
        const values = {};
        Object.keys(this.childControls).forEach((prop) => {
            if (this.childControls[prop].getValue) {
                values[prop] = this.childControls[prop].getValue();
            }
        });
        return values;
    }

    setValues(values, options) {
        Object.keys(values).forEach((prop) => {
            if (this.childControls[prop] && this.childControls[prop].setValue) {
                this.childControls[prop].setValue(values[prop], options);
            }
        }, this);
    }

    unRegisterControl(propName, id) {
        const existing = this.childControls[propName];
        if (existing && existing.id === id) {
            delete this.childControls[propName];
            this.childControlsArray = this.childControlsArray.filter(c => c.propName !== propName);
        }
    }

    getChildContext() {
        return {
            onChange: this.onChange,
            registerControl: this.registerChildControl,
            objType: this.props.objType,
            dataSource: this.props.dataSource,
            unRegisterControl: this.unRegisterControl,
            fields: this.fields,
        };
    }

    validate(propName, value) {
        if (!this.typeDescriptor[propName] ||
            !this.typeDescriptor[propName].validators ||
            this.typeDescriptor[propName].validators.length === 0) {
            return [];
        }

        const validator = new schema({ value: this.typeDescriptor[propName].validators });
        let errs;
        validator.validate({ value }, (errors) => {
            errs = errors;
        });
        return errs;
    }

    onSubmit(e) {
        e.preventDefault();
        let invalidControls = [];
        const changedProps = {};
        Object.keys(this.childControls).forEach((propName) => {
            const control = this.childControls[propName];
            control.hideErrors();

            const val = convertToObject(control.getValue(), control.type);
            if (control.isDirty()) {
                changedProps[propName] = val;
            }

            const errs = this.validate(propName, convertToValidator(control.getValue(), control.type));
            // if (control.type !== 'DATEONLY') {
            //     errs = this.validate(propName, convertToValidator(val, control.type));
            // } else {
            //     errs = this.validate(propName, convertToValidator(control.getValue(), control.type));
            // }
            if (errs && errs.length > 0) {
                invalidControls.push(control);
                control.showErrors(errs);
            }

        }, this);

        if (invalidControls.length > 0) {
            invalidControls[0].focus();
            return;
        }

        if (this.props.onSubmit) {
            this.props.onSubmit(e, {
                obj: changedProps,
                showErrors: this.showServerErrors,
                hideErrors: this.hideErrors,
            });
        }
    }

    showServerErrors(erros) {
        Object.keys(this.childControls).forEach((propName) => {
            const control = this.childControls[propName];
            if (control) {
                control.hideErrors();
                const controlErrs = erros.filter(e => e.field === propName);
                if (controlErrs.length > 0) {
                    control.showErrors(controlErrs);
                    control.focus();
                }
            }
        });

        const firstWithErrors = this.childControlsArray.find(c => c.hasErrors() === false);
        if (firstWithErrors) firstWithErrors.focus();
    }

    hideErrors() {
        Object.keys(this.childControls).forEach((propName) => {
            const control = this[propName];
            if (control) {
                control.hideErrors();
            }

        }, this);
    }

    registerChildControl(propName, ctrlDescr) {
        this.childControls[propName] = ctrlDescr;
        ctrlDescr.propName = propName;
        if (this.childControlsArray.find(c => c.propName === propName)) {
            this.childControlsArray.forEach((c, index) => {
                if (c.propName === propName) {
                    this.childControlsArray[index] = ctrlDescr;
                }
            });
        } else {
            this.childControlsArray.push(ctrlDescr);
        }

        return this.onChange;
    }

    componentDidMount() {
        if (!this.props.noFocusOnFirstCtrl) {
            $(`#${this.formId} :input:enabled:visible:first`).focus();
        }
    }

    render() {
        const { classes } = this.props;

        if (this.props.getValues) {
            this.props.getValues.func = this.getValues;
        }
        if (this.props.setValues) {
            this.props.setValues.func = this.setValues;
        }

        return (
            <div className={classes.formRoot}>
                {this.props.l && (<FormCaption >  {getTxt(this.props.en || this.props.l, this.props.it)}  </FormCaption>)}
                <form onSubmit={this.onSubmit} id={this.formId} noValidate>
                    <Grid container spacing={this.props.spacing || 3} >
                        {this.props.children}
                    </Grid>
                </form>
            </div>
        );
    }

}

export default withStyles(styles, { withTheme: true })(MaterialForm);

MaterialForm.childContextTypes = {
    onChange: PropTypes.func,
    registerControl: PropTypes.func,
    objType: PropTypes.string,
    dataSource: PropTypes.object,
    unRegisterControl: PropTypes.func,
    fields: PropTypes.Object,
};

