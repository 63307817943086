import gql from 'graphql-tag';

export const allGuarantyTypesQuery = gql`

query allGuarantyTypesQuery ($insurance_type_cd: String)  {
    allGuarantyTypes (insurance_type_cd: $insurance_type_cd) {
        guaranty_type_id
      insurance_type_cd
      guaranty_name
      used_by_default
      is_financial
      default_franchise_amount
      default_maximum_amount
      default_overdraft_amount
    }
}
`;

export const guarantyTypeByIdQuery = gql`

query guarantyTypeByIdQuery($id: ID!) {
    guarantyTypeById(id: $id) {
        guaranty_type_id
    insurance_type_cd
      guaranty_name
      used_by_default
      is_financial
      default_franchise_amount
      default_maximum_amount
      default_overdraft_amount
    }
  }
  

`;

export const updateGuarantyTypeMutation = gql`

mutation updateGuarantyTypeMutation($guarantyType: guaranty_type_input!) {
    updateGuarantyTypeMutation(guarantyType: $guarantyType) {
      id
      validation {
        field
        message
      }
    }
  }

  `;


export const deleteGuarantyTypeMutation = gql`

mutation deleteGuarantyTypeMutation ($id: ID!) {
    deleteGuarantyTypeMutation(id: $id){
        id
        validation {
            field
            message
        }
    } 
}

`;









export const contractGuarantyByIdQuery = gql`

query contractGuarantyByIdQuery($id: ID!) {
    contractGuarantyById(id: $id) {
        contract_guaranty_id
        insurance_request_id
        guaranty_type_id
        franchise_amount
        overdraft_amount
        maximum_amount
        net_amount
        ssn_percent
        broker_percent
        agency_percent
    }
  }
  
`;