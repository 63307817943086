import React, { Component } from 'react';
import { getTxt, toItalianDateString } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';
import { graphql, compose } from 'react-apollo';

import $ from 'jquery';
import { Checkbox } from '@material-ui/core';
import { IconButton } from '@material-ui/core';

import { Button } from '@material-ui/core';
import GridEx from 'components/form/GridEx';
import { HashRouter, BrowserRouter, Route, Link, withRouter } from 'react-router-dom';
import {
    SortingState, SelectionState, FilteringState, GroupingState,
    IntegratedFiltering, IntegratedGrouping, IntegratedSorting, IntegratedSelection,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    VirtualTable, TableHeaderRow, TableFilterRow, TableSelection, TableGroupRow,
    GroupingPanel, DragDropProvider, TableColumnReordering, Toolbar, TableColumnResizing,
} from '@devexpress/dx-react-grid-material-ui';
import LinearProgressEx from '../../../components/LinearProgressEx';
import * as queries from '../../../modules/contract/claim/claimQueries';
import { getLabel, DictionaryTypes, getDictionary } from '../../../global/dictionaryCache';
import RegularCard from '../../../components/dashboard/RegularCard';
import ComponentEx from '../../../components/ComponentEx';
import { customerContractsCountQuery } from '../contractQueries';
import { authInfo } from '../../../global/auth';

import { DialogContentText } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import c from '../../../components/form/Components';
import { filterRowMessages, createGridResizerCols, toItalianDateString2 } from '../../../global/appGlobal';



const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        margin: theme.spacing.unit * 3,
    },
    button: {
        margin: theme.spacing.unit,
    },

    badge: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        marginTop: 0,

    },
    gridRoot: {
        height: '100%',
    },

    tableHeader: {
        backgroundColor: theme.palette.background.paper,
    },
});

const toLowerCase = value => String(value).toLowerCase();

const filterContractCustomer = (row, e, a) => {

    const name = toLowerCase(a.contract.customer.display_name);
    return name.includes(toLowerCase(e.value));
};

class Claim_Search extends ComponentEx {

    state = {
        customerContractsCount: false,
    };

    render() {

        if (this.props.quiet) {
            return (<LinearProgressEx loading={this.props.data.loading} />);
        }

        const columns = [
            { name: 'number', title: getTxt('Numero di Sinistro') },
            { name: 'insurance_contract_id', title: getTxt('Contract') },
            { name: 'contract', title: getTxt('Contraente') },
            { name: 'counterpart_description', title: getTxt('Controparte') },
            { name: 'incident_date_time', title: getTxt('Incident Date Time') },
            { name: 'description', title: getTxt('Description') },
            { name: 'status', title: getTxt('Status') },
            { name: 'responsibility_type', title: getTxt('Tipo di Responsabilità') },
            

        ];
        createGridResizerCols(this.state, columns, {}, 280);

        const { classes } = this.props;
        let items = (this.props.data.claims && this.props.data.claims.claims) ? this.props.data.claims.claims : [];

        if ((this.props.customerId || authInfo.isCustomer()) &&
            (this.props.data.claims && this.props.data.claims.customerContractCount === 0)) {
            this.state.customerContractsCount = true;
        } else {
            this.state.customerContractsCount = false;
        }

        let caption = getTxt('Claims');
        const subtitle = getTxt('List of claims.');
        if (this.props.match.params.filter === 'received') {
            //     items = items.filter(i => i.status === 'RICEVUTO');
            caption = getTxt('Received Claims');
        }

        return (

            <div className={classes.root}>
                <LinearProgressEx loading={this.props.data.loading} />
                {!this.props.noCaption && <RegularCard

                    headerColor="red"
                    cardTitle={caption}
                    cardSubtitle={subtitle}
                    content={
                        <div>

                        </div>

                    }
                />}
                {this.props.showAddBtn && <Button
                    color="primary"
                    className={classes.button}
                    onClick={() => {
                        this.props.history.push(`/contract/${this.props.contractId}/claim/new`);
                    }}
                >
                    {getTxt('Add Claim')}
                </Button>}
                <Grid

                    rows={items}
                    columns={columns}

                >
                    <FilteringState
                        defaultFilters={[{}]}
                    />

                    <SortingState
                        defaultSorting={[
                            {},
                        ]}
                    />

                    <IntegratedFiltering columnExtensions={
                        [
                            { columnName: 'contract', predicate: filterContractCustomer },
                        ]}
                    />
                    <IntegratedSorting />

                    <VirtualTable
                        messages={{ noData: this.state.customerContractsCount ? getTxt('Nessuna polizza per il cliente') : getTxt('No Data') }}
                        cellComponent={({ row, column, style, ...props }) => {

                            if (column.name === 'number') {
                                return (
                                    <VirtualTable.Cell>
                                        {row.number &&
                                            <Link to={`/contract/${row.insurance_contract_id}/claim/view/${row.claim_id}`} style={{ padding: '6px', color: '#9C27B0', fontWeight: 'bold' }} >{row.number}</Link>
                                        }
                                    </VirtualTable.Cell>);
                            }
                            if (column.name === 'insurance_contract_id') {
                                return (
                                    <VirtualTable.Cell>
                                        {row.number &&
                                            <Link to={`/contract/view/${row.contract.insurance_type_cd}/${row.contract.insurance_contract_id}`} > {row.contract.contract_number}</Link>
                                        }
                                    </VirtualTable.Cell>);
                            }
                            if (column.name === 'incident_date_time') {
                                return (
                                    <VirtualTable.Cell>
                                        {row.incident_date_time && toItalianDateString2(row.incident_date_time)}
                                    </VirtualTable.Cell>);
                            }
                            if (column.name === 'status') {
                                return (
                                    <VirtualTable.Cell>
                                        {row.status && getLabel(DictionaryTypes.ClaimStatus, row.status)}
                                    </VirtualTable.Cell>);
                            }
                            if (column.name === 'responsibility_type') {
                                return (
                                    <VirtualTable.Cell>
                                        {row.responsibility_type && getLabel(DictionaryTypes.ResponsibilityType, row.responsibility_type)}
                                    </VirtualTable.Cell>);
                            }
                            if (column.name === 'contract') {
                                if (row.customer_type_cd === 'PRIVATE_PROSPECT') {
                                    return (<VirtualTable.Cell> <Link to={`/customers/prospect/private/${row.contract.customer.customer_id}`} style={{ padding: '6px', color: '#9C27B0', fontWeight: 'bold' }} >{row.contract.customer.display_name}</Link> </VirtualTable.Cell>);
                                }
                                return (<VirtualTable.Cell > <Link to={`/customers/${row.contract.customer.customer_type_cd.toLowerCase()}/${row.contract.customer.customer_id}`} style={{ padding: '6px', color: '#9C27B0', fontWeight: 'bold' }} >
                                    {row.contract.customer.display_name} </Link> </VirtualTable.Cell>);
                            }

                            return <VirtualTable.Cell {...props} />;
                        }}
                    />

                    <TableFilterRow
                        messages={filterRowMessages}
                        cellComponent={({ column, filter, onFilter, ...props }) => {
                            if (column.name === 'status') {
                                return (
                                    <c.gridColFilter noFilterVal={getTxt('Tutti')} setFilter={onFilter} >
                                        {getDictionary(DictionaryTypes.ClaimStatus)}
                                    </c.gridColFilter>);
                            }
                            if (column.name === 'responsibility_type') {
                                return (
                                    <c.gridColFilter noFilterVal={getTxt('Tutti')} setFilter={onFilter} >
                                        {getDictionary(DictionaryTypes.ResponsibilityType)}
                                    </c.gridColFilter>);
                            }
                            return <TableFilterRow.Cell onFilter={onFilter} filter={filter} {...props} />;
                        }}
                    />

                    <TableColumnResizing
                        columnWidths={this.state.columnWidths}
                        onColumnWidthsChange={(cols) => { this.setState({ columnWidths: cols }); }}
                        defaultColumnWidths={this.state.defColumnWidths}
                    />
                    <TableHeaderRow showSortingControls />

                </Grid>



            </div>
        );
    }
}

export default compose(

    graphql(queries.allClaimsQuery, {
        options: props => (
            {
                variables: {
                    contractId: props.contractId,
                    customerId: props.customerId,
                    filter: props.match ? props.match.params.filter : undefined,
                },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }
        ),
    }),
)(withRouter(withStyles(styles)(Claim_Search)));
