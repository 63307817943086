import React, { Component } from 'react';
import { getTxt } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import { Paper } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { AppBar } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { TextField } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';

import * as Icon from '@material-ui/icons';
import { Checkbox } from '@material-ui/core';

import { Button } from '@material-ui/core';
import moment from 'moment';
import { Badge } from '@material-ui/core';

import ComponentEx from 'components/ComponentEx';

import { p } from 'components/form/Proto';

import { LinearProgress } from '@material-ui/core';
import gql from 'graphql-tag';
import { graphql, compose } from 'react-apollo';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import { EntityTypes, getEntityDescriptor } from 'global/entityDescriptors';

import c from '../../../components/form/Components';
import LinearProgressEx from '../../../components/LinearProgressEx';
import { getLabel, DictionaryTypes, getDictionary, getValue } from '../../../global/dictionaryCache';
import MaterialForm from '../../../components/form/MaterialForm';
import MFormText from '../../../components/form/MFormText';
import { get_Requests_ById_Query, allRequestsQuery } from '../../../modules/request/requestQeries';


import GridEx from 'components/form/GridEx';
import RequestGuarantiesList from '../../../modules/request/RequestGuarantiesList';
import { ReportTypes } from '../../../global/reporting';

const styles = theme => ({
});


class Cauzione_View_Detail_Form extends ComponentEx {

    state = {
        address_country_cd: undefined,
        address_province: undefined,
    };

    render() {

        const { classes } = this.props;

        const request = this.props.request;

        let address_country_cd;
        if (request) {
            address_country_cd = this.state.address_country_cd ? this.state.address_country_cd : request.address_country_cd;
        }
        
        window.requestDetailsPdfData = [];

        return (

            <MaterialForm dataSource={request} objType={EntityTypes.InsuranceRequestCausione} noFocusOnFirstCtrl spacing={2} fields={this.props.fields} >
                <GridEx item sm={3} xs={12}>
                    <MaterialForm spacing={12} dataSource={request} objType={EntityTypes.InsuranceRequestCausione} noFocusOnFirstCtrl fields={this.props.fields} >
                        <c.group
                            pdf
                            l="Stazione Appaltante / Beneficiario"
                            fields={this.props.fields}
                            child={[
                                'beneficiary_name',
                                'beneficiary_fiscal_code',
                                'born_date',
                                'citizenship',
                                'passport_number',
                                'issue_date',
                                'expiration_date',
                                'visa_number',
                                'address_country_cd',
                                'address_province',
                                'address_city',
                                'address_cap',
                                'address_street',
                                'address_street_number',

                            ]}
                        />
                        <c.readOnlyInLine f="beneficiary_name" pdf />
                        <c.readOnlyInLine f="beneficiary_fiscal_code" pdf />
                        <c.readOnlyInLine f="born_date" pdf />
                        <c.readOnlyInLine f="citizenship" pdf />
                        <c.readOnlyInLine f="passport_number" pdf />
                        <c.readOnlyInLine f="issue_date" pdf />
                        <c.readOnlyInLine f="expiration_date" pdf />
                        <c.readOnlyInLine f="visa_number" pdf />

                        <c.readOnlyInLine l="Nazione" f="address_country_cd" v={getLabel(DictionaryTypes.Country, request.address_country_cd)} pdf />
                        {address_country_cd === 'IT' &&
                            <c.readOnlyInLine l={getTxt('Province')} f="address_province" v={getLabel(DictionaryTypes.ItalianProvinces, request.address_province)} pdf />
                        }
                        {address_country_cd !== 'IT' &&
                            <c.readOnlyInLine l={getTxt('Province')} f="address_province" pdf />
                        }
                        <c.readOnlyInLine l="Città" f="address_city" d pdf />
                        <c.readOnlyInLine l="CAP" f="address_cap" d pdf />
                        <c.readOnlyInLine l="Indirizzo" f="address_street" d pdf />
                        <c.readOnlyInLine l="Civico" f="address_street_number" d pdf />


                    </MaterialForm>
                </GridEx>

                <GridEx item sm={3} xs={12}>
                    <MaterialForm spacing={12} dataSource={request} objType={EntityTypes.InsuranceRequestCausione} noFocusOnFirstCtrl fields={this.props.fields} >
                        <c.group
                            pdf
                            l="Dati Garanzia"
                            fields={this.props.fields}
                            child={[
                                'cig_number',
                                'tender_start_date',
                                'concessione_date',
                                'proposal_date',
                                'guarantee_duration_days',
                                'guarantee_expiration',
                                'execution_place',
                                'work_total_amount',
                                'discount_percentage',
                                'guaranteed_percentage',
                                'guaranteed_amount',
                                'work_description',
                            ]}
                        />

                        <c.readOnlyInLine f="cig_number" pdf />
                        <c.readOnlyInLine f="tender_start_date" pdf />
                        <c.readOnlyInLine f="concessione_date" pdf />
                        <c.readOnlyInLine f="proposal_date" pdf />
                        <c.readOnlyInLine f="guarantee_duration_days" d pdf />
                        <c.readOnlyInLine f="guarantee_expiration" d pdf />
                        <c.readOnlyInLine f="execution_place" pdf />
                        <c.readOnlyInLine f="work_total_amount" pdf />
                        <c.readOnlyInLine f="discount_percentage" pdf />
                        <c.readOnlyInLine f="guaranteed_percentage" pdf />
                        <c.readOnlyInLine f="guaranteed_amount" pdf />
                        <c.readOnlyNewLine f="work_description" multiline pdf />

                    </MaterialForm>
                </GridEx>

                <GridEx item sm={3} xs={12}>
                    <MaterialForm spacing={12} dataSource={request} objType={EntityTypes.InsuranceRequestCausione} noFocusOnFirstCtrl fields={this.props.fields} >
                        <c.group
                            pdf
                            l="Informazioni Aggiuntive"
                            fields={this.props.fields}
                            child={[
                                'co_obligated_customer',
                                'authenticated_contract',
                                'digital_contract',
                                'ati_contract',
                                'additional_information',
                                'comments',
                            ]}
                        />

                        <c.readOnlyLinkToCustomer f="co_obligated_customer" pdf />
                        <c.readOnlyInLine f="authenticated_contract" pdf />
                        <c.readOnlyInLine f="digital_contract" pdf />
                        <c.readOnlyInLine f="ati_contract" stringValue pdf />
                        <c.readOnlyNewLine f="additional_information" multiline pdf />
                        <c.readOnlyNewLine f="comments" multiline pdf />
                    </MaterialForm>
                </GridEx>

            </MaterialForm>

        );
    }
}

export default withStyles(styles)(Cauzione_View_Detail_Form);
