import { FilteringState, IntegratedFiltering, IntegratedSorting, SortingState } from '@devexpress/dx-react-grid';
import { Grid, Table, TableFilterRow, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import ComponentEx from 'components/ComponentEx';
import c from 'components/form/Components';
import { getToken } from 'global/auth';
import $ from 'jquery';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/Delete';
import { Button } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { compose, graphql } from 'react-apollo';
import { Link } from 'react-router-dom';
import LinearProgressEx from '../../components/LinearProgressEx';
import { formatBytes, getConfigValue, getTxt, randomString, filterRowMessages } from '../../global/appGlobal';
import { DictionaryTypes, getDictionary, getLabel } from '../../global/dictionaryCache';
import { Tooltip } from '@material-ui/core';
import * as Icon from '@material-ui/icons';
import { reportLogoFilesQuery, updateReportLogoFileMutation, uploadNextReportLogoChunkMutation, deleteReportLogoFileMutation } from './reportQueries';

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,

    },
    button: {
        margin: theme.spacing.unit,
    },

    tableHeader: {
        backgroundColor: theme.palette.background.paper,
    },
    dialogText: {
        color: theme.palette.error.main,
    },
    uplPercent: {
        nextPosition: 'absolute',
        height: '6px',
        width: '100%',
        border: 'solid 1px',
    },
    uplPercentBackgr: {
        height: '6px',
        width: '0px',
        backgroundColor: 'green',
    },
    inGridButton: {
        width: 36,
        height: 30,
        marginRight: theme.spacing.unit * 2,
    },
    inGridLink: {
        width: 36,
        height: 30,
        marginTop: theme.spacing.unit * 2,
    },
    dragNote: {
        color: theme.palette.text.secondary,
    },
});

function getFileExtension(fileName) {
    let dotIndex;
    for (let i = fileName.length - 1; i >= 0; i -= 1) {
        if (fileName[i] === '.') {
            dotIndex = i;
        }
    }
    if (!dotIndex) return '';
    return fileName.substring(dotIndex);
}

function showUploadProgress(id, percentComplete) {
    const fullW = $(`#uplPercent${id}`).width();
    if (fullW && fullW > 0) {
        return (fullW * percentComplete) / 100;
        //$(`#uplPercentBackgr${id}`).width(fullW * percentComplete / 100);
    }
}

class Logo_File_Search extends ComponentEx {
    state = {
        toLargeFileDlgOpen: false,
        toLargeFilename: undefined,
        MAX_FILE_SIZE: getConfigValue('maxUploadFileSize'),
        activeUploads: {},
        delFileDlgOpen: false,
        delFileId: undefined,
    };

    constructor(props) {
        super(props);

        this.id = randomString(7, 'aA');
        this.handleDragOver = this.handleDragOver.bind(this);
        this.handleFileSelect = this.handleFileSelect.bind(this);
        this.uploading = this.uploading.bind(this);
        this.dragEnter = this.dragEnter.bind(this);
        this.dragLeave = this.dragLeave.bind(this);


        this.getRowId = row => row.customer_id;
        this.newClick = this.createQuoteClick.bind(this);
    }

    componentWillMount() {
        if (!this.props.data.loading) {
            this.props.data.refetch();
        }

    }

    componentDidMount() {
        $("table[class*='Table-headTable']").addClass(this.props.classes.tableHeader);
    }

    createQuoteClick() {
        this.props.history.push('/underdev');
    }

    handleDragOver(e) {
        if (this.props.noAddBtn) return;
        e.stopPropagation();
        e.preventDefault();
        e.dataTransfer.dropEffect = 'copy';
        $(`#drop_zone_${this.id}`).css('border', 'green dashed 2px');
    }

    uploading(tempId, nextChunkLenght, nextPosition, realId) {
        let activeUploads = this.state.activeUploads;
        const upload = activeUploads[tempId];
        if (realId) {
            upload.percentComplete = 100;
        } else {
            upload.percentComplete = nextPosition * 100 / upload.contentLength;
        }
        this.setState({ activeUploads });

        const array = new Uint8Array(upload.fileData);

        const nextChunk = {
            tempId,
            content: Array.from(array.slice(nextPosition, nextPosition + nextChunkLenght)),
        }

        this.props.uploadNextChunk({
            variables: { chunk: nextChunk },
        }, this).then(
            (resp) => {
                const serverErrors = resp.errors;
                if (serverErrors && serverErrors.length > 0) {
                    //showErrors(serverErrors);
                    return;
                }
                const validationErrors = resp.data.uploadNextReportLogoChunkMutation.validation;
                if (validationErrors && validationErrors.length > 0) {
                    //show validation;
                    return;
                }

                //const activeUploads = this.state.activeUploads;
                const serverResp = { ...resp.data.uploadNextReportLogoChunkMutation };

                if (serverResp.id) {
                    activeUploads = {};
                    this.setState({ activeUploads });
                    this.props.data.refetch();
                } else {
                    this.setState({ activeUploads });
                    this.uploading(serverResp.tempId,
                        serverResp.nextChunkLen,
                        serverResp.nextPosition);
                }





            });


    }

    handleFileSelect(e) {
        if (this.props.noAddBtn) return;
        e.stopPropagation();
        e.preventDefault();
        $(`#drop_zone_${this.id}`).css('border', 'transparent dashed 2px');
        const ff = e.dataTransfer ? e.dataTransfer.files : e.target.files;
        const files = [];
        if (ff && ff.length > 0) {
            files.push(ff[ff.length - 1]);
        }

        let toLargePresent = false;
        files.forEach((f) => {
            if (f.size > this.state.MAX_FILE_SIZE) {
                this.setState({ toLargeFilename: f.name, toLargeFileDlgOpen: true });
                toLargePresent = true;
            }
        }, this);
        if (toLargePresent) return;

        const uploadingFunc = this.uploading;

        files.forEach((f) => {
            const ext = getFileExtension(f.name);
            const obj = {
                document_id: '-1',
                attached_to_entity_id: this.props.entityId,
                file_type_cd: '-',
                file_extension: ext,
                file_name: f.name.replace(ext, ''),
                contentLength: f.size,

            };

            this.props.updateFile({
                variables: { file: obj },
            }, this).then(
                (resp) => {


                    const serverErrors = resp.errors;
                    if (serverErrors && serverErrors.length > 0) {
                        //showErrors(serverErrors);
                        return;
                    }
                    const validationErrors = resp.data.updateReportLogoFileMutation.validation;
                    if (validationErrors && validationErrors.length > 0) {
                        //show validation;
                        return;
                    }

                    const activeUploads = this.state.activeUploads;
                    obj.percentComplete = 0;

                    const reader = new FileReader();
                    reader.onload = (e) => {

                        obj.fileData = e.target.result;
                        obj.tempId = resp.data.updateReportLogoFileMutation.tempId;
                        activeUploads[resp.data.updateReportLogoFileMutation.tempId] = obj;
                        this.setState({ activeUploads });

                        uploadingFunc(resp.data.updateReportLogoFileMutation.tempId,
                            resp.data.updateReportLogoFileMutation.nextChunkLen,
                            resp.data.updateReportLogoFileMutation.nextPosition);


                    };

                    reader.readAsArrayBuffer(f);




                });
        }, this);
    }

    dragEnter() {
        if (this.props.noAddBtn) return;
        $(`#drop_zone_${this.id}`).css('border', 'green dashed 2px');
    }

    dragLeave() {
        if (this.props.noAddBtn) return;
        $(`#drop_zone_${this.id}`).css('border', 'transparent dashed 2px');
    }

    handleDelFile(id) {

        Object.keys(this.state.activeUploads).forEach((tempId) => {
            const f = this.state.activeUploads[tempId];
            if (f.document_id === id || f.document_id !== '-1') {
                delete this.state.activeUploads[tempId];
            }

        });

        this.props.deleteFile({
            variables: { id },
            refetchQueries: [
                {
                    query: reportLogoFilesQuery,
                },
            ],
        });
        this.setState({ delFileDlgOpen: false });
    }

    render() {

        if (this.props.quiet) {
            return (<LinearProgressEx loading={this.props.data.loading} />);
        }

        const { classes } = this.props;

        const rows = this.props.data.reportLogoFiles ? this.props.data.reportLogoFiles : [];
        const entityId = this.props.entityId;

        let allFiles = [];
        allFiles = allFiles.concat(rows);
        Object.keys(this.state.activeUploads).forEach((tempId) => {
            allFiles.push(this.state.activeUploads[tempId]);
        });


        const columns = [
            { name: 'file_name', title: getTxt('Nome del file') },
            { name: 'document_id', title: getTxt('Azioni') },
        ];

        const rootMargin = classes.root.margin;

        let showGrid = true;
        if (this.props.hideEmptyGrid && allFiles.length === 0) {
            showGrid = false;
        }

        return (

            <div
                className={classes.root}
                id={`drop_zone_${this.id}`}
                onDragOver={this.handleDragOver}
                onDrop={this.handleFileSelect}
                onDragEnter={this.dragEnter}
                onDragLeave={this.dragLeave}
                style={{ border: 'transparent dashed 2px' }}
            >
                <LinearProgressEx loading={this.props.data.loading} />
                {!this.props.noAddBtn &&
                    <div>
                        <Button
                            color="primary"
                            className={classes.button}
                            onClick={() => { $(`#hidden${this.id}`).click(); }}
                        >
                            {getTxt('Allega nuovo file')}
                        </Button>
                        <span className={classes.dragNote} >{getTxt('Or drag and drop your files here.')}</span>
                    </div>
                }
                <Dialog
                    open={this.state.toLargeFileDlgOpen}
                    onClose={() => {
                        this.setState({ toLargeFileDlgOpen: false });
                    }}
                >
                    <DialogTitle> <span className={classes.dialogText}> {getTxt('File to large', '#kj85b3l')} </span></DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <span className={classes.dialogText}>
                                {getTxt('File') + ' ' + this.state.toLargeFilename + ' ' + getTxt('to large for uload.')}
                            </span>
                            <br />
                            <span className={classes.dialogText}>
                                {getTxt('Files less than') + ' ' + formatBytes(this.state.MAX_FILE_SIZE) + ' ' + getTxt('can be uploaded.')}
                            </span>
                        </DialogContentText>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { this.setState({ serverError: undefined, toLargeFileDlgOpen: false }); }} color="primary" >
                            Ok
                        </Button>

                    </DialogActions>
                </Dialog>


                {showGrid && <Grid
                    rows={allFiles}
                    columns={columns}


                >
                    <FilteringState
                        defaultFilters={[{}]}
                    />
                    <SortingState
                        defaultSorting={[
                        ]}
                    />
                    <IntegratedFiltering />
                    <IntegratedSorting />

                    <Table
                        messages={{ noData: 'nessuna immagine' }}
                        cellComponent={({ row, column, style, ...props }) => {
                            if (column.name === 'file_name') {
                                return (<Table.Cell style={{ position: 'relative' }}>
                                    {row.document_id !== '-1' &&
                                        row.file_name
                                    }
                                    {row.document_id === '-1' &&
                                        <span>{row.file_name}</span>
                                    }
                                    <div
                                        id={`uplPercent${row.tempId}`}
                                        className={classes.uplPercent}
                                        style={{ borderColor: row.document_id === '-1' ? '#909090' : 'transparent' }}
                                    >
                                        {row.document_id === '-1' &&
                                            <div
                                                id={`uplPercentBackgr${row.tempId}`}
                                                className={classes.uplPercentBackgr}
                                                style={{ width: showUploadProgress(row.tempId, row.percentComplete) }}
                                            />
                                        }

                                    </div>

                                </Table.Cell>);
                            }
                            if (column.name === 'file_type_cd') {
                                return (<Table.Cell>{getLabel(DictionaryTypes.DocumentType, row.file_type_cd)}</Table.Cell>);
                            }
                            if (column.name === 'document_id') {
                                return (<Table.Cell>
                                    {row.document_id !== '-1' &&
                                        <div>
                                            {/* href={`${window.apiUrl}files/download/${row.document_id}`}  */}

                                            <Tooltip title={getTxt('Download')}>
                                                <a
                                                    href={`${window.apiUrl}files/r-logo`}
                                                    className={classes.inGridLink}
                                                    target="_blank"
                                                >
                                                    <CloudDownloadIcon style={{ width: 60 }} />
                                                </a>
                                            </Tooltip>

                                            {!this.props.noDelete && <Tooltip title={getTxt('Delete')}>

                                                <IconButton size="small" onClick={() => { this.setState({ delFileDlgOpen: true, delFileId: row.document_id }); }} className={classes.inGridButton} >
                                                    <Icon.Delete />
                                                </IconButton>
                                            </Tooltip>}

                                        </div>
                                    }
                                </Table.Cell>);
                            }



                            return <Table.Cell {...props} />;
                        }}
                    />

                    {!this.props.noFilters && <TableFilterRow
                        messages={filterRowMessages}
                        cellComponent={({ column, filter, onFilter, ...props }) => {
                            if (column.name === 'file_type_cd') {
                                return (
                                    <c.gridColFilter noFilterVal={getTxt('Tutti', '#6gnh21n')} setFilter={onFilter} >
                                        {getDictionary(DictionaryTypes.DocumentType)}
                                    </c.gridColFilter>);
                            }
                            return <TableFilterRow.Cell onFilter={onFilter} filter={filter} {...props} />;
                        }}
                    />}

                    <TableHeaderRow showSortingControls />

                </Grid>}
                <Dialog onClose={() => { this.setState({ delFileDlgOpen: false }); }} open={this.state.delFileDlgOpen}>
                    <DialogTitle >Cancella file.</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <span> Il file sarà cancellato sul server. </span>
                            <br />
                            <span > Sei sicuro di voler cancellare il file? </span>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => this.handleDelFile(this.state.delFileId)}
                            color="primary"
                        >
                            Ok
                        </Button>
                        <Button onClick={() => { this.setState({ delFileDlgOpen: false }); }} color="primary">
                            Annula
                        </Button>

                    </DialogActions>
                </Dialog>
                <input type="file" style={{ display: 'none' }} id={`hidden${this.id}`} onChange={(e) => { this.handleFileSelect(e); }} />
            </div >
        );
    }
}

export default compose(
    graphql(reportLogoFilesQuery, {
        options: props => (
            {
                variables: { entityId: props.entityId },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }),
    }),
    graphql(updateReportLogoFileMutation, {
        name: 'updateFile',
        options: {
            errorPolicy: 'all',
        },
    }),
    graphql(uploadNextReportLogoChunkMutation, {
        name: 'uploadNextChunk',
        options: {
            errorPolicy: 'all',
        },
    }),
    graphql(deleteReportLogoFileMutation, {
        name: 'deleteFile',
        options: {
            errorPolicy: 'all',
        },
    }),
)(withStyles(styles)(Logo_File_Search));




