import ComponentEx from 'components/ComponentEx';
import { getTxt } from 'global/appGlobal';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { compose, graphql } from 'react-apollo';
import LinearProgressEx from '../../components/LinearProgressEx';
import c from '../../components/form/Components';
import MaterialForm from '../../components/form/MaterialForm';
import { DictionaryTypes, getDictionary, getLabel } from '../../global/dictionaryCache';
import { metadataQuery } from '../../home/Home';
import Custom_Fields_Edit from '../../modules/publicInsType/Custom_Fields_Edit';
import * as queries from '../../modules/company/brokerPercentQueries';
import { EntityTypes } from '../../global/entityDescriptors';
import { queryCompaniesAsDictionary } from '../../components/form/MFormCompanySelect';
import { companiesAsDictionaryQuery } from './CompanyQueries';

let history;
let AfterSubmit;

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing.unit * 2,
        margin: theme.spacing.unit * 2,
    },
    dialogText: {
        color: theme.palette.error.main,
    },
});


class BrokerPercentEdit extends ComponentEx {

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.formChanged = this.formChanged.bind(this);
    }

    state = {
        open: false,
        existDlgOpen: false,
    };

    formSetValues = { func: undefined };

    formChanged(field, value, type, prevVal) {
    }

    onSubmit(e, { obj, showErrors, hideErrors }) {
        const original = this.props.data.brokerPercentById;
        obj.broker_percent_id = original.broker_percent_id;


        for (let i = 0; i < this.props.existing.length; i += 1) {
            const exist = this.props.existing[i];
            if (obj.broker_percent_id === exist.broker_percent_id) {
                continue;
            }
            if (obj.insurance_company_id === exist.insurance_company_id &&
                obj.broker_id === exist.broker_id &&
                obj.guaranty_type_id === exist.guaranty_type_id) {
                this.setState({ existDlgOpen: true });
                return;
            }
        }

        this.props.updateBrokerPercent({
            variables: { brokerPercent: obj },
            refetchQueries: [
                // {
                //     query: queries.allBrokerPercents,
                //     variables: { brokerId: original.broker_id, companyId: original.insurance_company_id },
                // },
                {
                    query: queries.brokerPercentByIdQuery,
                    variables: { id: original.broker_percent_id },
                },
                {
                    query: companiesAsDictionaryQuery,
                },
            ],
        }, this).then(
            (resp) => {
                hideErrors();
                if (resp.data.updateBrokerPercentMutation &&
                    resp.data.updateBrokerPercentMutation.validation &&
                    resp.data.updateBrokerPercentMutation.validation.length > 0) {
                    showErrors(resp.data.updateBrokerPercentMutation.validation);
                } else {
                    if (!AfterSubmit) {
                        history.goBack();
                    } else {
                        AfterSubmit(resp.data.updateBrokerPercentMutation.id);
                    }
                }
            });
    }

    render() {

        const { classes } = this.props;

        const loading = this.props.data.loading;
        const item = this.props.data.brokerPercentById;
        history = this.props.history;
        AfterSubmit = this.props.AfterSubmit;
        let caption;


        if (this.props.id === 'new') {
            caption = getTxt('Create Broker Percent');
        } else {
            caption = getTxt('Edit Broker Percent');
        }

        let guaranties = [];

        if (item && item.allGuaranties && item.allGuaranties.length > 0) {
            guaranties = item.allGuaranties.filter(g => !g.deleted)
                .map(i => ({ value: Number.parseInt(i.guaranty_type_id), label: i.guaranty_name }));
        }
        return (
            <div>

                <Dialog onClose={() => { this.props.AfterSubmit(); }} open >
                    <DialogContent>
                        <LinearProgressEx loading={loading} />
                        <Typography variant="h4" color="inherit" noWrap={false}>{caption}</Typography>


                        {item &&

                            <MaterialForm
                                dataSource={item}
                                objType={EntityTypes.BrokerPercent}
                                onSubmit={this.onSubmit}

                            >


                                <c.field l={getTxt('Broker')} f="broker_id" v={this.props.brokerId} ui="broker_select" d={this.props.brokerId ? true : false} no_deleted />
                                <c.field l={getTxt('Company')} f="insurance_company_id" v={this.props.companyId} ui="company_select" no_deleted d={this.props.companyId ? true : false} />
                                <c.field f="guaranty_type_id" ui="select" >
                                    {guaranties}
                                </c.field>
                                <c.field f="broker_percent" />
                                <c.field f="broker_percent_extra" />



                                <c.formButtons sm={12} onCancel={() => { this.props.AfterSubmit(); }} />
                            </MaterialForm>


                        }

                    </DialogContent>

                </Dialog>

                <Dialog
                    className={classes.dialog}
                    onClose={() => { this.setState({ existDlgOpen: false }) }}
                    open={this.state.existDlgOpen}
                >
                    <DialogTitle> <span className={classes.dialogText}>{getTxt('The commision already exists.')}</span></DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <span className={classes.dialogText}>
                                {getTxt('The Broker Comission with such Company and Guaranty Type already exists.')}
                            </span>
                        </DialogContentText>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { this.setState({ existDlgOpen: false }); }} color="primary" >
                            {getTxt('Ok')}
                        </Button>

                    </DialogActions>
                </Dialog>

            </div>

        );
    }
}

export default compose(
    graphql(queries.brokerPercentByIdQuery, {
        options: props => (
            {
                variables: {
                    id: props.id ? props.id : 'new',
                },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }
        ),
    }),
    graphql(queries.updateBrokerPercentMutation, {
        name: 'updateBrokerPercent',
        options: {
            errorPolicy: 'all',
          
        },
    }),
)(withStyles(styles)(BrokerPercentEdit));
