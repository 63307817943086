
import GridEx from 'components/form/GridEx';
import { Button } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { FormControl, FormHelperText } from '@material-ui/core';
import { Input, InputAdornment, InputLabel } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import Search from '@material-ui/icons/Search';
import { graphql, compose } from 'react-apollo';
import MFormControl from '../../components/form/MFormControl';
import Client_Search from '../../modules/client/Client_Search';
import { customersAsDictionary } from '../../modules/client/clientQueries';

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: '100%',
        position: 'relative',
    },

    input: {
        color: 'transparent',
    },

    dialogText: {
        color: theme.palette.error.main,
    },

    calcCodeBtn: {
        position: 'absolute',
        right: 0,
        bottom: theme.spacing.unit,
    },
    calcProgress: {
        position: 'absolute',
        right: theme.spacing.unit * 9,
        top: theme.spacing.unit - theme.spacing.unit * 2,
    },

    validationIconTrue: {
        position: 'absolute',
        right: theme.spacing.unit * 13,
        color: 'green',
    },

    validationIconFalse: {
        position: 'absolute',
        right: theme.spacing.unit * 13,
        color: theme.palette.error.main,
        cursor: 'pointer',
    },

    link: {
        position: 'absolute',
        top: theme.spacing.unit * 3,
    },
    iconSmall: {
        fontSize: 20,
    },

});

class MFormLimkToCustomer extends MFormControl {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);

        //this.onKeyDown = this.onKeyDown.bind(this);
    }

    selectedId = undefined;

    onChange(e) {
        this.setState({ value: e.target.value });
        this.setState({ isDirty: true });
        this.formOnChange(this.props.f, e.target.value, this.props.type);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.forseRefresh) {
            this.setState({ value: nextProps.v });
        }
    }

    render() {
        const { classes } = this.props;

        const me = this;
        const loading = this.props.data.loading;
        const customers = this.props.data.customersAsDictionary ? this.props.data.customersAsDictionary : [];
        let selected;
        let linkToCustomer = '#';
        if (customers.length > 0 && this.state.value) {
            selected = customers.find(c => c.customer_id === (this.state.value + ''));
            if (selected && selected.customer_type_cd === 'PRIVATE_PROSPECT') {
                linkToCustomer = `/customers/prospect/private/${selected.customer_id}`;
            } else {
                linkToCustomer = `/customers/${selected.customer_type_cd.toLowerCase()}/${selected.customer_id}`;
            }
        }

        return (

            <GridEx item sm={this.props.sm || 6} xs={this.props.xs || 12}>
                <FormControl className={classes.textField} >
                    <InputLabel
                        error={this.state.errorMessage ? true : false}
                        shrink
                        required={this.props.r}
                    >
                        {this.getLabel()}
                    </InputLabel>
                    <Input
                        margin="normal"
                        {...this.props}
                        value={''}
                        disabled
                        required={this.props.r}
                        //onChange={this.onChange}
                        className={classes.input}
                        inputProps={
                            {
                                id: this.props.id ? this.props.id : this.id,
                                name: this.props.f,
                                endAdornment: this.props.endAdornment,
                            }}

                        endAdornment={
                            <InputAdornment position="end">
                                {loading && <CircularProgress className={classes.calcProgress} size={30} />}
                                <Button
                                    variant="contained"
                                    size="small"
                                    onClick={() => { this.setState({ findDlgOpen: true }); }}
                                    className={classes.calcCodeBtn}
                                    style={this.props.d ? { display: 'none' } : {}}
                                >
                                    <Search className={classes.iconSmall} />
                                </Button>
                            </InputAdornment>
                        }
                    />
                    <Link to={linkToCustomer} className={classes.link}>
                        {selected ? selected.display_name : ''}
                    </Link>
                    <FormHelperText error={this.state.errorMessage ? true : false} >{this.state.errorMessage}</FormHelperText>
                </FormControl>

                <Dialog
                    className={classes.dialog}
                    open={this.state.findDlgOpen}
                    onClose={() => {
                        this.setState({ findDlgOpen: false });
                    }}
                >
                    <DialogTitle> <span>Ricerca Clienti</span></DialogTitle>
                    <DialogContent>
                        <DialogContentText>

                            <Client_Search
                                noAddButton
                                noPdfButton
                                showSelects
                                selectedId={this.state.value}
                                onSelectedChanged={(c) => {
                                    if (c) {
                                        this.selectedId = c;
                                    } else {
                                        this.selectedId = undefined;
                                    }

                                }}
                            />

                        </DialogContentText>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            me.setState({ findDlgOpen: false });
                            me.setValue(me.selectedId ? Number.parseInt(me.selectedId) : undefined);
                        }}
                            color="primary"
                        >
                            Ok
                        </Button>

                        <Button onClick={() => { this.setState({ findDlgOpen: false }); }} color="primary" >
                            Annulla
                           </Button>
                    </DialogActions>
                </Dialog>

            </GridEx>

        );
    }
}

MFormLimkToCustomer.contextTypes = {
    onChange: PropTypes.func,
    registerControl: PropTypes.func,
    objType: PropTypes.string,
    dataSource: PropTypes.object,
    unRegisterControl: PropTypes.func,
    fields: PropTypes.Object,
};

export default compose(
    graphql(customersAsDictionary, {
        options: props => ({}),
    }),
)(withStyles(styles)(MFormLimkToCustomer));
