import React, { Component } from 'react';
import { getTxt } from 'global/appGlobal';

import { withStyles } from '@material-ui/core/styles';
import { withTheme } from '@material-ui/core';

import { Typography } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';
import TabContainer from 'components/TabContainer';
import UnderDevScreen from 'components/UnderDevScreen';

import { Paper } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { AppBar } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { TextField } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import { graphql, compose } from 'react-apollo';

import File_Search from 'modules/file/File_Search';
import ReactSVG from 'react-svg';

import { Tabs, Tab } from '@material-ui/core';



import * as Icon from '@material-ui/icons';
import { Checkbox } from '@material-ui/core';
//import TabsEx, { Tab } from 'global/components/tabs/TabsEx';


import { Button } from '@material-ui/core';
import moment from 'moment';
import { Badge } from '@material-ui/core';




import { p } from 'components/form/Proto';
import $ from 'jquery';
import CmdBar from 'components/form/CmdBar';

import Contract_Item_Search from 'modules/contract/Contract_Item_Search';

import Contract_Item_Details_View from 'modules/contract/Contract_Item_Details_View';
import MaterialForm from '../../components/form/MaterialForm';

import * as queries from '../../modules/contract/contractQueries';
import LinearProgressEx from '../../components/LinearProgressEx';
import c from '../../components/form/Components';

import { HashRouter, BrowserRouter, Route, Link, withRouter } from 'react-router-dom';
import { getLabel, DictionaryTypes } from '../../global/dictionaryCache';
import ComponentEx from '../../components/ComponentEx';

let history;

const styles = theme => ({
    root: {
        flexGrow: 1,
        marginTop: theme.spacing.unit * 3,
        backgroundColor: theme.palette.background.paper,
    },
    button: {
        margin: theme.spacing.unit,
    },

    badge: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        marginTop: 0,
    },

    gridRoot: {
        height: '100%',
    },


    formCaption: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit * 2,
        width: '100%',
        position: 'relative',
        height: 40,
    },
    formCaptionLabel: {
        position: 'absolute',
        //color: theme.palette.primary1,
        backgroundColor: 'transparent',
        left: 25,
        paddingLeft: 10,
        paddingRight: 10,
    },

    rootPaper: theme.mixins.gutters({

        paddingTop: 0,
        paddingBottom: 0,
        marginTop: 0,// theme.spacing.unit * 3,

    }),

    formHeaderRoot: {
        padding: theme.spacing.unit * 2,
        position: 'relative',
    },

    formHeaderFieldLabel: {
        color: theme.palette.text.disabled,
        fontWeight: 'bold',

    },

    tableHeader: {
        backgroundColor: theme.palette.background.paper,
    },
    headerIcon: {
        width: theme.spacing.unit * 8,
        height: theme.spacing.unit * 9,
        marginRight: theme.spacing.unit * 2,
    },
    cmdBar: {
        position: 'absolute',
        right: theme.spacing.unit * 3,
    },

});


const tabsStateGlobalId = 'Contract_Item_View';
class Contract_Item_View extends ComponentEx {

    state = {
        value: window[tabsStateGlobalId] ? window[tabsStateGlobalId] : 0,
    };

    componentDidMount() {
        $("table[class*='Table-headTable']").addClass(this.props.classes.tableHeader);

    }

    handleChange = (event, value) => {
        this.setState({ value });
    };

    componentWillUnmount() {
        window[tabsStateGlobalId] = this.state.value;
        //this.props.data.refetch();
    }

    render() {

        const insType = this.props.insType ? this.props.insType : this.props.match.params.insType;

        const { classes } = this.props;
        const { value } = this.state;

        const loading = this.props.data.loading;
        const item = this.props.data.ContractItem;
        history = this.props.history;
        const caption = getTxt('Contract Movementi');

        return (
            <div >

                <Paper>
                    <LinearProgressEx loading={loading} />
                    {!loading && <div> <Grid container className={classes.formHeaderRoot}>
                        <Grid xs={12} item>

                            <Grid container >
                                <Grid xs={0} item>
                                    <ReactSVG
                                        path="icons/contract.svg"
                                        className={classes.headerIcon}
                                    />
                                </Grid>
                                <Grid xs={1} item >
                                    <Typography color="primary" variant="display2" >{caption}</Typography>
                                </Grid>

                            </Grid>


                        </Grid>


                        <Grid sm={3} item>
                            <Typography variant="subheading" className={classes.formHeaderFieldLabel} >{getTxt('Type of Movementi')}</Typography>
                            <Typography variant="body1" >{getLabel(DictionaryTypes.InsuranceItemType, item.item_type_cd)}</Typography>

                        </Grid>


                        <Grid sm={3} item>
                            <Typography variant="subheading" className={classes.formHeaderFieldLabel} >{getTxt('Total Client Amount')}</Typography>
                            <Typography variant="body1" > {item.total_amount_client} </Typography>
                        </Grid>

                        <Grid sm={3} item>
                            <Typography variant="subheading" className={classes.formHeaderFieldLabel} >{getTxt('Movimenti State')}</Typography>
                            <Typography variant="body1" >{getLabel(DictionaryTypes.InsuranceItemState, item.state_cd)}</Typography>
                        </Grid>
                        <Grid sm={3} item>
                            <Typography variant="subheading" className={classes.formHeaderFieldLabel} >{getTxt('Payment State')}</Typography>
                            <Typography variant="body1" >{getLabel(DictionaryTypes.InsuranceItemPaymentState, item.item_payment_state_cd)}</Typography>
                        </Grid>
                        {/* <Grid sm={3} item>
                            <Typography variant="subheading" className={classes.formHeaderFieldLabel} >{getTxt('Collaboratore')}</Typography>
                            <Typography variant="body1" >
                                <Link to={`/brokers/${item.broker.broker_id}`}>{item.broker.display_name}</Link>
                            </Typography>
                        </Grid> */}

                    </Grid></div>}

                </Paper>



                <AppBar position="static" color="default">
                    <Tabs
                        value={value}
                        onChange={this.handleChange}
                        scrollable
                        scrollButtons="auto"

                        indicatorColor="primary"
                        textColor="primary"
                    >
                        <Tab label="Details" />
                        <Tab label={<div><Icon.AttachFile />{getTxt('Documents')}</div>} />

                    </Tabs>
                </AppBar>



                {value === 0 && <TabContainer>
                    {item &&
                        <div>
                            <Button
                                color="primary"
                                className={classes.button}
                                onClick={() => { this.props.history.push(`/contract/view/${insType}/${this.props.match.params.contractId}`); }}
                            >
                                {getTxt('Back To Contract')}
                            </Button>

                            <Button
                                color="primary"
                                className={classes.button}
                                onClick={() => { this.props.history.push(`/contract/${insType}/${this.props.match.params.contractId}/item/${this.props.match.params.id}`); }}
                            >
                                {getTxt('Edit Details')}
                            </Button>

                            <Contract_Item_Details_View contractId={item.insurance_contract_id} id={item.contract_item_id} />
                        </div>
                    }

                </TabContainer>}

                {value === 1 && <TabContainer>
                    {item && <File_Search entityId={item.contract_item_id} />}
                </TabContainer>}

            </div>
        );
    }
}

export default compose(

    graphql(queries.contractItemByIdQuery, {
        options: props => (
            {
                variables: {
                    id: props.match.params.id ? props.match.params.id : 'new',
                    contractId: props.match.params.contractId,
                },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }
        ),
    }),

)(withStyles(styles)(Contract_Item_View));