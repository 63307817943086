import React, { Component } from 'react';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import $ from 'jquery';
import { addAuthListener, authInfo, initFromCookie, logout } from 'global/auth';

import {
    SortingState, SelectionState, FilteringState, GroupingState,
    IntegratedFiltering, IntegratedGrouping, IntegratedSorting, IntegratedSelection,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    VirtualTable, TableHeaderRow, TableFilterRow, TableSelection, TableGroupRow,
    GroupingPanel, DragDropProvider, TableColumnReordering, Toolbar, TableColumnResizing,
} from '@devexpress/dx-react-grid-material-ui';


import { HashRouter, BrowserRouter, Route, Link, withRouter } from 'react-router-dom';

import ComponentEx from 'components/ComponentEx';

import { Checkbox, IconButton, TableCell, Tooltip } from '@material-ui/core';
import { Button } from '@material-ui/core';
import * as Icon from '@material-ui/icons';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';

//import * as Icon from '@material-ui/icons';

import c from 'components/form/Components';
import { getTxt, filterRowMessages, tableMessages, createGridResizerCols } from '../../global/appGlobal';
import LinkToCustomerById from '../../components/LinksToObjectById';
import LinearProgressEx from '../../components/LinearProgressEx';
import { getLabel, getDictionary, DictionaryTypes } from '../../global/dictionaryCache';
import * as queries from '../../modules/broker/brokerQueries';
import RegularCard from '../../components/dashboard/RegularCard';
import { compose } from 'redux';


const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        margin: theme.spacing.unit * 3,
    },
    button: {
        marginRight: theme.spacing.unit,
    },

    tableHeader: {
        backgroundColor: theme.palette.background.paper,
    },
});

function getIsSelected(id, selectedId, selectedIds) {
    if (selectedIds && selectedIds.length) {
        return selectedIds.find(i => i === id);
    }
    return id === selectedId + '';
}

class Broker_Search extends ComponentEx {
    constructor(props) {
        super(props);

        this.handleDelete = this.handleDelete.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ selectedId: nextProps.selectedId });
        this.setState({ selectedIds: nextProps.selectedIds ? JSON.parse(nextProps.selectedIds) : [] });
    }

    componentWillMount() {
        // if (!this.props.data.loading) {
        //     this.props.data.refetch();
        // }

    }

    handleDelete() {
        this.props.deleteBroker({
            variables: { id: this.state.delId },
            refetchQueries: [
                {
                    query: queries.allBrokersQuery,
                },
            ],
        }, this).then(
            (resp) => {
                this.setState({ delId: undefined, delNumber: undefined });
            });
    }

    state = {
        selectedId: undefined,
        selectedIds: [],
    };

    render() {
        const { classes } = this.props;
        const multiselect = this.props.multiselect;
        const state = this.state;
        const setState = this.setState.bind(this);
        const onSelectedChanged = this.props.onSelectedChanged ? this.props.onSelectedChanged.bind(this) : undefined;
        let items = this.props.data.allBrokers ? this.props.data.allBrokers : [];
        items = items.filter(i => !i.is_deleted);
        if (this.props.filter === 'master_and_broker') {
            items = items.filter(i => (i.broker_type_cd === 'MasterBroker' || i.broker_type_cd === 'Broker'));
        }

        let columns = [
            { name: 'display_name', title: getTxt('Name', '#bm0ht2o') },
            { name: 'broker_type_cd', title: getTxt('Type', '#em9dfco') },
            { name: 'email', title: getTxt('email', '#v349661') },
            { name: 'mobile_phone', title: getTxt('Mobile Phone', '#w4lym4n') },
        ];
        if (authInfo.isAgencyAdministrator() || authInfo.isMasterBroker()) {
            columns.push({ name: 'is_deleted', title: getTxt('Actions') });
        }

        if (this.props.showSelects) {
            columns = [{ name: 'sex_cd', title: getTxt('Selected') }].concat(columns);
        }

        createGridResizerCols(this.state, columns, {}, 280);

        return (

            <div className={classes.root}>
                <LinearProgressEx loading={this.props.data.loading} />
                {!this.props.noCaption && <RegularCard

                    headerColor="red"
                    cardTitle={getTxt('Brokers')}
                    cardSubtitle={getTxt('List of insurance brokers')}
                    content={
                        <div>
                            {!this.props.noAddBtn && (authInfo.isAgencyAdministrator() || authInfo.isMasterBroker()) &&
                                <Button
                                    color="primary"
                                    className={classes.button}
                                    onClick={() => { this.props.history.push('/brokers/edit/new'); }}
                                >
                                    {getTxt('Aggiungi nuova Collaboratore')}
                                </Button>
                            }
                        </div>

                    }
                />}



                <Grid
                    rows={items}
                    columns={columns}
                >
                    <FilteringState
                        defaultFilters={[{}]}
                    />
                    <SortingState
                        defaultSorting={[]}
                    />
                    <IntegratedSorting />
                    {!this.props.showSelects && <IntegratedFiltering />}

                    <VirtualTable
                        messages={tableMessages}
                        cellComponent={({ row, column, style, ...props }) => {

                            if (column.name === 'sex_cd') {
                                return (<VirtualTable.Cell>
                                    <Checkbox
                                        onChange={(e, val) => {
                                            if (multiselect) {

                                                const without = state.selectedIds.filter(id => id !== row.broker_id);

                                                if (val) {
                                                    without.push(row.broker_id);
                                                }
                                                setState({ selectedIds: without });
                                                if (onSelectedChanged) {
                                                    onSelectedChanged(without);
                                                }

                                            } else {
                                                setState({ selectedId: val ? row.broker_id : undefined });
                                                if (onSelectedChanged) {
                                                    onSelectedChanged(val ? row.broker_id : undefined);
                                                }
                                            }


                                        }}
                                        checked={getIsSelected(row.broker_id, state.selectedId, state.selectedIds)}
                                    />
                                </VirtualTable.Cell>);
                            }


                            if (column.name === 'display_name') {
                                return (<VirtualTable.Cell> <Link to={`/brokers/${row.broker_id}`} >{row.display_name}</Link> </VirtualTable.Cell>);
                            }
                            if (column.name === 'broker_type_cd') {
                                return (<VirtualTable.Cell> {getLabel(DictionaryTypes.BrokerType, row.broker_type_cd)} </VirtualTable.Cell>);
                            }
                            if (column.name === 'is_deleted' && (authInfo.isAgencyAdministrator() || authInfo.isMasterBroker())) {
                                return (<VirtualTable.Cell>

                                    <Tooltip title={getTxt('Delete')}>
                                        <IconButton size="small" onClick={() => { this.setState({ delId: row.broker_id, delNumber: row.display_name }); }} className={classes.inGridButton} >
                                            <Icon.Delete fontSize="small" />
                                        </IconButton>
                                    </Tooltip>

                                </VirtualTable.Cell>);
                            }
                            return <VirtualTable.Cell {...props} />;
                        }}
                    />

                    {!this.props.showSelects && <TableFilterRow
                        messages={filterRowMessages}
                        cellComponent={({ column, filter, onFilter, ...props }) => {
                            if (column.name === 'broker_type_cd') {
                                return (
                                    <c.gridColFilter noFilterVal={getTxt('Tutti')} setFilter={onFilter} >
                                        {getDictionary(DictionaryTypes.BrokerType)}
                                    </c.gridColFilter>);
                            }
                            if (column.name === 'is_deleted') {
                                return (
                                    <TableFilterRow.Cell filteringEnabled={false} >
                                        <span />
                                    </TableFilterRow.Cell>);
                            }

                            return <TableFilterRow.Cell onFilter={onFilter} filter={filter} {...props} />;
                        }}
                    />}

                    <TableColumnResizing
                        columnWidths={this.state.columnWidths}
                        onColumnWidthsChange={(cols) => { this.setState({ columnWidths: cols }); }}
                        defaultColumnWidths={this.state.defColumnWidths}
                    />
                    <TableHeaderRow showSortingControls />

                </Grid>
                <Dialog onClose={() => { this.setState({ delId: undefined, delNumber: undefined }); }} open={this.state.delId}>
                    <DialogTitle >{getTxt('Cancella collaboratore')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <span>{getTxt('Il collaboratore sarà cancellato sul server.')}</span>
                            <br />
                            <span >{getTxt('Sei sicuro di voler cancellare il collaboratore?')}</span>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleDelete(this.state.delId)} color="primary">
                            {getTxt('Ok')}
                        </Button>
                        <Button onClick={() => { this.setState({ delId: undefined, delNumber: undefined }); }} color="primary">
                            {getTxt('Annula')}
                        </Button>

                    </DialogActions>
                </Dialog>
            </div >
        );
    }
}

export default compose(

    graphql(queries.allBrokersQuery, {
        options: {
            fetchPolicy: 'network-only',
            options: {
                errorPolicy: 'all',
            },
        },
    }),


    graphql(queries.deleteBrokerMutation, {
        name: 'deleteBroker',
        options: {

            errorPolicy: 'all',
        },
    }),

)(withStyles(styles)(Broker_Search));
