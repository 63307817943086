import React, { Component } from 'react';
import { getTxt } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import queryString from 'query-string';

import { Paper } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { AppBar } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { TextField } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';

import * as Icon from '@material-ui/icons';
import { Checkbox } from '@material-ui/core';

import GridEx from 'components/form/GridEx';

import { Button } from '@material-ui/core';
import moment from 'moment';
import { Badge } from '@material-ui/core';

import ComponentEx from 'components/ComponentEx';
import { authInfo } from 'global/auth';

import { LinearProgress } from '@material-ui/core';
import { graphql, compose } from 'react-apollo';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import { EntityTypes, getEntityDescriptor } from 'global/entityDescriptors';
import File_Search from 'modules/file/File_Search';
import c from '../../components/form/Components';
import LinearProgressEx from '../../components/LinearProgressEx';
import { getLabel, DictionaryTypes, getDictionary, getInternalInsTypeCd } from '../../global/dictionaryCache';
import MaterialForm from '../../components/form/MaterialForm';
import Viecoli_Edit_Form from '../../modules/request/viecoli/Viecoli_Edit_Form';

import Cauzione_Edit_Form from '../../modules/request/cauzione/Cauzione_Edit_Form';
import Cose_Edit_Form from '../../modules/request/cose/Cose_Edit_Form';
import Persone_Edit_Form from '../../modules/request/persone/Persone_Edit_Form';
import Other_Edit_Form from '../../modules/request/other/Other_Edit_Form';

import { get_Requests_ById_Query, allRequestsQuery, requestBriefQuery, update_Request_Mutation } from '../../modules/request/requestQeries';

let history;
let insType;

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing.unit * 2,
        margin: theme.spacing.unit * 2,
    },
    button: {
        margin: theme.spacing.unit,
    },
    formButtons: {
        float: 'right',
        margin: '15px',
    },
    dialogText: {
        color: theme.palette.error.main,
    },

    noGuarantyErrorText: {
        ...theme.typography.body1,
        color: theme.palette.error.main,
        paddingLeft: theme.spacing.unit * 2,
        float: 'right',
        marginRight: theme.spacing.unit * 25,

    },

});

let transferToContract = false;
let isNew = false;

class Request_Details_Edit extends ComponentEx {

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.afterFilesAdded = this.afterFilesAdded.bind(this);
    }

    state = {
        open: false,
        addFilesDlg: false,
        requestId: false,
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleRequestClose = () => {
        this.setState({ open: false });
    };

    onSubmit(e, { obj, showErrors, hideErrors }) {
        const toContract = transferToContract;
        if (!isNew) {
            transferToContract = false;
        }


        let originalRequest;
        let insEntityType;
        Object.keys(this.props.data.get_request_by_id).forEach((key) => {
            if (this.props.data.get_request_by_id[key] && this.props.data.get_request_by_id[key].insurance_request_id) {
                originalRequest = this.props.data.get_request_by_id[key];
                insEntityType = key;
            }
        });

        if (toContract &&
            originalRequest.insurance_request_id === '-1' &&
            this.props.data.get_request_by_id.defaultGuarantiesCount === 0) {
            this.setState({ noOneGuarantyDialogOpen: true });
            return;
        }

        if (toContract &&
            originalRequest.insurance_request_id !== '-1' &&
            this.props.data.get_request_by_id.guarantiesCount === 0) {
            this.setState({ noOneGuarantyDialogOpen: true });
            return;
        }

        obj.insurance_request_id = originalRequest.insurance_request_id;
        obj.customer_id = originalRequest.customer_id;
        obj.insurance_type_cd = this.props.match.params.insType;
        if (!toContract) {
            obj.state_cd = originalRequest.state_cd;
        } else {
            obj.state_cd = 'TRANSFERRED_TO_CONTRACT';
        }

        const finalObj = {};
        finalObj[insEntityType] = obj;

        this.props.updateRequest({
            variables: { request: finalObj },
            refetchQueries: [
                {
                    query: allRequestsQuery,
                    variables: { customerId: obj.customer_id },
                },
                {
                    query: requestBriefQuery,
                    variables: { id: obj.insurance_request_id, customerId: obj.customer_id, insType: getInternalInsTypeCd(this.props.match.params.insType) },
                },
                {
                    query: get_Requests_ById_Query,
                    variables: { id: obj.insurance_request_id, customerId: obj.customer_id, insType: getInternalInsTypeCd(this.props.match.params.insType) },
                },
            ],
        }, this).then(
            (resp) => {
                hideErrors();
                if (resp.data.update_Request_Mutation &&
                    resp.data.update_Request_Mutation.validation &&
                    resp.data.update_Request_Mutation.validation.length > 0) {
                    showErrors(resp.data.update_Request_Mutation.validation);
                } else {

                    if (isNew) {
                        this.setState({ requestId: resp.data.update_Request_Mutation.id, addFilesDlg: true });
                    } else {
                        if (toContract) {
                            const queryParams = queryString.parse(this.props.location.search);
                            if (queryParams.oci) {
                                history.push(`/contract/edit/${this.props.match.params.insType}/${resp.data.update_Request_Mutation.id}/new/?oci=${queryParams.oci}`);
                            } else {
                                history.push(`/contract/edit/${this.props.match.params.insType}/${resp.data.update_Request_Mutation.id}/new`);
                            }

                        } else {
                            history.push(`/requests/${this.props.match.params.insType}/view/${resp.data.update_Request_Mutation.id}`);
                        }
                    }



                }
            });
    }

    afterFilesAdded(requestId) {
        const toContract = transferToContract;
        transferToContract = false;
        if (toContract) {
            history.push(`/contract/edit/${this.props.match.params.insType}/${requestId}/new`);
        } else {
            let showMsgParam = '';
            if (authInfo.isCustomer()) {
                showMsgParam = '?sm=1';
            }
            history.push(`/requests/${this.props.match.params.insType}/view/${requestId}${showMsgParam}`);
        }
    }

    render() {

        const { classes } = this.props;

        const loading = this.props.data.loading;
        let fields = [];
        let guarantiesCount = 0;
        let defaultGuarantiesCount = 0;
        let request;
        if (this.props.data.get_request_by_id) {
            Object.keys(this.props.data.get_request_by_id).forEach((key) => {
                guarantiesCount = this.props.data.get_request_by_id.guarantiesCount;
                defaultGuarantiesCount = this.props.data.get_request_by_id.defaultGuarantiesCount;
                if (this.props.data.get_request_by_id[key] && this.props.data.get_request_by_id[key].insurance_request_id) {
                    request = this.props.data.get_request_by_id[key];
                }
            });

            if (this.props.data.get_request_by_id.fields) {
                fields = JSON.parse(this.props.data.get_request_by_id.fields);
            }
        }

        history = this.props.history;
        insType = getInternalInsTypeCd(this.props.match.params.insType);
        let caption = '';

        let hasIdParam;
        if (this.props.id) {
            hasIdParam = true;
        }
        if (this.props.match.params && this.props.match.params.id) {
            hasIdParam = true;
        }

        if (request) {
            const insType_ = getLabel(DictionaryTypes.InsuranceType, request.insurance_type_cd);
            if (hasIdParam) {
                caption = insType_ + ' - Modifica Richieste';
                isNew = false;
            } else {
                caption = insType_ + ' - Nuova Richiesta';
                isNew = true;
            }
        }

        const queryParams = queryString.parse(this.props.location.search);

        return (


            <div className={classes.root}>

                <Typography variant="h4" color="inherit" noWrap={false}>{getTxt(caption)}</Typography>
                <LinearProgressEx loading={loading} />

                {!loading &&
                    <div>
                        {insType === 'VEICOLI' &&
                            <Viecoli_Edit_Form
                                request={request}
                                onSubmit={this.onSubmit}
                                history={this.props.history}
                                transferToContract={request.customer.customer_type_cd !== 'PRIVATE_PROSPECT' && request.state_cd !== 'TRANSFERRED_TO_CONTRACT' ? () => { transferToContract = true; } : undefined}
                                fields={fields}
                                isNew={!hasIdParam}
                                oldContractId={queryParams.oci}
                            />
                        }

                        {insType === 'CAUZIONE' &&
                            <Cauzione_Edit_Form
                                request={request}
                                onSubmit={this.onSubmit}
                                history={this.props.history}
                                transferToContract={request.customer.customer_type_cd !== 'PRIVATE_PROSPECT' && request.state_cd !== 'TRANSFERRED_TO_CONTRACT' ? () => { transferToContract = true; } : undefined}
                                fields={fields}
                                isNew={!hasIdParam}
                                oldContractId={queryParams.oci}
                            />
                        }
                        {insType === 'COSE' &&
                            <Cose_Edit_Form
                                request={request}
                                onSubmit={this.onSubmit}
                                history={this.props.history}
                                transferToContract={request.customer.customer_type_cd !== 'PRIVATE_PROSPECT' && request.state_cd !== 'TRANSFERRED_TO_CONTRACT' ? () => { transferToContract = true; } : undefined}
                                fields={fields}
                                isNew={!hasIdParam}
                                oldContractId={queryParams.oci}
                            />
                        }
                        {insType === 'PERSONE' &&
                            <Persone_Edit_Form
                                request={request}
                                onSubmit={this.onSubmit}
                                history={this.props.history}
                                transferToContract={request.state_cd !== 'TRANSFERRED_TO_CONTRACT' ? () => { transferToContract = true; } : undefined}
                                fields={fields}
                                isNew={!hasIdParam}
                                oldContractId={queryParams.oci}
                            />
                        }
                        {insType === 'OTHER' &&
                            <Other_Edit_Form
                                request={request}
                                onSubmit={this.onSubmit}
                                history={this.props.history}
                                transferToContract={request.customer.customer_type_cd !== 'PRIVATE_PROSPECT' && request.state_cd !== 'TRANSFERRED_TO_CONTRACT' ? () => { transferToContract = true; } : undefined}
                                fields={fields}
                                isNew={!hasIdParam}
                                oldContractId={queryParams.oci}
                            />
                        }
                    </div>

                }

                <Dialog
                    className={classes.dialog}
                    open={this.state.noOneGuarantyDialogOpen}
                    onClose={() => {
                        this.setState({ serverError: undefined, generateFiscalValidationDlgOpen: false });
                    }}
                >
                    <DialogTitle> <span className={classes.dialogText}>{getTxt('The request has no guaranties')} </span></DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {guarantiesCount === 0 && hasIdParam &&
                                <span className={classes.dialogText}> {getTxt('To create Contract the Request must have at least one guarantee.')}  </span>
                            }
                            {defaultGuarantiesCount === 0 && !hasIdParam &&
                                <span className={classes.dialogText}> {getTxt('Request has no any default guaranties. To create Contract the Request must have at least one guarantee.')} </span>
                            }
                        </DialogContentText>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { this.setState({ noOneGuarantyDialogOpen: false }); }} color="primary" >
                            Ok
                               </Button>

                    </DialogActions>
                </Dialog>

                <Dialog
                    className={classes.dialog}
                    open={this.state.addFilesDlg}
                    onClose={() => {
                        const requestId = this.state.requestId;
                        this.setState({ addFilesDlg: false, requestId: undefined });
                        this.afterFilesAdded(requestId);
                    }}
                >
                    <DialogTitle> <span>{getTxt('Request Documents')} </span></DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <File_Search
                                entityId={this.state.requestId}
                                history={this.props.history}
                                afterSubmit={this.afterFilesAdded}
                            />
                        </DialogContentText>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            const requestId = this.state.requestId;
                            this.setState({ addFilesDlg: false, requestId: undefined });
                            this.afterFilesAdded(requestId);
                        }}
                            color="primary" >
                            {getTxt('Ok')}
                        </Button>
                        <Button onClick={() => {
                            const requestId = this.state.requestId;
                            this.setState({ addFilesDlg: false, requestId: undefined });
                            this.afterFilesAdded(requestId);
                        }} color="primary">
                            {getTxt('Annula')}
                        </Button>

                    </DialogActions>
                </Dialog>

            </div>

        );
    }
}


export default compose(
    graphql(get_Requests_ById_Query, {
        options: props => (
            {
                variables: {
                    id: (!props.id ? (props.match.params.id ? props.match.params.id : 'new') : props.id),
                    customerId: props.customerId ? props.customerId : props.match.params.customerId,
                    insType: getInternalInsTypeCd(props.match.params.insType),
                    publicInsType: props.match.params.insType,
                    getMeta: true,
                },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }
        ),
    }),
    graphql(update_Request_Mutation, {
        name: 'updateRequest',
        options: {
            errorPolicy: 'all',
           
        },
    }),
)(withStyles(styles)(Request_Details_Edit));
