import React, { Component } from 'react';
import { getTxt } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { authInfo, Roles } from '../../global/auth';
import { Paper } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { AppBar } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { TextField } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';

import * as Icon from '@material-ui/icons';
import { Checkbox } from '@material-ui/core';

import GridEx from 'components/form/GridEx';
import { Button } from '@material-ui/core';
import moment from 'moment';
import { Badge } from '@material-ui/core';

import ComponentEx from 'components/ComponentEx';
import { p } from 'components/form/Proto';

import { LinearProgress } from '@material-ui/core';
import { graphql, compose } from 'react-apollo';


import c from '../../components/form/Components';
import LinearProgressEx from '../../components/LinearProgressEx';
import { getLabel, DictionaryTypes, getDictionary } from '../../global/dictionaryCache';
import MaterialForm from '../../components/form/MaterialForm';
import MFormText from '../../components/form/MFormText';
import * as queries from '../../modules/contract/contractQueries';
import { toItalianDateString } from '../../global/appGlobal';
import $ from 'jquery';
import { updateStatisticsMutation } from '../dashboard/dashboardQueries';

let history;
let contractId;
const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing.unit * 2,
        // margin: theme.spacing.unit * 2,
    },

});

function toInputDateString(value) {
    if (!value || value === '') return value;

    let d = moment.utc(value);
    if (!d) return value;

    // let year = d.year();
    // let month = d.month();
    // if (month < 9) {
    //     month = '0' + month;
    // }

    // let date = d.date();
    // if (date < 9) {
    //     date = '0' + date;
    // }

    let hours = d.hours();
    if (hours < 10) {
        hours = '0' + hours;
    }

    let minutes = d.minutes();
    if (minutes < 10) {
        minutes = '0' + minutes;
    }


    const date = moment(value).format('YYYY-MM-DD');
    return date;
}

class Contract_Item_Edit extends ComponentEx {

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.fornChanged = this.fornChanged.bind(this);
    }

    state = {
        open: false,
        isFinancial: undefined,
        paymentState: undefined,
        dataPerfezionamento: undefined,

    };

    formSetValues = { func: undefined };

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleRequestClose = () => {
        this.setState({ open: false });
    };

    onSubmit(e, { obj, showErrors, hideErrors }) {

        const insType = this.props.insType ? this.props.insType : this.props.match.params.insType;

        const orig = this.props.data.ContractItem;
        obj.contract_item_id = orig.contract_item_id;
        obj.insurance_contract_id = orig.insurance_contract_id;

        if (obj.item_payment_state_cd === 'PAID') {
            obj.state_cd = 'COMPLETE';
        }

        contractId = this.props.contractId ? this.props.contractId : this.props.match.params.contractId;

        if (obj.data_perfezionamento === '') {
            obj.data_perfezionamento = null;
        }

        if (authInfo.isBroker() || authInfo.isMasterBroker()) {
            obj.payment_modified_by = authInfo.loginInfo.userInfo.user_id;
        }

        this.props.updateContractItem({
            variables: { contractItem: obj },
            refetchQueries: [
                {
                    query: queries.allContractsItemsQuery,
                    variables: { contractId },
                },
                {
                    query: queries.contractItemByIdQuery,
                    variables: {
                        id: this.props.id ? this.props.id : this.props.match.params.id,
                        contractId,
                    },
                },

            ],
        }, this).then(
            (resp) => {
                hideErrors();
                if (resp.data.updateContractItemMutation &&
                    resp.data.updateContractItemMutation.validation &&
                    resp.data.updateContractItemMutation.validation.length > 0) {
                    showErrors(resp.data.updateContractItemMutation.validation);
                } else {
                    this.props.updateStatistics({ variables: { brokerId: 0, companyId: 0, contractItemId: resp.data.updateContractItemMutation.id } });
                    window.updateTotalsNeedFor = resp.data.updateContractItemMutation.id;

                    if (resp.data.updateContractItemMutation.data_perfezionamento) {
                        $('#data_perf_in_c_view').text(toItalianDateString(resp.data.updateContractItemMutation.data_perfezionamento));
                    }

                    if (this.props.afterSubmit) {
                        this.props.afterSubmit(obj);
                    } else {
                        if (obj.item_type_cd === 'sostituzione' && resp.data.updateContractItemMutation.customerId) {
                            history.push(`/requests/${insType}/edit/${resp.data.updateContractItemMutation.customerId}/${resp.data.updateContractItemMutation.id}?oci=${resp.data.updateContractItemMutation.oldContractId}`);
                        } else {
                            history.push(`/contract/view/${insType}/${contractId}`);
                        }

                    }

                }
            });
    }

    fornChanged(field, value, type, prevVal) {
        if (field === 'item_type_cd') {
            const item = this.props.data.ContractItem;
            const itemType = item.availableItemTypes.find(i => i.value === value);
            this.setState({ isFinancial: itemType.is_financial === 'true' });
        }
        if (field === 'item_payment_state_cd') {
            this.setState({ paymentState: value });
        }
        if (field === 'state_cd') {
            if (value === 'COMPLETE') {
                const now = new Date(Date.now());
                this.formSetValues.func({ data_perfezionamento: toInputDateString(now) });
                //this.setState({ dataPerfezionamento: '2345-11-23' });
            } else {
                this.formSetValues.func({ data_perfezionamento: '' });
                //this.setState({ dataPerfezionamento: null });
            }
        }

    }

    render() {

        const { classes } = this.props;
        const loading = this.props.data.loading;
        const item = this.props.data.ContractItem;
        history = this.props.history;
        let caption;
        // const availableItemTypes = [];
        // if (item && item.availableItemTypes) {
        //     item.availableItemTypes.forEach((type) => {
        //         availableItemTypes.push({ value: type, label: type.label });
        //     });
        // }
        let isNew;
        if (this.props.match && this.props.match.params.id === 'new') {
            caption = getTxt('New Contract Movement');
            isNew = true;
        } else {
            caption = getTxt('Edit Contract Movement');
        }

        if (this.props.onlyPaymentDetails) {
            caption = getTxt('Edit Payment Information');
        }

        let isFinancial;
        if (item) {
            const fromItem = item.contract_item_type.is_financial === 'true';
            isFinancial = this.state.isFinancial !== undefined ? this.state.isFinancial : fromItem;
        }

        let paymentState;
        if (item) {
            paymentState = this.state.paymentState !== undefined ? this.state.paymentState : item.item_payment_state_cd;
        }

        let possibleStates;
        if (paymentState === 'PAID') {
            possibleStates = getDictionary(DictionaryTypes.InsuranceItemState).filter(i => i.value === 'COMPLETE');
        } else {
            possibleStates = getDictionary(DictionaryTypes.InsuranceItemState);
        }

        const quietanza = location.href.includes('quietanza');

        let dataPerf;
        if (item) {
            dataPerf = this.state.dataPerfezionamento !== undefined ? this.state.dataPerfezionamento : item.data_perfezionamento;
        }

        return (

            <div className={classes.root}>
                {<Typography variant="h4" color="inherit" noWrap={false}>{caption}</Typography>}
                <LinearProgressEx loading={loading} />
                {!loading &&
                    <div>

                        {!this.props.onlyPaymentDetails &&
                            <MaterialForm
                                dataSource={item}
                                objType="contract_item"
                                onSubmit={this.onSubmit}
                                onChange={this.fornChanged}
                                setValues={this.formSetValues}
                            >


                                <p.group l={getTxt('Type and Dates')} />
                                {item.contract_item_type.value === 'Polizza' && item.contract_item_id !== '-1' &&
                                    <c.field l={getTxt('Type of Movementi')} f="item_type_cd" ui="select" d>
                                        {item.availableItemTypes}
                                    </c.field>
                                }
                                {(item.contract_item_type.value !== 'Polizza' || item.contract_item_id === '-1') && this.props.match.params.id === 'new' &&
                                    !quietanza &&
                                    <c.field l={getTxt('Type of Movementi')} f="item_type_cd" ui="select">
                                        {item.availableItemTypes}
                                    </c.field>
                                }
                                {(item.contract_item_type.value !== 'Polizza' || item.contract_item_id === '-1') && this.props.match.params.id !== 'new' &&
                                    !quietanza &&
                                    <c.field l={getTxt('Type of Movementi')} f="item_type_cd" ui="select" d>
                                        {item.availableItemTypes}
                                    </c.field>
                                }

                                {quietanza && item.availableItemTypes && item.availableItemTypes.length &&
                                    <c.field l={getTxt('Type of Movementi')} f="item_type_cd" ui="select" d v={item.availableItemTypes[0].value}>
                                        {item.availableItemTypes}
                                    </c.field>
                                }

                                <c.field l={getTxt('Collaboratore')} f="broker_id" ui="broker_select" no_deleted />
                                <c.field l={getTxt('State')} f="state_cd" ui="select" >
                                    {possibleStates}
                                </c.field>
                                <c.field f="data_perfezionamento" v={dataPerf} />
                                <c.field l={getTxt('Start Date')} f="item_start_date" />
                                <c.field l={getTxt('End Date')} f="item_end_date" />

                                <c.field l={getTxt('Notes')} f="item_notes" sm={12} />

                                {/* {isFinancial && <p.group l={getTxt('Cliente Amounts')} />}
                            {isFinancial && <c.field l={getTxt('Total Net Amount')} f="amount_netto" />}
                            {isFinancial && <c.field l={getTxt('Total Cliente Amount')} f="total_amount_client" />}
                            {isFinancial && <p.group l={getTxt('Premio')} />}
                            {isFinancial && <c.field l={getTxt('Agency Amount')} f="total_amount_agency" />}
                            {isFinancial && <c.field l={getTxt('Broker Amount')} f="total_amount_broker" />} */}

                                {isFinancial && <p.group l="Other Amounts" />}
                                {isFinancial && <c.field l={getTxt('Extra Broker %')} f="extra_broker_percent" />}
                                {isFinancial && <c.field l={getTxt('Diritti')} f="diritti" />}
                                {isFinancial && <c.field l={getTxt('Competenze')} f="competenze" />}
                                {isFinancial && <c.field l={getTxt('Accessori')} f="accessori" />}
                                {isFinancial && <c.field l={getTxt('Autentica')} f="autentica" />}
                                {isFinancial && <c.field l={getTxt('Spese')} f="spese" />}


                                <c.formButtons sm={12} onCancel={() => { this.props.history.goBack(); }} />



                            </MaterialForm>}


                        {this.props.onlyPaymentDetails && <MaterialForm dataSource={item} objType="contract_item" onSubmit={this.onSubmit} onChange={this.fornChanged}>

                            {isFinancial && <c.field l={getTxt('Payment Type')} f="item_payment_type_cd" ui="select" >
                                {getDictionary(DictionaryTypes.PublicPaymentType)}
                            </c.field>}
                            {isFinancial && <c.field l={getTxt('Payment State')} f="item_payment_state_cd" ui="select" >
                                {getDictionary(DictionaryTypes.InsuranceItemPaymentState)}
                            </c.field>}
                            {isFinancial && <c.field f="payment_account" ui="select" >
                                {getDictionary(DictionaryTypes.PaymentAccount)}
                            </c.field>}
                            {isFinancial && <c.field l={getTxt('Payment Date')} f="item_payment_date" />}
                            {isFinancial && <c.field l={getTxt('Payment notes')} f="payment_notes" />}

                            {(authInfo.isBroker() || authInfo.isMasterBroker()) &&
                                <c.field f="payment_modified_by_name" d v={authInfo.loginInfo.userInfo.displayName} />
                            }

                            <c.formButtons sm={12} onCancel={() => {
                                if (this.props.afterSubmit) {
                                    this.props.afterSubmit();
                                } else {
                                    this.props.history.goBack();
                                }

                            }} />



                        </MaterialForm>}


                    </div>
                }


            </div>

        );
    }
}


export default compose(

    graphql(queries.updateContractItemMutationName, {
        name: 'updateContractItem',
        options: {

            errorPolicy: 'all',
        },
    }),

    graphql(queries.contractItemByIdQuery, {
        options: props => (
            {
                variables: {
                    id: props.id ? props.id : (props.match.params.id ? props.match.params.id : 'new'),
                    contractId: props.contractId ? props.contractId : props.match.params.contractId,
                    insType: props.insType ? props.insType : props.match.params.insType,
                    quietanza: location.href.includes('quietanza'),
                },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }
        ),
    }),

    graphql(updateStatisticsMutation, {
        name: 'updateStatistics',
        options: {

            errorPolicy: 'all',
        },
    }),

)(withStyles(styles)(Contract_Item_Edit));
