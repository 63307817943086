import gql from 'graphql-tag';
import { requestCommonFields, viecoliFields, causioneFields, coseFields, personeFields } from '../request/requestQeries';

export const allBrokerPercentsQuery = gql`

query allBrokerPercentsQuery ($brokerId: Int, $companyId: Int)  {
    allBrokerPercents (brokerId: $brokerId, companyId: $companyId) {
        broker_percent_id
        insurance_company_id
        broker_id
        guaranty_type_id
        broker_percent
        broker_percent_extra
        broker{
            broker_id
            display_name
            first_name
            last_name
        }
        company {
            insurance_company_id
            company_name
        }
        guaranty_type{
            guaranty_type_id,
            guaranty_name
        }
    }
}
`;


export const brokerPercentByIdQuery = gql`

query brokerPercentByIdQuery($id: ID!) {
    brokerPercentById(id: $id) {
        broker_percent_id
        insurance_company_id
        broker_id
        guaranty_type_id
        broker_percent
        broker_percent_extra
        allGuaranties{
            guaranty_type_id,
            guaranty_name
        }
    }
}

`;

export const updateBrokerPercentMutation = gql`

mutation updateBrokerPercentMutation ($brokerPercent: broker_percent_input!) {
    updateBrokerPercentMutation(brokerPercent: $brokerPercent){
        id
        validation {
            field
            message
        }
    } 
}

`;


export const deleteBrokerPercentMutation = gql`

mutation deleteBrokerPercentMutation ($id: ID!) {
    deleteBrokerPercentMutation(id: $id){
        id
        validation {
            field
            message
        }
    } 
}

`;
