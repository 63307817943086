import gql from 'graphql-tag';


export const allFilesQuery = gql`

query allFilesQuery($entityId: ID!) {
    allFiles(entityId: $entityId) {
        document_id
        attached_to_entity_id
        file_type_cd
        file_name
        file_extension
        file_comment
    }
  }

`;

export const fileByIdQuery = gql`

query fileByIdQuery($id: ID!) {
    fileById(id: $id) {
        document_id
        attached_to_entity_id
        file_type_cd
        file_name
        file_extension
        file_comment
    }
  }

`;


export const deleteFileMutation = gql`

mutation DeleteFileMutation ($id: ID!) {
    deleteFileMutation(id: $id){
        id
        validation {
            field
            message
        }
    } 
}

`;



export const updateFileMutation = gql`

mutation UpdateFileMutation ($file: attached_file_input!) {
    updateFileMutation(file: $file){
        id
        tempId
        nextChunkLen
        nextPosition
        validation {
            field
            message
        }
    } 
}

`;


export const uploadNextChunkMutation = gql`

mutation UploadNextChunkMutation ($chunk: UploadFileChunk_input!) {
    uploadNextChunkMutation(chunk: $chunk){
        id
        tempId
        nextChunkLen
        nextPosition
        validation {
            field
            message
        }
    } 
}

`;

