

import { HashRouter, Route, Link } from 'react-router-dom';
import { userInfo } from 'os';


export const Roles = {
    ApplicationConfigurator: 'ApplicationConfigurator',
    CmsContentManager: 'CmsContentManager',
    AgencyAdministrator: 'AgencyAdministrator',
    MasterBroker: 'MasterBroker',
    Broker: 'Broker',
    SubBroker: 'SubBroker',
    InsuranceCompany: 'InsuranceCompany',
    Customer: 'Customer',
};
Roles.AllBrokers = [Roles.AgencyAdministrator, Roles.MasterBroker, Roles.Broker, Roles.SubBroker];

function AuthInfo() {
    this.loginInfo = undefined;

    const hasRoles = () => (this.loginInfo && this.loginInfo.userInfo && this.loginInfo.userInfo.roles);

    this.isInRole = (role) => {
        if (hasRoles()) {
            return this.loginInfo.userInfo.roles.find(r => r === role);
        }
        return false;
    };

    this.isAgencyAdministrator = () => {
        if (hasRoles()) {
            return this.loginInfo.userInfo.roles.find(r => r === Roles.AgencyAdministrator);
        }
        return false;
    };

    this.isAnyBroker = () => {
        if (hasRoles()) {
            const master = this.loginInfo.userInfo.roles.find(r => r === Roles.MasterBroker);
            const broker = this.loginInfo.userInfo.roles.find(r => r === Roles.Broker);
            const sub = this.loginInfo.userInfo.roles.find(r => r === Roles.SubBroker);
            return (master || broker || sub);
        }
        return false;
    };

    this.isMasterBroker = () => {
        if (hasRoles()) {
            return this.loginInfo.userInfo.roles.find(r => r === Roles.MasterBroker);
        }
        return false;
    };

    this.isBroker = () => {
        if (hasRoles()) {
            return this.loginInfo.userInfo.roles.find(r => r === Roles.Broker);
        }
        return false;
    };

    this.isSubBroker = () => {
        if (hasRoles()) {
            return this.loginInfo.userInfo.roles.find(r => r === Roles.SubBroker);
        }
        return false;
    };

    this.isCustomer = () => {
        if (hasRoles()) {
            return this.loginInfo.userInfo.roles.find(r => r === Roles.Customer);
        }
        return false;
    };

    this.isCompany = () => {
        if (hasRoles()) {
            return this.loginInfo.userInfo.roles.find(r => r === Roles.InsuranceCompany);
        }
        return false;
    };
}

export const authInfo = new AuthInfo();

const cookieName = 'auth';
function getCookie(name) {
    const matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)',
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

const cookie = getCookie(cookieName);

const authChangedListeners = [];

export function addAuthListener(func) {
    authChangedListeners.push(func);

    // authChangedListeners.forEach((f) => {

    // })
}

function setCookie(name, value, options) {
    options = options || {};

    var expires = options.expires;

    if (typeof expires == "number" && expires) {
        var d = new Date();
        d.setTime(d.getTime() + expires * 1000);
        expires = options.expires = d;
    }
    if (expires && expires.toUTCString) {
        options.expires = expires.toUTCString();
    }

    value = encodeURIComponent(value);

    var updatedCookie = name + "=" + value;

    for (var propName in options) {
        updatedCookie += "; " + propName;
        var propValue = options[propName];
        if (propValue !== true) {
            updatedCookie += "=" + propValue;
        }
    }

    document.cookie = updatedCookie;
}

export function getToken() {
    const cookieValue = getCookie(cookieName);
    if (cookieValue) {
        const loginInfo = JSON.parse(cookieValue);
        return loginInfo.token;
    }
    return null;
}

export function init(loginInfo) {
    authInfo.loginInfo = loginInfo;
    setCookie(cookieName, JSON.stringify(loginInfo), { expires: (loginInfo.tokenLife + 9999999999) });
    authChangedListeners.forEach((f) => {
        f(loginInfo);
    });
}

export function initFromCookie(history) {
    const cookieValue = getCookie(cookieName);
    if (cookieValue) {
        authInfo.loginInfo = JSON.parse(cookieValue);
        authChangedListeners.forEach((f) => {
            f(authInfo.loginInfo);
        });
    } else {
        authChangedListeners.forEach((f) => {
            f(undefined);
        });
        history.push('/login');
    }

}

function deleteCookie(name) {
    setCookie(name, '', {
        expires: -1,
    });
}

export function check(history) {
    const token = getToken();
    if (!token) {
        authChangedListeners.forEach((f) => {
            f(undefined);
        });
        history.push('login');
    } else {
        authChangedListeners.forEach((f) => {
            f(authInfo.loginInfo);
        });
    }


}

export function logout() {
    authInfo.loginInfo = undefined;
    deleteCookie(cookieName);
    authChangedListeners.forEach((f) => {
        f(undefined);
    });
    
    window.location.reload();

}

export function getUserRoles() {
    if (!authInfo.loginInfo) return [];
    if (!authInfo.loginInfo.userInfo) return [];
    if (!authInfo.loginInfo.userInfo.roles) return [];

    return authInfo.loginInfo.userInfo.roles;
}

