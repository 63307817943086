import gql from 'graphql-tag';
import { requestCommonFields, viecoliFields, causioneFields, coseFields, personeFields } from '../request/requestQeries';



export const quietanzamentoReportQuery = gql`

query quietanzamentoReportQuery ($customerId: Int, $brokerId: String, $linkedTo: Int, $markIfLinketTo: Int, $report: Boolean, $expFrom: String, $expTo: String, $statusofSimpli: String, $filter30: String)  {
    quietanzamentoReport (customerId: $customerId, brokerId: $brokerId, linkedTo: $linkedTo, markIfLinketTo: $markIfLinketTo, report: $report, expFrom: $expFrom, expTo: $expTo, statusofSimpli: $statusofSimpli, filter30: $filter30 ) {
        insurance_contract_id
        insurance_company_id
        customer_id
        owner_broker_id
        state_cd
        contract_number
        contract_start_date
        contract_end_date
        insurance_request_id
        insurance_quote_id
        insurance_type_cd
        ins_period
        contract_delivery_state
        contract_delivery_date
        contract_delivery_note
        contract_notes
        contract_amount_netto
        contract_total_client_amount
        amount_netto
        customer_solicited_email
        customer_solicited_phone
        tacito_rinnovo
        contratto_soggetto_regolazione
        competenze
        payment_type_cd
        customer {
            customer_id
            display_name
            customer_type_cd
            email,
            mobile_phone,
            phone,
            phone_active
        }
        broker {
            broker_id
            display_name
        }
        company {
            insurance_company_id
            company_name
        }
        contract_item{
            item_payment_date
        }
        contract_link{
            insurance_contract_id
        }
        ins_request_veicoli{
            vehicle_targa_matricola
        }
    }
}

`;






export const allContractsQuery = gql`

query AllContractsQuery ($customerId: Int, $brokerId: Int, $linkedTo: Int, $markIfLinketTo: Int, $report: Boolean, $expFrom: String, $expTo: String, $statusofSimpli: String, $filter30: String)  {
    contracts (customerId: $customerId, brokerId: $brokerId, linkedTo: $linkedTo, markIfLinketTo: $markIfLinketTo, report: $report, expFrom: $expFrom, expTo: $expTo, statusofSimpli: $statusofSimpli, filter30: $filter30 ) {
        insurance_contract_id
        insurance_company_id
        customer_id
        owner_broker_id
        state_cd
        contract_number
        contract_start_date
        contract_end_date
        insurance_request_id
        insurance_quote_id
        insurance_type_cd
        ins_period
        contract_delivery_state
        contract_delivery_date
        contract_delivery_note
        contract_notes
        contract_amount_netto
        contract_total_client_amount
        amount_netto
        customer_solicited_email
        customer_solicited_phone
        tacito_rinnovo
        contratto_soggetto_regolazione
        competenze
        payment_type_cd
        customer {
            customer_id
            display_name
            customer_type_cd
            email,
            mobile_phone,
            phone,
            phone_active
        }
        broker {
            broker_id
            display_name
        }
        company {
            insurance_company_id
            company_name
        }
        contract_item{
            item_payment_date
        }
        contract_link{
            insurance_contract_id
        }
        ins_request_veicoli{
            vehicle_targa_matricola
        }
    }
}

`;


export const allContractsQuery2 = gql`

query AllContractsQuery2 ($customerId: Int, $brokerId: Int, $linkedTo: Int, $markIfLinketTo: Int, $report: Boolean, $expFrom: String, $expTo: String, $statusofSimpli: String,
        $take: Int, $filters: [GridFilters], $id: ID! )  {
    contracts2 (customerId: $customerId, brokerId: $brokerId, linkedTo: $linkedTo, markIfLinketTo: $markIfLinketTo, report: $report, expFrom: $expFrom, expTo: $expTo, statusofSimpli: $statusofSimpli,
        take: $take, filters: $filters, id: $id ) {
            insurance_contract_id
            insurance_company_id
            customer_id
            owner_broker_id
            state_cd
            contract_number
            contract_start_date
            contract_end_date
            insurance_request_id
            insurance_quote_id
            insurance_type_cd
            ins_period
            contract_delivery_state
            contract_delivery_date
            contract_delivery_note
            contract_notes
            contract_amount_netto
            contract_total_client_amount
            amount_netto
            tacito_rinnovo
            contratto_soggetto_regolazione
            customer {
                customer_id
                display_name
                customer_type_cd
            }
            broker {
                broker_id
                display_name
            }
            company {
                insurance_company_id
                company_name
            }
            contract_item{
                item_payment_date
            }
            contract_link{
                insurance_contract_id
            }
            ins_request_veicoli{
                vehicle_targa_matricola
            }
    }
}

`;


export const contractByIdQuery = gql`

query ContractByIdQuery($id: ID, $requestId: Int, $insType: String!, $oldContractId: Int) {
    contract(id: $id, requestId: $requestId, insType: $insType, oldContractId: $oldContractId) {
        insurance_contract_id
        insurance_company_id
        customer_id
        owner_broker_id
        state_cd
        contract_number
        contract_start_date
        contract_end_date
        insurance_request_id
        insurance_quote_id
        tacito_rinnovo
        indicizzazione
        contratto_soggetto_regolazione
        status
        data_perfezionamento    
        ins_period
        contract_delivery_state
        contract_delivery_date
        contract_delivery_note
        contract_notes
        contract_amount_netto
        contract_total_client_amount
        old_contract_id
        base_ins_type
        customer {
            customer_id
            display_name
            customer_type_cd
        }
        broker {
            broker_id
            display_name
        }
        company {
            insurance_company_id
            company_name
        }
        request{
            ins_request_veicoli {
                ${requestCommonFields}
                ${viecoliFields}
            
            }


          
            ins_request_cauzione {
                ${requestCommonFields}
                ${causioneFields}
            }
            ins_request_cose {
                ${requestCommonFields}
                ${coseFields}
            }
            ins_request_persone {
                ${requestCommonFields}
                ${personeFields}
            }
            ins_request_other {
                ${requestCommonFields}
               
            }
        }
        quotes{
            insurance_quote_id
            customer_id
            insurance_company_id
            is_confirmed
        }
        brokerPercent{
                broker_percent_id
                broker_percent
                guaranty_type_id
        }
        ins_request_veicoli{
            vehicle_targa_matricola
        }
        
    }
}

`;

export const updateContractMutation = gql`

mutation UpdateContractMutation ($contract: contract_input!) {
    updateContractMutation(contract: $contract){
        id
        validation {
            field
            message
        }
    } 
}

`;

export const allContractsItemsQuery = gql`

query AllContractsItemsQuery($contractId: ID!) {
        contract_items(contractId: $contractId) {
            contract_item_id
            insurance_contract_id
            broker_id
            item_type_cd
            state_cd
            item_payment_state_cd
            item_payment_type_cd
            item_payment_date
            item_start_date
            item_end_date
            amount_netto
            total_amount_client
            total_amount_broker
            total_amount_agency

            extra_broker_percent
            diritti
            competenze
            accessori
            autentica
            spese
            payment_notes
            
            item_notes
            data_perfezionamento
            invoice_generated_for
            broker {
                broker_id
                display_name
                broker_type_cd
            }
            contract_item_type{
                contract_item_type_id
                value
                label
                is_financial
                is_negative
            }
            
            payment_account
           
    }
}

`;


export const contractItemByIdQuery = gql`

query ContractItemByIdQuery($id: ID, $contractId: Int, $quietanza: Boolean) {
    ContractItem(id: $id, contractId: $contractId, quietanza: $quietanza) {
            contract_item_id
            insurance_contract_id
            broker_id
            item_type_cd
            state_cd
            item_payment_state_cd
            item_payment_type_cd
            item_payment_date
            item_start_date
            item_end_date
            amount_netto
            total_amount_client
            total_amount_broker
            total_amount_agency

            extra_broker_percent
            diritti
            competenze
            accessori
            autentica
            spese
            payment_notes

            item_notes
            data_perfezionamento
            brokerPercent{
                broker_percent_id
                broker_percent
                guaranty_type_id
        }
        broker {
                broker_id
                display_name
            }
        availableItemTypes{
                contract_item_type_id
                value
                label
                is_financial
                is_negative
        }
        contract_item_type{
                contract_item_type_id
                value
                label
                is_financial
                is_negative
            }
        payment_account
        payment_modified_by
        payment_modified_by_name
    }
}

`;

export const updateContractItemMutationName = gql`

mutation UpdateContractItemMutationName ($contractItem: contract_item_input!) {
    updateContractItemMutation(contractItem: $contractItem){
        id
        customerId
        oldContractId
        data_perfezionamento
        validation {
            field
            message
        }
    } 
}

`;

export const updateContractItemTotalsMutation = gql`

mutation UpdateContractItemTotalsMutation ($itemId: Int!, $totalNetto: Float!, $brokerAmount:Float!, $agencyAmount: Float!, $clientAmount: Float!) {
    updateContractItemTotalsMutation(itemId: $itemId, totalNetto: $totalNetto, brokerAmount: $brokerAmount, agencyAmount: $agencyAmount, clientAmount: $clientAmount){
        id

        validation {
            field
            message
        }
    } 
}

`;

export const updateContractLinkMutation = gql`

mutation UpdateContractLinkMutation ($linkedTo: Int!, $linkedIds: [Int]!) {
    updateContractLink(linkedTo: $linkedTo, linkedIds: $linkedIds){
        id
        validation {
            field
            message
        }
    } 
}

`;





export const allContractItemTypesQuery = gql`

query allContractItemTypesQuery  {
    contract_item_types{
        contract_item_type_id
        value
        label
        is_financial
        is_negative
    }
}
`;


export const сontractItemTypeByIdQuery = gql`

query ContractItemTypeByIdQuery($id: ID) {
    contract_item_type(id: $id) {
        contract_item_type_id
        value
        label
        is_financial
        is_negative
    }
}

`;

export const updateContractItemTypeMutation = gql`

mutation updateContractItemTypeMutation ($contractItemType: contract_item_type_input) {
    updateContractItemTypeMutation(contractItemType: $contractItemType){
        id
        validation {
            field
            message
        }
    } 
}

`;

export const deleteContractItemTypeMutation = gql`

mutation deleteContractItemTypeMutation ($id: ID) {
    deleteContractItemTypeMutation(id: $id){
        id
        validation {
            field
            message
        }
    } 
}

`;


export const soliciteCustomerMutation = gql`

mutation soliciteCustomerMutation ($contractId: ID!, $email: Boolean, $phone: Boolean ) {
    soliciteCustomer(contractId: $contractId, email: $email, phone: $phone ){
        id
        validation {
            field
            message
        }
    } 
}

`;

export const deleteContractMutation = gql`

mutation deleteContractMutation ($contractId: ID!) {
    deleteContract(contractId: $contractId){
        id
        validation {
            field
            message
        }
    } 
}

`;


export const deleteContractItemMutation = gql`

mutation deleteContractItemMutation($contractItemId: ID) {
    deleteContractItemMutation(contractItemId: $contractItemId) {
      id
      validation {
        field
        message
      }
    }
  }`;
