import ComponentEx from 'components/ComponentEx';
import TabContainer from 'components/TabContainer';
import { AppBar } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Paper } from '@material-ui/core';
import { Tabs, Tab } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { getTxt } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';
import * as Icon from '@material-ui/icons';
import React from 'react';
import { compose, graphql } from 'react-apollo';
import { Link } from 'react-router-dom';
import ReactSVG from 'react-svg';
import NavPills from 'components/NavPills/NavPills';
import ItemGrid from 'components/Grid/ItemGrid';
import HeaderViewItem from 'components/HeaderViewItem';
import LinearProgressEx from '../../components/LinearProgressEx';
import { DictionaryTypes, getLabel } from '../../global/dictionaryCache';
import Custom_Fields_View from '../../modules/publicInsType/Custom_Fields_View';
import { typeByIdQuery } from '../../modules/publicInsType/publicInsTypeQueries';
import Public_Ins_Type_Details_View from '../../modules/publicInsType/Public_Ins_Type_Details_View';
import GuarantyTypeSearch from '../../modules/guaranty/GuarantyTypeSearch';
import RegularCard from '../../components/dashboard/RegularCard';
import { publicPaymentTypeByIdQuery } from './paymentTypeQueries';
import Public_Paym_Type_Details_View from './Public_Paym_Type_Details_View';


const styles = theme => ({

   root: {
        backgroundColor: theme.palette.grey,
        margin: theme.spacing.unit * 3,
        marginTop: 0,
    },

    button: {
        margin: theme.spacing.unit,
    },

    badge: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        marginTop: 0,
    },

    gridRoot: {
        height: '100%',
    },


    formCaption: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit * 2,
        width: '100%',
        position: 'relative',
        height: 40,
    },
    formCaptionLabel: {
        position: 'absolute',
        //color: theme.palette.primary1,
        backgroundColor: 'transparent',
        left: 25,
        paddingLeft: 10,
        paddingRight: 10,
    },

    rootPaper: theme.mixins.gutters({

        paddingTop: 0,
        paddingBottom: 0,
        marginTop: 0,// theme.spacing.unit * 3,

    }),

    formHeaderRoot: {
        padding: theme.spacing.unit * 2,
        position: 'relative',
    },

    formHeaderFieldLabel: {
        color: theme.palette.text.disabled,
        fontWeight: 'bold',

    },

    tableHeader: {
        backgroundColor: theme.palette.background.paper,
    },

    headerIcon: {
        width: theme.spacing.unit * 8,
        height: theme.spacing.unit * 9,
        marginRight: theme.spacing.unit * 2,
    },

    cmdBar: {
        position: 'absolute',
        right: theme.spacing.unit * 3,
    },
    dialogText: {
        color: theme.palette.error.main,
    },

    noGuarantyErrorText: {
        ...theme.typography.body1,
        color: theme.palette.error.main,
        paddingLeft: theme.spacing.unit * 2,
    },

});

const tabsStateGlobalId = 'Public_Payment_Type_View_Tab';

class Public_Payment_Type_View extends ComponentEx {

    innerID = 'Public_Payment_Type_View';

    state = {
        value: window[tabsStateGlobalId] ? window[tabsStateGlobalId] : 0,
    };

    handleChange = (event, value) => {
        this.setState({ value });
    };

    componentWillUnmount() {
        window[tabsStateGlobalId] = this.state.value;
    }

    render() {

        const { classes } = this.props;
        const { value } = this.state;

        const loading = this.props.data.loading;
        const item = this.props.data.publicPaymentTypeById;

        return (

            <div className={classes.root}>

                <LinearProgressEx loading={loading} />

                <RegularCard

                    headerColor="red"
                    cardTitle={item && item.label}
                    cardSubtitle={getTxt('User Payment Type')}
                    content={
                        <Grid container>
                            <ItemGrid xs={12} sm={12} md={4}>
                                <HeaderViewItem l={getTxt('Key')}>
                                    <Typography variant="body1" >
                                        {item && item.value}
                                    </Typography>
                                </HeaderViewItem>
                            </ItemGrid>

                            <ItemGrid xs={12} sm={12} md={8}>
                                <HeaderViewItem l={getTxt('Name')}>
                                    <Typography variant="body1" >
                                        {item && item.label}
                                    </Typography>
                                </HeaderViewItem>
                            </ItemGrid>

                        </Grid>
                    }
                />



                <NavPills
                    color="warning"
                    onChange={this.handleChange}
                    alignCenter
                    active={value}
                    tabs={[
                        {
                            tabButton: getTxt('Details'),
                            tabIcon: Icon.Details,
                            tabContent: (
                                <TabContainer>
                                    {item && <Public_Paym_Type_Details_View
                                        item={item}
                                        id={item.public_payment_type_id}
                                        history={this.props.history}
                                    />}
                                </TabContainer>
                            ),
                        },

                    ]}
                />





            </div >
        );
    }
}


export default compose(
    graphql(publicPaymentTypeByIdQuery, {
        options: props => (
            {
                variables: {
                    id: props.match.params.id,
                },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }
        ),
    }),
)(withStyles(styles)(Public_Payment_Type_View));

