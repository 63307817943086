const descriptors = {};

export function getRequiredDescrsTypes(types) {
    return types.filter(id => descriptors[id] === undefined);
}

export function addDescriptors(descriptorsObj) {
    Object.keys(descriptorsObj).forEach((key) => { descriptors[key] = descriptorsObj[key]; }, this);
}

export function addDescriptor( key, descriptorObj) {
    descriptors[key] = descriptorObj;
}

export function getEntityDescriptor(type) {
    return descriptors[type];
}

export function getFieldLabel(type, fieldId) {
    if (!type) {
        return null;
    }

    if (!fieldId && type.indexOf('.') !== -1) {
        fieldId = type.split('.')[1];
        type = type.split('.')[0];
    }

    const descr = descriptors[type];
    if (!descr) {
        return fieldId;
    }

    const fieldMeta = descr[fieldId];
    if (!fieldMeta) {
        return fieldId;
    }

    return fieldMeta.label;
}


export const EntityTypes = {
    Entity: 'entity',
    Broker: 'broker',
    Customer: 'customer',
    InsuranceCompany: 'company',
    InsuranceCompanyReferent: 'insurance_company_referent',
    InsuranceQuote: 'insurance_quote',
    AttachedFile: 'attached_file',
    DictionaryItem: 'dictionary_item',
    Contract: 'contract',
    ContractItem: 'contract_item',
    Referent: 'referent',
    GuarantyType: 'guaranty_type',
    InsuranceCompanyGuaranty: 'insurance_company_guaranty',
    ContractGuaranty: 'contract_guaranty',
    PublicInsType: 'public_ins_type',

    InsuranceRequestRca: 'ins_request_veicoli',
    
    InsuranceRequestCausione: 'ins_request_cauzione',
    InsuranceRequestCose: 'ins_request_cose',
    InsuranceRequestPersone: 'ins_request_persone',
    InsuranceRequestOther: 'ins_request_other',

    Translation: 'translation',
    BrokerPercent: 'broker_percent',
    Claim: 'claim',
    ContractItemType: 'contract_item_type',
    BrokerPayment: 'broker_payment',
    CompanyPayment: 'company_payment',
    CustomerPayment: 'customer_payment',
    PublicPaymentType: 'public_payment_type',
    User: 'user',
    GlobalSettings: 'global_settings',
    EmailTemplate: 'email_template',
    ReportHeader: 'report_header',

    Iban: 'iban',
    CompanyIban: 'company_iban',
    BrokerFreeItem: 'broker_free_item',
    CompanyFreeItem: 'company_free_item',
    TransitAccount: 'transit_account',
};
