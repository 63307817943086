export const ReportTypes = {
    AllCustomersList: 'all_customers_list',
    PrivacyAgriment: 'privacy_agriment',
    ContractItemsReport: 'contract_items',
    CustomerContracts: 'customer_contracts',
    ClientBalance: 'client_balance',
    CompanyBalance: 'company_balance',
    BrokerBalance: 'broker_balance',
    Contracts: 'contracts',
    TrSimpli: 'tr_simpli',
    Incarico: 'incarico',

    Request_Details: 'Request_Details',
    Invoice: 'invoice',
    Claim_Details: 'claim_details',
    Contabili: 'contabili',
    Dichiarazione: 'dichiarazione',
    PolizzeAnnullate: 'polizze_annullate',
    BrokerContracts: 'broker_contracts',
    CustomerContracts2: 'customer_contracts2',
    Quietanzamento: 'quietanzamento',
};