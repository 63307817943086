import React, { Component } from 'react';
import { getTxt, toDecimalString } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';
import { graphql, compose } from 'react-apollo';
import { Checkbox } from '@material-ui/core';
import { Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import $ from 'jquery';
import { IconButton } from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import CurrencyGridFormatter from 'components/CurrencyGridFormatter';
import { FilteringState, IntegratedFiltering, IntegratedSorting, SortingState } from '@devexpress/dx-react-grid';
import { Grid, TableFilterRow, TableHeaderRow, VirtualTable, TableColumnResizing } from '@devexpress/dx-react-grid-material-ui';
import c from '../../components/form/Components';
import LinearProgressEx from '../../components/LinearProgressEx';
import * as queries from '../../modules/guaranty/guarantyTypeQueries';
import RegularCard from '../../components/dashboard/RegularCard';
import ComponentEx from '../../components/ComponentEx';
import { getLabel, DictionaryTypes, getDictionary } from '../../global/dictionaryCache';
import { filterRowMessages, tableMessages, createGridResizerCols } from '../../global/appGlobal';


const tableColumnExtensionsRigtAlign = [
    { columnName: 'default_franchise_amount', align: 'right' },
    { columnName: 'default_maximum_amount', align: 'right' },
    { columnName: 'default_overdraft_amount', align: 'right' },
    //    { columnName: 'guaranty_type_id', width: 100 },  
];

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        margin: theme.spacing.unit * 3,
    },
    rootAsChildControl: {
        backgroundColor: theme.palette.background.paper,
    },
    button: {
        marginRight: theme.spacing.unit,
    },

    badge: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        marginTop: 0,

    },
    gridRoot: {
        height: '100%',
    },

    tableHeader: {
        backgroundColor: theme.palette.background.paper,
    }
});

class GuarantyTypeSearch extends ComponentEx {


    state = {
        delDlgOpen: false,
        delId: undefined,
    };

    componentDidMount() {
        $("table[class*='Table-headTable']").addClass(this.props.classes.tableHeader);
    }

    handleDelete() {

        this.props.deleteGuarantyType({
            variables: { id: this.state.delId },
            refetchQueries: [
                {
                    query: queries.allGuarantyTypesQuery,
                    variables: { insurance_type_cd: this.props.insuranceType },
                },
            ],
        });
        this.setState({ delDlgOpen: false });
    }

    render() {

        const columns = [


            { name: 'guaranty_name', title: getTxt('Nome') },
            { name: 'used_by_default', title: getTxt('Predefinito') },
            { name: 'is_financial', title: getTxt('Finanziario') },
            { name: 'insurance_type_cd', title: getTxt('insurance type') },


            { name: 'default_franchise_amount', title: getTxt('Franchigia') },
            { name: 'default_maximum_amount', title: getTxt('Massimale') },
            { name: 'default_overdraft_amount', title: getTxt('Scoperto') },

            { name: 'guaranty_type_id', title: getTxt('Azioni') },

        ];


        createGridResizerCols(this.state, columns, {}, 180);

        const { classes } = this.props;
        const items = this.props.data.allGuarantyTypes ? this.props.data.allGuarantyTypes : [];

        return (

            <div className={this.props.noAddBtn ? classes.rootAsChildControl : classes.root}>
                <LinearProgressEx loading={this.props.data.loading} />
                <RegularCard

                    headerColor="red"
                    cardTitle={getTxt('Guaranty Types')}
                    cardSubtitle={getTxt('List of Guaranty Types')}
                    content={
                        <div>
                            {!this.props.noAddBtn && <Button
                                color="primary"
                                className={classes.button}
                                onClick={() => {
                                    if (!this.props.insuranceType) {
                                        this.props.history.push('/guaranty_types/new');
                                    } else {
                                        this.props.history.push(`/guaranty_types/new/for_ins_type/${this.props.insuranceType}`);
                                    }

                                }}
                            >
                                {getTxt('Add Guaranty Type')}
                            </Button>}
                        </div>

                    }
                />


                <Grid

                    rows={items}
                    columns={columns}

                >

                    <FilteringState
                        defaultFilters={[{}]}
                    />



                    <IntegratedFiltering columnExtensions={[]} />
                    <SortingState
                        columnExtensions={[{ columnName: 'guaranty_type_id', sortingEnabled: false }]}
                        defaultSorting={[
                            { columnName: 'guaranty_name', direction: 'asc' },
                        ]}
                    />
                    <IntegratedSorting />



                    <CurrencyGridFormatter
                        for={['default_franchise_amount', 'default_maximum_amount', 'default_overdraft_amount']}
                    />

                    <VirtualTable
                        messages={tableMessages}
                        columnExtensions={tableColumnExtensionsRigtAlign}
                        cellComponent={({ row, column, style, ...props }) => {
                            if (column.name === 'guaranty_name') {
                                return (
                                    <VirtualTable.Cell>
                                        <Link to={`/guaranty_types/${row.guaranty_type_id}`}>
                                            {row.guaranty_name}
                                        </Link>
                                    </VirtualTable.Cell>);
                            }
                            if (column.name === 'used_by_default') {
                                return (
                                    <VirtualTable.Cell>
                                        <Checkbox disabled checked={row.used_by_default === 'true'} />
                                    </VirtualTable.Cell>);
                            }
                            if (column.name === 'is_financial') {
                                return (
                                    <VirtualTable.Cell>
                                        <Checkbox disabled checked={row.is_financial === 'true'} />
                                    </VirtualTable.Cell>);
                            }
                            if (column.name === 'guaranty_type_id') {
                                return (<VirtualTable.Cell>

                                    <IconButton size="small" onClick={() => { this.setState({ delDlgOpen: true, delId: row.guaranty_type_id }); }} className={classes.inGridButton} >
                                        <DeleteIcon />
                                    </IconButton>


                                </VirtualTable.Cell>);
                            }
                            if (column.name === 'insurance_type_cd') {
                                return (<VirtualTable.Cell> {getLabel(DictionaryTypes.InsuranceType, row.insurance_type_cd)}</VirtualTable.Cell>);
                            }




                            return <VirtualTable.Cell {...props} />;
                        }}
                    />


                    <TableFilterRow
                        messages={filterRowMessages}
                        cellComponent={({ column, filter, onFilter, ...props }) => {
                            if (column.name === 'insurance_type_cd') {
                                return (
                                    <c.gridColFilter noFilterVal={getTxt('Tutti')} setFilter={onFilter} >
                                        {getDictionary(DictionaryTypes.InsuranceType)}
                                    </c.gridColFilter>);
                            }
                            if (column.name === 'is_financial' || column.name === 'used_by_default') {
                                return (
                                    <c.gridColFilter noFilterVal={getTxt('All')} setFilter={onFilter} >
                                        {[{ value: 'true', label: getTxt('Yes') }, { value: 'false', label: getTxt('No') }]}
                                    </c.gridColFilter>);
                            }


                            return <TableFilterRow.Cell onFilter={onFilter} filter={filter} {...props} />;
                        }}
                    />

                    <TableColumnResizing
                        columnWidths={this.state.columnWidths}
                        onColumnWidthsChange={(cols) => { this.setState({ columnWidths: cols }); }}
                        defaultColumnWidths={this.state.defColumnWidths}
                    />
                    <TableHeaderRow showSortingControls />

                </Grid>
                <Dialog onClose={() => { this.setState({ delDlgOpen: false }); }} open={this.state.delDlgOpen}>
                    <DialogTitle >{getTxt('Delete Guaranty type.')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <span>{getTxt('Guaranty type will be delete on server.')}</span>
                            <br />
                            <span >{getTxt('Are you sure do you want delete the Guaranty type?')}</span>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleDelete(this.state.delId)} color="primary">
                            {getTxt('Ok')}
                        </Button>
                        <Button onClick={() => { this.setState({ delDlgOpen: false }); }} color="primary">
                            {getTxt('Annula')}
                        </Button>

                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default compose(

    graphql(queries.allGuarantyTypesQuery, {
        options: props => (
            {
                variables: {
                    insurance_type_cd: props.insuranceType,
                },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }
        ),

    }),

    graphql(queries.deleteGuarantyTypeMutation, {
        name: 'deleteGuarantyType',
        options: {
            errorPolicy: 'all',
        },
    }),

)(withRouter(withStyles(styles)(GuarantyTypeSearch)));
