import ComponentEx from 'components/ComponentEx';
import { EntityTypes } from 'global/entityDescriptors';
import { withStyles } from '@material-ui/core/styles';
import $ from 'jquery';
import React from 'react';
import moment from 'moment';
import { randomString } from 'global/appGlobal';
import c from '../../../components/form/Components';
import MaterialForm from '../../../components/form/MaterialForm';
import { DictionaryTypes, getDictionary, getValue } from '../../../global/dictionaryCache';
import RequestFormButtons from '../../request/RequestFormButtons';

moment.locale('it-it');

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing.unit * 2,
        margin: theme.spacing.unit * 2,
    },

});


class Cose_Edit_Form extends ComponentEx {

    id = randomString(5, 'aA');

    state = {

    };

    render() {

        const { classes } = this.props;

        return (

            <div id={this.id}>
                <MaterialForm
                    dataSource={this.props.request}
                    objType={EntityTypes.InsuranceRequestCose}
                    onSubmit={this.props.onSubmit}
                    fields={this.props.fields}

                >
                    <c.group
                        l=""
                        fields={this.props.fields}
                        child={[
                            'piani_fuori_terra',
                            'numero_fabbricati',
                            'numero_unità_abitative',
                            'anno_di_costruzione',
                            'anno_di_ricostruzione',
                            'somma_assicurata',
                            'franchigia_frontale',
                            'quota_assicurata',
                            'tipo_fabbricato',
                            'indicizzazione',
                            'inizio_lavori',
                            'fine_lavori',
                            'decorrenza_polizza_decennale',
                            'committente',
                            'appaltatore',
                            'tipologia_lavori',
                            'descrizione_lavori',
                            'numero_contratto_appalto',
                            'costo_complessivo_opera',
                            'numero_questionario',
                            'ubicazione_del_rischio',
                            'valore_fabbricat',
                            'valore_contenuto',
                            'impianto_di_allarme',
                            'contratto_soggetto_regolazione',
                        ]}
                    />

                    <c.field f="piani_fuori_terra" />
                    <c.field f="numero_fabbricati" />
                    <c.field f="numero_unita_abitative" />
                    <c.field f="anno_di_costruzione" />
                    <c.field f="anno_di_ricostruzione" />
                    <c.field f="somma_assicurata" />
                    <c.field f="franchigia_frontale" />
                    <c.field f="quota_assicurata" />
                    <c.field f="tipo_fabbricato" ui="select" >
                        {getDictionary(DictionaryTypes.PropertyType)}
                    </c.field>
                    <c.field f="indicizzazione" />
                    <c.field f="inizio_lavori" />
                    <c.field f="fine_lavori" />
                    <c.field f="decorrenza_polizza_decennale" />
                    <c.field f="committente" />
                    <c.field f="appaltatore" />
                    <c.field f="tipologia_lavori" ui="select" >
                        {getDictionary(DictionaryTypes.WorkType)}
                    </c.field>
                    <c.field f="descrizione_lavori" />
                    <c.field f="numero_contratto_appalto" />
                    <c.field f="costo_complessivo_opera" />
                    <c.field f="numero_questionario" />
                    <c.field f="ubicazione_del_rischio" />
                    <c.field f="valore_fabbricat" />
                    <c.field f="valore_contenuto" />
                    <c.field f="impianto_di_allarme" />
                    <c.field f="contratto_soggetto_regolazione" />


                    <c.group
                        l="Informazioni Aggiuntive"
                        fields={this.props.fields}
                        child={[
                            'additional_information',
                            'comments',
                        ]}
                    />

                    <c.field f="additional_information" multiline sm={12} />
                    <c.field f="comments" multiline sm={12} />

                    <RequestFormButtons
                        history={this.props.history}
                        transferToContract={this.props.transferToContract}
                        isNew={this.props.isNew}
                        noSave={this.props.oldContractId}
                    />

                </MaterialForm>

            </div>

        );
    }
}

export default withStyles(styles)(Cose_Edit_Form);
