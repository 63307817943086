import { Button, makeStyles } from "@material-ui/core";
import React from 'react';
import { getTxt } from "../../global/appGlobal";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";


const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        top: 115,
        left: 40,
        zIndex: 99999,
        opacity: 0.72,
    },


}));

export default function GridFiltersButton({ onClick, filters }) {

    const classes = useStyles();
    const [currentFilters, setCurrentFilters] = useState(filters);
    const btnRef = useRef();

    let visible = false;
    if (currentFilters.length !== filters.length) {
        visible = true;
    } else {
        for (let i = 0; i < currentFilters.length; i += 1) {
            const oldFilter = currentFilters[i];
            const newFilter = filters.find(nf => nf.columnName === oldFilter.columnName);
            if (!newFilter) {
                visible = true;
                break;
            } else if (oldFilter.operation !== newFilter.operation) {
                visible = true;
                break;
            } else if (oldFilter.value !== newFilter.value) {
                visible = true;
                break;
            }
        }
    }


    return (
        <div className={classes.root} style={{ display: visible ? 'block' : 'none' }}>
            <Button
                ref={btnRef}
                variant="contained"
                color="primary"
                className="pulse"
                onClick={() => {
                    setCurrentFilters(filters);
                    if (onClick) {
                        onClick(filters);
                    }
                }}
            >
                {getTxt('Apply Filters')}

            </Button>
        </div >
    );
}