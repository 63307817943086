import React, { Component } from 'react';
import { getTxt } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';
import { withTheme } from '@material-ui/core';

import { Typography } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';



import { Paper } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { AppBar } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { TextField } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import gql from 'graphql-tag';
import { graphql, compose } from 'react-apollo';
import { Tabs, Tab } from '@material-ui/core';
import * as Icon from '@material-ui/icons';
import GridEx from 'components/form/GridEx';
import { Button } from '@material-ui/core';
import moment from 'moment';
import { Badge } from '@material-ui/core';

import ComponentEx from 'components/ComponentEx';

import { queryGetCustomerById, customerBriefQuery, allCustomersQuery } from '../../modules/client/clientQueries';
import c from '../../components/form/Components';
import LinearProgressEx from '../../components/LinearProgressEx';
import { getLabel, DictionaryTypes, getDictionary, getValue } from '../../global/dictionaryCache';
import MaterialForm from '../../components/form/MaterialForm';
import MFormText from '../../components/form/MFormText';
import { authInfo, Roles, getToken } from '../../global/auth';

import { Dialog } from '@material-ui/core';
import { DialogTitle } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { DialogContentText } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing.unit * 2,
        // margin: theme.spacing.unit * 2,
    },

    button: {
        margin: theme.spacing.unit,
    },

    badge: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        marginTop: 0,
    },

    gridRoot: {
        height: '100%',
    },


    formCaption: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit * 2,
        width: '100%',
        position: 'relative',
        height: 40,
    },
    formCaptionLabel: {
        position: 'absolute',
        backgroundColor: 'transparent',
        left: 25,
        paddingLeft: 10,
        paddingRight: 10,
    },

    rootPaper: theme.mixins.gutters({

        paddingTop: 0,
        paddingBottom: 0,
        marginTop: 0,// theme.spacing.unit * 3,

    }),

});


class Client_Company_Details_Edit extends ComponentEx {

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.fornChanged = this.fornChanged.bind(this);
        this.copyAddrFromLagalAddr = this.copyAddrFromLagalAddr.bind(this);
        this.copyAddrFromLagalAddrFix = this.copyAddrFromLagalAddrFix.bind(this);
    }

    state = {

        address_country_cd: undefined,
        address_province: undefined,
        legal_address_country_cd: undefined,
        legal_address_province: undefined,
        fornGetValuesFunc: undefined,

    };

    formGetValues = { func: undefined };
    formSetValues = { func: undefined };

    onSubmit(e, { obj, showErrors, hideErrors }) {

        obj.customer_id = this.props.data.customer.customer_id;
        if (!obj.customer_id) {
            obj.customer_id = this.props.data.customer.customer_id;
        }
        obj.customer_type_cd = 'COMPANY';
        delete obj.undefined; //fix it later

        const this1 = this;
        this.props.updateCustomer({
            variables: { customer: obj },

            refetchQueries: [
                {
                    query: customerBriefQuery,
                    variables: { id: this.props.data.customer.customer_id },
                },
                {
                    query: queryGetCustomerById,
                    variables: { id: this.props.data.customer.customer_id },
                },
                {
                    query: allCustomersQuery,
                },
            ],
        }, this).then(
            (resp) => {
                hideErrors();
                const serverErrors = resp.data.updateCustomerMutation.validation;
                const id = resp.data.updateCustomerMutation.id;

                if (serverErrors && serverErrors.length > 0) {
                    showErrors(serverErrors);
                } else {


                    if (!this1.props.isProfile) {
                        this1.props.history.push(`/customers/company/${id}`);
                    } else {
                        this1.setState({ okDlg: true });
                    }
                }
            });
    }

    fornChanged(field, value, type, prevVal) {
        if (field === 'address_country_cd') {

            const country = getValue(DictionaryTypes.Country, value);

            if (country) {
                this.setState({ address_country_cd: country });
            }
        }
        if (field === 'address_province') {

            const prov = getValue(DictionaryTypes.ItalianProvinces, value);

            if (prov) {
                this.setState({ address_province: prov });
            }


        }
        if (field === 'legal_address_country_cd') {

            const country = getValue(DictionaryTypes.Country, value);

            if (country) {
                this.setState({ legal_address_country_cd: country });
            }
        }
        if (field === 'legal_address_province') {
            const prov = getValue(DictionaryTypes.ItalianProvinces, value);

            if (prov) {
                this.setState({ legal_address_province: prov });
            }

        }
    }

    copyAddrFromLagalAddrFix() {
        this.copyAddrFromLagalAddr();
        setTimeout(() => {
            this.copyAddrFromLagalAddr();
        }, 200);

    }

    copyAddrFromLagalAddr() {
        const values = this.formGetValues.func();
        const copy = {};
        copy.address_country_cd = values.legal_address_country_cd;
        copy.address_province = values.legal_address_province;
        copy.address_city = values.legal_address_city;
        copy.address_street = values.legal_address_street;
        copy.address_street_number = values.legal_address_street_number;
        copy.address_cap = values.legal_address_cap;
        this.formSetValues.func(copy);

    }

    render() {

        const { classes } = this.props;
        const loading = this.props.data.loading;
        const customer = this.props.data.customer;

        let address_country_cd;
        let address_province;
        if (customer) {
            address_country_cd = this.state.address_country_cd ? this.state.address_country_cd : customer.address_country_cd;
            address_province = this.state.address_province ? this.state.address_province : customer.address_province;
        }

        let legal_address_country_cd;
        let legal_address_province;
        if (customer) {
            legal_address_country_cd = this.state.legal_address_country_cd ? this.state.legal_address_country_cd : customer.legal_address_country_cd;
            legal_address_province = this.state.legal_address_province ? this.state.legal_address_province : customer.legal_address_province;
        }

        let caption;

        if (this.props.match && !this.props.match.params.id) {
            caption = getTxt('Nuovo Company Cliente');
        } else {
            caption = (!loading && customer) ? 'Modifica - ' + customer.display_name : 'Modifica Company Cliente';
        }

        if (this.props.isProfile) {
            caption = getTxt('Customer Profile');
        }

        return (

            <div className={classes.root}>
                <Typography variant="h4" color="inherit" noWrap={false}>{getTxt(caption)}</Typography>
                <LinearProgressEx loading={loading} />
                {!loading &&
                    <div>
                        <MaterialForm
                            dataSource={customer}
                            objType="customer"
                            onSubmit={this.onSubmit}
                            onChange={this.fornChanged}
                            getValues={this.formGetValues}
                            setValues={this.formSetValues}
                        >

                            <c.group l="Dati Compagnia" />
                            <c.field l="Codice Interno" f="customer_id" d />
                            <c.field l="Ragione sociale" f="company_name" />




                            <c.field
                                l="Forma giuridica"
                                f="company_reg_type_cd"
                                ui="select"
                            >
                                {getDictionary(DictionaryTypes.CompanyRegType)}
                            </c.field>

                            <c.field l="Collaboratore" f="owner_broker_id" ui="broker_select" no_deleted d={authInfo.isSubBroker() || authInfo.isCustomer()} />

                            <c.group l="Sede Legale" d />

                            <c.field
                                l="Nazione"
                                f="legal_address_country_cd"
                                ui="auto-select"
                            >
                                {getDictionary(DictionaryTypes.Country)}
                            </c.field>
                            {legal_address_country_cd === 'IT' &&
                                <c.field
                                    l={getTxt('Province')}
                                    f="legal_address_province"
                                    ui="auto-select"
                                >
                                    {getDictionary(DictionaryTypes.ItalianProvinces)}
                                </c.field>}
                            {legal_address_country_cd === 'IT' &&
                                <c.field
                                    provinceId={legal_address_province}
                                    l="Città"
                                    f="legal_address_city"
                                    ui="city-auto-select"
                                />}
                            {legal_address_country_cd !== 'IT' &&
                                <c.field l="Città" f="legal_address_city" />
                            }

                            <c.field l="Indirizzo" f="legal_address_street" />
                            <c.field l="Civico" f="legal_address_street_number" />
                            <c.field l="CAP" f="legal_address_cap" />

                            <c.group l="Sede Operativa" />
                            <GridEx item sm={12} xs={12}>
                                <Button
                                    variant="contained"
                                    size="small"
                                    className={classes.calcCodeBtn}
                                    onClick={this.copyAddrFromLagalAddrFix}
                                >
                                    Copia da Sede Legale
                                </Button>
                            </GridEx>


                            <c.field
                                l="Nazione"
                                f="address_country_cd"
                                ui="auto-select"
                            >
                                {getDictionary(DictionaryTypes.Country)}
                            </c.field>
                            {address_country_cd === 'IT' &&
                                <c.field
                                    l={getTxt('Province')}
                                    f="address_province"
                                    ui="auto-select"
                                >
                                    {getDictionary(DictionaryTypes.ItalianProvinces)}
                                </c.field>}
                            {address_country_cd === 'IT' &&
                                <c.field
                                    provinceId={address_province}
                                    l="Città"
                                    f="address_city"
                                    ui="city-auto-select"
                                />}
                            {address_country_cd !== 'IT' &&
                                <c.field l="Città" f="address_city" />
                            }

                            <c.field l="Indirizzo" f="address_street" />
                            <c.field l="Civico" f="address_street_number" />
                            <c.field l="CAP" f="address_cap" />


                            <c.group l="Informazioni Contatti" />
                            <c.field l="Telefono" f="phone" />
                            <c.field l="Telefono Attività" f="phone_active" />
                            <c.field l="Cellulare" f="mobile_phone" />
                            <c.field l="Email" f="email" />
                            {customer.customer_id === '-1' &&
                                <c.field l={getTxt('Password')} f="password" type="password" />
                            }
                            {customer.customer_id === '-1' &&
                                <c.field l={getTxt('Confirm Password')} f="password2" type="password" />
                            }

                            {customer.customer_id !== '-1' &&
                                <c.field l={getTxt('New Password')} f="password_new" type="password" />
                            }

                            {customer.customer_id !== '-1' &&
                                <c.field l={getTxt('Confirm New Password')} f="password_new2" type="password" />
                            }


                            {this.props.isProfile &&
                                <c.field l={getTxt('New Password')} f="password_new" type="password" />
                            }

                            {this.props.isProfile &&
                                <c.field l={getTxt('Confirm New Password')} f="password_new2" type="password" />
                            }
                            <c.field l="PEC" f="email_pec" />






                            <c.group l="Informazioni Aggiuntive" d />
                            <c.field l="Codice Fiscale" f="fiscal_code" />
                            <c.field l="Partita iva" f="vat_number" />
                            <c.field l="IBAN" f="bank_iban" ui="iban" />
                            <c.field l="Attività Svolta" f="business_occupation" ui="select" >
                                {getDictionary(DictionaryTypes.BusinessOccupation)}
                            </c.field>

                            <c.field l="ritenuta" f="considered_percent" />
                            <c.field l="cod. ipa Fatt. Eletti." f="vat_descr" />
                            <c.field l="Numero iscrizione camera commercio" f="company_reg_number" />
                            <c.field l="Data iscrizione" f="company_registration_date" />

                            <c.field l="Numero di dipendenti dell'azienda" f="company_employee_number" />
                            <c.field l="Fatturato, EUR" f="company_revenue_eur" />
                            <c.field f="trusted_amount" />


                            <c.field l={getTxt('Communication Agree')} f="communication_agree" />
                            <c.field l="Non inviare comunicazioni automatiche" f="communication_unsubscribed" />

                            <c.field f="internal_notes" sm={12} multiline />



                            <c.formButtons sm={12} onCancel={() => { this.props.history.goBack(); }} />
                        </MaterialForm>

                    </div>
                }


                <Dialog
                    className={classes.dialog}
                    open={this.state.okDlg}
                    onClose={() => {
                        this.setState({ okDlg: undefined });
                    }}
                >
                    <DialogTitle> <span >{getTxt('Client Profile')}</span></DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <span >{getTxt('The client profile saved successfully.')}</span>
                        </DialogContentText>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { this.setState({ okDlg: false }); }} color="primary" >
                            {getTxt('Ok')}
                        </Button>

                    </DialogActions>
                </Dialog>

            </div>

        );
    }
}


const updateCustomerMutation = gql`

mutation UpdateCustomerMutation ($customer: customer_input!) {
    updateCustomerMutation(customer: $customer){
        id
        validation {
            field
            message
        }
    } 
}

`;


function getId(props) {
    if (!props.isProfile) {
        return props.match.params.id ? props.match.params.id : -1;
    }
    return -1;
}

export default compose(
    graphql(queryGetCustomerById, {
        options: props => ({ variables: { id: getId(props) } }),
    }),
    graphql(updateCustomerMutation, {
        name: 'updateCustomer',
        options: {
            errorPolicy: 'all',
        },
    }),
)(withStyles(styles)(Client_Company_Details_Edit));
