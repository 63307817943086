import TabContainer from 'components/TabContainer';
import $ from 'jquery';
import * as Icon from '@material-ui/icons';
import { AppBar } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Paper } from '@material-ui/core';
import { Tabs, Tab } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Contract_General_Data from 'modules/contract/Contract_General_Data';
import Contract_Item_Search from 'modules/contract/Contract_Item_Search';
import File_Search from 'modules/file/File_Search';
import NavPills from 'components/NavPills/NavPills';
import ItemGrid from 'components/Grid/ItemGrid';
import React, { Component } from 'react';
import { getTxt, toItalianDateString } from 'global/appGlobal';
import { compose, graphql } from 'react-apollo';
import Contract_Search from 'modules/contract/Contract_Search';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import ReactSVG from 'react-svg';
import HeaderViewItem from 'components/HeaderViewItem';
import LinearProgressEx from '../../components/LinearProgressEx';
import { DictionaryTypes, getInternalInsTypeCd, getLabel } from '../../global/dictionaryCache';
import * as queries from '../../modules/contract/contractQueries';
import RequestGuarantiesList from '../../modules/request/RequestGuarantiesList';
import Request_Details_View from '../../modules/request/Request_Details_View';
import RegularCard from '../../components/dashboard/RegularCard';
import Claim_Search from '../../modules/contract/claim/Claim_Search';
import ComponentEx from '../../components/ComponentEx';
import Contract_Edit_Details from './Contract_Edit_Details';
import { authInfo } from 'global/auth';

let history;

const styles = theme => ({
    root: {
        flexGrow: 1,
        marginTop: theme.spacing.unit * 3,
        backgroundColor: theme.palette.grey,
        margin: theme.spacing.unit * 3,
    },
    button: {
        margin: theme.spacing.unit,
    },

    badge: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        marginTop: 0,
    },

    gridRoot: {
        height: '100%',
    },


    formCaption: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit * 2,
        width: '100%',
        position: 'relative',
        height: 40,
    },
    formCaptionLabel: {
        position: 'absolute',
        //color: theme.palette.primary1,
        backgroundColor: 'transparent',
        left: 25,
        paddingLeft: 10,
        paddingRight: 10,
    },

    rootPaper: theme.mixins.gutters({

        paddingTop: 0,
        paddingBottom: 0,
        marginTop: 0,// theme.spacing.unit * 3,

    }),

    formHeaderRoot: {
        padding: theme.spacing.unit * 2,
        position: 'relative',
    },

    formHeaderFieldLabel: {
        color: theme.palette.text.disabled,
        fontWeight: 'bold',

    },

    tableHeader: {
        backgroundColor: theme.palette.background.paper,
    },

    headerIcon: {
        width: theme.spacing.unit * 8,
        height: theme.spacing.unit * 9,
        marginRight: theme.spacing.unit * 2,

    },

    cmdBar: {
        position: 'absolute',
        right: theme.spacing.unit * 3,
    },

    dialogText: {
        color: theme.palette.error.main,
    },
    formGroupLabel: {
        ...theme.typography.button,

        backgroundColor: theme.palette.background.paper,
        top: -13,
        // left: 25,
        paddingLeft: 0,
        paddingRight: 10,
        color: theme.palette.text.secondary,
        fontWeight: '900',
    },
});


const tabsStateGlobalId = 'Contract_Viecoli_View';
class Contract_View extends ComponentEx {

    state = {
        value: window[tabsStateGlobalId] ? window[tabsStateGlobalId] : 0,
        linkDlgOpen: false,
    };

    componentWillUnmount() {
        window[tabsStateGlobalId] = this.state.value;
    }

    componentDidMount() {
        $("table[class*='Table-headTable']").addClass(this.props.classes.tableHeader);
    }

    handleChange = (event, value) => {
        this.setState({ value });
    };

    render() {

        const { classes } = this.props;
        const { value } = this.state;

        const insType = this.props.insType ? this.props.insType : this.props.match.params.insType;
        //const internalInsType = getInternalInsTypeCd(insType);

        const loading = this.props.data.loading;
        const contract = this.props.data.contract;
        history = this.props.history;
        let caption = '';

        let request;
        if (contract && contract.request) {
            Object.keys(contract.request).forEach((key) => {
                const obj = contract.request[key];
                if (obj && obj.insurance_type_cd) {
                    request = obj;
                }
            });
            const insType_ = getLabel(DictionaryTypes.InsuranceType, request.insurance_type_cd);
            caption = insType_ + ' - ' + contract.customer.display_name + ", #" + contract.contract_number;
            window.pdfDetailscaption = insType_;
        }



        return (

            <div >
                <LinearProgressEx loading={loading} />

                {
                    this.state.editId && contract &&
                    <Contract_Edit_Details
                        id={this.state.editId}
                        AfterSubmit={(() => {
                            this.setState({ editId: undefined });
                        })}
                        requestId={contract.insurance_request_id}
                        insType={this.props.insType ? this.props.insType : this.props.match.params.insType}
                    />
                }


                <RegularCard

                    headerColor="red"
                    cardTitle={caption}
                    cardSubtitle={getTxt('Insurance Contract')}
                    content={
                        !authInfo.isCustomer() && <Grid container>
                            <ItemGrid xs={12} sm={12} md={3}>
                                <HeaderViewItem l={getTxt('Numero Polizza')}>{contract && contract.contract_number}</HeaderViewItem>
                            </ItemGrid>

                            {contract && contract.request.ins_request_veicoli &&
                                <ItemGrid xs={12} sm={12} md={3}>
                                    <HeaderViewItem l={getTxt('Targa')}>{contract.request.ins_request_veicoli.vehicle_targa_matricola}</HeaderViewItem>
                                </ItemGrid>}

                            <ItemGrid xs={12} sm={12} md={3}>
                                <HeaderViewItem l={getTxt('Tacito Rinnovo')}>{contract && contract.tacito_rinnovo === 'true' ? 'si' : 'no'}</HeaderViewItem>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={12} md={3}>
                                <HeaderViewItem l={getTxt('Indicizzazione')}>{contract && contract.indicizzazione === 'true' ? 'si' : 'no'}</HeaderViewItem>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={12} md={3}>
                                <HeaderViewItem l={getTxt('Contratto soggetto a regolazione')}>{contract && contract.contratto_soggetto_regolazione === 'true' ? 'si' : 'no'}</HeaderViewItem>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={12} md={3}>
                                <HeaderViewItem l={getTxt('Cliente')}>
                                    {contract && <Link to={`/customers/${contract.customer.customer_type_cd.toLowerCase()}/${contract.customer_id}`} >{contract.customer.display_name}</Link>}
                                </HeaderViewItem>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={12} md={3}>
                                <HeaderViewItem l={getTxt('Compagnia')}>
                                    {contract && (!authInfo.isCustomer() && !authInfo.isBroker()) && <Link to={`/company/view/${contract.company.insurance_company_id}`}>{contract.company.company_name}</Link>}
                                    {contract && (authInfo.isCustomer() || authInfo.isBroker()) && contract.company.company_name}
                                </HeaderViewItem>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={12} md={3}>
                                {
                                    contract && contract.status === 'Polizza' &&
                                    <HeaderViewItem l={getTxt('Stato')}>{getTxt('Polizza')}</HeaderViewItem>}
                                {
                                    contract && contract.status !== 'Polizza' &&
                                    <HeaderViewItem l={getTxt('Stato')}>{contract && getLabel(DictionaryTypes.InsuranceItemType, contract.status)}</HeaderViewItem>
                                }
                            </ItemGrid>


                            <ItemGrid xs={12} sm={12} md={3}>
                                <HeaderViewItem l={getTxt('Entrata in Effeto')}>
                                    {contract && (contract.contract_start_date ? toItalianDateString(contract.contract_start_date) : contract.contract_start_date)}
                                </HeaderViewItem>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={12} md={3}>
                                <HeaderViewItem l={getTxt('Scadenza')}>
                                    {contract && (contract.contract_end_date ? toItalianDateString(contract.contract_end_date) : contract.contract_end_date)}
                                </HeaderViewItem>
                            </ItemGrid>
                            {/* <ItemGrid xs={12} sm={12} md={3}>
                                <HeaderViewItem l={getTxt('Data Perfezionamento')} id="data_perf_in_c_view">
                                    {contract && (contract.data_perfezionamento ? toItalianDateString(contract.data_perfezionamento) : '')}
                                </HeaderViewItem>
                            </ItemGrid> */}
                            <ItemGrid xs={12} sm={12} md={3}>
                                <HeaderViewItem l={getTxt('Collaboratore')}>
                                    {contract && <Link to={`/brokers/${contract.broker.broker_id}`}>{contract.broker.display_name}</Link>}
                                </HeaderViewItem>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={12} md={3}>
                                {contract && <HeaderViewItem l={getTxt('Frazionamento')}>{getLabel(DictionaryTypes.InsPeriod, contract.ins_period)}</HeaderViewItem>}
                            </ItemGrid>
                            {contract && contract.contract_notes &&
                                <ItemGrid xs={12} sm={12} md={3}>
                                    <HeaderViewItem l={getTxt('Polizze note')}>{contract.contract_notes}</HeaderViewItem>
                                </ItemGrid>
                            }



                            <ItemGrid xs={12} sm={12} md={12}>
                                {contract && <Button
                                    style={{ float: 'right', marginRight: 40 }}
                                    color="primary"
                                    variant="contained"
                                    className={classes.button}
                                    onClick={() => {
                                        this.setState({ editId: contract.insurance_contract_id });
                                    }}
                                >
                                    {getTxt('Edit Contract')}
                                </Button>}
                            </ItemGrid>
                        </Grid>
                    }
                />
                <NavPills
                    color="warning"
                    onChange={this.handleChange}
                    alignCenter
                    active={value}
                    tabs={!authInfo.isCustomer() ? [
                        {
                            tabButton: getTxt('Movimenti'),
                            tabIcon: Icon.List,
                            tabContent: (
                                <TabContainer>
                                    {contract &&
                                        <div style={{ display: value === 0 ? 'block' : 'none' }}>
                                            <Contract_Item_Search

                                                contractId={contract.insurance_contract_id}
                                                contract={contract}
                                                history={this.props.history}
                                                base_ins_type={contract.base_ins_type}
                                            />
                                        </div>
                                    }
                                </TabContainer>
                            ),
                        },
                        // {
                        //     tabButton: getTxt('Guaranties'),
                        //     tabIcon: Icon.Drafts,
                        //     tabContent: (
                        //         <TabContainer>
                        //             {contract && <RequestGuarantiesList

                        //                 requestId={contract.insurance_request_id}
                        //                 insType={insType}
                        //                 history={this.props.history}
                        //                 noAddBtn
                        //                 contractId={contract.insurance_contract_id}
                        //                 onlyLatest
                        //                 noActions
                        //                 noCalculator
                        //             />}
                        //         </TabContainer>
                        //     ),
                        // },
                        {
                            tabButton: (contract && getLabel(DictionaryTypes.InsuranceType, request.insurance_type_cd)),
                            tabIcon: Icon.Details,
                            tabContent: (
                                <TabContainer>
                                    {contract && <Request_Details_View
                                        id={contract.insurance_request_id}
                                        customerId={contract.customer_id}
                                        history={this.props.history}
                                        insType={insType}
                                    />}
                                    <br />
                                    <br />
                                    <Typography
                                        className={classes.formGroupLabel}
                                        variant="headline"

                                    >
                                        {getTxt('Guaranties')}
                                    </Typography>

                                    {contract && /*value === 1 &&*/ <RequestGuarantiesList

                                        requestId={contract.insurance_request_id}
                                        insType={insType}
                                        history={this.props.history}
                                        noAddBtn
                                        contractId={contract.insurance_contract_id}
                                        onlyLatest
                                        noActions
                                        noCalculator
                                    />}
                                </TabContainer>
                            ),
                        },
                        {
                            tabButton: getTxt('Claims'),
                            tabIcon: Icon.DiscFull,
                            tabContent: (
                                <TabContainer>
                                    {contract &&

                                        <Claim_Search
                                            contractId={contract.insurance_contract_id}
                                            noCaption
                                            showAddBtn
                                        />

                                    }
                                </TabContainer>
                            ),
                        },
                        {
                            tabButton: getTxt('Dati Contr.'),
                            tabIcon: Icon.Domain,
                            tabContent: (
                                <TabContainer>
                                    {contract &&
                                        <Contract_General_Data
                                            id={contract.insurance_contract_id}
                                            history={this.props.history}
                                            insType={this.props.insType ? this.props.insType : this.props.match.params.insType}
                                        />
                                    }
                                </TabContainer>
                            ),
                        },
                        {
                            tabButton: getTxt('Linked Contracts'),
                            tabIcon: Icon.Link,
                            tabContent: (
                                <TabContainer>
                                    {contract &&
                                        <div>
                                            <Button
                                                color="primary"
                                                className={classes.button}
                                                onClick={() => { this.setState({ linkDlgOpen: true }); }}
                                            >
                                                {getTxt('Add / Remove Linked Contracts')}
                                            </Button>
                                            <Contract_Search noCaption noDeleteBtn linkedTo={contract.insurance_contract_id} />
                                        </div>
                                    }
                                </TabContainer>
                            ),
                        },
                        {
                            tabButton: getTxt('Documents'),
                            tabIcon: Icon.AttachFile,
                            tabContent: (
                                <TabContainer>
                                    {contract &&
                                        <File_Search
                                            entityId={contract.insurance_contract_id}
                                            noDelete={(!authInfo.isAgencyAdministrator() && !authInfo.isMasterBroker())}
                                        />}
                                </TabContainer>
                            ),
                        },
                    ] : [
                        {
                            tabButton: getTxt('Documents'),
                            tabIcon: Icon.AttachFile,
                            tabContent: (
                                <TabContainer>
                                    {contract &&
                                        <File_Search
                                            entityId={contract.insurance_contract_id}
                                            noAddBtn
                                            noDelete
                                        />}
                                </TabContainer>
                            ),
                        },
                        {
                            tabButton: (contract && getLabel(DictionaryTypes.InsuranceType, request.insurance_type_cd)),
                            tabIcon: Icon.Details,
                            tabContent: (
                                <TabContainer>
                                    {contract && <Request_Details_View
                                        id={contract.insurance_request_id}
                                        customerId={contract.customer_id}
                                        history={this.props.history}
                                        insType={insType}
                                    />}
                                    <br />
                                    <br />
                                    <Typography
                                        className={classes.formGroupLabel}
                                        variant="headline"

                                    >
                                        {getTxt('Guaranties')}
                                    </Typography>

                                    {contract && /*value === 1 &&*/ <RequestGuarantiesList

                                        requestId={contract.insurance_request_id}
                                        insType={insType}
                                        history={this.props.history}
                                        noAddBtn
                                        contractId={contract.insurance_contract_id}
                                        onlyLatest
                                        noActions
                                        noCalculator
                                    />}
                                </TabContainer>
                            ),
                        },
                    ]
                    }
                />

                {contract && <Dialog
                    className={classes.dialog}
                    open={this.state.linkDlgOpen}
                    onClose={() => {
                        this.setState({ linkDlgOpen: false });
                    }}
                >
                    <DialogTitle>{getTxt(`Contratti collegati a #${contract.contract_number}`)}</DialogTitle>
                    <DialogContent>
                        <Contract_Search
                            noCaption
                            noDeleteBtn
                            markIfLinketTo={contract.insurance_contract_id}
                            afterSubmitLinked={() => { this.setState({ linkDlgOpen: false }); }}
                        />
                    </DialogContent>
                </Dialog>}

            </div>
        );
    }
}

export default compose(

    graphql(queries.contractByIdQuery, {
        options: props => (
            {
                variables: {
                    id: (!props.id ? (props.match.params.id ? props.match.params.id : 'new') : props.id),
                    requestId: props.requestId ? props.requestId : props.match.params.requestId,
                    insType: getInternalInsTypeCd(props.insType ? props.insType : props.match.params.insType),
                },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }
        ),
    }),

)(withStyles(styles)(Contract_View));