import gql from 'graphql-tag';

export const getReportDataQuery = gql`

 query getReportDataQuery ($reportType: String, $params: String)  {
    reportData(reportType: $reportType, params: $params )
 }
 `;


export const reportHeaderQuery = gql`

 query reportHeaderQuery   {
    reportHeader{
        report_header_id
        caption
        data_line_1
        data_line_2
        data_line_3
        data_line_4
        data_line_5
        data_line_6
        data_line_7
        numero_iscrizione_rui
        invoice_header_word
        invoice_number_start_from
        invoice_number_template
    }
 }
 `;

export const updateReportHeaderMutation = gql`

mutation updateReportHeaderMutation ($header: report_header_input! ) {
    updateReportHeaderMutation(header: $header){
        id
        validation {
            field
            message
        }
    } 
}

`;


export const reportLogoFilesQuery = gql`

query reportLogoFilesQuery {
    reportLogoFiles {
        
        
        file_type_cd
        file_name
        file_extension
        file_comment
    }
  }

`;

export const updateReportLogoFileMutation = gql`

mutation updateReportLogoFileMutation ($file: attached_file_input!) {
    updateReportLogoFileMutation(file: $file){
        id
        tempId
        nextChunkLen
        nextPosition
        validation {
            field
            message
        }
    } 
}

`;

export const uploadNextReportLogoChunkMutation = gql`

mutation uploadNextReportLogoChunkMutation ($chunk: UploadFileChunk_input!) {
    uploadNextReportLogoChunkMutation(chunk: $chunk){
        id
        tempId
        nextChunkLen
        nextPosition
        validation {
            field
            message
        }
    } 
}

`;


export const deleteReportLogoFileMutation = gql`

mutation deleteReportLogoFileMutation  {
    deleteReportLogoFileMutation{
        id
        validation {
            field
            message
        }
    } 
}

`;

export const polizzeAnnullateReportQuery = gql`

query polizzeAnnullateReportQuery ($expFrom: String, $expTo: String, $itemType: String)  {
    polizzeAnnullateReport (expFrom: $expFrom, expTo: $expTo, itemType: $itemType ) {
        insurance_contract_id
        insurance_company_id
        customer_id
        owner_broker_id
        state_cd
        contract_number
        contract_start_date
        contract_end_date
        insurance_request_id
        insurance_quote_id
        insurance_type_cd
        ins_period
        contract_delivery_state
        contract_delivery_date
        contract_delivery_note
        contract_notes
        contract_amount_netto
        contract_total_client_amount
        amount_netto
        customer_solicited_email
        customer_solicited_phone
        tacito_rinnovo
        contratto_soggetto_regolazione
        competenze
        customer {
            customer_id
            display_name
            customer_type_cd
            email,
            mobile_phone,
            phone,
            phone_active
        }
        broker {
            broker_id
            display_name
        }
        company {
            insurance_company_id
            company_name
        }
        contract_item{
            item_payment_date
        }
        contract_link{
            insurance_contract_id
        }
        ins_request_veicoli{
            vehicle_targa_matricola
        }
        
    }
}

`;
