import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Menu } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';
import { Paper } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { AppBar } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { TextField } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import { Tabs, Tab } from '@material-ui/core';
import * as Icon from '@material-ui/icons';
import { Checkbox } from '@material-ui/core';
import { Button } from '@material-ui/core';
import moment from 'moment';
import { Badge } from '@material-ui/core';
import ComponentEx from 'components/ComponentEx';
import ReactSVG from 'react-svg';
import NavPills from 'components/NavPills/NavPills';
import ItemGrid from 'components/Grid/ItemGrid';
import HeaderViewItem from 'components/HeaderViewItem';
import { HashRouter, BrowserRouter, Route, Link, withRouter } from 'react-router-dom';
import TabContainer from 'components/TabContainer';

import $ from 'jquery';
import CmdBar from '../../components/form/CmdBar';
import { getTxt } from '../../global/appGlobal';
import gql from 'graphql-tag';
import { graphql, compose } from 'react-apollo';
import LinearProgressEx from '../../components/LinearProgressEx';
import { getLabel, DictionaryTypes } from '../../global/dictionaryCache';
import Client_Propspect_Privat_Details_View from '../../modules/client/Client_Propspect_Privat_Details_View';
import { queryGetCustomerById, customerBriefQuery } from '../../modules/client/clientQueries';
import NewRequestMenuItems from '../../modules/client/NewRequestMenuItems';
import RegularCard from '../../components/dashboard/RegularCard';
import CmdBarCustomBtns from '../../components/CmdBarCustomBtns';
import Request_Search from '../request/Request_Search';

const styles = theme => ({
   root: {
        backgroundColor: theme.palette.grey,
        margin: theme.spacing.unit * 3,
        marginTop: 0,
    },

    button: {
        margin: theme.spacing.unit,
    },

    badge: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        marginTop: 0,
    },

    gridRoot: {
        height: '100%',
    },


    formCaption: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit * 2,
        width: '100%',
        position: 'relative',
        height: 40,
    },
    formCaptionLabel: {
        position: 'absolute',
        backgroundColor: 'transparent',
        left: 25,
        paddingLeft: 10,
        paddingRight: 10,
    },

    rootPaper: theme.mixins.gutters({
        paddingTop: 0,
        paddingBottom: 0,
        marginTop: 0,
    }),

    formHeaderRoot: {
        padding: theme.spacing.unit * 2,
        position: 'relative',
    },

    formHeaderFieldLabel: {
        color: theme.palette.text.disabled,
        fontWeight: 'bold',
    },

    tableHeader: {
        backgroundColor: theme.palette.background.paper,
    },
    headerIcon: {
        width: theme.spacing.unit * 8,
        height: theme.spacing.unit * 9,
        marginRight: theme.spacing.unit * 2,
    },
    cmdBar: {
        position: 'absolute',
        right: theme.spacing.unit * 3,
    },
});

class Client_Prospect_Privat_View extends ComponentEx {
    state = {
        value: 0,
    };

    componentDidMount() {
        $("table[class*='Table-headTable']").addClass(this.props.classes.tableHeader);
        getTxt('Transform to real');
        getTxt('Edit');
    }

    handleChange = (event, value) => {
        this.setState({ value });
    };

    render() {

        const { classes } = this.props;
        const { value } = this.state;
        const loading = this.props.data.loading;
        const customer = this.props.data.customer;

        return (
            <div className={classes.root}>
                <LinearProgressEx loading={loading} />
                <RegularCard

                    headerColor="red"
                    cardTitle={customer && `${getLabel(DictionaryTypes.Title, customer.title_cd)} ${customer.display_name}`}
                    cardSubtitle={getTxt('Privat Prospect customer')}
                    content={

                        <Grid container>
                            <ItemGrid xs={12} sm={12} md={2}>
                                <HeaderViewItem l={getTxt('Contacts')}>
                                    <Typography variant="body1" >{customer && customer.email}</Typography>
                                    <Typography variant="body1" > {customer && customer.mobile_phone} </Typography>
                                </HeaderViewItem>
                            </ItemGrid>

                            <ItemGrid xs={12} sm={12} md={9}>
                                <CmdBarCustomBtns
                                    color="red"
                                    onClick={((btn) => {
                                        if (btn === 'Edit') {
                                            this.props.history.push(`/customers/prospect/private/edit/${this.props.match.params.id}`);
                                        }
                                        if (btn === 'Transform to real') {
                                            this.props.history.push(`/customers/private/edit/${this.props.match.params.id}/toreal/1`);
                                        }
                                    })}
                                >{['Edit', 'Transform to real']}</CmdBarCustomBtns>

                            </ItemGrid>
                        </Grid>
                    }

                />

                <NavPills
                    color="warning"
                    onChange={this.handleChange}
                    alignCenter
                    active={value}
                    tabs={[
                        {
                            tabButton: getTxt('Details'),
                            tabIcon: Icon.Dashboard,
                            tabContent: (
                                <TabContainer>
                                    <Button color="primary" className={classes.button} onClick={() => { this.props.history.push(`/customers/prospect/private/edit/${this.props.match.params.id}`) }} >{getTxt('Edit Details')}</Button>
                                    <Client_Propspect_Privat_Details_View customerId={this.props.match.params.id} />
                                </TabContainer>
                            ),
                        },
                        {
                            tabButton: getTxt('Richiesti'),
                            tabIcon: Icon.Schedule,
                            tabContent: (
                                <TabContainer>

                                    <Menu
                                        id="simple-menu"
                                        anchorEl={this.state.addNewMenuAnchor}
                                        open={this.state.addNewMenuOpen}
                                        onClose={() => { this.setState({ addNewMenuOpen: false }); }}
                                    >
                                        {customer && <NewRequestMenuItems history={this.props.history} customerId={customer.customer_id} />}
                                    </Menu>

                                    <Button
                                        color="primary"
                                        className={classes.button}
                                        onClick={(e) => {
                                            this.setState({ addNewMenuOpen: true, addNewMenuAnchor: e.currentTarget });
                                        }}
                                    >
                                        {getTxt('Aggiungi nuova richiesta')}
                                    </Button>
                                    {customer && <Request_Search noCaption customerId={customer.customer_id} quiet={this.state.value !== 1} />}
                                </TabContainer>
                            ),
                        },

                    ]}
                />

            </div>
        );
    }
}

export default compose(
    graphql(customerBriefQuery, {
        options: props => ({
            variables: { id: props.match.params.id },
            fetchPolicy: 'network-only',
            options: {
                errorPolicy: 'all',
            },
        }),
    }),
)(withStyles(styles)(Client_Prospect_Privat_View));
