
import React, { Component } from 'react';
import { getTxt, randomString } from 'global/appGlobal';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
//import FormField from 'components/form/FormField';
import GridEx from 'components/form/GridEx';

import { Grid } from '@material-ui/core';

import { EntityTypes, getEntityDescriptor } from 'global/entityDescriptors';

import MFormControl from '../../components/form/MFormControl';

const styles = theme => ({
    label: {
        ...theme.typography.body1,
        marginRight: theme.spacing.unit,
        color: theme.palette.text.secondary,
        fontWeight: 'bold',
    },
    value: {
        ...theme.typography.body1,
        color: theme.palette.text.secondary,
    },
    formControl: {
        margin: theme.spacing.unit / 4,
        flexDirection: 'row',
        whiteSpace: 'normal',
    },
});

class ReadOnlyAdjustable extends MFormControl {

    render() {
        const { classes } = this.props;
        let value = '';
        if (this.props.f && this.context.dataSource) {
            value = this.context.dataSource[this.props.f];
        }
        if (this.props.v) {
            value = this.props.v;
        }
        const objDescriptor = getEntityDescriptor(this.context.objType);
        const propDescriptor = objDescriptor[this.props.f];
        const propType = propDescriptor ? propDescriptor.type : this.props.type;

        if (this.context.fields && this.context.fields.all.length > 0) {
            if (!this.context.fields.byId[this.props.f]) {
                return '';
            }
        }

        return (

            <GridEx item sm={this.props.sm || 12} xs={this.props.xs || 12}>
                <Grid container spacing={0}>
                    <GridEx item xs={this.props.lxs || 3}>
                        <span className={classes.label} >
                            {this.getLabel()}
                        </span>
                    </GridEx>
                    <GridEx item xs={this.props.vxs || 8}>
                        <span className={classes.value}>
                            {this.convertToControl(value, propType, true)}
                        </span>
                    </GridEx>
                </Grid>
            </GridEx>

        );
    }
}

ReadOnlyAdjustable.contextTypes = {
    onChange: PropTypes.func,
    registerControl: PropTypes.func,
    objType: PropTypes.string,
    dataSource: PropTypes.object,
    unRegisterControl: PropTypes.func,
    fields: PropTypes.Object,
};

export default withStyles(styles, { withTheme: true })(ReadOnlyAdjustable);