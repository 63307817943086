import React from "react";
import {
    withStyles,
    Card,
    CardContent,
    CardHeader,
    CardActions
} from "@material-ui/core";
import PropTypes from "prop-types";
import cx from "classnames";

import regularCardStyle from "assets/jss/material-dashboard-react/regularCardStyle";

function RegularCard({ ...props }) {
    const {
        classes,
        headerColor,
        plainCard,
        cardTitle,
        cardSubtitle,
        content,
        footer,
        fitHeader,
    } = props;
    const plainCardClasses = cx({
        [" " + classes.cardPlain]: plainCard
    });
    const cardPlainHeaderClasses = cx({
        [" " + classes.cardPlainHeader]: plainCard
    });

    const headerClass = {
        root:
            classes.cardHeader +
            " " +
            //classes[headerColor + "CardHeader"] +
            classes['purple' + "CardHeader"] + //hardcoded color
            cardPlainHeaderClasses,
        title: classes.cardTitle,
        subheader: classes.cardSubtitle
    }

    return (
        <Card className={classes.card + plainCardClasses}>
            <CardHeader
                style={{ width: fitHeader ? 'fit-content' : 'auto' }}
                classes={headerClass}
                title={cardTitle}
                subheader={cardSubtitle}
            />
            <CardContent>{content}</CardContent>
            {footer !== undefined ? (
                <CardActions className={classes.cardActions}>{footer}</CardActions>
            ) : null}
        </Card>
    );
}

RegularCard.defaultProps = {
    headerColor: "purple"
};

RegularCard.propTypes = {
    plainCard: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    headerColor: PropTypes.oneOf(["orange", "green", "red", "blue", "purple"]),
    cardTitle: PropTypes.node,
    cardSubtitle: PropTypes.node,
    content: PropTypes.node,
    footer: PropTypes.node
};

export default withStyles(regularCardStyle)(RegularCard);
