import React from 'react';
import PropTypes from 'prop-types';
// react plugin for creating charts
import ChartistGraph from 'react-chartist';
import * as Icon from '@material-ui/icons';
import {
    ContentCopy,
    Store,
    InfoOutline,
    Warning,
    DateRange,
    LocalOffer,
    Update,
    ArrowUpward,
    AccessTime,
    Accessibility,
    NoteAdd,
} from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { getTxt } from 'global/appGlobal';
import { graphql, compose } from 'react-apollo';

import StatsCard from 'components/dashboard/StatsCard';
import ChartCard from 'components/dashboard/ChartCard';
import TasksCard from 'components/dashboard/TasksCard';
import RegularCard from 'components/dashboard/RegularCard';
import Table from 'components/dashboard/Table';
import ItemGrid from 'components/dashboard/ItemGrid';
import { Menu, MenuItem } from '@material-ui/core';
import { Divider } from '@material-ui/core';
import { Link } from 'react-router-dom';
import * as Icons from '@material-ui/icons';

import {
    dailySalesChart,
    emailsSubscriptionChart,
    completedTasksChart
} from 'components/dashboard/variables/charts';

import dashboardStyle from 'assets/jss/material-dashboard-react/dashboardStyle';
import { operationalDashboardData } from '../dashboard/dashboardQueries';
import LinearProgressEx from '../../components/LinearProgressEx';
import ContractItemsReport from '../../modules/reports/ContractItemsReport';
import ComponentEx from '../../components/ComponentEx';

const styles = theme => ({
    ...dashboardStyle,
    root: {
        backgroundColor: theme.palette.background.paper,
        paddingTop: theme.spacing.unit * 2,
        margin: theme.spacing.unit * 3,
        paddingBottom: theme.spacing.unit * 2,
    },
});

class ReportsRoot extends ComponentEx {
    state = {
        value: 0,
        menuOpen: false,
        anchorEl: undefined,
    };
    handleChange = (event, value) => {
        this.setState({ value });
    };

    handleChangeIndex = index => {
        this.setState({ value: index });
    };

    newClick(event) {
        this.setState({ menuOpen: true, anchorEl: event.currentTarget });
    }

    render() {
        const { classes } = this.props;
        const loading = this.props.data.loading;
        const data = this.props.data.operationalDashboardData;

        return (
            <div className={classes.root}>

           
                <LinearProgressEx loading={loading} />

                <Grid container>
                    <ItemGrid xs={12} sm={12} md={4}>
                        {/* <ChartCard
                            chart={
                                <ChartistGraph
                                    className="ct-chart"
                                    data={dailySalesChart.data}
                                    type="Line"
                                    options={dailySalesChart.options}
                                    listener={dailySalesChart.animation}
                                />
                            }
                            chartColor="green"
                            title="Agency Paiments (EUR)"
                            text={
                                <span>
                                    <span className={this.props.classes.successText}>
                                        <ArrowUpward
                                            className={this.props.classes.upArrowCardCategory}
                                        />{' '}
                                        55%
                  </span>{' '}
                                    increase in today payments.
                </span>
                            }
                            statIcon={AccessTime}
                            statText="Last week"
                        /> */}
                    </ItemGrid>
                    <ItemGrid xs={12} sm={12} md={4}>
                        {/* <ChartCard
                            chart={
                                <ChartistGraph
                                    className="ct-chart"
                                    data={emailsSubscriptionChart.data}
                                    type="Bar"
                                    options={emailsSubscriptionChart.options}
                                    responsiveOptions={emailsSubscriptionChart.responsiveOptions}
                                    listener={emailsSubscriptionChart.animation}
                                />
                            }
                            chartColor="orange"
                            title="New Customers"
                            text="All new customer during this year"
                            statIcon={AccessTime}
                            statText="totals include all types of customers"
                        /> */}
                    </ItemGrid>
                    <ItemGrid xs={12} sm={12} md={4}>
                        {/* <ChartCard
                            chart={
                                <ChartistGraph
                                    className="ct-chart"
                                    data={completedTasksChart.data}
                                    type="Line"
                                    options={completedTasksChart.options}
                                    listener={completedTasksChart.animation}
                                />
                            }
                            chartColor="red"
                            title={getTxt('Agency Profit (EUR)')}
                            text={getTxt('Profit calculated based on agency percent')}
                            statIcon={AccessTime}
                            statText={getTxt('information for the last week')}
                        /> */}
                    </ItemGrid>
                </Grid>
                {/* <ContractItemsReport /> */}
                {/* <Grid container>
                    <ItemGrid xs={12} sm={12} md={6}>
                        <TasksCard />
                    </ItemGrid>
                    <ItemGrid xs={12} sm={12} md={6}>
                        <RegularCard
                            headerColor="orange"
                            cardTitle={getTxt('Insurance Requests')}
                            cardSubtitle={getTxt('Latest 5 insurance requests')}
                            content={
                                <Table
                                    tableHeaderColor="warning"
                                    tableHead={['ID', 'Ins. Type', 'Date', 'Client']}
                                    tableData={[
                                        ['135', 'RCA', '2018-05-31', 'Jacob Rossi'],
                                        ['134', 'RCA', '2018-05-31', 'Pablo Bianchi'],
                                        ['133', 'Cosa', '2018-05-31', 'Natali Moretti'],
                                        ['132', 'RCA', '2018-05-30', 'Maria Bruno']
                                    ]}
                                />
                            }
                        />
                    </ItemGrid>
                </Grid>
                <Link to={'/reports/contracts/'} style={{ marginLeft: 30 }} >
                    {getTxt('Contract Items Report')}
                </Link> */}

                <Link to={'/reports/contracts/'} style={{ marginLeft: 30 }} >
                    {getTxt('Contract Items')}
                </Link>
                <br />
                <br />
                <Link to={'/reports/claims/'} style={{ marginLeft: 30 }} >
                    {getTxt('Claims')}
                </Link>
                <br />
                <br />
                <Link to={'/reports/quietanzamento/'} style={{ marginLeft: 30 }} >
                    {getTxt('Quietanzamento')}
                </Link>
                <br />
                <br />
                <Link to={'/reports/contracts/non-perfezionati/'} style={{ marginLeft: 30 }} >
                    {getTxt('Titoli non perfezionati')}
                </Link>
                <br />
                <br />
                <Link to={'/reports/tracciamento-simpli'} style={{ marginLeft: 30 }} >
                    {getTxt('Tracciamento simpli')}
                </Link>
                <br />
                <br />
                <Link to={'/reports/polizze-annullate'} style={{ marginLeft: 30 }} >
                    {getTxt('Polizze Annullate')}
                </Link>
                <br />
                <br />

                


                {/* <Link to={'/reports/monthly-inbound/'} style={{ marginLeft: 30 }} >
                    {getTxt('Monthly inbound cash flow')}
                </Link>
                <br />
                <br />
                <Link to={'/reports/revenues-company/'} style={{ marginLeft: 30 }} >
                    {getTxt('Revenues by Insurance Company')}
                </Link>
                <br />
                <br />
                <Link to={'/reports/conversion-index'} style={{ marginLeft: 30 }} >
                    {getTxt('Conversion index')}
                </Link> */}
            </div>
        );
    }
}



export default compose(
    graphql(operationalDashboardData, {
        options: props => ({

            fetchPolicy: 'network-only',
            options: {
                errorPolicy: 'all',
            },
        }),
    }),

)(withStyles(styles)(ReportsRoot));


export function getYearandMonthLabel(year, monthLabel) {
    let month = getTxt(monthLabel);
    if (month.length > 3) {
        month = month.substr(0, 3);
    }
    return month + '\n' + year;
}

export function getYearandMonthLabelGrid(year, monthLabel) {
    let month = getTxt(monthLabel);
    if (month.length > 3) {
        month = month.substr(0, 3);
    }
    return year + ' ' + month;
}