/* eslint-disable react/no-multi-comp */
import React, { Component } from 'react';
import { getTxt } from 'global/appGlobal';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withTheme } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import FormField from 'components/form/FormField';
import GridEx from 'components/form/GridEx';
import { MenuItem } from '@material-ui/core';
import { Button } from '@material-ui/core';
import {
    FormLabel,
    FormControl,
    FormGroup,
    FormControlLabel,
    FormHelperText,
} from '@material-ui/core';
import { Checkbox } from '@material-ui/core';
import { Input, InputLabel } from '@material-ui/core';
import FormCaption from 'components/form/FormCaption';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import { Paper } from '@material-ui/core';
import { Select } from '@material-ui/core';
import  { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';



const styles = theme => ({
    formRoot: {
        flexGrow: 1,
        width: '100%',

    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: '100%',
    },
    menu: {
        width: 200,
    },
    checkBox: {
        marginLeft: theme.spacing.unit,
    },
    brief: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit,
    },
    briefLabel: {
        whiteSpace: 'nowrap !important',
        overflow: 'hidden !important',
        textOverflow: 'ellipsis !important',
        marginBottom: theme.spacing.unit / 2,
    },
    briefValue: {
        whiteSpace: 'nowrap !important',
        overflow: 'hidden !important',
        textOverflow: 'ellipsis !important',
        fontWeight: 'bold',
    },
    formGroup: {
        marginTop: theme.spacing.unit * 5,
        marginBottom: theme.spacing.unit,
        width: '100%',
        height: '2px',
        //backgroundColor: theme.palette.text.primary,
        position: 'relative',
    },
    formGroupLabel: {
        position: 'absolute',
        backgroundColor: theme.palette.background.paper,
        top: -16,
        // left: 25,
        paddingLeft: 5,
        paddingRight: 10,
    },

    cell: {
        verticalAlign: 'top',
        width: '100%',

        paddingTop: theme.spacing.unit + 4,
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit * 2,

    },

    filterInput: {
        width: '100%',

    },

});



class P_Input extends Component {

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    state = {
        value: undefined,
    };

    componentWillMount() {
        if (this.props.disabled && (!this.props.value || this.props.value === '')) {
            this.setState({ value: ' ' });
        } else {
            this.setState({ value: this.props.value });
        }
    }

    onChange(e) {
        this.setState({ value: e.target.value });

        if (this.props.onChange) {
            this.props.onChange(e.target.value);
        }
    }

    render() {
        const { classes } = this.props;

        return (

            <GridEx item sm={this.props.sm || 6} xs={this.props.xs || 12}>
                <TextField
                    className={classes.textField}
                    label={getTxt(this.props.en || this.props.label, this.props.it)}
                    margin="normal"
                    {...this.props}
                    value={this.state.value}
                    onChange={this.onChange}

                />
            </GridEx>


        );
    }
}


class P_Input_ extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    state = {
        value: undefined,
    };

    componentWillMount() {
        if (this.props.d && (!this.props.v || this.props.v === '')) {
            this.setState({ value: ' ' });
        } else {
            this.setState({ value: this.props.v });
        }

        if (this.props.type === 'date' && !this.props.v) {
            this.setState({ value: moment().set({ year: 2017 }).format('YYYY-MM-DD') });
        }
    }

    onChange(e) {
        this.setState({ value: e.target.value });

        if (this.props.onChange) {
            this.props.onChange(e.target.value);
        }
    }

    render() {
        const { classes } = this.props;



        return (

            <GridEx item sm={this.props.sm || 6} xs={this.props.xs || 12}>

                <TextField
                    className={classes.textField}
                    label={getTxt(this.props.en || this.props.l, this.props.it)}
                    margin="normal"
                    {...this.props}
                    value={this.state.value}
                    disabled={this.props.d}
                    required={this.props.r}
                    onChange={this.onChange}

                />

            </GridEx>


        );
    }
}

class P_Select extends Component {

    render() {
        const { classes } = this.props;

        return (

            <GridEx item sm={this.props.sm || 6} xs={this.props.xs || 12}>

                <TextField
                    select
                    label={getTxt(this.props.en || this.props.label, this.props.it)}
                    className={classes.textField}
                    SelectProps={{
                        MenuProps: {
                            className: classes.menu,
                        },
                    }}
                    margin="normal"
                    {...this.props}
                    value={this.props.value} >
                    {this.props.children && this.props.children.map(mi => (
                        <MenuItem key={mi} value={mi}>
                            {mi}
                        </MenuItem>
                    ))}
                </TextField>



            </GridEx>


        );
    }
}


class DictionaryGridFilter extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    state = {
        value: undefined,
    };

    componentWillMount() {

        let val = '';
        if (this.props.children && this.props.children.find) {
            val = this.props.children.find(i => i === this.props.v);
        }
        if (!val) val = ' ';
        this.setState({ value: val });
    }


    onChange(e) {

        this.setState({ value: e.target.value });
        this.props.setFilter((e.target.value && (e.target.value !== this.props.noFilterVal)) ? { value: e.target.value } : null);
        if (this.props.onChange) {
            this.props.onChange(e.target.value);
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <TableCell className={classes.cell}>
                <Select

                    select
                    className={classes.filterInput}
                    SelectProps={{
                        MenuProps: {
                            className: classes.menu,
                        },
                        placeholder: getTxt('Filter...'),

                    }}
                    margin="normal"
                    {...this.props}
                    value={this.state.value}
                    placeholder={getTxt('Filter...')}
                    onChange={this.onChange}
                >
                    {this.props.children && this.props.children.map(mi => (
                        <MenuItem key={mi} value={mi}>
                            {mi}
                        </MenuItem>
                    ))}
                </Select>



            </TableCell>
        );
    }
}


class P_Select_ extends Component {

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    state = {
        value: undefined,
    };

    componentWillMount() {

        let val = '';
        if (this.props.children && this.props.children.find) {
            val = this.props.children.find(i => i === this.props.v);
        }
        if (!val) val = ' ';
        this.setState({ value: val });
    }


    onChange(e) {

        this.setState({ value: e.target.value });
        if (this.props.onChange) {
            this.props.onChange(e.target.value);
        }
    }

    render() {
        const { classes } = this.props;



        return (

            <GridEx item sm={this.props.sm || 6} xs={this.props.xs || 12}>

                <TextField
                    select
                    label={getTxt(this.props.en || this.props.l, this.props.it)}
                    className={classes.textField}
                    SelectProps={{
                        MenuProps: {
                            className: classes.menu,
                        },
                    }}
                    margin="normal"
                    {...this.props}
                    value={this.state.value}
                    disabled={this.props.d}
                    required={this.props.r}
                    onChange={this.onChange}
                >
                    {this.props.children && this.props.children.map(mi => (
                        <MenuItem key={mi} value={mi}>
                            {mi}
                        </MenuItem>
                    ))}
                </TextField>



            </GridEx>


        );
    }
}

class P_CheckBox extends Component {




    render() {
        const { classes } = this.props;

        return (

            <GridEx item sm={this.props.sm || 6} xs={this.props.xs || 12}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                className={classes.checkBox}
                                onChange={this.onChange}
                                {...this.props}
                                checked={this.props.value}
                            />
                        }
                        label={getTxt(this.props.en || this.props.label, this.props.it)}
                    />
                </FormGroup>
            </GridEx>


        );
    }
}

class P_CheckBox_ extends Component {

    render() {
        const { classes } = this.props;

        return (

            <GridEx item sm={this.props.sm || 6} xs={this.props.xs || 12}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                className={classes.checkBox}
                                onChange={this.onChange}
                                {...this.props}
                                disabled={this.props.d}
                                required={this.props.r}
                                checked={this.props.v}
                            />
                        }
                        label={getTxt(this.props.en || this.props.l, this.props.it)}
                    />
                </FormGroup>
            </GridEx>


        );
    }
}


class P_Button extends Component {




    render() {
        const { classes } = this.props;

        return (

            <GridEx item sm={this.props.sm || 6} xs={this.props.xs || 12}>
                <Button {...this.props} >{getTxt(this.props.en || this.props.label, this.props.it)}</Button>

            </GridEx>


        );
    }
}

class P_Button_ extends Component {




    render() {
        const { classes } = this.props;

        return (

            <GridEx item sm={this.props.sm || 6} xs={this.props.xs || 12}>
                <Button {...this.props}
                    variant="contained"
                    disabled={this.props.d}
                >{getTxt(this.props.en || this.props.l, this.props.it)}</Button>

            </GridEx>


        );
    }
}

class P_Brief extends Component {
    render() {
        const { classes } = this.props;

        return (
            <GridEx item sm={this.props.sm || 6} xs={this.props.xs || 12}>
                <div className={classes.brief}>
                    <Typography
                        className={classes.briefLabel}
                        variant="button"
                    >
                        {this.props.label}
                    </Typography>
                    <div className={classes.briefValue} >{this.props.value}</div>
                </div>
            </GridEx>
        );
    }
}

class P_Brief_ extends Component {
    render() {
        const { classes } = this.props;

        return (
            <GridEx item sm={this.props.sm || 6} xs={this.props.xs || 12}>
                <div className={classes.brief}>
                    <Typography
                        className={classes.briefLabel}
                        variant="button"
                    >
                        {this.props.label}
                    </Typography>
                    <div className={classes.briefValue} >{getTxt(this.props.en || this.props.l, this.props.it)}</div>
                </div>
            </GridEx>
        );
    }
}

class Group extends FormField {

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange(value) {
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <GridEx item sm={this.props.sm || 12} xs={this.props.xs || 12}>
                <div className={classes.formGroup} >
                    <Typography
                        className={classes.formGroupLabel}
                        variant="headline"

                    >
                        {getTxt(this.props.en || this.props.l, this.props.it)}
                    </Typography>
                </div>
            </GridEx>
        );
    }
}

class Form extends Component {
    static defaultProps = {
        layout: (<span>{'empty form'}</span>),
        readOnly: false,
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.formRoot}>
                {this.props.l && (<FormCaption >  {getTxt(this.props.en || this.props.l, this.props.it)}  </FormCaption>)}
                <Grid container spacing={24}>
                    {this.props.children}
                </Grid>
            </div>
        );
    }
}

class DataGrid extends FormField {

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    render() {
        const { classes } = this.props;

        const data = [];

        if (this.props.children && this.props.children.length) {
            for (let i = 0; i < this.props.children.length; i += 1) {
                const row = this.props.children[i];
                const rowObj = {};

                if (this.props.cols) {
                    for (let k = 0; k < this.props.cols.length; k += 1) {
                        const col = this.props.cols[k];
                        rowObj[col] = row[k];
                    }
                }
                data.push(rowObj);
            }
        }

        let cols = [];
        if (this.props.cols && this.props.cols.length) {
            cols = this.props.cols;
        }

        if (this.props.data && this.props.data.length) {
            cols = this.props.data;
        }

        return (
            <GridEx item sm={this.props.sm || 12} xs={this.props.xs || 12}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            {cols.map(c => { return (<TableCell >{c}</TableCell>) })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map(i => {
                            return (
                                <TableCell >{c}</TableCell>)
                        })}
                    </TableBody>
                </Table>
            </GridEx>
        );
    }
}







const p_ = {};
p_.group = withStyles(styles, { withTheme: true })(Group);
p_.text = withStyles(styles, { withTheme: true })(P_Input_);
p_.select = withStyles(styles, { withTheme: true })(P_Select_);
p_.check = withStyles(styles, { withTheme: true })(P_CheckBox_);
p_.btn = withStyles(styles, { withTheme: true })(P_Button_);
p_.brief = withStyles(styles, { withTheme: true })(P_Brief_);
p_.form = withStyles(styles, { withTheme: true })(Form);
p_.grid = withStyles(styles, { withTheme: true })(DataGrid);
p_.gridColFilter = withStyles(styles, { withTheme: true })(DictionaryGridFilter);



export const p = p_;

