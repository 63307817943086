import React, { Component } from 'react';
import { getTxt } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';
import { withTheme } from '@material-ui/core';

import { Typography } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';



import { Paper } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { AppBar } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { TextField } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import gql from 'graphql-tag';
import { graphql, compose } from 'react-apollo';
import { Tabs, Tab } from '@material-ui/core';
import * as Icon from '@material-ui/icons';
import GridEx from 'components/form/GridEx';
import { Button } from '@material-ui/core';
import moment from 'moment';
import { Badge } from '@material-ui/core';
import ComponentEx from 'components/ComponentEx';
import { get_Broker_ById_Query } from '../../modules/broker/brokerQueries';
import c from '../../components/form/Components';
import LinearProgressEx from '../../components/LinearProgressEx';
import { getLabel, DictionaryTypes, getDictionary } from '../../global/dictionaryCache';
import MaterialForm from '../../components/form/MaterialForm';
import MFormText from '../../components/form/MFormText';
import * as queries from '../../modules/broker/brokerQueries';


const styles = theme => ({

    root: {
        backgroundColor: theme.palette.background.paper,
    },

    button: {
        margin: theme.spacing.unit,
    },

    badge: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        marginTop: 0,
    },

    gridRoot: {
        height: '100%',
    },


    formCaption: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit * 2,
        width: '100%',
        position: 'relative',
        height: 40,
    },
    formCaptionLabel: {
        position: 'absolute',
        //color: theme.palette.primary1,
        backgroundColor: 'transparent',
        left: 25,
        paddingLeft: 10,
        paddingRight: 10,
    },

    rootPaper: theme.mixins.gutters({

        paddingTop: 0,
        paddingBottom: 0,
        marginTop: 0,// theme.spacing.unit * 3,

    }),

});

class Broker_Details_View extends ComponentEx {
    state = {
        value: 0,
    };

    handleChange = (event, value) => {
        this.setState({ value });
    };

    render() {

        if (this.props.quiet) {
            return (<LinearProgressEx loading={this.props.data.loading} />);
        }


        const { classes } = this.props;
        const loading = this.props.data.loading;
        const broker = this.props.data.broker;

        let birth_country_cd;
        let birth_province;
        if (broker) {
            birth_country_cd = this.state.birth_country_cd ? this.state.birth_country_cd : broker.birth_country_cd;
            birth_province = this.state.birth_province ? this.state.birth_province : broker.birth_province;
        }

        let address_country_cd;
        let address_province;
        if (broker) {
            address_country_cd = this.state.address_country_cd ? this.state.address_country_cd : broker.address_country_cd;
            address_province = this.state.address_province ? this.state.address_province : broker.address_province;
        }

        window.refetchBrokerView = this.props.data.refetch;

        return (

            

            <div className={classes.root}>
             
                <LinearProgressEx loading={loading} />
                {broker &&
                    <MaterialForm dataSource={broker} objType="customer" onSubmit={this.onSubmit} onChange={this.fornChanged}>

                        <c.group l={getTxt('Dati personali')} />
                        <c.field l={getTxt('Nome / Ragione Sociale')} f="first_name" d />
                        <c.field l={getTxt('Cognome / Collaboratore')} f="last_name" d />
                        <c.field f="sex_cd" v={getLabel(DictionaryTypes.Sex, broker.sex_cd)} d />
                        <c.field l={getTxt('Tupo')} f="broker_type_cd" v={getLabel(DictionaryTypes.BrokerType, broker.broker_type_cd)} d />
                            
                        <c.field l={getTxt('Stato')} f="state_cd" v={getLabel(DictionaryTypes.BrokerState, broker.state_cd)} d />
                            
                        <c.field l={getTxt('Codice Collaboratore')} f="company_name" d />

                        <c.group l={getTxt('Address')} />
                        <c.field
                            l={getTxt('Luogo Nascita')}
                            f="birth_country_cd"
                            v={getLabel(DictionaryTypes.Country, broker.birth_country_cd)}
                            d
                        />
                         
                        {birth_country_cd === 'IT' &&
                            <c.field
                                l={getTxt('Province di Nascita')}
                                f="birth_province"
                                v={getLabel(DictionaryTypes.ItalianProvinces, broker.birth_province)}  
                                d
                            />}
                        {birth_country_cd === 'IT' &&
                            <c.field
                                provinceId={birth_province}
                                l={getTxt('Città di Nascita')}
                                f="birth_city"
                                v={getLabel(DictionaryTypes.ItalianCities, broker.birth_city)}  
                                d
                            />}
                        {birth_country_cd !== 'IT' &&
                            <c.field l={getTxt('Città di Nascita')} f="birth_city" d />
                        }



                        <c.field
                            l={getTxt('Nazione')}
                            f="address_country_cd"
                            v={getLabel(DictionaryTypes.Country, broker.address_country_cd)}  
                            d
                        />
                            
                        {address_country_cd === 'IT' &&
                            <c.field
                                l={getTxt('Address Province')}
                                f="address_province"
                                v={getLabel(DictionaryTypes.ItalianProvinces, broker.address_province)}  
                                d
                            />}
                        {address_country_cd === 'IT' &&
                            <c.field
                                provinceId={birth_province}
                                l={getTxt('Address Città')}
                                f="address_city"
                                v={getLabel(DictionaryTypes.ItalianCities, broker.address_city)}  
                                d
                            />}
                        {address_country_cd !== 'IT' &&
                            <c.field l={getTxt('Address Città')} f="address_city" d />
                        }
                        <c.field l={getTxt('CAP')} f="address_cap" d />
                        <c.field l={getTxt('Indirizzo')} f="address_street" d />
                        <c.field l={getTxt('Numero Civico')} f="number_street" d />


                        <c.group l={getTxt('Informazioni Contatti')} d />
                        <c.field l={getTxt('Telefono')} f="phone" d />
                        <c.field l={getTxt('Cellulare')} f="mobile_phone" d />
                        <c.field l={getTxt('Email')} f="email" d />
                        <c.field l={getTxt('PEC')} f="email_pec" d />
                        <c.field l={getTxt('Fax')} f="fax" d />

                        <c.group l={getTxt('Informazioni Aggiuntive')} d />
                       
                        <c.field l={getTxt('Partita IVA')} f="vat_number" d />
                        <c.field l={getTxt('Data di Nascita')} f="birth_date" d />
                        <c.field l={getTxt('Codice Fiscale')} f="fiscal_code" d />
                        <c.field l={getTxt('IBAN')} f="bank_iban" d />
                        <c.field l={getTxt('Data inizio accordo')} f="agreement_start_date" d />
                        <c.field l={getTxt('Rui number')} f="rui_number" d />
                        <c.field l={getTxt('Percentuale Ritenute')} f="withholdings_percent" d />

                    </MaterialForm>
                }
            </div>

        );
    }
}

export default graphql(queries.brokerByIdQuery,
    {
        options: props => ({
            variables: { id: props.brokerId ? props.brokerId : props.match.params.id },
            fetchPolicy: 'network-only',
            options: {
                errorPolicy: 'all',
            },
        }),
    })(withStyles(styles, true)(Broker_Details_View));

