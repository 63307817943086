import { IntegratedSorting, SortingState } from '@devexpress/dx-react-grid';
import { Grid, TableHeaderRow, VirtualTable, Table, TableColumnResizing } from '@devexpress/dx-react-grid-material-ui';
import $ from 'jquery';
import { withStyles } from '@material-ui/core/styles';
import { HashRouter, BrowserRouter, Route, Link, withRouter } from 'react-router-dom';
import React, { Component } from 'react';
import { DictionaryTypes, getDictionary } from '../../global/dictionaryCache';
import RegularCard from '../../components/dashboard/RegularCard';
import { getTxt, tableMessages, createGridResizerCols } from '../../global/appGlobal';
import ComponentEx from '../../components/ComponentEx';



const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        margin: theme.spacing.unit * 3,
    },
    rootAsChildControl: {
        backgroundColor: theme.palette.background.paper,
    },
    button: {
        margin: theme.spacing.unit,
    },
    tableHeader: {
        backgroundColor: theme.palette.background.paper,
    }
});

class Internal_Ins_Type_Search extends ComponentEx {


    state = {
        delDlgOpen: false,
        delId: undefined,
    };

    componentDidMount() {
        $("table[class*='Table-headTable']").addClass(this.props.classes.tableHeader);
    }

    render() {

        const columns = [

            { name: 'label', title: getTxt('Name') },
            { name: 'value', title: getTxt('Key') },

        ];

        createGridResizerCols(this.state, columns, {}, 250);

        const { classes } = this.props;
        const items = getDictionary(DictionaryTypes.InternalInsuranceType);



        return (

            <div className={this.props.noAddBtn ? classes.rootAsChildControl : classes.root}>

                <RegularCard

                    headerColor="red"
                    cardTitle={getTxt('Real Ins. Types')}
                    cardSubtitle={getTxt('List of real Ins. Types')}
                    content={
                        <div>

                        </div>

                    }
                />

                <Grid

                    rows={items}
                    columns={columns}

                >

                    <SortingState
                        defaultSorting={[]}
                    />


                    <IntegratedSorting />

                    <Table
                        messages={tableMessages}
                        cellComponent={({ row, column, style, ...props }) => {
                            if (column.name === 'label') {
                                return (<Table.Cell>
                                    <Link to={`/real_insurance_types/view/${row.value}`} >{row.label}</Link>
                                </Table.Cell>);
                            }
                            return <Table.Cell {...props} />;
                        }}
                    />

                    <TableColumnResizing
                        columnWidths={this.state.columnWidths}
                        onColumnWidthsChange={(cols) => { this.setState({ columnWidths: cols }); }}
                        defaultColumnWidths={this.state.defColumnWidths}
                    />

                    <TableHeaderRow showSortingControls />

                </Grid>

            </div>
        );
    }
}

export default withStyles(styles)(Internal_Ins_Type_Search);
