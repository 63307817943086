import ComponentEx from 'components/ComponentEx';
import { EntityTypes } from 'global/entityDescriptors';
import { withStyles } from '@material-ui/core/styles';
import $ from 'jquery';
import React from 'react';
import moment from 'moment';
import { randomString } from 'global/appGlobal';
import c from '../../../components/form/Components';
import MaterialForm from '../../../components/form/MaterialForm';
import { DictionaryTypes, getDictionary, getValue } from '../../../global/dictionaryCache';
import RequestFormButtons from '../../request/RequestFormButtons';

moment.locale('it-it');

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing.unit * 2,
        margin: theme.spacing.unit * 2,
    },

});


class Persone_Edit_Form extends ComponentEx {

    id = randomString(5, 'aA');

    state = {

    };

    render() {

        const { classes } = this.props;

        return (

            <div id={this.id}>
                <MaterialForm
                    dataSource={this.props.request}
                    objType={EntityTypes.InsuranceRequestPersone}
                    onSubmit={this.props.onSubmit}
                    fields={this.props.fields}

                >
                    <c.group
                        l=""
                        fields={this.props.fields}
                        child={[
                            'durata_contratto_in_anni',
                            'persone_assicurate',
                            'fumatore',
                            'peso',
                            'altezza',
                            'pressione_arteriosa_min',
                            'pressione_arteriosa_max',
                            'patologie_in_essere',
                            'patologie_nucleo_familiare',
                            'beneficiario_caso_morte',
                            'capitale_versato',
                            'controvalore',
                            'caricamenti',
                            'capitale_investito',
                            'prestazione_caso_morte',
                            'vincolo',
                            'pegno',
                            'note_aggiuntive',
                        ]}
                    />

                    <c.field f="durata_contratto_in_anni" />
                    <c.field f="persone_assicurate" ui="multi-link-to-customer" />
                    <c.field f="fumatore" />
                    <c.field f="peso" />
                    <c.field f="altezza" />
                    <c.field f="pressione_arteriosa_min" />
                    <c.field f="pressione_arteriosa_max" />
                    <c.field f="patologie_in_essere" multiline sm={12} />
                    <c.field f="patologie_nucleo_familiare" multiline sm={12} />
                    <c.field f="beneficiario_caso_morte" />
                    <c.field f="capitale_versato" />
                    <c.field f="controvalore" />
                    <c.field f="caricamenti" />
                    <c.field f="capitale_investito" />
                    <c.field f="prestazione_caso_morte" />
                    <c.field f="vincolo" />
                    <c.field f="pegno" />
                    <c.field f="note_aggiuntive" multiline sm={12} />


                    <c.group
                        l="Informazioni Aggiuntive"
                        fields={this.props.fields}
                        child={[
                            'additional_information',
                            'comments',
                        ]}
                    />

                    <c.field f="additional_information" multiline sm={12} />
                    <c.field f="comments" multiline sm={12} />

                    <RequestFormButtons
                        history={this.props.history}
                        transferToContract={this.props.transferToContract}
                        isNew={this.props.isNew}
                        noSave={this.props.oldContractId}
                    />

                </MaterialForm>

            </div>

        );
    }
}

export default withStyles(styles)(Persone_Edit_Form);
