import React from 'react';
import { graphql, compose } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import $ from 'jquery';

import ComponentEx from 'components/ComponentEx';
import {
    SortingState, FilteringState, IntegratedSorting, IntegratedFiltering, SelectionState, IntegratedSelection,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    VirtualTable, TableHeaderRow, TableFilterRow, TableSelection, TableColumnResizing,
} from '@devexpress/dx-react-grid-material-ui';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import * as Icon from '@material-ui/icons';
//import pdfMake from 'pdfmake';
import { getTxt } from '../../global/appGlobal';
import LinearProgressEx from '../../components/LinearProgressEx';
import RegularCard from '../../components/dashboard/RegularCard';
import { authInfo } from 'global/auth';

import { allEmailTemplatesQuery } from '../email-templates/emailTemplatesQueries';

const styles = theme => ({
    root: {
        //
        padding: theme.spacing.unit * 2,
        // margin: theme.spacing.unit * 2,
    },

    button: {
        margin: theme.spacing.unit,
    },

    badge: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        marginTop: 0,
    },

    gridRoot: {
        height: '100%',
    },


    formCaption: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit * 2,
        width: '100%',
        position: 'relative',
        height: 40,
    },
    formCaptionLabel: {
        position: 'absolute',
        backgroundColor: 'transparent',
        left: 25,
        paddingLeft: 10,
        paddingRight: 10,
    },

    rootPaper: theme.mixins.gutters({

        paddingTop: 0,
        paddingBottom: 0,
        marginTop: 0,// theme.spacing.unit * 3,

    }),

    dialogText: {
        color: theme.palette.error.main,
    },

    calcCodeBtn: {
        marginTop: theme.spacing.unit,
    },

    formButtons: {
        float: 'right',
        margin: '15px',
    },
});



class Email_Templates_Search extends ComponentEx {
    render() {
        const { classes } = this.props;

        const items = this.props.data.allEmailTemplates ? this.props.data.allEmailTemplates : [];


        return (

            <div className={classes.root}>

                {!this.props.noCaption && <RegularCard

                    headerColor="red"
                    cardTitle={getTxt('Email Templates')}
                    cardSubtitle={getTxt('List of Email Notification Templates')}
                    content={
                        <div style={{ paddingTop: 20 }}>
                            {
                                items.map((i) => 
                                <div style={{whiteSpace: 'normal'}} >
                                    <Link to={`/email_template/${i.email_template_id}`} >{i.template_name}</Link>
                                    <br />
                                    <br />
                                </div>)
                            }
                        </div>
                    }
                />}



            </div >


        );
    }


}


export default compose(
    graphql(allEmailTemplatesQuery, {
        options: props => ({ variables: {} }),
    }),

)(withStyles(styles)(Email_Templates_Search));