import React, { Component } from 'react';
import { getTxt } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';
import { withTheme } from '@material-ui/core';

import { Typography } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';



import { Paper } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { AppBar } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { TextField } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import gql from 'graphql-tag';
import { graphql, compose } from 'react-apollo';
import { Tabs, Tab } from '@material-ui/core';
import * as Icon from '@material-ui/icons';
import GridEx from 'components/form/GridEx';
import { Button } from '@material-ui/core';
import moment from 'moment';
import { Badge } from '@material-ui/core';

import ComponentEx from 'components/ComponentEx';



import { getCompanyQuery, allCompaniesQuery, companyIbanQuery, updateCompanyIbanMutation, allCompanyIbansQuery } from '../../modules/company/CompanyQueries';
import c from '../../components/form/Components';
import LinearProgressEx from '../../components/LinearProgressEx';
import { getLabel, DictionaryTypes, getDictionary, getValue } from '../../global/dictionaryCache';
import MaterialForm from '../../components/form/MaterialForm';
import MFormText from '../../components/form/MFormText';

import { Dialog } from '@material-ui/core';
import { DialogTitle } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { DialogContentText } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import { EntityTypes } from '../../global/entityDescriptors';

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing.unit * 2,
        margin: theme.spacing.unit * 2,
    },

    button: {
        margin: theme.spacing.unit,
    },

    badge: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        marginTop: 0,
    },

    gridRoot: {
        height: '100%',
    },


    formCaption: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit * 2,
        width: '100%',
        position: 'relative',
        height: 40,
    },
    formCaptionLabel: {
        position: 'absolute',
        backgroundColor: 'transparent',
        left: 25,
        paddingLeft: 10,
        paddingRight: 10,
    },

    rootPaper: theme.mixins.gutters({

        paddingTop: 0,
        paddingBottom: 0,
        marginTop: 0,// theme.spacing.unit * 3,

    }),

});


class Company_Iban_Edit extends ComponentEx {

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.formChanged = this.formChanged.bind(this);
    }

    state = {
        selectedCompanies: undefined,

    };

    onSubmit(e, { obj, showErrors, hideErrors }) {

        obj.iban_id = this.props.data.companyIban.iban_id;
        obj.companies = JSON.parse(this.state.selectedCompanies).map((i) => {
            return parseInt(i, 0);
        });
        delete obj.selectedCompanies;

        this.props.updateCompanyIban({
            variables: { iban: obj },
            refetchQueries: [
                {
                    query: allCompanyIbansQuery,
                },
            ],
        }, this).then(
            (resp) => {
                hideErrors();
                const serverErrors = resp.data.updateCompanyIban.validation;
                const id = resp.data.updateCompanyIban.id;

                if (serverErrors && serverErrors.length > 0) {
                    showErrors(serverErrors);
                } else {
                    if (!this.props.isProfile) {
                        this.props.history.push(`/reports_header`);
                    } else {
                        this.setState({ okDlg: true });
                    }

                }
            });
    }

    formChanged(field, value, type, prevVal) {
        if (field === 'selectedCompanies') {
            this.state.selectedCompanies = value;
        }

    }

    render() {

        const { classes } = this.props;
        const loading = this.props.data.loading;
        const iban = this.props.data.companyIban;

        let caption;

        if (this.props.match && (!this.props.match.params.id || this.props.match.params.id === 'new')) {
            caption = getTxt('Nuovo IBAN Agenzia');
        } else {
            caption = getTxt('Modifica IBAN Agenzia');
        }

        if (iban && !this.state.selectedCompanies) {
            this.state.selectedCompanies = JSON.stringify(iban.companies.map(comp => comp.insurance_company_id.toString()));
        }

        return (

            <div className={classes.root}>
                <Typography variant="h4" color="inherit" noWrap={false}>{caption}</Typography>
                <LinearProgressEx loading={loading} />
                {iban &&

                    <MaterialForm dataSource={iban} objType={EntityTypes.Iban} onSubmit={this.onSubmit} onChange={this.formChanged}>

                        <c.group l={getTxt('')} />

                        <c.field l={getTxt('IBAN')} f="iban" ui="iban" />
                        <c.field f="selectedCompanies" l={getTxt('Companies')} v={this.state.selectedCompanies} ui="multi-link-to-company" />


                        <c.formButtons sm={12} onCancel={() => { this.props.history.goBack(); }} />
                    </MaterialForm>


                }

                <Dialog
                    className={classes.dialog}
                    open={this.state.okDlg}
                    onClose={() => {
                        this.setState({ okDlg: undefined });
                    }}
                >
                    <DialogTitle> <span className={classes.dialogText}>{getTxt('Company Profile')}</span></DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <span className={classes.dialogText}>{getTxt('The company profile saved successfully.')}</span>
                        </DialogContentText>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { this.setState({ okDlg: false }); }} color="primary" >
                            {getTxt('Ok')}
                        </Button>

                    </DialogActions>
                </Dialog>



            </div>

        );
    }
}


export default compose(
    graphql(companyIbanQuery, {
        options: props => ({ variables: { id: props.match.params.id } }),
    }),
    graphql(updateCompanyIbanMutation, {
        name: 'updateCompanyIban',
        options: {
            errorPolicy: 'all',
        },
    }),
)(withStyles(styles)(Company_Iban_Edit));
