import React, { Component } from 'react';
import { getTxt } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { authInfo } from 'global/auth';
import { Paper } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { AppBar } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { TextField } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';

import * as Icon from '@material-ui/icons';
import { Checkbox } from '@material-ui/core';

import { Button } from '@material-ui/core';
import moment from 'moment';
import { Badge } from '@material-ui/core';

import ComponentEx from 'components/ComponentEx';

import { p } from 'components/form/Proto';

import { LinearProgress } from '@material-ui/core';
import gql from 'graphql-tag';
import { graphql, compose } from 'react-apollo';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import { EntityTypes, getEntityDescriptor } from 'global/entityDescriptors';


import LinearProgressEx from '../../components/LinearProgressEx';
import { getLabel, DictionaryTypes, getDictionary, getValue, getInternalInsTypeCd } from '../../global/dictionaryCache';
import MaterialForm from '../../components/form/MaterialForm';
import MFormText from '../../components/form/MFormText';
import { get_Requests_ById_Query, allRequestsQuery } from '../../modules/request/requestQeries';
import Viecoli_View_Details_Form from '../../modules/request/viecoli/Viecoli_View_Details_Form';

import Cauzione_View_Detail_Form from '../../modules/request/cauzione/Cauzione_View_Detail_Form';
import Cose_View_Detail_Form from '../../modules/request/cose/Cose_View_Detail_Form';
import Persone_View_Detail_Form from '../../modules/request/persone/Persone_View_Detail_Form';
import Other_View_Detail_Form from '../../modules/request/other/Other_View_Detail_Form';
import { oneTimeTokenMutation } from '../broker/Broker_Balance_Search';
import { ReportTypes } from '../../global/reporting';

const styles = theme => ({
    button: {
        margin: theme.spacing.unit,
    },

    badge: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        marginTop: 0,

    },
    rootPaper: theme.mixins.gutters({
        paddingTop: 16,
        paddingBottom: 16,
        marginTop: theme.spacing.unit * 3,
    }),
    dialogText: {
        color: theme.palette.error.main,
    },

});


class Request_Details_View extends ComponentEx {

    constructor(props) {
        super(props);
        this.generatePDF = this.generatePDF.bind(this);
    }

    state = {
        editDlgOpen: false,
        newRequestDlgOpen: false,
    }

    generateDetailsPDF = { func: undefined };

    componentWillMount() {
        if (this.props.history.location.search === '?sm=1') {
            this.state.newRequestDlgOpen = true;
        }
    }

    componentWillUnmount() {
        delete window.refetch_Request_Details_View;
    }

    generatePDF() {

        const groups = [];

        window.requestDetailsPdfData.forEach((group) => {
            const gr = { caption: group.caption, fields: [] };
            gr.fields = [];

            group.fields.forEach((field) => {
                if (field.value || field.value === '') {
                    gr.fields.push({ label: field.label, value: Buffer.from(field.value + '', 'utf8').toString('base64') });
                }
            });
            groups.push(gr);

        });

        const reqId = getId(this.props);
        const insType = getInternalInsTypeCd(getInsType(this.props));

        const newWindow = window.open("", "_blank");
        newWindow.document.write(getTxt('Generating PDF, please wait...'));
        this.props.getOneTimeToken({

        }, this).then(
            (resp) => {

                if (resp.data && resp.data.oneTimeTokenMutation && resp.data.oneTimeTokenMutation.token) {
                    newWindow.location.href = encodeURI(`${window.apiUrl}files/reports?ott=${resp.data.oneTimeTokenMutation.token}&type=${ReportTypes.Request_Details}&params=${JSON.stringify(
                        {
                            data: JSON.stringify(groups),
                            caption: window.pdfDetailscaption ? Buffer.from(window.pdfDetailscaption + '', 'utf8').toString('base64') : '',
                            id: reqId,
                            insType,
                        },
                    )
                        }`);
                    newWindow.focus();
                }

            });
    }

    render() {

        window.refetch_Request_Details_View = this.props.data.refetch;

        const { classes } = this.props;

        const loading = this.props.data.loading;
        const generateDetailsPDF = { func: undefined };

        const insType = getInsType(this.props);
        const internalInsType = getInternalInsTypeCd(insType);
        let fields = [];

        let request;
        if (this.props.data.get_request_by_id) {
            Object.keys(this.props.data.get_request_by_id).forEach((key) => {
                if (this.props.data.get_request_by_id[key] && this.props.data.get_request_by_id[key].insurance_request_id) {
                    request = this.props.data.get_request_by_id[key];
                }
            });

            if (this.props.data.get_request_by_id.fields) {
                fields = JSON.parse(this.props.data.get_request_by_id.fields);
            }

        }

        return (


            <div >
                <LinearProgressEx loading={loading} />
                {request && request.state_cd !== 'TRANSFERRED_TO_CONTRACT' && !this.props.noEditBtn && !authInfo.isCustomer() &&
                    <Button
                        color="primary"
                        className={classes.button}
                        onClick={() => {
                            this.props.history.push(`/requests/${insType}/edit/${request.customer.customer_id}/${request.insurance_request_id}`);
                        }}>
                        {getTxt('Edit Details')}
                    </Button>
                }

                {request && request.state_cd === 'TRANSFERRED_TO_CONTRACT' && !this.props.noEditBtn && !authInfo.isCustomer() &&
                    (authInfo.isAgencyAdministrator() || authInfo.isMasterBroker()) &&
                    <Button
                        color="primary"
                        className={classes.button}
                        onClick={() => {
                            this.setState({ editDlgOpen: true });
                        }}>
                        {getTxt('Edit Details')}
                    </Button>
                }

                {request &&
                    <Button
                        color="primary"
                        className={classes.button}
                        onClick={this.generatePDF}
                    >
                        {getTxt('Create PDF')}
                    </Button>
                }

                <Dialog
                    className={classes.dialog}
                    open={this.state.editDlgOpen}
                    onClose={() => {
                        this.setState({ editDlgOpen: false });
                    }}
                >
                    <DialogTitle> <span className={classes.dialogText}>{getTxt('Request transfered to contrct.')} </span></DialogTitle>
                    <DialogContent>
                        <DialogContentText>

                            <span className={classes.dialogText}>{getTxt('Are you sure do you want to edit the request already transfered to contract.')}</span>
                            <br />

                        </DialogContentText>

                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                this.setState({ editDlgOpen: false });
                                this.props.history.push(`/requests/${insType}/edit/${request.customer.customer_id}/${request.insurance_request_id}`);
                            }}
                            color="primary"
                        >
                            {getTxt('Yes')}
                        </Button>

                        <Button
                            onClick={() => {
                                this.setState({ editDlgOpen: false });
                            }}
                            color="primary"
                        >
                            {getTxt('Cancel')}
                        </Button>

                    </DialogActions>
                </Dialog>

                <Dialog
                    className={classes.dialog}
                    open={this.state.newRequestDlgOpen}
                    onClose={() => {
                        this.setState({ newRequestDlgOpen: false });
                        window.history.replaceState({}, document.title, "/" + window.location.hash.replace('?sm=1', ''));
                    }}
                >
                    <DialogContent>
                        <DialogContentText>
                            <span> {getTxt('La tua richiesta è stata ricevuta ed è in fase di elaborazione')}  </span>
                        </DialogContentText>

                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                this.setState({ newRequestDlgOpen: false });
                                window.history.replaceState({}, document.title, "/" + window.location.hash.replace('?sm=1', ''));
                            }}
                            color="primary"
                        >
                            Ok
                        </Button>

                    </DialogActions>
                </Dialog>

                {request &&
                    <div>
                        {internalInsType === 'VEICOLI' && <Viecoli_View_Details_Form request={request} fields={fields} generatePdfFunc={this.generateDetailsPDF} />}
                        {internalInsType === 'CAUZIONE' && <Cauzione_View_Detail_Form request={request} fields={fields} generatePdfFunc={this.generateDetailsPDF} />}
                        {internalInsType === 'COSE' && <Cose_View_Detail_Form request={request} fields={fields} generatePdfFunc={this.generateDetailsPDF} />}
                        {internalInsType === 'PERSONE' && <Persone_View_Detail_Form request={request} fields={fields} generatePdfFunc={this.generateDetailsPDF} />}
                        {internalInsType === 'OTHER' && <Other_View_Detail_Form request={request} fields={fields} generatePdfFunc={this.generateDetailsPDF} />}
                    </div>
                }

            </div>

        );
    }
}

function getId(props) {

    if (props.id) return props.id;
    if (props.match && props.match.params.id) return props.match.params.id;
    return 'new';
}

function getCustomerId(props) {

    if (props.customerId) return props.customerId;
    if (props.match && props.match.params.customerId) {
        return props.match.params.customerId;
    }
    return undefined;
}

function getInsType(props) {

    if (props.insType) return props.insType;
    if (props.match && props.match.params.insType) {
        return props.match.params.insType;
    }
    return undefined;
}


export default compose(


    graphql(oneTimeTokenMutation, {
        name: 'getOneTimeToken',
        options: {
            errorPolicy: 'all',

        },
    }),

    graphql(get_Requests_ById_Query, {
        options: props => (
            {


                variables: {
                    id: getId(props),
                    customerId: getCustomerId(props),
                    insType: getInternalInsTypeCd(getInsType(props)),
                    publicInsType: props.insType,
                    getMeta: true,
                },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }
        ),
    }),

)(withStyles(styles)(Request_Details_View));
