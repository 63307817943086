
//import FormField from 'components/form/FormField';
import GridEx from 'components/form/GridEx';
import { getEntityDescriptor } from 'global/entityDescriptors';
import { FormControl } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { compose, graphql } from 'react-apollo';
import FormFieldHigh from '../../components/form/FormFieldHigh';
import { customersAsDictionary } from '../../modules/client/clientQueries';





const styles = theme => ({
    label: {
        ...theme.typography.body1,
        marginRight: theme.spacing.unit,
        color: theme.palette.text.secondary,
        whiteSpace: 'nowrap', 
    },
    value: {
        ...theme.typography.body1,
        color: theme.palette.text.primary,
        //fontWeight: 'bold',
    },
    formControl: {
        margin: theme.spacing.unit / 4,
        flexDirection: 'row',
        whiteSpace: 'normal',
    },
});

class ReadOnlyLinkToCustomer extends FormFieldHigh {


    
    render() {
        const { classes } = this.props;

        //const loading = this.props.data.loading;
        const customers = this.props.data.customersAsDictionary ? this.props.data.customersAsDictionary : [];
        let value = '';
        if (this.props.f && this.context.dataSource) {
            value = this.context.dataSource[this.props.f];
        }
        if (this.props.v) {
            value = this.props.v;
        }

        let selected;
        if (customers.length > 0 && value) {
            selected = customers.find(c => c.customer_id === (value + ''));
        }

        if (this.context.fields && this.context.fields.all.length > 0) {
            if (!this.context.fields.byId[this.props.f]) {
                return '';
            }
        }

        return (

            <GridEx item sm={this.props.sm || 12} xs={this.props.xs || 12}>
                <FormControl className={classes.formControl}>
                    <span className={classes.label} >
                        { this.getLabel()}
                    </span>
                    <span className={classes.value}>
                        {selected ? selected.display_name : ''}
                    </span>
                </FormControl>
            </GridEx>

        );
    }
}

ReadOnlyLinkToCustomer.contextTypes = {
    onChange: PropTypes.func,
    registerControl: PropTypes.func,
    objType: PropTypes.string,
    dataSource: PropTypes.object,
    unRegisterControl: PropTypes.func,
    fields: PropTypes.Object,
};

export default compose(
    graphql(customersAsDictionary, {
        options: props => ({ }),
    }),
)(withStyles(styles)(ReadOnlyLinkToCustomer));