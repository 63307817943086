import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/core/styles';
import { getTxt } from '../global/appGlobal';

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const styles1 = theme => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.dark,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing.unit * 2,
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
});

function MySnackbarContent(props) {
    const { classes, className, message, onClose, variant, ...other } = props;
    const Icon = variantIcon[variant];

    return (
        <SnackbarContent
            className={classNames(classes[variant], className)}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.message}>
                    <Icon className={classNames(classes.icon, classes.iconVariant)} />
                    {message}
                </span>
            }
            action={[
                <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    className={classes.close}
                    onClick={onClose}
                >
                    <CloseIcon className={classes.icon} />
                </IconButton>,
            ]}
            {...other}
        />
    );
}

MySnackbarContent.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    message: PropTypes.node,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

const styles2 = theme => ({
    margin: {
        margin: theme.spacing.unit,
    },
    root: {
        marginTop: theme.spacing.unit * 10,
    },
});

class SnackbarMessage extends React.Component {
    state = {
        open: false,
    };

    handleClick = () => {
        this.setState({ open: true });
    };

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        if (this.props.onClose) {
            this.props.onClose();
        }


    };

    render() {
        const { classes } = this.props;

        let msg;
        if (this.props.variant === 'report_header_save_ok') {
            msg = getTxt('Modifiche salvate correttamente');
        }

        // if (this.props.variant === 'del_ok') {
        //     msg = getTxt('Deleted successfully.');
        // }
        // if (this.props.variant === 'save_ok') {
        //     msg = getTxt('Saved successfully.');
        // }
        // if (this.props.variant === 'publish_ok') {
        //     msg = getTxt('Published successfully.');
        // }
        // if (this.props.variant === 'status_changed_ok') {
        //     msg = getTxt('Status changed successfully.');
        // }
        // if (this.props.variant === 'order_created') {
        //     msg = getTxt('Oreder placed successfully.');
        // }
        // if (this.props.variant === 'order_updated') {
        //     msg = getTxt('Oreder updated successfully.');
        // }
        // if (this.props.variant === 'email_template_changed_ok') {
        //     msg = getTxt('Email template updated successfully.');
        // }
        // if (this.props.variant === 'admin_profile_change_ok') {
        //     msg = getTxt('Admin profile updated successfully.');
        // }
        // if (this.props.variant === 'engineer_payment_ok') {
        //     msg = getTxt('Payment Accepted.');
        // }
        // if (this.props.variant === 'engineer_payment_cancel') {
        //     msg = getTxt('Payment Canceled.');
        // }
        // if (this.props.variant === 'engineer_payment_error') {
        //     msg = getTxt('Payment canceled, PayPal error.');
        // }

        return (
            <div>
                <Snackbar

                    anchorOrigin={{
                        vertical: 'top', horizontal: 'left',
                    }}
                    open
                    autoHideDuration={6000}
                    onClose={this.handleClose}

                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                >
                    <div>
                        {(this.props.variant === 'del_ok' ||

                            this.props.variant === 'report_header_save_ok' ||
                            this.props.variant === '9999999999') &&
                            <MySnackbarContentWrapper
                                onClose={this.handleClose}
                                variant="success"
                                message={(
                                    <div>
                                        <span>{msg}</span>
                                    </div>
                                )}
                            />}
                        {(this.props.variant === 'del_ok' ||
                            this.props.variant === 'save_ok' ||
                            this.props.variant === 'publish_ok' ||

                            this.props.variant === 'status_changed_ok') &&
                            <MySnackbarContentWrapper
                                onClose={this.handleClose}
                                variant="success"
                                message={(
                                    <div>
                                        <span><b>{this.props.objName}</b></span>
                                        <br />
                                        <span>{msg}</span>
                                    </div>
                                )}
                            />}

                        {(this.props.variant === 'order_created' || this.props.variant === 'order_updated' ||
                            this.props.variant === 'email_template_changed_ok' || this.props.variant === 'engineer_payment_ok' || this.props.variant === 'admin_profile_change_ok') &&
                            <MySnackbarContentWrapper
                                onClose={this.handleClose}
                                variant="success"
                                message={(
                                    <div>
                                        <span>{msg}</span>
                                    </div>
                                )}
                            />}
                        {(this.props.variant === 'order_confirmed' || this.props.variant === 'order_declined') &&
                            <MySnackbarContentWrapper
                                onClose={this.handleClose}
                                variant="success"
                                message={(
                                    <div>
                                        <span>{this.props.msg}</span>
                                    </div>
                                )}
                            />}
                        {(this.props.variant === 'email_sent_to_customer_ok') &&
                            <MySnackbarContentWrapper
                                onClose={this.handleClose}
                                variant="success"
                                message={(
                                    <div>
                                        <span>{getTxt('Email inviata al cliente.')}</span>
                                    </div>
                                )}
                            />}

                        {(this.props.variant === 'engineer_payment_cancel' || this.props.variant === 'engineer_payment_error') &&
                            <MySnackbarContentWrapper
                                onClose={this.handleClose}
                                variant="error"
                                message={(
                                    <div>
                                        <span>{msg}</span>
                                    </div>
                                )}
                            />}

                    </div>
                </Snackbar>
                {/* <MySnackbarContentWrapper
                    variant="error"
                    className={classes.margin}
                    message="This is an error message!"
                />
                <MySnackbarContentWrapper
                    variant="warning"
                    className={classes.margin}
                    message="This is a warning message!"
                />
                <MySnackbarContentWrapper
                    variant="info"
                    className={classes.margin}
                    message="This is an information message!"
                />
                <MySnackbarContentWrapper
                    variant="success"
                    className={classes.margin}
                    message="This is a success message!"
                /> */}
            </div>
        );
    }
}

SnackbarMessage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles2)(SnackbarMessage);