import { FilteringState, IntegratedFiltering, IntegratedSorting, SortingState, VirtualTableState } from '@devexpress/dx-react-grid';
import { Grid, TableFilterRow, TableHeaderRow, VirtualTable, TableColumnResizing } from '@devexpress/dx-react-grid-material-ui';
import { Plugin, Template, TemplatePlaceholder } from '@devexpress/dx-react-core';
import ComponentEx from 'components/ComponentEx';
import c from 'components/form/Components';
import { getToken } from 'global/auth';
import { ReportTypes } from 'global/reporting';
import $ from 'jquery';
import { Button, Tooltip, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Toolbar, Input } from '@material-ui/core';
import { TableCell } from '@material-ui/core';
import { Menu, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { Checkbox } from '@material-ui/core';
import { graphql, compose } from 'react-apollo';
import { Typography } from '@material-ui/core';
import gql from 'graphql-tag';
import { Link, useHistory, useParams } from 'react-router-dom';
import LinearProgressEx from '../../components/LinearProgressEx';
import LinkToCustomerById from '../../components/LinksToObjectById';
import { getTxt, filterRowMessages, tableMessages, createGridResizerCols, createGridResizerColsHooksGrid, randomString } from '../../global/appGlobal';
import { DictionaryTypes, getDictionary, getLabel } from '../../global/dictionaryCache';
import { allCustomersQuery, deleteCustomerMutation, allCustomersQuery2 } from '../../modules/client/clientQueries';
import RegularCard from '../../components/dashboard/RegularCard';
import * as Icon from '@material-ui/icons';
import { authInfo } from 'global/auth';
import { useState } from 'react';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useEffect } from 'react';
import { useRef } from 'react';
import GridFiltersButton from '../../components/big-grid-data/GridFiltersButton';
import { oneTimeTokenMutation } from '../broker/Broker_Balance_Search';
import ItemGrid from 'components/Grid/ItemGrid';
import { Grid as RGrid } from '@material-ui/core';


export const LOAD_MORE_ROW_VALUE = -9999;
const LOAD_MORE_PROP = 'display_name';
function isLoadMoreRow(row) {
    return row[LOAD_MORE_PROP] === LOAD_MORE_ROW_VALUE;
}

function getPhone(f1, f2, f3) {
    let phone = f1;
    if (!phone) {
        phone = f2;
    }
    if (!phone) {
        phone = f3;
    }
    return phone;
}

function getGridAddressLine(row) {
    let line = (row.address_cap ? row.address_cap : ' ') +
        (row.address_street ? ', ' : ' ') +
        (row.address_street ? row.address_street : ' ') +
        (row.address_street_number ? ', ' : ' ') +
        (row.address_street_number ? row.address_street_number : ' ');
    line = line.trimLeft();
    if (line.startsWith(',')) {
        line = line.substring(1, line.length);
    }
    return line;
}


export const MIN_TAKE = 50;
export const MAX_TAKE = 2000;
export const FILTERS_TIMEOUT = 2000;

let filtersL = [];
let takeL = MIN_TAKE;

const getRowId = row => row.customer_id;
let filterTimeoutId;

function compareFilters(currentFilters, filters) {
    let visible = false;
    if (currentFilters.length !== filters.length) {
        visible = true;
    } else {
        for (let i = 0; i < currentFilters.length; i += 1) {
            const oldFilter = currentFilters[i];
            const newFilter = filters.find(nf => nf.columnName === oldFilter.columnName);
            if (!newFilter) {
                visible = true;
                break;
            } else if (oldFilter.operation !== newFilter.operation) {
                visible = true;
                break;
            } else if (oldFilter.value !== newFilter.value) {
                visible = true;
                break;
            }
        }
    }
    return visible;
}

export function canLoadMore(itemsCount, currentMax, absoluteMax) {
    if (itemsCount === 0) {
        return false;
    }
    if (currentMax === absoluteMax && itemsCount > absoluteMax) {
        return false;
    }
    return true;
}

export function filtersPromptVisible(itemsCount, currentMax, absoluteMax) {
    if (itemsCount === 0) {
        return false;
    }
    if (currentMax === absoluteMax && itemsCount > absoluteMax) {
        return true;
    }
    return false;
}

export function getNextTake(ranges, current) {
    if (current === ranges[ranges.length - 1]) {
        return ranges[ranges.length + 1];
    }
    for (let i = 0; i < ranges.length; i += 1) {
        const n = ranges[i];
        if (n === current) {
            return ranges[i + 1];
        }
    }
}





export default function Client_Search_BigData() {

    function getFilterRowClassName(filters) {
        if (filters.length > 1) {
            return 'filterRowActive';
        }
        if (filters.length === 0) {
            return '';
        }
        if (filters.length === 1) {
            if (filters[0].value === 'customers-no-contracts') {
                return '';
            } else {
                return 'filterRowActive';
            }
        }
        return '';
    }

    const theme = window.theme;
    const styles = {
        root: {
            backgroundColor: theme.palette.background.paper,
            margin: theme.spacing.unit * 3,
        },
        button: {
            marginRight: theme.spacing.unit,
        },

        tableHeader: {
            backgroundColor: theme.palette.background.paper,
        },

        totalsRow: {
            backgroundColor: theme.palette.grey['200'],
            fontWeight: 'bold',
        },
    };

    const columns = [
        { name: 'display_name', title: getTxt('Nome') },
        { name: 'customer_id', title: getTxt('Codice Interno') },
        { name: 'customer_type_cd', title: getTxt('Tipo') },
        { name: 'address_street', title: getTxt('Indirizzo') },
        { name: 'address_city', title: getTxt('Città') },
        { name: 'address_province', title: getTxt('Province') },
        { name: 'email', title: getTxt('Email') },
        { name: 'vat_number', title: getTxt('VAT Number') },
        { name: 'fiscal_code', title: getTxt('Fiscal Code') },
        { name: 'owner_broker_id', title: getTxt('Collaboratore') },
    ];

    if (authInfo.isAgencyAdministrator() || authInfo.isMasterBroker()) {
        columns.push({ name: 'is_deleted', title: getTxt('Actions') });
    }

    const defColumnWidths = createGridResizerColsHooksGrid(columns, { display_name: 240, customer_id: 100 }, 180);
    const [сolumnWidths, setColumnWidths] = useState(defColumnWidths);

    const [delId, setDelId] = useState();
    const [delNumber, setDelNumber] = useState();
    const [anchorEl, setAnchorEl] = useState();
    const history = useHistory();
    const { filter } = useParams();

    function initCustomersNoContractsFilter(filters) {
        if (filter !== 'customers-no-contracts') {
            filters.forEach((f) => {
                if (f.value === 'customers-no-contracts') {
                    f.value = '';
                }
            });
            return;
        }
        if (filters.length === 0) {
            filters.push({ value: 'customers-no-contracts' });
        } else {
            const defFilter = filters.find(f => f.value === 'customers-no-contracts');
            if (!defFilter) {
                filters.push({ value: 'customers-no-contracts' });
            }
        }
    }

    const [refetch, { loading, data }] = useLazyQuery(allCustomersQuery2, { client: window.apolloClient });
    const [deleteCustomer, { loading: loadingDelete }] = useMutation(deleteCustomerMutation, {
        client: window.apolloClient,
        onCompleted: (data) => {
            initCustomersNoContractsFilter(filtersL);
            refetch({ variables: { take: takeL, filters: filtersL, id: randomString(7, 'aA') } });
        },
        refetchQueries: [

        ],

    });

    const [getOneTimeToken, { loading: loadingOneTimeTocken }] = useMutation(oneTimeTokenMutation, {
        client: window.apolloClient,
        onCompleted: (data) => {

        },
    });

    const items = data && data.allCustomers2 ? data.allCustomers2 : [];
    if (items.length > 0 && items.length > takeL && !isLoadMoreRow(items[items.length - 1])) {
        items.push({
            [LOAD_MORE_PROP]: LOAD_MORE_ROW_VALUE,
            customer_id: LOAD_MORE_ROW_VALUE,
        });
    }

    useEffect(() => {
        filtersL = [];
        initCustomersNoContractsFilter(filtersL);
        refetch({ variables: { take: takeL, filters: filtersL, id: randomString(7, 'aA') } });
    }, []);


    // useEffect(() => {
    //     if (latestFilterId) {
    //         $('#' + latestFilterId).focus();
    //     }
    // });
    //const display_name_ref = useRef(null);

    let title;
    if (filter === 'customers-no-contracts') {
        title = 'Clienti senza polizza';
    }

    return (

        <div style={styles.root}>
            <LinearProgressEx loading={loading || loadingDelete} />
            <RegularCard

                headerColor="purple"
                cardTitle={title}
                cardSubtitle={getTxt('List of customers')}
                content={
                    <RGrid container>
                        <ItemGrid xs={12} sm={'auto'} md={'auto'}>
                            <Button color="primary" style={styles.button} onClick={e => setAnchorEl(e.currentTarget)} >
                                {getTxt('Add new Customer')}
                            </Button>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={'auto'} md={'auto'}>
                            <Button
                                color="primary"
                                onClick={() => {

                                    const newWindow = window.open("", "_blank");
                                    newWindow.document.write(getTxt('Generating PDF Report, please wait...'));
                                    getOneTimeToken({

                                    }, this).then(
                                        (resp) => {
                                            if (resp.data && resp.data.oneTimeTokenMutation && resp.data.oneTimeTokenMutation.token) {

                                                newWindow.location.href = `${window.apiUrl}files/reports?ott=${resp.data.oneTimeTokenMutation.token}&type=${ReportTypes.AllCustomersList}`;
                                                newWindow.focus();
                                            }
                                        });
                                }}
                            >
                                {getTxt('Create PDF')}
                            </Button>
                        </ItemGrid>
                        <ItemGrid xs={12} sm={'auto'} md={'auto'}>
                            <Button
                                color="primary"
                                onClick={() => {

                                    const newWindow = window.open("", "_blank");
                                    newWindow.document.write(getTxt('Generazione del report XLS, attendere prego...'));
                                    getOneTimeToken({

                                    }, this).then(
                                        (resp) => {

                                            if (resp.data && resp.data.oneTimeTokenMutation && resp.data.oneTimeTokenMutation.token) {
                                                newWindow.location.href = `${window.apiUrl}files/reports?ott=${resp.data.oneTimeTokenMutation.token}&type=${ReportTypes.AllCustomersList}&params=${JSON.stringify({ xlsx: true })}`;
                                                newWindow.focus();
                                            }

                                        });

                                }}
                            >
                                {getTxt('Genera XLS')}
                            </Button>
                        </ItemGrid>
                    </RGrid>

                }
            />

            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={anchorEl}
                onClose={() => setAnchorEl(null)}
            >
                <MenuItem onClick={() => { history.push('/customers/new/company/'); }}>{getTxt('Azienda')}</MenuItem>
                <MenuItem onClick={() => { history.push('/customers/new/private/'); }}>{getTxt('Private')}</MenuItem>
                <MenuItem onClick={() => { history.push('/customers/new/prospect/private/'); }}>{getTxt('Prospect')}</MenuItem>

            </Menu>
            <div>


                <Grid

                    rows={items}
                    columns={columns}
                    getRowId={getRowId}
                >

                    <FilteringState
                        defaultFilters={filtersL}
                        onFiltersChange={async (f, a) => {
                            filtersL = f;

                            if (filterTimeoutId) {
                                clearTimeout(filterTimeoutId);
                            }
                            filterTimeoutId = setTimeout(async () => {
                                filterTimeoutId = undefined;
                                filtersL = f;
                                initCustomersNoContractsFilter(filtersL);
                                refetch({ variables: { take: takeL, filters: filtersL, id: randomString(7, 'aA') } });

                            }, FILTERS_TIMEOUT);
                        }}
                    />


                    <SortingState
                        defaultSorting={[
                            { columnName: 'customer_id', direction: 'desc' },
                        ]}
                        columnExtensions={[
                            { columnName: 'is_deleted', sortingEnabled: false },
                        ]}
                    />
                    <IntegratedSorting />


                    <VirtualTable
                        messages={tableMessages}
                        rowComponent={({ ...props, row }) => {
                            if (isLoadMoreRow(row)) {
                                return (<VirtualTable.Row
                                    {...props}
                                    style={{ position: 'relative', height: 60 }}
                                >
                                    {canLoadMore(items.length, takeL, MAX_TAKE) &&
                                        <Button
                                            style={{
                                                width: 200,
                                                position: 'absolute',
                                                marginTop: 10,
                                                left: '50%',
                                                marginLeft: -100,
                                            }}
                                            variant='outlined'
                                            onClick={async () => {
                                                takeL = getNextTake([MIN_TAKE, MAX_TAKE], takeL);
                                                initCustomersNoContractsFilter(filtersL);
                                                refetch({ variables: { take: takeL, filters: filtersL, id: randomString(7, 'aA') } });

                                            }}
                                        >
                                            {getTxt('Load More...')}

                                        </Button>
                                    }

                                    {filtersPromptVisible(items.length, takeL, MAX_TAKE) &&
                                        <Typography
                                            variant="body1"
                                            style={{
                                                whiteSpace: 'normal',
                                                width: 350,
                                                position: 'absolute',
                                                marginTop: 20,
                                                left: '50%',
                                                marginLeft: -175,

                                            }}
                                        >
                                            {getTxt('Please, specify filters to load more rows.')}
                                        </Typography>
                                    }

                                </VirtualTable.Row>);
                            }
                            return (<VirtualTable.Row {...props} />);
                        }}
                        //columnExtensions={tableColumnExtensions}
                        cellComponent={({ row, column, style, ...props }) => {

                            if (column.name === 'customer_id' && !isLoadMoreRow(row)) {
                                if (row.customer_type_cd === 'PRIVATE_PROSPECT') {
                                    return (<VirtualTable.Cell>{row.customer_id} </VirtualTable.Cell>);
                                }
                                return (<VirtualTable.Cell> {row.customer_id} </VirtualTable.Cell>);
                            }
                            if (column.name === 'address_street' && !isLoadMoreRow(row)) {
                                return (<VirtualTable.Cell>
                                    {getGridAddressLine(row)}
                                </VirtualTable.Cell>);
                            }
                            if (column.name === 'customer_id' && isLoadMoreRow(row)) {
                                return (<VirtualTable.Cell />);
                            }
                            if (column.name === 'customer_type_cd' && !isLoadMoreRow(row)) {
                                return (<VirtualTable.Cell> {getLabel(DictionaryTypes.CustomerType, row.customer_type_cd)} </VirtualTable.Cell>);
                            }
                            if (column.name === 'display_name' && !isLoadMoreRow(row)) {
                                if (row.customer_type_cd === 'PRIVATE_PROSPECT') {
                                    return (<VirtualTable.Cell> <Link to={`/customers/prospect/private/${row.customer_id}`} style={{ padding: '6px', color: '#9C27B0', fontWeight: 'bold' }} >{row.display_name}</Link> </VirtualTable.Cell>);
                                }
                                return (<VirtualTable.Cell > <Link to={`/customers/${row.customer_type_cd.toLowerCase()}/${row.customer_id}`} style={{ padding: '6px', color: '#9C27B0', fontWeight: 'bold' }} >
                                    {row.display_name} </Link> </VirtualTable.Cell>);
                            }
                            if (column.name === 'address_province' && !isLoadMoreRow(row)) {
                                return (<VirtualTable.Cell> {getLabel(DictionaryTypes.ItalianProvinces, row.address_province)} </VirtualTable.Cell>);
                            }
                            if (column.name === 'address_city' && !isLoadMoreRow(row)) {
                                return (<VirtualTable.Cell> {getLabel(DictionaryTypes.ItalianCities, row.address_city)} </VirtualTable.Cell>);
                            }
                            if (column.name === 'owner_broker_id' && row.broker && !isLoadMoreRow(row)) {
                                return (<VirtualTable.Cell><Link to={`/brokers/${row.broker.broker_id}`} >{row.broker.display_name}</Link></VirtualTable.Cell>);
                            }
                            if (column.name === 'mobile_phone' && !isLoadMoreRow(row)) {
                                return (<VirtualTable.Cell>
                                    {getPhone(row.mobile_phone, row.mobile_phone, row.referent_phone)}
                                </VirtualTable.Cell>);
                            }
                            if (column.name === 'is_deleted' && !isLoadMoreRow(row)) {
                                return (<VirtualTable.Cell>

                                    <Tooltip title={getTxt('Delete')}>
                                        <IconButton size="small" onClick={() => { setDelId(row.customer_id); setDelNumber(row.display_name); }} style={styles.inGridButton} >
                                            <Icon.Delete fontSize="small" />
                                        </IconButton>
                                    </Tooltip>

                                </VirtualTable.Cell>);
                            }
                            if (column.name === LOAD_MORE_PROP && isLoadMoreRow(row)) {
                                return (<VirtualTable.Cell />);
                            }


                            return <VirtualTable.Cell {...props} />;
                        }}
                    />

                    <TableFilterRow
                        messages={filterRowMessages}
                        rowComponent={({ ...props }) => {
                            return (<VirtualTable.Row className={getFilterRowClassName(filtersL)} {...props} />);
                        }}
                        cellComponent={({ column, filter, onFilter, ...props }) => {

                            if (column.name === 'referent_phone') {
                                return <TableCell onFilter={onFilter} filter={filter} {...props} key={column.name} >{''}</TableCell>;
                            }

                            if (column.name === 'customer_type_cd') {
                                return (
                                    <c.gridColFilter noFilterVal={getTxt('Tutti', '#k9ezdp1')} setFilter={onFilter} f={'customer_type_cd'} filters={filtersL} key={column.name} >
                                        {getDictionary(DictionaryTypes.CustomerType)}
                                    </c.gridColFilter>);
                            }

                            if (column.name === 'address_province') {
                                return (
                                    <c.gridColFilter noFilterVal={getTxt('Tutti', '#fq1jbs0')} setFilter={onFilter} f={'address_province'} filters={filtersL} key={column.name} >
                                        {getDictionary(DictionaryTypes.ItalianProvinces)}
                                    </c.gridColFilter>);
                            }

                            if (column.name === 'is_deleted') {
                                return <TableCell onFilter={onFilter} filter={filter} {...props} key={column.name} >{''}</TableCell>;
                            }

                            return <TableFilterRow.Cell onFilter={onFilter} filter={filter} {...props} key={column.name} />;
                        }}
                    />

                    <TableColumnResizing
                        columnWidths={сolumnWidths}
                        onColumnWidthsChange={(cols) => { setColumnWidths(cols); }}
                        defaultColumnWidths={defColumnWidths}
                    />
                    <TableHeaderRow />

                </Grid>

                {/* {filtersL.length > 0 &&
                    <div style={{ position: 'absolute', top: 10, left: 10, zIndex: 999999 }}>

                        <Button
                            variant="contained"
                            style={{ backgroundColor: 'bisque' }}
                            className='filterRowActive'
                            onClick={() => {
                                filtersL = [];
                                refetch({ variables: { take: takeL, filters: filtersL, id: randomString(7, 'aA') } });
                            }}
                        >
                            {getTxt('Reset Filters')}
                        </Button>

                    </div>
                } */}

            </div>
            <Dialog onClose={() => { setDelId(undefined); setDelNumber(undefined); }} open={delId}>
                <DialogTitle >{getTxt('Cancella Cliente') + ' ' + delNumber}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <span>{getTxt('Il cliente sarà cancellato sul server.')}</span>
                        <br />
                        <span >{getTxt('Sei sicuro di voler cancellare il cliente?')}</span>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { deleteCustomer({ variables: { id: delId } }); setDelId(undefined); setDelNumber(undefined); }} color="primary">
                        {getTxt('Ok')}
                    </Button>
                    <Button onClick={() => { setDelId(undefined); setDelNumber(undefined); }} color="primary">
                        {getTxt('Annula')}
                    </Button>

                </DialogActions>
            </Dialog>

        </div >

    );
}
