import gql from 'graphql-tag';


export const allQuotesQuery = gql`

query allQuotesQuery($requestId: Int) {
    allQuotes(requestId: $requestId) {
      insurance_quote_id
      insurance_request_id
      customer_id
      insurance_company_id
      estimated_amount
      agency_fee_amount
      total_amount
      is_confirmed
      date_confirmed
      notes
      company{
        insurance_company_id
        company_name
      }
    }
  }

`;


export const quoteByIdQery = gql`

query quoteByIdQery($id: ID!) {
    quoteById(id: $id) {
      insurance_quote_id
      insurance_request_id
      customer_id
      insurance_company_id
      estimated_amount
      agency_fee_amount
      total_amount
      is_confirmed
      date_confirmed
      notes
      company{
        insurance_company_id
        company_name
      }
    }
   
}

`;

export const updateQuoteMutation = gql`

mutation UpdateQuoteMutation ($quote: insurance_quote_input! ) {
    updateQuoteMutation(quote: $quote){
        id
        validation {
            field
            message
        }
    } 
}

`;
