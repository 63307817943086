
import React, { Component } from 'react';
import { getTxt, randomString } from 'global/appGlobal';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
//import FormField from 'components/form/FormField';
import GridEx from 'components/form/GridEx';
import { MenuItem } from '@material-ui/core';
import { Button } from '@material-ui/core';
import {
    FormLabel,
    FormControl,
    FormGroup,
    FormControlLabel,
    FormHelperText,
} from '@material-ui/core';
import { Checkbox } from '@material-ui/core';
import { Input, InputLabel } from '@material-ui/core';
import FormCaption from 'components/form/FormCaption';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import $ from 'jquery';

import { EntityTypes, getEntityDescriptor } from 'global/entityDescriptors';
import schema from 'async-validator';

import  { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

import NumberFormat from 'react-number-format';
import { Select } from '@material-ui/core';
import { HashRouter, BrowserRouter, Route, Link, withRouter } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';

import MFormControl from '../../components/form/MFormControl';

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: '100%',
    },
});

class NumberFormatCustomPercent extends React.Component {

    constructor(props) {
        super(props);
        this.id = randomString(7, 'aA');
    }

    render() {
        const { inputRef, onChange, ...other } = this.props;
        return (
            <NumberFormat
                {...other}
                ref={inputRef}
                decimalScale={2}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator={'.'}
                decimalSeparator={','}
                isNumericString
            />
        );
    }
}

class NumberFormatCustom extends React.Component {

    constructor(props) {
        super(props);
        this.id = randomString(7, 'aA');
    }

    render() {
        const { inputRef, onChange, ...other } = this.props;
        return (
            <NumberFormat
                {...other}
                ref={inputRef}
                decimalScale={2}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator={'.'}
                decimalSeparator={','}
                isNumericString
                prefix="€ "
            />
        );
    }
}

class MFormText extends MFormControl {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);


        //this.onKeyDown = this.onKeyDown.bind(this);
    }

    onChange(e) {
        this.setState({ value: e.target.value });
        this.setState({ isDirty: true });
        this.formOnChange(this.props.f, e.target.value, this.props.type);
    }

    componentDidMount() {
        if (this.props.max) {
            $('#' + this.id).attr('max', this.props.max);
        }
        if (this.props.maxlength) {
            $('#' + this.id).attr('maxlength', this.props.maxlength);
        }

        if (this.props.propDescriptor && this.props.propDescriptor.type === 'DECIMAL') {
            // $('#' + this.id).on('focus', function (e) {
            //     $(this).select();
            // });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.forseRefresh) {
            this.setState({ value: nextProps.v });
        }



    }

    render() {
        const { classes } = this.props;

        const inputProps = {
            id: this.props.id ? this.props.id : this.id,
            name: this.props.f,
            placeholder: this.props.placeholder,
            max: this.props.max,
            min: this.props.min,
            pattern: this.props.pattern,
            maxlength: this.props.maxlength,
            readOnly: this.props.readOnly,
        }
        if (this.props.currency) {
            inputProps.inputComponent = NumberFormatCustom;
        }
        if (!this.props.currency && this.props.propType === 'DECIMAL') {
            inputProps.inputComponent = NumberFormatCustomPercent;
        }
      
        return (



            <GridEx item sm={this.props.sm || 6} xs={this.props.xs || 12}>

                <TextField
                    className={classes.textField}
                    label={this.getLabel()}
                    margin="normal"
                    {...this.props}
                    value={this.state.value}
                    disabled={this.props.d}
                    required={this.props.r}
                    onChange={this.onChange}

                    InputLabelProps={{ shrink: this.props.labelShrink ? this.props.labelShrink : this.state.labelShrinkAfterSetValue }}
                    inputProps={inputProps}
                    helperText={this.state.errorMessage}
                    error={this.state.errorMessage ? true : false}

                />
            </GridEx >

        );
    }
}

MFormText.contextTypes = {
    onChange: PropTypes.func,
    registerControl: PropTypes.func,
    objType: PropTypes.string,
    dataSource: PropTypes.object,
    unRegisterControl: PropTypes.func,
    fields: PropTypes.Object,
};

export default withStyles(styles, { withTheme: true })(MFormText);