import React, { Component } from 'react';
import { getTxt } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import { Paper } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { AppBar } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { TextField } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';

import * as Icon from '@material-ui/icons';
import { Checkbox } from '@material-ui/core';

import GridEx from 'components/form/GridEx';

import { Button } from '@material-ui/core';
import moment from 'moment';
import { Badge } from '@material-ui/core';

import ComponentEx from 'components/ComponentEx';
import $ from 'jquery';

import { LinearProgress } from '@material-ui/core';
import { graphql, compose } from 'react-apollo';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import { EntityTypes, getEntityDescriptor } from 'global/entityDescriptors';

import c from '../../../components/form/Components';
import LinearProgressEx from '../../../components/LinearProgressEx';
import { getLabel, DictionaryTypes, getDictionary } from '../../../global/dictionaryCache';
import MaterialForm from '../../../components/form/MaterialForm';
import MFormText from '../../../components/form/MFormText';
import { get_Requests_ById_Query, allRequestsQuery, requestBriefQuery, update_Request_Mutation } from '../../../modules/request/requestQeries';
import RequestFormButtons from '../../request/RequestFormButtons';


const styles = theme => ({

});

let transferToContract = false;

class Viecoli_Edit_Form extends ComponentEx {

    constructor(props) {
        super(props);
        this.sicDataChanged = this.sicDataChanged.bind(this);
    }

    state = {
        open: false,
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleRequestClose = () => {
        this.setState({ open: false });
    };

    formGetValues = { func: undefined };
    formSetValues = { func: undefined };

    // componentDidMount() {

    //     const data = new FormData();
    //     data.append('dispatch', 'toWelcomePage');
    //     data.append('j_username', 'id98871');
    //     data.append('j_password', 'Aviva352');
    //     data.append('imp_num', '352');
    //     data.append('enteSel', '352');


    //     $.ajax({
    //         url: 'https://www.sic.ania.it/welcome.htmx',
    //         data,
    //         processData: false,
    //         type: 'POST',
    //         success: (data) => {
    //             alert(data);
    //         }
    //     });
    // }
    sicDataChanged(data) {
        //TELAIO = ZFA19900001514343
        //Tipo_veicolo = A - AUTOVETTURA
        //Causale = PASSAGGIO DI PROPRIETA'
        //Data_emissione_carta = null
        //Provincia_emissione_carta = NA
        //Tipo_movimento = TAGLIANDO
        //Data_immatricolazione = 03 / 06 / 2009
        //Provincia_residenza = NA

        const formValues = {};
    
        if (data.Cavalli_fiscali) {
            formValues.vehicle_cv_ql = data.Cavalli_fiscali;
        }
        if (data.kW) {
            formValues.vehicle_kw = data.kW;
        }
        if (data.Valore) {
            formValues.vehicle_value = data.Valore;
        }
        if (data.Omologazione) {
            formValues.vehicle_approval = data.Omologazione;
        }
        if (data.Tipo_alimentazione) {

            const enTypes = getDictionary(DictionaryTypes.VehicleEngineType);
            const foundType = enTypes.find((i) => {
                const ta = data.Tipo_alimentazione ? (data.Tipo_alimentazione.toUpperCase()) : '';
                return ta.includes(i.value.toUpperCase()) ||
                    i.value.toUpperCase().startsWith(data.Tipo_alimentazione.toUpperCase());
            });
            if (foundType) {
                formValues.vehicle_engine_type = foundType.value;
            } else {
                formValues.vehicle_engine_type = null;
            }
        }
        if (data.Uso) {

            const usoTypes = getDictionary(DictionaryTypes.VehicleUseType);
            const foundUsoType = usoTypes.find((i) => {
                return data.Tipo_alimentazione.toUpperCase().includes(i.value.toUpperCase()) ||
                    i.value.toUpperCase().startsWith(data.Tipo_alimentazione.toUpperCase());
            });
            if (foundUsoType) {
                formValues.vehicle_use = foundUsoType.value;
            } else {
                formValues.vehicle_use = null;
            }
        }
        if (data.Massa_complessiva) {
            formValues.vehicle_towable_weight = data.Massa_complessiva;/////////////
        }
        if (data.atrcHtml) {
            formValues.sic_atrc_data = data.atrcHtml;
        } else {
            formValues.sic_atrc_data = '';
        }

        if (data.Data_immatricolazione) {
            formValues.vehicle_immatricolazione = data.Data_immatricolazione;
        }
        if (data.Impresa) {
            formValues.prev_company = data.Impresa;
        }
        if (data.Provenienza) {
            formValues.prev_cv_prov = data.Provenienza;
        }
        if (data.Assegnazione) {
            formValues.prev_cu_ass = data.Assegnazione;
        }
        if (data.ScadenzaContrattuale) {

            const slashSplit = data.ScadenzaContrattuale.split('/');
            if (slashSplit.length === 3) {
                formValues.prev_contract_expiry = slashSplit[2] + '-' + slashSplit[1] + '-' + slashSplit[0];
            } else {
                formValues.prev_contract_expiry = data.ScadenzaContrattuale;
            }


        }




        if (data.Tipo_veicolo) {
            const types = getDictionary(DictionaryTypes.VehicleType);
            const found = types.find(i => data.Tipo_veicolo.toUpperCase().includes(i.value.toUpperCase()));
            if (found) {
                formValues.vehicle_type = found.value;
            } else {
                formValues.vehicle_type = null;
            }

        }
        if (data.Cod_omologazione) {
            formValues.vehicle_approval = data.Cod_omologazione;
        }
        if (data.Cod_omologazione) {
            formValues.vehicle_approval = data.Cod_omologazione;
        }
        if (data.Modello_costruttore) {
            formValues.vehicle_brand_and_model = data.Modello_costruttore;
        }
        if (data.Marca_modello_veicolo) {
            formValues.vehicle_brand_and_model = data.Marca_modello_veicolo;
        }
        if (data.Cilindrata) {
            formValues.vehicle_cilinders = data.Cilindrata;
        }




        this.formSetValues.func(formValues);

    }

    render() {
        const { classes } = this.props;

        let atrcData;
        if (this.props.request) {
            atrcData = this.props.request.sic_atrc_data;
        }
        if (this.formGetValues.func) {
            atrcData = this.formGetValues.func().sic_atrc_data;
        }

        return (

            <div >

                <Dialog open={this.state.open} onClose={this.handleRequestClose}>
                    <DialogTitle>Request Vehical Info</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <LinearProgress />
                            * please whait while we request Vehicle info.
                            <br /><br />

                            To request vehicle info from the registry database please enter 'Targa o Matricola' and click Request info. Request can take up to a minute.
            </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Targa o Matricola"

                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleRequestClose} >
                        Annulla
            </Button>
                        <Button onClick={this.handleRequestClose} color="primary">
                            Request Info
            </Button>
                    </DialogActions>
                </Dialog>

                <MaterialForm
                    dataSource={this.props.request}
                    objType="ins_request_veicoli"
                    onSubmit={this.props.onSubmit}
                    fields={this.props.fields}
                    getValues={this.formGetValues}
                    setValues={this.formSetValues}
                >

                    <c.group
                        l={getTxt('Dati Veicolo')}
                        fields={this.props.fields}
                        child={[
                            'vehicle_targa_matricola',
                            'vehicle_type',
                            'vehicle_brand_and_model',
                            'vehicle_cilinders',
                            'vehicle_cv_ql',
                            'vehicle_kw',
                            'vehicle_immatricolazione',
                            'vehicle_value',
                            'vehicle_approval',
                            'vehicle_engine_type',
                            'sic_atrc_data',
                        ]}
                    />
                    <c.field l={getTxt('Targa o Matricola')} f="vehicle_targa_matricola" ui="viecle_id" sicDataChanged={this.sicDataChanged} />
                    <c.field l={getTxt('Tipologia Veivolo')} f="vehicle_type" ui="select" >
                        {getDictionary(DictionaryTypes.VehicleType)}
                    </c.field>
                    <c.field l={getTxt('Marca e Modello')} f="vehicle_brand_and_model" />
                    <c.field l={getTxt('Cilindrata')} f="vehicle_cilinders" />
                    <c.field l={getTxt('CV/QL')} f="vehicle_cv_ql" />
                    <c.field l={getTxt('Kw')} f="vehicle_kw" />
                    <c.field l={getTxt('Immatricolazione')} f="vehicle_immatricolazione" />

                    <c.field f="vehicle_value" />
                    <c.field l={getTxt('Omologazione')} f="vehicle_approval" />
                    <c.field l={getTxt('Alimentazione')} f="vehicle_engine_type" ui="select" >
                        {getDictionary(DictionaryTypes.VehicleEngineType)}
                    </c.field>
                    <c.field f="sic_atrc_data" ui="atrc-data-link" sm={12} />

                    <c.group
                        l={getTxt('Uso')}
                        fields={this.props.fields}
                        child={[
                            'vehicle_use',
                            'vehicle_used_by',
                            'vehicle_expiry_limit',
                            'vehicle_towable_weight',
                            'vehicle_antitheft_type',
                            'vehicle_alarm_satellite_alarm_brand_model',
                        ]}
                    />

                    <c.field l={getTxt('Uso')} f="vehicle_use" ui="select" >
                        {getDictionary(DictionaryTypes.VehicleUseType)}
                    </c.field>
                    <c.field l={getTxt('Veicolo a favore di')} f="vehicle_used_by" />
                    <c.field l={getTxt('scadenza Vincolo')} f="vehicle_expiry_limit" />
                    <c.field f="vehicle_towable_weight" />
                    <c.field l={getTxt('Tipo Antifurto')} f="vehicle_antitheft_type" />
                    <c.field f="vehicle_alarm_satellite_alarm_brand_model" />

                    <c.group
                        l={getTxt('Situazione Assicurariva')}
                        fields={this.props.fields}
                        child={[
                            'prev_company',
                            'prev_company_division',
                            'prev_cv_prov',
                            'prev_cu_ass',
                            'prev_contract_expiry',
                        ]}
                    />
                    <c.field l={getTxt('Compagnia Precedente')} f="prev_company" />
                    <c.field l={getTxt('Divisione')} f="prev_company_division" />
                    <c.field l={getTxt('CU Prov')} f="prev_cv_prov" />
                    <c.field l={getTxt('CU Ass')} f="prev_cu_ass" />
                    <c.field l={getTxt('Scadenza Polizza')} f="prev_contract_expiry" />

                    <c.group
                        l={getTxt('Caratteristiche di Richesta')}
                        fields={this.props.fields}
                        child={[
                            'ins_maximum',
                            'ins_km_annul',
                            'ins_period',
                            'ins_guide_type',
                            'ins_convention_description',
                            'ins_franchise',
                            'ins_contract_date',
                        ]}
                    />
                    <c.field l={getTxt('Massimali')} f="ins_maximum" />
                    <c.field l={getTxt('Km Annul')} f="ins_km_annul" />
                    <c.field l={getTxt('Frazionamento')} f="ins_period" ui="select">
                        {getDictionary(DictionaryTypes.InsPeriod)}
                    </c.field>
                    <c.field l={getTxt('Tipo di Guida')} f="ins_guide_type" ui="select" >
                        {getDictionary(DictionaryTypes.InsuranceinsGuideType)}
                    </c.field>
                    <c.field l={getTxt('Descrizione Convenzione')} f="ins_convention_description" ui="select" >
                        {getDictionary(DictionaryTypes.ConventionDescription)}
                    </c.field>
                    <c.field l={getTxt('Franchigia')} f="ins_franchise" />
                    <c.field l={getTxt('Decorrenza Contratto')} f="ins_contract_date" />





                    <c.group
                        l={getTxt('Informazioni Aggiuntive')}
                        fields={this.props.fields}
                        child={[
                            'additional_information',
                            'comments',
                        ]}
                    />

                    <c.field f="additional_information" multiline sm={12} />
                    <c.field f="comments" multiline sm={12} />

                    <RequestFormButtons
                        history={this.props.history}
                        transferToContract={this.props.transferToContract}
                        isNew={this.props.isNew}
                        noSave={this.props.oldContractId}
                    />

                </MaterialForm>




            </div>




        );
    }
}

export default withStyles(styles)(Viecoli_Edit_Form);
