
//import FormField from 'components/form/FormField';
import GridEx from 'components/form/GridEx';
import { getEntityDescriptor } from 'global/entityDescriptors';
import { FormControl } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { toDecimalString } from 'global/appGlobal';
import { toItalianDateString } from 'global/appGlobal';
import FormFieldHigh from '../../components/form/FormFieldHigh';
import { getValue } from '../../global/dictionaryCache';
import { getTxt } from '../../global/appGlobal';

const styles = theme => ({
    label: {
        ...theme.typography.body1,
        marginRight: theme.spacing.unit * 2,
        color: theme.palette.text.secondary,
        whiteSpace: 'nowrap',
    },
    value: {
        ...theme.typography.body1,
        color: theme.palette.text.primary,

    },
    formControl: {
        margin: theme.spacing.unit / 4,
        flexDirection: 'row',
        whiteSpace: 'normal',

    },
    root: {
        marginRight: theme.spacing.unit * 8,
    },
});



class ReadOnlyInTableGroup extends FormFieldHigh {

    getLabel(field, customLabel) {
        const objDescriptor = getEntityDescriptor(this.context.objType);
        const propDescriptor = objDescriptor[field];

        let label;
        if (propDescriptor) {
            label = propDescriptor.label;
        }
        if (this.context.fields && this.context.fields.byId[this.props.f]) {
            const customField = this.context.fields.byId[this.props.f];
            if (customField && customField.l) {
                return customField.l;
            }
        }

        if (customLabel) {
            label = customLabel;
        }

        if (this.props.pdf) {
            const latestGroup = window.requestDetailsPdfData[window.requestDetailsPdfData.length - 1];
            latestGroup.fields.push({ label });
        }

        return label;
    }

    getValue(f, v, directValue) {
        if (directValue) {
            return v;
        }
        let value = '';
        if (f && this.context.dataSource) {
            value = this.context.dataSource[f];
        }
        if (v) {
            value = v;
        }

        const objDescriptor = getEntityDescriptor(this.context.objType);
        const propDescriptor = objDescriptor[f];
        const propType = propDescriptor ? propDescriptor.type : this.props.t;

        if (this.context.fields && this.context.fields.all.length > 0) {
            if (!this.context.fields.byId[f]) {
                return '';
            }
        }

        if (propType === 'DATE' && value) {
            return value;
        }

        if (propType === 'BOOLEAN') {
            value = value === 'true' ? getTxt('Yes') : getTxt('No');
        } else {
            value = this.convertToControl(value, propType, true);
        }

        if (propType === 'DATEONLY' && value) {
            value = moment(value).format('DD-MM-YYYY');
        }
        if (propType === 'DECIMAL' && value) {
            value = toDecimalString(value, true);
        }

        if (this.props.pdf) {
            const latestGroup = window.requestDetailsPdfData[window.requestDetailsPdfData.length - 1];
            const latestFields = latestGroup.fields[latestGroup.fields.length - 1];
            latestFields.value = value || '';
        }

        return value;
    }

    render() {
        const { classes } = this.props;


        return (




            <table border={0} cellPadding={0} cellSpacing={0} className={this.props.m ? classes.root : ''}>

                {
                    this.props.children.map((child) => {

                        const objDescriptor = getEntityDescriptor(this.context.objType);
                        const propDescriptor = objDescriptor[child.f];
                        let currency;
                        if (propDescriptor && propDescriptor.currency) {
                            currency = true;
                        } else {
                            currency = child.currency;
                        }

                        if (child.render) {
                            return (
                                <tr>
                                    <td colSpan={2}>{child.render}</td>
                                </tr>
                            );
                        }
                        return (
                            <tr>
                                <td>
                                    <span className={classes.label}>
                                        {this.getLabel(child.f, child.l)}
                                    </span>
                                </td>
                                <td style={currency ? { textAlign: 'right' } : {}}>
                                    <span className={classes.value}>
                                        {this.getValue(child.f, child.v, child.directValue)}
                                    </span>
                                </td>
                            </tr>
                        );
                    }, this)
                }

            </table>

        );
    }
}

ReadOnlyInTableGroup.contextTypes = {
    onChange: PropTypes.func,
    registerControl: PropTypes.func,
    objType: PropTypes.string,
    dataSource: PropTypes.object,
    unRegisterControl: PropTypes.func,
    fields: PropTypes.Object,
};

export default withStyles(styles, { withTheme: true })(ReadOnlyInTableGroup);