import React from 'react';
import { graphql, compose } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import $ from 'jquery';
import { Link } from 'react-router-dom';

import {
    SortingState, FilteringState, IntegratedFiltering, IntegratedSorting,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    VirtualTable, TableHeaderRow, TableFilterRow, TableColumnResizing,
} from '@devexpress/dx-react-grid-material-ui';
import File_Search from 'modules/file/File_Search';
import ComponentEx from 'components/ComponentEx';
import { Button } from '@material-ui/core';
import c from 'components/form/Components';
import { getTxt, toItalianDateString, toDecimalString, filterRowMessages, tableMessages, createGridResizerCols } from '../../global/appGlobal';
import LinkToCustomerById from '../../components/LinksToObjectById';
import LinearProgressEx from '../../components/LinearProgressEx';
import { getLabel, getDictionary, DictionaryTypes } from '../../global/dictionaryCache';
import { allRequestsQuery } from '../../modules/request/requestQeries';
import { Menu, MenuItem } from '@material-ui/core';
import CurrencyGridFormatter from 'components/CurrencyGridFormatter';
import { IconButton } from '@material-ui/core';

import { Divider } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import RegularCard from '../../components/dashboard/RegularCard';
import * as queries from '../../modules/broker/brokerQueries';
import Broker_Payment_Edit from '../../modules/broker/Broker_Payment_Edit';
import { Tooltip, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import * as Icon from '@material-ui/icons';
import { deleteBrokerPercentMutation } from '../company/brokerPercentQueries';
import Broker_Free_Item_Edit from './Broker_Free_Item_Edit';

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        margin: theme.spacing.unit * 3,
    },
    button: {
        marginRight: theme.spacing.unit,
    },

    tableHeader: {
        backgroundColor: theme.palette.background.paper,
    },
    menuItem: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& $text, & $icon': {
                color: theme.palette.common.white,
            },
        },
    },
    text: {},
    icon: {},
    totalsRow: {
        backgroundColor: theme.palette.grey['200'],
        fontWeight: 'bold',
    },
    formButtons: {
        float: 'right',
        margin: '15px',
    },

});

const toLowerCase = value => String(value).toLowerCase();

const filterByBroker = (cellValue, e, row) => {
    const name = toLowerCase(row.broker.display_name);
    return name.includes(toLowerCase(e.value));
};

const filterByPaymentDate = (cellValue, e, row) => {
    const dateStr = row.payment_date ? toItalianDateString(row.payment_date) : row.payment_date;
    return dateStr && dateStr.includes(e.value);
};



const tableColumnExtensions = [
    { columnName: 'payment_amount', align: 'right' },
];

class Broker_Free_Items_Search extends ComponentEx {

    state = {
        anchorEl: null,
        open: false,
        editId: undefined,
        delDlgOpen: false,
        delId: undefined,
    };

    constructor() {
        super();
        this.handleDelete = this.handleDelete.bind(this);
        this.getRowId = row => row.broker_free_item_id;
    }

    componentDidMount() {
        $("table[class*='Table-headTable']").addClass(this.props.classes.tableHeader);
    }

    handleDelete(id) {

        const data = this.props.data;
        this.props.deleteBrokeкFreeItem({
            variables: { id: this.state.delId },
            refetchQueries: [
                {
                    query: queries.allBrokerFreeItemsQuery,
                    variables: { brokerId: this.props.brokerId },
                },
            ],
        }).then(
            (resp) => {

                this.props.data.refetch();
            }
        );
        this.setState({ delDlgOpen: false });
    }

    render() {

        if (this.props.quiet) {
            return (<LinearProgressEx loading={this.props.data.loading} />);
        }

        const columns = [

            { name: 'broker_id', title: getTxt('Broker') },

            { name: 'payment_date', title: getTxt('Data') },
            { name: 'payment_amount', title: getTxt('Importo') },
            { name: 'provvigioni', title: getTxt('Provvigioni') },
            { name: 'payment_notes', title: getTxt('Note') },
            { name: 'files', title: getTxt('Documents') },
            { name: 'broker_free_item_id', title: getTxt('Actions') },
        ];

        createGridResizerCols(this.state, columns, {}, 180);

        const { classes } = this.props;

        let items = this.props.data.allBrokerFreeItems ? this.props.data.allBrokerFreeItems : [];
        const loading = this.props.data.loading;

        const caption = getTxt('Movimenti Liberi');
        const subtitle = getTxt('List of broker Movimenti Liberi.');

        const withSubtotal = [];
        if (items.length > 0) {
            let prevName;
            let total = 0;
            let totalProvvigioni = 0;
            items.forEach((i) => {
                if (prevName && i.broker.display_name !== prevName) {
                    withSubtotal.push({
                        broker_free_item_id: -1,
                        broker_id: -1,
                        payment_date: '',
                        payment_amount: total,
                        payment_notes: '',
                        provvigioni: totalProvvigioni,
                        broker: {
                            display_name: '',
                        },
                    });
                    total = 0;
                    totalProvvigioni = 0;
                }
                prevName = i.broker.display_name;
                const payment = Number.parseFloat(i.payment_amount);
                const provvigioni = Number.parseFloat(i.provvigioni);
                if (!isNaN(payment)) {
                    total += payment;
                    totalProvvigioni += provvigioni;
                }
                withSubtotal.push(i);
            });

            withSubtotal.push({
                broker_free_item_id: -1,
                broker_id: -1,
                payment_date: '',
                payment_amount: total,
                provvigioni: totalProvvigioni,
                payment_notes: '',
                broker: {
                    display_name: '',
                },
            });

            items = withSubtotal;
        }
        const this1 = this;

        return (

            <div className={classes.root}>
                <LinearProgressEx loading={loading} />

                {!this.props.noCaption && <RegularCard

                    headerColor="red"
                    cardTitle={caption}
                    cardSubtitle={subtitle}
                    content={
                        <div>
                            {<Button
                                color="primary"
                                className={classes.button}
                                onClick={() => { this.setState({ editId: 'new' }); }}
                            >{getTxt('AGGIUNGI NUOVO MOVIMENTO LIBERO')}</Button>}
                        </div>

                    }
                />}

                {this.props.showAlterAddBtn &&
                    <Button
                        color="primary"
                        className={classes.button}
                        onClick={() => { this.setState({ editId: 'new' }); }}
                    >{getTxt('AGGIUNGI NUOVO MOVIMENTO LIBERO')}</Button>}

                <Grid

                    rows={items}
                    columns={columns}

                >

                    <CurrencyGridFormatter
                        for={['payment_amount']}
                    />

                    <FilteringState
                        defaultFilters={[{}]}
                    />

                    <SortingState
                        defaultSorting={[

                        ]}
                    />

                    <IntegratedFiltering
                        columnExtensions={[
                            { columnName: 'broker_id', predicate: filterByBroker },
                            { columnName: 'payment_date', predicate: filterByPaymentDate },
                        ]}
                    />
                    <SortingState
                        defaultSorting={[]}
                    />
                    <IntegratedSorting
                        columnExtensions={[
                        ]}
                    />

                    <VirtualTable
                        messages={tableMessages}
                        
                        rowComponent={({ row, ...restProps }) => {
                            if (row.broker_id === -1) {
                                return (<VirtualTable.Row
                                    className={classes.totalsRow}
                                    {...restProps}
                                />);
                            }
                            return (<VirtualTable.Row {...restProps} />);
                        }}
                        cellComponent={({ row, column, style, ...props }) => {

                            if (column.name === 'broker_id') {
                                return (<VirtualTable.Cell><Link to={`/brokers/${row.broker_id}`} >{row.broker.display_name}</Link></VirtualTable.Cell>);
                            }
                           

                            if (column.name === 'payment_date') {
                                return (<VirtualTable.Cell>
                                    {row.payment_date ? toItalianDateString(row.payment_date) : row.payment_date}
                                </VirtualTable.Cell>);
                            }

                            if (column.name === 'broker_free_item_id' && row.broker_free_item_id !== -1) {
                                return (<VirtualTable.Cell>


                                    <Tooltip title={getTxt('Edit')}>
                                        <IconButton size="small" onClick={() => { this.setState({ editId: row.broker_free_item_id }); }} className={classes.inGridButton} >
                                            <Icon.Edit />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title={getTxt('Delete')}>

                                        <IconButton size="small" onClick={() => { this.setState({ delDlgOpen: true, delId: row.broker_free_item_id }); }} className={classes.inGridButton} >
                                            <Icon.Delete />
                                        </IconButton>
                                    </Tooltip>

                                </VirtualTable.Cell>);
                            }
                            if (column.name === 'broker_free_item_id' && row.broker_id === -1) {
                                return (<VirtualTable.Cell>{''}</VirtualTable.Cell>);
                            }
                            if (column.name === 'payment_amount') {
                                return (<VirtualTable.Cell>{toDecimalString(row.payment_amount)}</VirtualTable.Cell>);
                            }
                            if (column.name === 'provvigioni') {
                                return (<VirtualTable.Cell>{toDecimalString(row.provvigioni)}</VirtualTable.Cell>);
                            }
                            if (column.name === 'files') {
                                if (row.broker_free_item_id === -1) {
                                    return (<VirtualTable.Cell>{''}</VirtualTable.Cell>);
                                }
                                if (!row.files || (row.files && row.files.length === 0)) {
                                    return (<VirtualTable.Cell>
                                        <a
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                this1.setState({ onlyFilesEditId: row.broker_free_item_id });
                                            }} >{getTxt('no documents attached')}</a> </VirtualTable.Cell>);
                                }
                                if (row.files && row.files.length > 0) {
                                    return (<VirtualTable.Cell>
                                        <a
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                this1.setState({ onlyFilesEditId: row.broker_free_item_id });
                                            }} >{row.files.length + ' ' + getTxt('document(s) attached')}</a> </VirtualTable.Cell>);
                                }

                            }

                            return <VirtualTable.Cell {...props} />;
                        }}
                    />

                    <TableFilterRow
                        messages={filterRowMessages}
                        cellComponent={({ column, filter, onFilter, ...props }) => {
                            if (column.name === 'broker_free_item_id') {
                                return (
                                    <TableFilterRow.Cell filteringEnabled={true} >
                                        <span />
                                    </TableFilterRow.Cell>);
                            }
                            if (column.name === 'files') {
                                return (
                                    <TableFilterRow.Cell filteringEnabled={true} >
                                        <span />
                                    </TableFilterRow.Cell>);
                            }
                            return <TableFilterRow.Cell onFilter={onFilter} filter={filter} {...props} />;
                        }}
                    />

                    <TableColumnResizing
                        columnWidths={this.state.columnWidths}
                        onColumnWidthsChange={(cols) => { this.setState({ columnWidths: cols }); }}
                        defaultColumnWidths={this.state.defColumnWidths}
                    />

                    <TableHeaderRow />

                </Grid>
                {
                    this.state.editId &&
                    <Broker_Free_Item_Edit
                        id={this.state.editId}
                        realType={this.props.realType}
                        brokerId={this.props.brokerId}
                        AfterSubmit={((id) => {
                            this.setState({ editId: undefined });
                            if (id) {
                                this.setState({ id, filesDlgOpen: true });
                            }

                            this.props.data.refetch();
                        })}

                    />
                }
                {
                    this.state.id && this.state.filesDlgOpen &&
                    <Dialog onClose={() => { this.setState({ filesDlgOpen: false }); }} open={this.state.filesDlgOpen}>
                        <DialogTitle>{getTxt('Documenti Movimento Libero')}</DialogTitle>
                        <DialogContent>
                            <File_Search entityId={this.state.id} afterSubmit={this.props.data.refetch} />
                        </DialogContent>
                        <DialogActions>
                            <div className={classes.formButtons} >
                                <Button onClick={() => {
                                    this.setState({ filesDlgOpen: false, editId: this.state.id });
                                }}
                                    className={classes.button}
                                    variant="contained">
                                    {getTxt('Back')}
                                </Button>
                                <Button onClick={() => this.setState({ filesDlgOpen: false })} className={classes.button} variant="contained" color="primary">
                                    {getTxt('Save')}
                                </Button>
                            </div>
                        </DialogActions>
                    </Dialog>
                }
                {
                    this.state.onlyFilesEditId &&
                    <Dialog onClose={() => { this.setState({ onlyFilesEditId: false }); }} open={this.state.onlyFilesEditId}>
                        <DialogTitle>{getTxt('Documenti Movimento Libero')}</DialogTitle>
                        <DialogContent>
                            <File_Search entityId={this.state.onlyFilesEditId} afterSubmit={this.props.data.refetch} />
                        </DialogContent>
                        <DialogActions>
                            <div className={classes.formButtons} >
                                <Button onClick={() => {
                                    this.setState({ onlyFilesEditId: false });
                                }}
                                    className={classes.button}
                                    variant="contained">
                                    {getTxt('Cancel')}
                                </Button>
                                <Button onClick={() => this.setState({ onlyFilesEditId: false })} className={classes.button} variant="contained" color="primary">
                                    {getTxt('Save')}
                                </Button>
                            </div>
                        </DialogActions>
                    </Dialog>
                }




                <Dialog onClose={() => { this.setState({ delDlgOpen: false, delId: null }); }} open={this.state.delDlgOpen}>
                   
                    <DialogContent>
                        <DialogContentText>
                            <span> {getTxt('Il movimento libero sarà eliminato dal server.')} </span>
                            <br />
                            <span > {getTxt('Sei sicuro di voler eliminare il movimento libero?')} </span>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleDelete(this.state.delId)} color="primary">
                            {getTxt('Ok')}
                        </Button>
                        <Button onClick={() => { this.setState({ delDlgOpen: false, delId: null }); }} color="primary">
                            {getTxt('Annula')}
                        </Button>

                    </DialogActions>
                </Dialog>

            </div >
        );
    }


}

export default compose(

    graphql(queries.allBrokerFreeItemsQuery, {
        options: {
            options: props => (
                {
                    variables:
                    {
                        brokerId: props.brokerId,
                    },
                    fetchPolicy: 'network-only',
                    errorPolicy: 'all',
                }),

        },
    }),
    graphql(queries.deleteBrokerFreeItemMutation, {
        name: 'deleteBrokeкFreeItem',
        options: {
            errorPolicy: 'all',
        },
    }),

)(withStyles(styles)(Broker_Free_Items_Search));
