import gql from 'graphql-tag';

export const allPaymentTypesQuery = gql`
query allPaymentTypesQuery {
    allPaymentTypes {
        public_payment_type_id,
        value,
        label,
        real_payment_type_value, 
    }
}`;

export const publicPaymentTypeByIdQuery = gql`

query publicPaymentTypeByIdQuery($id: ID!) {
    publicPaymentTypeById(id: $id) {
        public_payment_type_id,
        value,
        label,
        real_payment_type_value, 
    }
   
}
  

`;

export const updatePublicPaymntTypeMutation = gql`

mutation updatePublicPaymntTypeMutation($type: public_payment_type_input!) {
    updatePublicPaymntType(type: $type) {
      id
      validation {
        field
        message
      }
    }
  }

  `;


export const deletePublicPaymentTypeMutation = gql`

mutation deletePublicPaymentTypeMutation ($id: ID!) {
    deletePublicPaymentType(id: $id){
        id
        validation {
            field
            message
        }
    } 
}

`;
