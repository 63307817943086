import gql from 'graphql-tag';

export const allContractItems_In_InsTypeQuery = gql`

query allContractItems_In_InsTypeQuery($insTypeCd: String) {
    allContractItems_In_InsTypeQuery (insTypeCd: $insTypeCd) {  
        allTypes{
            contract_item_type_id
            value
            label
            is_financial
            is_negative
        }
    insType
    selectedItems{
        insurance_type_cd
        item_type_cd
    }
    
  }
}`;

export const updateContractItems_In_InsTypeMutation = gql`

mutation updateContractItems_In_InsTypeMutation($insTypeCd: String, $selectedTypes: [String!]!) {
    updateContractItems_In_InsTypeMutation(insTypeCd: $insTypeCd, selectedTypes: $selectedTypes ) {
      id
      validation {
        field
        message
      }
    }
  }

  `;

