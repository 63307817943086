
//import FormField from 'components/form/FormField';
import GridEx from 'components/form/GridEx';
import { Checkbox } from '@material-ui/core';
import { FormControlLabel, FormGroup } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import MFormControl from '../../components/form/MFormControl';

const styles = theme => ({
    checkBox: {
        marginLeft: theme.spacing.unit,
    },
});

class MFormCheck extends MFormControl {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        //this.onKeyDown = this.onKeyDown.bind(this);
    }

    onChange(e) {
        this.setState({ value: e.target.checked });
        this.setState({ isDirty: true });
        this.formOnChange(this.props.f, e.target.checked, this.props.type);
    }

    // onKeyDown(e){
    //     var f = 0;
    // }

    render() {
        const { classes } = this.props;

        let val = this.state.value;
        if (this.props.stringValue) {
            if (this.state.value === '1' ||
                this.state.value === 'si' ||
                this.state.value === 'true' ||
                this.state.value === true) {
                val = true;
            } else {
                val = false;
            }
        }

        return (

            <GridEx item sm={this.props.sm || 6} xs={this.props.xs || 12}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                className={classes.checkBox}
                                onChange={this.onChange}
                                {...this.props}
                                disabled={this.props.d}
                                required={this.props.r}
                                checked={val}
                                name={this.props.f}
                                InputProps={{ id: this.id, name: this.props.f, readOnly: this.props.readOnly }}
                                error={this.state.errorMessage ? true : false}
                                helperText={this.state.errorMessage}
                            />
                        }
                        label={this.getLabel()}
                    />
                </FormGroup>
            </GridEx>

        );
    }
}

MFormCheck.contextTypes = {
    onChange: PropTypes.func,
    registerControl: PropTypes.func,
    objType: PropTypes.string,
    dataSource: PropTypes.object,
    unRegisterControl: PropTypes.func,
    fields: PropTypes.Object,
};

export default withStyles(styles, { withTheme: true })(MFormCheck);