import React, { Component } from 'react';
import { getTxt, toDecimalString, toItalianDateString } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';
import { graphql, compose } from 'react-apollo';
import { Typography } from '@material-ui/core';
import gql from 'graphql-tag';
import { p } from 'components/form/Proto';
import $ from 'jquery';
import { HashRouter, BrowserRouter, Route, Link, withRouter } from 'react-router-dom';
import { Button } from '@material-ui/core';
import * as Icon from '@material-ui/icons';
import { authInfo, getToken } from 'global/auth';
import { ReportTypes } from 'global/reporting';
import ChartCard from 'components/dashboard/ChartCard';
import ChartistGraph from 'react-chartist';
var Chartist = require("chartist");
import {
    SortingState, SelectionState, FilteringState, GroupingState,
    IntegratedFiltering, IntegratedGrouping, IntegratedSorting, IntegratedSelection,
} from '@devexpress/dx-react-grid';
import classNames from 'classnames';
import {
    Grid,
    Table,
    VirtualTable, TableHeaderRow, TableFilterRow, TableSelection, TableGroupRow,
    GroupingPanel, DragDropProvider, TableColumnReordering, Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import LinearProgressEx from '../../components/LinearProgressEx';
import * as queries from '../../modules/contract/contractQueries';
import { getLabel, DictionaryTypes, getDictionary } from '../../global/dictionaryCache';
import RegularCard from '../../components/dashboard/RegularCard';
import MaterialForm from '../../components/form/MaterialForm';
import GridEx from 'components/form/GridEx';
import c from '../../components/form/Components';
import { } from '../../global/dictionaryCache';
import { addDescriptor } from '../../global/entityDescriptors';
import ComponentEx from '../../components/ComponentEx';
import { getReportDataQuery } from './reportQueries';
import {
    dailySalesChart,
    emailsSubscriptionChart,
    completedTasksChart
} from 'components/dashboard/variables/charts';
import { getYearandMonthLabel } from './ReportsRoot';

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        //  margin: theme.spacing.unit * 2,
    },
    button: {
        margin: theme.spacing.unit,
    },
    formButtons: {
        float: 'right',
        margin: '15px',
        whiteSpace: 'normal',
    },

    badge: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        marginTop: 0,

    },
    gridRoot: {
        height: '100%',
    },

    tableHeader: {
        backgroundColor: theme.palette.background.paper,
    },
    leftIcon: {
        marginRight: theme.spacing.unit,
    },
    iconSmall: {
        fontSize: 20,
    },
    filterForemRoot: {
        paddingLeft: theme.spacing.unit * 2,
        paddingRight: theme.spacing.unit * 2,
    },
    totalsRow: {
        backgroundColor: theme.palette.grey['200'],
        fontWeight: 'bold',
    },
});


class Percentages_Matured_Agency_Last_12_Report extends ComponentEx {

    constructor(props) {
        super(props);
        this.generateReport = this.generateReport.bind(this);
        this.formChanged = this.formChanged.bind(this);
        this.appplyFilters = this.appplyFilters.bind(this);
    }

    state = {
        from: null,
        to: null,
        companyId: null,
    };

    formSetValues = { func: undefined };

    generateReport() {
        const newWindow = window.open("", "_blank");
        newWindow.document.write(getTxt('Generating PDF Report, please wait...'));
        this.props.getOneTimeToken({

        }, this).then(
            (resp) => {

                if (resp.data && resp.data.oneTimeTokenMutation && resp.data.oneTimeTokenMutation.token) {

                    newWindow.location.href = `${window.apiUrl}files/reports?ott=${resp.data.oneTimeTokenMutation.token}&type=${ReportTypes.ContractItemsReport}&params=${JSON.stringify(reportsFilters)}`;
                    newWindow.focus();
                }

            });
    }

    formChanged(field, value, type, prevVal) {
        reportsFilters[field] = value;
        const stateVal = {};
        this.state[field] = value;
    }

    appplyFilters() {
        this.props.data.refetch({
            reportType: 'claims',
            params: JSON.stringify({
                from: this.state.from,
                to: this.state.to,
                companyId: this.state.companyId,
            }),
        });
    }

    render() {

        const { classes } = this.props;
        const items = this.props.data.reportData ? JSON.parse(this.props.data.reportData) : [];

        const chartOptions = {

            lineSmooth: Chartist.Interpolation.cardinal({
                tension: 0
            }),
            low: 0,
            high: 110,
            chartPadding: {
                top: 0,
                right: 10,
                bottom: 5,
                left: 10,
            }

        };

        const chartData = {
            labels: [],
            series: [[]],
        };

        let max = 0;
        items.forEach((i) => {
            chartData.labels.push(getYearandMonthLabel(i.year_number, i.month_label));
            const percent = Number.parseFloat(i.AverageAgencyPercent);
            chartData.series[0].push(percent);
            if (percent > max) {
                max = percent;
            }
        });

        chartOptions.high = max + 3;

        return (

            <div className={classes.root}>
                <LinearProgressEx loading={this.props.data.loading} />
                {!this.props.noCaption && <RegularCard

                    headerColor="green"
                    cardTitle={getTxt('New customers in last 12 months')}
                    cardSubtitle={''}
                    content={
                        <div>{''}</div>
                    }
                />}



                <GridEx item sm={12} xs={12}>
                    <div style={{ overflow: 'auto' }}>
                        <div style={{ minWidth: 570 }}>
                            <ChartCard
                                chart={
                                    <ChartistGraph
                                        className="ct-chart"
                                        data={chartData}
                                        type="Line"
                                        options={chartOptions}
                                        listener={dailySalesChart.animation}
                                    />}
                                chartColor="green"
                                text={getTxt('Average Agency Percent per month')}
                                statIcon={() => ''}
                                statText=""
                            />
                        </div>
                    </div>
                </GridEx>



            </div>
        );
    }
}

export default compose(
    graphql(getReportDataQuery, {
        options: props => (
            {
                variables: { reportType: 'percentages-matured-agency-last-12-months', params: JSON.stringify({}) },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }
        ),
    }),

)(withRouter(withStyles(styles)(Percentages_Matured_Agency_Last_12_Report)));
