import React, { Component } from 'react';
import { getTxt, randomString } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';
import { withTheme } from '@material-ui/core';

import { Typography } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';
import File_Search from 'modules/file/File_Search';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';

import { Paper } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { AppBar } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { TextField } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import gql from 'graphql-tag';
import { graphql, compose } from 'react-apollo';
import { Tabs, Tab } from '@material-ui/core';
import * as Icon from '@material-ui/icons';
import GridEx from 'components/form/GridEx';

import { Button } from '@material-ui/core';
import moment from 'moment';
import { Badge } from '@material-ui/core';
import { authInfo } from 'global/auth';

import ComponentEx from 'components/ComponentEx';

import { queryGetCustomerById, customerBriefQuery, allCustomersQuery } from '../../../modules/client/clientQueries';
import c from '../../../components/form/Components';
import LinearProgressEx from '../../../components/LinearProgressEx';
import { getLabel, DictionaryTypes, getDictionary, getValue } from '../../../global/dictionaryCache';
import MaterialForm from '../../../components/form/MaterialForm';
import MFormText from '../../../components/form/MFormText';
import * as queries from '../../../modules/contract/claim/claimQueries';


const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing.unit * 2,
        margin: theme.spacing.unit * 2,
    },

    button: {
        margin: theme.spacing.unit,
    },

    badge: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        marginTop: 0,
    },

    gridRoot: {
        height: '100%',
    },


    formCaption: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit * 2,
        width: '100%',
        position: 'relative',
        height: 40,
    },
    formCaptionLabel: {
        position: 'absolute',
        backgroundColor: 'transparent',
        left: 25,
        paddingLeft: 10,
        paddingRight: 10,
    },

    rootPaper: theme.mixins.gutters({

        paddingTop: 0,
        paddingBottom: 0,
        marginTop: 0,// theme.spacing.unit * 3,

    }),

});

let history;
let isNew = false;
class Claim_Details_Edit extends ComponentEx {

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.fornChanged = this.fornChanged.bind(this);
        this.afterFilesAdded = this.afterFilesAdded.bind(this);
    }

    state = {
        claimId: undefined,
        addFilesDlg: false,
    };

    onSubmit(e, { obj, showErrors, hideErrors }) {

        history = this.props.history;
        obj.insurance_contract_id = this.props.match.params.contractId;
        obj.claim_id = this.props.data.claim.claim_id;

        this.props.updateClaim({
            variables: { claim: obj },

            refetchQueries: [
                {
                    query: queries.allClaimsQuery,
                },
                {
                    query: queries.claimByIdQuery,
                    variables: {
                        id: obj.claim_id,
                        contractId: obj.insurance_contract_id,
                    },
                },
            ],
        }, this).then(
            (resp) => {
                hideErrors();
                const serverErrors = resp.data.updateClaimMutation.validation;

                if (serverErrors && serverErrors.length > 0) {
                    showErrors(serverErrors);
                } else {

                    if (isNew) {
                        this.setState({ claimId: resp.data.updateClaimMutation.id, addFilesDlg: true });
                    } else {
                        history.goBack();
                    }

                }
            });
    }

    afterFilesAdded(requestId) {
        history.goBack();
    }

    fornChanged(field, value, type, prevVal) {

    }

    render() {

        const { classes } = this.props;
        const loading = this.props.data.loading;
        const item = this.props.data.claim;

        let caption;

        if (this.props.match.params.id === 'new') {
            caption = getTxt('New Claim');
            isNew = true;
        } else {
            caption = getTxt('Edit Claim');
            isNew = true;
        }

        return (

            <div className={classes.root}>
                <Typography variant="h4" color="inherit" noWrap={false}>{getTxt(caption)}</Typography>
                <LinearProgressEx loading={loading} />
                {item &&
                    <div>
                        <MaterialForm dataSource={item} objType="claim" onSubmit={this.onSubmit} onChange={this.fornChanged}>
                            <c.group l={getTxt('')} />
                            <c.field f="number" />
                            <c.field f="incident_date_time" />
                            <c.field f="description" sm={12} multiline />
                            <c.field f="responsibility_type" ui="select" >
                                {getDictionary(DictionaryTypes.ResponsibilityType, item.responsibility_type)}
                            </c.field>
                            <c.field f="status" ui="select" >
                                {getDictionary(DictionaryTypes.ClaimStatus, item.status)}
                            </c.field>
                            <c.field f="registration_date" l="Data Ricezione" />
                            <c.field f="claim_evidence_text" />
                            <c.field f="counterpart_description" />
                            <c.field f="counterpart_plate_number" />
                           
                            <c.field f="liquidation_date" />
                            <c.field f="amount_paid" />
                            <c.field f="reserved_amount" />
                            <c.field f="professiona_claim_evaluation_text" />
                            <c.field f="riscontro_cliente" ui="select" >
                                {getDictionary(DictionaryTypes.RiscontroCliente, item.riscontro_cliente)}
                            </c.field>
                            <c.field f="tipologia_di_danno" ui="select" >
                                {getDictionary(DictionaryTypes.TipologiaDiDanno, item.tipologia_di_danno)}
                            </c.field>
                            <c.field f="sinistro_multiplo" />

                            { !authInfo.isCustomer() && <c.field f="event_description" multiline sm={12} />}

                            <c.formButtons sm={12} onCancel={() => { this.props.history.goBack(); }} />
                        </MaterialForm>

                    </div>
                }


                <Dialog
                    className={classes.dialog}
                    open={this.state.addFilesDlg}
                    onClose={() => {
                        const requestId = this.state.requestId;
                        this.setState({ addFilesDlg: false, claimId: undefined });
                        this.afterFilesAdded(requestId);
                    }}
                >
                    <DialogTitle> <span className={classes.dialogText}>{getTxt('Request Documents')} </span></DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <File_Search
                                entityId={this.state.claimId}
                                history={this.props.history}
                                afterSubmit={this.afterFilesAdded}
                            />
                        </DialogContentText>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            const claimId = this.state.claimId;
                            this.setState({ addFilesDlg: false, claimId: undefined });
                            this.afterFilesAdded(claimId);
                        }}
                            color="primary" >
                            {getTxt('Ok')}
                        </Button>
                        <Button onClick={() => {
                            const claimId = this.state.claimId;
                            this.setState({ addFilesDlg: false, claimId: undefined });
                            this.afterFilesAdded(claimId);
                        }} color="primary">
                            {getTxt('Annula')}
                        </Button>

                    </DialogActions>
                </Dialog>

            </div>

        );
    }
}


export default compose(
    graphql(queries.claimByIdQuery, {
        options: props => ({
            variables:
            {
                id: props.match.params.claimId,
                contractId: props.match.params.contractId,
            },
            fetchPolicy: 'network-only',
            options: {
                errorPolicy: 'all',
            },
        }),
    }),

    graphql(queries.updateClaimMutation, {
        name: 'updateClaim',
        options: {
            errorPolicy: 'all',
        },
    }),

)(withStyles(styles)(Claim_Details_Edit));
