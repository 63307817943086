
import React, { Component } from 'react';
import { getTxt } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import GridEx from 'components/form/GridEx';
import { MenuItem } from '@material-ui/core';
import $ from 'jquery';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { CircularProgress } from '@material-ui/core';

import MFormControl from '../../components/form/MFormControl';

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: '100%',
    },
    menu: {
        width: 200,
    },
    selectProgress: {
        position: 'absolute',
        margin: `${theme.spacing.unit * 3.5}px ${theme.spacing.unit * 2}px`,
    },
    forFocusHidden: {
        width: '0px',
        height: '0px',
        border: 0,
        color: 'transparent',
        backgroundColor: 'transparent',
        position: 'absolute',
    },
});

class MFormCitySelect extends MFormControl {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange(e) {
        this.setState({ value: e.target.value });
        this.setState({ isDirty: true });
        this.formOnChange(this.props.f, e.target.value, this.props.type);
    }

    isDirty() {
        return true;
    }

    focus() {
        $(`#${this.id}for_focus`).focus();
    }

    getValue() { return $(`#${this.id}`).val(); }

    render() {
        const { classes } = this.props;

        const loading = this.props.data.loading;
        const cities = this.props.data.citiesByProvince ? this.props.data.citiesByProvince : [];
        let selected = this.state.value !== '' ? this.state.value : this.context.dataSource[this.props.f];

        if (!loading) {
            const inList = cities.find(c => c.value === selected);
            if (inList) {
                selected = inList.value;
            } else {
                selected = '';
            }
        }

        return (
            <GridEx item sm={this.props.sm || 6} xs={this.props.xs || 12}>
                <div style={{ position: 'relative' }}>
                    <input type="text" id={`${this.id}for_focus`} tabIndex="-1" className={classes.forFocusHidden} />
                    {loading && <CircularProgress className={classes.selectProgress} size={30} />}
                    <TextField
                        select
                        label={this.getLabel()}
                        className={classes.textField}
                        SelectProps={{
                            MenuProps: {
                                className: classes.menu,
                            },
                        }}
                        margin="normal"
                        {...this.props}
                        InputProps={{ id: this.id, name: this.props.f }}
                        value={selected}
                        disabled={this.props.d}
                        required={this.props.r}
                        onChange={this.onChange}
                        error={this.state.errorMessage ? true : false}
                        helperText={this.state.errorMessage}
                    >
                        {cities.map(mi => (
                            <MenuItem key={mi.value} value={mi.value}>
                                {mi.label}
                            </MenuItem>
                        ))}
                    </TextField>

                </div>

            </GridEx >
        );
    }
}

MFormCitySelect.contextTypes = {
    onChange: PropTypes.func,
    registerControl: PropTypes.func,
    objType: PropTypes.string,
    dataSource: PropTypes.object,
    unRegisterControl: PropTypes.func,
    fields: PropTypes.Object,
};

const queryCitiesByProvince = gql`

query citiesByProvinceQuery($provinceCd: String) {
    citiesByProvince(provinceCd: $provinceCd) {
      label
      value
    }
   
}

`;

export default graphql(queryCitiesByProvince,
    {
        options: props => (
            {
                variables: { provinceCd: props.provinceId },
                errorPolicy: 'all',
            }),
    })(withStyles(styles, true)(MFormCitySelect));
