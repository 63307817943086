import React, { Component } from 'react';
import { getTxt } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';
import { withTheme } from '@material-ui/core';

import { Typography } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';



import { Paper } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { AppBar } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { TextField } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import gql from 'graphql-tag';
import { graphql, compose } from 'react-apollo';
import { Tabs, Tab } from '@material-ui/core';
import * as Icon from '@material-ui/icons';
import GridEx from 'components/form/GridEx';
import { Button } from '@material-ui/core';
import moment from 'moment';
import { Badge } from '@material-ui/core';

import ComponentEx from 'components/ComponentEx';


import { getCompanyQuery } from '../../modules/company/CompanyQueries';
import c from '../../components/form/Components';
import LinearProgressEx from '../../components/LinearProgressEx';
import { getLabel, DictionaryTypes, getDictionary } from '../../global/dictionaryCache';
import MaterialForm from '../../components/form/MaterialForm';
import MFormText from '../../components/form/MFormText';

import Referent_Search from '../../modules/referent/Referent_Search';


const styles = theme => ({
    button: {
        margin: theme.spacing.unit,
    },

    badge: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        marginTop: 0,
    },

    gridRoot: {
        height: '100%',
    },


    formCaption: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit * 2,
        width: '100%',
        position: 'relative',
        height: 40,
    },
    formCaptionLabel: {
        position: 'absolute',
        //color: theme.palette.primary1,
        backgroundColor: 'transparent',
        left: 25,
        paddingLeft: 10,
        paddingRight: 10,
    },

    rootPaper: theme.mixins.gutters({

        paddingTop: 0,
        paddingBottom: 0,
        marginTop: 0,// theme.spacing.unit * 3,

    }),

});



class Company_Details_View extends ComponentEx {
    state = {
        value: 0,
    };

    handleChange = (event, value) => {
        this.setState({ value });
    };

    render() {

        if (this.props.quiet) {
            return (<LinearProgressEx loading={this.props.data.loading} />);
        }

        const { classes } = this.props;
        const loading = this.props.data.loading;
        const company = this.props.data.company;



        return (

            <div>
                <LinearProgressEx loading={loading} />
                {!loading &&
                    <MaterialForm dataSource={company} objType="company" spacing={2} noFocusOnFirstCtrl>

                        <GridEx item sm={4} xs={12}>
                            <MaterialForm spacing={12} dataSource={company} objType="customer" noFocusOnFirstCtrl >
                                <c.group l={getTxt('Dati Compagnia')} d />
                                <c.readOnlyInLine l={getTxt('Codice Interno')} f="insurance_company_id" d />
                                <c.readOnlyInLine l={getTxt('Ragione Sociale Compagnia')} f="company_name" d />
                            </MaterialForm>
                            <MaterialForm spacing={12} dataSource={company} objType="customer" noFocusOnFirstCtrl >
                                <c.group l={getTxt('Address')} d />

                                <c.readOnlyInLine l={getTxt('Nazione')} f="address_country_cd" d />
                                {company.address_country_cd === 'IT' &&
                                    <c.readOnlyInLine l={getTxt('Province')} f="address_province" v={getLabel(DictionaryTypes.ItalianProvinces, company.address_province)} d />
                                }
                                <c.readOnlyInLine l={getTxt('Città')} f="address_city" d />
                                <c.readOnlyInLine l={getTxt('CAP')} f="address_cap" d />
                                <c.readOnlyInLine l={getTxt('Indirizzo')} f="address_street" d />
                                <c.readOnlyInLine l={getTxt('Civico')} f="address_street_number" d />
                            </MaterialForm>
                        </GridEx>
                        
                        <GridEx item sm={4} xs={12}>
                            <MaterialForm spacing={12} dataSource={company} objType="customer" noFocusOnFirstCtrl >

                                <c.group l={getTxt('Dati per la Fatturazione')} d />

                                <c.readOnlyInLine l={getTxt('Ragione Sociale di Fatturazione')} f="company_billing_name" d />
                                <c.readOnlyInLine l={getTxt('Nazione')} f="invoice_address_country_cd" d />
                                {company.invoice_address_country_cd === 'IT' &&
                                    <c.readOnlyInLine l={getTxt('Province')} f="invoice_address_province" v={getLabel(DictionaryTypes.ItalianProvinces, company.invoice_address_province)} d />
                                }
                                <c.readOnlyInLine l={getTxt('Città')} f="invoice_address_city" d />
                                <c.readOnlyInLine l={getTxt('CAP')} f="invoice_address_cap" d />
                                <c.readOnlyInLine l={getTxt('Indirizzo')} f="invoice_address_street" d />
                                <c.readOnlyInLine l={getTxt('Civico')} f="invoice_address_street_number" d />
                                <c.readOnlyInLine l={getTxt('Partita iva')} f="vat_number" d />
                                <c.readOnlyInLine l={getTxt('Email')} f="email_address" />
                                <c.readOnlyInLine l={getTxt('PEC')} f="certified_email_address" />
                                <c.readOnlyInLine l={getTxt('Telefono')} f="phone_number" />
                                <c.readOnlyInLine l={getTxt('Codice SDI')} f="sdi_code" />
                            </MaterialForm>
                        </GridEx>


                       

                        <GridEx item sm={4} xs={12}>
                            <MaterialForm spacing={12} dataSource={company} objType="customer" noFocusOnFirstCtrl >
                                <c.group l={getTxt('Informazioni Contatti')} d />
                                <c.readOnlyInLine l={getTxt('Telefono')} f="phone" d />
                                <c.readOnlyInLine l={getTxt('Cellulare')} f="mobile_phone" d />
                                <c.readOnlyInLine l={getTxt('Email')} f="email" d />
                                <c.readOnlyInLine l={getTxt('PEC')} f="email_pec" d />
                                <c.readOnlyInLine l={getTxt('Fax')} f="fax" d />
                            </MaterialForm>
                        </GridEx>




                        <GridEx item sm={4} xs={12}>
                            <MaterialForm spacing={12} dataSource={company} objType="customer" noFocusOnFirstCtrl >

                                <c.group l={getTxt('Informazioni Aggiuntive')} d />


                                {/* <c.readOnlyInLine l="Codice Fiscale" f="fiscal_code" d /> */}
                                <c.readOnlyInLine l={getTxt('IBAN')} f="bank_iban" d />
                                <c.readOnlyInLine l={getTxt('IBAN Secondario')} f="bank_iban_2" />

                                {company &&
                                    <c.readOnlyInLine l={getTxt('Tipo di Rapporto')} f="report_type_cd" v={getLabel(DictionaryTypes.ReportType, company.report_type_cd)} />
                                }
                                <c.readOnlyInLine l={getTxt('Codice Agenzia')} f="agency_code" />
                                <c.readOnlyInLine l={getTxt('Codice Sub')} f="sub_code" />
                                {company &&
                                    <c.readOnlyInLine l={getTxt('Tipologia Rimessa')} f="remittance_type_cd" v={getLabel(DictionaryTypes.RemittanceType, company.remittance_type_cd)} />
                                }
                                <c.readOnlyInLine l={getTxt("Ritenuta d'acconto")} f="withholding_tax" />

                                <c.readOnlyInLine l={getTxt('Costo Autentica')} f="authentic_cost" />
                                <c.readOnlyInLine l={getTxt('Costo spedizione')} f="shipping_cost" />
                                <c.readOnlyInLine l={getTxt('Username SIC Ania')} f="username_SIC_ania" />
                                <c.readOnlyInLine l={getTxt('Password SIC Ania')} f="password_SIC_ania" />
                                <c.readOnlyInLine l={getTxt('Codice Ente SIC Ania')} f="code_SIC_ania" />
                                <c.readOnlyInLine l={getTxt('Username Company App')} f="username_company_app" />
                                <c.readOnlyInLine l={getTxt('Password Company App')} f="password_company_app" />










                            </MaterialForm>
                        </GridEx>

                        <GridEx item sm={12} xs={12}>
                            <MaterialForm spacing={2} dataSource={company} objType="company" noFocusOnFirstCtrl >
                                {company && <Referent_Search parentId={company.insurance_company_id} history={this.props.history} />}
                            </MaterialForm>
                        </GridEx>






                    </MaterialForm>
                }
            </div>

        );
    }
}

export default graphql(getCompanyQuery,
    {
        options: props => ({
            variables: { id: props.companyId },
            fetchPolicy: 'network-only',
            options: {
                errorPolicy: 'all',
            },
        }),
    })(withStyles(styles, true)(Company_Details_View));

