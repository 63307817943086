import gql from 'graphql-tag';

export const customerBriefQuery = gql`

query customerBriefQuery($id: ID!) {
    customer(id: $id) {
        customer_id
        mobile_phone
        email
        owner_broker_id
        title_cd
        requests_count
        address_city,
      address_province,
      address_country_cd,
      address_province,
      address_city,
      address_street,
      address_street_number,
      address_cap,
      fiscal_code,
      is_deleted,
      display_name,
             broker{
                broker_id
                display_name
                email
        }
        referent{
            referent_id, 
            first_name, 
            last_name, 
            display_name,
            mobile_phone, 
            email
        }
    }
}

`;


export const queryGetCustomerById = gql`

query CustomerQuery($id: ID!) {
    customer(id: $id) {
        customer_id
        customer_type_cd 
        state_cd
        address_country_cd
        address_province
        address_city
        address_street 
        address_street_number
        address_cap
        phone
        phone_active
        mobile_phone
        fax
        email
        email_pec
        bank_iban
        owner_broker_id
        first_name
        last_name
        title_cd
        sex_cd
        birth_date
        birth_country_cd
        birth_province
        birth_city
        id_card
        id_card_type_cd
        id_card_expiration_date
        id_card_release
        business_occupation
        considered_percent
        company_reg_number
        company_reg_type_cd
        company_registration_date
        company_revenue_eur
        company_employee_number
        vat_descr
        fiscal_code
        dependent_family_members
        living_property_type
        type_of_pet
        company_name
        vat_number
        communication_agree
        communication_unsubscribed
        internal_notes
        legal_address_country_cd
        legal_address_province
        legal_address_city
        legal_address_street
        legal_address_street_number
        legal_address_cap    
        legal_person
        rui_number
        referent_first_name
        referent_last_name
        referent_email
        referent_phone
        display_name
        id_release_date
        trusted_amount
        password
        password2
        password_new
        password_new2
        is_deleted
        broker{
                broker_id
                display_name
        }
        referent{
            referent_id, 
            first_name, 
            last_name, 
            display_name,
            mobile_phone, 
            email
        }
    }
}

`;

export const allCustomersQuery = gql`

query AllCustomersQuery($filter: String) {
    allCustomers(filter: $filter) {
      customer_id
      customer_type_cd
      mobile_phone, 
      phone,
      referent_phone,
      display_name,
      address_province, 
      address_city,
      owner_broker_id,
      vat_number,
      email,
      is_deleted,
      address_street,
      address_street_number,
      address_cap,
      fiscal_code,
      broker{
            broker_id
            display_name
        }
    }
  }
  


`;


export const allCustomersQuery2 = gql`

query AllCustomersQuery2( $take: Int, $filters: [GridFilters], $id: ID! ) {
    allCustomers2(take: $take, filters: $filters, id: $id ) {
      customer_id
      customer_type_cd
      mobile_phone, 
      phone,
      referent_phone,
      display_name,
      address_province, 
      address_city,
      owner_broker_id,
      vat_number,
      email,
      is_deleted,
      address_street,
      address_street_number,
      address_cap,
      fiscal_code,
      broker{
            broker_id
            display_name
        }
    }
  }
  


`;

export const deleteCustomerMutation = gql`

mutation deleteCustomerMutation ($id: ID!) {
    deleteCustomerMutation(id: $id){
        id
        validation {
            field
            message
        }
    } 
}

`;

export const customersAsDictionary = gql`

query customersAsDictionaryQuery($id: ID) {
    customersAsDictionary(id: $id) {
        customer_id
        display_name
        customer_type_cd
    }
  }

`;


export const allCustomerPaymentsQuery = gql`
query allCustomerPaymentsQuery($customerId: ID) {
    allCustomerPayments(customerId: $customerId ){
        customer_payment_id
        customer_id
        payment_type_cd
        payment_state_cd
        payment_date
        payment_amount
        payment_notes
        payment_account
        customer{
            display_name
            customer_type_cd
        }
        files{
            document_id
        }
    }
}`;


export const customerBalanceQuery = gql`
query customerBalanceQuery($i: ID!, $customerId: Int, $from: String, $to: String) {
    customerBalance(i: $i, customerId: $customerId, from: $from, to: $to )
}`;


export const customerPaymentByIdQuery = gql`

query customerPaymentByIdQuery($id: ID!) {
    customerPayment(id: $id) {
        customer_payment_id
        customer_id
        payment_type_cd
        payment_state_cd
        payment_date
        payment_amount
        payment_notes
        payment_account
        customer{
            display_name
            customer_type_cd
        }
    }
   
}`;

export const updateCustomerPaymentMutation = gql`

mutation updateCustomerPaymentMutation($customerPayment: customer_payment_input!) {
    updateCustomerPaymentMutation(customerPayment: $customerPayment) {
      id
      validation {
        field
        message
      }
    }
  }`;

export const deleteCustomerPaymentMutation = gql`

mutation deleteCustomerPaymentMutation($id: ID) {
    deleteCustomerPaymentMutation(id: $id) {
      id
      validation {
        field
        message
      }
    }
  }`;



export const customerContractReport = gql`
query customerContractReport($params: String) {
    customerContractReport(params: $params ){
        contract_number
        insurance_type_cd
        company {
            insurance_company_id, 
            company_name
        }
        contract_start_date
        insurance_contract_id
        insurance_company_id
        premio
    }
}`;

