import React, { Component } from 'react';
import { getTxt, randomString } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';
import { withTheme } from '@material-ui/core';

import { Typography } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';



import { Paper } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { AppBar } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { TextField } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import gql from 'graphql-tag';
import { graphql, compose } from 'react-apollo';
import { Tabs, Tab } from '@material-ui/core';
import * as Icon from '@material-ui/icons';
import GridEx from 'components/form/GridEx';




import { Button } from '@material-ui/core';
import moment from 'moment';
import { Badge } from '@material-ui/core';
import { authInfo } from 'global/auth';

import ComponentEx from 'components/ComponentEx';


import c from '../../components/form/Components';
import LinearProgressEx from '../../components/LinearProgressEx';
import { getLabel, DictionaryTypes, getDictionary, getValue } from '../../global/dictionaryCache';
import MaterialForm from '../../components/form/MaterialForm';
import MFormText from '../../components/form/MFormText';
import * as queries from '../../modules/broker/brokerQueries';
import { getUserRoles, Roles } from '../../global/auth';

import { Dialog } from '@material-ui/core';
import { DialogTitle } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { DialogContentText } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import { getClientHeight, getClientWidth } from '../../global/appGlobal';


const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing.unit * 2,
        margin: theme.spacing.unit * 2,
    },
});


class ui_dev_info extends ComponentEx {

    state = {
    };

    render() {

        const { classes } = this.props;
    
        return (

            <div className={classes.root}>
                <b>UI version: </b><span>1.0.5</span>
                <br />
                <b>Display size:  </b><span>{getClientWidth()} X {getClientHeight()}</span>
            </div>

        );
    }
}


export default withStyles(styles)(ui_dev_info);
