import React, { Component } from 'react';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import $ from 'jquery';


import {
    SortingState, SelectionState, FilteringState, GroupingState,
    IntegratedFiltering, IntegratedGrouping, IntegratedSorting, IntegratedSelection,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    VirtualTable, Table, TableHeaderRow, TableFilterRow, TableSelection, TableGroupRow,
    GroupingPanel, DragDropProvider, TableColumnReordering, Toolbar,
} from '@devexpress/dx-react-grid-material-ui';


import { HashRouter, BrowserRouter, Route, Link, withRouter } from 'react-router-dom';

import ComponentEx from 'components/ComponentEx';

import { TableCell } from '@material-ui/core';
import { Button } from '@material-ui/core';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import { authInfo } from 'global/auth';
//import * as Icon from '@material-ui/icons';

import c from 'components/form/Components';
import { getTxt, tableMessages, toDecimalString, toItalianDateString } from '../../global/appGlobal';
import LinkToCustomerById from '../../components/LinksToObjectById';
import LinearProgressEx from '../../components/LinearProgressEx';
import { getLabel, getDictionary, DictionaryTypes } from '../../global/dictionaryCache';
import { allQuotesQuery } from '../../modules/quote/quoteQeries';
import { Menu, MenuItem } from '@material-ui/core';


const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        margin: theme.spacing.unit * 3,
    },
    button: {
        margin: theme.spacing.unit,
    },

    tableHeader: {
        backgroundColor: theme.palette.background.paper,
    }
});

class Quote_Search extends ComponentEx {

    state = {

    };

    constructor(props) {
        super(props);



        this.getRowId = row => row.customer_id;
        this.newClick = this.newClick.bind(this);
    }

    componentWillMount() {
        if (!this.props.data.loading) {
            this.props.data.refetch();
        }

    }

    componentDidMount() {
        $("table[class*='Table-headTable']").addClass(this.props.classes.tableHeader);
    }

    newClick() {
        this.props.history.push(`/quotes/edit/${this.props.requestId}/new`);
    }

    render() {
        const { classes } = this.props;

        const allQuotes = this.props.data.allQuotes ? this.props.data.allQuotes : [];

        const columns = [
            { name: 'insurance_quote_id', title: getTxt('id', '#5i87rga') },
            { name: 'insurance_company_id', title: getTxt('Compagnia', '#4i6xps0') },
            { name: 'estimated_amount', title: getTxt('Importo del Preventivo', '#8ewf7cp') },
            { name: 'agency_fee_amount', title: getTxt('Competenze', '#4359l7d') },
            { name: 'total_amount', title: getTxt('Totale', '#cfoy3g7') },
            { name: 'is_confirmed', title: getTxt('Confermato', '#kq3acgg') },
            { name: 'date_confirmed', title: getTxt('Data Confermata', '#5avlky4') },


        ];

        const rootMargin = classes.root.margin;

        return (

            <div className={classes.root}>
                <LinearProgressEx loading={this.props.data.loading} />
                {(!this.props.noAddBtn && !authInfo.isCustomer()) &&
                    <Button
                        color="primary"
                        className={classes.button}
                        onClick={this.newClick}
                    >
                        {getTxt('Aggiungi nuova Quotation')}
                    </Button>
                }


                <Grid
                    rows={allQuotes}
                    columns={columns}
                >
                    <SortingState
                        defaultSorting={[
                        ]}
                    />

                    <IntegratedSorting />

                    <Table
                        messages={tableMessages}
                        cellComponent={({ row, column, style, ...props }) => {
                            if (column.name === 'insurance_quote_id') {
                                return (<Table.Cell> <Link to={`/quotes/edit/${this.props.requestId}/${row.insurance_quote_id}`} >{row.insurance_quote_id}</Link> </Table.Cell>);
                            }
                            if (column.name === 'insurance_company_id') {
                                return (<Table.Cell><Link to={`/company/view/${row.company.insurance_company_id}`} >{row.company.company_name}</Link></Table.Cell>);
                            }
                            if (column.name === 'estimated_amount') {
                                return (<Table.Cell>
                                    {toDecimalString(row.estimated_amount, true)}
                                </Table.Cell>);
                            }
                            if (column.name === 'estimated_amount') {
                                return (<Table.Cell>
                                    {toDecimalString(row.estimated_amount, true)}
                                </Table.Cell>);
                            }
                            if (column.name === 'agency_fee_amount') {
                                return (<Table.Cell>
                                    {toDecimalString(row.agency_fee_amount, true)}
                                </Table.Cell>);
                            }
                            if (column.name === 'total_amount') {
                                return (<Table.Cell>
                                    {toDecimalString(row.total_amount, true)}
                                </Table.Cell>);
                            }
                            if (column.name === 'is_confirmed') {
                                return (<Table.Cell>
                                    {row.is_confirmed === 'true' ? getTxt('yes') : getTxt('no') }
                                </Table.Cell>);
                            }
                            if (column.name === 'date_confirmed') {
                                return (<Table.Cell>
                                    {toItalianDateString(row.date_confirmed)}
                                </Table.Cell>);
                            }

                            return <Table.Cell {...props} />;
                        }}
                    />

                    <TableHeaderRow showSortingControls />

                </Grid>
            </div >
        );
    }
}

export default graphql(allQuotesQuery, {
    options: {
        options: props => (
            {
                variables: { requestId: props.match.params.requestId ? props.match.params.requestId : props.requestId },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }),
    },
})(withStyles(styles, true)(Quote_Search));
