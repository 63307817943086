
//import FormField from 'components/form/FormField';
import GridEx from 'components/form/GridEx';
import { getEntityDescriptor } from 'global/entityDescriptors';
import { FormControl } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { compose, graphql } from 'react-apollo';
import FormFieldHigh from '../../components/form/FormFieldHigh';
import { customersAsDictionary } from '../../modules/client/clientQueries';





const styles = theme => ({
    label: {
        ...theme.typography.body1,
        marginRight: theme.spacing.unit,
        color: theme.palette.text.secondary,
        whiteSpace: 'nowrap',
    },
    value: {
        ...theme.typography.body1,
        color: theme.palette.text.primary,
        //fontWeight: 'bold',
    },
    formControl: {
        margin: theme.spacing.unit / 4,
        flexDirection: 'row',
        whiteSpace: 'normal',
    },
});

class ReadOnlyMultilinkToCustomer extends FormFieldHigh {



    render() {
        const { classes } = this.props;

        //const loading = this.props.data.loading;
        const customers = this.props.data.customersAsDictionary ? this.props.data.customersAsDictionary : [];
        let customersIds;
        if (this.props.f && this.context.dataSource) {
            customersIds = this.context.dataSource[this.props.f];
        }
        if (this.props.v) {
            customersIds = this.props.v;
        }
        if (customersIds && customersIds.length) {
            customersIds = JSON.parse(customersIds);
        }


        if (this.context.fields && this.context.fields.all.length > 0) {
            if (!this.context.fields.byId[this.props.f]) {
                return '';
            }
        }

        let text = '';
        let selectedCustomers = [];
        if ( customers && customersIds && customers.length > 0 && customersIds.length > 0) {
            customersIds.forEach((id, index) => {
                const customer = customers.find(c => c.customer_id === id);
                if (customer) {
                    let link;
                    if (customer.customer_type_cd === 'PRIVATE_PROSPECT') {
                        link = `/customers/prospect/private/${customer.customer_id}`;
                    } else {
                        link = `/customers/${customer.customer_type_cd.toLowerCase()}/${customer.customer_id}`;
                    }
                    selectedCustomers.push({
                        link,
                        display_name: customer.display_name,
                    });
                    text += customer.display_name;
                    if (customersIds.length > 1 && index < customersIds.length) {
                        text += ',____ ';
                    }
                }

            });

        }

        const label = this.getLabel();
        if (this.props.pdf) {
            const latestGroup = window.requestDetailsPdfData[window.requestDetailsPdfData.length - 1];
            const latestFields = latestGroup.fields[latestGroup.fields.length - 1];

            let customers = '';
            selectedCustomers.map((selected, index) => {
                customers += (selected ? selected.display_name : '') + '\n';
            });

            latestFields.value = customers || '';
        }

        return (

            <GridEx item sm={this.props.sm || 12} xs={this.props.xs || 12}>
                <FormControl className={classes.formControl}>
                    <span className={classes.label} >
                        {label}
                    </span>
                    <span className={classes.value}>

                        {selectedCustomers.map((selected, index) => {
                            return (

                                //  <GridEx item sm={this.props.sm || 6} xs={this.props.xs || 12}>
                                <span>
                                    <span className={classes.value}>
                                        {selected ? selected.display_name : ''}
                                    </span>
                                    {selectedCustomers.length > 1 && index < selectedCustomers.length && <span className={classes.delim} >,</span>}
                                </span>
                                // </GridEx>
                            );
                        })}


                    </span>
                </FormControl>
            </GridEx>

        );
    }
}

ReadOnlyMultilinkToCustomer.contextTypes = {
    onChange: PropTypes.func,
    registerControl: PropTypes.func,
    objType: PropTypes.string,
    dataSource: PropTypes.object,
    unRegisterControl: PropTypes.func,
    fields: PropTypes.Object,
};

export default compose(
    graphql(customersAsDictionary, {
        options: props => ({}),
    }),
)(withStyles(styles)(ReadOnlyMultilinkToCustomer));