import gql from "graphql-tag";

// eslint-disable-next-line import/prefer-default-export
export const sharedSearchQuery = gql`

query sharedSearchQuery ($take: Int, $filters: [GridFilters], $id: ID! )  {
    sharedSearch (take: $take, filters: $filters, id: $id ) {
            insurance_contract_id
            customer_id
            contract_number
            insurance_type_cd
            contract_notes
            contract_delivery_state
            contract_start_date
            customer {
                customer_id
                display_name
                customer_type_cd
            }
            
            ins_request_veicoli{
                vehicle_targa_matricola
            }
    }
}
`;
