
import React, { Component } from 'react';
import { getTxt } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import GridEx from 'components/form/GridEx';
import { MenuItem } from '@material-ui/core';
import $ from 'jquery';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { CircularProgress } from '@material-ui/core';
import MFormAutoSelect from '../../components/form/MFormAutoSelect';
import MFormControl from '../../components/form/MFormControl';


const styles = theme => ({
    root: {
        marginLeft: ('-' + theme.spacing.unit),
    },

});

class MFormCityAutoSelect extends MFormControl {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    componentWillMount() {
        this.formOnChange = this.context.registerControl(this.props.f, {
            isDirty: this.isDirty,
            getValue: this.getValue,
            setValue: this.setValue,
            showErrors: this.showErrors,
            hideErrors: this.hideErrors,
            focus: this.focus,
            hasErrors: this.hasErrors,
            type: this.props.propType,
            id: this.id,
        });
    }

    onChange(e) {
        this.setState({ value: e.target.value });
        this.setState({ isDirty: true });
        this.formOnChange(this.props.f, e.target.value, this.props.type);
    }

    isDirty() {
        return true;
    }

    focus() {
        $(`#${this.id}for_focus`).focus();
    }

    getValue() { return $(`#${this.id}`).val(); }

    setValue(val) {
        this.setState({ value: val });
        this.formOnChange(this.props.f, val, this.props.type);
    }

    render() {
        const { classes } = this.props;

        const loading = this.props.data.loading;
        const cities = this.props.data.citiesByProvince ? this.props.data.citiesByProvince : [];
        let selected = this.state.value !== '' ? this.state.value : this.context.dataSource[this.props.f];

        if (!loading) {
            const inList = cities.find(c => c.value === selected);
            if (inList) {
                selected = inList.value;
            } else {
                selected = '';
            }
        }

        return (

            <MFormAutoSelect className={classes.root} {...this.props} v={selected} setValueParent={this.setValue} >{cities}</MFormAutoSelect>

        );
    }
}

MFormCityAutoSelect.contextTypes = {
    onChange: PropTypes.func,
    registerControl: PropTypes.func,
    objType: PropTypes.string,
    dataSource: PropTypes.object,
    unRegisterControl: PropTypes.func,
    fields: PropTypes.Object,
};

const queryCitiesByProvince = gql`

query citiesByProvinceQuery($provinceCd: String) {
    citiesByProvince(provinceCd: $provinceCd) {
      label
      value
    }
   
}

`;

export default graphql(queryCitiesByProvince,
    {
        options: props => (
            {
                variables: { provinceCd: props.provinceId },
                errorPolicy: 'all',
            }),
    })(withStyles(styles, true)(MFormCityAutoSelect));
