import React, { Component } from 'react';
import { getTxt, toItalianDateString } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';
import { graphql, compose } from 'react-apollo';
import { p } from 'components/form/Proto';
import $ from 'jquery';
import { Checkbox } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import c from '../../components/form/Components';
import { Button } from '@material-ui/core';
import GridEx from 'components/form/GridEx';
import DeleteIcon from '@material-ui/icons/Delete';
import { HashRouter, BrowserRouter, Route, Link, withRouter } from 'react-router-dom';
import { TextField } from '@material-ui/core';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import * as Icon from '@material-ui/icons';
import {
    SortingState, SelectionState, FilteringState, GroupingState,
    IntegratedFiltering, IntegratedGrouping, IntegratedSorting, IntegratedSelection,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    VirtualTable, TableHeaderRow, TableFilterRow, TableSelection, TableGroupRow,
    GroupingPanel, DragDropProvider, TableColumnReordering, Toolbar, TableColumnResizing,
} from '@devexpress/dx-react-grid-material-ui';
import LinearProgressEx from '../../components/LinearProgressEx';
import * as queries from '../../modules/contract/contractQueries';
import { getLabel, DictionaryTypes, getDictionary } from '../../global/dictionaryCache';
import RegularCard from '../../components/dashboard/RegularCard';
import ComponentEx from '../../components/ComponentEx';
import { filterRowMessages, tableMessages, toDecimalString, createGridResizerCols } from '../../global/appGlobal';
import { Tooltip } from '@material-ui/core';
import { authInfo } from 'global/auth';
import { addDescriptor } from '../../global/entityDescriptors';
import MaterialForm from '../../components/form/MaterialForm';
import { oneTimeTokenMutation } from '../broker/Broker_Balance_Search';
import { ReportTypes } from '../../global/reporting';
import { polizzeAnnullateReportQuery } from './reportQueries';


function scroll(a, b, c) {
    console.log('scrol')
    const x = 0;
    const d = x + 3;
}

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        margin: theme.spacing.unit * 3,
    },
    button: {
        margin: theme.spacing.unit,
    },

    badge: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        marginTop: 0,

    },
    gridRoot: {
        height: '100%',
    },

    tableHeader: {
        backgroundColor: theme.palette.background.paper,
    },

    filterForemRoot: {
        paddingLeft: theme.spacing.unit * 2,
        paddingRight: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
    },
});

let reports_filter_obj_descr;

const OBG_DESCR_KEY = 'broker_balance_filter';

let reportsFilters = {
    start_date_from: null,
    start_date_to: null,
};

class PolizzeAnnullateReport extends ComponentEx {

    constructor(props) {
        super(props);


        this.formChanged = this.formChanged.bind(this);
        this.appplyFilters = this.appplyFilters.bind(this);
        reports_filter_obj_descr = {
            from: {
                id: 'from',
                label: getTxt('Data di scadenza da'),
                type: 'DATEONLY',
                validators: [],
            },
            to: {
                id: 'to',
                label: getTxt('Data di scadenza a'),
                type: 'DATEONLY',
                validators: [],
            },
            itemType: {
                id: 'itemType',
                label: getTxt('Tipo di Movimento'),
                type: 'STRING',
                validators: [],
            },

        };

        reportsFilters = {
            from: null,
            to: null,
            itemType: null,
        };

        addDescriptor(OBG_DESCR_KEY, reports_filter_obj_descr);
    }

    state = {
        delId: undefined,
        delNumber: undefined,
        itemTypes: undefined,
    };

    formSetValues = { func: undefined };

    formChanged(field, value, type, prevVal) {
        reportsFilters[field] = value;
        const stateVal = {};
        this.state[field] = value;
        this.props.data.refetch({
            expFrom: this.state.from,
            expTo: this.state.to,
            itemType: this.state.itemType,
        });
    }


    appplyFilters() {
        this.props.data.refetch({
            expFrom: this.state.from,
            expTo: this.state.to,
            itemType: this.state.itemType,
        });
    }

    componentDidMount() {
        document.body.onscroll = 'scroll()';
        $("table[class*='Table-headTable']").addClass(this.props.classes.tableHeader);
    }

    render() {


        let columns = [
            { name: 'contract_number', title: getTxt('Numero') },
            { name: 'insurance_type_cd', title: getTxt('Tipo') },
        ];

        if (this.props.isCustomerView) {
            columns = columns.concat([
                { name: 'state_cd', title: getTxt('Stato') },
            ]);
        }

        columns = columns.concat([
            { name: 'customer_id', title: getTxt('Cliente') },

        ]);

        //if (authInfo.isAgencyAdministrator() || authInfo.isMasterBroker()) {
        columns.push({ name: 'insurance_company_id', title: getTxt('Compagnia') });
        //}

        columns = columns.concat([
            { name: 'owner_broker_id', title: getTxt('Collaboratore') },
            { name: 'contract_start_date', title: getTxt('Inizio') },
            { name: 'contract_end_date', title: getTxt('Scadenza') },
            { name: 'amount_netto', title: getTxt('Premio') },
            { name: 'competenze', title: getTxt('Competenze') },
            { name: 'contract_delivery_state', title: getTxt('Targa') },
            { name: 'contract_notes', title: getTxt('Cellulare') },

        ]);

        if (!this.props.noDeleteBtn) {
            if (authInfo.isAgencyAdministrator() || authInfo.isMasterBroker()) {
                columns.push({ name: 'insurance_contract_id', title: getTxt('Actions') });
            }
        }

        const insType = this.props.insType ? this.props.insType : this.props.match.params.insType;
        const { classes } = this.props;
        let items = this.props.data.polizzeAnnullateReport ? this.props.data.polizzeAnnullateReport : [];

        let insTypeCd;
        if (items.length > 0) {
            insTypeCd = items[0].insurance_type_cd;
        }

        let caption = getTxt('Contracts');
        let subtitle = getTxt('List of insurance contracts.');
        if (this.props.match && this.props.match.params.filter === 'will-expiry-next-30-days') {
            subtitle = getTxt('List of insurance contracts that will expiry in the next 30 days.');
            caption = getTxt('Near to expiry Contracts');

            let today = new Date();
            today = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);

            let expiredDate = new Date(new Date().setDate(today.getDate() + 30));
            expiredDate = new Date(expiredDate.getFullYear(), expiredDate.getMonth(), expiredDate.getDate(), 0, 0, 0);

            items = items.filter((c) => {
                const endDate = new Date(c.contract_end_date);
                return (endDate > today && endDate < expiredDate);
            });
        }

        if (this.props.match && this.props.match.params.filter === 'expired-prev-30-days-not-paid') {
            subtitle = getTxt('List of insurance contracts that expired in the previous 30 days not yet paid.');
            caption = getTxt('Expired not paid Contracts');

            let today_ = new Date();
            today_ = new Date(today_.getFullYear(), today_.getMonth(), today_.getDate(), 0, 0, 0);

            let todayMinus30Days = new Date(new Date().setDate(today_.getDate() - 30));
            todayMinus30Days = new Date(todayMinus30Days.getFullYear(), todayMinus30Days.getMonth(), todayMinus30Days.getDate(), 0, 0, 0);

            items = items.filter((c) => {
                const endDate = new Date(c.contract_end_date);
                return (endDate > todayMinus30Days && endDate < today_);
            });
        }

        if (this.props.markIfLinketTo) {

            columns = [{ name: 'linked', title: getTxt('Linked') }].concat(columns);

            items.forEach((i) => {
                if (this.state[i.insurance_contract_id] === undefined) {
                    this.state[i.insurance_contract_id] = i.contract_link ? true : false;
                }
            }, this);
        }


        createGridResizerCols(this.state, columns, {}, 160);


        return (

            <div className={classes.root}>
                <LinearProgressEx loading={this.props.data.loading} />
                {!this.props.noCaption && <RegularCard

                    headerColor="red"
                    cardTitle={caption}
                    cardSubtitle={subtitle}
                    content={
                        <div>

                        </div>

                    }
                />}


                <div className={classes.filterForemRoot}>
                    <MaterialForm
                        dataSource={reportsFilters}
                        objType={OBG_DESCR_KEY}
                        onChange={this.formChanged}
                        setValues={this.formSetValues}
                    >

                        <c.field f="from" />
                        <c.field f="to" />
                        <c.field
                            l={getTxt('Type of Movementi')}
                            f="itemType"
                            ui="item_type_select"
                            startsWith={['Annullo', 'Annullamento', 'annullo', 'annullamento']}
                            sm={12}
                        />

                        <GridEx item sm={'auto'} xs={12}>

                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    reportsFilters.from = '';
                                    reportsFilters.to = '';


                                    this.formSetValues.func({
                                        from: '',
                                        to: '',
                                        itemType: '',
                                    });
                                    this.setState({
                                        from: '',
                                        to: '',
                                        itemType: '',
                                    });
                                    this.appplyFilters();
                                }}

                            >
                                {getTxt('Reset Filters')}
                            </Button>
                        </GridEx>
                        <GridEx item sm={'auto'} xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {

                                    const newWindow = window.open("", "_blank");
                                    newWindow.document.write(getTxt('Generating PDF Report, please wait...'));
                                    this.props.getOneTimeToken({

                                    }, this).then(
                                        (resp_ott) => {

                                            if (resp_ott.data && resp_ott.data.oneTimeTokenMutation && resp_ott.data.oneTimeTokenMutation.token) {

                                                newWindow.location.href = `${window.apiUrl}files/reports?ott=${resp_ott.data.oneTimeTokenMutation.token}&type=${ReportTypes.PolizzeAnnullate}&params=${JSON.stringify({ from: this.state.from, to: this.state.to, itemType: this.state.itemType })}`;
                                                newWindow.focus();
                                            }

                                        });
                                }}
                            >
                                {getTxt('Create PDF')}
                            </Button>


                        </GridEx>

                        <GridEx item sm={'auto'} xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {

                                    const newWindow = window.open("", "_blank");
                                    newWindow.document.write(getTxt('Generazione del report XLS, attendere prego...'));
                                    this.props.getOneTimeToken({

                                    }, this).then(
                                        (resp_ott) => {

                                            if (resp_ott.data && resp_ott.data.oneTimeTokenMutation && resp_ott.data.oneTimeTokenMutation.token) {

                                                newWindow.location.href = `${window.apiUrl}files/reports?ott=${resp_ott.data.oneTimeTokenMutation.token}&type=${ReportTypes.PolizzeAnnullate}&params=${JSON.stringify({ from: this.state.from, to: this.state.to, itemType: this.state.itemType, xlsx: true })}`;
                                                newWindow.focus();
                                            }

                                        });
                                }}
                            >
                                {getTxt('Genera XLS')}
                            </Button>


                        </GridEx>

                    </MaterialForm>
                </div>


                <Grid

                    rows={items}
                    columns={columns}

                >
                    <FilteringState
                        defaultFilters={[{}]}
                    />

                    <SortingState
                        columnExtensions={[
                            { columnName: 'insurance_contract_id', sortingEnabled: false },
                        ]}
                        defaultSorting={[
                            { columnName: 'Cliente', direction: 'asc' },
                        ]}
                    />

                    {/* <IntegratedFiltering columnExtensions={
                        [{ columnName: 'owner_broker_id', predicate: filterBroker },
                        { columnName: 'customer_id', predicate: filterCustomer },
                        { columnName: 'insurance_company_id', predicate: filterCompany },
                        ]}
                    /> */}
                    {/* <IntegratedSorting /> */}

                    <VirtualTable
                        id="sssssssssssssss"
                        messages={tableMessages}

                        cellComponent={({ row, column, style, ...props }) => {

                            if (column.name === 'linked') {
                                return (
                                    <VirtualTable.Cell>
                                        <Checkbox
                                            onChange={(e, value) => {
                                                const newState = {};
                                                newState[row.insurance_contract_id] = value;
                                                this.setState(newState);
                                            }}
                                            checked={this.state[row.insurance_contract_id]}
                                            key={row.item_type_cd}
                                        />
                                    </VirtualTable.Cell>);
                            }

                            if (column.name === 'contract_number') {
                                return (<VirtualTable.Cell><Link to={`/contract/view/${row.insurance_type_cd}/${row.insurance_contract_id}`} style={{ padding: '6px', color: '#9C27B0', fontWeight: 'bold' }} > {row.contract_number}</Link> </VirtualTable.Cell>);
                            }
                            if (column.name === 'customer_id') {
                                return (<VirtualTable.Cell>
                                    <Link to={`/customers/${row.customer.customer_type_cd.toLowerCase()}/${row.customer.customer_id}`} >{row.customer.display_name}</Link>
                                </VirtualTable.Cell>);
                            }
                            if (column.name === 'insurance_company_id') {
                                return (<VirtualTable.Cell>
                                    {(authInfo.isAgencyAdministrator() || authInfo.isMasterBroker()) &&
                                        <Link to={`/company/view/${row.company.insurance_company_id}`}>{row.company.company_name}</Link>}
                                    {(!authInfo.isAgencyAdministrator() && !authInfo.isMasterBroker()) &&
                                        <spn>{row.company.company_name}</spn>}
                                </VirtualTable.Cell>);
                            }

                            if (column.name === 'owner_broker_id') {
                                return (<VirtualTable.Cell>
                                    <Link to={`/brokers/${row.broker.broker_id}`}>{row.broker.display_name}</Link>
                                </VirtualTable.Cell>);
                            }
                            if (column.name === 'insurance_type_cd') {
                                return (<VirtualTable.Cell> {getLabel(DictionaryTypes.InsuranceType, row.insurance_type_cd)}</VirtualTable.Cell>);
                            }
                            if (column.name === 'ins_request_veicoli') {
                                return (<VirtualTable.Cell> {row.ins_request_veicoli.vehicle_targa_matricola}</VirtualTable.Cell>);
                            }
                            if (column.name === 'contract_start_date') {
                                return (<VirtualTable.Cell> {row.contract_start_date && toItalianDateString(row.contract_start_date)}</VirtualTable.Cell>);
                            }
                            if (column.name === 'contract_end_date') {
                                return (<VirtualTable.Cell> {row.contract_end_date && toItalianDateString(row.contract_end_date)}</VirtualTable.Cell>);
                            }
                            if (this.props.isCustomerView && column.name === 'state_cd') {
                                return (<VirtualTable.Cell> {row.state_cd ? row.state_cd : getTxt('Polizza')} </VirtualTable.Cell>);
                            }
                            if (column.name === 'insurance_contract_id') {
                                return (<VirtualTable.Cell>

                                    <Tooltip title={getTxt('Delete')}>
                                        <IconButton size="small" onClick={() => { this.setState({ delId: row.insurance_contract_id, delNumber: row.contract_number }); }} className={classes.inGridButton} >
                                            <Icon.Delete />
                                        </IconButton>
                                    </Tooltip>

                                </VirtualTable.Cell>);
                            }
                            if (column.name === 'contract_delivery_state') {
                                if (row.ins_request_veicoli) {
                                    return (<VirtualTable.Cell> {row.ins_request_veicoli.vehicle_targa_matricola}</VirtualTable.Cell>);
                                }
                                return (<VirtualTable.Cell>{''}</VirtualTable.Cell>);

                            }
                            if (column.name === 'amount_netto') {
                                return (<VirtualTable.Cell>
                                    {toDecimalString(row.amount_netto)}
                                </VirtualTable.Cell>);
                            }
                            if (column.name === 'competenze') {
                                return (<VirtualTable.Cell>
                                    {toDecimalString(row.competenze)}
                                </VirtualTable.Cell>);
                            }
                            if (column.name === 'contract_notes') {
                                return (
                                    <VirtualTable.Cell>
                                        {row.customer && row.customer.mobile_phone && row.customer.mobile_phone}
                                    </VirtualTable.Cell>
                                );
                            }



                            return <VirtualTable.Cell {...props} />;
                        }}
                    />

                    <TableColumnResizing
                        columnWidths={this.state.columnWidths}
                        onColumnWidthsChange={(cols) => { this.setState({ columnWidths: cols }); }}
                        defaultColumnWidths={this.state.defColumnWidths}
                    />
                    <TableHeaderRow showSortingControls={false} />

                </Grid>


            </div>
        );
    }
}

export default compose(

    graphql(oneTimeTokenMutation, {
        name: 'getOneTimeToken',
        options: {
            errorPolicy: 'all',

        },
    }),

    graphql(polizzeAnnullateReportQuery, {
        options: props => (
            {
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }
        ),
    }),
)(withRouter(withStyles(styles)(PolizzeAnnullateReport)));
