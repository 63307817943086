import { IntegratedSorting, SortingState } from '@devexpress/dx-react-grid';
import { Grid, TableHeaderRow, VirtualTable, Table } from '@devexpress/dx-react-grid-material-ui';
import $ from 'jquery';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import { Button } from '@material-ui/core';
import { graphql, compose } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import { HashRouter, BrowserRouter, Route, Link, withRouter } from 'react-router-dom';
import { Checkbox } from '@material-ui/core';
import React, { Component } from 'react';
import { DictionaryTypes, getDictionary, getLabel } from '../../global/dictionaryCache';
import RegularCard from '../../components/dashboard/RegularCard';
import { getTxt, tableMessages } from '../../global/appGlobal';
import * as queries from '../../modules/internalInsType/ContractItemTypeQueries';
import LinearProgressEx from '../../components/LinearProgressEx';
import ComponentEx from '../../components/ComponentEx';


const dialogColumns = [
    { name: 'selected', title: getTxt('Selected') },
    { name: 'item_type_cd', title: getTxt('Movenent Type') },
];


const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        margin: theme.spacing.unit * 3,
    },
    rootAsChildControl: {
        backgroundColor: theme.palette.background.paper,
    },
    button: {
        margin: theme.spacing.unit,
    },
    tableHeader: {
        backgroundColor: theme.palette.background.paper,
    },
});

function getLabelx(resp, item_type_cd) {
    const found = resp.allTypes.find(i => i.value === item_type_cd);
    if (found && found.label) {
        return found.label;
    }
    return '';
}

class ContractItemTypesSearch extends ComponentEx {

    constructor(props) {
        super(props);
        const allInsTypes = getDictionary(DictionaryTypes.InsuranceItemType).filter(i => i.value !== 'Polizza');
        allInsTypes.forEach((insType) => {
            this.state[insType.value] = false;
        });
    }

    state = {
        dlgOpen: false,
        insTypeCd: undefined,
        itemsLoaded: false,
    };

    componentDidMount() {
        $("table[class*='Table-headTable']").addClass(this.props.classes.tableHeader);
    }

    saveSelectedItems() {
        const selected = [];
        const allTypes = this.props.data.allContractItems_In_InsTypeQuery.allTypes;
        allTypes.forEach((itemType) => {
            if (this.state[itemType.value]) {
                selected.push(itemType.value);
            }
        }, this);

        this.props.saveItems({
            variables: { insTypeCd: this.props.insTypeCd, selectedTypes: selected },
            refetchQueries: [
                {
                    query: queries.allContractItems_In_InsTypeQuery,
                    variables: { insTypeCd: this.props.insTypeCd },
                },
            ],
        }, this).then(
            (resp) => {
            });
    }

    render() {

        const columns = [
            { name: 'item_type_cd', title: getTxt('Movenent Type') },
        ];

        const { classes } = this.props;
        const loading = this.props.data.loading;
        const resp = this.props.data.allContractItems_In_InsTypeQuery ? this.props.data.allContractItems_In_InsTypeQuery : { selectedItems: [], allItems: [] };

        if (loading) {
            this.state.itemsLoaded = false;
        }
        if (!loading && !this.state.itemsLoaded) {
            resp.selectedItems.forEach((selected) => { this.state[selected.item_type_cd] = true; });
            this.state.itemsLoaded = true;
        }


        let dialogItems = [];
        if (!loading && resp.allTypes) {
            dialogItems = resp.allTypes.map((k) => {
                return { selected: this.state[k.value], item_type_cd: k.value };
            });
        }



        return (

            <div className={this.props.noAddBtn ? classes.rootAsChildControl : classes.root}>


                <LinearProgressEx loading={loading} />
                {!this.props.noEditBtn &&
                    <Button
                        color="primary"
                        className={classes.button}
                        onClick={() => {
                            this.setState({ dlgOpen: true });
                        }}
                    >{getTxt('Add / Remove Movements Types')} </Button>
                }

                {!this.props.noCaption && <RegularCard

                    headerColor="red"
                    cardTitle={getTxt('Contract Movements Types')}
                    cardSubtitle={getTxt('List of Contract Movements Types')}
                    content={
                        <div>

                        </div>

                    }
                />}

                <Grid
                    rows={resp.selectedItems}
                    columns={columns}
                >
                    <SortingState
                        defaultSorting={[]}
                    />

                    <IntegratedSorting />

                    <Table
                        messages={tableMessages}
                        cellComponent={({ row, column, style, ...props }) => {
                            if (column.name === 'item_type_cd') {
                                return (<Table.Cell>
                                    {getLabelx(resp, row.item_type_cd)}
                                </Table.Cell>);
                            }
                            return <Table.Cell {...props} />;

                        }}
                    />

                    <TableHeaderRow showSortingControls />

                </Grid>


                <Dialog onClose={() => { this.setState({ dlgOpen: false }); }} open={this.state.dlgOpen}>
                    <DialogTitle>{getTxt('Movements Types.')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <span> {getTxt('Movements available for') + ' ' + getLabel(DictionaryTypes.InternalInsuranceType, this.props.insTypeCd) + '.'} </span>
                        </DialogContentText>

                        <Grid
                            rows={dialogItems}
                            columns={dialogColumns}
                        >
                            <SortingState
                                defaultSorting={[]}
                            />

                            <IntegratedSorting />
                            <Table
                                messages={tableMessages}
                                cellComponent={({ row, column, style, ...props }) => {
                                    if (column.name === 'selected') {
                                        return (<Table.Cell>
                                            <Checkbox
                                                onChange={(e, value) => {
                                                    const newState = {};
                                                    newState[row.item_type_cd] = value;
                                                    this.setState(newState);
                                                }}
                                                checked={row.selected}
                                                key={row.item_type_cd}
                                            />
                                        </Table.Cell>);
                                    }
                                    if (column.name === 'item_type_cd') {
                                        return (<Table.Cell>

                                            {resp.allTypes.find(i => i.value === row.item_type_cd).label}
                                        </Table.Cell>);
                                    }

                                    return <Table.Cell {...props} />;

                                }}
                            />
                            <TableHeaderRow showSortingControls />

                        </Grid>

                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                this.saveSelectedItems();
                                this.setState({ dlgOpen: false });
                            }}
                            color="primary"
                        >
                            {getTxt('Salva')}
                        </Button>
                        <Button onClick={() => { this.setState({ dlgOpen: false }); }} color="primary">
                            {getTxt('Annula')}
                        </Button>

                    </DialogActions>
                </Dialog>

            </div>
        );
    }
}

export default compose(

    graphql(queries.allContractItems_In_InsTypeQuery, {
        options: props => (
            {
                insTypeCd: props.insTypeCd,
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }
        ),

    }),

    graphql(queries.updateContractItems_In_InsTypeMutation, {
        name: 'saveItems',
        options: {
            errorPolicy: 'all',
        },
    }),

)(withRouter(withStyles(styles)(ContractItemTypesSearch)));




