import UnderDevScreen from 'components/UnderDevScreen';
import MainMenu from 'components/mainMenu/MainMenu';
import { addServerConfig, getTxt } from 'global/appGlobal';
import { addAuthListener, authInfo, initFromCookie, logout } from 'global/auth';
import { addCities, addDictionaries } from 'global/dictionaryCache';
import { addDescriptors } from 'global/entityDescriptors';
import gql from 'graphql-tag';
import logoImage from 'home/logo.png';
import $ from 'jquery';
import * as Icon from '@material-ui/icons';

import { AppBar } from '@material-ui/core';
import { Drawer } from '@material-ui/core';
import { Hidden } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import { ListItemIcon, ListItemText } from '@material-ui/core';
import { Menu, MenuItem } from '@material-ui/core';
import { Toolbar } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import LoginPage from 'modules/LoginPage';
import Broker_Details_View from 'modules/broker/Broker_Details_View';
import Broker_Search from 'modules/broker/Broker_Search';
import Client_Company_Details_Edit from 'modules/client/Client_Company_Details_Edit';
import Client_Company_View from 'modules/client/Client_Company_View';
import Client_Privat_Details_Edit from 'modules/client/Client_Privat_Details_Edit';
import Client_Privat_View from 'modules/client/Client_Privat_View';
import Client_Propspect_Privat_Details_Edit from 'modules/client/Client_Propspect_Privat_Details_Edit';
import Client_Prospect_Privat_View from 'modules/client/Client_Prospect_Privat_View';
import Client_Search from 'modules/client/Client_Search';
import Company_Details_Edit from 'modules/company/Company_Details_Edit';
import Company_Search from 'modules/company/Company_Search';
import Company_View from 'modules/company/Company_View';
import Contract_Edit from 'modules/contract/Contract_Edit';
import Contract_Item_Edit from 'modules/contract/Contract_Item_Edit';
import Contract_Item_View from 'modules/contract/Contract_Item_View';
import Contract_Search from 'modules/contract/Contract_Search';
import Contract_View from 'modules/contract/Contract_View';
import Dictionary_Item_Edit from 'modules/dictionary/Dictionary_Item_Edit';
import Dictionary_Items_Search from 'modules/dictionary/Dictionary_Items_Search';
import Dictionary_Search from 'modules/dictionary/Dictionary_Search';
import File_Edit from 'modules/file/File_Edit';
import Quote_Edit from 'modules/quote/Quote_Edit';
import Referent_Edit from 'modules/referent/Referent_Edit';
import Request_Details_Edit from 'modules/request/Request_Details_Edit';
import Request_Search from 'modules/request/Request_Search';
import Request_View from 'modules/request/Request_View';
/* eslint-disable flowtype/require-valid-file-annotation */
import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'react-apollo';
import { Route } from 'react-router-dom';
import { Offline } from 'react-detect-offline';
import Broker_Details_Edit from '../modules/broker/Broker_Details_Edit';
import Broker_View from '../modules/broker/Broker_View';
import CompanyGuarantyEdit from '../modules/company/CompanyGuarantyEdit';
import GuarantyTypeEdit from '../modules/guaranty/GuarantyTypeEdit';
import GuarantyTypeSearch from '../modules/guaranty/GuarantyTypeSearch';
import Internal_Ins_Type_Search from '../modules/internalInsType/Internal_Ins_Type_Search';
import Internal_Ins_Type_View from '../modules/internalInsType/Internal_Ins_Type_View';
import PublicInsTypeSearch from '../modules/publicInsType/PublicInsTypeSearch';
import Public_Ins_Type_View from '../modules/publicInsType/Public_Ins_Type_View';
import Custom_Fields_Edit from '../modules/publicInsType/Custom_Fields_Edit';
import RequestGuarantyEdit from '../modules/request/RequestGuarantyEdit';
import Settings from '../modules/settings/Settings';

import ManagementDashboard from '../modules/dashboard/ManagementDashboard';
import InstallBanner from '../components/InstallBanner';
import Translations_Search from '../modules/translation/Translations_Search';
import ContractItemsReport from '../modules/reports/ContractItemsReport';
import Claim_Details_Edit from '../modules/contract/claim/Claim_Details_Edit';
import Claim_Details_View from '../modules/contract/claim/Claim_Details_View';
import ContractItemTypeSearch from '../modules/contract/Contract_Item_Type_Search';
import Broker_Payments_Search from '../modules/broker/Broker_Payments_Search';
import Client_Payments_Search from '../modules/client/Client_Payments_Search';
import Company_Payments_Search from '../modules/company/Company_Payments_Search';
import Claim_Search from '../modules/contract/claim/Claim_Search';
import ClaimsReport from '../modules/reports/ClaimsReport';
import ClaimReport_print from '../modules/reports/ClaimReport_print';
import Monthly_Inbound_Report from '../modules/reports/Monthly_Inbound_Report';
import Monthly_Inbound_Report_print from '../modules/reports/Monthly_Inbound_Report_print';
import Revenues_Insurance_Company_Report from '../modules/reports/Revenues_Insurance_Company_Report';
import ReportsRoot from '../modules/reports/ReportsRoot';
import OperationalDashboard from '../modules/dashboard/OperationalDashboard';
import Conversion_Index_Report from '../modules/reports/Conversion_Index_Report';
import PublicPaymTypeSearch from '../modules/paymentTypes/PublicPaymTypeSearch';
import Public_Payment_Type_View from '../modules/paymentTypes/Public_Payment_Type_View';
import AdminProfile from '../modules/profile/AdminProfile';
import Global_Settings_Edit from '../modules/globalSettings/Global_Settings_Edit';
import { tableMessages, filterRowMessages, getConfigValue, getClientWidth, resizeService } from '../global/appGlobal';
import ChangeLog from '../modules/ChangeLog/ChangeLog';
import { Button } from '@material-ui/core';
import Email_Templates_Search from '../modules/email-templates/Email_Templates_Search';
import Email_Template_Edit from '../modules/email-templates/Email_Template_Edit';
import QuietanzamentoReport from '../modules/reports/QuietanzamentoReport';
import TracciamentoSimpliReport from '../modules/reports/TracciamentoSimpliReport';
import ReportHeaderEdit from '../modules/reports/ReportHeaderEdit';
import SnackbarMessage from '../components/SnackbarMessage';
import Client_Search_BigData from '../modules/client/Client_Search_BigData';
import Contract_Search_BigData from '../modules/contract/Contract_Search_BigData';
import Request_Search_BigData from '../modules/request/Request_Search_BigData';
import Client_Profile_Edit from '../modules/client/Client_Profile_Edit';
import Company_Iban_Edit from '../modules/company/Company_Iban_Edit';
import Quote_View from '../modules/quote/Quote_View';
import SharedSearch from '../modules/shared-search/SharedSearch';
import Transit_Account_Search from '../modules/transit_account/Transit_Account_Search';
import Transit_Account_Type_List from '../modules/transit_account/Transit_Account_Type_List';
import { Fragment } from 'react';
import ui_dev_info from '../modules/ui_dev_info/ui_dev_info';
import PolizzeAnnullateReport from '../modules/reports/PolizzeAnnullateReport';




const drawerWidth = 235;

const styles = theme => (
    {
        root: {
            flexGrow: 1,
            zIndex: 1,
            overflow: 'hidden',
            position: 'relative',
            display: 'flex',
            width: '100%',
        },
        appBar: {
            // position: 'absolute',
            // _marginLeft: drawerWidth,
            // [theme.breakpoints.up('md')]: {
            //     width: `calc(100% - ${drawerWidth}px)`,
            // },

            backgroundColor: 'black',
            position: 'absolute',

            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        navIconHide: {
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
        toolBar: {
            ...theme.mixins.toolbar,
            backgroundColor: 'rgb(110,110,110)', // jacob-color-change
            opacity: 1,
            paddingLeft: theme.spacing.unit,
            position: 'relative',
        },
        hidden: {
            backgroundColor: 'red',
            height: '3000px',
        },
        drawerPaper: {
            width: drawerWidth + 20,
            [theme.breakpoints.up('md')]: {
                position: 'relative',
            },


            backgroundColor: 'rgb(90,90,90)',// jacob-color-change


            color: theme.palette.background.paper,

            [theme.breakpoints.up('md')]: {
                width: drawerWidth,
                position: 'relative',
                height: '100%',
            },

        },
        appFrame: {
            position: 'relative',
            display: 'flex',
            width: '100%',
            height: '100%',
        },
        content: {
            flexGrow: 1,
            backgroundColor: '#EEEEEE', //theme.palette.background.default,
            width: 'calc(100% - 240px)', //'85%',
            padding: theme.spacing.unit,
            height: 'calc(100% - 0px)',
            marginTop: theme.spacing.unit * 4,
            opacity: 0.967,


            [theme.breakpoints.up('sm')]: {
                height: 'calc(100% - 0px)',
                marginTop: theme.spacing.unit * 7,
            },

        },

        logoRoot: {
            width: '53px',
            height: '50px',
            marginRight: '10px',
            cursor: 'pointer',
            marginLeft: theme.spacing.unit * 2,
        },

        adtLogo1: {
            position: 'absolute',
            top: -115,
            height: 176,
            left: 256,
            [theme.breakpoints.between('xs', 'sm')]: {
                left: 300,
            },
        },

        adtLogo2: {
            position: 'relative',
            top: 0,
            //left: 275,
            height: 52,
            // [theme.breakpoints.between('xs', 'sm')]: {
            //     left: 320,
            // },
        },

        adtLogo2svg: {
            position: 'absolute',
            top: -205,
            left: -135,
            height: 260,
            // [theme.breakpoints.between('xs', 'sm')]: {
            //     left: 0,
            // },

        },

        userPnl: {

            whiteSpace: 'nowrap',
            width: 'auto',
        },
        button: {
            margin: theme.spacing.unit,
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: theme.spacing.unit * 5,

        },
        userIcon: {
            fontSize: theme.spacing.unit * 5,
        },
        mainMenuRoot: {
            opacity: 0.967,
        },
        backBtn: {
            position: 'absolute !important',
            right: theme.spacing.unit,
            color: 'white !important',
            backgroundColor: 'rgb(110,110,110) !important',

        },
    });

let comeBackClicked = false;

class Home extends React.Component {

    constructor(props) {
        super(props);
        this.userMenuClose = this.userMenuClose.bind(this);
        this.onLogout = this.onLogout.bind(this);
        this.onResize = this.onResize.bind(this);
        $('body').click(this.userMenuClose);
        this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
        this.comeBack = this.comeBack.bind(this);


        this.showPopupInfo = this.showPopupInfo.bind(this);
        // eslint-disable-next-line no-undef
        window['showPopupInfo'] = this.showPopupInfo;
        this.hidePopupInfo = this.hidePopupInfo.bind(this);


        this.props.history.listen((location, action) => {

            if (location.pathname &&
                (location.pathname.includes('/edit/') || location.pathname.includes('/new')) && comeBackClicked) {
                this.props.history.goBack();
            }
            comeBackClicked = false;
        });

        resizeService.addResizable('home', () => {
            this.onResize();
        });
    }

    state = {
        mobileOpen: false,
        loginInfo: undefined,
        userMenuAnchor: undefined,
        userMenuOpen: false,
        popupInfoProps: false,
        backBtnText: getTxt('Back'),
        logoScale: 1,
        logoTranslate: 0,
    };

    showPopupInfo(params) {
        this.setState({ popupInfoProps: params });
    }

    hidePopupInfo() {
        this.setState({ popupInfoProps: false });
    }

    componentWillMount() {
        initFromCookie(this.props.history);
        this.setState({ loginInfo: authInfo.loginInfo });

        addAuthListener((loginInfo) => {
            this.setState({ loginInfo });
        });
    }

    componentDidMount() {
        resizeService.resizeRequest();
    }

    handleDrawerToggle = () => {
        this.setState({ mobileOpen: !this.state.mobileOpen });
    };

    userMenuClose() {
        if (this.state.userMenuOpen) {
            this.setState({ userMenuOpen: false });
        }

    }

    onLogout() {
        this.userMenuClose();
        logout();
    }

    onMenuClick(path) {
        this.props.history.push(path);
    }

    comeBack() {
        comeBackClicked = true;
        this.props.history.goBack();
    }

    onResize() {
        const w = getClientWidth();
        if (w < 730) {
            this.setState({ backBtnText: '' });
            const percentLess = w * 100 / 730;
            const scale = percentLess / 100;
            this.setState({ logoScale: scale });
            this.setState({ logoTranslate: 100 - percentLess - 5 });


        } else {
            this.setState({ backBtnText: getTxt('Back') });
            this.setState({ logoScale: 1 });
            this.setState({ logoTranslate: 0 });
        }

    }


    render() {
        const { classes, theme } = this.props;


        if (!this.props.data.loading) {
            const meta = this.props.data.allMetadata;
            if (meta && meta.entityDescriptors) {
                addDescriptors(JSON.parse(meta.entityDescriptors));
            }

            if (meta && meta.dictionaries) {
                addDictionaries(JSON.parse(meta.dictionaries));
            }

            if ((meta && meta.cities)) {
                addCities(JSON.parse(meta.cities));
            }

            if ((meta && meta.config)) {
                addServerConfig(JSON.parse(meta.config));
            }

            if ((meta && meta.translations)) {
                window.translations = JSON.parse(meta.translations);

                tableMessages.noData = getTxt('No Data');
                filterRowMessages.filterPlaceholder = getTxt('Filter...');
            }

            if ((meta && meta.globalSettings)) {
                window.globalSettings = JSON.parse(meta.globalSettings);
            }
        }

        if (this.props.data.loading) {
            return (<span>{getTxt('Loading...', '#jjpzgo0')}</span>);
        }

        const clientUrl = getConfigValue('client_url');
        return (
            <div className={classes.root}>



                <div className={classes.appFrame}>

                    {this.state.popupInfoProps && <SnackbarMessage
                        className={classes.root}
                        {...this.state.popupInfoProps}
                        onClose={this.hidePopupInfo}
                    />}

                    <AppBar className={classes.appBar}>
                        <Toolbar className={classes.toolBar} >

                            {/* <Offline>
                                <Snackbar
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                    open={true}

                                    ContentProps={{
                                        'aria-describedby': 'message-id',
                                    }}
                                    message={<span id="message-id">Nessuna connessione Internet</span>}
                                />

                            </Offline> */}


                            {this.state.loginInfo &&
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={this.handleDrawerToggle}
                                    className={classes.navIconHide}
                                >
                                    <Icon.Menu />
                                </IconButton>
                            }

                            <div style={{
                                height: 50, display: 'flex', flexWrap: 'nowrap', paddingLeft: 10, width: 450,
                                transform: `scale(${this.state.logoScale}) translate(-${this.state.logoTranslate}%, 0) `
                            }}>

                                <img id="logo" src={logoImage} alt="Logo" onClick={() => this.props.history.push('/')}
                                    style={{ height: '100%', width: 52, objectFit: 'contain', marginRight: 10 }} />


                                <Typography variant="h5" color="inherit" noWrap onClick={() => this.props.history.push('/')}
                                    style={{ cursor: 'pointer', height: 50, paddingTop: 10, textOverflow: 'clip', marginRight: 25 }} >
                                    My Insurance
                                </Typography>

                                {(clientUrl === 'https://www.portalemyinsurance.it/') &&
                                    <img id="adtLogo" src="icons/GOLINO.svg" alt="Logo" onClick={() => this.props.history.push('/')}
                                        style={{ marginTop: -115, height: 170 }}

                                    />
                                }
                                {(clientUrl === 'https://innovaass.portalemyinsurance.it/') &&
                                    <div className={classes.adtLogo2}>
                                        <img id="adtLogo" src="icons/INNOVAASS.svg" alt="Logo"
                                            className={classes.adtLogo2svg}

                                        />
                                    </div>
                                }

                            </div>

                            {/* <img id="logo" src={logoImage} className={classes.logoRoot} alt="Logo" onClick={() => this.props.history.push('/')}
                            style={{width: this.state.logoRootWidth, height: this.state.logoRootHeight}} />
                            <Typography variant="h5" color="inherit" noWrap onClick={() => this.props.history.push('/')} style={{ cursor: 'pointer' }} >
                                My Insurance
                            </Typography> */}


                            {/* {(clientUrl === 'https://www.portalemyinsurance.it/' || location.hostname.includes('localhost')) &&
                                <img id="adtLogo" src="icons/GOLINO.svg" alt="Logo" onClick={() => this.props.history.push('/')}
                                    style={{ width: this.state.adtLogo1Width, top: this.state.adtLogo1Top }}
                                    className={classes.adtLogo1}
                                />
                            }
                            {(clientUrl === 'https://innovaass.portalemyinsurance.it/') &&
                                <div className={classes.adtLogo2}>
                                    <img id="adtLogo" src="icons/INNOVAASS.svg" alt="Logo"
                                        className={classes.adtLogo2svg}

                                    />
                                </div>
                            } */}

                            {this.props.history.location.pathname !== '/' && this.props.history.location.pathname !== '/login' &&
                                <Fragment>
                                    {(getClientWidth() > 730) &&
                                        <Button
                                            className={classes.backBtn}
                                            onClick={this.comeBack}
                                            color="primary"
                                            variant="contained"
                                            size="medium"
                                            id="backButton"

                                        >
                                            <Icon.NavigateBefore />
                                            Indietro
                                        </Button>
                                    }

                                    {(getClientWidth() < 730) &&


                                        <IconButton
                                            aria-label={getTxt('Come Back')}
                                            className={classes.backBtn}
                                            onClick={this.comeBack}
                                          
                                            id="backButton"

                                        >
                                            <Icon.NavigateBefore />

                                        </IconButton>
                                    }

                                </Fragment>

                            }
                            <div>
                                {this.state.loginInfo &&
                                    <div className={classes.userPnl}>
                                        {/* <IconButton
                                            color="inherit"
                                            className={classes.button}
                                            onClick={(e) => {
                                                this.setState({ userMenuAnchor: e.target, userMenuOpen: true });
                                            }}
                                        >
                                            <Icon.AccountCircle className={classes.userIcon} />
                                        </IconButton> */}
                                        <Menu

                                            anchorEl={this.state.userMenuAnchor}
                                            open={this.state.userMenuOpen}
                                            onClose={() => {
                                                this.setState({ userMenuOpen: false });
                                            }}
                                        >
                                            <MenuItem onClick={this.userMenuClose}>
                                                <ListItemIcon className={classes.icon}>
                                                    <Icon.PermIdentity />
                                                </ListItemIcon>
                                                <ListItemText primary={this.state.loginInfo.userInfo.displayName} />
                                            </MenuItem>
                                            <MenuItem onClick={this.onLogout}>
                                                <ListItemIcon className={classes.icon}>
                                                    <Icon.ExitToApp />
                                                </ListItemIcon>
                                                <ListItemText primary="Logout" />
                                            </MenuItem>


                                        </Menu>

                                    </div>
                                }

                            </div>



                        </Toolbar>
                    </AppBar>
                    <Hidden mdUp
                        className={classes.hidden}
                    >
                        <Drawer
                            variant="temporary"
                            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                            open={this.state.mobileOpen}
                            onClose={this.handleDrawerToggle}
                            classes={{
                                paper: classes.drawerPaper,
                            }}

                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                        >

                            {this.state.loginInfo && <MainMenu history={this.props.history} onClose={this.handleDrawerToggle} />}
                        </Drawer>
                    </Hidden>
                    {<Hidden smDown implementation="css" >
                        {this.state.loginInfo && <Drawer
                            variant="permanent"
                            open
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                        >
                            <MainMenu history={this.props.history} />
                        </Drawer>}
                    </Hidden>}
                    <main className={classes.content}>

                        {authInfo && !authInfo.loginInfo &&
                            <div>
                                <Route path="/login" component={LoginPage} />
                                <Route exact path="/" component={LoginPage} />
                            </div>
                        }
                        {authInfo && authInfo.loginInfo &&
                            <div>
                                <Route exact path="/customers/all" component={Client_Search_BigData} />
                                <Route exact path="/customers/filterx/:filter" component={Client_Search_BigData} />
                                <Route exact path="/customers/filter/:filter" component={Client_Search} />
                                <Route exact path="/customers" component={Client_Search} />
                                <Route exact path="/customers/private/:id" component={Client_Privat_View} />
                                <Route exact path="/customers/private/edit/:id" component={Client_Privat_Details_Edit} />
                                <Route exact path="/customers/new/private" component={Client_Privat_Details_Edit} />
                                <Route exact path="/customers/private/edit/:id/toreal/:toReal" component={Client_Privat_Details_Edit} />


                                <Route exact path="/customers/company/:id" component={Client_Company_View} />
                                <Route exact path="/customers/company/edit/:id" component={Client_Company_Details_Edit} />
                                <Route exact path="/customers/new/company/" component={Client_Company_Details_Edit} />
                                <Route exact path="/customers/prospect/private/:id/" component={Client_Prospect_Privat_View} />

                                <Route exact path="/customers/prospect/private/edit/:id" component={Client_Propspect_Privat_Details_Edit} />
                                <Route exact path="/customers/new/prospect/private/" component={Client_Propspect_Privat_Details_Edit} />

                                <Route exact path="/customers/payments/all" component={Client_Payments_Search} />



                                <Route exact path="/requests" component={Request_Search} />
                                <Route exact path="/requests/all" component={Request_Search_BigData} />
                                <Route exact path="/requests/filter/:filter" component={Request_Search} />

                                <Route exact path="/requests/:insType/view/:id" component={Request_View} />
                                <Route exact path="/requests/:insType/:customerId/new" component={Request_Details_Edit} />
                                <Route exact path="/requests/:insType/edit/:customerId/:id" component={Request_Details_Edit} />

                                <Route exact path="/quotes/edit/:requestId/:id" component={Quote_Edit} />

                                <Route exact path="/quotes/view/:requestId/:id" component={Quote_View} />

                                <Route exact path="/documents/edit/:id" component={File_Edit} />

                                <Route exact path="/brokers/view/:id" component={Broker_Details_View} />



                                <Route exact path="/reports" component={ReportsRoot} />
                                <Route exact path="/reports/contracts" component={ContractItemsReport} />
                                <Route exact path="/reports/contracts/non-perfezionati" component={ContractItemsReport} />
                                <Route exact path="/reports/quietanzamento" component={QuietanzamentoReport} />
                                <Route exact path="/reports/tracciamento-simpli" component={TracciamentoSimpliReport} />
                                <Route exact path="/reports/polizze-annullate" component={PolizzeAnnullateReport} />

                                <Route exact path="/ui_info" component={ui_dev_info} />


                                {authInfo.isCustomer() &&
                                    <Route exact path="/" component={Contract_Search_BigData} />
                                }
                                {!authInfo.isCustomer() &&
                                    <Route exact path="/company/search" component={Company_Search} />
                                }
                                <Route exact path="/company/view/:id" component={Company_View} />
                                <Route exact path="/company/edit/:id" component={Company_Details_Edit} />

                                {(authInfo.isAgencyAdministrator() || authInfo.isMasterBroker() || authInfo.isBroker() || authInfo.isSubBroker()) &&
                                    <Route exact path="/companies/payments/all" component={Company_Payments_Search} />
                                }
                                {(authInfo.isAgencyAdministrator() || authInfo.isMasterBroker() || authInfo.isBroker() || authInfo.isSubBroker()) &&
                                    <Route exact path="/changelog" component={ChangeLog} />
                                }



                                <Route exact path="/dictionaries/:dictionaryId/items/:id" component={Dictionary_Item_Edit} />
                                <Route exact path="/dictionaries/:dictionaryId/new" component={Dictionary_Item_Edit} />
                                <Route exact path="/dictionaries/:dictionaryId/items" component={Dictionary_Items_Search} />
                                <Route exact path="/dictionaries/" component={Dictionary_Search} />

                                <Route exact path="/contracts/all" component={Contract_Search_BigData} />
                                <Route exact path="/contracts" component={Contract_Search} />
                                <Route exact path="/contracts/filter/:filter" component={Contract_Search} />
                                <Route exact path="/contract/view/:insType/:id" component={Contract_View} />
                                <Route exact path="/contract/edit/:insType/:requestId" component={Contract_Edit} />
                                <Route exact path="/contract/edit/:insType/:requestId/:id" component={Contract_Edit} />
                                <Route exact path="/contract/:insType/:contractId/item/:id" component={Contract_Item_Edit} />
                                <Route exact path="/contract/:insType/:contractId/quietanza/:id" component={Contract_Item_Edit} />
                                <Route exact path="/contract/:insType/:contractId/item/view/:id" component={Contract_Item_View} />


                                <Route exact path="/contract/:contractId/claim/:claimId" component={Claim_Details_Edit} />
                                <Route exact path="/contract/:contractId/claim/view/:claimId" component={Claim_Details_View} />

                                <Route exact path="/contract_items_types" component={ContractItemTypeSearch} />


                                <Route exact path="/claims" component={Claim_Search} />

                                <Route exact path="/claims/filter/:filter" component={Claim_Search} />


                                <Route exact path="/referents/edit/:parentId/:id" component={Referent_Edit} />

                                {(authInfo.isAgencyAdministrator() || authInfo.isMasterBroker() || authInfo.isBroker()) &&
                                    <Route exact path="/brokers" component={Broker_Search} />
                                }

                                {(authInfo.isAgencyAdministrator() || authInfo.isMasterBroker() || authInfo.isBroker() || authInfo.isSubBroker()) &&
                                    <Route exact path="/brokers/payments/all" component={Broker_Payments_Search} />
                                }

                                <Route exact path="/brokers/:id" component={Broker_View} />

                                {(authInfo.isAgencyAdministrator() || authInfo.isMasterBroker() || authInfo.isBroker()) &&
                                    <Route exact path="/brokers/edit/:id" component={Broker_Details_Edit} />
                                }
                                {(authInfo.isSubBroker()) &&
                                    <Route exact path="/brokers/edit/:id" component={Broker_Details_View} />
                                }


                                <Route exact path="/guaranty_types" component={GuarantyTypeSearch} />
                                <Route exact path="/guaranty_types/:id" component={GuarantyTypeEdit} />
                                <Route exact path="/guaranty_types/:id/for_ins_type/:insType" component={GuarantyTypeEdit} />

                                <Route exact path="/company_guaranties/:companyId/:id" component={CompanyGuarantyEdit} />

                                <Route exact path="/guaranty/:requestId/:id" component={RequestGuarantyEdit} />
                                <Route exact path="/guaranty/:requestId/:id/:contractId" component={RequestGuarantyEdit} />

                                <Route exact path="/insurance_types" component={PublicInsTypeSearch} />
                                <Route exact path="/public_ins_type/view/:id" component={Public_Ins_Type_View} />
                                <Route exact path="/public_ins_type/:id/edit_fields" component={Custom_Fields_Edit} />

                                <Route exact path="/real_insurance_types" component={Internal_Ins_Type_Search} />
                                <Route exact path="/real_insurance_types/view/:id" component={Internal_Ins_Type_View} />

                                <Route exact path="/payment_types" component={PublicPaymTypeSearch} />
                                <Route exact path="/public_payment_type/view/:id" component={Public_Payment_Type_View} />

                                {authInfo.isAgencyAdministrator() &&
                                    <Route exact path="/ui_translation" component={Translations_Search} />
                                }


                                {(authInfo.isAgencyAdministrator() || authInfo.isMasterBroker() || authInfo.isBroker()) &&
                                    <Route exact path="/reports/claims" component={ClaimsReport} />
                                }
                                {(authInfo.isAgencyAdministrator() || authInfo.isMasterBroker() || authInfo.isBroker()) &&
                                    <Route exact path="/reports/claims/print/:from/:to/:companyId" component={ClaimReport_print} />
                                }
                                {(authInfo.isAgencyAdministrator() || authInfo.isMasterBroker() || authInfo.isBroker()) &&
                                    <Route exact path="/reports/monthly-inbound" component={Monthly_Inbound_Report} />
                                }
                                {(authInfo.isAgencyAdministrator() || authInfo.isMasterBroker() || authInfo.isBroker()) &&
                                    <Route exact path="/reports/monthly-inbound/print" component={Monthly_Inbound_Report_print} />
                                }

                                {(authInfo.isAgencyAdministrator() || authInfo.isMasterBroker() || authInfo.isBroker()) &&
                                    <Route exact path="/reports/revenues-company" component={Revenues_Insurance_Company_Report} />
                                }

                                {(authInfo.isAgencyAdministrator() || authInfo.isMasterBroker() || authInfo.isBroker()) &&
                                    <Route exact path="/reports/conversion-index" component={Conversion_Index_Report} />
                                }


                                {(authInfo.isAgencyAdministrator() || authInfo.isMasterBroker() || authInfo.isSubBroker()) &&
                                    <Route exact path="/dashboard/management" component={ManagementDashboard} />
                                }

                                {(authInfo.isBroker() || authInfo.isSubBroker() || authInfo.isAgencyAdministrator() || authInfo.isMasterBroker()) &&
                                    <Route exact path="/" component={OperationalDashboard} />
                                }

                                {(authInfo.isAgencyAdministrator() || authInfo.isMasterBroker() || authInfo.isBroker() || authInfo.isSubBroker()) &&
                                    <Route exact path="/dashboard/operational" component={OperationalDashboard} />
                                }
                                {(authInfo.isAgencyAdministrator()) &&
                                    <Route exact path="/admin/profile" component={AdminProfile} />
                                }
                                {(authInfo.isCompany()) &&
                                    <Route exact path="/company/profile" component={() => <Company_Details_Edit isProfile />} />
                                }
                                {(authInfo.isBroker()) &&
                                    <Route exact path="/broker/profile" component={() => <Broker_Details_Edit isProfile />} />
                                }
                                {(authInfo.isCustomer()) &&
                                    <Route exact path="/customer/profile" component={() => <Client_Profile_Edit />} />
                                }
                                {/* {(authInfo.isCustomer() && authInfo.loginInfo.userInfo.customerType === 'PRIVATE') &&
                                    <Route exact path="/customer/profile" component={() => <Client_Privat_Details_Edit isProfile />} />
                                }
                                {(authInfo.isCustomer() && authInfo.loginInfo.userInfo.customerType === 'COMPANY') &&
                                    <Route exact path="/customer/profile" component={() => <Client_Company_Details_Edit isProfile />} />
                                } */}

                                {(authInfo.isAgencyAdministrator() || authInfo.isMasterBroker()) &&
                                    <Route
                                        exact
                                        path="/conti_transito"
                                        component={Transit_Account_Type_List}
                                    />
                                }

                                {(authInfo.isAgencyAdministrator() || authInfo.isMasterBroker()) &&
                                    <Route
                                        exact
                                        path="/conti_transito/:payment_account"
                                        component={Transit_Account_Search}
                                    />
                                }

                                {(authInfo.isAgencyAdministrator() || authInfo.isMasterBroker()) &&
                                    <Route
                                        exact
                                        path="/settings"
                                        component={Global_Settings_Edit}
                                    />
                                }
                                {(authInfo.isAgencyAdministrator() || authInfo.isMasterBroker()) &&
                                    <Route
                                        exact
                                        path="/email_templates"
                                        component={Email_Templates_Search}
                                    />
                                }
                                {(authInfo.isAgencyAdministrator() || authInfo.isMasterBroker()) &&
                                    <Route
                                        exact
                                        path="/email_template/:id"
                                        component={Email_Template_Edit}
                                    />
                                }
                                {(authInfo.isAgencyAdministrator() || authInfo.isMasterBroker()) &&
                                    <Route
                                        exact
                                        path="/reports_header"
                                        component={ReportHeaderEdit}
                                    />
                                }
                                {(authInfo.isAgencyAdministrator() || authInfo.isMasterBroker()) &&
                                    <Route
                                        exact
                                        path="/iban/edit/:id"
                                        component={Company_Iban_Edit}
                                    />
                                }
                                {(authInfo.isAgencyAdministrator() || authInfo.isMasterBroker() || authInfo.isBroker()) && getConfigValue('partnerAgencies').length > 0 &&
                                    <Route
                                        exact
                                        path="/shared-search"
                                        component={SharedSearch}
                                    />
                                }

                                <Route path="/login" component={LoginPage} />
                                <Route path="/underdev" component={UnderDevScreen} />

                            </div>
                        }



                    </main>

                </div>
                {/* <InstallBanner />  */}
            </div>
        );
    }
}

Home.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export const metadataQuery = gql`

    query allmetaQuery{
        allMetadata {
            entityDescriptors
            dictionaries
            config
            translations
        }
    }

`;

export default graphql(metadataQuery, {
    options: {
        errorPolicy: 'all',
    },
})(withStyles(styles, { withTheme: true })(Home));





