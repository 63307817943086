import ComponentEx from 'components/ComponentEx';
import { getTxt } from 'global/appGlobal';
import { Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, Input, InputAdornment, InputBase, InputLabel, Link } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { EntityTypes, getEntityDescriptor } from 'global/entityDescriptors';
import { compose, graphql } from 'react-apollo';
import LinearProgressEx from '../../components/LinearProgressEx';
import c from '../../components/form/Components';
import MaterialForm from '../../components/form/MaterialForm';
import { DictionaryTypes, getDictionary, getLabel } from '../../global/dictionaryCache';
import { metadataQuery } from '../../home/Home';
import { authInfo, Roles } from '../../global/auth';
import GridEx from 'components/form/GridEx';

import * as queries from '../../modules/broker/brokerQueries';
import { Button } from '@material-ui/core';
import { QueuePlayNext, Search } from '@material-ui/icons';
import { addDescriptor } from '../../global/entityDescriptors';
import File_Search from '../file/File_Search';
import { Line } from 'chartist';

const OBG_DESCR_KEY = 'email_to_client_obj_descr';

let history;
let AfterSubmit;
const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing.unit * 2,
        margin: theme.spacing.unit * 2,
    },
    formButtons: {
        float: 'right',
        marginTop: '15px',
        marginBottom: '15px',
    },
    button: {
        margin: theme.spacing.unit,
    },

    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        paddingTop: theme.spacing.unit,
        width: '100%',
        position: 'relative',
    },

    textFieldCheckBox: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        marginTop: theme.spacing.unit / 2,

        width: '100%',
        position: 'relative',
    },

    label: {
        marginTop: theme.spacing.unit,
    },

    delim: {
        marginRight: theme.spacing.unit,
    },

    input: {
        color: 'transparent',
    },

    dialogText: {
        color: theme.palette.error.main,
    },

    calcCodeBtn: {
        position: 'absolute',
        right: 0,
        bottom: theme.spacing.unit,
    },
    calcProgress: {
        position: 'absolute',
        right: theme.spacing.unit * 9,
        top: theme.spacing.unit - theme.spacing.unit * 2,
    },

    validationIconTrue: {
        position: 'absolute',
        right: theme.spacing.unit * 13,
        color: 'green',
    },

    validationIconFalse: {
        position: 'absolute',
        right: theme.spacing.unit * 13,
        color: theme.palette.error.main,
        cursor: 'pointer',
    },

    link: {
        position: 'absolute',
        top: theme.spacing.unit * 6,
        left: theme.spacing.unit,
    },
    iconSmall: {
        fontSize: 20,
    },
});

let email_to_client_obj_descr = {
    userId: {
        id: 'userId',
        label: 'userId',
        type: 'INTEGER',
        validators: [],
    },
    email: {
        id: 'email',
        label: getTxt('Email'),
        type: 'STRING',
        validators: [],
    },
    subject: {
        id: 'subject',
        label: getTxt('Oggetto'),
        type: 'STRING',
        validators: [{ required: true, message: 'Inserisci Oggetto.' }],
    },
    body: {
        id: 'body',
        label: getTxt('Corpo'),
        type: 'STRING',
        validators: [{ required: true, message: 'Inserisci Corpo.' }],
    },
    attachedFilesIs: {
        id: 'attachedFilesIs',
        label: getTxt('Allegati'),
        type: 'STRING',
        validators: [],
    },

};

addDescriptor(OBG_DESCR_KEY, email_to_client_obj_descr);

class Email_To_Client_Dlg extends ComponentEx {

    constructor(props) {
        super(props);
        email_to_client_obj_descr = {
            userId: props.userId,
            email: props.email,
            subject: '',
            body: '',
            attachedFilesIds: []
        };
        this.onSubmit = this.onSubmit.bind(this);
    }

    state = {
        open: true,
        selectedFiles: [],
    };



    formSetValues = { func: undefined };

    onSubmit(e, { obj, showErrors, hideErrors }) {
        this.props.AfterSubmit({
            ...obj,
            files: this.state.selectedFiles,
            PDFIncarico: this.state.PDFIncarico,
            ContractsPDF: this.state.ContractsPDF,
            PrivacyPDF: this.state.PrivacyPDF,
            DichiarazionePDF: this.state.DichiarazionePDF,
        });
    }

    render() {

        const { classes } = this.props;

        AfterSubmit = this.props.AfterSubmit;

        const caption = getTxt('Email al cliente');
        const me = this;
        const selectedFiles = this.state.selectedFiles;
        const _this = this;
        const setState = this.setState.bind(this);

        return (

            <Dialog onClose={() => { this.props.AfterSubmit(); }} open={this.state.open} >
                <DialogContent>
                    <div style={{ paddingBottom: 10, paddingRight: 20 }}>
                        <Typography variant="h4" color="inherit" noWrap={false}>{caption}</Typography>



                        <MaterialForm
                            dataSource={email_to_client_obj_descr}
                            objType={OBG_DESCR_KEY}
                            onSubmit={this.onSubmit}
                        >

                            <c.field f="email" d sm={12} />
                            <c.field f="subject" sm={12} />
                            <c.field f="body" sm={12} multiline />



                            <GridEx item sm={12} xs={12}>
                                <FormControl className={classes.textField} >
                                    <InputLabel
                                        shrink={this.state.selectedFiles.length > 0}
                                        className={classes.label}
                                    >
                                        {getTxt('Allegati')}
                                    </InputLabel>
                                    <div style={{ height: 40 }} />
                                    <InputBase
                                        className={classes.textFieldCheckBox}
                                        readOnly
                                        key={1}
                                        inputProps={{ 'aria-label': 'naked' }}
                                        startAdornment={
                                            <Checkbox
                                                onChange={(e, value) => {
                                                    setState({ PrivacyPDF: value });
                                                }}
                                                checked={_this.state.PrivacyPDF}
                                            />
                                        }
                                        value={getTxt('Privacy PDF')}
                                    />

                                    <InputBase
                                        className={classes.textFieldCheckBox}
                                        readOnly
                                        key={2}
                                        startAdornment={
                                            <Checkbox
                                                onChange={(e, value) => {
                                                    setState({ PDFIncarico: value });
                                                }}
                                                checked={_this.state.PDFIncarico}
                                            />
                                        }
                                        value={getTxt('PDF Incarico')}
                                    />

                                    <InputBase
                                        className={classes.textFieldCheckBox}
                                        readOnly
                                        key={3}
                                        startAdornment={
                                            <Checkbox
                                                onChange={(e, value) => {
                                                    setState({ ContractsPDF: value });
                                                }}
                                                checked={_this.state.ContractsPDF}
                                            />
                                        }
                                        value={getTxt('Contracts PDF')}
                                    />

                                    <InputBase
                                        className={classes.textFieldCheckBox}
                                        readOnly
                                        key={3}
                                        startAdornment={
                                            <Checkbox
                                                onChange={(e, value) => {
                                                    setState({ DichiarazionePDF: value });
                                                }}
                                                checked={_this.state.DichiarazionePDF}
                                            />
                                        }
                                        value={getTxt('Dichiarazione PDF')}
                                    />

                                    {/* <Input
                                        margin="normal"


                                        disabled

                                        multiline
                                        className={classes.input}
                                        inputProps={
                                            {
                                                id: this.props.id ? this.props.id : this.id,
                                                name: this.props.f,
                                                endAdornment: this.props.endAdornment,
                                            }}

                                        endAdornment={
                                            <InputAdornment position="end" style={{ zIndex: 9999 }}>
                                                <Button
                                                    variant="contained"
                                                    size="small"
                                                    onClick={() => { this.setState({ findDlgOpen: true }); }}
                                                    className={classes.calcCodeBtn}
                                                    style={this.props.d ? { display: 'none' } : {}}
                                                >
                                                    <Search className={classes.iconSmall} />
                                                </Button>
                                            </InputAdornment>
                                        }
                                    /> */}

                                    {/* <Grid container spacing={this.props.spacing || 24} className={classes.link} >
                                        <div style={{ position: 'absolute', top: -10, left: -5 }} >
                                            {selectedFiles.map((selected, index) => {
                                                return (

                                                    <span>
                                                       
                                                        {selected ? selected.file_name : ''}
                                                      
                                                        {(selectedFiles.length > 1 && index < (selectedFiles.length - 1)) && <span className={classes.delim} >, </span>}
                                                    </span>

                                                );
                                            })}
                                        </div>
                                    </Grid> */}

                                </FormControl>

                                {/* <Dialog
                                    className={classes.dialog}
                                    open={this.state.findDlgOpen}
                                    onClose={() => {
                                        this.setState({ findDlgOpen: false });
                                    }}
                                >
                                    <DialogTitle> <span>{getTxt('Find file')}</span></DialogTitle>
                                    <DialogContent>
                                        <DialogContentText>

                                            <File_Search
                                                noAddBtn
                                                entityId={this.props.userId}
                                                selectedFiles={this.state.selectedFiles}
                                                onSelectedChanged={files => this.setState({ selectedFiles: files })}

                                            />

                                        </DialogContentText>

                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            onClick={() => {
                                                me.setState({ findDlgOpen: false });

                                                if (me.selectedId) {
                                                    me.setValue(JSON.stringify(me.selectedId));
                                                }

                                            }}
                                            color="primary"
                                        >
                                            Ok
                        </Button>

                                        <Button onClick={() => { this.setState({ findDlgOpen: false }); }} color="primary" >
                                            Annulla
                           </Button>
                                    </DialogActions>
                                </Dialog> */}

                            </GridEx>








                            {/* <c.field f="attachedFilesIs" sm={12} multiline /> */}

                            <GridEx item sm={12} xs={12}>
                                <div className={classes.formButtons} >
                                    <Button
                                        className={classes.button}
                                        style={{ marginRight: 10 }}
                                        variant="contained"
                                        onClick={() => {
                                            this.props.AfterSubmit();
                                        }}
                                    >{getTxt('Annulla')}</Button>
                                    <Button
                                        color="primary"
                                        className={classes.button}
                                        type="submit"
                                        variant="contained"
                                    > {getTxt('INVIA')}</Button>
                                </div>

                            </GridEx>



                        </MaterialForm>

                    </div>

                </DialogContent>

            </Dialog>

        );
    }
}

export default compose(

)(withStyles(styles)(Email_To_Client_Dlg));
