import React, { Component } from 'react';
import { getTxt } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import { Paper } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { AppBar } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { TextField } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import * as Icon from '@material-ui/icons';
import { Checkbox } from '@material-ui/core';

import GridEx from 'components/form/GridEx';
import { Button } from '@material-ui/core';
import moment from 'moment';
import { Badge } from '@material-ui/core';
import ComponentEx from 'components/ComponentEx';
import { LinearProgress } from '@material-ui/core';
import { graphql, compose } from 'react-apollo';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import c from '../../components/form/Components';
import LinearProgressEx from '../../components/LinearProgressEx';
import { getLabel, DictionaryTypes, getDictionary } from '../../global/dictionaryCache';
import MaterialForm from '../../components/form/MaterialForm';

import { allFilesQuery, updateFileMutation, fileByIdQuery } from '../../modules/file/fileQueries';

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing.unit * 2,
        margin: theme.spacing.unit * 2,
    },
});

let history;

class File_Edit extends ComponentEx {
    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
    }

    state = {
        open: false,
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleRequestClose = () => {
        this.setState({ open: false });
    };

    onSubmit(e, { obj, showErrors, hideErrors }) {
        const originalFile = this.props.data.fileById;
        obj.document_id = originalFile.document_id;
        this.props.updateFile({
            variables: { file: obj },
            refetchQueries: [
                {
                    query: allFilesQuery,
                    variables: { entityId: originalFile.attached_to_entity_id },
                },
            ],
        }, this).then(
            (resp) => {
                hideErrors();
                if (resp.data.updateFileMutation &&
                    resp.data.updateFileMutation.validation &&
                    resp.data.updateFileMutation.validation.length > 0) {
                    showErrors(resp.data.updateFileMutation.validation);
                } else {
                    history.goBack();
                }
            });
    }


    render() {

        const { classes } = this.props;

        const loading = this.props.data.loading;
        const file = this.props.data.fileById;

        history = this.props.history;

        let caption;

        let hasIdParam;
        if (this.props.id) {
            hasIdParam = true;
        }
        if (this.props.match.params && this.props.match.params.id) {
            hasIdParam = true;
        }

        if (hasIdParam) {
            caption = 'Modifica File';
        } else {
            caption = 'Nuovo File';
        }
        return (


            <div className={classes.root}>


              
                <Typography variant="h4" color="inherit" noWrap={false}>{getTxt(caption)}</Typography>
                <LinearProgressEx loading={loading} />

                {!loading &&
                    <div>
                        <MaterialForm dataSource={file} objType="attached_file" onSubmit={this.onSubmit} >

                            <c.field l="Tipo" f="file_type_cd" ui="select">
                                {getDictionary(DictionaryTypes.DocumentType)}
                            </c.field>

                            <c.field l="Nome del file" f="file_name" />
                            <c.field l="Commento" f="file_comment" sm={12} multiline />

                            <c.formButtons sm={12} onCancel={() => { this.props.history.goBack(); }} />
                        </MaterialForm>

                    </div>
                }

            </div>




        );
    }
}




export default compose(
    graphql(fileByIdQuery, {
        options: props => (
            {
                variables: {
                    id: props.match.params.id,
                },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }
        ),
    }),
    graphql(updateFileMutation, {
        name: 'updateFile',
        options: {
            errorPolicy: 'all',
        },
    }),
)(withStyles(styles)(File_Edit));
