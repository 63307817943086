import React from 'react';
import { graphql, compose } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import $ from 'jquery';
import { Link } from 'react-router-dom';

import {
    SortingState, FilteringState, IntegratedFiltering, IntegratedSorting,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    VirtualTable, TableHeaderRow, TableFilterRow, TableColumnResizing,
} from '@devexpress/dx-react-grid-material-ui';

import ComponentEx from 'components/ComponentEx';
import { Button } from '@material-ui/core';
import c from 'components/form/Components';
import { getTxt, toItalianDateString, filterRowMessages, tableMessages, createGridResizerCols } from '../../global/appGlobal';
import LinkToCustomerById from '../../components/LinksToObjectById';
import LinearProgressEx from '../../components/LinearProgressEx';
import { getLabel, getDictionary, DictionaryTypes } from '../../global/dictionaryCache';
import { allRequestsQuery, deleteRequestMutation } from '../../modules/request/requestQeries';
import { Menu, MenuItem } from '@material-ui/core';
import * as Icon from '@material-ui/icons';

import { Divider } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import RegularCard from '../../components/dashboard/RegularCard';
import { Tooltip, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { authInfo } from 'global/auth';
import NewRequestMenuItems from '../client/NewRequestMenuItems';


const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        margin: theme.spacing.unit * 3,
    },
    button: {
        marginRight: theme.spacing.unit,
    },

    tableHeader: {
        backgroundColor: theme.palette.background.paper,
    },
    menuItem: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& $text, & $icon': {
                color: theme.palette.common.white,
            },
        },
    },
    text: {},
    icon: {},
});


const toLowerCase = value => String(value).toLowerCase();

const filterByDisplayName = (row, e) => {
    const name = toLowerCase(row.display_name);
    return name.includes(toLowerCase(e.value));
};

class Request_Search extends ComponentEx {

    state = {
        anchorEl: null,
        open: false,
        menuOpen: false,
        newRequestDlgOpen: false,
    };


    newClick(event) {
        if (!authInfo.isCustomer()) {
            this.setState({ menuOpen: true, anchorEl: event.currentTarget });
        } else {
            this.setState({ addNewMenuOpen: true, addNewMenuAnchor: event.currentTarget });
        }
    }

    handleRequestClose = () => {
        this.setState({ menuOpen: false });
    };

    constructor() {
        super();

        this.tableCellTemplate = ({ row, column }) => {
            if (column.name === 'display_name') {
                return (<VirtualTable.Cell> <LinkToCustomerById customer={row} /> </VirtualTable.Cell>);
            }
            return undefined;
        };

        this.getRowId = row => row.customer_id;
        this.newClick = this.newClick.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    componentWillMount() {
        if (!this.props.data.loading) {
            this.props.data.refetch();
        }

    }

    componentDidMount() {
        $("table[class*='Table-headTable']").addClass(this.props.classes.tableHeader);
    }

    createQuoteClick() {
        this.props.history.push('/underdev');
    }

    handleDelete() {
        this.props.deleteRequest({
            variables: { requestId: this.state.delId },
            refetchQueries: [
                {
                    query: allRequestsQuery,
                    variables: {
                        customerId: this.props.customerId,
                        broker_id: this.props.brokerId,
                    },
                },
            ],
        }, this).then(
            (resp) => {
                this.setState({ delId: undefined, delNumber: undefined });
                this.props.data.refetch();
            });
    }

    render() {

        if (this.props.quiet) {
            return (<LinearProgressEx loading={this.props.data.loading} />);
        }

        const { classes } = this.props;

        let requests = [];
        if (this.props.data.allRequests) {
            this.props.data.allRequests.typesPresents.forEach((reqTypeName) => {
                if (this.props.data.allRequests[reqTypeName]) {
                    requests = requests.concat(this.props.data.allRequests[reqTypeName]);
                }

            });
        }

        const requestsExt = [];
        requests.forEach((r) => {
            requestsExt.push({ ...r, broker: r.customer.broker });

        });
        requests = requestsExt;

        let caption = getTxt('Requests');
        let subtitle = getTxt('List of insurance requests.');

        let defFilter = {};
        if (this.props.match && this.props.match.params.filter === 'received') {
            // requests = requests.filter((r) => {
            //     return (r.state_cd !== 'TRANSFERRED_TO_CONTRACT' &&
            //         r.state_cd !== 'ALL_QUOTATIONS_REFUSED' &&
            //         r.state_cd !== 'REFUSED_BY_AGENCY');
            // });

            subtitle = getTxt('List of received insurance requests.');
            caption = getTxt('Received Requests');
            defFilter = {
                columnName: 'state_cd',
                value: 'RECIEVED',
            };
        }

        const columns = [
            { name: 'insurance_type_cd', title: getTxt('Tipo', '#m8d7dy2') },
        ];
        if (!this.props.customerId) {
            columns.push({ name: 'customer', title: getTxt('Cliente', '#36q9yv8') });
        }
        columns.push({ name: 'state_cd', title: getTxt('Stato', '#q023fte') });
        columns.push({ name: 'broker', title: getTxt('Collaboratore', '#8zmx3w0') });
        columns.push({ name: 'creation_date', title: getTxt('Data Ricezione', '#0tja6u7') });
        columns.push({ name: 'ins_contract_date', title: getTxt('Decorrenza Contratto') });
        if (authInfo.isAgencyAdministrator() || authInfo.isMasterBroker()) {
            columns.push({ name: 'insurance_request_id', title: getTxt('Actions') });
        }

        createGridResizerCols(this.state, columns, {}, 250);

        return (

            <div className={classes.root}>
                <LinearProgressEx loading={this.props.data.loading} />

                {!this.props.noCaption && <RegularCard

                    headerColor="red"
                    cardTitle={caption}
                    cardSubtitle={subtitle}
                    content={
                        <div>
                            {<Button color="primary" className={classes.button}
                                //onClick={() => { this.setState({ menuOpen: true, anchorEl: event.currentTarget }); }}

                                onClick={this.newClick}
                            >{getTxt('Aggiungi nuova richiesta')}</Button>}
                        </div>

                    }
                />}

                <Menu
                    id="simple-menu"
                    anchorEl={this.state.addNewMenuAnchor}
                    open={this.state.addNewMenuOpen}
                    onClose={() => { this.setState({ addNewMenuOpen: false }); }}
                >
                    <NewRequestMenuItems history={this.props.history} customerId={authInfo.loginInfo.userInfo.user_id} />
                </Menu>

                <Menu
                    id="simple-menu"
                    anchorEl={this.state.anchorEl}
                    open={this.state.menuOpen}
                    onClose={this.handleRequestClose}
                >
                    <MenuItem className={classes.menuItem} disabled={true} >
                        {getTxt('Request for New Customer')}
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={() => { this.props.history.push('/customers/new/company/'); }}>{getTxt('Azienda')}</MenuItem>
                    <MenuItem onClick={() => { this.props.history.push('/customers/new/private/'); }}>{getTxt('Privato')}</MenuItem>

                    <MenuItem onClick={() => { this.props.history.push('/customers/new/prospect/private/'); }}>{getTxt('Prospect')}</MenuItem>

                    <Divider />
                    <MenuItem onClick={() => { this.props.history.push('/customers'); }}><Icons.Search />{getTxt('Cerca Cliente')}</MenuItem>

                </Menu>


                <Grid

                    rows={requests}
                    columns={columns}

                >
                    <FilteringState
                        defaultFilters={[defFilter]}
                    />

                    <SortingState
                        defaultSorting={[
                            { columnName: 'creation_date', direction: 'desc' },
                        ]}
                    />

                    <IntegratedFiltering
                        columnExtensions={[
                            { columnName: 'broker', predicate: filterByDisplayName },
                            { columnName: 'customer', predicate: filterByDisplayName },
                        ]}
                    />
                    
                    <IntegratedSorting
                        columnExtensions={[
                            {
                                columnName: 'insurance_type_cd',
                                compare: (a, b) => {
                                    const labelA = getLabel(DictionaryTypes.InsuranceType, a);
                                    const labelB = getLabel(DictionaryTypes.InsuranceType, b);
                                    if (labelA === labelB) {
                                        return 0;
                                    }
                                    return (labelA < labelB) ? -1 : 1;
                                },
                            },
                            {
                                columnName: 'customer',
                                compare: (a, b) => {
                                    const labelA = a.display_name;
                                    const labelB = b.display_name;
                                    if (labelA === labelB) {
                                        return 0;
                                    }
                                    return (labelA < labelB) ? -1 : 1;
                                },
                            },
                            {
                                columnName: 'state_cd',
                                compare: (a, b) => {
                                    const labelA = getLabel(DictionaryTypes.InsuranceRequestState, a);
                                    const labelB = getLabel(DictionaryTypes.InsuranceRequestState, b);
                                    if (labelA === labelB) {
                                        return 0;
                                    }
                                    return (labelA < labelB) ? -1 : 1;
                                },
                            },
                            {
                                columnName: 'broker',
                                compare: (a, b) => {
                                    const labelA = a.display_name;
                                    const labelB = b.display_name;
                                    if (labelA === labelB) {
                                        return 0;
                                    }
                                    return (labelA < labelB) ? -1 : 1;
                                },
                            },
                            {
                                columnName: 'creation_date',
                                compare: (a, b) => {
                                    try {
                                        const labelA = new Date(a);
                                        const labelB = new Date(b);
                                        if (labelA === labelB) {
                                            return 0;
                                        }
                                        return (labelA < labelB) ? -1 : 1;
                                    } catch (e) {
                                        console.error('sort in grid by dates');
                                        return 0;
                                    }

                                },
                            },
                            {
                                columnName: 'ins_contract_date',
                                compare: (a, b) => {
                                    try {
                                        const labelA = new Date(a);
                                        const labelB = new Date(b);
                                        if (labelA === labelB) {
                                            return 0;
                                        }
                                        return (labelA < labelB) ? -1 : 1;
                                    } catch (e) {
                                        console.error('sort in grid by dates');
                                        return 0;
                                    }

                                },
                            },
                            
                        ]}
                    />

                    <VirtualTable
                        messages={tableMessages}
                        cellComponent={({ row, column, style, ...props }) => {
                            if (column.name === 'insurance_type_cd') {
                                return (<VirtualTable.Cell> <Link style={{ padding: '6px', color: '#9C27B0', fontWeight: 'bold' }} to={`/requests/${row.insurance_type_cd}/view/${row.insurance_request_id}`} >{getLabel(DictionaryTypes.InsuranceType, row.insurance_type_cd) + ' ' + row.insurance_request_id}</Link> </VirtualTable.Cell>);
                            }
                            if (column.name === 'broker') {
                                return (<VirtualTable.Cell><Link to={`/brokers/${row.customer.broker.broker_id}`} >{row.customer.broker.display_name}</Link></VirtualTable.Cell>);
                            }
                            if (column.name === 'customer') {
                                return (<VirtualTable.Cell> <Link to={`/customers/${row.customer.customer_type_cd.toLowerCase()}/${row.customer.customer_id}`} >{row.customer.display_name}</Link> </VirtualTable.Cell>);
                            }
                            if (column.name === 'state_cd') {
                                return (<VirtualTable.Cell> {getLabel(DictionaryTypes.InsuranceRequestState, row.state_cd)} </VirtualTable.Cell>);
                            }
                            if (column.name === 'creation_date') {
                                return (<VirtualTable.Cell>
                                    {row.creation_date ? toItalianDateString(row.creation_date) : row.creation_date}
                                </VirtualTable.Cell>);
                            }
                            if (column.name === 'ins_contract_date') {
                                return (<VirtualTable.Cell>
                                    {row.ins_contract_date ? toItalianDateString(row.ins_contract_date) : row.ins_contract_date}
                                </VirtualTable.Cell>);
                            }
                            
                            if (column.name === 'insurance_request_id') {
                                return (<VirtualTable.Cell>

                                    <Tooltip title={getTxt('Delete')}>
                                        <IconButton size="small" onClick={() => { this.setState({ delDlgOpen: true, delId: row.insurance_request_id }); }} className={classes.inGridButton} >
                                            <Icon.Delete />
                                        </IconButton>
                                    </Tooltip>

                                </VirtualTable.Cell>);
                            }

                            return <VirtualTable.Cell {...props} />;
                        }}
                    />

                    <TableFilterRow
                        messages={filterRowMessages}
                        cellComponent={({ column, filter, onFilter, ...props }) => {
                            if (column.name === 'insurance_type_cd') {
                                return (
                                    <c.gridColFilter noFilterVal={getTxt('Tutti')} setFilter={onFilter} >
                                        {getDictionary(DictionaryTypes.InsuranceType)}
                                    </c.gridColFilter>);
                            }
                            if (column.name === 'state_cd') {
                                return (
                                    <c.gridColFilter noFilterVal={getTxt('Tutti')} setFilter={onFilter} 
                                        v={(this.props.match && this.props.match.params.filter === 'received') ? 'RECIEVED' : undefined} 
                                    >
                                        {getDictionary(DictionaryTypes.InsuranceRequestState)}
                                    </c.gridColFilter>);
                            }

                            return <TableFilterRow.Cell onFilter={onFilter} filter={filter} {...props} />;
                        }}
                    />
                    <TableColumnResizing
                        columnWidths={this.state.columnWidths}
                        onColumnWidthsChange={(cols) => { this.setState({ columnWidths: cols }); }}
                        defaultColumnWidths={this.state.defColumnWidths}
                    />
                    <TableHeaderRow showSortingControls />

                </Grid>

                <Dialog onClose={() => { this.setState({ delId: undefined, delNumber: undefined }); }} open={this.state.delId}>
                    <DialogTitle >{getTxt('Delete Request')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <span>{getTxt('Request will be delete on server.')}</span>
                            <br />
                            <span >{getTxt('Are you sure do you want delete the Request?')}</span>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleDelete(this.state.delId)} color="primary">
                            {getTxt('Ok')}
                        </Button>
                        <Button onClick={() => { this.setState({ delId: undefined }); }} color="primary">
                            {getTxt('Annula')}
                        </Button>

                    </DialogActions>
                </Dialog>

            </div >
        );
    }


}

export default compose(

    graphql(deleteRequestMutation, {
        name: 'deleteRequest',
        options: {

            errorPolicy: 'all',
        },
    }),

    graphql(allRequestsQuery, {

        options: {
            options: props => (
                
                    
                {   
                    
                    variables:
                    {
                        customerId: props.customerId,
                        broker_id: props.brokerId,
                        stateFilter: (this.props.match && this.props.match.params.filter === 'received') ? 'RECIEVED' : null,
                    },
                    fetchPolicy: 'network-only',
                    errorPolicy: 'all',
                }),


        },
    }),

)(withStyles(styles)(Request_Search));
