import React, { Component } from 'react';
import { getTxt } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import { Paper } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { AppBar } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { TextField } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';

import * as Icon from '@material-ui/icons';
import { Checkbox } from '@material-ui/core';
import GridEx from 'components/form/GridEx';
import { Button } from '@material-ui/core';
import moment from 'moment';
import { Badge } from '@material-ui/core';

import ComponentEx from 'components/ComponentEx';
import { p } from 'components/form/Proto';

import { LinearProgress } from '@material-ui/core';
import { graphql, compose } from 'react-apollo';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';

import c from '../../components/form/Components';
import LinearProgressEx from '../../components/LinearProgressEx';
import { getLabel, DictionaryTypes, getDictionary } from '../../global/dictionaryCache';
import MaterialForm from '../../components/form/MaterialForm';
import MFormText from '../../components/form/MFormText';
import { quoteByIdQery, updateQuoteMutation, allQuotesQuery } from '../../modules/quote/quoteQeries';

let history;

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing.unit * 2,
        margin: theme.spacing.unit * 2,
    },
});


class Quote_Edit extends ComponentEx {

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.formChanged = this.formChanged.bind(this);
    }

    state = {
        open: false,
        estimated_amount: 0,
        agency_fee_amount: 0,
        total_amount: 0,
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleRequestClose = () => {
        this.setState({ open: false });
    };

    onSubmit(e, { obj, showErrors, hideErrors }) {
        const originalQuote = this.props.data.quoteById;
        obj.insurance_quote_id = originalQuote.insurance_quote_id;
        //obj.insurance_company_id = originalQuote.insurance_company_id;
        obj.insurance_request_id = this.props.match.params.requestId ? this.props.match.params.requestId : this.props.requestId;

        this.props.updateQuote({
            variables: { quote: obj },
            refetchQueries: [
                {
                    query: allQuotesQuery,
                },
                {
                    query: quoteByIdQery,
                    variables: { id: obj.insurance_quote_id },
                },
            ],
        }, this).then(
            (resp) => {
                hideErrors();
                if (resp.data.updateQuoteMutation &&
                    resp.data.updateQuoteMutation.validation &&
                    resp.data.updateQuoteMutation.validation.length > 0) {
                    showErrors(resp.data.updateQuoteMutation.validation);
                } else {
                    history.goBack();
                }
            });
    }

    formChanged(field, value, type, prevVal) {
        if (field === 'estimated_amount') {
            this.setState({ estimated_amount: value });
        }
        if (field === 'agency_fee_amount') {
            this.setState({ agency_fee_amount: value });
        }
    }

    render() {

        const { classes } = this.props;

        const loading = this.props.data.loading;
        let quote = this.props.data.quoteById;
        history = this.props.history;
        let caption;

        let hasIdParam;
        if (this.props.id) {
            hasIdParam = true;
        }
        if (this.props.match.params && this.props.match.params.id) {
            hasIdParam = true;
        }

        if (hasIdParam) {
            caption = 'Modifica Quotazione';
        } else {
            caption = 'Nuovo Quotazione';
        }
        if (quote) {
            quote = { ...quote };
        }

        if (quote) {
            if (!this.state.estimated_amount && !this.state.agency_fee_amount) {
                this.state.estimated_amount = quote.estimated_amount;
                this.state.agency_fee_amount = quote.agency_fee_amount;
            }
            quote.total_amount = (this.state.estimated_amount ? this.state.estimated_amount : 0) +
                (this.state.agency_fee_amount ? this.state.agency_fee_amount : 0);
            this.state.total_amount = quote.total_amount;
        }
        return (


            <div className={classes.root}>




                <Typography variant="h4" color="inherit" noWrap={false}>{getTxt(caption)}</Typography>
                <LinearProgressEx loading={loading} />

                {!loading && quote &&
                    <div>
                        <MaterialForm dataSource={quote} objType="insurance_quote" onSubmit={this.onSubmit} onChange={this.formChanged} >

                            <c.field l="Compagnia" f="insurance_company_id" ui="company_select" no_deleted />


                            <c.field l="Importo del Preventivo" f="estimated_amount" />
                            <c.field l="Competenze" f="agency_fee_amount" />
                            <c.field l="Totale" f="total_amount" v={this.state.total_amount} forseRefresh d />
                            <c.field l="Confermato" f="is_confirmed" />
                            <c.field l="Data Conferma" f="date_confirmed" />
                            <c.field l="Note" f="notes" sm={12} multiline />

                            <c.formButtons sm={12} onCancel={() => { this.props.history.goBack(); }} />
                        </MaterialForm>

                    </div>
                }

            </div>




        );
    }
}




export default compose(
    graphql(quoteByIdQery, {
        options: props => (
            {
                variables: {
                    id: (!props.id ? (props.match.params.id ? props.match.params.id : 'new') : props.id),
                },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }
        ),
    }),
    graphql(updateQuoteMutation, {
        name: 'updateQuote',
        options: {
            errorPolicy: 'all',
          
        },
    }),
)(withStyles(styles)(Quote_Edit));
