import ComponentEx from 'components/ComponentEx';
import { getTxt } from 'global/appGlobal';
import { Dialog, DialogContent } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { EntityTypes, getEntityDescriptor } from 'global/entityDescriptors';
import { compose, graphql } from 'react-apollo';
import LinearProgressEx from '../../components/LinearProgressEx';
import c from '../../components/form/Components';
import MaterialForm from '../../components/form/MaterialForm';
import { DictionaryTypes, getDictionary, getLabel } from '../../global/dictionaryCache';
import { metadataQuery } from '../../home/Home';
import { authInfo, Roles } from '../../global/auth';
import GridEx from 'components/form/GridEx';

import * as queries from '../../modules/broker/brokerQueries';
import { Button } from '@material-ui/core';
import { QueuePlayNext } from '@material-ui/icons';


let history;
let AfterSubmit;
const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing.unit * 2,
        margin: theme.spacing.unit * 2,
    },
    formButtons: {
        float: 'right',
        margin: '15px',
    },
    button: {
        margin: theme.spacing.unit,
    },
});


class Broker_Free_Item_Edit extends ComponentEx {

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);

    }

    state = {
        open: false,
    };

    formSetValues = { func: undefined };



    onSubmit(e, { obj, showErrors, hideErrors }) {
        const original = this.props.data.brokerFreeItem;
        obj.broker_free_item_id = original.broker_free_item_id;

        this.props.updaterFreeItem({
            variables: { brokerFreeItem: obj },
            refetchQueries: [

                {
                    query: queries.brokerFreeItemByIdQuery,
                    variables: { id: this.props.id },
                },
                {
                    query: queries.brokerBalanceQuery,
                    variables: {
                        i: Date.now(),
                        brokerId: this.props.data.brokerFreeItem.broker_id,
                    },
                },
            ],
        }, this).then(
            async (resp) => {
                hideErrors();
                if (resp.data.updateBrokerFreeItemMutation &&
                    resp.data.updateBrokerFreeItemMutation.validation &&
                    resp.data.updateBrokerFreeItemMutation.validation.length > 0) {
                    showErrors(resp.data.updateBrokerFreeItemMutation.validation);
                } else {
                    AfterSubmit(resp.data.updateBrokerFreeItemMutation.id);
                   
                    
                }
            });
    }

    render() {

        const { classes } = this.props;

        const loading = this.props.data.loading;
        const item = this.props.data.brokerFreeItem;
        history = this.props.history;
        AfterSubmit = this.props.AfterSubmit;

        const caption = (item && item.broker_free_item_id !== '-1') ? getTxt('Modifica Movimento Libero') : getTxt('Nuovo Movimento Libero');

        let allowBrokerSelect = false;
        if (authInfo.isAgencyAdministrator() || authInfo.isMasterBroker()) {
            allowBrokerSelect = true;
        }
        if (this.props.brokerId) {
            allowBrokerSelect = false;
        }
        if (item && item.broker_free_item_id !== '-1') {
            allowBrokerSelect = false;
        }

        return (

            <Dialog onClose={() => { this.props.AfterSubmit(); }} open >
                <DialogContent>
                    <LinearProgressEx loading={loading} />


                    <Typography variant="h4" color="inherit" noWrap={false}>{caption}</Typography>

                    {item &&

                        <MaterialForm
                            dataSource={item}
                            objType={EntityTypes.BrokerFreeItem}
                            onSubmit={this.onSubmit}
                        >
                            {!this.props.brokerId && <c.field f="broker_id" d={!allowBrokerSelect} ui="broker_select" no_deleted />}
                            {this.props.brokerId && <c.field f="broker_id" d={!allowBrokerSelect} ui="broker_select" v={this.props.brokerId} no_deleted />}

                        
                            <c.field l={getTxt('Data')} f="payment_date" />
                            <c.field l={getTxt('Note')} f="payment_notes" />
                            <c.field f="payment_amount" />
                            <c.field f="provvigioni" />
                            
                            <GridEx item sm={12} xs={12}>
                                <div className={classes.formButtons} >
                                    <Button
                                        className={classes.button}
                                        style={{ marginRight: 10 }}
                                        variant="contained"
                                        onClick={() => {
                                            this.props.AfterSubmit();
                                        }}
                                    >{getTxt('Annulla')}</Button>
                                    <Button
                                        color="primary"
                                        className={classes.button}
                                        type="submit"
                                        variant="contained"
                                    > {getTxt('Next')}</Button>
                                </div>

                            </GridEx>

                        </MaterialForm>

                    }

                </DialogContent>

            </Dialog>

        );
    }
}

export default compose(

    graphql(queries.brokerFreeItemByIdQuery, {
        options: props => (
            {
                variables: {
                    id: props.id ? props.id : 'new',
                },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }
        ),
    }),

    graphql(queries.updateBrokerFreeItemMutation, {
        name: 'updaterFreeItem',
        options: {
            errorPolicy: 'all',
            fetchPolicy: 'no-cache',
        },
    }),

)(withStyles(styles)(Broker_Free_Item_Edit));
