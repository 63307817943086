import React from 'react';
import { getTxt } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';
import { authInfo } from 'global/auth';

import GridEx from 'components/form/GridEx';

import { Button } from '@material-ui/core';

import ComponentEx from 'components/ComponentEx';

const styles = theme => ({
    button: {
        margin: theme.spacing.unit,
    },
    formButtons: {
        float: 'right',
        margin: '15px',
    },
});

class RequestFormButtons extends ComponentEx {

    render() {

        const { classes } = this.props;

        return (

            <GridEx item sm={12} xs={this.props.xs || 12}>
                <div className={classes.formButtons} >
                    {!this.props.noSave && <Button
                        color="primary"
                        className={classes.button}
                        type="submit"
                        variant="contained"
                    >{getTxt('Salva')}</Button>}
                    {this.props.transferToContract && !authInfo.isCustomer() &&
                        <Button
                            className={classes.button}
                            variant="contained"
                            type="submit"
                            onClick={() => {
                                this.props.transferToContract();
                            }}
                        >{getTxt('Save and transfer to Contract')}
                        </Button>
                    }
                    <Button
                        className={classes.button}
                        variant="contained"
                        onClick={() => {
                            this.props.history.goBack();
                        }}
                    >{getTxt('Annulla')}</Button>
                </div>

            </GridEx>
        );
    }
}

export default withStyles(styles)(RequestFormButtons);
