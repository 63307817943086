import 'babel-polyfill';
import ReactDOM from 'react-dom';
import React from 'react';
import App from 'app/app';
import 'assets/css/material-dashboard-react.css';



ReactDOM.render((       
    <App />
), document.getElementById('root'));
