import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { toDecimalString } from 'global/appGlobal';

const CurrencyGridFormatter = props => (
    <DataTypeProvider
        formatterComponent={({ value }) => toDecimalString(value)}
        {...props}
    />
);

export default CurrencyGridFormatter;
