import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { getTxt } from 'global/appGlobal';

import { graphql, compose } from 'react-apollo';


import c from '../../components/form/Components';
import LinearProgressEx from '../../components/LinearProgressEx';

import MaterialForm from '../../components/form/MaterialForm';


import { Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import { allEmailTemplatesQuery, emailTmplateByIdQuery, updateEmailTemplateMutation } from '../email-templates/emailTemplatesQueries';

import { EntityTypes } from '../../global/entityDescriptors';
import { getConfigValue } from '../../global/appGlobal';




const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing.unit * 2,
        margin: theme.spacing.unit * 2,
    },

    button: {
        margin: theme.spacing.unit,
    },

    badge: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        marginTop: 0,
    },

    gridRoot: {
        height: '100%',
    },


    formCaption: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit * 2,
        width: '100%',
        position: 'relative',
        height: 40,
    },
    formCaptionLabel: {
        position: 'absolute',
        backgroundColor: 'transparent',
        left: 25,
        paddingLeft: 10,
        paddingRight: 10,
    },

    rootPaper: theme.mixins.gutters({

        paddingTop: 0,
        paddingBottom: 0,
        marginTop: 0,// theme.spacing.unit * 3,

    }),
});


class Email_Template_Edit extends Component {

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.fornChanged = this.fornChanged.bind(this);
    }

    state = {};

    onSubmit(e, { obj, showErrors, hideErrors }) {

        obj.email_template_id = this.props.data.emailTemplate.email_template_id;
        this.props.updateTemplate({
            variables: { template: obj },
            refetchQueries: [

                {
                    query: emailTmplateByIdQuery,
                    variables: { id: this.props.match.params.id },
                },
                {
                    query: allEmailTemplatesQuery,
                },
            ],
        }, this).then(
            async (resp) => {
                hideErrors();
                const serverErrors = resp.data.updateEmailTemplate.validation;


                if (serverErrors && serverErrors.length > 0) {
                    showErrors(serverErrors);
                } else {

                    const id = resp.data.updateEmailTemplate.id;
                    if (id) {
                        this.props.history.goBack();
                        //                  window.showPopupInfo({
                        //                    variant: 'email_template_changed_ok',
                        //              });
                    }



                }
            });
    }

    fornChanged(field, value, type, prevVal) {

    }

    render() {

        const { classes } = this.props;
        const loading = this.props.data.loading;
        const item = this.props.data.emailTemplate;

        const caption = getTxt('Edit Email Template');
        const fromFromConfig = getConfigValue('email_from');

        const this1 = this;

        return (

            <div className={classes.root}>
                <Typography variant="h4" color="inherit" style={{ whiteSpace: 'normal' }}>{getTxt(caption)}</Typography>
                <LinearProgressEx loading={loading || this.state.loading} />
                {item &&

                    <MaterialForm dataSource={item} objType={EntityTypes.EmailTemplate} onSubmit={this.onSubmit} onChange={this.fornChanged}>

                        <c.field f="template_name" />
                        <c.field f="subject" />

                        <c.field f="body" sm={12} multiline />
                        <c.field f="from" v={item.from ? item.from : fromFromConfig} />


                        <c.formButtons sm={12} onCancel={() => { this.props.history.goBack(); }} />
                    </MaterialForm>

                }

            </div>

        );
    }
}

export default compose(
    graphql(emailTmplateByIdQuery, {
        options: props => ({ variables: { id: props.match.params.id ? props.match.params.id : -1 } }),
    }),
    graphql(updateEmailTemplateMutation, {
        name: 'updateTemplate',
        options: {
            errorPolicy: 'all',
        },
    }),
)(withStyles(styles)(Email_Template_Edit));
