import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from 'components/CustomButtons/Button';
import { getTxt } from 'global/appGlobal';




const styles = theme => ({
    root: {
        whiteSpace: 'pre-wrap !important',
    },
    btn: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        backgroundColor: themeColor,
    },
});

class CmdBarCustomBtns extends Component {

    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    componentWillMount() {

    }

    onClick(b, e) {
        if (this.props.onClick) {
            this.props.onClick(b, e);
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root} >








                {

                    this.props.children.map(b => (


                        <Button round color="primary" className={classes.btn} variant="contained" key={b} onClick={(e) => { this.onClick(b, e); }}>{getTxt(b)}</Button>



                    ))

                }

            </div>
        );
    }
}


export default withStyles(styles, { withTheme: true })(CmdBarCustomBtns);