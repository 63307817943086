import React, { Component } from 'react';
import { getTxt, toItalianDateString, toDecimalString } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { Paper } from '@material-ui/core';
import { graphql, compose } from 'react-apollo';
import { AppBar } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { TextField } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import * as Icon from '@material-ui/icons';
import { Checkbox } from '@material-ui/core';
import GridEx from 'components/form/GridEx';
import { Button } from '@material-ui/core';
import moment from 'moment';
import { Badge } from '@material-ui/core';
import { p } from 'components/form/Proto';
import $ from 'jquery';
import { TableCell } from '@material-ui/core';
import CurrencyGridFormatter from 'components/CurrencyGridFormatter';
import { HashRouter, BrowserRouter, Route, Link, withRouter } from 'react-router-dom';
import {
    SortingState, SelectionState, FilteringState, GroupingState, DataTypeProvider,
    IntegratedFiltering, IntegratedGrouping, IntegratedSorting, IntegratedSelection, RowDetailState
} from '@devexpress/dx-react-grid';
import {
    Grid, Table,
    VirtualTable, TableHeaderRow, TableFilterRow, TableSelection, TableGroupRow,
    GroupingPanel, DragDropProvider, TableColumnReordering, Toolbar, TableRowDetail, TableColumnResizing,
} from '@devexpress/dx-react-grid-material-ui';
import { } from '@devexpress/dx-react-grid';
import LinearProgressEx from '../../components/LinearProgressEx';
import * as queries from '../../modules/contract/contractQueries';
import { getLabel, DictionaryTypes } from '../../global/dictionaryCache';
import GridDetails_Contract_Item from '../../modules/contract/GridDetails_Contract_Item';
import ComponentEx from '../../components/ComponentEx';
import { tableMessages, createGridResizerCols } from '../../global/appGlobal';
import { Tooltip, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { authInfo } from 'global/auth';

let history;

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    rootAsChildControl: {
        backgroundColor: theme.palette.background.paper,
    },
    button: {
        margin: theme.spacing.unit,
    },

    badge: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        marginTop: 0,

    },
    gridRoot: {
        height: '100%',
    },

    tableHeader: {
        backgroundColor: theme.palette.background.paper,
    },

    guarantyList: {
        marginBottom: theme.spacing.unit * 5,
    },
});

const GridDetailContainerBase = ({ row, classes }, base_ins_type) => (
    <GridDetails_Contract_Item
        id={row.contract_item_id}
        contractId={row.insurance_contract_id}
        history={history}
        insType={row.insType}
        base_ins_type={base_ins_type}
    />
);

const getRowId = row => row.contract_item_id;

class Contract_Item_Search extends ComponentEx {

    constructor() {
        super();
        this.handleDelete = this.handleDelete.bind(this);
        this.setDataPerfezionamentoInContractItemGrid = this.setDataPerfezionamentoInContractItemGrid.bind(this);
    }

    componentWillUnmount() {
        delete window.refetchContractList;
    }

    state = {
        expandedRowIds: [],
    };

    handleDelete(id) {

        this.props.deleteContractItem({
            variables: { contractItemId: this.state.delId },
            refetchQueries: [
                {
                    query: queries.allContractsItemsQuery,
                    variables: { contractId: this.props.contractId },
                },
            ],
        }).then(
            (resp) => {

                //data.refetch();
            }
        );
        this.setState({ delDlgOpen: false });
    }

    setDataPerfezionamentoInContractItemGrid(dp) {
        //this.props.contract.data_perfezionamento = dp;
        //this.setState({ data_perfezionamento: dp });
    }

    render() {

        window.setDataPerfezionamentoInContractItemGrid = this.setDataPerfezionamentoInContractItemGrid;

        this.state.data_perfezionamento = this.props.contract.data_perfezionamento;

        let columns = [

            { name: 'item_type_cd', title: getTxt('Tipo') },
            { name: 'item_start_date', title: getTxt('Start Date') },
            { name: 'item_end_date', title: getTxt('End Data') },

            { name: 'amount_netto', title: getTxt('Amount Netto') },

            { name: 'total_amount_client', title: getTxt('Client Amount') },


            // { title: 'Autentica', name: 'autentica' },
            // { title: 'Spese', name: 'spese' },

            { name: 'item_payment_state_cd', title: getTxt('Is Paid') },
            { name: 'state_cd', title: getTxt('Stato') },
            { name: 'data_perfezionamento', title: getTxt('Data Perfezionamento') },
            { name: 'broker_id', title: getTxt('Broker') },

        ];

        if (authInfo.isAgencyAdministrator() || authInfo.isMasterBroker()) {
            columns = [{ name: 'contract_item_id', title: getTxt('Actions') }, ...columns];
        }

        const tableColumnExtensions = [
            { columnName: 'amount_netto', align: 'right' },
            { columnName: 'total_amount_client', align: 'right' },
        ];

        columns.forEach((c) => {
            c.columnName = c.name;
            c.width = 130;
        });


        const insType = this.props.insType ? this.props.insType : this.props.match.params.insType;
        const { classes } = this.props;
        const itemsOrig = this.props.data.contract_items ? this.props.data.contract_items : [];


        const items = [];
        for (let i = 0; i < itemsOrig.length; i += 1) {
            const item = { ...itemsOrig[i], insType };
            items.push(item);
        }

        history = this.props.history;

        window.refetchContractList = this.props.data.refetch;


        let expanded = this.state.expandedRowIds;
        if (expanded.length === 0 && items.length > 0) {

            expanded = [items[0].contract_item_id];


        }

        return (

            <div className={this.props.noAddBtn ? classes.rootAsChildControl : classes.root}>
                <LinearProgressEx loading={this.props.data.loading} />


                <div className={classes.guarantyList} />
                {!this.props.noAddBtn && <Button
                    color="primary"
                    className={classes.button}
                    onClick={() => { this.props.history.push(`/contract/${insType}/${this.props.contractId}/item/new`); }}
                >
                    {getTxt('Add Contract Movimento')}
                </Button>}

                {!this.props.noAddBtn && <Button
                    color="primary"
                    className={classes.button}
                    onClick={() => { this.props.history.push(`/contract/${insType}/${this.props.contractId}/quietanza/new`); }}
                >
                    {getTxt('Nuova Quietanza')}
                </Button>}



                <Grid

                    rows={items}
                    columns={columns}
                    getRowId={getRowId}
                >

                    {/* <CurrencyGridFormatter
                        for={['amount_netto', 'total_amount_client']}
                    /> */}

                    <RowDetailState
                        expandedRowIds={expanded}
                        onExpandedRowIdsChange={(expandedIds) => {
                            this.setState({ expandedRowIds: expandedIds });
                        }}
                    />
                    <Table
                        messages={tableMessages}
                        columnExtensions={tableColumnExtensions}
                        cellComponent={({ row, column, style, ...props }) => {
                            const isFinancial = row.contract_item_type.is_financial === 'true';
                            const isNegative = row.contract_item_type.is_negative === 'true';

                            if (column.name === 'data_perfezionamento') {
                                return (<VirtualTable.Cell>
                                    {(row.data_perfezionamento ? toItalianDateString(row.data_perfezionamento) : '')}
                                </VirtualTable.Cell>);
                            }

                            if (column.name === 'contract_item_id' && row.broker_id !== -1) {
                                return (<VirtualTable.Cell>

                                    {(row.item_type_cd !== 'Polizza') && <Tooltip title={getTxt('Delete')}>
                                        <IconButton
                                            size="small"
                                            onClick={() => { this.setState({ delDlgOpen: true, delId: row.contract_item_id }); }}
                                            className={classes.inGridButton}
                                        >
                                            <Icon.Delete />
                                        </IconButton>
                                    </Tooltip>}

                                </VirtualTable.Cell>);
                            }

                            if (column.name === 'item_type_cd') {
                                return (
                                    <Table.Cell>
                                        <b>
                                            {row.contract_item_type.label}
                                        </b>

                                    </Table.Cell>);
                            }
                            if (column.name === 'item_start_date') {
                                return (
                                    <Table.Cell>
                                        {row.item_start_date ? toItalianDateString(row.item_start_date) : row.item_start_date}
                                    </Table.Cell>);
                            }
                            if (column.name === 'item_end_date') {
                                return (
                                    <Table.Cell>
                                        {row.item_end_date ? toItalianDateString(row.item_end_date) : row.item_end_date}
                                    </Table.Cell>);
                            }
                            if (column.name === 'item_payment_state_cd') {
                                return (
                                    <Table.Cell>
                                        {isFinancial && getLabel(DictionaryTypes.InsuranceItemPaymentState, row.item_payment_state_cd)}
                                        {!isFinancial && ''}
                                    </Table.Cell>);
                            }
                            if (column.name === 'state_cd') {
                                return (
                                    <Table.Cell>
                                        {getLabel(DictionaryTypes.InsuranceItemState, row.state_cd)}
                                    </Table.Cell>);
                            }
                            if (column.name === 'broker_id') {
                                return (<Table.Cell>
                                    <Link to={`/brokers/${row.broker.broker_id}`}>{row.broker.display_name}</Link>
                                </Table.Cell>);
                            }

                            if (column.name === 'total_amount_broker') {
                                return (<Table.Cell>
                                    {isFinancial && toDecimalString(row.total_amount_broker)}
                                    {!isFinancial && ''}
                                </Table.Cell>);
                            }
                            if (column.name === 'amount_netto') {
                                // if (!isFinancial) {
                                //     return (<Table.Cell>{''}</Table.Cell>);
                                // }
                                // if (row.amount_netto === 0) {
                                //     return (<Table.Cell style={{ textAlign: 'right' }} >{toDecimalString(0, true)}</Table.Cell>);
                                // }
                                if (isNegative) {
                                    return (<Table.Cell style={{ textAlign: 'right' }}>{toDecimalString('-' + row.amount_netto, true)}</Table.Cell>);
                                }

                                return (<Table.Cell style={{ textAlign: 'right' }}>
                                    {toDecimalString(row.amount_netto, true)}
                                </Table.Cell>);

                            }
                            if (column.name === 'total_amount_agency') {
                                return (<Table.Cell>
                                    {isFinancial && toDecimalString(row.total_amount_agency, true)}
                                    {!isFinancial && ''}
                                </Table.Cell>);
                            }
                            if (column.name === 'total_amount_client') {
                                // if (!isFinancial) {
                                //     return (<VirtualTable.Cell>{''}</VirtualTable.Cell>);
                                // }
                                // if (row.total_amount_client === 0) {
                                //     return (<Table.Cell>{toDecimalString(0, true)}</Table.Cell>);
                                // }
                                if (isNegative) {
                                    return (<Table.Cell style={{ textAlign: 'right' }}>{toDecimalString('-' + row.total_amount_client, true)}</Table.Cell>);
                                }
                                return (<Table.Cell style={{ textAlign: 'right' }}>
                                    {toDecimalString(row.total_amount_client, true)}
                                </Table.Cell>);
                            }
                            return <Table.Cell {...props} />;
                        }}
                    />

                    <TableRowDetail
                        contentComponent={(e) => GridDetailContainerBase(e, this.props.base_ins_type)}
                    />


                    <TableHeaderRow />
                </Grid>

                <Dialog onClose={() => { this.setState({ delDlgOpen: false, delId: null }); }} open={this.state.delDlgOpen}>
                    <DialogTitle>{getTxt('Delete contract movement.')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <span> {getTxt('Contract movement will be delete on server.')} </span>
                            <br />
                            <span > {getTxt('Are you sure do you want delete the contract movement ?')} </span>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleDelete(this.state.delId)} color="primary">
                            {getTxt('Ok')}
                        </Button>
                        <Button onClick={() => { this.setState({ delDlgOpen: false, delId: null }); }} color="primary">
                            {getTxt('Annula')}
                        </Button>

                    </DialogActions>
                </Dialog>

            </div>
        );
    }
}

export default compose(

    graphql(queries.allContractsItemsQuery, {
        options: props => (
            {
                variables: {
                    contractId: props.contractId,
                },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }
        ),
    }),

    graphql(queries.deleteContractItemMutation, {
        name: 'deleteContractItem',
        options: {
            errorPolicy: 'all',
        },
    }),

)(withRouter(withStyles(styles)(Contract_Item_Search)));
