import ComponentEx from 'components/ComponentEx';
import GridEx from 'components/form/GridEx';
import { getTxt, randomString } from 'global/appGlobal';
import { Checkbox } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Input } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { compose, graphql } from 'react-apollo';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import { Button } from '@material-ui/core';
import { get_Requests_ById_Query, allRequestsQuery } from '../../modules/request/requestQeries';
import LinearProgressEx from '../../components/LinearProgressEx';
import c from '../../components/form/Components';
import { DictionaryTypes, getDictionaryItem } from '../../global/dictionaryCache';
import { getEntityDescriptor } from '../../global/entityDescriptors';
import { metadataQuery } from '../../home/Home';
import * as queries from '../../modules/publicInsType/publicInsTypeQueries';

let history;
let AfterSubmit;

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing.unit * 2,
        margin: theme.spacing.unit * 2,
    },
    formRoot: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        width: '100%',
        paddingTop: theme.spacing.unit * 5,
    },
    textField: {
        width: '100%',
    },
    dialogText: {
        color: theme.palette.error.main,
    },
});


class Custom_Fields_Edit extends ComponentEx {

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
    }

    fields = {};

    state = {
        atLeastOneCheckDlgOpen: false,
    };



    componentWillReceiveProps(nextProps) {
        if (nextProps.data && nextProps.data.public_Ins_Type_By_Id) {
            this.fields = {};
            const item = nextProps.data.public_Ins_Type_By_Id;
            let insEntityDescr = {};
            const actualInsIypeItem = getDictionaryItem(DictionaryTypes.InternalInsuranceType, item.actual_ins_type_cd);
            insEntityDescr = getEntityDescriptor(actualInsIypeItem.entityType);

            const customFields = (item && item.custom_fields) ? JSON.parse(item.custom_fields) : [];
            const customFieldsMap = {};
            customFields.forEach((f) => {
                customFieldsMap[f.f] = f;
            });

            Object.keys(insEntityDescr).forEach((i) => {
                const originalField = insEntityDescr[i];
                if (originalField && originalField.id) {
                    if (originalField.custom === false) return;

                    const custom = customFieldsMap[i];

                    const stateObj = {};
                    const id = randomString(5, 'aA');
                    let isCustomField = custom ? true : false;
                    if (customFields.length === 0) {
                        isCustomField = true;
                    }
                    const label = custom && custom.l ? custom.l : originalField.label;
                    stateObj['used_' + id] = isCustomField;
                    stateObj['label_' + id] = label;
                    this.setState(stateObj);
                    this.fields[id] = { isCustomField, label, id, field: i };
                }
            }, this);
        }
    }


    onSubmit(e) {
        e.preventDefault();
        const original = this.props.data.public_Ins_Type_By_Id;
        const obj = { ...original };

        delete obj.custom_fields;
        delete obj.__typename;

        let hascustom = false;
        Object.keys(this.fields).map((i) => {
            const field = this.fields[i];
            if (field.id && field.isCustomField) {
                if (!obj.custom_fields) obj.custom_fields = [];

                obj.custom_fields.push({ f: field.field, l: field.label });
            }
        });



        if (obj.custom_fields) {

            obj.custom_fields.forEach((field) => {
                if (field.isCustomField) {
                    hascustom = true;
                }
            });
        }

        if (obj.custom_fields) {
            obj.custom_fields = JSON.stringify(obj.custom_fields);
        } else {
            //obj.custom_fields = null;
        }

        this.props.updateType({
            variables: { type: obj },
            refetchQueries: [
                {
                    query: queries.allTypesQuery,
                },
                {
                    query: queries.typeByIdQuery,
                    variables: { id: original.public_ins_type_id },
                },
                {
                    query: metadataQuery,
                },


            ],
        }, this).then(
            (resp) => {

                if (resp.data.update_public_Ins_Type_Mutation &&
                    resp.data.update_public_Ins_Type_Mutation.validation &&
                    resp.data.update_public_Ins_Type_Mutation.validation.length > 0) {

                } else {
                    history.goBack();

                }
            });
    }

    render() {

        const { classes } = this.props;

        const loading = this.props.data.loading;
        const item = this.props.data.public_Ins_Type_By_Id;
        history = this.props.history;
        //AfterSubmit = this.props.AfterSubmit;
        let caption = 'Insurance Type Fields';
        if (item) {
            caption = `${item.public_ins_type_name} - Used Fields`;
        }

        return (


            <div className={classes.root}>
                <LinearProgressEx loading={loading} />


                {!this.props.noCaption && <Typography variant="h4" color="inherit" noWrap={false}>{getTxt(caption)}</Typography>}

                {item &&


                    <div className={classes.formRoot}>
                        <form onSubmit={this.onSubmit} noValidate>
                            <Grid container spacing={this.props.spacing || 4}>
                                {Object.keys(this.fields).map((i) => {
                                    const checkId = 'used_' + i;
                                    const labelId = 'label_' + i;
                                    const setState = this.setState.bind(this);
                                    return (
                                        <GridEx item sm={this.props.sm || 4} xs={this.props.xs || 12} key={i} >

                                            <Input
                                                className={classes.textField}
                                                disabled={!this.state[checkId]}
                                                startAdornment={
                                                    <Checkbox
                                                        className={classes.checkBox}
                                                        onChange={(e, value) => {
                                                            const field = this.fields[i];
                                                            field.isCustomField = value;

                                                            let customExists = false;
                                                            Object.keys(this.fields).forEach((p) => {
                                                                const f = this.fields[p];
                                                                if (f && f.id && f.isCustomField) customExists = true;
                                                            });
                                                            if (customExists === false) {
                                                                setState({ atLeastOneCheckDlgOpen: true });
                                                                field.isCustomField = true;
                                                                return;
                                                            }

                                                            const setStateObj = {};
                                                            setStateObj[checkId] = value;
                                                            setState(setStateObj);
                                                        }}
                                                        checked={this.state[checkId]}
                                                        name={checkId}
                                                        key={i}
                                                    />
                                                }
                                                key={i}
                                                value={this.state[labelId]}
                                                onChange={(e) => {
                                                    const field = this.fields[i];
                                                    const value = e.target.value;
                                                    field.label = value;
                                                    const setStateObj = {};
                                                    setStateObj[labelId] = value;
                                                    setState(setStateObj);
                                                }}
                                            />

                                        </GridEx>
                                    );
                                }, this)}
                                <c.formButtons sm={12} onCancel={() => { this.props.history.goBack(); }} />
                            </Grid>
                        </form>

                        <Dialog
                            open={this.state.atLeastOneCheckDlgOpen}
                            onClose={() => {
                                this.setState({ atLeastOneCheckDlgOpen: false });
                            }}
                        >
                            <DialogTitle> <span className={classes.dialogText}> {getTxt('Insurance type without fields.', '#s8e0bcl')} </span></DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    <span className={classes.dialogText}> {getTxt(`At least one field have to be used.`, '#zt36id9')} </span>
                                </DialogContentText>

                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => { this.setState({ atLeastOneCheckDlgOpen: false }); }} color="primary" >
                                    Ok
                                </Button>

                            </DialogActions>
                        </Dialog>

                    </div>


                }

            </div>

        );
    }
}

export default compose(
    graphql(queries.typeByIdQuery, {
        options: props => (
            {
                variables: {
                    id: (!props.id ? (props.match.params.id ? props.match.params.id : 'new') : props.id),
                    customFields: true,
                },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }
        ),
    }),
    graphql(queries.updateTypeMutation, {
        name: 'updateType',
        options: {
            errorPolicy: 'all',
         
        },
    }),
)(withStyles(styles)(Custom_Fields_Edit));
