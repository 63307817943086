import React, { Component } from 'react';
import { getTxt } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import { graphql, compose } from 'react-apollo';
import { AppBar } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { TextField } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import * as Icon from '@material-ui/icons';
import { Checkbox } from '@material-ui/core';
import GridEx from 'components/form/GridEx';
import { Button } from '@material-ui/core';
import moment from 'moment';
import { Badge } from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import $ from 'jquery';
import { IconButton } from '@material-ui/core';
import { TableCell } from '@material-ui/core';
import { HashRouter, BrowserRouter, Route, Link, withRouter } from 'react-router-dom';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import {
    SortingState, SelectionState, FilteringState, GroupingState,
    IntegratedFiltering, IntegratedGrouping, IntegratedSorting, IntegratedSelection,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    VirtualTable, TableHeaderRow, TableFilterRow, TableSelection, TableGroupRow,
    GroupingPanel, DragDropProvider, TableColumnReordering, Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import CurrencyGridFormatter from 'components/CurrencyGridFormatter';
import LinearProgressEx from '../../components/LinearProgressEx';
import * as queries from '../../modules/company/CompanyQueries';
import { getLabel, DictionaryTypes } from '../../global/dictionaryCache';
import CompanyGuarantyEdit from '../../modules/company/CompanyGuarantyEdit';
import ComponentEx from '../../components/ComponentEx';
import { forEach } from 'async';
import { filterRowMessages, tableMessages } from '../../global/appGlobal';
import { authInfo } from 'global/auth';


const tableColumnExtensionsRigtAlign = [
    { columnName: 'default_broker_percent', align: 'right' },
    { columnName: 'default_agency_percent', align: 'right' },
];

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    rootAsChildControl: {
        backgroundColor: theme.palette.background.paper,
    },
    button: {
        margin: theme.spacing.unit,
    },

    badge: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        marginTop: 0,

    },
    gridRoot: {
        height: '100%',
    },

    tableHeader: {
        backgroundColor: theme.palette.background.paper,
    }
});

const toLowerCase = value => String(value).toLowerCase();

const filterInsCd = (row, e, a) => {
    const name = toLowerCase(a.guaranty_type.insurance_type_cd);
    return name.includes(toLowerCase(e.value));
};

const compareRamo = (a, b) => {


};

class CmpanyGuarantySearch extends ComponentEx {


    state = {
        delDlgOpen: false,
        delId: undefined,
        editId: undefined,
    };

    componentDidMount() {
        $("table[class*='Table-headTable']").addClass(this.props.classes.tableHeader);
    }

    handleDelete(id) {

        const data = this.props.data;
        this.props.deleteInsCompGuaranty({
            variables: { id: this.state.delId },
            refetchQueries: [
                {
                    query: queries.allInsCompGuaranty,
                    variables: { companyId: this.state.delId },
                },
            ],
        }).then(
            (resp) => {
                data.refetch();
            }
        );
        this.setState({ delDlgOpen: false });
    }

    render() {

        if (this.props.quiet) {
            return (<LinearProgressEx loading={this.props.data.loading} />);
        }

        const columns = [


            { name: 'insurance_type_cd', title: getTxt('Ramo Personalizzato') },
            { name: 'guaranty_name', title: getTxt('guaranty type') },
            { name: 'default_broker_percent', title: getTxt('default broker percent') },
            { name: 'default_agency_percent', title: getTxt('default agency percent') },

            


        ];

        if (authInfo.isAgencyAdministrator() || authInfo.isMasterBroker()) {
            columns.push({ name: 'insurance_company_guaranty_id', title: getTxt('Azioni') });
        }

        const { classes } = this.props;
        let items = this.props.data.allInsCompGuaranty ? this.props.data.allInsCompGuaranty : [];

        const withRamo = [];
        if (items.length > 0) {
            items.forEach((i) => {
                const wr = { ...i };
                wr.insurance_type_cd = i.guaranty_type.insurance_type_cd;
                wr.guaranty_name = i.guaranty_type.guaranty_name;
                withRamo.push(wr);
            });
            items = withRamo;
        }

        return (

            <div className={this.props.noAddBtn ? classes.rootAsChildControl : classes.root}>
                <LinearProgressEx loading={this.props.data.loading} />
                {!this.props.noAddBtn && (authInfo.isAgencyAdministrator() || authInfo.isMasterBroker()) && <Button
                    color="primary"
                    className={classes.button}
                    onClick={() => { this.setState({ editId: 'new' }); }}
                >
                    {getTxt('Add Company Guaranty')}
                </Button>}

                <Grid

                    rows={items}
                    columns={columns}

                >

                    <FilteringState
                        defaultFilters={[{}]}
                    />

                    <SortingState
                        defaultSorting={[

                        ]}
                        columnExtensions={[
                            { columnName: 'insurance_company_guaranty_id', sortingEnabled: false },

                        ]}
                    />

                    <IntegratedSorting columnExtensions={
                        [
                            //{ columnName: 'insurance_company_id', compare: compareRamo },
                        ]}
                    />

                    <IntegratedFiltering columnExtensions={[
                        { columnName: 'insurance_type_cd', predicate: filterInsCd },
                        // { columnName: 'customer_id', predicate: filterCustomer },
                        // { columnName: 'insurance_company_id', predicate: filterCompany },
                    ]}
                    />

                    <CurrencyGridFormatter
                        for={['default_broker_percent', 'default_agency_percent']}
                    />

                    <VirtualTable
                        messages={tableMessages}
                        columnExtensions={tableColumnExtensionsRigtAlign}
                        cellComponent={({ row, column, style, ...props }) => {

                            if (column.name === 'insurance_type_cd') {
                                return (
                                    <VirtualTable.Cell>
                                        {getLabel(DictionaryTypes.InsuranceType, row.insurance_type_cd)}

                                    </VirtualTable.Cell>);
                            }
                            if (column.name === 'guaranty_name') {
                                return (
                                    <VirtualTable.Cell>


                                        {row.guaranty_name}

                                    </VirtualTable.Cell>);
                            }
                            if (column.name === 'insurance_company_guaranty_id') {
                                return (<VirtualTable.Cell>

                                    <IconButton size="small" onClick={() => { this.setState({ delDlgOpen: true, delId: row.insurance_company_guaranty_id }); }} className={classes.inGridButton} >
                                        <DeleteIcon />
                                    </IconButton>


                                </VirtualTable.Cell>);
                            }

                            return <VirtualTable.Cell {...props} />;
                        }}
                    />

                    <TableFilterRow
                        messages={filterRowMessages}
                        cellComponent={({ column, filter, onFilter, ...props }) => {
                            if (column.name === 'insurance_company_guaranty_id') {
                                return (
                                    <TableFilterRow.Cell>
                                        <TextField margin="normal" style={{ margin: 0 }} disabled placeholder={getTxt('Filter...')} value={''} />
                                    </TableFilterRow.Cell>);
                            }


                            return <TableFilterRow.Cell onFilter={onFilter} filter={filter} {...props} />;
                        }}
                    />

                    <TableHeaderRow showSortingControls />

                </Grid>

                {
                    this.state.editId &&
                    <CompanyGuarantyEdit
                        id={this.state.editId}
                        companyId={this.props.companyId}
                        existing={items}

                        AfterSubmit={((id) => {
                            this.setState({ editId: undefined });
                            this.props.data.refetch();
                        })}

                    />
                }

                <Dialog onClose={() => { this.setState({ delDlgOpen: false }); }} open={this.state.delDlgOpen}>
                    <DialogTitle>{getTxt('Delete Guaranty type.')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <span> {getTxt('Company Guaranty Broker percent will be delete on server.')} </span>
                            <br />
                            <span > {getTxt('Are you sure do you want delete the Guaranty ?')} </span>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleDelete(this.state.delId)} color="primary">
                            {getTxt('Ok')}
                        </Button>
                        <Button onClick={() => { this.setState({ delDlgOpen: false }); }} color="primary">
                            {getTxt('Annula')}
                        </Button>

                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default compose(

    graphql(queries.allInsCompGuaranty, {
        options: props => (
            {
                variables: {
                    companyId: props.companyId,
                },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }
        ),
    }),

    graphql(queries.deleteInsCompGuarantyMutation, {
        name: 'deleteInsCompGuaranty',
        options: {
            errorPolicy: 'all',
        },
    }),

)(withRouter(withStyles(styles)(CmpanyGuarantySearch)));
