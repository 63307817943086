import React, { Component } from 'react';
import { getTxt, randomString } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';
import { withTheme } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import gql from 'graphql-tag';
import { graphql, compose } from 'react-apollo';
import * as Icon from '@material-ui/icons';
import ComponentEx from 'components/ComponentEx';
import TabContainer from 'components/TabContainer';
import NavPills from 'components/NavPills/NavPills';
import c from '../../components/form/Components';
import LinearProgressEx from '../../components/LinearProgressEx';
import $ from 'jquery';
import { EntityTypes } from '../../global/entityDescriptors';
import { reportHeaderQuery, updateReportHeaderMutation, allCompanyQueryName } from './reportQueries';
import MaterialForm from '../../components/form/MaterialForm';
import Logo_File_Search from './Logo_File_Search';
import Company_Iban_Search from '../company/Company_Iban_Search';

const invoiceHeaderWords = [
    { value: 'Proforma', label: 'Proforma' },
    { value: 'Fattura', label: 'Fattura' },
];

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing.unit * 2,
        margin: theme.spacing.unit * 2,
    },

    button: {
        margin: theme.spacing.unit,
    },

    badge: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        marginTop: 0,
    },

    gridRoot: {
        height: '100%',
    },


    formCaption: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit * 2,
        width: '100%',
        position: 'relative',
        height: 40,
    },
    formCaptionLabel: {
        position: 'absolute',
        backgroundColor: 'transparent',
        left: 25,
        paddingLeft: 10,
        paddingRight: 10,
    },

    rootPaper: theme.mixins.gutters({

        paddingTop: 0,
        paddingBottom: 0,
        marginTop: 0,// theme.spacing.unit * 3,

    }),

});

const tabsStateGlobalId = 'ReportHeaderEdit';

class ReportHeaderEdit extends ComponentEx {

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
    }

    state = {
        value: window[tabsStateGlobalId] ? window[tabsStateGlobalId] : 0,
    };

    componentWillUnmount() {
        window[tabsStateGlobalId] = this.state.value;
    }

    componentDidMount() {
        $("table[class*='Table-headTable']").addClass(this.props.classes.tableHeader);
    }

    onSubmit(e, { obj, showErrors, hideErrors }) {

        obj.report_header_id = 1;

        this.setState({ save: true });


        this.props.updateHeader({
            variables: { header: obj },
        }, this).then(
            (resp) => {
                this.setState({ save: false });
                hideErrors();
                const serverErrors = resp.data.updateReportHeaderMutation.validation;

                if (serverErrors && serverErrors.length > 0) {
                    showErrors(serverErrors);
                } else {
                    window.showPopupInfo({
                        variant: 'report_header_save_ok',
                    });

                }
            });
    }

    render() {

        const { classes } = this.props;
        const { value } = this.state;
        const loading = this.props.data.loading || this.state.save;
        const item = this.props.data.reportHeader;

        const caption = getTxt('Modifica i dati aziendali');

        return (

            <div className={classes.root}>
                {/* <Typography variant="h4" color="inherit" noWrap={false}>{getTxt(caption)}</Typography> */}
                <LinearProgressEx loading={loading} />

                <NavPills
                    color="warning"
                    onChange={this.handleChange}
                    alignCenter
                    active={value}
                    tabs={[
                        {
                            tabButton: getTxt('Dati Aziendali'),
                            tabIcon: Icon.Settings,
                            tabContent: (
                                <TabContainer>
                                    {item &&



                                        <div>
                                            <Logo_File_Search
                                                noFilters
                                                entityId={1}
                                                openDialog={this.state.openAttFileDlg}
                                            //noAddBtn={(!authInfo.isAgencyAdministrator() && !authInfo.isMasterBroker())}
                                            //quiet={this.state.value !== 1}
                                            />
                                            <MaterialForm dataSource={item} objType={EntityTypes.ReportHeader} onSubmit={this.onSubmit} onChange={this.fornChanged}>
                                                <c.group l={getTxt('')} />
                                                <c.field f="caption" l={getTxt('Nome azienda')} sm={12} />
                                                <c.field f="data_line_1" l={getTxt('Indirizzo')} sm={12} />
                                                <c.field f="data_line_2" l={getTxt('Telefono / Fax')} sm={12} />
                                                <c.field f="data_line_3" l={getTxt('Mail / Pec')} sm={12} />
                                                <c.field f="data_line_4" l={getTxt('Sito web')} sm={12} />
                                                <c.field f="data_line_5" l={getTxt('Responsabile per la protezione dei dati')} sm={12} />

                                                <c.field f="numero_iscrizione_rui" sm={12} />
                                                <c.field f="data_line_6" sm={12} />
                                                <c.field f="data_line_7" sm={12} />

                                                <c.formButtons sm={12} onCancel={() => { this.props.history.goBack(); }} />
                                            </MaterialForm>

                                        </div>
                                    }
                                    <Company_Iban_Search />
                                </TabContainer>
                            ),
                        },
                        {
                            tabButton: getTxt('Impostazioni Fatturazione'),
                            tabIcon: Icon.PictureAsPdf,
                            tabContent: (
                                <TabContainer>
                                    {item &&

                                        <MaterialForm dataSource={item} objType={EntityTypes.ReportHeader} onSubmit={this.onSubmit} onChange={this.fornChanged}>
                                           

                                            <c.field f="invoice_header_word" l="Tipologia di Fattura" ui="select" >
                                                {invoiceHeaderWords}
                                            </c.field>
                                            <c.field f="invoice_number_template" l="Modello di Enumerazione" />
                                            <c.field f="invoice_number_start_from" l="Numero di Fattura" />

                                            <c.formButtons sm={12} onCancel={() => { this.props.history.goBack(); }} />
                                        </MaterialForm>


                                    }
                                </TabContainer>
                            ),
                        },

                    ]}
                />

            </div>

        );
    }
}


export default compose(


    graphql(reportHeaderQuery, {
        options: props => ({
            fetchPolicy: 'network-only',
            options: {
                errorPolicy: 'all',
            },
        }),
    }),

    graphql(updateReportHeaderMutation, {
        name: 'updateHeader',
        options: {
            errorPolicy: 'all',
        },
    }),

)(withStyles(styles)(ReportHeaderEdit));
