import ComponentEx from 'components/ComponentEx';
import TabContainer from 'components/TabContainer';
import { AppBar } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Paper } from '@material-ui/core';
import { Tabs, Tab } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { getTxt } from 'global/appGlobal';
import React from 'react';
import { compose, graphql } from 'react-apollo';
import { Link } from 'react-router-dom';
import ReactSVG from 'react-svg';
import * as Icon from '@material-ui/icons';
import NavPills from 'components/NavPills/NavPills';
import ItemGrid from 'components/Grid/ItemGrid';
import HeaderViewItem from 'components/HeaderViewItem';
import LinearProgressEx from '../../components/LinearProgressEx';
import { DictionaryTypes, getLabel, getDictionaryItem } from '../../global/dictionaryCache';
import Custom_Fields_View from '../../modules/publicInsType/Custom_Fields_View';
import { typeByIdQuery } from '../../modules/publicInsType/publicInsTypeQueries';
import Public_Ins_Type_Details_View from '../../modules/publicInsType/Public_Ins_Type_Details_View';
import PublicInsTypeSearch from '../../modules/publicInsType/PublicInsTypeSearch';
import RegularCard from '../../components/dashboard/RegularCard';
import CmdBarCustomBtns from '../../components/CmdBarCustomBtns';
import ContractItemTypesSearch from '../../modules/internalInsType/ContractItemTypesSearch';

const styles = theme => ({

   root: {
        backgroundColor: theme.palette.grey,
        margin: theme.spacing.unit * 3,
        marginTop: 0,
    },

    button: {
        margin: theme.spacing.unit,
    },

    badge: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        marginTop: 0,
    },

    gridRoot: {
        height: '100%',
    },


    formCaption: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit * 2,
        width: '100%',
        position: 'relative',
        height: 40,
    },
    formCaptionLabel: {
        position: 'absolute',
        //color: theme.palette.primary1,
        backgroundColor: 'transparent',
        left: 25,
        paddingLeft: 10,
        paddingRight: 10,
    },

    rootPaper: theme.mixins.gutters({

        paddingTop: 0,
        paddingBottom: 0,
        marginTop: 0,// theme.spacing.unit * 3,

    }),

    formHeaderRoot: {
        padding: theme.spacing.unit * 2,
        position: 'relative',
    },

    formHeaderFieldLabel: {
        color: theme.palette.text.disabled,
        fontWeight: 'bold',

    },

    tableHeader: {
        backgroundColor: theme.palette.background.paper,
    },

    headerIcon: {
        width: theme.spacing.unit * 8,
        height: theme.spacing.unit * 9,
        marginRight: theme.spacing.unit * 2,
    },

    cmdBar: {
        position: 'absolute',
        right: theme.spacing.unit * 3,
    },
    dialogText: {
        color: theme.palette.error.main,
    },

    noGuarantyErrorText: {
        ...theme.typography.body1,
        color: theme.palette.error.main,
        paddingLeft: theme.spacing.unit * 2,
    },

});

const tabsStateGlobalId = 'Internal_Ins_Type_View_Tab';

class Internal_Ins_Type_View extends ComponentEx {

    innerID = 'Internal_Ins_Type_View_ID';

    state = {
        value: window[tabsStateGlobalId] ? window[tabsStateGlobalId] : 0,
    };

    componentWillUnmount() {
        window[tabsStateGlobalId] = this.state.value;
    }

    render() {

        const { classes } = this.props;
        const { value } = this.state;

        const id = !this.props.id ? this.props.match.params.id : this.props.id;

        const item = getDictionaryItem(DictionaryTypes.InternalInsuranceType, id);

        return (


            <div className={classes.root}>


                <RegularCard

                    headerColor="red"
                    cardTitle={item && item.label}
                    cardSubtitle={getTxt('Real Insurance Type')}
                    content={
                        <Grid container>
                            <ItemGrid xs={12} sm={12} md={2}>
                                <HeaderViewItem l={getTxt('Key')}>
                                    <Typography variant="body1" >
                                        {item && <span>{item.value}</span>}
                                    </Typography>
                                </HeaderViewItem>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={12} md={2}>
                                <HeaderViewItem l={getTxt('Name')}>
                                    <Typography variant="body1" >
                                        {item && <span>{item.label}</span>}
                                    </Typography>
                                </HeaderViewItem>
                            </ItemGrid>

                        </Grid>
                    }
                />



                <NavPills
                    color="warning"

                    alignCenter
                    active={value}
                    tabs={[
                        {
                            tabButton: getTxt('User Ins. Types'),
                            tabIcon: Icon.DeviceHub,
                            tabContent: (
                                <TabContainer>
                                    {
                                        item && <TabContainer>
                                            <PublicInsTypeSearch
                                                realType={id}
                                                history={this.props.history}
                                                noCaption
                                                addButton
                                            />
                                        </TabContainer>
                                    }
                                </TabContainer>
                            ),
                        },
                        {
                            tabButton: getTxt('Contract Item Types'),
                            tabIcon: Icon.Menu,
                            tabContent: (
                                <TabContainer>
                                    {
                                        item && <TabContainer>
                                            <ContractItemTypesSearch
                                                insTypeCd={id}
                                                history={this.props.history}
                                                noAddBtn
                                                noCaption
                                                noActionsColumn
                                            />
                                        </TabContainer>
                                    }
                                </TabContainer>
                            ),
                        },

                    ]}
                />





            </div >
        );
    }
}


export default withStyles(styles)(Internal_Ins_Type_View);

