import React, { Component } from 'react';
import { getTxt } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';
import { withTheme } from '@material-ui/core';

import { Typography } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';
import ComponentEx from 'components/ComponentEx';


import { Paper } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { AppBar } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { TextField } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import gql from 'graphql-tag';
import { graphql, compose } from 'react-apollo';
import { Tabs, Tab } from '@material-ui/core';
import * as Icon from '@material-ui/icons';
import GridEx from 'components/form/GridEx';
import { Button } from '@material-ui/core';
import moment from 'moment';
import { Badge } from '@material-ui/core';
import { queryGetCustomerById, customerBriefQuery, allCustomersQuery } from '../../modules/client/clientQueries';
import c from '../../components/form/Components';
import LinearProgressEx from '../../components/LinearProgressEx';
import { getLabel, DictionaryTypes, getDictionary } from '../../global/dictionaryCache';
import MaterialForm from '../../components/form/MaterialForm';
import MFormText from '../../components/form/MFormText';

const styles = theme => ({
    button: {
        // margin: theme.spacing.unit,
    },

    badge: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        marginTop: 0,
    },

    gridRoot: {
        height: '100%',
    },


    formCaption: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit * 2,
        width: '100%',
        position: 'relative',
        height: 40,
    },
    formCaptionLabel: {
        position: 'absolute',
        backgroundColor: 'transparent',
        left: 25,
        paddingLeft: 10,
        paddingRight: 10,
    },

    rootPaper: theme.mixins.gutters({

        paddingTop: 0,
        paddingBottom: 0,
        marginTop: 0,// theme.spacing.unit * 3,

    }),

});


class Client_Propspect_Privat_Details_View extends ComponentEx {

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.fornChanged = this.fornChanged.bind(this);
    }

    state = {
        address_country_cd: undefined,
        address_province: undefined,
    };

    onSubmit(e, { obj, showErrors, hideErrors }) {

        obj.customer_id = this.props.data.customer.customer_id;
        if (!obj.customer_id) {
            obj.customer_id = this.props.data.customer.customer_id;
        }
        obj.customer_type_cd = 'PRIVATE_PROSPECT';


        this.props.updateCustomer({
            variables: { customer: obj },

            refetchQueries: [
                {
                    query: customerBriefQuery,
                    variables: { id: this.props.data.customer.customer_id },
                },
                {
                    query: queryGetCustomerById,
                    variables: { id: this.props.data.customer.customer_id },
                },
                {
                    query: allCustomersQuery,
                },
            ],
        }, this).then(
            (resp) => {
                hideErrors();
                const serverErrors = resp.data.updateCustomerMutation.validation;
                const id = resp.data.updateCustomerMutation.id;

                if (serverErrors && serverErrors.length > 0) {
                    showErrors(serverErrors);
                } else {
                    this.props.history.push(`/customers/prospect/private/${id}`);
                }
            });
    }

    fornChanged(field, value) {
        if (field === 'address_country_cd') {
            this.setState({ address_country_cd: value });
        }
        if (field === 'address_province') {
            this.setState({ address_province: value });
        }
    }

    render() {

        const { classes } = this.props;
        const loading = this.props.data.loading;
        const customer = this.props.data.customer;


        let address_country_cd;
        let address_province;
        if (customer) {
            address_country_cd = this.state.address_country_cd ? this.state.address_country_cd : customer.address_country_cd;
            address_province = this.state.address_province ? this.state.address_province : customer.address_province;
        }



        return (

            <div>

                <LinearProgressEx loading={loading} />
                {!loading &&
                    <div>
                        <MaterialForm dataSource={customer} objType="customer" onSubmit={this.onSubmit} onChange={this.fornChanged}>

                            <c.group l="Dati personali" d />
                            <c.field l="Codice Interno" f="customer_id" d />

                            <c.field l="Nome" f="first_name" d />
                            <c.field l="Cognome" f="last_name" d />
                            {customer.broker && <c.field l="Collaboratore" f="owner_broker_id" v={customer.broker.display_name} d />}


                            <c.group l="Indirizzo" d />

                            <c.field
                                l="Nazione"
                                f="address_country_cd"
                                v={getLabel(DictionaryTypes.Country, customer.address_country_cd)}
                                d
                            >
                                {getDictionary(DictionaryTypes.Country)}
                            </c.field>
                            {address_country_cd === 'IT' &&
                                <c.field
                                    l={getTxt('Province')}
                                    f="address_province"
                                    v={getLabel(DictionaryTypes.ItalianProvinces, customer.address_province)}
                                    d
                                >
                                    {getDictionary(DictionaryTypes.ItalianProvinces)}
                                </c.field>}

                            <c.field l="Città" f="address_city" d />

                            <c.field l="CAP" f="address_cap" d />


                            <c.field l="Indirizzo" f="address_street" d />
                            <c.field l="Civico" f="address_street_number" d />

                            <c.group l="Informazioni Contatti" d />
                            <c.field l="Telefono" f="phone" d />
                            <c.field l="Telefono Attività" f="phone_active" d />
                            <c.field l="Cellulare" f="mobile_phone" d />
                            <c.field l="Email" f="email" d />
                            <c.field l="PEC" f="email_pec" d />



                            <c.group l="Informazioni sul'Attività" d />
                            <c.field l="Attività Svolta" f="business_occupation" v={getLabel(DictionaryTypes.BusinessOccupation, customer.business_occupation)} d />

                            <c.group l={getTxt('Notes')} d />
                            <GridEx item sm={12} xs={12}>
                                <MaterialForm spacing={12} dataSource={customer} objType="customer" >
                                    <c.readOnlyInLine l="" f="internal_notes" d />
                                </MaterialForm>
                            </GridEx>

                        </MaterialForm>

                    </div>
                }


            </div>

        );
    }
}


const updateCustomerMutation = gql`

mutation UpdateCustomerMutation ($customer: customer_input!) {
    updateCustomerMutation(customer: $customer){
        id
        validation {
            field
            message
        }
    } 
}

`;

export default compose(
    graphql(queryGetCustomerById, {
        options: props => ({
            variables: { id: props.customerId ? props.customerId : -1 },
            fetchPolicy: 'network-only',
            options: {
                errorPolicy: 'all',
            },
        }),
    }),
    graphql(updateCustomerMutation, {
        name: 'updateCustomer',

        options: {
            errorPolicy: 'all',
        },
    }),
)(withStyles(styles)(Client_Propspect_Privat_Details_View));
