import ComponentEx from 'components/ComponentEx';
import { EntityTypes } from 'global/entityDescriptors';
import { withStyles } from '@material-ui/core/styles';
import $ from 'jquery';
import React from 'react';
import moment from 'moment';
import { randomString } from 'global/appGlobal';
import c from '../../../components/form/Components';
import MaterialForm from '../../../components/form/MaterialForm';
import { DictionaryTypes, getDictionary, getValue } from '../../../global/dictionaryCache';
import RequestFormButtons from '../../request/RequestFormButtons';

moment.locale('it-it');

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing.unit * 2,
        margin: theme.spacing.unit * 2,
    },

});


class Cauzione_Edit_Form extends ComponentEx {

    constructor(props) {
        super(props);
        this.fornChanged = this.fornChanged.bind(this);
    }

    id = randomString(5, 'aA');

    state = {
        address_country_cd: undefined,
        address_province: undefined,


        concessione_date: undefined,
        guarantee_duration_days: undefined,
        guarantee_expiration: undefined,
    };

    formGetValues = { func: undefined };
    formSetValues = { func: undefined };

    fornChanged(field, value, type, prevVal) {

        if (field === 'address_country_cd') {
            const country = getValue(DictionaryTypes.Country, value);

            if (country) {
                this.setState({ address_country_cd: country });
            }
        }
        if (field === 'address_province') {
            const prov = getValue(DictionaryTypes.ItalianProvinces, value);
            if (prov) {
                this.setState({ address_province: prov });
            }
        }

        if (field === 'concessione_date' || field === 'guarantee_duration_days') {
            let duration = $('#' + this.id).find('input[name="guarantee_duration_days"]').val();
            let concessioneDate = $('#' + this.id).find('input[name="concessione_date"]').val();

            if (concessioneDate && !isNaN(parseInt(duration, 0))) {
                duration = parseInt(duration, 0);
                concessioneDate = moment(concessioneDate);
                concessioneDate = concessioneDate.add(duration, 'days').format('YYYY-MM-DD');

                this.formSetValues.func(
                    {
                        guarantee_expiration: concessioneDate,
                    }, 'no focus');
            } else {
                this.formSetValues.func(
                    {
                        guarantee_expiration: '',
                    }, 'no focus');
            }
        }

        // if (field === 'concessione_date' || field === 'proposal_date') {

        //     const values = this.formGetValues.func();
        //     const expiration = $('#' + this.id).find('input[name="expiration_date"]').val();
        //     const proposal = $('#' + this.id).find('input[name="proposal_date"]').val();

        //     if (expiration && proposal) {
        //         const exp = moment(expiration);
        //         const prop = moment(proposal);
        //         const duration = exp.diff(prop, 'days');

        //         this.formSetValues.func(
        //             {

        //                 guarantee_duration_days: duration,
        //                 guarantee_expiration: prop.add(duration, 'days').format('YYYY-MM-DD'),
        //             }, 'no focus');


        //     }



        // }

        if (this.props.onChange) {
            this.props.onChange(field, value, type, prevVal);
        }
    }

    render() {

        const { classes } = this.props;

        let address_country_cd;
        let address_province;
        if (this.props.request) {
            address_country_cd = this.state.address_country_cd ? this.state.address_country_cd : this.props.request.address_country_cd;
            address_province = this.state.address_province ? this.state.address_province : this.props.request.address_province;
        }

        return (

            <div id={this.id}>
                <MaterialForm
                    dataSource={this.props.request}
                    objType={EntityTypes.InsuranceRequestCausione}
                    onSubmit={this.props.onSubmit}
                    onChange={this.fornChanged}
                    fields={this.props.fields}
                    getValues={this.formGetValues}
                    setValues={this.formSetValues}
                >
                    <c.group
                        l="Stazione Appaltante / Beneficiario"
                        fields={this.props.fields}
                        child={[
                            'beneficiary_name',
                            'beneficiary_fiscal_code',
                            'born_date',
                            'citizenship',
                            'passport_number',
                            'issue_date',
                            'expiration_date',
                            'visa_number',
                            'address_country_cd',
                            'address_province',
                            'address_city',
                            'address_cap',
                            'address_street',
                            'address_street_number',

                        ]}
                    />

                    <c.field f="beneficiary_name" />
                    <c.field f="beneficiary_fiscal_code" />
                    <c.field f="born_date" />
                    <c.field f="citizenship" />
                    <c.field f="passport_number" />
                    <c.field f="issue_date" />
                    <c.field f="expiration_date" />
                    <c.field f="visa_number" />

                    <c.field
                        f="address_country_cd"
                        ui="auto-select"
                    >
                        {getDictionary(DictionaryTypes.Country)}
                    </c.field>
                    {address_country_cd === 'IT' &&
                        <c.field
                            f="address_province"
                            ui="auto-select"
                        >
                            {getDictionary(DictionaryTypes.ItalianProvinces)}
                        </c.field>}
                    {address_country_cd === 'IT' &&
                        <c.field
                            provinceId={address_province}
                            f="address_city"
                            ui="city-auto-select"
                        />}
                    {address_country_cd !== 'IT' &&
                        <c.field f="address_city" />
                    }
                    <c.field f="address_cap" />
                    <c.field f="address_street" />
                    <c.field f="address_street_number" />



                    <c.group
                        l="Dati Garanzia"
                        fields={this.props.fields}
                        child={[
                            'cig_number',
                            'tender_start_date',
                            'concessione_date',
                            'proposal_date',
                            'guarantee_duration_days',
                            'guarantee_expiration',
                            'execution_place',
                            'work_total_amount',
                            'discount_percentage',
                            'guaranteed_percentage',
                            'guaranteed_amount',
                            'work_description',
                        ]}
                    />

                    <c.field f="cig_number" />
                    <c.field f="tender_start_date" />
                    <c.field f="concessione_date" /> {/* 1 */}
                    <c.field f="proposal_date" />
                    <c.field f="guarantee_duration_days" /> {/* 2 */}
                    <c.field f="guarantee_expiration" /> {/* 3 */}
                    <c.field f="execution_place" />
                    <c.field f="work_total_amount" />
                    <c.field f="discount_percentage" />
                    <c.field f="guaranteed_percentage" />
                    <c.field f="guaranteed_amount" />
                    <c.field f="work_description" sm={12} multiline />

                    <c.group
                        l="Informazioni Aggiuntive"
                        fields={this.props.fields}
                        child={[
                            'co_obligated_customer',
                            'authenticated_contract',
                            'digital_contract',
                            'ati_contract',
                            'additional_information',
                            'comments',
                        ]}
                    />

                    {this.props.request &&
                        <c.field
                            ui="link-to-customer"
                            f="co_obligated_customer"

                        />}
                    <c.field f="authenticated_contract" />
                    <c.field f="digital_contract" />
                    <c.field f="ati_contract" stringValue />
                    <c.field f="additional_information" sm={12} multiline />
                    <c.field f="comments" sm={12} multiline />

                    <RequestFormButtons
                        history={this.props.history}
                        transferToContract={this.props.transferToContract}
                        isNew={this.props.isNew}
                        noSave={this.props.oldContractId}
                    />

                </MaterialForm>

            </div>

        );
    }
}

export default withStyles(styles)(Cauzione_Edit_Form);
