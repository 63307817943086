import React, { Component } from 'react';
import { getTxt, toItalianDateString } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';
import { graphql, compose } from 'react-apollo';
import c from 'components/form/Components';
import $ from 'jquery';
import { Checkbox } from '@material-ui/core';
import { IconButton } from '@material-ui/core';

import { Button } from '@material-ui/core';
import GridEx from 'components/form/GridEx';
import { HashRouter, BrowserRouter, Route, Link, withRouter } from 'react-router-dom';
import {
    SortingState, SelectionState, FilteringState, GroupingState,
    IntegratedFiltering, IntegratedGrouping, IntegratedSorting, IntegratedSelection,
} from '@devexpress/dx-react-grid';
import DeleteIcon from '@material-ui/icons/Delete';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import {
    Grid,
    VirtualTable, TableHeaderRow, TableFilterRow, TableSelection, TableGroupRow,
    GroupingPanel, DragDropProvider, TableColumnReordering, Toolbar, TableColumnResizing,
} from '@devexpress/dx-react-grid-material-ui';
import LinearProgressEx from '../../components/LinearProgressEx';
import * as queries from '../../modules/contract/contractQueries';
import { getLabel, DictionaryTypes } from '../../global/dictionaryCache';
import RegularCard from '../../components/dashboard/RegularCard';
import Contract_Item_Type_Edit from '../../modules/contract/Contract_Item_Type_Edit';
import ComponentEx from '../../components/ComponentEx';
import { filterRowMessages, tableMessages, createGridResizerCols } from '../../global/appGlobal';



const columnExtensions = [

];

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        margin: theme.spacing.unit * 3,
    },
    button: {
        margin: theme.spacing.unit,
    },

    badge: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        marginTop: 0,

    },
    gridRoot: {
        height: '100%',
    },

    tableHeader: {
        backgroundColor: theme.palette.background.paper,
    },
});

class Contract_Item_Type_Search extends ComponentEx {

    state = {
        editDlgOpen: false,
        delDlgOpen: false,
        delId: false,
    };

    componentDidMount() {
        $("table[class*='Table-headTable']").addClass(this.props.classes.tableHeader);
    }

    handleDelete() {
        this.props.deleteType({
            variables: { id: this.state.delId },
            refetchQueries: [
                {
                    query: queries.allContractItemTypesQuery,
                },
            ],
        });
        this.setState({ delDlgOpen: false });
    }

    render() {

        const { classes } = this.props;
        const items = this.props.data.contract_item_types ? this.props.data.contract_item_types : [];
        const columns = [


            { name: 'value', title: getTxt('Key') },
            { name: 'label', title: getTxt('Label') },
            { name: 'is_financial', title: getTxt('Is Financial') },
            { name: 'is_negative', title: getTxt('Is Negative') },
            { name: 'contract_item_type_id', title: getTxt('Actions') },

        ];

        createGridResizerCols(this.state, columns, {}, 210);
        const caption = getTxt('Contract Movement Types');
        const subtitle = getTxt('List of Contract Movements Types.');

        return (

            <div className={classes.root}>
                <LinearProgressEx loading={this.props.data.loading} />
                {!this.props.noCaption && <RegularCard

                    headerColor="red"
                    cardTitle={caption}
                    cardSubtitle={subtitle}
                    content={
                        <div>
                            {!this.props.noAddBtn && <Button
                                color="primary"
                                className={classes.button}
                                onClick={() => {
                                    this.setState({ editDlgOpen: true });
                                }}
                            >
                                {getTxt('Add New Type')}
                            </Button>}
                        </div>

                    }
                />}

                {
                    this.state.editDlgOpen &&
                    <Contract_Item_Type_Edit

                        realType={this.props.realType}
                        AfterSubmit={((id) => {
                            this.setState({ editDlgOpen: false });
                            // this.props.data.refetch();


                        })}

                    />
                }
                <Grid

                    rows={items}
                    columns={columns}

                >
                    <FilteringState
                        defaultFilters={[{}]}
                    />

                    <SortingState
                        defaultSorting={[
                            {},
                        ]}
                        columnExtensions={[
                            {
                                columnName: 'contract_item_type_id',
                                sortingEnabled: false,
                            },
                        ]}
                    />

                    <IntegratedFiltering />
                    <IntegratedSorting />

                    <VirtualTable
                        messages={tableMessages}
                        columnExtensions={columnExtensions}
                        cellComponent={({ row, column, style, ...props }) => {
                            // if (column.name === 'value') {
                            //     return (
                            //         <VirtualTable.Cell>
                            //             {row.value === 'POLLIZE' && 'Polizza' }
                            //             {row.value !== 'POLLIZE' && row.value }
                            //         </VirtualTable.Cell>);
                            // }
                            if (column.name === 'contract_item_type_id') {
                                return (
                                    <VirtualTable.Cell>
                                        {row.value !== 'Polizza' && <IconButton size="small" onClick={() => { this.setState({ delDlgOpen: true, delId: row.contract_item_type_id }); }} >
                                            <DeleteIcon />
                                        </IconButton>}
                                        {row.value === 'Polizza' && <IconButton size="small" disabled >
                                            <DeleteIcon />
                                        </IconButton>}
                                    </VirtualTable.Cell>);
                            }
                            if (column.name === 'is_financial') {
                                return (
                                    <VirtualTable.Cell>
                                        <Checkbox disabled checked={row.is_financial === 'true' ? true : false} />
                                    </VirtualTable.Cell>);
                            }
                            if (column.name === 'is_negative') {
                                return (
                                    <VirtualTable.Cell>
                                        <Checkbox disabled checked={row.is_negative === 'true' ? true : false} />
                                    </VirtualTable.Cell>);
                            }

                            return <VirtualTable.Cell {...props} />;
                        }}
                    />

                    <TableFilterRow
                        messages={filterRowMessages}
                        cellComponent={({ column, filter, onFilter, ...props }) => {
                            if (column.name === 'contract_item_type_id') {
                                return (
                                    <TableFilterRow.Cell filteringEnabled={true} >
                                        <span />
                                    </TableFilterRow.Cell>);
                            }
                            if (column.name === 'is_financial' || column.name === 'is_negative') {
                                return (
                                    <c.gridColFilter noFilterVal={getTxt('All')} setFilter={onFilter} >
                                        {[{ value: 'true', label: getTxt('Yes') }, { value: 'false', label: getTxt('No') }]}
                                    </c.gridColFilter>);
                            }
                            return <TableFilterRow.Cell onFilter={onFilter} filter={filter} {...props} />;
                        }}
                    />

                    <TableColumnResizing
                        columnWidths={this.state.columnWidths}
                        onColumnWidthsChange={(cols) => { this.setState({ columnWidths: cols }); }}
                        defaultColumnWidths={this.state.defColumnWidths}
                    />
                    <TableHeaderRow showSortingControls />

                </Grid>
                <Dialog onClose={() => { this.setState({ delDlgOpen: false }); }} open={this.state.delDlgOpen}>
                    <DialogTitle >{getTxt('Delete Contract Movement type.')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <span>{getTxt('Contract Movement type will be delete on server.')}</span>
                            <br />
                            <span >{getTxt('Are you sure do you want delete this Contract Movement type?')}</span>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleDelete(this.state.delId)} color="primary">
                            {getTxt('Ok')}
                        </Button>
                        <Button onClick={() => { this.setState({ delDlgOpen: false }); }} color="primary">
                            {getTxt('Annula')}
                        </Button>

                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default compose(
    graphql(queries.deleteContractItemTypeMutation, {
        name: 'deleteType',
        options: {
            errorPolicy: 'all',
           
        },
    }),
    graphql(queries.allContractItemTypesQuery, {
        options: props => (
            {
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }
        ),
    }),
)(withRouter(withStyles(styles)(Contract_Item_Type_Search)));
