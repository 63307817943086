import React from 'react';
import PropTypes from 'prop-types';
// react plugin for creating charts
import ChartistGraph from 'react-chartist';
import * as Icon from '@material-ui/icons';
import {
    ContentCopy,
    Store,
    InfoOutline,
    Warning,
    DateRange,
    LocalOffer,
    Update,
    ArrowUpward,
    AccessTime,
    Accessibility,
    NoteAdd,
} from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
var Chartist = require("chartist");
import { Typography } from '@material-ui/core';
import { getTxt } from 'global/appGlobal';
import { graphql, compose } from 'react-apollo';

import StatsCard from 'components/dashboard/StatsCard';
import ChartCard from 'components/dashboard/ChartCard';
import TasksCard from 'components/dashboard/TasksCard';
import RegularCard from 'components/dashboard/RegularCard';
import Table from 'components/dashboard/Table';
import HeaderViewItem from 'components/HeaderViewItem';
import ItemGrid from 'components/dashboard/ItemGrid';
import { Menu, MenuItem } from '@material-ui/core';
import { Divider } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { addAuthListener, authInfo, initFromCookie, logout } from 'global/auth';
import {
    dailySalesChart,
    emailsSubscriptionChart,
    completedTasksChart
} from 'components/dashboard/variables/charts';

import dashboardStyle from 'assets/jss/material-dashboard-react/dashboardStyle';
import { operationalDashboardData, managementDashboardData } from '../dashboard/dashboardQueries';
import LinearProgressEx from '../../components/LinearProgressEx';
import ComponentEx from '../../components/ComponentEx';
import Monthly_Inbound_Report from '../reports/Monthly_Inbound_Report';
import Revenues_Insurance_Company_Report from '../reports/Revenues_Insurance_Company_Report';
import Conversion_Index_Report from '../reports/Conversion_Index_Report';
import New_Customers_Last_12_Report from '../reports/New_Customers_Last_12_Report';
import Percentages_Matured_Agency_Last_12_Report from '../reports/Percentages_Matured_Agency_Last_12_Report';
import CollaboratorePerformance_Report from '../reports/CollaboratorePerformance_Report';

const styles = theme => ({
    ...dashboardStyle,
    root: {
        backgroundColor: theme.palette.background.paper,
        paddingTop: theme.spacing.unit * 2,
        margin: theme.spacing.unit * 3,
    },
    headerViewItem: {
        marginBottom: theme.spacing.unit * 1.5,
    },
    formHeaderFieldLabel: {
        lineHeight: '0.85em',
        color: theme.palette.text.disabled,
        fontWeight: 'bold',
    }
});

class ManagementDashboard extends ComponentEx {
    state = {
        value: 0,
        menuOpen: false,
        anchorEl: undefined,
    };
    handleChange = (event, value) => {
        this.setState({ value });
    };

    handleChangeIndex = index => {
        this.setState({ value: index });
    };

    newClick(event) {
        this.setState({ menuOpen: true, anchorEl: event.currentTarget });
    }

    render() {
        const { classes } = this.props;

        const loading = this.props.data.loading;
        const dasboardData = this.props.data.managementDashboardData;

        return (
            <div className={classes.root}>

                <LinearProgressEx loading={loading} />

                <Grid container>


                    <ItemGrid xs={12} sm={12} md={3}>
                        <HeaderViewItem l={getTxt('Clients total')}>{dasboardData && dasboardData.clientsCount}</HeaderViewItem>
                    </ItemGrid>

                    <ItemGrid xs={12} sm={12} md={3}>
                        <HeaderViewItem l={getTxt('Prospect Totali')}>{dasboardData && dasboardData.prospectCount}</HeaderViewItem>
                    </ItemGrid>

                    <ItemGrid xs={12} sm={12} md={3}>
                        <HeaderViewItem l={getTxt('Contracts total')}>{dasboardData && dasboardData.contractsCount}</HeaderViewItem>
                    </ItemGrid>
                    <Link to="/customers/filterx/customers-no-contracts">
                        <ItemGrid xs={12} sm={12} md={3}>

                            <HeaderViewItem l={getTxt('Clienti senza polizza')}>{dasboardData && dasboardData.customersNoContracts}</HeaderViewItem>

                        </ItemGrid>
                    </Link>
                </Grid>

                <Grid container>

                    <ItemGrid xs={12} sm={12} md={12} >
                        <New_Customers_Last_12_Report noCaption />
                    </ItemGrid>
                    <ItemGrid xs={12} sm={12} md={12}>
                        <Percentages_Matured_Agency_Last_12_Report noCaption />
                    </ItemGrid>
                </Grid>
                <Grid container>
                    <ItemGrid xs={12} sm={12} md={12}>
                        <Revenues_Insurance_Company_Report noCaption />
                    </ItemGrid>
                    <ItemGrid xs={12} sm={12} md={12}>
                        <Conversion_Index_Report noCaption />
                    </ItemGrid>
                    <ItemGrid xs={12} sm={12} md={12}>
                        <CollaboratorePerformance_Report noCaption />
                    </ItemGrid>

                </Grid>
                <Grid container>

                    {(authInfo.isAgencyAdministrator() || authInfo.isMasterBroker()) &&
                        <ItemGrid xs={12} sm={12} md={12} >
                            <Typography variant="subheading" color="inherit" noWrap={false}>{getTxt('Monthly Cash Flow')}</Typography>
                            <Monthly_Inbound_Report noCaption noPrintButton />
                        </ItemGrid>

                    }




                </Grid>

            </div >
        );
    }
}

export default compose(

    graphql(managementDashboardData, {
        options: props => (
            {
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }
        ),
    }),

)(withStyles(styles)(ManagementDashboard));

