import React, { Component } from 'react';
import gql from 'graphql-tag';
import { graphql, compose } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import $ from 'jquery';
import { IconButton } from '@material-ui/core';
import ComponentEx from 'components/ComponentEx';
import { Typography } from '@material-ui/core';
import * as Icon from '@material-ui/icons';
import PropTypes from 'prop-types';
import {
    SortingState, SelectionState, FilteringState, GroupingState, EditingState,
    IntegratedFiltering, IntegratedGrouping, IntegratedSorting, IntegratedSelection,
} from '@devexpress/dx-react-grid';
import {
    Grid, Table,
    VirtualTable, TableHeaderRow, TableFilterRow, TableEditRow, TableSelection, TableGroupRow,
    GroupingPanel, DragDropProvider, TableColumnReordering, Toolbar, TableEditColumn,
} from '@devexpress/dx-react-grid-material-ui';
import { HashRouter, BrowserRouter, Route, Link, withRouter } from 'react-router-dom';
import { TableCell } from '@material-ui/core';
import { Button } from '@material-ui/core';
import c from 'components/form/Components';
import { getToken } from 'global/auth';
import { ReportTypes } from 'global/reporting';
import DeleteIcon from '@material-ui/icons/Delete';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import { getTxt, filterRowMessages, tableMessages } from '../../global/appGlobal';
import LinkToCustomerById from '../../components/LinksToObjectById';
import LinearProgressEx from '../../components/LinearProgressEx';
import { addDictionaries, getLabel, getDictionary, DictionaryTypes } from '../../global/dictionaryCache';
import { allDictionariesQuery, removeDictionaryMutation, allDictionaryItemsQuery, removeDictionaryItemMutation } from '../../modules/dictionary/dictionariesQueries';
import { Menu, MenuItem } from '@material-ui/core';



const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        margin: theme.spacing.unit * 2,
    },
    button: {
        margin: theme.spacing.unit,
    },

    tableHeader: {
        backgroundColor: theme.palette.background.paper,
    },
    inGridButton: {
        width: 36,
        height: 30,
        marginRight: theme.spacing.unit * 2,
    },
    caption: {
        paddingLeft: theme.spacing.unit * 2,
        paddingTop: theme.spacing.unit * 2,
    },
    dialogText: {
        color: theme.palette.error.main,
    },
});

const AddButton = ({ onExecute }) => (
    <div style={{ textAlign: 'center' }}>
        <Button
            color="primary"
            onClick={onExecute}
            title="Crea un nuovo elemento del dizionario"
        >
            Nuovo
        </Button>
    </div>
);
AddButton.propTypes = {
    onExecute: PropTypes.func.isRequired,
};

const EditButton = ({ onExecute }) => (
    <IconButton size="small" onClick={onExecute} title="Edit row">
        <Icon.Edit />
    </IconButton>
);
EditButton.propTypes = {
    onExecute: PropTypes.func.isRequired,
};

const DeleteButton = ({ onExecute }) => (
    <IconButton size="small" onClick={onExecute} title="Delete row">
        <Icon.Delete />
    </IconButton>
);
DeleteButton.propTypes = {
    onExecute: PropTypes.func.isRequired,
};

const CommitButton = ({ onExecute }) => (
    <IconButton size="small" onClick={onExecute} title="Save changes">
        <Icon.Save />
    </IconButton>
);
CommitButton.propTypes = {
    onExecute: PropTypes.func.isRequired,
};

const CancelButton = ({ onExecute }) => (
    <IconButton size="small" color="secondary" onClick={onExecute} title="Cancel changes">
        <Icon.Cancel />
    </IconButton>
);
CancelButton.propTypes = {
    onExecute: PropTypes.func.isRequired,
};

const commandComponents = {
    add: AddButton,
    edit: EditButton,
    delete: DeleteButton,
    commit: CommitButton,
    cancel: CancelButton,
};

const Command = ({ id, onExecute }) => {
    const CommandButton = commandComponents[id];
    return (
        <CommandButton
            onExecute={onExecute}
        />
    );
};

class Dictionary_Items_List extends ComponentEx {

    state = {
        anchorEl: null,
        open: false,
        delDlgOpen: false,
        delId: undefined,
        sorting: [],
        editingRowIds: [],
        addedRows: [],
        rowChanges: {},
        noDelDlgOpen: false,
    };

    constructor(props) {
        super(props);

        this.changeSorting = this.changeSorting.bind(this);
        this.changeEditingRowIds = this.changeEditingRowIds.bind(this);
        this.changeAddedRows = this.changeAddedRows.bind(this);
        this.changeRowChanges = this.changeRowChanges.bind(this);
        this.changeCurrentPage = this.changeCurrentPage.bind(this);
        this.changePageSize = this.changePageSize.bind(this);
        this.commitChanges = this.commitChanges.bind(this);
        this.cancelDelete = this.cancelDelete.bind(this);
        this.deleteRows = this.deleteRows.bind(this);
        this.changeColumnOrder = this.changeColumnOrder.bind(this);

        this.getRowId = row => row.dictionary_item_id;
    }

    componentDidMount() {
        $("table[class*='Table-headTable']").addClass(this.props.classes.tableHeader);

    }

    changeSorting(sorting) { this.setState({ sorting }); }
    changeEditingRowIds(editingRowIds) { this.setState({ editingRowIds }); }
    changeAddedRows(addedRows) {
        this.props.history.push(`/dictionaries/${this.props.match.params.dictionaryId}/new`);
        // this.setState({
        //     addedRows: addedRows.map(row => (Object.keys(row).length ? row : {
        //         amount: 0,
        //         discount: 0,
        //         saleDate: new Date().toISOString().split('T')[0],
        //         //product: availableValues.product[0],
        //         //region: availableValues.region[0],
        //         //customer: availableValues.customer[0],
        //     })),
        // });
    }
    changeRowChanges(rowChanges) { this.setState({ rowChanges }); }
    changeCurrentPage(currentPage) { this.setState({ currentPage }); }
    changePageSize(pageSize) { this.setState({ pageSize }); }
    commitChanges({ added, changed, deleted }) {
        if (deleted) {
            const itemToDel = this.items[deleted[0]];
            this.setState({ delId: itemToDel.dictionary_item_id, delDlgOpen: true });
        }

        // let { rows } = this.state;
        // if (added) {
        //     const startingAddedId = (rows.length - 1) > 0 ? rows[rows.length - 1].id + 1 : 0;
        //     rows = [
        //         ...rows,
        //         ...added.map((row, index) => ({
        //             id: startingAddedId + index,
        //             ...row,
        //         })),
        //     ];
        // }
        // if (changed) {
        //     rows = rows.map(row => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
        // }
        // this.setState({ rows, deletingRows: deleted || this.state.deletingRows });
    }
    cancelDelete() { this.setState({ deletingRows: [] }); }
    deleteRows() {
        const rows = this.state.rows.slice();
        this.state.deletingRows.forEach((rowId) => {
            const index = rows.findIndex(row => row.id === rowId);
            if (index > -1) {
                rows.splice(index, 1);
            }
        });
        this.setState({ rows, deletingRows: [] });
    }
    changeColumnOrder(order) {
        this.setState({ columnOrder: order });
    }

    handleDelDictionary(id) {
        this.props.deleteDictionaryItem({
            variables: { id },
            refetchQueries: [
                {
                    query: allDictionaryItemsQuery,
                    variables: { dictionaryId: this.props.match.params.dictionaryId },
                },
            ],
        }).then(
            (resp) => {


                if (resp.data && resp.data.removeDictionaryItem.dictionaries) {
                    addDictionaries(JSON.parse(resp.data.removeDictionaryItem.dictionaries));
                }

            });
        this.setState({ delDlgOpen: false });
    }

    render() {
        const { classes } = this.props;

        const items = this.props.data.allDictionaryItems ? this.props.data.allDictionaryItems : [];
        this.items = items;

        const columns = [

            { name: 'value', title: getTxt('key', '#i83u9p1') },
            { name: 'label', title: getTxt('Label', '#roex34e') },
            { name: 'display_order', title: getTxt('Display Order', '#q1x8960') },
            { name: 'dictionary_cd', title: getTxt('Dictionary', '#y76bti4') },
            { name: 'dictionary_item_id', title: getTxt('Azioni', '#jsnao7j') },
        ];

        const {
            editingRowIds,
            addedRows,
            rowChanges } = this.state;

        return (

            <div className={classes.root}>
                {items.length > 0 && <Typography variant="h4" color="inherit" className={classes.caption} noWrap={false}>{`${items[0].dictionary_cd}`}</Typography>}
                <LinearProgressEx loading={this.props.data.loading} />
                <Button
                    color="primary"
                    className={classes.button}
                    onClick={() => { this.props.history.push(`/dictionaries/${this.props.match.params.dictionaryId}/new`); }}
                >
                    {getTxt('Add new Dictionary Item')}
                </Button>

                <Grid

                    rows={items}
                    columns={columns}
                >
                    <FilteringState />
                    <SortingState
                        defaultSorting={[{ columnName: 'display_order', direction: 'asc' }]}
                    />
                    <EditingState
                        editingRowIds={editingRowIds}
                        onEditingRowIdsChange={this.changeEditingRowIds}
                        rowChanges={rowChanges}
                        onRowChangesChange={this.changeRowChanges}
                        addedRows={addedRows}
                        onAddedRowsChange={this.changeAddedRows}
                        onCommitChanges={this.commitChanges}
                    />


                    <IntegratedSorting />
                    <IntegratedFiltering />
                    <VirtualTable
                        messages={tableMessages}
                        cellComponent={({ row, column, style, ...props }) => {
                            if (column.name === 'value') {
                                return (<VirtualTable.Cell >
                                    <Link to={`/dictionaries/${row.dictionary_cd}/items/${row.dictionary_item_id}`} >{row.value}</Link>
                                </VirtualTable.Cell>);
                            }
                            if (column.name === 'dictionary_item_id') {
                                return (<VirtualTable.Cell>


                                    <IconButton
                                        size="small"
                                        onClick={() => {
                                            if (items.length === 1) {
                                                this.setState({ noDelDlgOpen: true });
                                            } else {
                                                this.setState({ delDlgOpen: true, delId: row.dictionary_item_id });
                                            }

                                        }}
                                        className={classes.inGridButton}
                                    >
                                        <DeleteIcon />
                                    </IconButton>



                                </VirtualTable.Cell>);
                            }

                            return <VirtualTable.Cell {...props} />;
                        }}
                    />

                    {/* <TableEditColumn
                        width={120}
                        showAddCommand
                        showDeleteCommand
                        commandComponent={Command}
                    /> */}

                    <TableFilterRow messages={filterRowMessages} />

                    <TableHeaderRow showSortingControls />

                </Grid>
                <Dialog onClose={() => { this.setState({ delDlgOpen: false }); }} open={this.state.delDlgOpen}>
                    <DialogTitle >{getTxt('Delete dictionary item .')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <span> {getTxt('Dictionary item will be delete on server.')} </span>
                            <br />
                            <span >{getTxt('If this dictionary item already used in application logic, the application will be broken. ')} </span>
                            <br />
                            <span > {getTxt('Are you sure do you want delete this dictionary item?')}  </span>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleDelDictionary(this.state.delId)} color="primary">
                            {getTxt('Ok')}
                        </Button>
                        <Button onClick={() => { this.setState({ delDlgOpen: false }); }} color="primary">
                            {getTxt('Annula')}
                        </Button>

                    </DialogActions>
                </Dialog>
                <Dialog onClose={() => { this.setState({ noDelDlgOpen: false }); }} open={this.state.noDelDlgOpen}>
                    <DialogTitle ><span className={classes.dialogText} > {getTxt('Imposible to delete dictionary item.')}</span> </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <span className={classes.dialogText}> {getTxt('At least one dictionary have to be present in dictionary.')} </span>

                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({ noDelDlgOpen: false })} color="primary">
                            {getTxt('Ok')}
                        </Button>


                    </DialogActions>
                </Dialog>
            </div >
        );
    }
}

export default compose(
    graphql(allDictionaryItemsQuery, {
        options: props => (
            {
                variables: {
                    dictionaryId: props.match.params.dictionaryId ? props.match.params.dictionaryId : -1,
                },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }
        ),
    }),
    graphql(removeDictionaryItemMutation, {
        name: 'deleteDictionaryItem',
        options: {
            errorPolicy: 'all',
        },
    }),

)(withRouter(withStyles(styles)(Dictionary_Items_List)));




