import React from 'react';
import { graphql, compose } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import $ from 'jquery';
import { Link } from 'react-router-dom';

import {
    SortingState, FilteringState, IntegratedFiltering, IntegratedSorting,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    VirtualTable, TableHeaderRow, TableFilterRow, TableColumnResizing,
} from '@devexpress/dx-react-grid-material-ui';
import File_Search from 'modules/file/File_Search';
import CurrencyGridFormatter from 'components/CurrencyGridFormatter';
import { IconButton, Typography } from '@material-ui/core';

import * as Icons from '@material-ui/icons';
import ComponentEx from 'components/ComponentEx';
import { Button } from '@material-ui/core';
import c from 'components/form/Components';
import { getTxt, toItalianDateString, toDecimalString, filterRowMessages, tableMessages, createGridResizerCols } from '../../global/appGlobal';
import LinkToCustomerById from '../../components/LinksToObjectById';
import LinearProgressEx from '../../components/LinearProgressEx';
import { getLabel, getDictionary, DictionaryTypes } from '../../global/dictionaryCache';
import { allRequestsQuery } from '../../modules/request/requestQeries';
import { Menu, MenuItem } from '@material-ui/core';
import * as Icon from '@material-ui/icons';
import RegularCard from '../../components/dashboard/RegularCard';
import Company_Payment_Edit from '../../modules/company/Company_Payment_Edit';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Tooltip } from '@material-ui/core';
import MaterialForm from '../../components/form/MaterialForm';
import GridEx from 'components/form/GridEx';
import { addDescriptor } from '../../global/entityDescriptors';
import { allTransitAccountQuery, deleteTransitAccountMutation } from './TransitAccountQueries';
import Transit_Account_Edit from './Transit_Account_Edit';
import { oneTimeTokenMutation } from '../broker/Broker_Balance_Search';
import { ReportTypes } from '../../global/reporting';

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        margin: theme.spacing.unit * 3,

    },
    button: {
        marginRight: theme.spacing.unit,
    },

    tableHeader: {
        backgroundColor: theme.palette.background.paper,
    },
    menuItem: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& $text, & $icon': {
                color: theme.palette.common.white,
            },
        },
    },
    text: {},
    icon: {},
    totalsRow: {
        backgroundColor: theme.palette.grey['200'],
        fontWeight: 'bold',
    },
    formButtons: {
        float: 'right',
        margin: '15px',
    },
    filterForemRoot: {
        paddingLeft: theme.spacing.unit * 2,
        paddingRight: theme.spacing.unit * 2,
        marginTop: -50,
        paddingTop: -50,
    },
});

let reports_filter_obj_descr;

const OBG_DESCR_KEY = 'trans_account_filter';

let reportsFilters = {
    start_date_from: null,
    start_date_to: null,
};

const toLowerCase = value => String(value).toLowerCase();

const filterByPaymentDate = (cellValue, e, row) => {
    const dateStr = row.date ? toItalianDateString(row.date) : row.date;
    return dateStr && dateStr.includes(e.value);
};

const tableColumnExtensions = [
    { columnName: 'amount', align: 'right' },
];


class Transit_Account_Search extends ComponentEx {

    state = {
        anchorEl: null,
        open: false,
        editId: undefined,
        delDlgOpen: false,
        delId: undefined,
    };

    constructor() {
        super();
        this.handleDelete = this.handleDelete.bind(this);
        this.getRowId = row => row.transit_account_id;

        this.formChanged = this.formChanged.bind(this);
        this.appplyFilters = this.appplyFilters.bind(this);
        reports_filter_obj_descr = {
            from: {
                id: 'from',
                label: getTxt('Data da'),
                type: 'DATEONLY',
                validators: [],
            },
            to: {
                id: 'to',
                label: getTxt('Data a'),
                type: 'DATEONLY',
                validators: [],
            },

        };

        reportsFilters = {
            from: null,
            to: null,
        };

        addDescriptor(OBG_DESCR_KEY, reports_filter_obj_descr);
    }

    formSetValues = { func: undefined };

    componentDidMount() {
        $("table[class*='Table-headTable']").addClass(this.props.classes.tableHeader);
    }

    formSetValues = { func: undefined };

    formChanged(field, value, type, prevVal) {
        reportsFilters[field] = value;
        const stateVal = {};
        this.state[field] = value;
        this.props.data.refetch({
            paymentAccountCd: getpaymentAccountCd(this.props),
            from: this.state.from,
            to: this.state.to,
        });
    }


    appplyFilters() {
        this.props.data.refetch({
            paymentAccountCd: getpaymentAccountCd(this.props),
            from: this.state.from,
            to: this.state.to,
        });
    }

    handleDelete(id) {

        const data = this.props.data;
        this.props.delete({
            variables: { id: this.state.delId },
            refetchQueries: [
                {
                    query: allTransitAccountQuery,
                },
            ],
        }).then(
            (resp) => {
                data.refetch();
            }
        );
        this.setState({ delDlgOpen: false });
    }

    render() {


        if (this.props.quiet) {
            return (<LinearProgressEx loading={this.props.data.loading} />);
        }

        window.refetchCompanyPaymebts = this.props.data.refetch();
        const columns = [
            { name: 'date', title: getTxt('Date') },
            { name: 'notes', title: getTxt('Note') },
            { name: 'amount', title: getTxt('Importo') },
            { name: 'payment_type_cd', title: getTxt('Tipo di pagamento') },
            { name: 'direction', title: getTxt('Entrata/Uscita') },
            { name: 'files', title: getTxt('Documents') },
            { name: 'transit_account_id', title: getTxt('Actions') },
        ];

        createGridResizerCols(this.state, columns, {}, 180);

        const { classes } = this.props;

        let items = this.props.data.allTransitAccounts ? this.props.data.allTransitAccounts : [];
        const loading = this.props.data.loading;

        const caption = getTxt('Operazioni Contabili');
        const subtitle = getTxt('Lista delle Operazioni');

        const this1 = this;
        let totalEntrata = 0;
        let totalUscita = 0;

        items.forEach((i) => {
            if (i.direction === 'ENTRATA') {
                totalEntrata += i.amount;
            }
            if (i.direction === 'USCITA') {
                totalUscita += i.amount;
            }
        });

        return (

            <div className={classes.root}>
                <LinearProgressEx loading={loading} />

                {!this.props.noCaption && <RegularCard

                    headerColor="red"
                    cardTitle={caption}
                    cardSubtitle={subtitle}
                    content=""
                />}
                <div className={classes.filterForemRoot}>
                    <MaterialForm
                        dataSource={reportsFilters}
                        objType={OBG_DESCR_KEY}
                        onChange={this.formChanged}
                        setValues={this.formSetValues}
                    >

                        <c.field f="from" />
                        <c.field f="to" />

                        <GridEx item sm="auto" xs={12}>
                            <Button
                                color="primary"
                                className={classes.button}
                                onClick={() => { this.setState({ editId: 'new' }); }}
                            >{getTxt('AGGIUNGI OPERAZIONE')}</Button>
                        </GridEx>

                        <GridEx item sm="auto" xs={12}>


                            <Button
                                variant="contained"
                                color="primary"

                                onClick={() => {
                                    reportsFilters.from = '';
                                    reportsFilters.to = '';


                                    this.formSetValues.func({
                                        from: '',
                                        to: '',
                                        companyId: '',
                                    });
                                    this.setState({
                                        from: '',
                                        to: '',
                                        companyId: '',
                                    });
                                    this.appplyFilters();
                                }}

                            >
                                {getTxt('Reset Filters')}
                            </Button>
                        </GridEx>

                        <GridEx item sm="auto" xs={12}>

                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {

                                    const newWindow = window.open("", "_blank");
                                    newWindow.document.write(getTxt('Generating PDF Report, please wait...'));
                                    this.props.getOneTimeToken({

                                    }, this).then(
                                        (resp_ott) => {

                                            if (resp_ott.data && resp_ott.data.oneTimeTokenMutation && resp_ott.data.oneTimeTokenMutation.token) {

                                                newWindow.location.href = `${window.apiUrl}files/reports?ott=${resp_ott.data.oneTimeTokenMutation.token}&type=${ReportTypes.Contabili}&params=${JSON.stringify({ paymentAccountCd: getpaymentAccountCd(this.props), from: this.state.from, to: this.state.to })}`;
                                                newWindow.focus();
                                            }

                                        });
                                }}
                            >
                                {getTxt('Create PDF')}
                            </Button>


                        </GridEx>

                        <GridEx item sm="auto" xs={12}>

                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {

                                    const newWindow = window.open("", "_blank");
                                    newWindow.document.write(getTxt('Generazione del report XLS, attendere prego...'));
                                    this.props.getOneTimeToken({

                                    }, this).then(
                                        (resp_ott) => {

                                            if (resp_ott.data && resp_ott.data.oneTimeTokenMutation && resp_ott.data.oneTimeTokenMutation.token) {

                                                newWindow.location.href = `${window.apiUrl}files/reports?ott=${resp_ott.data.oneTimeTokenMutation.token}&type=${ReportTypes.Contabili}&params=${JSON.stringify({ paymentAccountCd: getpaymentAccountCd(this.props), from: this.state.from, to: this.state.to, xlsx: true })}`;
                                                newWindow.focus();
                                            }

                                        });
                                }}
                            >
                                {getTxt('Genera XLS')}
                            </Button>


                        </GridEx>

                    </MaterialForm>
                </div>
                {this.props.showAlterAddBtn &&
                    <Button
                        color="primary"
                        className={classes.button}
                        onClick={() => { this.setState({ editId: 'new' }); }}
                    >{getTxt('AGGIUNGI OPERAZIONE')}</Button>}

                <Grid

                    rows={items}
                    columns={columns}

                >

                    <CurrencyGridFormatter
                        for={['amount']}
                    />

                    <FilteringState
                        defaultFilters={[{}]}
                    />

                    <SortingState
                        defaultSorting={[

                        ]}
                    />

                    <IntegratedFiltering
                        columnExtensions={[

                            { columnName: 'date', predicate: filterByPaymentDate },
                        ]}
                    />
                    <SortingState
                        defaultSorting={[]}
                    />
                    <IntegratedSorting
                        columnExtensions={[

                        ]}
                    />

                    <VirtualTable
                        messages={tableMessages}
                        rowComponent={({ row, ...restProps }) => {
                            if (row.insurance_company_id === -1) {
                                return (<VirtualTable.Row
                                    className={classes.totalsRow}
                                    {...restProps}
                                />);
                            }
                            return (<VirtualTable.Row {...restProps} />);
                        }}
                        cellComponent={({ row, column, style, ...props }) => {
                            if (column.name === 'date') {
                                return (<VirtualTable.Cell>
                                    {row.date ? toItalianDateString(row.date) : ''}
                                </VirtualTable.Cell>);
                            }
                            if (column.name === 'payment_type_cd') {
                                return (<VirtualTable.Cell>{getLabel(DictionaryTypes.PaymentType, row.payment_type_cd)}</VirtualTable.Cell>);
                            }

                            if (column.name === 'direction') {
                                return (<VirtualTable.Cell> {getLabel(DictionaryTypes.TransitAccountDirection, row.direction)} </VirtualTable.Cell>);
                            }

                            if (column.name === 'transit_account_id') {
                                return (<VirtualTable.Cell>

                                    <Tooltip title={getTxt('Edit')}>
                                        <IconButton size="small" onClick={() => { this.setState({ editId: row.transit_account_id }); }} className={classes.inGridButton} >
                                            <Icon.Edit />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title={getTxt('Delete')}>

                                        <IconButton size="small" onClick={() => { this.setState({ delDlgOpen: true, delId: row.transit_account_id }); }} className={classes.inGridButton} >
                                            <Icon.Delete />
                                        </IconButton>
                                    </Tooltip>

                                </VirtualTable.Cell>);
                            }
                            if (column.name === 'files') {
                                if (row.broker_payment_id === -1) {
                                    return (<VirtualTable.Cell>{''}</VirtualTable.Cell>);
                                }
                                if (!row.files || (row.files && row.files.length === 0)) {
                                    return (<VirtualTable.Cell>
                                        <a
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                this1.setState({ onlyFilesEditId: row.transit_account_id });
                                            }} >{getTxt('no documents attached')}</a> </VirtualTable.Cell>);
                                }
                                if (row.files && row.files.length > 0) {
                                    return (<VirtualTable.Cell>
                                        <a
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                this1.setState({ onlyFilesEditId: row.transit_account_id });
                                            }} >{row.files.length + ' ' + getTxt('document(s) attached')}</a> </VirtualTable.Cell>);
                                }

                            }



                            return <VirtualTable.Cell {...props} />;
                        }}
                    />

                    <TableFilterRow
                        messages={filterRowMessages}
                        cellComponent={({ column, filter, onFilter, ...props }) => {

                            if (column.name === 'payment_type_cd') {
                                return (
                                    <c.gridColFilter noFilterVal={getTxt('Tutti')} setFilter={onFilter} >
                                        {getDictionary(DictionaryTypes.PaymentType)}
                                    </c.gridColFilter>);
                            }
                            if (column.name === 'direction') {
                                return (
                                    <c.gridColFilter noFilterVal={getTxt('Tutti')} setFilter={onFilter} >
                                        {getDictionary(DictionaryTypes.TransitAccountDirection)}
                                    </c.gridColFilter>);
                            }
                            if (column.name === 'transit_account_id') {
                                return (
                                    <TableFilterRow.Cell filteringEnabled >
                                        <span />
                                    </TableFilterRow.Cell>);
                            }
                            if (column.name === 'files') {
                                return (
                                    <TableFilterRow.Cell filteringEnabled >
                                        <span />
                                    </TableFilterRow.Cell>);
                            }

                            return <TableFilterRow.Cell onFilter={onFilter} filter={filter} {...props} />;
                        }}
                    />
                    <TableColumnResizing
                        columnWidths={this.state.columnWidths}
                        onColumnWidthsChange={(cols) => { this.setState({ columnWidths: cols }); }}
                        defaultColumnWidths={this.state.defColumnWidths}
                    />

                    <TableHeaderRow />

                </Grid>

                <table style={{ marginTop: 10 }}>
                    <tr>
                        <td>
                            <Typography variant="subheading" style={{ whiteSpace: 'normal', marginTop: 30, marginLeft: 30 }} >
                                {getTxt('Entrata')}:
                            </Typography>
                        </td>
                        <td>
                            <Typography variant="subheading" style={{ whiteSpace: 'normal', marginTop: 30, marginLeft: 30 }} >
                                {toDecimalString(totalEntrata, true)}
                            </Typography>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Typography variant="subheading" style={{ whiteSpace: 'normal', marginTop: 30, marginLeft: 30 }} >
                                {getTxt('Uscita')}:
                            </Typography>
                        </td>
                        <td>
                            <Typography variant="subheading" style={{ whiteSpace: 'normal', marginTop: 30, marginLeft: 30 }} >
                                {toDecimalString(totalUscita, true)}
                            </Typography>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Typography variant="subheading" style={{ whiteSpace: 'normal', marginTop: 30, marginLeft: 30 }} >
                                {getTxt('Saldo')}:
                            </Typography>
                        </td>
                        <td>
                            <Typography variant="subheading" style={{ whiteSpace: 'normal', marginTop: 30, marginLeft: 30 }} >
                                {toDecimalString(totalEntrata - totalUscita, true)}
                            </Typography>
                        </td>
                    </tr>
                </table>

                {
                    this.state.editId &&
                    <Transit_Account_Edit
                        paymentAccount={this.props.match.params.payment_account}
                        id={this.state.editId}

                        AfterSubmit={((id) => {
                            this.setState({ editId: undefined });
                            if (id) {
                                this.setState({ onlyFilesEditId: id });
                            }
                            this.props.data.refetch();
                        })}

                    />
                }
                {
                    this.state.onlyFilesEditId &&
                    <Dialog onClose={() => { this.setState({ onlyFilesEditId: false }); }} open={this.state.onlyFilesEditId}>
                        <DialogTitle>{getTxt('Operazioni Contabili Documenti.')}</DialogTitle>
                        <DialogContent>
                            <File_Search entityId={this.state.onlyFilesEditId} />
                        </DialogContent>
                        <DialogActions>
                            <div className={classes.formButtons} >
                                <Button onClick={() => {
                                    this.setState({ onlyFilesEditId: false });
                                }}
                                    className={classes.button}
                                    variant="contained">
                                    {getTxt('Cancel')}
                                </Button>
                                <Button onClick={() => this.setState({ onlyFilesEditId: false })} className={classes.button} variant="contained" color="primary">
                                    {getTxt('Save')}
                                </Button>
                            </div>
                        </DialogActions>
                    </Dialog>
                }

                <Dialog onClose={() => { this.setState({ delDlgOpen: false, delId: null }); }} open={this.state.delDlgOpen}>

                    <DialogContent>
                        <DialogContentText>
                            <span> {getTxt("L'operazione sarà eliminata dal server.")} </span>
                            <br />
                            <span > {getTxt("Sei sicuro di voler eliminare l'operazione?")} </span>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleDelete(this.state.delId)} color="primary">
                            {getTxt('Ok')}
                        </Button>
                        <Button onClick={() => { this.setState({ delDlgOpen: false, delId: null }); }} color="primary">
                            {getTxt('Annula')}
                        </Button>

                    </DialogActions>
                </Dialog>
            </div >
        );
    }


}

function getpaymentAccountCd(props) {
    if (props.match) {
        return props.match.params.payment_account ? props.match.params.payment_account : null;
    }
    return null;
}

export default compose(

    graphql(oneTimeTokenMutation, {
        name: 'getOneTimeToken',
        options: {
            errorPolicy: 'all',
            fetchPolicy: 'no-cache',
        },
    }),

    graphql(allTransitAccountQuery, {
        options: props => (
            {
                variables: {
                    paymentAccountCd: getpaymentAccountCd(props),
                },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }
        ),
    }),

    graphql(deleteTransitAccountMutation, {
        name: 'delete',
        options: {
            errorPolicy: 'all',
        },
    }),

)(withStyles(styles)(Transit_Account_Search));
