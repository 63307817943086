import gql from 'graphql-tag';

export const dictionaryById = gql`

query DictionaryQuery($id: ID!) {
    dictionaryById(id: $id) {
        dictionary_id
        name
    }
}

`;


export const dictionaryItemByIdQuery = gql`

query dictionaryItemByIdQuery($id: ID!) {
    dictionaryItemById(id: $id) {
        dictionary_item_id
        value
        label
        dictionary_cd
        display_order
    }
}

`;

export const allDictionaryItemsQuery = gql`

query AllDictionaryItemsQuery($dictionaryId: String!) {
    allDictionaryItems (dictionaryId: $dictionaryId) {
        dictionary_item_id
        value
        label
        dictionary_cd
        display_order
    }
  }
  
`;

export const allDictionariesQuery = gql`

query AllDictionariesQuery {
    allDictionaries {
        dictionary_cd
    }
  }
  
`;

export const removeDictionaryMutation = gql`

mutation RemoveDictionaryMutation ($id: ID!) {
    removeDictionary(id: $id){
        id
        validation {
            field
            message
        }
        dictionaries
    } 
}
`;

export const removeDictionaryItemMutation = gql`

mutation removeDictionaryItemMutation ($id: ID!) {
    removeDictionaryItem(id: $id){
        id
        validation {
            field
            message
        }
        dictionaries
    } 
}
`;

export const updateDictionaryMutation = gql`

mutation UpdateDictionaryMutation ($dictionary: dictionary_input!) {
    updateDictionaryMutation(dictionary: $dictionary){
        id
        validation {
            field
            message
        }
        
    } 
}
`;

export const updateDictionaryItemMutation = gql`

mutation UpdateDictionaryItemMutation ($dictionaryItem: dictionary_item_input!) {
    updateDictionaryItemMutation(dictionaryItem: $dictionaryItem){
        id
        validation {
            field
            message
        }
        dictionaries
    } 
}
`;