import gql from 'graphql-tag';

export const allTransitAccountQuery = gql`
query allTransitAccountQuery($paymentAccountCd: ID, $from: String, $to: String) {
    allTransitAccounts(paymentAccountCd: $paymentAccountCd, from: $from, to: $to){
        transit_account_id
        date
        notes
        amount
        payment_type_cd
        direction
        payment_account
        files{
            document_id
        }
    }    
}`;

export const deleteTransitAccountMutation = gql`
mutation deleteTransitAccountMutation($id: ID) {
    deleteTransitAccountMutation(id: $id) {
      id
      validation {
        field
        message
      }
    }
  }`;

export const transitAccountByIdQuery = gql`

query transitAccountByIdQuery($id: ID!) {
    transitAccount(id: $id){
        transit_account_id
        date
        notes
        amount
        payment_type_cd
        direction
        payment_account
    }
}`;

export const updateTransitAccountMutation = gql`

mutation updateTransitAccountMutation($transitAccount: transit_account_input!) {
    updateTransitAccountMutation(transitAccount: $transitAccount) {
      id
      validation {
        field
        message
      }
    }
  }`;