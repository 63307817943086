import React, { Component } from 'react';
import { getTxt, toDecimalString, toItalianDateString } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';
import { graphql, compose } from 'react-apollo';
import { Paper, Typography } from '@material-ui/core';
import gql from 'graphql-tag';
import { p } from 'components/form/Proto';
import $ from 'jquery';
import { HashRouter, BrowserRouter, Route, Link, withRouter } from 'react-router-dom';
import { Button } from '@material-ui/core';
import * as Icon from '@material-ui/icons';
import { authInfo, getToken } from 'global/auth';
import { ReportTypes } from 'global/reporting';
import ChartCard from 'components/dashboard/ChartCard';
import ChartistGraph from 'react-chartist';
var Chartist = require("chartist");
import {
    SortingState, SelectionState, FilteringState, GroupingState,
    IntegratedFiltering, IntegratedGrouping, IntegratedSorting, IntegratedSelection,
} from '@devexpress/dx-react-grid';
import classNames from 'classnames';
import {
    Grid,
    Table,
    VirtualTable, TableHeaderRow, TableFilterRow, TableSelection, TableGroupRow,
    GroupingPanel, DragDropProvider, TableColumnReordering, Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import LinearProgressEx from '../../components/LinearProgressEx';
import * as queries from '../../modules/contract/contractQueries';
import { getLabel, DictionaryTypes, getDictionary } from '../../global/dictionaryCache';
import RegularCard from '../../components/dashboard/RegularCard';
import MaterialForm from '../../components/form/MaterialForm';
import GridEx from 'components/form/GridEx';
import c from '../../components/form/Components';
import { } from '../../global/dictionaryCache';
import { addDescriptor } from '../../global/entityDescriptors';
import ComponentEx from '../../components/ComponentEx';
import { getReportDataQuery } from './reportQueries';
import { tableMessages } from '../../global/appGlobal';
import DonutChart from 'react-donut-chart/lib/dist/DonutChart';
import { roundNumber } from './CollaboratorePerformance_Report';

let reports_filter_obj_descr;

const OBG_DESCR_KEY = 'claims_reports_filter';

let reportsFilters = {
    movimento_start_date_from: null,
    movimento_start_date_to: null,
    ins_company_id: null,
    broker_id: 'all',
    is_paid: 'all',
    insurance_type: 'all',
    group_by_subtotal: 'all_items',
    show_agency_totals: authInfo.isMasterBroker() || authInfo.isAgencyAdministrator(),
};


const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        //  margin: theme.spacing.unit * 2,
    },
    button: {
        margin: theme.spacing.unit,
    },
    formButtons: {
        float: 'right',
        margin: '15px',
        whiteSpace: 'normal',
    },

    badge: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        marginTop: 0,

    },
    gridRoot: {
        height: '100%',
    },

    tableHeader: {
        backgroundColor: theme.palette.background.paper,
    },
    leftIcon: {
        marginRight: theme.spacing.unit,
    },
    iconSmall: {
        fontSize: 20,
    },
    filterForemRoot: {
        paddingLeft: theme.spacing.unit * 2,
        paddingRight: theme.spacing.unit * 2,
    },
    totalsRow: {
        backgroundColor: theme.palette.grey['200'],
        fontWeight: 'bold',
    },
});


class ClaimsReport extends ComponentEx {

    constructor(props) {
        super(props);
        this.generateReport = this.generateReport.bind(this);
        this.formChanged = this.formChanged.bind(this);
        this.appplyFilters = this.appplyFilters.bind(this);
        reports_filter_obj_descr = {
            from: {
                id: 'from',
                label: getTxt('Incident Date Time from'),
                type: 'DATEONLY',
                validators: [],
            },
            to: {
                id: 'to',
                label: getTxt('Incident Date Time to'),
                type: 'DATEONLY',
                validators: [],
            },
            companyId: {
                id: 'companyId',
                label: getTxt('Company'),
                type: 'INTEGER',
                validators: [],
            },

        };

        reportsFilters = {
            from: null,
            to: null,
            companyId: null,
        };

        addDescriptor(OBG_DESCR_KEY, reports_filter_obj_descr);

    }

    state = {

        from: null,
        to: null,
        companyId: null,
    };

    formSetValues = { func: undefined };

    generateReport() {
        const newWindow = window.open("", "_blank");
        newWindow.document.write(getTxt('Generating PDF Report, please wait...'));
        this.props.getOneTimeToken({

        }, this).then(
            (resp) => {



                if (resp.data && resp.data.oneTimeTokenMutation && resp.data.oneTimeTokenMutation.token) {

                    newWindow.location.href = `${window.apiUrl}files/reports?ott=${resp.data.oneTimeTokenMutation.token}&type=${ReportTypes.ContractItemsReport}&params=${JSON.stringify(reportsFilters)}`;
                    newWindow.focus();
                }


            });
    }

    formChanged(field, value, type, prevVal) {
        reportsFilters[field] = value;
        const stateVal = {};
        this.state[field] = value;
    }

    appplyFilters() {
        this.props.data.refetch({
            reportType: 'claims',
            params: JSON.stringify({
                from: this.state.from,
                to: this.state.to,
                companyId: this.state.companyId,
            }),
        });
    }

    render() {

        const columns = [
            { name: 'status', title: getTxt('Status') },
            { name: 'count', title: getTxt('Count') },
        ];


        const { classes } = this.props;
        const reportData = this.props.data.reportData ? JSON.parse(this.props.data.reportData) : null;

        let itemsStatus = reportData ? reportData.status : [];
        let itemsRespType = reportData ? reportData.responsibilityType : [];
        let itemsTipologia = reportData ? reportData.tipologia : [];
        const chartDataStatus = [];
        const chartDataRespType = [];
        const chartDataTipologia = [];

        const chart_colors = [
            '#f19f3e',
            '#63a059',
            '#59bbd1',
            '#f44336', '#e91e63', '#9c27b0', '#e15746', '#673ab7', '#3f51b5', '#2196f3', '#03a9f4', '#00bcd4', '#009688', '#4caf50', '#8bc34a', '#cddc39', '#ffeb3b', '#ffc107', '#ff9800', '#ff5722', '#795548', '#607d8b'];




        itemsStatus.forEach((i) => {

            chartDataStatus.push(
                {
                    label: getLabel(DictionaryTypes.ClaimStatus, i.status),
                    value: i.count,
                },
            );

        });

        let chartH;
        if (chartDataStatus.length > 15) {
            chartH = 770 * chartDataStatus.length / 15;
        }
        ////////////////////////////////////////////////////////
        itemsRespType.forEach((i) => {

            chartDataRespType.push(
                {
                    label: getLabel(DictionaryTypes.ResponsibilityType, i.responsibility_type),
                    value: i.count,
                },
            );

        });

        let chartHRespType;
        if (chartDataRespType.length > 15) {
            chartHRespType = 770 * chartDataRespType.length / 15;
        }
        ////////////////////////////////////////////////////////////
        itemsTipologia.forEach((i) => {

            chartDataTipologia.push(
                {
                    label: i.tipologia_di_danno ? i.tipologia_di_danno : 'non specificato',
                    value: i.count,
                },
            );

        });

        let chartHTypology;
        if (chartDataTipologia.length > 15) {
            chartHTypology = 770 * chartDataTipologia.length / 15;
        }

        return (

            <div className={classes.root}>
                <LinearProgressEx loading={this.props.data.loading} />
                {!this.props.noCaption && <RegularCard

                    headerColor="red"
                    cardTitle={getTxt('Claims')}
                    cardSubtitle={''}
                    content={
                        <div>{''}</div>
                    }
                />}

                <div className={classes.filterForemRoot}>
                    <MaterialForm
                        dataSource={reportsFilters}
                        objType={OBG_DESCR_KEY}
                        onSubmit={this.onSubmit}
                        onChange={this.formChanged}
                        setValues={this.formSetValues}
                    >

                        <c.field f="from" />
                        <c.field f="to" />
                        {(authInfo.isAgencyAdministrator() || authInfo.isMasterBroker()) && <c.field f="companyId" ui="company_select" />}

                        <GridEx item sm={(authInfo.isAgencyAdministrator() || authInfo.isMasterBroker()) ? 6 : 12} xs={12}>
                            <div className={classes.formButtons} >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.appplyFilters}
                                    className={classes.button}
                                >
                                    {/* <Icon.Print className={classNames(classes.leftIcon)} /> */}
                                    {getTxt('Apply Filters')}
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        reportsFilters.from = '';
                                        reportsFilters.to = '';
                                        reportsFilters.companyId = '';

                                        this.formSetValues.func({
                                            from: '',
                                            to: '',
                                            companyId: '',
                                        });
                                        this.setState({
                                            from: '',
                                            to: '',
                                            companyId: '',
                                        });
                                        this.appplyFilters();
                                    }}
                                    className={classes.button}
                                >
                                    {getTxt('Reset Filters')}
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        const url = `/#/reports/claims/print/${this.state.from ? this.state.from : false}/${this.state.to ? this.state.to : false}/${this.state.companyId ? this.state.companyId : false}`;
                                        const winReport = window.open(url, '_blank');
                                        winReport.focus();
                                    }}
                                    className={classes.button}
                                >
                                    <Icon.Print className={classNames(classes.leftIcon)} />
                                    {getTxt('Printable View')}
                                </Button>
                            </div>
                        </GridEx>

                        {/* {items.length > 0 && <GridEx item sm={12} xs={12}>
                            <ChartistGraph
                                style={{ with: 300, height: 300, marginBottom: 50 }}
                                data={chartData}
                                type="Pie"
                                options={chartOptions}
                                responsiveOptions={responsiveOptions}
                            />
                        </GridEx>} */}

                        <div style={{ paddingTop: 20, display: 'flex', flexWrap: 'wrap' }}>

                            <Paper style={{ margin: 10, padding: 10 }}>
                                <Typography variant="h5">Stato</Typography>
                                <DonutChart
                                    data={chartDataStatus}
                                    clickToggle={false}
                                    colors={chart_colors}
                                    strokeColor='#FFFFFF'
                                    height={chartH}
                                />
                            </Paper>
                            <Paper style={{ margin: 10, padding: 10 }} >
                                <Typography variant="h5">Tipo di Responsabilità</Typography>
                                <DonutChart
                                    data={chartDataRespType}
                                    clickToggle={false}
                                    colors={chart_colors}
                                    strokeColor='#FFFFFF'
                                    height={chartHRespType}
                                />
                            </Paper>
                            <Paper style={{ margin: 10, padding: 10 }}>
                                <Typography variant="h5">Tipologia di Danno</Typography>
                                <DonutChart
                                    data={chartDataTipologia}
                                    clickToggle={false}
                                    colors={chart_colors}
                                    strokeColor='#FFFFFF'
                                    height={chartHTypology}
                                />
                            </Paper>
                        </div>

                    </MaterialForm>
                </div>


            </div>
        );
    }
}

export default compose(
    graphql(getReportDataQuery, {
        options: props => (
            {
                variables: { reportType: 'claims', params: JSON.stringify({}) },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }
        ),
    }),

)(withRouter(withStyles(styles)(ClaimsReport)));
