import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { getTxt } from 'global/appGlobal';
import { graphql, compose } from 'react-apollo';
import * as queries from '../../modules/contract/contractQueries';
import { Dialog, DialogContent } from '@material-ui/core';
import LinearProgressEx from '../../components/LinearProgressEx';
import { p } from 'components/form/Proto';
import $ from 'jquery';
import MaterialForm from '../../components/form/MaterialForm';
import c from '../../components/form/Components';
import { getInternalInsTypeCd } from '../../global/dictionaryCache';
import ComponentEx from '../../components/ComponentEx';



let AfterSubmit;


const styles = theme => ({
    button: {
        margin: theme.spacing.unit,
    },

    chip: {
        margin: theme.spacing.unit,
    },
    badge: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        marginTop: 0,
    },

    gridRoot: {
        height: '100%',
    },

    row: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',

    },
    formCaption: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit * 2,
        width: '100%',
        position: 'relative',
        height: 40,
    },
    formCaptionLabel: {
        position: 'absolute',
        //color: theme.palette.primary1,
        backgroundColor: 'transparent',
        left: 25,
        paddingLeft: 10,
        paddingRight: 10,
    },

    rootPaper: theme.mixins.gutters({

        paddingTop: 0,
        paddingBottom: 0,
        marginTop: 0,// theme.spacing.unit * 3,

    }),

    formHeaderRoot: {
        padding: theme.spacing.unit * 2,
        position: 'relative',
    },

    formHeaderFieldLabel: {
        color: theme.palette.text.disabled,
        fontWeight: 'bold',

    },

    tableHeader: {
        backgroundColor: theme.palette.background.paper,
    }

});


class Contract_General_Data_Edit extends ComponentEx {

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);

    }

    state = {
        value: 0,
    };

    componentDidMount() {
        $("table[class*='Table-headTable']").addClass(this.props.classes.tableHeader);
    }

    handleChange = (value) => {
        this.setState({ value });
    };

    onSubmit(e, { obj, showErrors, hideErrors }) {
        const originalContract = this.props.data.contract;
        obj.insurance_contract_id = originalContract.insurance_contract_id;



        this.props.updateContract({
            variables: { contract: obj },
            refetchQueries: [
                {
                    query: queries.allContractsQuery,
                    variables: { customerId: obj.customer_id },
                },
                {
                    query: queries.contractByIdQuery,
                    variables: {
                        id: this.props.id,
                        insType: getInternalInsTypeCd(this.props.insType ? this.props.insType : this.props.match.params.insType),
                    },
                },

            ],
        }, this).then(
            (resp) => {
                hideErrors();
                if (resp.data.updateContractMutation &&
                    resp.data.updateContractMutation.validation &&
                    resp.data.updateContractMutation.validation.length > 0) {
                    showErrors(resp.data.updateContractMutation.validation);
                } else {
                    AfterSubmit();

                }
            });
    }

    render() {

        const loading = this.props.data.loading;
        const contract = this.props.data.contract;
        AfterSubmit = this.props.AfterSubmit;

        return (

            <Dialog onClose={() => { this.props.AfterSubmit(); }} open >
                <DialogContent>
                    <LinearProgressEx loading={loading} />

                    {contract &&
                        <div>
                            <MaterialForm dataSource={contract} objType="contract" onSubmit={this.onSubmit}>

                                <p.group l={getTxt('Tracciamento del Simpli di Polizza')} />
                                <c.field l={getTxt('Tracciamento')} f="contract_delivery_state" ui="select" >
                                    {
                                        [
                                            { label: getTxt('Nessuna Inf.'), value: 'Nessuna Inf.' },
                                            { label: getTxt('Da Consegnare'), value: 'Da Consegnare' },
                                            { label: getTxt('Consegnare al Cliente'), value: 'Consegnare al Cliente' },
                                            { label: getTxt('Consegnare al Collaboratore'), value: 'Consegnare al Collaboratore' },
                                            { label: getTxt('Consegnare alla Compagnia'), value: 'Consegnare alla Compagnia' },
                                            { label: getTxt('Firma OTP'), value: 'firma_otp' },
                                        ]
                                    }
                                </c.field>
                                <c.field l={getTxt('Data Oper.')} f="contract_delivery_date" />
                                <c.field l={getTxt('Note di Consegna')} f="contract_delivery_note" sm={12} />

                                <c.formButtons sm={12} onCancel={() => { this.props.AfterSubmit(); }} />
                            </MaterialForm>


                        </div>}

                </DialogContent>

            </Dialog>
        );
    }
}

export default compose(

    graphql(queries.updateContractMutation, {
        name: 'updateContract',
        options: {
          
            errorPolicy: 'all',
        },
    }),

    graphql(queries.contractByIdQuery, {
        options: props => (
            {
                variables: {
                    id: props.id,
                    insType: getInternalInsTypeCd(props.insType ? props.insType : props.match.params.insType),
                },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }
        ),
    }),

)(withStyles(styles)(Contract_General_Data_Edit));