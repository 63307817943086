import React from 'react';
import { graphql, compose } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import {
    SortingState, FilteringState, IntegratedFiltering, IntegratedSorting, RowDetailState,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    VirtualTable, TableHeaderRow, TableFilterRow, TableColumnResizing, TableRowDetail,
} from '@devexpress/dx-react-grid-material-ui';

import CurrencyGridFormatter from 'components/CurrencyGridFormatter';
import { IconButton } from '@material-ui/core';

import * as Icons from '@material-ui/icons';
import ComponentEx from 'components/ComponentEx';
import { Button } from '@material-ui/core';
import c from 'components/form/Components';
import { getTxt, toItalianDateString, toDecimalString, filterRowMessages, tableMessages, createGridResizerCols } from '../../global/appGlobal';
import LinkToCustomerById from '../../components/LinksToObjectById';
import LinearProgressEx from '../../components/LinearProgressEx';
import { getLabel, getDictionary, DictionaryTypes } from '../../global/dictionaryCache';
import { allRequestsQuery } from '../../modules/request/requestQeries';
import { Menu, MenuItem } from '@material-ui/core';
import * as Icon from '@material-ui/icons';
import RegularCard from '../../components/dashboard/RegularCard';
import * as queries from '../../modules/company/CompanyQueries';
import Company_Payment_Edit from '../../modules/company/Company_Payment_Edit';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Tooltip } from '@material-ui/core';
import ChangeLogDetails from './ChangeLogDetails';

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        margin: theme.spacing.unit * 3,
    },
    button: {
        marginRight: theme.spacing.unit,
    },

    tableHeader: {
        backgroundColor: theme.palette.background.paper,
    },
    menuItem: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& $text, & $icon': {
                color: theme.palette.common.white,
            },
        },
    },
    text: {},
    icon: {},
    totalsRow: {
        backgroundColor: theme.palette.grey['200'],
        fontWeight: 'bold',
    },
});

const toLowerCase = value => String(value).toLowerCase();

const filterUser = (cellValue, e, row) => {
    const name = toLowerCase(row.user.user_display_name);
    return name.includes(toLowerCase(e.value));
};

const LogDetailContainer = ({ row, classes }) => (
    <ChangeLogDetails
        logId={row.system_log_id}
    />
);

class ChangeLog extends ComponentEx {

    state = {
        expandedRowIds: [],
    };

    constructor() {
        super();
        this.getRowId = row => row.system_log_id;
    }

    render() {
        window.refetchCompanyPaymebts = this.props.data.refetch();

        const columns = [
            { name: 'date', title: getTxt('Date') },
            { name: 'user_id', title: getTxt('Who') },
            { name: 'entity_name', title: getTxt('Entity') },
            { name: 'state', title: getTxt('Status') },
            { name: 'action', title: getTxt('Action') },
            { name: 'notes', title: getTxt('Notes') },
        ];

        createGridResizerCols(this.state, columns, {}, 210);

        const { classes } = this.props;

        const items = this.props.data.allSystemLogs ? this.props.data.allSystemLogs : [];
        const loading = this.props.data.loading;

        const caption = getTxt('Changes in System');
        const subtitle = getTxt('List of changes in system.');

        let expanded = this.state.expandedRowIds;
        if (expanded.length === 0 && items.length > 0) {
            expanded = [items[0].system_log_id];
        }

        return (

            <div className={classes.root}>
                <LinearProgressEx loading={loading} />

                {!this.props.noCaption && <RegularCard

                    headerColor="red"
                    cardTitle={caption}
                    cardSubtitle={subtitle}
                    content={
                        <div>

                        </div>

                    }
                />}

                <Grid

                    rows={items}
                    columns={columns}

                >


                    <FilteringState
                        defaultFilters={[{}]}
                    />

                    <SortingState
                        defaultSorting={[

                        ]}
                    />

                    <IntegratedFiltering
                        columnExtensions={[
                            { columnName: 'user_id', predicate: filterUser },
                        ]}
                    />
                    <SortingState
                        defaultSorting={[]}
                    />
                    <RowDetailState
                        expandedRowIds={expanded}
                        onExpandedRowIdsChange={(expandedIds) => {
                            this.setState({ expandedRowIds: expandedIds });
                        }}
                    />
                    <IntegratedSorting />

                    <VirtualTable
                        messages={tableMessages}
                        rowComponent={({ row, ...restProps }) => {
                            if (row.insurance_company_id === -1) {
                                return (<VirtualTable.Row
                                    className={classes.totalsRow}
                                    {...restProps}
                                />);
                            }
                            return (<VirtualTable.Row {...restProps} />);
                        }}
                        cellComponent={({ row, column, style, ...props }) => {

                            if (column.name === 'date') {
                                return (<VirtualTable.Cell>
                                    {toItalianDateString(row.date, true)}
                                </VirtualTable.Cell>);
                            }
                            if (column.name === 'user_id') {
                                return (<VirtualTable.Cell>
                                    {row.user && row.user.user_display_name ? row.user.user_display_name : ''}
                                </VirtualTable.Cell>);
                            }
                            if (column.name === 'request_link') {
                                if (row.request_link) {
                                    return (<VirtualTable.Cell>
                                        <Link style={{ padding: '6px', color: '#9C27B0', fontWeight: 'bold' }}
                                            to={row.request_link}
                                        >
                                            {row.request_label}
                                        </Link>
                                    </VirtualTable.Cell>);
                                }
                            }
                            if (column.name === 'client_link') {
                                if (row.client_link) {
                                    return (<VirtualTable.Cell>
                                        <Link style={{ padding: '6px', color: '#9C27B0', fontWeight: 'bold' }}
                                            to={row.client_link}
                                        >
                                            {row.client_label}
                                        </Link>
                                    </VirtualTable.Cell>);
                                }
                            }

                            return <VirtualTable.Cell {...props} />;
                        }
                        }
                    />

                    <TableFilterRow
                        messages={filterRowMessages}
                        cellComponent={({ column, filter, onFilter, ...props }) => {
                            return <TableFilterRow.Cell onFilter={onFilter} filter={filter} {...props} />;
                        }}
                    />

                    <TableColumnResizing
                        columnWidths={this.state.columnWidths}
                        onColumnWidthsChange={(cols) => { this.setState({ columnWidths: cols }); }}
                        defaultColumnWidths={this.state.defColumnWidths}
                    />
                    <TableRowDetail
                        contentComponent={e => LogDetailContainer(e)}
                    />

                    <TableHeaderRow />

                </Grid>

            </div >
        );
    }


}

export const allSystemLogsSchemaQuery = gql`
query allSystemLogsSchemaQuery ($entityId: Int) {
    allSystemLogs(entityId: $entityId ){
        system_log_id
        date
        user_id
        entity_id
        entity_name
        state
        action
        notes
        
        user{
            user_display_name
        }
    }
}`;



export default compose(

    graphql(allSystemLogsSchemaQuery, {
        options: {
            options: props => (
                {
                    variables:
                    {
                        entityId: props.entityId,
                    },
                    fetchPolicy: 'network-only',
                    errorPolicy: 'all',
                }),

        },
    }),

)(withStyles(styles)(ChangeLog));
