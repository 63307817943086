import React, { Component } from 'react';
import { getTxt } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import { Paper } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { AppBar } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { TextField } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';

import * as Icon from '@material-ui/icons';
import { Checkbox } from '@material-ui/core';

import GridEx from 'components/form/GridEx';

import { Button } from '@material-ui/core';
import moment from 'moment';
import { Badge } from '@material-ui/core';

import ComponentEx from 'components/ComponentEx';



import { LinearProgress } from '@material-ui/core';
import { graphql, compose } from 'react-apollo';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';

import c from '../../components/form/Components';
import LinearProgressEx from '../../components/LinearProgressEx';
import { getLabel, DictionaryTypes, getDictionary } from '../../global/dictionaryCache';
import MaterialForm from '../../components/form/MaterialForm';
import MFormText from '../../components/form/MFormText';
import * as queries from '../../modules/company/CompanyQueries';


const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing.unit * 2,
    },
    dialogText: {
        color: theme.palette.error.main,
    },
});

let history;
let AfterSubmit;

class CompanyGuarantyEdit extends ComponentEx {

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.formChanged = this.formChanged.bind(this);
    }

    state = {
        open: false,
        insurance_type_cd: undefined,
    };

    onSubmit(e, { obj, showErrors, hideErrors }) {
        const original = this.props.data.insCompGuarantyById;
        obj.insurance_company_guaranty_id = original.insurance_company_guaranty_id;
        obj.insurance_company_id = this.props.companyId;
        delete obj.allGuaranties;


        for (let i = 0; i < this.props.existing.length; i += 1) {
            const exist = this.props.existing[i];
            if (obj.insurance_company_guaranty_id === exist.insurance_company_guaranty_id) {
                continue;
            }
            if (obj.guaranty_type_id === exist.guaranty_type_id &&
                obj.insurance_company_id === exist.insurance_company_id) {
                this.setState({ existDlgOpen: true });
                return;
            }
        }



        this.props.updateInsCompGuaranty({
            variables: { insCompGuaranty: obj },
            refetchQueries: [
                {
                    query: queries.allInsCompGuaranty,
                    variables: { companyId: this.props.companyId },
                },
                // {
                //     query: queries.insCompGuarantyById,
                //     variables: { id: original.insurance_company_guaranty_id },
                // },
            ],
        }, this).then(
            (resp) => {
                hideErrors();
                if (resp.data.updateInsCompGuarantyMutation &&
                    resp.data.updateInsCompGuarantyMutation.validation &&
                    resp.data.updateInsCompGuarantyMutation.validation.length > 0) {
                    showErrors(resp.data.updateInsCompGuarantyMutation.validation);
                } else {
                    AfterSubmit(resp.data.updateInsCompGuarantyMutation.id);
                }
            });
    }

    formChanged(field, value, type, prevVal) {
        if (field === 'insurance_type_cd') {
            this.setState({ insurance_type_cd: value });
        }
    }

    render() {


        const { classes } = this.props;

        const loading = this.props.data.loading;
        let item = this.props.data.insCompGuarantyById;
        history = this.props.history;
        AfterSubmit = this.props.AfterSubmit;



        let guaranties = [];
        const exists = this.props.existing;
        if (item) {
            guaranties = item.allGuaranties.map(i => ({ value: Number.parseInt(i.guaranty_type_id), label: i.guaranty_name, insurance_type_cd: i.insurance_type_cd }));

            guaranties = guaranties.filter((g) => {
                return !exists.find(e => g.value === e.guaranty_type_id);
            });

        }


        return (




            <div>

                <Dialog onClose={() => { this.props.AfterSubmit(); }} open >
                    <DialogContent>

                        <Typography variant="h4" color="inherit" noWrap={false}>{getTxt('Nuovo Company Guaranty')}</Typography>
                        <LinearProgressEx loading={loading} />

                        {item &&
                            <div>
                                <MaterialForm dataSource={item} objType="guaranty_type" onSubmit={this.onSubmit} onChange={this.formChanged} >

                                    <c.field l={getTxt('Ramo Personalizzato')} f="insurance_type_cd" ui="select" >
                                        {getDictionary(DictionaryTypes.InsuranceType)}
                                    </c.field>

                                    <c.field l={getTxt('Guaranty Type')} f="guaranty_type_id" ui="select" >
                                        {guaranties.filter(g => g.insurance_type_cd === this.state.insurance_type_cd)}
                                    </c.field>


                                    <c.field f="default_broker_percent" l={getTxt('Default Broker Percent')} />
                                    <c.field f="default_agency_percent" l={getTxt('Default Agency Percent')} />

                                    <c.formButtons sm={12} onCancel={() => { this.props.AfterSubmit(); }} />
                                </MaterialForm>

                            </div>
                        }

                    </DialogContent>

                </Dialog>

                <Dialog
                    className={classes.dialog}
                    onClose={() => { this.setState({ existDlgOpen: false }) }}
                    open={this.state.existDlgOpen}
                >
                    <DialogTitle> <span className={classes.dialogText}>{getTxt('The Guaranty already exists.')}</span></DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <span className={classes.dialogText}>
                                {getTxt('The Company Guaranty with such Guaranty Type already exists.')}
                            </span>
                        </DialogContentText>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { this.setState({ existDlgOpen: false }); }} color="primary" >
                            {getTxt('Ok')}
                        </Button>

                    </DialogActions>
                </Dialog>

            </div>




        );
    }
}




export default compose(
    graphql(queries.insCompGuarantyById, {
        options: props => (
            {
                variables: {
                    id: (props.id ? props.id : 'new'),
                },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }
        ),
    }),
    graphql(queries.updateInsCompGuarantyMutation, {
        name: 'updateInsCompGuaranty',
        options: {
            errorPolicy: 'all',
          
        },
    }),
)(withStyles(styles)(CompanyGuarantyEdit));
