import React, { Component } from 'react';
import gql from 'graphql-tag';
import { graphql, compose } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import $ from 'jquery';
import {
    SortingState, SelectionState, FilteringState, GroupingState,
    IntegratedFiltering, IntegratedGrouping, IntegratedSorting, IntegratedSelection,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    VirtualTable, TableHeaderRow, TableFilterRow, TableSelection, TableGroupRow,
    GroupingPanel, DragDropProvider, TableColumnReordering, Toolbar,
} from '@devexpress/dx-react-grid-material-ui';


import { HashRouter, BrowserRouter, Route, Link, withRouter } from 'react-router-dom';

import ComponentEx from 'components/ComponentEx';
import Tooltip from '@material-ui/core/Tooltip';
import { IconButton } from '@material-ui/core';
import { TableCell } from '@material-ui/core';
import { Button } from '@material-ui/core';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';

//import * as Icon from '@material-ui/icons';
import * as Icon from '@material-ui/icons';
import c from 'components/form/Components';
import { getTxt, filterRowMessages, tableMessages } from '../../global/appGlobal';
import LinkToCustomerById from '../../components/LinksToObjectById';
import LinearProgressEx from '../../components/LinearProgressEx';
import { getLabel, getDictionary, DictionaryTypes } from '../../global/dictionaryCache';
import RegularCard from '../../components/dashboard/RegularCard';
import * as queries from '../../modules/company/brokerPercentQueries';
import BrokerPercentEdit from '../../modules/company/BrokerPercentEdit';
import { authInfo } from 'global/auth';

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    button: {
        marginRight: theme.spacing.unit,
    },

    tableHeader: {
        backgroundColor: theme.palette.background.paper,
    },
    inGridButton: {
        width: 36,
        height: 30,
        marginRight: theme.spacing.unit * 2,
    },
});

class BrokerPercentSearch extends ComponentEx {

    componentWillMount() {
        // if (!this.props.data.loading) {
        //     this.props.data.refetch();
        // }

    }

    state = {
        delDlgOpen: false,
        delId: undefined,
        editId: undefined,
    };

    handleDelete(id) {

        const data = this.props.data;
        this.props.deleteBrokerPercentMutation({
            variables: { id: this.state.delId },
            refetchQueries: [
                {
                    query: queries.allBrokerPercentsQuery,
                    variables: { companyId: this.props.companyId, brokerId: this.props.brokerId },
                },
            ],
        }).then(
            (resp) => {
                //data.refetch();
            }
        );
        this.setState({ delDlgOpen: false });
    }

    render() {

        if (this.props.quiet) {
            return (<LinearProgressEx loading={this.props.data.loading} />);
        }

        const { classes } = this.props;

        const items = this.props.data.allBrokerPercents ? this.props.data.allBrokerPercents : [];

        const columns = [
            { name: 'broker_percent_id', title: getTxt('Actions') },
            { name: 'broker_percent', title: getTxt('Broker Percent') },
            { name: 'broker_percent_extra', title: getTxt('% Extra Collaboratore') },
            { name: 'broker_id', title: getTxt('Broker') },
            { name: 'guaranty_type_id', title: getTxt('Guaranty Type') },
            { name: 'insurance_company_id', title: getTxt('Company') },

        ];

        return (

            <div className={classes.root}>
                <LinearProgressEx loading={this.props.data.loading} />
                {!this.props.noCaption && <RegularCard

                    headerColor="red"
                    cardTitle={getTxt('Brokers Percent')}
                    cardSubtitle={getTxt('List of defined Brokers Percents')}
                    content={
                        <div>
                        </div>
                    }
                />}

                {(authInfo.isAgencyAdministrator() || authInfo.isMasterBroker()) &&
                    <Button
                        color="primary"
                        className={classes.button}
                        onClick={() => { this.setState({ editId: 'new', }); }}
                    >
                        {getTxt('Add new Broker Percent')}
                    </Button>
                }

                <Grid
                    rows={items}
                    columns={columns}
                >
                    <FilteringState
                        defaultFilters={[{}]}
                    />
                    <SortingState
                        defaultSorting={[]}
                    />
                    <IntegratedSorting />
                    <IntegratedFiltering />

                    <VirtualTable
                        messages={tableMessages}
                        cellComponent={({ row, column, style, ...props }) => {
                            if (column.name === 'broker_percent_id') {
                                return (<VirtualTable.Cell>

                                    <Tooltip title={getTxt('Edit')}>
                                        <IconButton size="small" onClick={() => { this.setState({ editId: row.broker_percent_id }); }} className={classes.inGridButton} >
                                            <Icon.Edit />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title={getTxt('Delete')}>

                                        <IconButton size="small" onClick={() => { this.setState({ delDlgOpen: true, delId: row.broker_percent_id }); }} className={classes.inGridButton} >
                                            <Icon.Delete />
                                        </IconButton>
                                    </Tooltip>

                                </VirtualTable.Cell>);
                            }
                            if (column.name === 'broker_id') {
                                return (<VirtualTable.Cell><Link to={`/brokers/${row.broker.broker_id}`} >{row.broker.display_name}</Link></VirtualTable.Cell>);
                            }
                            if (column.name === 'guaranty_type_id') {
                                return (<VirtualTable.Cell>{row.guaranty_type.guaranty_name}</VirtualTable.Cell>);
                            }
                            if (column.name === 'insurance_company_id') {
                                return (<VirtualTable.Cell><Link to={`/company/view/${row.company.insurance_company_id}`} >{row.company.company_name}</Link></VirtualTable.Cell>);
                            }
                            return <VirtualTable.Cell {...props} />;
                        }}
                    />

                    <TableFilterRow
                        messages={filterRowMessages}
                        cellComponent={({ column, filter, onFilter, ...props }) => {
                            if (column.name === 'broker_type_cd') {
                                return (
                                    <c.gridColFilter noFilterVal={getTxt('Tutti', '#a4b1ua7')} setFilter={onFilter} >
                                        {getDictionary(DictionaryTypes.BrokerType)}
                                    </c.gridColFilter>);
                            }

                            return <TableFilterRow.Cell onFilter={onFilter} filter={filter} {...props} />;
                        }}
                    />

                    <TableHeaderRow showSortingControls />

                </Grid>

                {
                    this.state.editId &&
                    <BrokerPercentEdit
                        id={this.state.editId}
                        companyId={this.props.companyId}
                        brokerId={this.props.brokerId}
                        existing={items}
                        AfterSubmit={((id) => {
                            this.setState({ editId: undefined, deleteId: undefined, createOverride: false });
                            this.props.data.refetch();
                        })}

                    />
                }

                <Dialog onClose={() => { this.setState({ delDlgOpen: false, delId: null }); }} open={this.state.delDlgOpen}>
                    <DialogTitle>{getTxt('Delete Broker Percent.')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <span> {getTxt('Broker percent will be delete on server.')} </span>
                            <br />
                            <span > {getTxt('Are you sure do you want delete the broker percent ?')} </span>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleDelete(this.state.delId)} color="primary">
                            {getTxt('Ok')}
                        </Button>
                        <Button onClick={() => { this.setState({ delDlgOpen: false, delId: null }); }} color="primary">
                            {getTxt('Annula')}
                        </Button>

                    </DialogActions>
                </Dialog>
            </div >
        );
    }
}

export default compose(
    graphql(queries.allBrokerPercentsQuery, {
        options: props => (
            {
                variables: {
                    brokerId: props.brokerId,
                    companyId: props.companyId,
                },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }
        ),
    }),
    graphql(queries.deleteBrokerPercentMutation, {
        name: 'deleteBrokerPercentMutation',
        options: {
            errorPolicy: 'all',
        },
    }),
)(withRouter(withStyles(styles)(BrokerPercentSearch)));
