import React, { Component } from 'react';
import { getTxt } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';
import { withTheme } from '@material-ui/core';

import { Typography } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';



import { Paper } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { AppBar } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { TextField } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import TabContainer from 'components/TabContainer';


import { Tabs, Tab } from '@material-ui/core';



import * as Icon from '@material-ui/icons';
import { Checkbox } from '@material-ui/core';


import GridEx from 'components/form/GridEx';


import { Button } from '@material-ui/core';
import moment from 'moment';
import { Badge } from '@material-ui/core';

import CmdBar from 'components/form/CmdBar';

import { appChageTheme } from 'app/app';

import { p } from 'components/form/Proto';
import $ from 'jquery';
import UnderDevScreen from 'components/UnderDevScreen';
import ComponentEx from '../../components/ComponentEx';


const styles = theme => ({
    button: {
        margin: theme.spacing.unit,
    },

    badge: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        marginTop: 0,
    },

    gridRoot: {
        height: '100%',
    },


    formCaption: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit * 2,
        width: '100%',
        position: 'relative',
        height: 40,
    },
    formCaptionLabel: {
        position: 'absolute',
        //color: theme.palette.primary1,
        backgroundColor: 'transparent',
        left: 25,
        paddingLeft: 10,
        paddingRight: 10,
    },

    rootPaper: theme.mixins.gutters({

        paddingTop: 0,
        paddingBottom: 0,
        marginTop: 0,// theme.spacing.unit * 3,

    }),

    formHeaderRoot: {
        padding: theme.spacing.unit * 2,
        position: 'relative',
    },

    formHeaderFieldLabel: {
        color: theme.palette.text.disabled,
        fontWeight: 'bold',

    }

});

class Settings extends ComponentEx {

    constructor(props) {
        super(props);

        this.themes = ['light', 'dark'];
        this.backgrounds = [
            'simple_blue.jpg',
            'microphone-head-mesh-bg_30.jpg',
            'the-grid-3015702_1920.jpg',
            'wall-3035971_1920.jpg',
        ];

        this.state = {
            value: 0,
            colorTheme: this.themes[0],
            backgrounds: this.backgrounds[0],
        };
    }



    componentDidMount() {
        $("div[class|='TableContainer']").css('height', '100%');
    }




    handleChange = (event, value) => {
        this.setState({ value });
    };

    onThemeChange(val) {
        this.setState({ val });
        appChageTheme.func(val);
    }

    render() {

        const { classes } = this.props;
        const { value } = this.state;
        /*
            <div>
                        <Icon.PermIdentity  style={{  width: 64, height: 64,  }} /> 
                    </div>                
           
        */


        return (






            <div>




                <Paper  >
                    <Grid container className={classes.formHeaderRoot}>
                        <Grid xs={12} item>





                            <Grid container  >
                                <Grid xs={6} item>

                                    <Typography variant="display2" > Settings </Typography>
                                </Grid>
                                <Grid xs={6} item>
                                    <div style={{ float: 'right' }}>
                                        <CmdBar >{['apply', 'set defaults']}</CmdBar>
                                    </div>
                                </Grid>
                            </Grid>


                        </Grid>
                    </Grid>

                </Paper>


                <Paper>
                    <Tabs
                        value={value}
                        onChange={this.handleChange}
                        scrollable
                        scrollButtons="auto"

                        indicatorColor="primary"
                        textColor="primary"
                    >
                        <Tab label={<div>General</div>} />
                        <Tab label={<div>User Preferences</div>} />


                    </Tabs>
                </Paper>



                {value === 0 && <TabContainer>

                    <p.form l="" >
                        <p.select l="Color Theme" v={this.state.colorTheme} onChange={(val) => { this.onThemeChange(val); }} >{this.themes}</p.select>
                        <p.select
                            l="Site Background"
                            v={this.state.backgrounds}
                            onChange={(val) => {
                                $('body').css('background', `#101010 url(${window.appUrl}/background/${val}) center center fixed`);        
                            }} 
                        >
                            {this.backgrounds}
                        </p.select>
                    </p.form>


                </TabContainer>}
                {value === 1 && <TabContainer> <UnderDevScreen />  </TabContainer>}






            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(Settings);
