import React, { Component } from 'react';
import { getTxt } from 'global/appGlobal';

import { withStyles } from '@material-ui/core/styles';
import { withTheme } from '@material-ui/core';

import { Typography } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';
import TabContainer from 'components/TabContainer';
import UnderDevScreen from 'components/UnderDevScreen';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { Paper } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { AppBar } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { TextField } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import { graphql, compose } from 'react-apollo';

import File_Search from 'modules/file/File_Search';
import ReactSVG from 'react-svg';

import { Tabs, Tab } from '@material-ui/core';



import * as Icon from '@material-ui/icons';
import { Checkbox } from '@material-ui/core';
//import TabsEx, { Tab } from 'global/components/tabs/TabsEx';


import { Button } from '@material-ui/core';
import moment from 'moment';
import { Badge } from '@material-ui/core';




import { p } from 'components/form/Proto';
import $ from 'jquery';
import CmdBar from 'components/form/CmdBar';

import MaterialForm from '../../components/form/MaterialForm';

import * as queries from '../../modules/contract/contractQueries';
import LinearProgressEx from '../../components/LinearProgressEx';
import c from '../../components/form/Components';

import { HashRouter, BrowserRouter, Route, Link, withRouter } from 'react-router-dom';
import { getLabel, DictionaryTypes } from '../../global/dictionaryCache';
import RequestGuarantiesList from '../../modules/request/RequestGuarantiesList';
import Contract_Item_Edit from '../../modules/contract/Contract_Item_Edit';

import Contract_Item_Details_View from 'modules/contract/Contract_Item_Details_View';
import ComponentEx from '../../components/ComponentEx';


let history;

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    button: {
        margin: theme.spacing.unit,
    },

    badge: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        marginTop: 0,
    },

    gridRoot: {
        height: '100%',
    },

    guarantyCaption: {

        ...theme.typography.button,
        //position: 'absolute',
        //backgroundColor: theme.palette.background.paper,
        //top: -13,
        // left: 25,
        // paddingLeft: 0,
        // paddingRight: 10,
        color: theme.palette.text.secondary,
        fontWeight: '900',


        marginTop: theme.spacing.unit * 2,
        marginLeft: theme.spacing.unit * 2,
    },

    formCaption: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit * 2,
        width: '100%',
        position: 'relative',
        height: 40,
    },
    formCaptionLabel: {
        position: 'absolute',
        //color: theme.palette.primary1,
        backgroundColor: 'transparent',
        left: 25,
        paddingLeft: 10,
        paddingRight: 10,
    },

    rootPaper: theme.mixins.gutters({

        paddingTop: 0,
        paddingBottom: 0,
        marginTop: 0,// theme.spacing.unit * 3,

    }),

    formHeaderRoot: {
        padding: theme.spacing.unit * 2,
        position: 'relative',
    },

    formHeaderFieldLabel: {
        color: theme.palette.text.disabled,
        fontWeight: 'bold',

    },

    tableHeader: {
        backgroundColor: theme.palette.background.paper,
    },
    headerIcon: {
        width: theme.spacing.unit * 8,
        height: theme.spacing.unit * 9,
        marginRight: theme.spacing.unit * 2,
    },
    cmdBar: {
        position: 'absolute',
        right: theme.spacing.unit * 3,
    },

});


const tabsStateGlobalId = 'Contract_Item_View';
class GridDetails_Contract_Item extends ComponentEx {

    state = {
        value: window[tabsStateGlobalId] ? window[tabsStateGlobalId] : 0,
        paymentDlgOpen: false,
    };

    componentDidMount() {
        $("table[class*='Table-headTable']").addClass(this.props.classes.tableHeader);

    }

    handleChange = (event, value) => {
        this.setState({ value });
    };

    componentWillUnmount() {
        window[tabsStateGlobalId] = this.state.value;
        delete window.refetchItemDetails[this.id];
        //this.props.data.refetch();
    }

    render() {

        const insType = this.props.insType ? this.props.insType : this.props.match.params.insType;
        const { classes } = this.props;

        const loading = this.props.data.loading;
        const item = this.props.data.ContractItem;
        history = this.props.history;

        const isFinancial = item && item.contract_item_type.is_financial === 'true';

        if (!window.refetchItemDetails) {
            window.refetchItemDetails = {};
        }
        window.refetchItemDetails[this.id] = this.props.data.refetch;

        return (
            <div >
                <LinearProgressEx loading={loading} />
                {item &&
                    <Paper className={classes.root}>

                        <Button
                            color="primary"
                            className={classes.button}
                            onClick={() => { this.props.history.push(`/contract/${insType}/${this.props.contractId}/item/${this.props.id}`); }}
                        >
                            {getTxt('Edit Movimento Details')}
                        </Button>

                        {isFinancial && <Button
                            color="primary"
                            className={classes.button}
                            onClick={() => { this.setState({ paymentDlgOpen: true }); }}
                        >
                            {getTxt('Edit payment info')}
                        </Button>}


                        {this.state.paymentDlgOpen &&
                            <Dialog
                                className={classes.dialog}
                                open={this.state.paymentDlgOpen}
                                onClose={() => {
                                    this.setState({ paymentDlgOpen: false });
                                }}
                            >

                                <DialogContent>
                                    <Contract_Item_Edit
                                        onlyPaymentDetails
                                        contractId={this.props.contractId}
                                        id={this.props.id}
                                        insType={insType}
                                        afterSubmit={(obj) => {
                                            this.setState({ paymentDlgOpen: false });
                                            if (window.refetchContractItemView_) {
                                                Object.keys(window.refetchContractItemView_).forEach(key => window.refetchContractItemView_[key]());
                                            }
                                            if (window.refetchContractList) {
                                                window.refetchContractList();
                                            }
                                            if (window.setDataPerfezionamentoInContractItemGrid) {
                                                window.setDataPerfezionamentoInContractItemGrid(obj.item_payment_date);
                                            }
                                        }}
                                    />

                                </DialogContent>

                            </Dialog>
                        }


                        <RequestGuarantiesList
                            history={this.props.history}
                            insType={insType}
                            inContract
                            noAddBtn
                            contractId={item.insurance_contract_id}
                            contractItemId={item.contract_item_id}
                            brokerPercent={item.brokerPercent}
                            contractItem={item}
                            base_ins_type={this.props.base_ins_type}
                        />







                    </Paper>

                }

            </div>
        );
    }
}

export default compose(

    graphql(queries.contractItemByIdQuery, {
        options: props => (
            {
                variables: {
                    id: props.id,
                    contractId: props.contractId,
                },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }
        ),
    }),

)(withStyles(styles)(GridDetails_Contract_Item));