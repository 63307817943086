import React, { Component } from 'react';
import { getTxt, toItalianDateString, toDecimalString } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';
import { graphql, compose } from 'react-apollo';

import $ from 'jquery';
import { Checkbox } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import c from '../../components/form/Components';
import { Button } from '@material-ui/core';
import GridEx from 'components/form/GridEx';
import DeleteIcon from '@material-ui/icons/Delete';
import { HashRouter, BrowserRouter, Route, Link, withRouter } from 'react-router-dom';
import { TextField } from '@material-ui/core';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import {
    SortingState, SelectionState, FilteringState, GroupingState,
    IntegratedFiltering, IntegratedGrouping, IntegratedSorting, IntegratedSelection,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    VirtualTable, TableHeaderRow, TableFilterRow, TableSelection, TableGroupRow,
    GroupingPanel, DragDropProvider, TableColumnReordering, Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import LinearProgressEx from '../../components/LinearProgressEx';
import * as queries from '../../modules/client/clientQueries';
import { getLabel, DictionaryTypes, getDictionary } from '../../global/dictionaryCache';
import RegularCard from '../../components/dashboard/RegularCard';
import ComponentEx from '../../components/ComponentEx';
import { filterRowMessages, tableMessages } from '../../global/appGlobal';

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        margin: theme.spacing.unit * 3,
    },
    button: {
        margin: theme.spacing.unit,
    },

    badge: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        marginTop: 0,

    },
    gridRoot: {
        height: '100%',
    },

    tableHeader: {
        backgroundColor: theme.palette.background.paper,
    },
    totalsRow: {
        backgroundColor: theme.palette.grey['200'],
        fontWeight: 'bold',
    },
});

const toLowerCase = value => String(value).toLowerCase();

const filterBroker = (row, e, a) => {
    const name = toLowerCase(a.broker.display_name);
    return name.includes(toLowerCase(e.value));
};

const filterCompany = (row, e, a) => {
    const name = toLowerCase(a.company.company_name);
    return name.includes(toLowerCase(e.value));
};


const filterCustomer = (row, e, a) => {
    const name = toLowerCase(a.customer.display_name);
    return name.includes(toLowerCase(e.value));
};

class Client_Contracts_Report extends ComponentEx {


    state = {
        delId: undefined,
        delNumber: undefined,
    };

    componentDidMount() {
        $("table[class*='Table-headTable']").addClass(this.props.classes.tableHeader);
    }

    render() {


        const columns = [
            { name: 'contract_number', title: getTxt('Numero') },
            { name: 'insurance_type_cd', title: getTxt('Tipo') },
            { name: 'company_name', title: getTxt('Compagnia') },
            { name: 'contract_start_date', title: getTxt('Inizio') },
            { name: 'premio', title: getTxt('Premio') },
        ];

        const { classes } = this.props;
        let items = this.props.data.customerContractReport ? this.props.data.customerContractReport : [];

        const withCompany = [];
        items.forEach((i) => {
            const c = { ...i };
            c.company_name = i.company.company_name;
            withCompany.push(c);
        });
        items = withCompany;

        if (items.length > 0) {
            const withSubtotal = [];
            let total = 0;
            items.forEach((i) => {

                // withSubtotal.push({
                //     contract_number: '',
                //     insurance_type_cd: '',
                //     company_name: '',
                //     contract_start_date: '',
                //     premio: total,
                // });
                // total = 0;

                const payment = Number.parseFloat(i.premio);
                if (!isNaN(payment)) {
                    total += payment;
                }
                withSubtotal.push(i);
            });

            withSubtotal.push({
                contract_number: '',
                insurance_type_cd: '',
                company_name: '',
                contract_start_date: '',
                premio: total,
            });

            items = withSubtotal;
        }

        return (

            <div className={classes.root}>
                <LinearProgressEx loading={this.props.data.loading} />

                <Grid
                
                    rows={items}
                    columns={columns}

                >
                    <FilteringState
                        defaultFilters={[{}]}
                    />

                    <SortingState />

                    <IntegratedFiltering columnExtensions={
                        [

                        ]}
                    />
                    <IntegratedSorting />

                    <VirtualTable
                        messages={tableMessages}
                        rowComponent={({ row, ...restProps }) => {
                            if (row.contract_number === '') {
                                return (<VirtualTable.Row
                                    className={classes.totalsRow}
                                    {...restProps}
                                />);
                            }
                            return (<VirtualTable.Row {...restProps} />);
                        }}
                        cellComponent={({ row, column, style, ...props }) => {



                            if (column.name === 'contract_number') {
                                return (<VirtualTable.Cell><Link to={`/contract/view/${row.insurance_type_cd}/${row.insurance_contract_id}`} style={{ padding: '6px', color: '#9C27B0', fontWeight: 'bold' }} > {row.contract_number}</Link> </VirtualTable.Cell>);
                            }

                            if (column.name === 'company_name') {
                                return (<VirtualTable.Cell>
                                    <Link to={`/company/view/${row.insurance_company_id}`}>{row.company_name}</Link>
                                </VirtualTable.Cell>);
                            }

                            if (column.name === 'insurance_type_cd') {
                                return (<VirtualTable.Cell> {getLabel(DictionaryTypes.InsuranceType, row.insurance_type_cd)}</VirtualTable.Cell>);
                            }

                            if (column.name === 'contract_start_date') {
                                return (<VirtualTable.Cell> {row.contract_start_date && toItalianDateString(row.contract_start_date)}</VirtualTable.Cell>);
                            }
                            if (column.name === 'premio') {
                                return (<VirtualTable.Cell>{toDecimalString(row.premio)}</VirtualTable.Cell>);
                            }



                            return <VirtualTable.Cell {...props} />;
                        }}
                    />

                    <TableFilterRow
                        messages={filterRowMessages}
                        cellComponent={({ column, filter, onFilter, ...props }) => {
                            if (column.name === 'insurance_type_cd') {
                                return (
                                    <c.gridColFilter noFilterVal={getTxt('Tutti')} setFilter={onFilter} >
                                        {getDictionary(DictionaryTypes.InsuranceType)}
                                    </c.gridColFilter>);
                            }

                            return <TableFilterRow.Cell onFilter={onFilter} filter={filter} {...props} />;
                        }}
                    />

                    <TableHeaderRow />

                </Grid>
            </div>
        );
    }
}

export default compose(
    graphql(queries.customerContractReport, {
        options: props => (
            {
                variables: {
                    params: JSON.stringify({ customerId: props.customerId }),
                },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }
        ),
    }),
)(withRouter(withStyles(styles)(Client_Contracts_Report)));
