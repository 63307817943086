import React from "react";
import {
    withStyles,
    Checkbox,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Tooltip
} from "@material-ui/core";
import { Edit, Close, Check } from "@material-ui/icons";

import PropTypes from "prop-types";

import tasksStyle from "assets/jss/material-dashboard-react/tasksStyle.jsx";

class Tasks extends React.Component {
    state = {
        checked: this.props.checkedIndexes
    };
    handleToggle = value => () => {
        const { checked } = this.state;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        this.setState({
            checked: newChecked
        });
    };
    render() {
        const { classes, tasksIndexes, tasks } = this.props;
        return (
            <Table className={classes.table}>
                <TableBody>
                    {tasksIndexes.map(value => (
                        <TableRow key={value} className={classes.tableRow}>
                            
                            <TableCell className={classes.tableCell}>
                                {tasks[value]}
                            </TableCell>
                            
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        );
    }
}

Tasks.propTypes = {
    classes: PropTypes.object.isRequired,
    tasksIndexes: PropTypes.arrayOf(PropTypes.number),
    tasks: PropTypes.arrayOf(PropTypes.node)
};

export default withStyles(tasksStyle)(Tasks);
