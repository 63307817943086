import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { LinearProgress } from '@material-ui/core';

const styles = {
    root: {
        width: '100%',
        minHeight: '5px',
    },
};

class LinearProgressEx extends Component {
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root} >
                <LinearProgress className={classes.root} style={{ display: this.props.loading ? 'block' : 'none' }} />
            </div>
        );
    }
}


export default withStyles(styles)(LinearProgressEx);