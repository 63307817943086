import React, { Component } from 'react';
import { getTxt } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import { graphql, compose } from 'react-apollo';
import { AppBar } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { TextField } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import * as Icon from '@material-ui/icons';
import { Checkbox } from '@material-ui/core';
import GridEx from 'components/form/GridEx';
import { Button } from '@material-ui/core';
import moment from 'moment';
import { Badge } from '@material-ui/core';
import { p } from 'components/form/Proto';
import DeleteIcon from '@material-ui/icons/Delete';
import $ from 'jquery';
import { IconButton } from '@material-ui/core';
import { TableCell } from '@material-ui/core';
import { HashRouter, BrowserRouter, Route, Link, withRouter } from 'react-router-dom';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import {
    SortingState, SelectionState, FilteringState, GroupingState,
    IntegratedFiltering, IntegratedGrouping, IntegratedSorting, IntegratedSelection,
} from '@devexpress/dx-react-grid';
import {
    Grid, Table,
    VirtualTable, TableHeaderRow, TableFilterRow, TableSelection, TableGroupRow,
    GroupingPanel, DragDropProvider, TableColumnReordering, Toolbar, TableColumnResizing,
} from '@devexpress/dx-react-grid-material-ui';
import LinearProgressEx from '../../components/LinearProgressEx';
import { getLabel, DictionaryTypes, removeDictionaryItem } from '../../global/dictionaryCache';
import PublicInsTypeEdit from '../../modules/publicInsType/PublicInsTypeEdit';
import { metadataQuery } from '../../home/Home';
import RegularCard from '../../components/dashboard/RegularCard';
import ComponentEx from '../../components/ComponentEx';
import { allPaymentTypesQuery, deletePublicPaymentTypeMutation } from './paymentTypeQueries';
import PublicPaymentTypeEdit from './PublicPaymentTypeEdit';
import { tableMessages, createGridResizerCols } from '../../global/appGlobal';




const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        margin: theme.spacing.unit * 3,
    },
    rootAsChildControl: {
        backgroundColor: theme.palette.background.paper,
    },
    button: {
        marginRight: theme.spacing.unit,
    },

    badge: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        marginTop: 0,

    },
    gridRoot: {
        height: '100%',
    },

    tableHeader: {
        backgroundColor: theme.palette.background.paper,
    }
});

class PublicPaymTypeSearch extends ComponentEx {


    state = {
        delDlgOpen: false,
        delId: undefined,
    };

    handleDelete() {

        this.props.deletePaymentType({
            variables: { id: this.state.delId },
            refetchQueries: [
                {
                    query: allPaymentTypesQuery,
                },
                {
                    query: metadataQuery,
                },
            ],
        }, this).then(
            () => {

                this.props.data.refetch();
            });

        this.setState({ delDlgOpen: false });

    }

    render() {

        const columns = [

            { name: 'label', title: getTxt('User Payment type Name') },
            { name: 'value', title: getTxt('Iser Payment type Key') },
            { name: 'real_payment_type_value', title: getTxt('Actual Payment type') },
            { name: 'public_payment_type_id', title: getTxt('Azioni') },
        ];

        createGridResizerCols(this.state, columns, {}, 280);

        const { classes } = this.props;
        const items = this.props.data.allPaymentTypes ? this.props.data.allPaymentTypes : [];

        return (

            <div className={this.props.noAddBtn ? classes.rootAsChildControl : classes.root}>
                <LinearProgressEx loading={this.props.data.loading} />
                {!this.props.noCaption && <RegularCard

                    headerColor="red"
                    cardTitle={getTxt('User Payment Types')}
                    cardSubtitle={getTxt('List of user Payment Types')}
                    content={
                        <div>
                            {!this.props.noAddBtn && <Button color="primary" className={classes.button}

                                onClick={() => { this.setState({ editId: 'new' }); }}
                            >{getTxt('Add new Payment Type')} </Button>}
                        </div>

                    }
                />}

                {this.props.addButton &&
                    <Button color="primary"
                        className={classes.button}

                        onClick={() => { this.setState({ editId: 'new' }); }}
                    >{getTxt('Add new Payment Type')} </Button>
                }



                {
                    this.state.editId &&
                    <PublicPaymentTypeEdit
                        id={this.state.editId}
                        AfterSubmit={((id) => {
                            this.setState({ editId: undefined });
                            this.props.data.refetch();
                            if (!this.props.realType && id) {
                                this.props.history.push(`/public_payment_type/view/${id}`);
                            }

                        })}

                    />
                }

                <Grid

                    rows={items}
                    columns={columns}

                >

                    <SortingState
                        defaultSorting={[{ columnName: 'label', direction: 'asc' }]}
                    />


                    <IntegratedSorting />

                    <Table
                        messages={tableMessages}
                        cellComponent={({ row, column, style, ...props }) => {
                            if (column.name === 'label') {
                                return (<Table.Cell>
                                    <Link to={`/public_payment_type/view/${row.public_payment_type_id}`} >{row.label}</Link>
                                </Table.Cell>);
                            }
                            if (column.name === 'real_payment_type_value') {
                                return (
                                    <Table.Cell>
                                        {getLabel(DictionaryTypes.PaymentType, row.real_payment_type_value)}
                                    </Table.Cell>);
                            }
                            if (column.name === 'public_payment_type_id') {
                                return (<Table.Cell>

                                    <IconButton size="small" onClick={() => { this.setState({ delDlgOpen: true, delId: row.public_payment_type_id }); }} className={classes.inGridButton} >
                                        <DeleteIcon />
                                    </IconButton>


                                </Table.Cell>);
                            }
                            if (column.name === 'public_payment_type_id') {
                                return (<Table.Cell>

                                    <IconButton size="small" onClick={() => { this.setState({ delDlgOpen: true, delId: row.public_payment_type_id, delCd: row.public_payment_type_id }); }} className={classes.inGridButton} >
                                        <DeleteIcon />
                                    </IconButton>


                                </Table.Cell>);
                            }
                            return <Table.Cell {...props} />;
                        }}
                    />

                    <TableColumnResizing
                        columnWidths={this.state.columnWidths}
                        onColumnWidthsChange={(cols) => { this.setState({ columnWidths: cols }); }}
                        defaultColumnWidths={this.state.defColumnWidths}
                    />
                    <TableHeaderRow showSortingControls />

                </Grid>
                <Dialog onClose={() => { this.setState({ delDlgOpen: false }); }} open={this.state.delDlgOpen}>
                    <DialogTitle >Elimina Tipo di Pagamento</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <span> Il pagamento sarà cancellato sul server. </span>
                            <br />
                            <span > Sei sicuro di voler cancellare il pagamento? </span>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleDelete(this.state.delId)} color="primary">
                            Ok
                        </Button>
                        <Button onClick={() => { this.setState({ delDlgOpen: false }); }} color="primary">
                            Annula
                        </Button>

                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default compose(

    graphql(allPaymentTypesQuery, {
        options: props => (
            {
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }
        ),

    }),

    graphql(deletePublicPaymentTypeMutation, {
        name: 'deletePaymentType',
        options: {
            errorPolicy: 'all',
        },
    }),

)(withRouter(withStyles(styles)(PublicPaymTypeSearch)));
