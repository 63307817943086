let items = {};
const cities = {};

export function getRequiredDictionaryTypes(types) {
    return types.filter(type => items[type] === undefined);
}

export function getDictionaryItem(type, value) {
    const result = items[type];
    if (!result) return undefined;
    return result.find(i => i.value === value);
}

export function getInternalInsTypeCd(publicInsTypeValue) {
    const publicInsType = getDictionaryItem(DictionaryTypes.InsuranceType, publicInsTypeValue);
    if (!publicInsType) return undefined;

    const internalInsType = items[DictionaryTypes.InternalInsuranceType].find(i => i.value === publicInsType.actualInsTypeCd);
    if (!internalInsType) return undefined;
    return internalInsType.value;
}

export function getDictionary(type) {
    const result = items[type];
    if (!result) return [];
    return result;
}

export function getValue(type, label) {
    const dict = items[type];
    if (!dict) return undefined;
    const found = dict.filter(i => i.label === label);
    if (!found || found.length === 0) return undefined;
    return found[0].value;
}

export function getLabel(type, value) {
    const dict = items[type];
    if (!dict) return value;

    if (dict[value]) return dict[value];

    const found = dict.find(i => i.value === value);
    if (!found) return value;

    // if (found.actualInsTypeCd) {
    //     const actType = dict.find((d) => { return d.value === found.actualInsTypeCd; });
    // }


    return found.label;
}

export function addDictionaries(dictionaries) {
    items = {};
    Object.keys(dictionaries).forEach((type) => { items[type] = dictionaries[type]; });
}

export function addDictionaryItem(type, item) {
    const dict = items[type];
    if (!dict) {
        items[type] = [];
    }
    items[type].push(item);
}

export function removeDictionaryItem(type, itemCd) {
    if (items[type]) {
        items[type] = items[type].filter(i => i.value !== itemCd);
    }
}

export function addCities(provinceCd, citiesInProvince) {
    cities[provinceCd] = citiesInProvince;
}

export function getCities(provinceCd) {
    if (cities[provinceCd]) return cities[provinceCd];
    return [];
}

export const DictionaryTypes = {
    InternalInsuranceType: 'internal_insurance_type',
    InsuranceType: 'insurance_type',
    DocumentType: 'tipologia documenti',
    CompliantCause: 'compliant_cause',
    PaymentMode: 'payment_mode',
    PaymentType: 'payment_type',
    PublicPaymentType: 'public_payment_type',
    BrokerType: 'broker_type',
    CustomerType: 'customer_type',
    Country: 'country',
    ItalianProvinces: 'italian_provinces',
    ItalianCities: 'italian_cities',
    CustomerState: 'customer_state',
    BrokerState: 'broker_state',
    InsuranceRequestState: 'insurance_request_state',
    VehicleType: 'tipo veicolo',
    InsuranceCompanyState: 'company_state',
    InsuranceQuoteState: 'quote_state',
    PaymentSchedule: 'payment_schedule',
    Title: 'titolo onorifico',
    Sex: 'sex',
    CompanyRegType: 'forma giuridica',
    IdCardType: 'documenti di riconoscimento',
    VehicleEngineType: 'alimentazione',
    VehicleUseType: 'uso',
    InsurancePeriod: 'insurance_period',
    InsuranceinsGuideType: 'tipo di guida',
    BusinessOccupation: 'attività svolta',
    PetType: 'animali domestici',
    PropertyType: 'tipo di abitazione',
    ConventionDescription: 'convenzioni',
    InsuranceItemType: 'insurance_item_type',
    InsuranceItemState: 'insurance_item_state',
    InsuranceItemPaymentState: 'insurance_item_payment_state',
    ContractDeliveryState: 'contract_delivery_state',
    InsPeriod: 'ins_period',
    ReportType: 'tipologia mandato',
    RemittanceType: 'tipologia rimessa',
    WorkType: 'Tipologia di Lavori',
    PaymentAccount: 'conti transito',
    ResponsibilityType: 'Tipologia di Responsabilità',
    ClaimStatus: 'claim_status',
    RiscontroCliente: 'riscontro_cliente',
    TipologiaDiDanno: 'Tipologia di Danno',
    TransitAccountDirection: 'transit_account_direction',
};
