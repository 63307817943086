import gql from 'graphql-tag';

export const allContractGuaranty = gql`

query allContractGuaranty($requestId: String, $contractId: Int, $onlyLatest: Boolean, $contractItemId: Int) {
    allContractGuaranty (requestId: $requestId, contractId: $contractId, onlyLatest: $onlyLatest, contractItemId: $contractItemId) {
        contract_guaranty_id
        insurance_request_id
        insurance_contract_id
        contract_item_id
        guaranty_type_id
        franchise_amount
        overdraft_amount
        maximum_amount
        net_amount
        ssn_percent
        broker_percent  
        agency_percent
        imposte_percent
        insurance_request_id
        guaranty_type{
            guaranty_type_id
            guaranty_name
        }
        contract_item
        {
          contract_item_id
            extra_broker_percent
            diritti
            competenze
            accessori
            autentica
            spese
            contract_item_type{
                contract_item_type_id
                value
                label
                is_financial
                is_negative
            }
            payment_account
            payment_notes
            
                        item_type_cd
                        state_cd
                        item_payment_state_cd
                        item_payment_type_cd
                        item_payment_date
                        item_start_date
                        item_end_date
                        amount_netto
                        total_amount_client
                        total_amount_broker
                        total_amount_agency
        }
  }
}

`;

export const contractGuarantyByIdQuery = gql`

query contractGuarantyByIdQuery($id: ID!, $reqId: Int, $insType: String!) {
    contractGuarantyById(id: $id, reqId: $reqId, insType: $insType ) {
        contract_guaranty_id
        insurance_request_id
        insurance_contract_id
        contract_item_id
        guaranty_type_id
        franchise_amount
        overdraft_amount
        maximum_amount
        net_amount
        ssn_percent
        broker_percent
        agency_percent
        imposte_percent
        base_ins_type
        allGuaranties {
            guaranty_type_id
            insurance_type_cd
            guaranty_name
            used_by_default
            is_financial
            default_franchise_amount
            default_overdraft_amount
            default_maximum_amount
        }
        
    }
  }

  `;


export const updateRequestGuarantyMutationName = gql`

mutation updateRequestGuarantyMutationName($requestGuaranty: contract_guaranty_input!) {
    updateRequestGuarantyMutationName(requestGuaranty: $requestGuaranty) {
      id
      validation {
        field
        message
      }
    }
  }

  `;

export const deleteContractGuarantyMutation = gql`

mutation deleteContractGuarantyMutation($id: ID!) {
    deleteContractGuarantyMutation(id: $id) {
      id
      validation {
        field
        message
      }
    }
  }


  `;