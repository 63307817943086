import React, { Component } from 'react';
import { getTxt, randomString } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';
import { withTheme } from '@material-ui/core';

import { Typography } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';



import { Paper } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { AppBar } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { TextField } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import gql from 'graphql-tag';
import { graphql, compose } from 'react-apollo';
import { Tabs, Tab } from '@material-ui/core';
import * as Icon from '@material-ui/icons';
import GridEx from 'components/form/GridEx';




import { Button } from '@material-ui/core';
import moment from 'moment';
import { Badge } from '@material-ui/core';
import { authInfo } from 'global/auth';

import ComponentEx from 'components/ComponentEx';

import { queryGetCustomerById, customerBriefQuery, allCustomersQuery } from '../../modules/client/clientQueries';
import c from '../../components/form/Components';
import LinearProgressEx from '../../components/LinearProgressEx';
import { getLabel, DictionaryTypes, getDictionary, getValue } from '../../global/dictionaryCache';
import MaterialForm from '../../components/form/MaterialForm';
import MFormText from '../../components/form/MFormText';
import * as queries from '../../modules/broker/brokerQueries';
import { getUserRoles, Roles } from '../../global/auth';
import { adminProfileQuery, updateAdminProfileMutation } from './profileQueries';
import { Dialog } from '@material-ui/core';
import { DialogTitle } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { DialogContentText } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';


const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing.unit * 2,
        margin: theme.spacing.unit * 2,
    },

    button: {
        margin: theme.spacing.unit,
    },

    badge: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        marginTop: 0,
    },

    gridRoot: {
        height: '100%',
    },


    formCaption: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit * 2,
        width: '100%',
        position: 'relative',
        height: 40,
    },
    formCaptionLabel: {
        position: 'absolute',
        backgroundColor: 'transparent',
        left: 25,
        paddingLeft: 10,
        paddingRight: 10,
    },

    rootPaper: theme.mixins.gutters({

        paddingTop: 0,
        paddingBottom: 0,
        marginTop: 0,// theme.spacing.unit * 3,

    }),

});

let history;
class AdminProfile extends ComponentEx {

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.fornChanged = this.fornChanged.bind(this);
        this.changePassword = this.changePassword.bind(this);
    }

    formGetValues = { func: undefined };

    state = {
        birth_country_cd: undefined,
        birth_province: undefined,
        address_country_cd: undefined,
        address_province: undefined,
    };

    onSubmit(e, { obj, showErrors, hideErrors }) {

        // obj.user_id = '-1';
        // obj.user_type_cd = '-1';
        // obj.user_login = '-1';
        // obj.user_login = '-1';
        // obj.user_display_name = '-1';
        this.props.updateAdminProfile({
            variables: { admin: obj },

        }, this).then(
            (resp) => {
                hideErrors();
                if (resp.errors) {
                    return;
                }
                const serverErrors = resp.data.updateAdminProfileMutation.validation;
                const id = resp.data.updateAdminProfileMutation.id;
                if (resp.errors) {
                    showErrors(resp.errors);
                }
                if (serverErrors && serverErrors.length > 0) {
                    showErrors(serverErrors);
                } else {
                    this.setState({ okDlg: true });
                }
            });
    }

    changePassword(id) {
        const values = this.formGetValues.func();
    }

    fornChanged(field, value, type, prevVal) {

    }

    render() {

        const { classes } = this.props;
        const loading = this.props.data.loading;
        const item = this.props.data.adminProfile;
        const caption = getTxt('Agency Administrator Profile');

        return (

            <div className={classes.root}>
                <Typography variant="h4" color="inherit" noWrap={false}>{getTxt(caption)}</Typography>
                <LinearProgressEx loading={loading} />
                {!loading &&
                    <div>
                        <MaterialForm dataSource={item} objType="user" onSubmit={this.onSubmit} onChange={this.fornChanged} getValues={this.formGetValues}>
                            <c.group l={getTxt('Dati personali')} />

                            <c.field l={getTxt('Email')} f="email" sm={12} />

                            {this.props.match.params.id !== 'new' &&
                                <c.field l={getTxt('New Password')} f="password_new" type="password" />
                            }

                            {this.props.match.params.id !== 'new' &&
                                <c.field l={getTxt('Confirm New Password')} f="password_new2" type="password" />
                            }

                            <c.group l={getTxt('Dati SIC ANIA')} />
                            <c.field l={getTxt('Utente SIC ANIA')} f="user_SIC_ANIA" />
                            <c.field l={getTxt('Password SIC ANIA')} f="password_SIC_ANIA" />
                            <c.field l={getTxt('Codice ente SIC ANIA')} f="code_SIC_ANIA" />

                            <c.formButtons sm={12} onCancel={() => { this.props.history.goBack(); }} />
                        </MaterialForm>

                    </div>
                }


                <Dialog
                    className={classes.dialog}
                    open={this.state.okDlg}
                    onClose={() => {
                        this.setState({ okDlg: undefined });
                    }}
                >
                    <DialogTitle> <span className={classes.dialogText}>{getTxt('Admin Profile')}</span></DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <span className={classes.dialogText}>{getTxt('The agency administrator profile saved successfully.')}</span>
                        </DialogContentText>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { this.setState({ okDlg: false }); }} color="primary" >
                            {getTxt('Ok')}
                        </Button>

                    </DialogActions>
                </Dialog>


            </div>

        );
    }
}


export default compose(
    graphql(adminProfileQuery, {
        options: props => ({
            fetchPolicy: 'network-only',
            options: {
                errorPolicy: 'all',
            },
        }),
    }),

    graphql(updateAdminProfileMutation, {
        name: 'updateAdminProfile',
        options: {
            errorPolicy: 'all',
        },
    }),

)(withStyles(styles)(AdminProfile));
