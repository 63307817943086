import { FilteringState, IntegratedFiltering, IntegratedSorting, SortingState } from '@devexpress/dx-react-grid';
import { Grid, TableFilterRow, TableHeaderRow, VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import ComponentEx from 'components/ComponentEx';
import c from 'components/form/Components';
import { getToken } from 'global/auth';
import { ReportTypes } from 'global/reporting';
import { EntityTypes, getEntityDescriptor, getFieldLabel } from 'global/entityDescriptors';
import $ from 'jquery';
import { Button } from '@material-ui/core';
import { TableCell } from '@material-ui/core';
import { Menu, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import * as Icon from '@material-ui/icons';
import React from 'react';
import { graphql, compose } from 'react-apollo';
import { Checkbox } from '@material-ui/core';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import { IconButton } from '@material-ui/core';

import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import LinearProgressEx from '../../components/LinearProgressEx';
import LinkToCustomerById from '../../components/LinksToObjectById';
import { getTxt, filterRowMessages, tableMessages } from '../../global/appGlobal';
import { DictionaryTypes, getDictionary, getLabel } from '../../global/dictionaryCache';
import { allTranslationsListQuery, deleteTranslationMutation } from '../../modules/translation/translationsQueries';
import RegularCard from '../../components/dashboard/RegularCard';
import Translation_Edit from '../../modules/translation/Translation_Edit';

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        margin: theme.spacing.unit * 3,
    },
    button: {
        marginRight: theme.spacing.unit,
    },

    tableHeader: {
        backgroundColor: theme.palette.background.paper,
    },

});

class Translations_Search extends ComponentEx {

    state = {
        selectedId: undefined,
        editId: undefined,
        createOverride: undefined,
        delId: undefined,
        delDlgOpen: false,
    };

    constructor(props) {
        super(props);

        this.tableCellTemplate = ({ row, column, style }) => {
            if (column.name === 'display_name') {
                return (<VirtualTable.Cell> <LinkToCustomerById customer={row} /> </VirtualTable.Cell>);
            }
            return undefined;
        };

        this.getRowId = row => row.customer_id;
        this.newClick = this.newClick.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ selectedId: nextProps.selectedId });
        this.setState({ selectedIds: nextProps.selectedIds ? JSON.parse(nextProps.selectedIds) : [] });
    }

    componentDidMount() {
        $("table[class*='Table-headTable']").addClass(this.props.classes.tableHeader);
    }

    newClick(event) {
        this.setState({ open: true, anchorEl: event.currentTarget });
    }

    handleRequestClose = () => {
        this.setState({ open: false });
    };

    handleDelete() {

        this.props.deleteTranslation({
            variables: { id: this.state.delId },
            refetchQueries: [
                {
                    query: allTranslationsListQuery,
                },
            ],
        }, this).then(
            () => {

                this.props.data.refetch();
            });

        this.setState({ delDlgOpen: false, delId: undefined });

    }

    render() {
        const { classes } = this.props;

        const allTranslations = this.props.data.allTranslations ? this.props.data.allTranslations : [];

        const columns = [
            { name: 'translation_id', title: getTxt('Actions') },
            { name: 'original', title: getFieldLabel(EntityTypes.Translation, 'original') },
            { name: 'translated', title: getFieldLabel(EntityTypes.Translation, 'translated') },
            { name: 'alt_key', title: getFieldLabel(EntityTypes.Translation, 'alt_key') },
        ];

        const onSelectedChanged = this.props.onSelectedChanged ? this.props.onSelectedChanged.bind(this) : undefined;
        const setState = this.setState.bind(this);
        const state = this.state;
        const multiselect = this.props.multiselect;

        return (




            <div className={classes.root}>
                <LinearProgressEx loading={this.props.data.loading} />
                <RegularCard

                    headerColor="red"
                    cardTitle={getTxt('Translations')}
                    cardSubtitle={getTxt('List of strings in the user interface available to translate.')}
                    content={
                        <div>
                            {/* {!this.props.noAddButton && <Button color="primary" className={classes.button} onClick={this.newClick} >Add new Customer</Button>}
                            {!this.props.noPdfButton && <Button
                                color="primary"
                                onClick={() => {
                                    window.open(`${window.apiUrl}files/reports?token=${getToken()}&type=${ReportTypes.AllCustomersList}`, '_blank');
                                }}
                            >
                                Create PDF
                    </Button>} */}
                        </div>

                    }
                />

                <Grid

                    rows={allTranslations}
                    columns={columns}

                >
                    <FilteringState
                        defaultFilters={[{}]}
                    />



                    <IntegratedFiltering />
                    <SortingState
                        defaultSorting={[
                            { columnName: 'original', direction: 'asc' },
                        ]}
                    />
                    <IntegratedSorting />





                    <VirtualTable
                        messages={tableMessages}
                        cellComponent={({ row, column, style, ...props }) => {
                            if (column.name === 'translation_id') {
                                return (<VirtualTable.Cell>
                                    <Tooltip title={getTxt('Edit translation')}>
                                        <IconButton size="small" onClick={() => { this.setState({ editId: row.translation_id }); }} className={classes.inGridButton} >
                                            <Icon.Edit />
                                        </IconButton>
                                    </Tooltip>
                                    {!row.alt_key &&
                                        <Tooltip title={getTxt('Create override translation from this')}>
                                            <IconButton size="small" onClick={() => { this.setState({ editId: row.translation_id, createOverride: true }); }} className={classes.inGridButton} >
                                                <Icon.CallSplit />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                    {row.alt_key &&
                                        <Tooltip title={getTxt('Delete overrided translation')}>
                                            <IconButton size="small" onClick={() => { this.setState({ delDlgOpen: true, delId: row.translation_id }); }} className={classes.inGridButton} >
                                                <Icon.Delete />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                </VirtualTable.Cell>);
                            }

                            return <VirtualTable.Cell {...props} />;
                        }}
                    />

                    <TableFilterRow
                        messages={filterRowMessages}
                        cellComponent={({ column, filter, onFilter, ...props }) => {

                            if (column.name === 'translation_id') {
                                return (
                                    <TableFilterRow.Cell filteringEnabled={true} >
                                        <span />
                                    </TableFilterRow.Cell>);
                            }

                            return <TableFilterRow.Cell onFilter={onFilter} filter={filter} {...props} />;
                        }}
                    />

                    <TableHeaderRow showSortingControls />

                </Grid>

                {
                    this.state.editId &&
                    <Translation_Edit
                        id={this.state.editId}
                        createOverride={this.state.createOverride}
                        AfterSubmit={((id) => {
                            this.setState({ editId: undefined, deleteId: undefined, createOverride: false });
                            this.props.data.refetch();
                        })}

                    />
                }

                <Dialog onClose={() => { this.setState({ delDlgOpen: false }); }} open={this.state.delDlgOpen}>
                    <DialogTitle >{getTxt('Delete Overrided Translation')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <span> {getTxt('Overrided Translation will be delete on server.')}  </span>
                            <br />
                            <span > {getTxt('Are you sure do you want delete the Overrided Translation?')} </span>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleDelete(this.state.delId)} color="primary">
                            Ok
                        </Button>
                        <Button onClick={() => { this.setState({ delDlgOpen: false }); }} color="primary">
                            Annula
                        </Button>

                    </DialogActions>
                </Dialog>


            </div >
        );
    }


}



export default compose(
    graphql(allTranslationsListQuery, {
        options: {
            fetchPolicy: 'network-only',
            options: {
                errorPolicy: 'all',
            },
        },
    }),
    graphql(deleteTranslationMutation, {
        name: 'deleteTranslation',
        options: {
            errorPolicy: 'all',
           
        },
    }),
)(withStyles(styles)(Translations_Search));




