import React, { Component } from 'react';

import {logout} from 'global/auth';

export default class ComponentEx extends Component {

    

    componentWillReceiveProps(nextProps) {
        if (nextProps.data && nextProps.data.error &&
            (nextProps.data.error.message.indexOf('401') > -1 || nextProps.data.error.message.indexOf('403') > -1)) {
           

            logout();
        }
      

    }

   
}