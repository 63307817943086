
import React, { Component } from 'react';
import { getTxt, randomString } from 'global/appGlobal';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
//import FormField from 'components/form/FormField';
import GridEx from 'components/form/GridEx';
import { MenuItem } from '@material-ui/core';
import {
    FormLabel,
    FormControl,
    FormGroup,
    FormControlLabel,
    FormHelperText,
} from '@material-ui/core';
import { Checkbox } from '@material-ui/core';
import { Input, InputLabel } from '@material-ui/core';
import FormCaption from 'components/form/FormCaption';
import { Grid } from '@material-ui/core';
import { Paper } from '@material-ui/core';
import moment from 'moment';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import $ from 'jquery';
import { EntityTypes, getEntityDescriptor } from 'global/entityDescriptors';
import schema from 'async-validator';
import { IconButton } from '@material-ui/core';
import * as Icon from '@material-ui/icons';
import  { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

import { Select } from '@material-ui/core';
import { HashRouter, BrowserRouter, Route, Link, withRouter } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import Downshift from 'downshift';

import MFormControl from '../../components/form/MFormControl';
import { getLabel, getValue } from '../../global/dictionaryCache';



const styles = theme => ({
    root: {
        paddingTop: '9px !important',
        position: 'relative',
    },
    container: {
        flexGrow: 1,
    },
    textField: {
        marginTop: theme.spacing.unit * 3.4,
        marginRight: theme.spacing.unit,
        width: '100%',
    },
    menu: {
        minWidth: 200,
        position: 'absolute',
        backgroundColor: theme.palette.background.paper,
        zIndex: 99999,
    },
    menuItem: {
        minWidth: 200,
        backgroundColor: theme.palette.background.paper,
    },
    forFocusHidden: {
        width: '0px',
        height: '0px',
        border: 0,
        color: 'transparent',
        backgroundColor: 'transparent',
        position: 'absolute',
    },
    suggestionsContainerOpen: {
        position: 'absolute',
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit * 3,
        left: 0,
        right: 0,
        zIndex: 99999,
    },
    suggestion: {
        display: 'block',
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
        backgroundColor: theme.palette.background.paper,
    },
    formControl: {
        margin: theme.spacing.unit,

        width: '100%',
    },
    errorMessage: {
        color: theme.palette.error.main,
        marginLeft: theme.spacing.unit,
    },
    arrowDropDownBtn: {
        position: 'absolute',
        top: theme.spacing.unit * 5.9,
        right: theme.spacing.unit,
        color: theme.palette.text.secondary,

    },
});




function renderSuggestion(suggestion, { query, isHighlighted }) {
    const matches = match(suggestion.label, query);
    const parts = parse(suggestion.label, matches);

    return (
        <MenuItem selected={isHighlighted} component="div">
            <div>
                {parts.map((part, index) => {
                    return part.highlight ? (
                        <span key={String(index)} style={{ fontWeight: 300 }}>
                            {part.text}
                        </span>
                    ) : (
                            <strong key={String(index)} style={{ fontWeight: 500 }}>
                                {part.text}
                            </strong>
                        );
                })}
            </div>
        </MenuItem>
    );
}


function renderSuggestionsContainer(options) {
    const { containerProps, children } = options;

    return (
        <Paper {...containerProps} square>
            {children}
        </Paper>
    );
}

function getSuggestionValue(suggestion) {
    return suggestion.label;
}

function getSuggestions(value, suggestions, showAll) {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;

    // if (showAll) {
    //     return suggestions;
    // }

    return inputLength === 0
        ? []
        : suggestions.filter(suggestion => {
            const keep =
                count < 200 && suggestion.label.toLowerCase().slice(0, inputLength) === inputValue;

            if (keep) {
                count += 1;
            }

            return keep;
        });
}


class MFormAutoSelect extends MFormControl {
    constructor(props) {
        super(props);




        this.state.suggestions = [];
        this.handleChange = this.handleChange.bind(this);
        this.handleSuggestionsFetchRequested = this.handleSuggestionsFetchRequested.bind(this);
        this.renderInput = this.renderInput.bind(this);
        this.setValue = this.setValue.bind(this);

        if (this.props.children &&
            this.props.children.length &&
            this.props.children.length > 0 &&
            this.props.children[0].value &&
            this.props.v) {
            const selected = this.props.children.find((i) => { return i.value === this.props.v; });

            if (selected && selected.label) {
                //this.state.selected = selected.label;
                this.state.suggestions = this.props.children;
                this.state.value = selected.label;
            }


        }
        this.state.showAll = false;
    }

    renderInput(inputProps) {
        const { classes, autoFocus, value, ref, id, ...other } = inputProps;

        return (
            <TextField
                autoFocus={autoFocus}
                className={classes.textField}
                value={value}
                inputRef={ref}
                id={`${this.id}for_focus`}
                InputProps={{
                    classes: {
                        input: classes.input,
                    },
                    ...other,
                }}
            />
        );
    }

    handleSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: getSuggestions(value, this.props.children, this.state.showAll),

        });
    };

    handleSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        });
    };

    handleChange = (event, { newValue }) => {
        const prevVal = this.state.value;
        this.setState({
            value: newValue,
        });

        this.setState({ isDirty: true });
        this.formOnChange(this.props.f, newValue, this.props.type, prevVal);
    };

    getValue() {
        if (this.props.children && this.state.value && this.state.value.trim) {
            const selected = this.props.children.find(i => { return i.label.trim().toLowerCase() === this.state.value.trim().toLowerCase() })
            if (selected) {
                return selected.value;
            }
            return undefined;
        }
        return undefined;
    }

    setValue(val) {
        // if (this.props.setValueParent) {
        //     this.props.setValueParent(val);
        // }
        const v = this.props.children.find(i => i.value === val);
        if (v) {
            this.setState({ value: v.label });
            this.formOnChange(this.props.f, v.label, this.props.type);
        }
    }

    focus() {
        $(`#${this.id}for_focus`).focus();
        //$(`#${this.id}for_focus`).mousedown();

    }

    render() {
        const { classes, theme } = this.props;
        const disabled = this.props.d;
        const required = this.props.r;

        return (
            <GridEx item sm={this.props.sm || 6} xs={this.props.xs || 12} className={classes.root}>
                <input type="text" tabIndex="-1" className={classes.forFocusHidden} />

                <FormControl className={classes.formControl} error={this.state.errorMessage ? true : false}>
                    <InputLabel
                        FormControlClasses={{
                            focused: classes.inputLabelFocused,
                        }}
                        disabled={this.props.d}
                        required={this.props.r}
                        shrink
                    >
                        {this.getLabel()}
                    </InputLabel>

                    <Autosuggest
                        theme={{
                            container: classes.container,
                            suggestionsContainerOpen: classes.suggestionsContainerOpen,
                            suggestionsList: classes.suggestionsList,
                            suggestion: classes.suggestion,
                        }}
                        renderInputComponent={this.renderInput}
                        suggestions={this.state.suggestions}
                        onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
                        onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
                        renderSuggestionsContainer={renderSuggestionsContainer}
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        disabled={this.props.d}
                        required={this.props.r}
                        alwaysRenderSuggestions={this.state.showAll}
                        highlightFirstSuggestion
                        inputProps={{

                            classes,
                            placeholder: getTxt('Start typing to search (start with)'),
                            value: this.state.value === null ? '' : this.state.value,
                            onChange: this.handleChange,
                            name: this.props.f,
                        }}
                    />
                </FormControl>
                {/* <IconButton
                    aria-label="Delete"
                    className={classes.arrowDropDownBtn}
                    
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        this.setState({ showAll: !this.state.showAll });
                        this.focus();
                    }}
                > */}
                <Icon.KeyboardHide className={classes.arrowDropDownBtn} />
                {/* </IconButton> */}
                <FormHelperText className={classes.errorMessage} >{this.state.errorMessage}</FormHelperText>
            </GridEx>
        );
    }
}

MFormAutoSelect.contextTypes = {
    onChange: PropTypes.func,
    registerControl: PropTypes.func,
    objType: PropTypes.string,
    dataSource: PropTypes.object,
    unRegisterControl: PropTypes.func,
    fields: PropTypes.Object,
};

export default withStyles(styles, { withTheme: true })(MFormAutoSelect);