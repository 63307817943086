import React, { Component } from 'react';
import PropTypes from 'prop-types';
//import FormField from 'components/form/FormField';




import moment from 'moment';
import $ from 'jquery';
import { EntityTypes, getEntityDescriptor } from 'global/entityDescriptors';




import MFormLimkToCustomer from 'components/form/MFormLimkToCustomer';
import MFormMultiLimkToCustomer from 'components/form/MFormMultiLimkToCustomer';
import MFormMultilinkToCompany from 'components/form/MFormMultilinkToCompany';
import MFormLinkToEntity from '../../components/form/MFormLinkToEntity';
import MFormText from '../../components/form/MFormText';
import MFormCheck from '../../components/form/MFormCheck';
import MFormSelect from '../../components/form/MFormSelect';
import MFormAutoSelect from '../../components/form/MFormAutoSelect';
import MFormCityAutoSelect from '../../components/form/MFormCityAutoSelect';
import MFormCitySelect from '../../components/form/MFormCitySelect';
import MFormCompanySelect from '../../components/form/MFormCompanySelect';
import BrokerSelect from '../../components/form/BrokerSelect';
import MFormFiscalCodeText from '../../components/form/MFormFiscalCodeText';
import MFormIbanText from '../../components/form/MFormIbanText';
import { returnTrue } from 'react-number-format/lib/utils';
import MFormViecleNumber from './MFormViecleNumber';
import MFormLinkToAtrcData from './MFormLinkToAtrcData';
import MFormMultilinkToBroker from './MFormMultilinkToBroker';
import MFormItemTypeSelect from './MFormItemTypeSelect';


moment.locale('it-it');

function getMaxlength(propDescriptor) {
    if (propDescriptor.validators) {
        const maxValidator = propDescriptor.validators.find(v => v.max);
        if (maxValidator) {
            return maxValidator.max;
        }
    }
    return undefined;
}

function getPropValue(obj, path) {
    const splitted = path.split('.');
    if (splitted.length === 0) return obj[path];
    let value = obj;
    splitted.forEach((partOfPath) => {
        value = value[partOfPath.trim()];
    });
    return value;
}

function getChildControlByType(props, propType, value, required, uiType, children, propDescriptor) {

    if (uiType === 'auto-select') {
        return (<MFormAutoSelect {...props} v={value} r={required} propType={propType} propDescriptor={propDescriptor} >{children}</MFormAutoSelect>);
    }
    if (uiType === 'select') {
        return (<MFormSelect {...props} v={value} r={required} propType={propType} propDescriptor={propDescriptor} >{children}</MFormSelect>);
    }
    if (uiType === 'city_select') {
        return (<MFormCitySelect {...props} v={value} r={required} propType={propType} propDescriptor={propDescriptor} />);
    }
    if (uiType === 'item_type_select') {
        return (<MFormItemTypeSelect {...props} v={value} r={required} propType={propType} propDescriptor={propDescriptor} />);
    }
    if (uiType === 'city-auto-select') {
        return (<MFormCityAutoSelect {...props} v={value} r={required} propType={propType} propDescriptor={propDescriptor} />);
    }
    if (uiType === 'broker_select') {
        return (<BrokerSelect {...props} v={value} r={required} propType={propType} propDescriptor={propDescriptor} />);
    }
    if (uiType === 'link_to_broker') {
        return (<MFormLinkToEntity to={`brokers/${value.broker_id}`} {...props} r={required} propType={propType} propDescriptor={propDescriptor} >{value.display_name}</MFormLinkToEntity>);
    }
    if (uiType === 'company_select') {
        return (<MFormCompanySelect {...props} v={value} r={required} propType={propType} propDescriptor={propDescriptor} />);
    }
    if (uiType === 'fiscal_code') {
        return (<MFormFiscalCodeText {...props} v={value} r={required} propType={propType} propDescriptor={propDescriptor} maxlength={getMaxlength(propDescriptor)} />);
    }
    if (uiType === 'iban') {
        return (<MFormIbanText {...props} v={value} r={required} propType={propType} propDescriptor={propDescriptor} />);
    }
    if (uiType === 'link-to-customer') {
        return (<MFormLimkToCustomer {...props} v={value} r={required} propType={propType} propDescriptor={propDescriptor} />);
    }
    if (uiType === 'multi-link-to-customer') {
        return (<MFormMultiLimkToCustomer {...props} v={value} r={required} propType={propType} propDescriptor={propDescriptor} />);
    }
    if (uiType === 'multi-link-to-company') {
        return (<MFormMultilinkToCompany {...props} v={value} r={required} propType={propType} propDescriptor={propDescriptor} />);
    }
    if (uiType === 'multi-link-to-broker') {
        return (<MFormMultilinkToBroker {...props} v={value} r={required} propType={propType} propDescriptor={propDescriptor} />);
    }
    if (uiType === 'viecle_id') {
        return (<MFormViecleNumber {...props} v={value} r={required} propType={propType} propDescriptor={propDescriptor} />);
    }
    if (uiType === 'atrc-data-link') {
        return (<MFormLinkToAtrcData {...props} v={value} r={required} propType={propType} propDescriptor={propDescriptor} />);
    }

    switch (propType) {
        case 'STRING':
            return (<MFormText {...props} v={value} r={required} propType={propType} propDescriptor={propDescriptor} maxlength={getMaxlength(propDescriptor)} />);
        case 'INTEGER':
            return (<MFormText {...props} v={value} r={required} propType={propType} propDescriptor={propDescriptor} />);
        case 'DATEONLY':
            //return (<MFormDatePicker {...props} v={value} r={required} propType={propType} />);
            return (<MFormText {...props} v={value} r={required} propType={propType} type="date" max="9999-01-01" placeholder="DD/MM/YYYY" pattern="[0-9]{2}/[0-9]{2}/[0-9]{4}" labelShrink propDescriptor={propDescriptor} />);
        case 'DATE':
            //return (<MFormDatePicker {...props} v={value} r={required} propType={propType} />);
            return (<MFormText {...props} v={value} r={required} propType={propType} type="datetime-local" max="9999-01-01" placeholder="DD/MM/YYYY" pattern="[0-9]{2}/[0-9]{2}/[0-9]{4}" labelShrink propDescriptor={propDescriptor} />);
        case 'BOOLEAN':
            return (<MFormCheck {...props} v={value} r={required} propType={propType} propDescriptor={propDescriptor} />);
        case 'DECIMAL':
            return (<MFormText {...props} v={value} r={required} propType={propType} propDescriptor={propDescriptor} currency={propDescriptor.currency} />);
        default: return (<MFormText {...props} v={value} r={required} propType={propType} propDescriptor={propDescriptor} />);
    }
}

export default class FormFieldHigh extends Component {

    convertToControl(value, propType, propDescriptor) {
        switch (propType) {
            case 'STRING':
            case 'INTEGER':
                return value;
            case 'DATE':
                if (!value || value === '') return value;

                let d = moment.utc(value);
                if (!d) return value;
                
                // let year = d.year();
                // let month = d.month();
                // if (month < 9) {
                //     month = '0' + month;
                // }

                // let date = d.date();
                // if (date < 9) {
                //     date = '0' + date;
                // }

                let hours = d.hours();
                if (hours < 10) {
                    hours = '0' + hours;
                }

                let minutes = d.minutes();
                if (minutes < 10) {
                    minutes = '0' + minutes;
                }


                const date = moment(value).format('YYYY-MM-DD');
                return date + 'T' + hours + ':' + minutes ;
            case 'DATEONLY':
                if (!value || value === '') return value;

                // if (readonly && value) {
                //     const options = { 'month': '2-digit', 'day': '2-digit', "year": "numeric" };
                //     return new Date(value).toLocaleString('it-IT', options);
                // }

                return moment(value).format('YYYY-MM-DD');


            // if (readonly && value) {
            //     const options = { 'month': '2-digit', 'day': '2-digit', "year": "numeric" };
            //     return new Date(value).toLocaleString('it-IT', options);
            // }

            case 'BOOLEAN':
                {
                    if (value === true || value === 'true') {
                        return true;
                    };
                    if (value === false || value === 'false' || !value) {
                        return false;
                    };
                }
                return value;
            case 'DECIMAL':
                if (value || value === 0) {
                    return parseFloat(value).toFixed(2);
                }
                if (propDescriptor.currency && (value === null || value === undefined)) {
                    return '';
                }
                return value;

            default: return value;
        }
    }

    render() {
        const objDescriptor = getEntityDescriptor(this.context.objType);
        const propDescriptor = objDescriptor[this.props.f];
        const propType = propDescriptor ? propDescriptor.type : this.props.type;


        let required = false;
        if (propDescriptor && propDescriptor.validators &&
            propDescriptor.validators.find(v => v.required)) {
            required = true;
        } else {
            required = this.props.r;
        }

        const value = this.props.v ? this.props.v : getPropValue(this.context.dataSource, this.props.f);

        if (this.context.fields && this.context.fields.all.length > 0) {
            if (!this.context.fields.byId[this.props.f]) {
                return '';
            }
        }

        return getChildControlByType(this.props, propType, this.convertToControl(value, propType, propDescriptor), required, this.props.ui, this.props.children, propDescriptor);

    }

    getLabel() {
        const objDescriptor = getEntityDescriptor(this.context.objType);
        const propDescriptor = objDescriptor[this.props.f];

        let label;
        if (propDescriptor) {
            label = propDescriptor.label;
        }
        if (this.context.fields && this.context.fields.byId[this.props.f]) {
            const customField = this.context.fields.byId[this.props.f];
            if (customField && customField.l) {
                if (this.props.pdf) {
                    const latestGroup = window.requestDetailsPdfData[window.requestDetailsPdfData.length - 1];
                    latestGroup.fields.push({ label: customField.l });
                }
                return customField.l;
            }
        }
        const fromMarkup = this.props.l;
        if (fromMarkup) {
            label = fromMarkup;
        }

        if (this.props.pdf) {
            const latestGroup = window.requestDetailsPdfData[window.requestDetailsPdfData.length - 1];
            latestGroup.fields.push({ label });
        }

        return label;
    }
}

FormFieldHigh.contextTypes = {
    onChange: PropTypes.func,
    registerControl: PropTypes.func,
    objType: PropTypes.string,
    dataSource: PropTypes.object,
    unRegisterControl: PropTypes.func,
    fields: PropTypes.Object,
};