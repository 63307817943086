import React from 'react';
import { graphql, compose } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import {
    SortingState, FilteringState, IntegratedFiltering, IntegratedSorting,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    VirtualTable, TableHeaderRow, TableFilterRow, TableColumnResizing,
} from '@devexpress/dx-react-grid-material-ui';

import CurrencyGridFormatter from 'components/CurrencyGridFormatter';
import { FormControl, IconButton, Paper } from '@material-ui/core';

import * as Icons from '@material-ui/icons';
import ComponentEx from 'components/ComponentEx';
import { Button } from '@material-ui/core';
import c from 'components/form/Components';
import { getTxt, toItalianDateString, toDecimalString, filterRowMessages, tableMessages, createGridResizerCols } from '../../global/appGlobal';
import LinkToCustomerById from '../../components/LinksToObjectById';
import LinearProgressEx from '../../components/LinearProgressEx';
import { getLabel, getDictionary, DictionaryTypes, getInternalInsTypeCd } from '../../global/dictionaryCache';
import { allRequestsQuery } from '../../modules/request/requestQeries';
import { Menu, MenuItem } from '@material-ui/core';
import * as Icon from '@material-ui/icons';
import RegularCard from '../../components/dashboard/RegularCard';
import * as queries from '../../modules/company/CompanyQueries';
import Company_Payment_Edit from '../../modules/company/Company_Payment_Edit';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Tooltip } from '@material-ui/core';
import MaterialForm from '../../components/form/MaterialForm';
import GridEx from 'components/form/GridEx';

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit * 4,
    },
    button: {
        marginRight: theme.spacing.unit,
    },

    tableHeader: {
        backgroundColor: theme.palette.background.paper,
    },
    menuItem: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& $text, & $icon': {
                color: theme.palette.common.white,
            },
        },
    },
    text: {},
    icon: {},
    totalsRow: {
        backgroundColor: theme.palette.grey['200'],
        fontWeight: 'bold',
    },
    label: {
        ...theme.typography.body2,
        marginRight: theme.spacing.unit,
        color: theme.palette.text.secondary,
        whiteSpace: 'nowrap',

    },
    value: {
        ...theme.typography.body2,
        color: theme.palette.text.primary,

    },
    formControl: {
        margin: theme.spacing.unit / 4,
        flexDirection: 'row',
        whiteSpace: 'normal',

    },
});

const toLowerCase = value => String(value).toLowerCase();

const filterUser = (cellValue, e, row) => {
    const name = toLowerCase(row.user.user_display_name);
    return name.includes(toLowerCase(e.value));
};

function getInsuranceTypeCd(item) {
    const r = item.ins_request_veicoli || item.ins_request_cose || item.ins_request_persone || item.ins_request_cauzione || item.ins_request_other;
    return r ? r.insurance_type_cd : null;
}
function getRequestId(item) {
    const r = item.ins_request_veicoli || item.ins_request_cose || item.ins_request_persone || item.ins_request_cauzione || item.ins_request_other;
    return r ? r.insurance_request_id : null;
}

class ChangeLogDetails extends ComponentEx {

    state = {

    };

    constructor() {
        super();
    }

    render() {

        const { classes } = this.props;

        const item = this.props.data.logDetails ? this.props.data.logDetails : [];
        const loading = this.props.data.loading;


        return (

            <Paper className={classes.root}>
                <LinearProgressEx loading={loading} />
                {item && item.contract &&

                    <GridEx item sm={this.props.sm || 12} xs={this.props.xs || 12} >
                        <FormControl className={classes.formControl}>
                            <span className={classes.label} >
                                {getTxt('Contract')}
                            </span>
                            <div className={classes.value}>
                                <Link to={`/contract/view/${item.contract.insurance_type_cd}/${item.contract.insurance_contract_id}`} style={{ padding: '6px', color: '#9C27B0', fontWeight: 'bold' }} > {item.contract.contract_number}</Link>
                            </div>
                        </FormControl>
                    </GridEx>
                }
                {item && item.broker &&
                    <GridEx item sm={this.props.sm || 12} xs={this.props.xs || 12} >
                        <FormControl className={classes.formControl}>
                            <span className={classes.label} >
                                {getTxt('Broker')}
                            </span>
                            <div className={classes.value}>
                                <Link to={`/brokers/${item.broker.broker_id}`} >{item.broker.display_name}</Link>
                            </div>
                        </FormControl>
                    </GridEx>
                }

                {item && item.company &&
                    <GridEx item sm={this.props.sm || 12} xs={this.props.xs || 12} >
                        <FormControl className={classes.formControl}>
                            <span className={classes.label} >
                                {getTxt('Company')}
                            </span>
                            <div className={classes.value}>
                                <Link to={`/company/view/${item.company.insurance_company_id}`} >{item.company.company_name}</Link>
                            </div>
                        </FormControl>
                    </GridEx>
                }
                {item && item.customer &&
                    <GridEx item sm={this.props.sm || 12} xs={this.props.xs || 12} >
                        <FormControl className={classes.formControl}>
                            <span className={classes.label} >
                                {getTxt('Customer')}
                            </span>
                            <div className={classes.value}>
                                {item.customer.customer_type_cd === 'PRIVATE_PROSPECT' &&
                                    <Link to={`/customers/prospect/private/${item.customer_id}`} style={{ padding: '6px', color: '#9C27B0', fontWeight: 'bold' }} >{item.display_name}</Link>
                                }
                                {item.customer.customer_type_cd !== 'PRIVATE_PROSPECT' &&
                                    <Link to={`/customers/${item.customer.customer_type_cd.toLowerCase()}/${item.customer.customer_id}`} style={{ padding: '6px', color: '#9C27B0', fontWeight: 'bold' }} >
                                        {item.customer.display_name}
                                    </Link>
                                }
                            </div>
                        </FormControl>
                    </GridEx>
                }

                {item && item.referent &&
                    <GridEx item sm={this.props.sm || 12} xs={this.props.xs || 12} >
                        <FormControl className={classes.formControl}>
                            <span className={classes.label} >
                                {getTxt('Referent')}
                            </span>
                            <div className={classes.value}>
                                <Link to={`referents/edit/${item.referent.parent_id}/${item.referent.referent_id}`}> {item.referent.first_name + ' ' + item.referent.last_name} </Link>
                            </div>
                        </FormControl>
                    </GridEx>
                }
                {item && (item.ins_request_veicoli || item.ins_request_cose || item.ins_request_persone || item.ins_request_cauzione || item.ins_request_other) &&
                    <GridEx item sm={this.props.sm || 12} xs={this.props.xs || 12} >
                        <FormControl className={classes.formControl}>
                            <span className={classes.label} >
                                {getTxt('Richiesta')}
                            </span>
                            <div className={classes.value}>
                                <Link style={{ padding: '6px', color: '#9C27B0', fontWeight: 'bold' }} to={`/requests/${getInsuranceTypeCd(item)}/view/${getRequestId(item)}`} >{getLabel(DictionaryTypes.InsuranceType, getInsuranceTypeCd(item)) + ' ' + getRequestId(item)}</Link>
                            </div>
                        </FormControl>
                    </GridEx>
                }
             
                {item && item.claim &&
                    <GridEx item sm={this.props.sm || 12} xs={this.props.xs || 12} >
                        <FormControl className={classes.formControl}>
                            <span className={classes.label} >
                                {getTxt('Claim')}
                            </span>
                            <div className={classes.value}>
                                <Link style={{ padding: '6px', color: '#9C27B0', fontWeight: 'bold' }} to={`/contract/${item.insurance_contract_id}/claim/view/${item.claim_id}`} >{item.number}</Link>
                            </div>
                        </FormControl>
                    </GridEx>
                }




            </Paper>
        );
    }
}

export const logDetailsQuery = gql`
query logDetailsQuery ($logId: Int) {
    logDetails(logId: $logId ){
        system_log_id
        entity_type_cd

        contract {
            insurance_type_cd
            insurance_contract_id
            contract_number
        }
        broker {
            broker_id
            display_name
        }
        customer{
            customer_type_cd
            customer_id
            display_name
        }
        referent{
            parent_id
            referent_id
            first_name
            last_name
        }
        ins_request_veicoli {
            insurance_request_id
            insurance_type_cd
        }
        ins_request_cose {
            insurance_request_id
            insurance_type_cd
        }
        ins_request_persone {
            insurance_request_id
            insurance_type_cd
        }
        ins_request_cauzione {
            insurance_request_id
            insurance_type_cd
        }
        ins_request_other {
            insurance_request_id
            insurance_type_cd
        }
        company {
            insurance_company_id
            company_name
        }
        contract_item {
            contract_item_id
        }
        company_payment{
            company_payment_id
        }
        customer_payment{
            customer_payment_id
        }
        broker_percent{
            broker_percent_id
        }
        claim{
            claim_id
            insurance_contract_id
            number
        }
    }
}`;

export default compose(

    graphql(logDetailsQuery, {
        options: {
            options: props => (
                {
                    variables:
                    {
                        logId: props.logId,
                    },
                    fetchPolicy: 'network-only',
                    errorPolicy: 'all',
                }),

        },
    }),
)(withStyles(styles)(ChangeLogDetails));
