import React, { Component } from 'react';
import { getTxt } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import { Paper } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { AppBar } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { TextField } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import { authInfo } from 'global/auth';
import * as Icon from '@material-ui/icons';
import { Checkbox } from '@material-ui/core';

import { Button } from '@material-ui/core';
import moment from 'moment';
import { Badge } from '@material-ui/core';

import ComponentEx from 'components/ComponentEx';

import { p } from 'components/form/Proto';

import { LinearProgress } from '@material-ui/core';
import gql from 'graphql-tag';
import { graphql, compose } from 'react-apollo';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import { EntityTypes, getEntityDescriptor } from 'global/entityDescriptors';

import c from '../../../components/form/Components';
import LinearProgressEx from '../../../components/LinearProgressEx';
import { getLabel, DictionaryTypes, getDictionary, getValue } from '../../../global/dictionaryCache';
import MaterialForm from '../../../components/form/MaterialForm';
import MFormText from '../../../components/form/MFormText';
import { get_Requests_ById_Query, allRequestsQuery } from '../../../modules/request/requestQeries';


import GridEx from 'components/form/GridEx';
import RequestGuarantiesList from '../../../modules/request/RequestGuarantiesList';
import ReadOnlyInTableGroup from '../../../components/form/ReadOnlyInTableGroup';
import { oneTimeTokenMutation } from '../../broker/Broker_Balance_Search';
import { ReportTypes } from '../../../global/reporting';

const styles = theme => ({
});


class Viecoli_View_Details_Form extends ComponentEx {

    render() {



        const { classes } = this.props;

        const request = this.props.request;

        const details = [

            { f: 'vehicle_targa_matricola' },
            { f: 'vehicle_type', v: getLabel(DictionaryTypes.VehicleType, request.vehicle_type) },
            { f: 'vehicle_brand_and_model' },
            { f: 'vehicle_cilinders' },
            { f: 'vehicle_cv_ql' },
            { f: 'vehicle_kw' },
            { f: 'vehicle_immatricolazione' },

            { f: 'vehicle_value' },
            { f: 'vehicle_approval' },
            { f: 'vehicle_engine_type', v: getLabel(DictionaryTypes.VehicleEngineType, request.vehicle_engine_type) },


        ];
        if (!authInfo.isCustomer()) {
            details.push({
                render: (<c.field f="sic_atrc_data" ui="atrc-data-link" viewMode pdf />),
            });
        }

        window.requestDetailsPdfData = [];
        return (

            <MaterialForm
                dataSource={request}
                objType="ins_request_veicoli"
                noFocusOnFirstCtrl
                spacing={2}
                fields={this.props.fields}
            >
                <GridEx item sm="auto" xs="auto" >
                    <MaterialForm spacing={12} dataSource={request} objType="ins_request_veicoli" noFocusOnFirstCtrl fields={this.props.fields} >

                        <c.group l={getTxt('Dati Veicolo')} m pdf />
                        <ReadOnlyInTableGroup m pdf>
                            {details}
                        </ReadOnlyInTableGroup>

                    </MaterialForm>
                </GridEx>


                <GridEx item sm="auto" xs="auto" >
                    <MaterialForm spacing={12} dataSource={request} objType="ins_request_veicoli" noFocusOnFirstCtrl fields={this.props.fields} >

                        <c.group l={getTxt('Uso')} m pdf />
                        <ReadOnlyInTableGroup m pdf>
                            {[
                                { f: 'vehicle_use', v: getLabel(DictionaryTypes.VehicleUseType, request.vehicle_use) },
                                { f: 'vehicle_used_by' },
                                { f: 'vehicle_expiry_limit' },
                                { f: 'vehicle_towable_weight' },
                                { f: 'vehicle_antitheft_type' },
                                { f: 'vehicle_alarm_satellite_alarm_brand_model' },

                            ]}
                        </ReadOnlyInTableGroup>

                    </MaterialForm>
                </GridEx>

                <GridEx item sm="auto" xs="auto" >
                    <MaterialForm spacing={12} dataSource={request} objType="ins_request_veicoli" noFocusOnFirstCtrl fields={this.props.fields} >

                        <c.group l={getTxt('Situazione Assicurariva')} m pdf />
                        <ReadOnlyInTableGroup m pdf>
                            {[
                                { f: 'prev_company' },
                                { f: 'prev_company_division' },
                                { f: 'prev_cv_prov' },
                                { f: 'prev_cu_ass' },
                                { f: 'prev_contract_expiry' },
                            ]}
                        </ReadOnlyInTableGroup>




                    </MaterialForm>
                </GridEx>


                <GridEx item sm="auto" xs="auto" >
                    <MaterialForm spacing={12} dataSource={request} objType="ins_request_veicoli" noFocusOnFirstCtrl fields={this.props.fields} >

                        <c.group l={getTxt('Caratteristiche di Richesta')} m pdf />
                        <ReadOnlyInTableGroup m pdf>
                            {[
                                { f: 'ins_maximum' },
                                { f: 'ins_km_annul' },
                                { f: 'ins_period', v: getLabel(DictionaryTypes.InsPeriod, request.ins_period) },
                                { f: 'ins_guide_type', v: getLabel(DictionaryTypes.InsuranceinsGuideType, request.ins_guide_type) },
                                { f: 'ins_convention_description', v: getLabel(DictionaryTypes.ConventionDescription, request.ins_convention_description) },

                                { f: 'ins_franchise' },
                                { f: 'ins_contract_date' },


                            ]}
                        </ReadOnlyInTableGroup>

                    </MaterialForm>
                </GridEx>

                <GridEx item sm="auto" xs={12} >
                    <MaterialForm spacing={12} dataSource={request} objType="ins_request_veicoli" noFocusOnFirstCtrl fields={this.props.fields} >

                        <c.group l={getTxt('Informazioni Aggiuntive')} m pdf />
                        <ReadOnlyInTableGroup m pdf>
                            {[
                                { render: <c.readOnlyNewLine f="additional_information" multiline pdf /> },
                                { render: <c.readOnlyNewLine f="comments" multiline pdf /> },
                            ]}
                        </ReadOnlyInTableGroup>



                    </MaterialForm>
                </GridEx>

            </MaterialForm>

        );
    }
}

export default withStyles(styles)(Viecoli_View_Details_Form);