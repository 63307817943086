import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import { Typography } from '@material-ui/core';

import { Grid } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import gql from 'graphql-tag';
import { graphql, compose } from 'react-apollo';
import GridEx from 'components/form/GridEx';

import { Button } from '@material-ui/core';
import $ from 'jquery';
import { withRouter } from 'react-router-dom';
import LinearProgressEx from 'components/LinearProgressEx';
import { getTxt, randomString } from 'global/appGlobal';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import { init as initAuth } from 'global/auth';

const styles = theme => ({
    formRoot: {
        flexGrow: 1,
        width: '100%',
    },
    formGroup: {
        marginTop: theme.spacing.unit * 5,
        marginBottom: theme.spacing.unit,
        width: '100%',
        height: '2px',
        position: 'relative',
    },
    formGroupLabel: {
        position: 'absolute',
        backgroundColor: theme.palette.background.paper,
        top: -16,
        paddingLeft: 5,
        paddingRight: 10,
    },

    gridFilterInput: {
        width: '100%',
    },
    formButtons: {
        float: 'right',
        margin: '15px',
    },
    button: {
        width: '100%',
        margin: theme.spacing.unit,
        marginTop: theme.spacing.unit * 3,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: '100%',
        marginTop: 0,
        marginBottom: 0,
    },
    passwordField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: '100%',
        marginTop: 0,
    },
    loginRoot: {
        [theme.breakpoints.up('md')]: {
            width: '350px',
        },
        [theme.breakpoints.up('sm')]: {

            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing.unit * 2,
        paddingRight: theme.spacing.unit * 4,
        marginTop: theme.spacing.unit * 3,
    },
    dialogText: {
        color: theme.palette.error.main,
    },


});


class LoginPage extends Component {

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.validateName = this.validateName.bind(this);
        this.validatePassword = this.validatePassword.bind(this);
        this.onNameChange = this.onNameChange.bind(this);
        this.onPasswordChange = this.onPasswordChange.bind(this);
        this.formId = randomString(5, 'aA');
    }

    state = {
        nameFieldError: undefined,
        pwdFieldError: undefined,
        loading: undefined,
        serverError: undefined,
        serverErrorDialogOpen: false,
    };

    componentDidMount() {
        $('#login_user').focus();
    }

    onNameChange() {
        const name = $('#login_user').val();
        this.validateName(name);
    }

    onPasswordChange() {
        const password = $('#login_password').val();
        this.validatePassword(password);
    }

    validateName(name) {
        this.setState({ nameFieldError: undefined });
        const n = name.trim();
        if (n === '') {
            this.setState({ nameFieldError: 'Please input User name.' });
            $('#login_user').focus();
            return false;
        }
        const maxLength = 50;
        if (n.length > maxLength) {
            this.setState({ nameFieldError: `Max length must be less or equal ${maxLength} characters.` });
            $('#login_user').focus();
            return false;
        }
        return true;
    }

    validatePassword(pwd) {
        this.setState({ pwdFieldError: undefined });
        const n = pwd.trim();
        if (n === '') {
            this.setState({ pwdFieldError: 'Please input Password.' });
            $('#login_password').focus();
            return false;
        }
        const maxLength = 250;
        if (n.length > maxLength) {
            this.setState({ pwdFieldError: `Max length must be less or equal ${maxLength} characters.` });
            $('#login_password').focus();
            return false;
        }
        return true;
    }

    onSubmit(e) {
        e.preventDefault();
        this.setState({ serverError: undefined });
        const name = $('#login_user').val();
        const password = $('#login_password').val();
        if (!this.validateName(name) || !this.validatePassword(password)) {
            return;
        }



        this.setState({ loading: true });
        this.props.login({
            variables: { user: { name, password } },
        }, this).then(
            (resp) => {
                this.setState({ loading: false });
                if (resp.errors && resp.errors.length > 0) {
                    //show errors
                } else if (resp.data.loginMutation.error) {
                    this.setState({ serverError: resp.data.loginMutation.error, serverErrorDialogOpen: true });
                } else {

                    initAuth(resp.data.loginMutation);

                    this.props.history.push('/');

                }
            });
    }



    render() {
        const { classes } = this.props;

        return (

            <div className={classes.loginRoot}>

                <Typography variant="h4" color="inherit" noWrap={false}>{getTxt('Log in', '#64tpw12')}</Typography>
                <LinearProgressEx loading={this.state.loading} />

                <form onSubmit={this.onSubmit} id={this.formId} noValidate onKeyDown={(e) => {
                    if (e.keyCode == 13) {
                        this.onSubmit(e);
                    }
                }} >
                    <Grid container spacing={4}>
                        <GridEx item sm={12} xs={12}>
                            <TextField
                                className={classes.textField}
                                label={getTxt('Nome', '#u7e4jki')}
                                margin="normal"
                                required
                                onChange={this.onNameChange}
                                inputProps={{ id: 'login_user', name: 'user' }}
                                helperText={this.state.nameFieldError}
                                error={this.state.nameFieldError ? true : false}
                            />
                        </GridEx>

                        <GridEx item sm={12} xs={12}>
                            <TextField
                                className={classes.textField}
                                label={getTxt('Parola', '#f8gvb12')}
                                margin="normal"
                                required
                                type="password"
                                onChange={this.onPasswordChange}
                                inputProps={{ id: 'login_password', name: 'password' }}
                                helperText={this.state.pwdFieldError}
                                error={this.state.pwdFieldError ? true : false}
                            />
                        </GridEx>

                        <GridEx item sm={12} xs={12}>
                            <Button
                                color="primary"
                                className={classes.button}
                                type="submit"
                                variant="contained"
                            >{getTxt('Log in', '#u3g4j8i')}</Button>
                        </GridEx>

                    </Grid>
                </form>

                <Dialog
                    className={classes.dialog}
                    open={this.state.serverErrorDialogOpen}
                    onClose={() => {
                        this.setState({ serverError: undefined, serverErrorDialogOpen: false });
                    }}
                >
                    <DialogTitle> <span className={classes.dialogText}> Accesso fallito </span></DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <span className={classes.dialogText}> Username o password non validi. </span>
                        </DialogContentText>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { this.setState({ serverError: undefined, serverErrorDialogOpen: false }); }} color="primary" >
                            Ok
                        </Button>

                    </DialogActions>
                </Dialog>

            </div>

        );
    }
}


const loginMutation = gql`

mutation LoginMutation ($user: User_Login_Input!) {
    loginMutation(user: $user){
        token
        tokenLife
        userInfo {
            displayName
            roles
            customerType
            user_id
        }
        error
    } 
}

`;

export default compose(
    graphql(loginMutation, {
        name: 'login',
        options: {
            errorPolicy: 'all',
        },
    }),
)(withRouter(withStyles(styles)(LoginPage)));
