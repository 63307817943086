import ComponentEx from 'components/ComponentEx';
import { getTxt } from 'global/appGlobal';
import { Dialog, DialogContent } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { compose, graphql } from 'react-apollo';
import LinearProgressEx from '../../components/LinearProgressEx';
import c from '../../components/form/Components';
import MaterialForm from '../../components/form/MaterialForm';
import { DictionaryTypes, getDictionary, getLabel } from '../../global/dictionaryCache';
import { metadataQuery } from '../../home/Home';
import Custom_Fields_Edit from '../../modules/publicInsType/Custom_Fields_Edit';
import * as queries from '../../modules/translation/translationsQueries';
import { EntityTypes } from '../../global/entityDescriptors';

let history;
let AfterSubmit;

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing.unit * 2,
        margin: theme.spacing.unit * 2,
    },
});


class Translation_Edit extends ComponentEx {

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.formChanged = this.formChanged.bind(this);
    }

    state = {
        open: false,
    };

    formSetValues = { func: undefined };

    formChanged(field, value, type, prevVal) {
    }

    onSubmit(e, { obj, showErrors, hideErrors }) {
        const original = this.props.data.translationById;
        obj.translation_id = original.translation_id;

        if (this.props.createOverride) {
            obj.translation_id = '-1';
        }

        this.props.updateTranslation({
            variables: { translation: obj },
            refetchQueries: [
                {
                    query: queries.allTranslationsListQuery,
                },
                {
                    query: queries.translationByIdQuery,
                    variables: { id: original.translation_id },
                },
                {
                    query: metadataQuery,
                },
            ],
        }, this).then(
            (resp) => {
                hideErrors();
                if (resp.data.updateTranslationMutation &&
                    resp.data.updateTranslationMutation.validation &&
                    resp.data.updateTranslationMutation.validation.length > 0) {
                    showErrors(resp.data.updateTranslationMutation.validation);
                } else {
                    if (!AfterSubmit) {
                        history.goBack();
                    } else {
                        AfterSubmit(resp.data.updateTranslationMutation.id);
                    }
                }
            });
    }

    render() {

        const { classes } = this.props;

        const loading = this.props.data.loading;
        const item = this.props.data.translationById;
        history = this.props.history;
        AfterSubmit = this.props.AfterSubmit;
        let caption;


        if (item && item.alt_key && !this.props.createOverride) {
            caption = getTxt('Edit Overrided Translation');
        }
        if (item && !item.alt_key && !this.props.createOverride) {
            caption = getTxt('Edit Translation');
        }
        if (item && !item.alt_key && this.props.createOverride) {
            caption = getTxt('Create Overrided Translation');
        }

        return (


            <Dialog onClose={() => { this.props.AfterSubmit(); }} open >
                <DialogContent>
                    <LinearProgressEx loading={loading} />
                    <Typography variant="h4" color="inherit" noWrap={false}>{caption}</Typography>
                    

                    {item &&

                        <MaterialForm
                            dataSource={item}
                            objType={EntityTypes.Translation}
                            onSubmit={this.onSubmit}
                            onChange={this.formChanged}
                            setValues={this.formSetValues}
                        >


                            <c.field f="original" d />
                            <c.field f="translated" />
                            {item.alt_key && <c.field f="alt_key" d />}
                            {this.props.createOverride && <c.field f="alt_key" />}



                            <c.formButtons sm={12} onCancel={() => { this.props.AfterSubmit(); }} />
                        </MaterialForm>


                    }

                </DialogContent>

            </Dialog>

        );
    }
}

export default compose(
    graphql(queries.translationByIdQuery, {
        options: props => (
            {
                variables: {
                    id: (!props.id ? (props.match.params.id ? props.match.params.id : 'new') : props.id),
                },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }
        ),
    }),
    graphql(queries.updateTranslationMutation, {
        name: 'updateTranslation',
        options: {
            errorPolicy: 'all',
          
        },
    }),
)(withStyles(styles)(Translation_Edit));
