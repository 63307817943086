import React, { Component } from 'react';
import { getTxt } from 'global/appGlobal';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';

import { Grid } from '@material-ui/core';

const styles = theme => ({

});

const classes = {
    gridEx: {
        paddingTop: '0px !important',
        paddingBottom: '0px !important',

    },
};

class GridEx extends Component {

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange(value) {
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    render() {

        return (
            <Grid style={classes.gridEx} {...this.props} >
                {this.props.children}
            </Grid>
        );
    }
}

export default withStyles(styles, { withTheme: true })(GridEx);
