import { FilteringState, IntegratedFiltering, IntegratedSorting, SortingState } from '@devexpress/dx-react-grid';
import { Grid, TableFilterRow, TableHeaderRow, VirtualTable, TableColumnResizing } from '@devexpress/dx-react-grid-material-ui';
import ComponentEx from 'components/ComponentEx';
import c from 'components/form/Components';
import { getToken } from 'global/auth';
import { ReportTypes } from 'global/reporting';
import $ from 'jquery';
import { Button, Tooltip, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { TableCell } from '@material-ui/core';
import { Menu, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { Checkbox } from '@material-ui/core';
import { graphql, compose } from 'react-apollo';
import { Typography } from '@material-ui/core';
import gql from 'graphql-tag';
import { Link } from 'react-router-dom';
import LinearProgressEx from '../../components/LinearProgressEx';
import LinkToCustomerById from '../../components/LinksToObjectById';
import { getTxt, filterRowMessages, tableMessages, createGridResizerCols } from '../../global/appGlobal';
import { DictionaryTypes, getDictionary, getLabel } from '../../global/dictionaryCache';
import { allCustomersQuery, deleteCustomerMutation } from '../../modules/client/clientQueries';
import RegularCard from '../../components/dashboard/RegularCard';
import * as Icon from '@material-ui/icons';
import { authInfo } from 'global/auth';

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        margin: theme.spacing.unit * 3,
    },
    button: {
        marginRight: theme.spacing.unit,
    },

    tableHeader: {
        backgroundColor: theme.palette.background.paper,
    },

});


const toLowerCase = value => String(value).toLowerCase();
const filterByBroker = (value, filter, row) => {
    const name = toLowerCase(row.broker.display_name);
    return name.includes(toLowerCase(filter.value));
};

const filterAddress = (value, filter, row) => {
    const addr = (row.address_cap ? row.address_cap : ' ') +
        (row.address_street ? ', ' : ' ') +
        (row.address_street ? row.address_street : ' ') +
        (row.address_street_number ? ', ' : ' ') +
        (row.address_street_number ? row.address_street_number : ' ');

    return addr.toUpperCase().includes(filter.value.toUpperCase());
};

function getIsSelected(id, selectedId, selectedIds) {
    if (selectedIds && selectedIds.length) {
        return selectedIds.find(i => i === id);
    }
    return id === selectedId + '';
}

function getPhone(f1, f2, f3) {
    let phone = f1;
    if (!phone) {
        phone = f2;
    }
    if (!phone) {
        phone = f3;
    }
    return phone;
}

class Client_Search extends ComponentEx {

    state = {
        anchorEl: null,
        open: false,
        selectedId: undefined,
        selectedIds: [],
    };

    constructor(props) {
        super(props);

        this.tableCellTemplate = ({ row, column, style }) => {
            if (column.name === 'display_name') {
                return (<VirtualTable.Cell> <LinkToCustomerById customer={row} /> </VirtualTable.Cell>);
            }
            return undefined;
        };

        this.getRowId = row => row.customer_id;
        this.newClick = this.newClick.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ selectedId: nextProps.selectedId });
        this.setState({ selectedIds: nextProps.selectedIds ? JSON.parse(nextProps.selectedIds) : [] });
    }

    componentDidMount() {
        $("table[class*='Table-headTable']").addClass(this.props.classes.tableHeader);
    }

    newClick(event) {
        this.setState({ open: true, anchorEl: event.currentTarget });
    }

    handleRequestClose = () => {
        this.setState({ open: false });
    };

    handleDelete() {
        this.props.deleteCustomer({
            variables: { id: this.state.delId },
            refetchQueries: [
                {
                    query: allCustomersQuery,
                },
            ],
        }, this).then(
            (resp) => {
                this.setState({ delId: undefined, delNumber: undefined });
            });
    }

    render() {
        const { classes } = this.props;

        const allCustomers = this.props.data.allCustomers ? this.props.data.allCustomers : [];

        let columns = [
            { name: 'display_name', title: getTxt('Nome') },
            { name: 'customer_id', title: getTxt('Codice Interno') },
            { name: 'customer_type_cd', title: getTxt('Tipo') },
            { name: 'address_street', title: getTxt('Indirizzo') },
            { name: 'address_city', title: getTxt('Città') },
            { name: 'address_province', title: getTxt('Province') },
            { name: 'email', title: getTxt('Email') },
            { name: 'vat_number', title: getTxt('VAT Number') },
            { name: 'fiscal_code', title: getTxt('Fiscal Code') },
            { name: 'owner_broker_id', title: getTxt('Collaboratore') },


        ];

        if (!this.props.showSelects) {
            if (authInfo.isAgencyAdministrator() || authInfo.isMasterBroker()) {
                columns.push({ name: 'is_deleted', title: getTxt('Actions') });
            }
        }

        if (this.props.showSelects) {
            columns = [{ name: 'referent_phone', title: getTxt('Selected') }].concat(columns);
        }

        createGridResizerCols(this.state, columns, { display_name: 240, customer_id: 100 }, 180);

        const onSelectedChanged = this.props.onSelectedChanged ? this.props.onSelectedChanged.bind(this) : undefined;
        const setState = this.setState.bind(this);
        const state = this.state;
        const multiselect = this.props.multiselect;

        let title = getTxt('Customers');
        if (this.props.match && this.props.match.params.filter && this.props.match.params.filter === 'docs-expiry-next-60-days') {
            title = 'Documenti in scadenza a 60 giorni';
        }
        if (this.props.match && this.props.match.params.filter && this.props.match.params.filter === 'customers-no-contracts') {
            title = 'Clienti senza polizza';
        }

        return (




            <div className={classes.root}>
                <LinearProgressEx loading={this.props.data.loading} />
                <RegularCard

                    headerColor="purple"
                    cardTitle={title}
                    cardSubtitle={getTxt('List of customers', '#znft9se')}
                    content={
                        <div>
                            {!this.props.noAddButton && (this.props.match && !this.props.match.params.filter) && <Button color="primary" className={classes.button} onClick={this.newClick} >
                                {getTxt('Add new Customer')}
                            </Button>}
                            {!this.props.noPdfButton && (this.props.match && !this.props.match.params.filter) && <Button
                                color="primary"
                                onClick={() => {

                                    const newWindow = window.open("", "_blank");
                                    newWindow.document.write(getTxt('Generating PDF Report, please wait...'));
                                    this.props.getOneTimeToken({

                                    }, this).then(
                                        (resp) => {



                                            if (resp.data && resp.data.oneTimeTokenMutation && resp.data.oneTimeTokenMutation.token) {

                                                newWindow.location.href = `${window.apiUrl}files/reports?ott=${resp.data.oneTimeTokenMutation.token}&type=${ReportTypes.AllCustomersList}`;
                                                newWindow.focus();
                                            }


                                        });



                                }}
                            >
                                {getTxt('Create PDF')}
                            </Button>}
                            {!this.props.noPdfButton && (this.props.match && !this.props.match.params.filter) && <Button
                                color="primary"
                                onClick={() => {

                                    const newWindow = window.open("", "_blank");
                                    newWindow.document.write(getTxt('Generazione del report XLS, attendere prego...'));
                                    this.props.getOneTimeToken({

                                    }, this).then(
                                        (resp) => {

                                            if (resp.data && resp.data.oneTimeTokenMutation && resp.data.oneTimeTokenMutation.token) {
                                                newWindow.location.href = `${window.apiUrl}files/reports?ott=${resp.data.oneTimeTokenMutation.token}&type=${ReportTypes.AllCustomersList}&params=${JSON.stringify({ xlsx: true })}`;
                                                newWindow.focus();
                                            }

                                        });

                                }}
                            >
                                {getTxt('Genera XLS')}
                            </Button>}
                        </div>

                    }
                />

                <Menu
                    id="simple-menu"
                    anchorEl={this.state.anchorEl}
                    open={this.state.open}
                    onClose={this.handleRequestClose}
                >
                    <MenuItem onClick={() => { this.props.history.push('/customers/new/company/'); }}>{getTxt('Azienda')}</MenuItem>
                    <MenuItem onClick={() => { this.props.history.push('/customers/new/private/'); }}>{getTxt('Private')}</MenuItem>
                    <MenuItem onClick={() => { this.props.history.push('/customers/new/prospect/private/'); }}>{getTxt('Prospect')}</MenuItem>

                </Menu>

                <Grid

                    rows={allCustomers}
                    columns={columns}

                >
                    <FilteringState
                        defaultFilters={[{}]}
                    />



                    <IntegratedFiltering
                        columnExtensions={[
                            { columnName: 'owner_broker_id', predicate: filterByBroker },
                            { columnName: 'address_street', predicate: filterAddress },
                        ]}
                    />
                    <SortingState
                        defaultSorting={[
                            { columnName: 'Nome', direction: 'asc' },
                        ]}
                        columnExtensions={[
                            { columnName: 'is_deleted', sortingEnabled: false },
                        ]}
                    />
                    <IntegratedSorting />





                    <VirtualTable
                        messages={tableMessages}
                        //columnExtensions={tableColumnExtensions}
                        cellComponent={({ row, column, style, ...props }) => {
                            if (column.name === 'referent_phone') {
                                return (<VirtualTable.Cell>
                                    <Checkbox
                                        onChange={(e, val) => {
                                            if (multiselect) {

                                                const without = state.selectedIds.filter(id => id !== row.customer_id);

                                                if (val) {
                                                    without.push(row.customer_id);
                                                }
                                                setState({ selectedIds: without });
                                                if (onSelectedChanged) {
                                                    onSelectedChanged(without);
                                                }

                                            } else {
                                                setState({ selectedId: val ? row.customer_id : undefined });
                                                if (onSelectedChanged) {
                                                    onSelectedChanged(val ? row.customer_id : undefined);
                                                }
                                            }


                                        }}
                                        checked={getIsSelected(row.customer_id, state.selectedId, state.selectedIds)}
                                    />
                                </VirtualTable.Cell>);
                            }
                            if (column.name === 'customer_id') {
                                if (row.customer_type_cd === 'PRIVATE_PROSPECT') {
                                    return (<VirtualTable.Cell>{row.customer_id} </VirtualTable.Cell>);
                                }
                                return (<VirtualTable.Cell> {row.customer_id} </VirtualTable.Cell>);
                            }
                            if (column.name === 'customer_type_cd') {
                                return (<VirtualTable.Cell> {getLabel(DictionaryTypes.CustomerType, row.customer_type_cd)} </VirtualTable.Cell>);
                            }
                            if (column.name === 'address_street') {
                                return (<VirtualTable.Cell>
                                    {
                                        (row.address_cap ? row.address_cap : ' ') +
                                        (row.address_street ? ', ' : ' ') +
                                        (row.address_street ? row.address_street : ' ') +
                                        (row.address_street_number ? ', ' : ' ') +
                                        (row.address_street_number ? row.address_street_number : ' ')
                                    }
                                </VirtualTable.Cell>);
                            }
                            if (column.name === 'display_name') {
                                if (row.customer_type_cd === 'PRIVATE_PROSPECT') {
                                    return (<VirtualTable.Cell> <Link to={`/customers/prospect/private/${row.customer_id}`} style={{ padding: '6px', color: '#9C27B0', fontWeight: 'bold' }} >{row.display_name}</Link> </VirtualTable.Cell>);
                                }
                                return (<VirtualTable.Cell > <Link to={`/customers/${row.customer_type_cd.toLowerCase()}/${row.customer_id}`} style={{ padding: '6px', color: '#9C27B0', fontWeight: 'bold' }} >
                                    {row.display_name} </Link> </VirtualTable.Cell>);
                            }
                            if (column.name === 'address_province') {
                                return (<VirtualTable.Cell> {getLabel(DictionaryTypes.ItalianProvinces, row.address_province)} </VirtualTable.Cell>);
                            }
                            if (column.name === 'address_city') {
                                return (<VirtualTable.Cell> {getLabel(DictionaryTypes.ItalianCities, row.address_city)} </VirtualTable.Cell>);
                            }
                            if (column.name === 'owner_broker_id' && row.broker) {
                                return (<VirtualTable.Cell><Link to={`/brokers/${row.broker.broker_id}`} >{row.broker.display_name}</Link></VirtualTable.Cell>);
                            }
                            if (column.name === 'mobile_phone') {
                                return (<VirtualTable.Cell>
                                    {getPhone(row.mobile_phone, row.mobile_phone, row.referent_phone)}
                                </VirtualTable.Cell>);
                            }
                            if (column.name === 'is_deleted') {
                                return (<VirtualTable.Cell>

                                    <Tooltip title={getTxt('Delete')}>
                                        <IconButton size="small" onClick={() => { this.setState({ delId: row.customer_id, delNumber: row.display_name }); }} className={classes.inGridButton} >
                                            <Icon.Delete fontSize="small" />
                                        </IconButton>
                                    </Tooltip>

                                </VirtualTable.Cell>);
                            }

                            return <VirtualTable.Cell {...props} />;
                        }}
                    />

                    <TableFilterRow
                        messages={filterRowMessages}
                        cellComponent={({ column, filter, onFilter, ...props }) => {
                            if (column.name === 'referent_phone') {
                                return <TableCell onFilter={onFilter} filter={filter} {...props} >{''}</TableCell>;
                            }
                            if (column.name === 'customer_type_cd') {
                                return (
                                    <c.gridColFilter noFilterVal={getTxt('Tutti', '#k9ezdp1')} setFilter={onFilter} >
                                        {getDictionary(DictionaryTypes.CustomerType)}
                                    </c.gridColFilter>);
                            }

                            if (column.name === 'address_province') {
                                return (
                                    <c.gridColFilter noFilterVal={getTxt('Tutti', '#fq1jbs0')} setFilter={onFilter} >
                                        {getDictionary(DictionaryTypes.ItalianProvinces)}
                                    </c.gridColFilter>);
                            }

                            return <TableFilterRow.Cell onFilter={onFilter} filter={filter} {...props} />;
                        }}
                    />

                    <TableColumnResizing
                        columnWidths={this.state.columnWidths}
                        onColumnWidthsChange={(cols) => { this.setState({ columnWidths: cols }); }}
                        defaultColumnWidths={this.state.defColumnWidths}
                    />
                    <TableHeaderRow showSortingControls />


                </Grid>
                <Dialog onClose={() => { this.setState({ delId: undefined, delNumber: undefined }); }} open={this.state.delId}>
                    <DialogTitle >{getTxt('Cancella Cliente') + ' ' + this.state.delNumber}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <span>{getTxt('Il cliente sarà cancellato sul server.')}</span>
                            <br />
                            <span >{getTxt('Sei sicuro di voler cancellare il cliente?')}</span>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleDelete(this.state.delId)} color="primary">
                            {getTxt('Ok')}
                        </Button>
                        <Button onClick={() => { this.setState({ delId: undefined, delNumber: undefined }); }} color="primary">
                            {getTxt('Annula')}
                        </Button>

                    </DialogActions>
                </Dialog>

            </div >
        );
    }


}

const oneTimeTokenMutation = gql`

mutation oneTimeTokenMutation  {
    oneTimeTokenMutation{
        token
    }
}
`;

export default compose(

    graphql(allCustomersQuery, {
        options: props => (
            {
                variables: {

                    filter: props.match ? props.match.params.filter : null,
                },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }
        ),
    }),

    graphql(oneTimeTokenMutation, {
        name: 'getOneTimeToken',
        options: {
            errorPolicy: 'all',

        },
    }),
    graphql(deleteCustomerMutation, {
        name: 'deleteCustomer',
        options: {

            errorPolicy: 'all',
        },
    }),

)(withStyles(styles)(Client_Search));
