import React, { Component } from 'react';
import gql from 'graphql-tag';
import { graphql, compose } from 'react-apollo';
import { getTxt, toDecimalString } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';


import File_Search from 'modules/file/File_Search';


import c from 'components/form/Components';



import LinearProgressEx from '../../components/LinearProgressEx';
import { getLabel, getDictionary, DictionaryTypes } from '../../global/dictionaryCache';


import { Divider } from '@material-ui/core';
import { ListItemIcon, ListItemText } from '@material-ui/core';
import * as Icons from '@material-ui/icons';


import GridEx from 'components/form/GridEx';
import * as queries from '../../modules/contract/contractQueries';
import MaterialForm from '../../components/form/MaterialForm';
import ReadOnlyInTableGroup from '../../components/form/ReadOnlyInTableGroup';
import ComponentEx from '../../components/ComponentEx';

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        margin: theme.spacing.unit * 2,
    },
    button: {
        margin: theme.spacing.unit,
    },

    tableHeader: {
        backgroundColor: theme.palette.background.paper,
    },
    menuItem: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& $text, & $icon': {
                color: theme.palette.common.white,
            },
        },
    },

    columnsWithButtons: {
        width: 1,
    },

    title: {
        ...theme.typography.button,
        //position: 'absolute',
        //backgroundColor: theme.palette.background.paper,
        //top: -13,
        // left: 25,
        // paddingLeft: 0,
        // paddingRight: 10,
        color: theme.palette.text.secondary,
        fontWeight: '900',
        marginTop: theme.spacing.unit * 2,
        marginLeft: theme.spacing.unit * 2,
    },
    table: {
        width: 0,
    },
    itemNotesForm: {
        paddingLeft: theme.spacing.unit * 2,
    },
});

function getMinusOrEmpty(isNegative, amount) {
    if (!isNegative || amount === 0 || amount === '0.00' || !amount) {
        return '';
    }
    return '-';
}

class PremioCalculator extends ComponentEx {

    constructor(props) {
        super(props);
        this.updateClick = this.updateClick.bind(this);
    }

    state = {
        loading: false,
    };

    updateClick(totals) {
        //this.setState({ loading: true });
        this.props.updateContractItemTotals({
            variables: {
                itemId: this.props.contractItem.contract_item_id,
                totalNetto: totals.netto,
                brokerAmount: totals.broker,
                agencyAmount: totals.agency,
                clientAmount: totals.client,
            },
            // refetchQueries: [
            //     {
            //         query: queries.allContractsItemsQuery,
            //         variables: { contractId: totals.insurance_contract_id },
            //     },0
            // {
            //     query: queries.contractItemByIdQuery,
            //     variables: {
            //         id: totals.contract_item_id,
            //         contractId: totals.insurance_contract_id,
            //     },
            // },

            // ],
        }, this).then(
            (resp) => {
                window.updateTotalsNeedFor = null;
                //this.setState({ loading: false });
                if (window.refetchContractList) {
                    window.refetchContractList();
                }
                if (window.refetchContractItemView) {
                    Object.keys(window.refetchContractItemView).forEach(key => window.refetchContractItemView[key]());
                }
                if (window.refetchContractItemView_) {
                    Object.keys(window.refetchContractItemView_).forEach(key => window.refetchContractItemView_[key]());
                }
                if (window.refetchItemDetails) {
                    Object.keys(window.refetchItemDetails).forEach(key => window.refetchItemDetails[key]());
                }


            });
    }

    totals = { netto: 0, imposte: 0, ssn: 0, client: 0, broker: 0, agency: 0 };

    render() {
        const { classes } = this.props;

        let items = this.props.items;
        if (!items) {
            items = [];
        }

        this.totals = { netto: 0, imposte: 0, ssn: 0, client: 0, broker: 0, agency: 0, provvigioni_totali: 0 };

        let extra_broker_percent = 0;
        let diritti = 0;
        let competenze = 0;
        let accessori = 0;
        let autentica = 0;
        let spese = 0;
        let contractItem;
        let isNegative;
        if (items.length > 0 && this.props.contractItem) {
            contractItem = this.props.contractItem;
            isNegative = contractItem.contract_item_type.is_negative === 'true';
            extra_broker_percent = contractItem.extra_broker_percent;
            diritti = contractItem.diritti;
            competenze = contractItem.competenze;
            accessori = contractItem.accessori;
            autentica = contractItem.autentica;
            spese = contractItem.spese;
        }


        items.forEach((i) => {

            // let brokerPercent = i.broker_percent;
            // const inOverrides = this.props.brokerPercent.find(pb => pb.guaranty_type_id === i.guaranty_type_id);
            // if (inOverrides) {
            //     brokerPercent = inOverrides.broker_percent;
            // }


            this.totals.netto += i.net_amount;
            this.totals.imposte += i.imposte_percent;
            this.totals.ssn += i.ssn_percent;

            this.totals.broker += i.net_amount * (i.agency_percent / 100) * ((i.broker_percent + extra_broker_percent) / 100);

            this.totals.agency += ((i.net_amount * i.agency_percent) / 100) - (i.net_amount * (i.agency_percent / 100) * ((i.broker_percent + extra_broker_percent) / 100));
            this.totals.contract_item_id = i.contract_item_id;
            this.totals.insurance_contract_id = i.insurance_contract_id;

            let is_negative = false;
            if (i.contract_item && i.contract_item.contract_item_type && i.contract_item.contract_item_type.is_negative === 'true') {
                is_negative = true;
            }


        }, this);



        this.totals.client = this.totals.netto + this.totals.imposte + this.totals.ssn + diritti + competenze + accessori + autentica + spese;
        const totalBroker = this.totals.broker; /* + (diritti + competenze) * (extra_broker_percent / 100); */
        this.totals.broker = totalBroker;
        const totalAgency = this.totals.agency; /* + diritti + competenze + accessori + autentica + spese - totalBroker; */
        this.totals.agency = totalAgency;

        this.totals.provvigioni_totali = this.totals.agency + this.totals.broker;

        const isFinancial = contractItem && contractItem.contract_item_type.is_financial === 'true';

        if (window.updateTotalsNeedFor &&
            window.updateTotalsNeedFor === this.props.contractItem.contract_item_id &&
            items.length > 0) {
            window.updateTotalsNeedFor = null;
            this.updateClick(this.totals);
        }

        let fields = [
            { l: getTxt('Total Net Amount'), v: getMinusOrEmpty(isNegative, this.totals.netto) + toDecimalString(this.totals.netto, true), t: 'DECIMAL', currency: true },
            { l: getTxt('Total Imposte'), v: toDecimalString(this.totals.imposte, true), t: 'DECIMAL', currency: true },
        ];
        if (this.props.base_ins_type === 'VEICOLI') {
            fields = [...fields, { l: getTxt('Total SSN'), v: toDecimalString(this.totals.ssn, true), t: 'DECIMAL', currency: true }];
        }
        fields = [
            ...fields,
            { l: getTxt('Total Cliente Amount'), v: getMinusOrEmpty(isNegative, this.totals.client) + toDecimalString(this.totals.client, true), t: 'DECIMAL', currency: true },
            { l: getTxt('Agency Amount'), v: getMinusOrEmpty(isNegative, this.totals.agency) + toDecimalString(this.totals.agency, true), t: 'DECIMAL', currency: true },
            { l: getTxt('Broker Amount'), v: getMinusOrEmpty(isNegative, this.totals.broker) + toDecimalString(this.totals.broker, true), t: 'DECIMAL', currency: true },
            { l: getTxt('Provvigioni Totali'), v: getMinusOrEmpty(isNegative, this.totals.provvigioni_totali) + toDecimalString((this.totals.provvigioni_totali ? this.totals.provvigioni_totali : 0), true) },
        ];

        return (

            <div className={classes.root}>
                <LinearProgressEx loading={this.state.loading} />

                {contractItem && contractItem != null &&
                    <div >


                        <MaterialForm dataSource={contractItem} objType="contract_item" onSubmit={this.onSubmit} onChange={this.fornChanged}>
                            {isFinancial && <GridEx item sm={3} xs={12}>
                                <MaterialForm spacing={12} dataSource={contractItem} objType="contract_item" >
                                    <c.group l={getTxt('Premio Calculator')} />
                                    <ReadOnlyInTableGroup>{fields}</ReadOnlyInTableGroup>
                                </MaterialForm>
                            </GridEx>}
                            {isFinancial && <GridEx item sm={3} xs={12}>
                                <MaterialForm spacing={12} dataSource={contractItem} objType="contract_item" >
                                    <c.group l={getTxt('Payment information')} />
                                    <ReadOnlyInTableGroup>
                                        {
                                            [
                                                { l: getTxt('Payment Type'), f: 'item_payment_type_cd', v: getLabel(DictionaryTypes.PaymentType, contractItem.item_payment_type_cd) },
                                                { l: getTxt('Payment State'), f: 'item_payment_state_cd', v: getLabel(DictionaryTypes.InsuranceItemPaymentState, contractItem.item_payment_state_cd) },
                                                { l: getTxt('Payment Date'), f: 'item_payment_date' },
                                                { f: 'payment_account', v: getLabel(DictionaryTypes.PaymentAccount, contractItem.payment_account) },
                                                { l: getTxt('Payment Notes'), f: 'payment_notes' },
                                            ]
                                        }
                                    </ReadOnlyInTableGroup>
                                </MaterialForm>
                            </GridEx>}

                            {isFinancial && <GridEx item sm={3} xs={12}>
                                <MaterialForm spacing={12} dataSource={contractItem} objType="contract_item" >
                                    <c.group l={getTxt('Other Amounts')} />

                                    <ReadOnlyInTableGroup>
                                        {
                                            [
                                                { l: getTxt('Autentica'), f: 'autentica' },
                                                { l: getTxt('Spese'), f: 'spese' },
                                                { l: getTxt('Extra Broker %'), f: 'extra_broker_percent', currency: true },
                                                { l: getTxt('Diritti'), f: 'diritti' },
                                                { l: getTxt('Competenze'), f: 'competenze' },
                                                { l: getTxt('Accessori'), f: 'accessori' },
                                            ]
                                        }
                                    </ReadOnlyInTableGroup>


                                </MaterialForm>
                            </GridEx>}

                            {contractItem && contractItem.item_notes && <MaterialForm spacing={2} dataSource={contractItem} objType="contract_item" >
                                <GridEx item sm={12} xs={12}>
                                    <MaterialForm spacing={12} dataSource={contractItem} objType="contract" >
                                        <div className={classes.itemNotesForm}>
                                            <c.readOnlyInLine l={getTxt('Notes')} f="item_notes" />
                                        </div>
                                    </MaterialForm>
                                </GridEx>
                            </MaterialForm>}

                            <GridEx item sm={12} xs={12}>
                                <MaterialForm spacing={2} dataSource={contractItem} objType="contract_item" >
                                    <File_Search entityId={contractItem.contract_item_id} noFilters hideEmptyGrid />
                                </MaterialForm>
                            </GridEx>

                        </MaterialForm>

                    </div>
                }



            </div >
        );
    }


}





export default compose(

    graphql(queries.updateContractItemTotalsMutation, {
        name: 'updateContractItemTotals',
        options: {

            errorPolicy: 'all',
        },
    }),

)(withStyles(styles)(PremioCalculator));

