import React, { Component } from 'react';
import { getTxt, toItalianDateString, toDecimalString } from 'global/appGlobal';

import { withStyles } from '@material-ui/core/styles';
import { withTheme } from '@material-ui/core';

import { Typography } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';
import ReactSVG from 'react-svg';
import { authInfo } from 'global/auth';

import { Paper } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { AppBar } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { TextField } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import { graphql, compose } from 'react-apollo';
import TabContainer from 'components/TabContainer';

import { Tabs, Tab } from '@material-ui/core';

import HeaderViewItem from 'components/HeaderViewItem';

import * as Icon from '@material-ui/icons';
import { Checkbox } from '@material-ui/core';
//import TabsEx, { Tab } from 'global/components/tabs/TabsEx';


import { Button } from '@material-ui/core';
import moment from 'moment';
import { Badge } from '@material-ui/core';

import ComponentEx from 'components/ComponentEx';
import { EntityTypes, getEntityDescriptor } from 'global/entityDescriptors';


import $ from 'jquery';
import CmdBar from 'components/form/CmdBar';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import GridEx from 'components/form/GridEx';

import File_Search from 'modules/file/File_Search';
import NavPills from 'components/NavPills/NavPills';
import ItemGrid from 'components/Grid/ItemGrid';


import Quote_Search from '../../../modules/quote/Quote_Search';
import { requestBriefQuery } from '../../../modules/request/requestQeries';
import ChangeRequestStateDialog from '../../../modules/request/ChangeRequestStateDialog';


import { HashRouter, BrowserRouter, Route, Link, withRouter } from 'react-router-dom';
import { getLabel, DictionaryTypes, getDictionary, getDictionaryItem, getValue, getInternalInsTypeCd } from '../../../global/dictionaryCache';
import RequestGuarantiesList from '../../../modules/request/RequestGuarantiesList';
import Request_Details_View from '../../../modules/request/Request_Details_View';
import RegularCard from '../../../components/dashboard/RegularCard';
import CmdBarCustomBtns from '../../../components/CmdBarCustomBtns';
import * as queries from '../../../modules/contract/claim/claimQueries';
import c from '../../../components/form/Components';
import MaterialForm from '../../../components/form/MaterialForm';
import ReadOnlyInTableGroup from '../../../components/form/ReadOnlyInTableGroup';
import { ReportTypes } from '../../../global/reporting';
import { oneTimeTokenMutation } from '../../broker/Broker_Balance_Search';
import { toItalianDateString2 } from '../../../global/appGlobal';


const styles = theme => ({

    root: {
        backgroundColor: theme.palette.grey,
        margin: theme.spacing.unit * 3,
        marginTop: 0,
    },

    button: {
        margin: theme.spacing.unit,
    },

    badge: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        marginTop: 0,
    },

    gridRoot: {
        height: '100%',
    },


    formCaption: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit * 2,
        width: '100%',
        position: 'relative',
        height: 40,
    },
    formCaptionLabel: {
        position: 'absolute',
        //color: theme.palette.primary1,
        backgroundColor: 'transparent',
        left: 25,
        paddingLeft: 10,
        paddingRight: 10,
    },

    rootPaper: theme.mixins.gutters({

        paddingTop: 0,
        paddingBottom: 0,
        marginTop: 0,// theme.spacing.unit * 3,

    }),

    formHeaderRoot: {
        padding: theme.spacing.unit * 2,
        position: 'relative',
        overflowX: 'auto',
    },

    formHeaderFieldLabel: {
        color: theme.palette.text.disabled,
        fontWeight: 'bold',

    },

    tableHeader: {
        backgroundColor: theme.palette.background.paper,
    },

    headerIcon: {
        width: theme.spacing.unit * 8,
        height: theme.spacing.unit * 9,
        marginRight: theme.spacing.unit * 2,
    },

    cmdBar: {
        position: 'absolute',
        right: theme.spacing.unit * 3,
    },
    dialogText: {
        color: theme.palette.error.main,
    },

    noGuarantyErrorText: {
        ...theme.typography.body1,
        color: theme.palette.error.main,
        paddingLeft: theme.spacing.unit * 2,
    },
    formRoot: {
        marginLeft: theme.spacing.unit * 2,
    },
});

const tabsStateGlobalId = 'Request_View_active_Tab';

class Claim_Details_View extends ComponentEx {

    constructor(props) {
        super(props);
        this.generatePDF = this.generatePDF.bind(this);
    }

    innerID = 'Claim_View';

    state = {
        value: window[tabsStateGlobalId] ? window[tabsStateGlobalId] : 0,
        currentSate: undefined,

    };

    componentDidMount() {
        $("table[class*='Table-headTable']").addClass(this.props.classes.tableHeader);
    }

    handleChange = (event, value) => {
        this.setState({ value });
    };

    componentWillUnmount() {
        window[tabsStateGlobalId] = this.state.value;
    }

    generatePDF() {

        const claim = { ...this.props.data.claim };

        const newWindow = window.open("", "_blank");
        newWindow.document.write(getTxt('Generating PDF, please wait...'));
        this.props.getOneTimeToken({

        }, this).then(
            (resp) => {

                if (resp.data && resp.data.oneTimeTokenMutation && resp.data.oneTimeTokenMutation.token) {
                    newWindow.location.href = encodeURI(`${window.apiUrl}files/reports?ott=${resp.data.oneTimeTokenMutation.token}&type=${ReportTypes.Claim_Details}&params=${JSON.stringify(claim)}`);
                    newWindow.focus();
                }

            });
    }


    render() {

        const { classes, theme } = this.props;
        const { value } = this.state;

        const loading = this.props.data.loading;
        const item = this.props.data.claim;

        const fields = item ? [
            { f: 'number', l: getTxt('Number') },
            { f: 'incident_date_time', l: getTxt('Incident Date Time'), v: toItalianDateString(item.incident_date_time, true), directValue: true },
            { f: 'description', l: getTxt('Description') },
            { f: 'description', l: getTxt('Contract'), v: item.contract.contract_number },
            { f: 'responsibility_type', l: getTxt('Responsibility Type'), v: getLabel(DictionaryTypes.ResponsibilityType, item.responsibility_type) },
            { f: 'status', l: getTxt('Status'), v: getLabel(DictionaryTypes.ClaimStatus, item.status) },

            { f: 'status', l: getTxt('Contraente'), v: item.contract.customer.display_name },


            { f: 'registration_date', l: getTxt('Data Ricezione'), v: toItalianDateString(item.registration_date), directValue: true },
            { f: 'claim_evidence_text', l: getTxt('ClaimEvidence Text') },
            { f: 'counterpart_description', l: getTxt('Counterpart Description') },
            { f: 'counterpart_plate_number', l: getTxt('Counterpart Plate Number') },


            { f: 'liquidation_date', l: getTxt('Liquidation Date'), v: toItalianDateString(item.liquidation_date), directValue: true },
            { f: 'amount_paid', l: getTxt('Amount Paid') },
            { f: 'reserved_amount', l: getTxt('Reserved Amount') },
            { f: 'professiona_claim_evaluation_text', l: getTxt('Professiona Claim Evaluation Text') },
            { f: 'riscontro_cliente', l: getTxt('Rriscontro Cliente'), v: getLabel(DictionaryTypes.RiscontroCliente, item.riscontro_cliente) },
            { f: 'tipologia_di_danno', v: getLabel(DictionaryTypes.TipologiaDiDanno, item.tipologia_di_danno) },
            { f: 'sinistro_multiplo' },


        ] : [];
        if (!authInfo.isCustomer()) {
            fields.push({ f: 'event_description', l: getTxt('Note') });
        }

        return (


            <div className={classes.root}>
                <RegularCard

                    headerColor="red"
                    cardTitle={item && item.number}
                    cardSubtitle={getTxt('Claim')}
                    content={

                        <Grid container>
                            <ItemGrid xs={12} sm={12} md={2}>
                                <HeaderViewItem l={getTxt('Number')}>
                                    {item &&
                                        item.number
                                    }
                                </HeaderViewItem>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={12} md={2}>
                                <HeaderViewItem l={getTxt('Incident Date Time')}>
                                    {item && toItalianDateString(item.incident_date_time)}
                                </HeaderViewItem>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={12} md={2}>
                                <HeaderViewItem l={getTxt('Stato')}>
                                    {item && getLabel(DictionaryTypes.ClaimStatus, item.status)}
                                </HeaderViewItem>
                            </ItemGrid>

                            <ItemGrid xs={12} sm={12} md={2}>
                                <HeaderViewItem l={getTxt('Contract')}>
                                    {item && item.contract &&
                                        <Link to={`/contract/view/${item.contract.insurance_type_cd}/${item.contract.insurance_contract_id}`}> {item.contract.contract_number} </Link>
                                    }
                                </HeaderViewItem>
                            </ItemGrid>

                            <ItemGrid xs={12} sm={12} md={6}>


                                {/* <CmdBarCustomBtns
                                    onClick={((btn) => {
                                        if (btn === getTxt('Go Back')) {
                                            this.props.history.goBack();
                                        }
                                    })}
                                >{[getTxt('Go Back')]}</CmdBarCustomBtns> */}

                            </ItemGrid>

                        </Grid>

                    }

                />



                <NavPills
                    color="warning"
                    alignCenter
                    active={value}
                    onChange={this.handleChange}
                    tabs={[
                        {
                            tabButton: getTxt('Details'),
                            tabIcon: Icon.Dock,
                            tabContent: (
                                <TabContainer>
                                    {
                                        item && <MaterialForm dataSource={item} objType="claim">

                                            <GridEx item sm={3} xs={12} className={classes.formRoot}>
                                                <MaterialForm spacing={2} dataSource={item} objType="claim" >

                                                    <GridEx item sm={this.props.sm || 12} xs={this.props.xs || 12}>
                                                        {!authInfo.isCustomer() &&
                                                            <Button
                                                                color="primary"
                                                                className={classes.button}
                                                                onClick={() => { this.props.history.push(`/contract/${this.props.match.params.contractId}/claim/${this.props.match.params.claimId}`); }}
                                                            >{getTxt('Edit Details')}</Button>
                                                        }
                                                        <Button
                                                            color="primary"
                                                            className={classes.button}
                                                            onClick={this.generatePDF}
                                                        >
                                                            {getTxt('Create PDF')}
                                                        </Button>
                                                        <c.group l={getTxt('_______')} />
                                                    </GridEx>



                                                    <ReadOnlyInTableGroup>
                                                        {
                                                            fields
                                                        }
                                                    </ReadOnlyInTableGroup>

                                                </MaterialForm>
                                            </GridEx>

                                        </MaterialForm>
                                    }
                                </TabContainer>
                            ),
                        },
                        {
                            tabButton: getTxt('Documents'),
                            tabIcon: Icon.AttachFile,
                            tabContent: (
                                <TabContainer>
                                    {item &&
                                        <File_Search
                                            entityId={item.claim_id}
                                            openDialog={this.state.openAttFileDlg}
                                            noAddBtn={authInfo.isCustomer()}
                                            noDelete={authInfo.isCustomer()}
                                        />}
                                </TabContainer>
                            ),
                        },

                    ]}
                />

            </div >
        );
    }
}


export default compose(

    graphql(oneTimeTokenMutation, {
        name: 'getOneTimeToken',
        options: {
            errorPolicy: 'all',

        },
    }),


    graphql(queries.claimByIdQuery, {
        options: props => ({
            variables:
            {
                id: props.match.params.claimId,
                contractId: props.match.params.contractId,
            },
            fetchPolicy: 'network-only',
            options: {
                errorPolicy: 'all',
            },
        }),
    }),

)(withStyles(styles, { withTheme: true })(Claim_Details_View));

