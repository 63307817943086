import { IntegratedSorting, SortingState } from '@devexpress/dx-react-grid';
import { Grid, Table, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import ComponentEx from 'components/ComponentEx';
import { getTxt } from 'global/appGlobal';
import { Button } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import GridEx from 'components/form/GridEx';
import { compose, graphql } from 'react-apollo';
import c from '../../components/form/Components';
import MaterialForm from '../../components/form/MaterialForm';
import LinearProgressEx from '../../components/LinearProgressEx';
import { DictionaryTypes, getDictionaryItem, getLabel } from '../../global/dictionaryCache';
import { getEntityDescriptor } from '../../global/entityDescriptors';
import * as queries from '../../modules/publicInsType/publicInsTypeQueries';
import PublicInsTypeEdit from '../../modules/publicInsType/PublicInsTypeEdit';

let history;
//let AfterSubmit;

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    button: {
        marginBottom: theme.spacing.unit * 3,
    },
});

class Public_Ins_Type_Details_View extends ComponentEx {

    state = {
        delId: undefined,
    };

    render() {
        const { classes } = this.props;

        const loading = this.props.data.loading;
        const item = this.props.data.public_Ins_Type_By_Id;
        history = this.props.history;

        return (

            <div className={classes.root} >
                <LinearProgressEx loading={loading} />
                {item &&
                    <div>
                        <Button
                            color="primary"
                            className={classes.button}
                            onClick={() => { this.setState({ editId: item.public_ins_type_id }); }}
                        >{getTxt('Edit Details')}</Button>

                        {
                            this.state.editId &&
                            <PublicInsTypeEdit
                                id={this.state.editId}
                                AfterSubmit={(() => {
                                    this.setState({ editId: undefined });
                                    this.props.data.refetch();
                                    if (window.Public_Ins_Type_View_Refresh) {
                                        window.Public_Ins_Type_View_Refresh();
                                    }
                                })}

                            />
                        }

                        <MaterialForm
                            dataSource={item}
                            objType="public_ins_type"
                        >


                            <GridEx item sm={3} xs={12}>
                                <MaterialForm spacing={12} dataSource={item} objType="public_ins_type" >

                                    <c.readOnlyInLine l="Actualins Insurance Type" f="actual_ins_type_cd" value={getLabel(DictionaryTypes.InsuranceType, item.actual_ins_type_cd)} />
                                    <c.readOnlyInLine f="public_ins_type_cd" l="Insurance Type Key" />
                                    <c.readOnlyInLine f="public_ins_type_name" l="Insurance Type Name" />
                                </MaterialForm>
                            </GridEx>
                        </MaterialForm>

                    </div>

                }

            </div>

        );
    }
}

export default compose(
    graphql(queries.typeByIdQuery, {
        options: props => (
            {
                variables: {
                    id: (!props.id ? (props.match.params.id ? props.match.params.id : 'new') : props.id),
                },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }
        ),
    }),
)(withStyles(styles)(Public_Ins_Type_Details_View));
