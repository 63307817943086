import React, { Component } from 'react';
import { getTxt, toItalianDateString, toDecimalString } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';
import { InputBase, Typography } from '@material-ui/core';
import { Input } from '@material-ui/core';
import { Paper } from '@material-ui/core';
import { graphql, compose } from 'react-apollo';
import { AppBar } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { TextField } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import * as Icon from '@material-ui/icons';
import { Checkbox } from '@material-ui/core';
import GridEx from 'components/form/GridEx';
import { Button } from '@material-ui/core';
import moment from 'moment';
import { Badge } from '@material-ui/core';
import { p } from 'components/form/Proto';
import $ from 'jquery';
import { TableCell } from '@material-ui/core';
import CurrencyGridFormatter from 'components/CurrencyGridFormatter';
import { HashRouter, BrowserRouter, Route, Link, withRouter } from 'react-router-dom';
import {
    SortingState, SelectionState, FilteringState, GroupingState, DataTypeProvider,
    IntegratedFiltering, IntegratedGrouping, IntegratedSorting, IntegratedSelection, RowDetailState
} from '@devexpress/dx-react-grid';
import {
    Grid, Table,
    VirtualTable, TableHeaderRow, TableFilterRow, TableSelection, TableGroupRow,
    GroupingPanel, DragDropProvider, TableColumnReordering, Toolbar, TableRowDetail, TableColumnResizing,
} from '@devexpress/dx-react-grid-material-ui';
import { } from '@devexpress/dx-react-grid';
import LinearProgressEx from '../../components/LinearProgressEx';
import * as queries from '../../modules/contract/contractQueries';
import { getLabel, DictionaryTypes } from '../../global/dictionaryCache';
import GridDetails_Contract_Item from '../../modules/contract/GridDetails_Contract_Item';
import ComponentEx from '../../components/ComponentEx';
import { tableMessages, createGridResizerCols } from '../../global/appGlobal';
import { Tooltip, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { authInfo } from 'global/auth';

let history;

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    rootAsChildControl: {
        backgroundColor: theme.palette.background.paper,
    },
    button: {
        margin: theme.spacing.unit,
    },

    badge: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        marginTop: 0,

    },
    gridRoot: {
        height: '100%',
    },

    tableHeader: {
        backgroundColor: theme.palette.background.paper,
    },

    guarantyList: {
        marginBottom: theme.spacing.unit * 5,
    },
    textField: {
    },
});

function getIsSelected(id, selectedIds) {
    if (selectedIds && selectedIds.length) {
        return selectedIds.find(i => i === id);
    }
    return false;
}

const getRowId = row => row.contract_item_id;

class Items_Select_Dialog extends ComponentEx {

    constructor() {
        super();
        this.handleDelete = this.handleDelete.bind(this);
        this.setDataPerfezionamentoInContractItemGrid = this.setDataPerfezionamentoInContractItemGrid.bind(this);
    }

    componentWillUnmount() {
        delete window.refetchContractList;
    }

    state = {
        selectedIds: [],
        generateInvoiceFor: 'b',
    };

    handleDelete(id) {

        this.props.deleteContractItem({
            variables: { contractItemId: this.state.delId },
            refetchQueries: [
                {
                    query: queries.allContractsItemsQuery,
                    variables: { contractId: this.props.contractId },
                },
            ],
        }).then(
            (resp) => {

                //data.refetch();
            }
        );
        this.setState({ delDlgOpen: false });
    }

    setDataPerfezionamentoInContractItemGrid(dp) {
        //this.props.contract.data_perfezionamento = dp;
        //this.setState({ data_perfezionamento: dp });
    }

    render() {

        window.setDataPerfezionamentoInContractItemGrid = this.setDataPerfezionamentoInContractItemGrid;

        //this.state.data_perfezionamento = this.props.contract.data_perfezionamento;

        let columns = [

            { name: 'item_type_cd', title: getTxt('Tipo') },
            { name: 'item_start_date', title: getTxt('Start Date') },
            { name: 'item_end_date', title: getTxt('End Data') },

            { name: 'amount_netto', title: getTxt('Amount Netto') },

            { name: 'total_amount_client', title: getTxt('Client Amount') },


            // { title: 'Autentica', name: 'autentica' },
            // { title: 'Spese', name: 'spese' },

            { name: 'item_payment_state_cd', title: getTxt('Is Paid') },
            { name: 'state_cd', title: getTxt('Stato') },
            { name: 'data_perfezionamento', title: getTxt('Data Perfezionamento') },
            { name: 'broker_id', title: getTxt('Broker') },

        ];

        //      createGridResizerCols(this.state, columns, {}, 180);


        if (authInfo.isAgencyAdministrator() || authInfo.isMasterBroker() || authInfo.isBroker() ) {
            columns = [{ name: 'contract_item_id', title: getTxt('Actions'), width: 300 }, ...columns];
        }

        const tableColumnExtensions = [
            { columnName: 'amount_netto', align: 'right' },
            { columnName: 'total_amount_client', align: 'right' },
            { columnName: 'contract_item_id', width: 200 },
        ];

        columns.forEach((c) => {
            c.columnName = c.name;
            c.width = 130;
        });


        const insType = this.props.insType ? this.props.insType : this.props.match.params.insType;
        const { classes } = this.props;
        const itemsOrig = this.props.data.contract_items ? this.props.data.contract_items : [];


        const items = [];
        for (let i = 0; i < itemsOrig.length; i += 1) {
            const item = { ...itemsOrig[i], insType };
            items.push(item);
        }

        history = this.props.history;

        window.refetchContractList = this.props.data.refetch;

        if (items.length > 0 && this.state.selectedIds.length === 0) {
            this.state.selectedIds.push(items[0].contract_item_id);
            if (this.props.onSelectedChanged) {
                this.props.onSelectedChanged(this.state.selectedIds,
                    items[0].broker.broker_type_cd === 'SubBroker' ? (items[0].invoice_generated_for ? items[0].invoice_generated_for : 'b') : undefined);
            }
            if (items[0].invoice_generated_for) {
                this.state.generateInvoiceFor = items[0].invoice_generated_for;
            }
        }

        const state = this.state;
        const setState = this.setState.bind(this);
        const onSelectedChanged = this.props.onSelectedChanged;

        return (

            <div className={this.props.noAddBtn ? classes.rootAsChildControl : classes.root}>
                <LinearProgressEx loading={this.props.data.loading} />







                <Grid

                    rows={items}
                    columns={columns}
                    getRowId={getRowId}
                >

                    {/* <CurrencyGridFormatter
                        for={['amount_netto', 'total_amount_client']}
                    /> */}

                    {/* <RowDetailState
                        expandedRowIds={expanded}
                        onExpandedRowIdsChange={(expandedIds) => {
                            this.setState({ expandedRowIds: expandedIds });
                        }}
                    /> */}
                    <Table
                        messages={tableMessages}
                        columnExtensions={tableColumnExtensions}
                        cellComponent={({ row, column, style, ...props }) => {
                            const isFinancial = row.contract_item_type.is_financial === 'true';
                            const isNegative = row.contract_item_type.is_negative === 'true';

                            if (column.name === 'data_perfezionamento') {
                                return (<VirtualTable.Cell>
                                    {(row.data_perfezionamento ? toItalianDateString(row.data_perfezionamento) : '')}
                                </VirtualTable.Cell>);
                            }

                            if (column.name === 'contract_item_id' && row.broker_id !== -1 && row.broker.broker_type_cd !== 'SubBroker') {
                                return (<VirtualTable.Cell>
                                    <Checkbox
                                        onChange={(e, val) => {

                                            let updated = state.selectedIds;
                                            if (val) {
                                                updated = [row.contract_item_id];
                                                setState({ selectedIds: updated });
                                            }


                                            if (onSelectedChanged) {
                                                onSelectedChanged(updated);
                                            }
                                        }}
                                        checked={getIsSelected(row.contract_item_id, state.selectedIds)}
                                    />
                                </VirtualTable.Cell>);
                            }

                            if (column.name === 'contract_item_id' && row.broker_id !== -1 && row.broker.broker_type_cd === 'SubBroker') {
                                return (<VirtualTable.Cell>
                                    <div>
                                        <InputBase
                                            className={classes.textField}
                                            disabled
                                            size="small"
                                            startAdornment={
                                                <Checkbox
                                                    onChange={(e, val) => {

                                                        let updated = state.selectedIds;
                                                        if (val) {
                                                            updated = [row.contract_item_id];
                                                            setState({ selectedIds: updated, generateInvoiceFor: 'b' });
                                                        }

                                                        if (onSelectedChanged) {
                                                            onSelectedChanged(updated, 'b');
                                                        }
                                                    }}
                                                    checked={getIsSelected(row.contract_item_id, state.selectedIds) && state.generateInvoiceFor === 'b'}
                                                    disabled={row.invoice_generated_for === 'c'}
                                                />
                                            }
                                            variant={(row.invoice_generated_for === 'c') ? 'filled' : ''}
                                            key={'1'}
                                            value={getTxt('Broker')}
                                        />
                                    </div>
                                    <InputBase
                                        className={classes.textField}
                                        disabled
                                        size="small"
                                        startAdornment={
                                            <Checkbox
                                                onChange={(e, val) => {

                                                    let updated = state.selectedIds;
                                                    if (val) {
                                                        updated = [row.contract_item_id];
                                                        setState({ selectedIds: updated, generateInvoiceFor: 'c' });
                                                    }

                                                    if (onSelectedChanged) {
                                                        onSelectedChanged(updated, 'c');
                                                    }
                                                }}
                                                checked={getIsSelected(row.contract_item_id, state.selectedIds) && state.generateInvoiceFor === 'c'}
                                                disabled={row.invoice_generated_for === 'b'}
                                            />
                                        }
                                        variant={(row.invoice_generated_for === 'b') ? 'filled' : ''}
                                        key={'2'}
                                        value={getTxt('Client')}
                                    />

                                </VirtualTable.Cell>);
                            }

                            if (column.name === 'item_type_cd') {
                                return (
                                    <Table.Cell>
                                        <b>
                                            {row.contract_item_type.label}
                                        </b>

                                    </Table.Cell>);
                            }
                            if (column.name === 'item_start_date') {
                                return (
                                    <Table.Cell>
                                        {row.item_start_date ? toItalianDateString(row.item_start_date) : row.item_start_date}
                                    </Table.Cell>);
                            }
                            if (column.name === 'item_end_date') {
                                return (
                                    <Table.Cell>
                                        {row.item_end_date ? toItalianDateString(row.item_end_date) : row.item_end_date}
                                    </Table.Cell>);
                            }
                            if (column.name === 'item_payment_state_cd') {
                                return (
                                    <Table.Cell>
                                        {isFinancial && getLabel(DictionaryTypes.InsuranceItemPaymentState, row.item_payment_state_cd)}
                                        {!isFinancial && ''}
                                    </Table.Cell>);
                            }
                            if (column.name === 'state_cd') {
                                return (
                                    <Table.Cell>
                                        {getLabel(DictionaryTypes.InsuranceItemState, row.state_cd)}
                                    </Table.Cell>);
                            }
                            if (column.name === 'broker_id') {
                                return (<Table.Cell>
                                    <Link to={`/brokers/${row.broker.broker_id}`}>{row.broker.display_name}</Link>
                                </Table.Cell>);
                            }

                            if (column.name === 'total_amount_broker') {
                                return (<Table.Cell>
                                    {isFinancial && toDecimalString(row.total_amount_broker)}
                                    {!isFinancial && ''}
                                </Table.Cell>);
                            }
                            if (column.name === 'amount_netto') {
                                // if (!isFinancial) {
                                //     return (<Table.Cell>{''}</Table.Cell>);
                                // }
                                // if (row.amount_netto === 0) {
                                //     return (<Table.Cell style={{ textAlign: 'right' }} >{toDecimalString(0, true)}</Table.Cell>);
                                // }
                                if (isNegative) {
                                    return (<Table.Cell style={{ textAlign: 'right' }}>{toDecimalString('-' + row.amount_netto, true)}</Table.Cell>);
                                }

                                return (<Table.Cell style={{ textAlign: 'right' }}>
                                    {toDecimalString(row.amount_netto, true)}
                                </Table.Cell>);

                            }
                            if (column.name === 'total_amount_agency') {
                                return (<Table.Cell>
                                    {isFinancial && toDecimalString(row.total_amount_agency, true)}
                                    {!isFinancial && ''}
                                </Table.Cell>);
                            }
                            if (column.name === 'total_amount_client') {
                                // if (!isFinancial) {
                                //     return (<VirtualTable.Cell>{''}</VirtualTable.Cell>);
                                // }
                                // if (row.total_amount_client === 0) {
                                //     return (<Table.Cell>{toDecimalString(0, true)}</Table.Cell>);
                                // }
                                if (isNegative) {
                                    return (<Table.Cell style={{ textAlign: 'right' }}>{toDecimalString('-' + row.total_amount_client, true)}</Table.Cell>);
                                }
                                return (<Table.Cell style={{ textAlign: 'right' }}>
                                    {toDecimalString(row.total_amount_client, true)}
                                </Table.Cell>);
                            }
                            return <Table.Cell {...props} />;
                        }}
                    />

                    {/* <TableRowDetail
                        contentComponent={(e) => GridDetailContainerBase(e, this.props.base_ins_type)}
                    /> */}

                    {/* <TableColumnResizing
                        columnWidths={{ name: 'contract_item_id', width: 300 }}
                        onColumnWidthsChange={(cols) => { this.setState({ columnWidths: cols }); }}
                        defaultColumnWidths={this.state.defColumnWidths}
                    /> */}


                    <TableHeaderRow />
                </Grid>

            </div>
        );
    }
}

export default compose(

    graphql(queries.allContractsItemsQuery, {
        options: props => (
            {
                variables: {
                    contractId: props.contractId,
                },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }
        ),
    }),

)(withRouter(withStyles(styles)(Items_Select_Dialog)));
