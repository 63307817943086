import React, { Component } from 'react';
import 'app/app.css';
import { ApolloProvider } from 'react-apollo';
import { HttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';

import { HashRouter, BrowserRouter, Route, Link } from 'react-router-dom';

import { getToken } from 'global/auth';
import $ from 'jquery';

import Home from 'home/Home';


const authLink = setContext((_, { headers }) => {
    return {
        headers: {
            ...headers,
            authorization: getToken(),
        },
    };
});

const client = new ApolloClient({
    link: authLink.concat(new HttpLink({ uri: window.apolloServerUrl })),
    cache: new InMemoryCache(),
});
window.apolloClient = client;

let theme = createMuiTheme({
    palette: {
        type: 'light',
        purple: '#9C27B0',
    },

});
//theme.palette.primary.main = '#9C27B0';
theme.palette.primary.main = window.themeColor;

theme = { ...theme };
window.theme = theme;

export const appChageTheme = { func: undefined };

export default class App extends Component {
    constructor(props) {
        super(props);
        this.changeTheme = this.changeTheme.bind(this);
        appChageTheme.func = this.changeTheme;


        // $(window).keydown(function (event) {
        //     if (event.keyCode == 13) {
        //         event.preventDefault();
        //         return false;
        //     }
        // });

    }

    componentDidMount() {
        $('html').css('height', '100%');
    }

    state = {
        theme,
    };

    changeTheme(type) {
        const t = createMuiTheme({
            palette: {
                type,
            },
        });

        const t0 = getMuiTheme({
            palette: {
                type,
            },
        });

        this.setState({ theme: t, themeV0: t0 });

    }


    render() {
        return (

            <ApolloProvider client={client}>
                
                <MuiThemeProvider theme={this.state.theme}>
                    <HashRouter key="root-router" >
                        <Route label="Home" path="/" component={Home} />
                    </HashRouter >
                </MuiThemeProvider>
                
            </ApolloProvider>


        );
    }
}

