import React, { Component } from 'react';
import { getTxt, toItalianDateString } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';
import { graphql, compose } from 'react-apollo';
import { p } from 'components/form/Proto';
import $ from 'jquery';
import { Checkbox } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import c from '../../components/form/Components';
import { Button } from '@material-ui/core';
import GridEx from 'components/form/GridEx';
import DeleteIcon from '@material-ui/icons/Delete';
import { HashRouter, BrowserRouter, Route, Link, withRouter } from 'react-router-dom';
import { TextField } from '@material-ui/core';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import * as Icon from '@material-ui/icons';
import {
    SortingState, SelectionState, FilteringState, GroupingState,
    IntegratedFiltering, IntegratedGrouping, IntegratedSorting, IntegratedSelection,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    VirtualTable, TableHeaderRow, TableFilterRow, TableSelection, TableGroupRow,
    GroupingPanel, DragDropProvider, TableColumnReordering, Toolbar, TableColumnResizing,
} from '@devexpress/dx-react-grid-material-ui';
import LinearProgressEx from '../../components/LinearProgressEx';
import * as queries from '../../modules/contract/contractQueries';
import { getLabel, DictionaryTypes, getDictionary } from '../../global/dictionaryCache';
import RegularCard from '../../components/dashboard/RegularCard';
import ComponentEx from '../../components/ComponentEx';
import { filterRowMessages, tableMessages, toDecimalString, createGridResizerCols } from '../../global/appGlobal';
import { Tooltip } from '@material-ui/core';
import { authInfo } from 'global/auth';
import { addDescriptor } from '../../global/entityDescriptors';
import MaterialForm from '../../components/form/MaterialForm';
import { oneTimeTokenMutation } from '../broker/Broker_Balance_Search';
import { ReportTypes } from '../../global/reporting';

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        margin: theme.spacing.unit * 3,
    },
    button: {
        margin: theme.spacing.unit,
    },

    badge: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        marginTop: 0,

    },
    gridRoot: {
        height: '100%',
    },

    tableHeader: {
        backgroundColor: theme.palette.background.paper,
    },

    filterForemRoot: {
        paddingLeft: theme.spacing.unit * 2,
        paddingRight: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
    },
});

let reports_filter_obj_descr;

const OBG_DESCR_KEY = 'trs_filter';

let reportsFilters = {
    contract_delivery_date_from: null,
    contract_delivery_date_to: null,
    contract_delivery_state: null,
};

const toLowerCase = value => String(value).toLowerCase();

const filterBroker = (row, e, a) => {
    const name = toLowerCase(a.broker.display_name);
    return name.includes(toLowerCase(e.value));
};

const filterCompany = (row, e, a) => {
    const name = toLowerCase(a.company.company_name);
    return name.includes(toLowerCase(e.value));
};


const filterCustomer = (row, e, a) => {
    const name = toLowerCase(a.customer.display_name);
    return name.includes(toLowerCase(e.value));
};

class TracciamentoSimpliReport extends ComponentEx {

    constructor(props) {
        super(props);

        this.formChanged = this.formChanged.bind(this);
        this.appplyFilters = this.appplyFilters.bind(this);
        reports_filter_obj_descr = {
            from: {
                id: 'from',
                label: getTxt('Data operazione da'),
                type: 'DATEONLY',
                validators: [],
            },
            to: {
                id: 'to',
                label: getTxt('Data operazione a'),
                type: 'DATEONLY',
                validators: [],
            },
            state: {
                id: 'state',
                label: getTxt('Tracciamento'),
                type: 'STRING',
                validators: [],
            },

        };

        reportsFilters = {
            from: null,
            to: null,
            state: null,
        };

        addDescriptor(OBG_DESCR_KEY, reports_filter_obj_descr);
    }

    state = {
        delId: undefined,
        delNumber: undefined,
    };

    formSetValues = { func: undefined };

    formChanged(field, value, type, prevVal) {
        reportsFilters[field] = value;
        const stateVal = {};
        this.state[field] = value;
        this.props.data.refetch({
            i: Date.now(),
            brokerId: this.props.brokerId,
            expFrom: this.state.from,
            expTo: this.state.to,
            report: false,
            statusofSimpli: this.state.state,
        });
    }


    appplyFilters() {
        this.props.data.refetch({
            i: Date.now(),
            brokerId: this.props.brokerId,
            expFrom: this.state.from,
            expTo: this.state.to,
            report: false,
            statusofSimpli: this.state.state,
        });
    }

    componentDidMount() {
        $("table[class*='Table-headTable']").addClass(this.props.classes.tableHeader);
    }

    render() {

        let columns = [
            { name: 'contract_number', title: getTxt('Numero') },
            { name: 'contract_delivery_state', title: getTxt('Tracciamento') },
            { name: 'contract_delivery_date', title: getTxt('Data operazione') },
        ];
        //if (authInfo.isAgencyAdministrator() || authInfo.isMasterBroker()) {
            columns.push({ name: 'insurance_company_id', title: getTxt('Compagnia') });
        //}
        columns.push({ name: 'customer_id', title: getTxt('Cliente') });

        const insType = this.props.insType ? this.props.insType : this.props.match.params.insType;
        const { classes } = this.props;
        let items = this.props.data.contracts ? this.props.data.contracts : [];

        let insTypeCd;
        if (items.length > 0) {
            insTypeCd = items[0].insurance_type_cd;
        }

        let caption = getTxt('Tracciamento simpli');

        createGridResizerCols(this.state, columns, {}, 220);

        return (

            <div className={classes.root}>
                <LinearProgressEx loading={this.props.data.loading} />
                {!this.props.noCaption && <RegularCard

                    headerColor="red"
                    cardTitle={caption}

                    content={
                        <div>

                        </div>

                    }
                />}


                <div className={classes.filterForemRoot}>
                    <MaterialForm
                        dataSource={reportsFilters}
                        objType={OBG_DESCR_KEY}
                        onChange={this.formChanged}
                        setValues={this.formSetValues}
                    >

                        <c.field f="from" />
                        <c.field f="to" />

                        <c.field l={getTxt('Tracciamento')} f="state" ui="select" >
                            {
                                [
                                    { label: getTxt('Nessuna Inf.'), value: 'Nessuna Inf.' },
                                    { label: getTxt('Da Consegnare'), value: 'Da Consegnare' },
                                    { label: getTxt('Consegnare al Cliente'), value: 'Consegnare al Cliente' },
                                    { label: getTxt('Consegnare al Collaboratore'), value: 'Consegnare al Collaboratore' },
                                    { label: getTxt('Consegnare alla Compagnia'), value: 'Consegnare alla Compagnia' },
                                    { label: getTxt('Firma OTP'), value: 'firma_otp' },
                                ]
                            }
                        </c.field>
                        <GridEx item sm={'auto'} xs={12}>


                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    reportsFilters.from = '';
                                    reportsFilters.to = '';
                                    reportsFilters.state = '';

                                    this.formSetValues.func({
                                        from: '',
                                        to: '',
                                        state: '',
                                    });
                                    this.setState({
                                        from: '',
                                        to: '',
                                        state: '',
                                    });
                                    this.appplyFilters();
                                }}

                            >
                                {getTxt('Reset Filters')}
                            </Button>
                        </GridEx>
                        <GridEx item sm={'auto'} xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {

                                    const newWindow = window.open("", "_blank");
                                    newWindow.document.write(getTxt('Generating PDF Report, please wait...'));
                                    this.props.getOneTimeToken({

                                    }, this).then(
                                        (resp_ott) => {

                                            if (resp_ott.data && resp_ott.data.oneTimeTokenMutation && resp_ott.data.oneTimeTokenMutation.token) {

                                                newWindow.location.href = `${window.apiUrl}files/reports?ott=${resp_ott.data.oneTimeTokenMutation.token}&type=${'tr_simpli'}&params=${JSON.stringify({ from: this.state.from, to: this.state.to, state: this.state.state, tr_simpli: true })}`;
                                                newWindow.focus();
                                            }

                                        });
                                }}
                            >
                                {getTxt('Create PDF')}
                            </Button>


                        </GridEx>

                        <GridEx item sm={'auto'} xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {

                                    const newWindow = window.open("", "_blank");
                                    newWindow.document.write(getTxt('Generazione del report XLS, attendere prego...'));
                                    this.props.getOneTimeToken({

                                    }, this).then(
                                        (resp_ott) => {

                                            if (resp_ott.data && resp_ott.data.oneTimeTokenMutation && resp_ott.data.oneTimeTokenMutation.token) {

                                                newWindow.location.href = `${window.apiUrl}files/reports?ott=${resp_ott.data.oneTimeTokenMutation.token}&type=${'tr_simpli'}&params=${JSON.stringify({ from: this.state.from, to: this.state.to, state: this.state.state, tr_simpli: true, xlsx: true })}`;
                                                newWindow.focus();
                                            }

                                        });
                                }}
                            >
                                {getTxt('Genera XLS')}
                            </Button>


                        </GridEx>

                    </MaterialForm>
                </div>


                <Grid

                    rows={items}
                    columns={columns}

                >
                    {/* <FilteringState
                        defaultFilters={[{}]}
                    />

                    <SortingState
                        columnExtensions={[
                            //                            { columnName: 'insurance_contract_id', sortingEnabled: false },
                        ]}
                        defaultSorting={[
                            //                          { columnName: 'Cliente', direction: 'asc' },
                        ]}
                    />

                    <IntegratedFiltering columnExtensions={
                        [
                            { columnName: 'customer_id', predicate: filterCustomer },
                            { columnName: 'insurance_company_id', predicate: filterCompany },
                        ]}
                    />
                    <IntegratedSorting /> */}

                    <VirtualTable
                        id="sssssssssssssss"
                        messages={tableMessages}

                        cellComponent={({ row, column, style, ...props }) => {

                            if (column.name === 'contract_delivery_date') {
                                return (
                                    <VirtualTable.Cell>
                                        {toItalianDateString(row.contract_delivery_date)}
                                    </VirtualTable.Cell>);
                            }

                            if (column.name === 'contract_number') {
                                return (<VirtualTable.Cell><Link to={`/contract/view/${row.insurance_type_cd}/${row.insurance_contract_id}`} style={{ padding: '6px', color: '#9C27B0', fontWeight: 'bold' }} > {row.contract_number}</Link> </VirtualTable.Cell>);
                            }
                            if (column.name === 'customer_id') {
                                return (<VirtualTable.Cell>
                                    <Link to={`/customers/${row.customer.customer_type_cd.toLowerCase()}/${row.customer.customer_id}`} >{row.customer.display_name}</Link>
                                </VirtualTable.Cell>);
                            }
                            if (column.name === 'insurance_company_id') {
                                return (<VirtualTable.Cell>
                                    {(authInfo.isAgencyAdministrator() || authInfo.isMasterBroker()) &&
                                    <Link to={`/company/view/${row.company.insurance_company_id}`}>{row.company.company_name}</Link> }
                                    {(!authInfo.isAgencyAdministrator() && !authInfo.isMasterBroker()) &&
                                    <span>{row.company.company_name}</span> }
                                </VirtualTable.Cell>);
                            }
                            if (column.name === 'owner_broker_id') {
                                return (<VirtualTable.Cell>
                                    <Link to={`/brokers/${row.broker.broker_id}`}>{row.broker.display_name}</Link>
                                </VirtualTable.Cell>);
                            }
                            if (column.name === 'insurance_type_cd') {
                                return (<VirtualTable.Cell> {getLabel(DictionaryTypes.InsuranceType, row.insurance_type_cd)}</VirtualTable.Cell>);
                            }
                            if (column.name === 'ins_request_veicoli') {
                                return (<VirtualTable.Cell> {row.ins_request_veicoli.vehicle_targa_matricola}</VirtualTable.Cell>);
                            }
                            if (column.name === 'contract_start_date') {
                                return (<VirtualTable.Cell> {row.contract_start_date && toItalianDateString(row.contract_start_date)}</VirtualTable.Cell>);
                            }
                            if (column.name === 'contract_end_date') {
                                return (<VirtualTable.Cell> {row.contract_end_date && toItalianDateString(row.contract_end_date)}</VirtualTable.Cell>);
                            }
                            if (this.props.isCustomerView && column.name === 'state_cd') {
                                return (<VirtualTable.Cell> {row.state_cd ? row.state_cd : getTxt('Polizza')} </VirtualTable.Cell>);
                            }
                            if (column.name === 'insurance_contract_id') {
                                return (<VirtualTable.Cell>

                                    <Tooltip title={getTxt('Delete')}>
                                        <IconButton size="small" onClick={() => { this.setState({ delId: row.insurance_contract_id, delNumber: row.contract_number }); }} className={classes.inGridButton} >
                                            <Icon.Delete />
                                        </IconButton>
                                    </Tooltip>

                                </VirtualTable.Cell>);
                            }
                            // if (column.name === 'contract_delivery_state') {
                            //     if (row.ins_request_veicoli) {
                            //         return (<VirtualTable.Cell> {row.ins_request_veicoli.vehicle_targa_matricola}</VirtualTable.Cell>);
                            //     }
                            //     return (<VirtualTable.Cell>{''}</VirtualTable.Cell>);

                            // }
                            if (column.name === 'amount_netto') {
                                return (<VirtualTable.Cell>
                                    {toDecimalString(row.amount_netto)}
                                </VirtualTable.Cell>);
                            }


                            return <VirtualTable.Cell {...props} />;
                        }}
                    />

                    {/* <TableFilterRow
                        messages={filterRowMessages}
                        cellComponent={({ column, filter, onFilter, ...props }) => {
                            if (column.name === 'insurance_type_cd') {
                                return (
                                    <c.gridColFilter noFilterVal={getTxt('Tutti', '#094c57z')} setFilter={onFilter} >
                                        {getDictionary(DictionaryTypes.InsuranceType)}
                                    </c.gridColFilter>);
                            }
                            if (column.name === 'insurance_contract_id') {
                                return (
                                    <TableFilterRow.Cell>
                                        <TextField margin="normal" style={{ margin: 0 }} disabled placeholder={getTxt('Filter...')} value={''} />
                                    </TableFilterRow.Cell>);
                            }
                            return <TableFilterRow.Cell onFilter={onFilter} filter={filter} {...props} />;
                        }}
                    /> */}

                    <TableColumnResizing
                        columnWidths={this.state.columnWidths}
                        onColumnWidthsChange={(cols) => { this.setState({ columnWidths: cols }); }}
                        defaultColumnWidths={this.state.defColumnWidths}
                    />

                    <TableHeaderRow /*showSortingControls*/ />

                </Grid>
            </div>
        );
    }
}

export default compose(

    graphql(oneTimeTokenMutation, {
        name: 'getOneTimeToken',
        options: {
            errorPolicy: 'all',
           
        },
    }),

    graphql(queries.allContractsQuery, {
        options: props => (
            {
                variables: {
                    customerId: props.customerId,
                    brokerId: props.brokerId,
                    linkedTo: props.linkedTo,
                    markIfLinketTo: props.markIfLinketTo,
                    report: false,
                    statusofSimpli: '',
                },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }
        ),
    }),
)(withRouter(withStyles(styles)(TracciamentoSimpliReport)));

//contract_delivery_state
//contract_delivery_date