import React from 'react';
import { graphql, compose } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import $ from 'jquery';
import { Link } from 'react-router-dom';

import {
    SortingState, FilteringState, IntegratedFiltering, IntegratedSorting,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    VirtualTable, TableHeaderRow, TableFilterRow, TableColumnResizing,
} from '@devexpress/dx-react-grid-material-ui';
import File_Search from 'modules/file/File_Search';
import CurrencyGridFormatter from 'components/CurrencyGridFormatter';
import { IconButton } from '@material-ui/core';

import * as Icons from '@material-ui/icons';
import ComponentEx from 'components/ComponentEx';
import { Button } from '@material-ui/core';
import c from 'components/form/Components';
import { getTxt, toItalianDateString, toDecimalString, filterRowMessages, tableMessages, createGridResizerCols } from '../../global/appGlobal';
import LinkToCustomerById from '../../components/LinksToObjectById';
import LinearProgressEx from '../../components/LinearProgressEx';
import { getLabel, getDictionary, DictionaryTypes } from '../../global/dictionaryCache';
import { allRequestsQuery } from '../../modules/request/requestQeries';
import { Menu, MenuItem } from '@material-ui/core';
import * as Icon from '@material-ui/icons';
import RegularCard from '../../components/dashboard/RegularCard';
import * as queries from '../../modules/company/CompanyQueries';
import Company_Payment_Edit from '../../modules/company/Company_Payment_Edit';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Tooltip } from '@material-ui/core';
import MaterialForm from '../../components/form/MaterialForm';
import GridEx from 'components/form/GridEx';
import { addDescriptor } from '../../global/entityDescriptors';

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        margin: theme.spacing.unit * 3,
    },
    button: {
        marginRight: theme.spacing.unit,
    },

    tableHeader: {
        backgroundColor: theme.palette.background.paper,
    },
    menuItem: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& $text, & $icon': {
                color: theme.palette.common.white,
            },
        },
    },
    text: {},
    icon: {},
    totalsRow: {
        backgroundColor: theme.palette.grey['200'],
        fontWeight: 'bold',
    },
    formButtons: {
        float: 'right',
        margin: '15px',
    },
    filterForemRoot: {
        paddingLeft: theme.spacing.unit * 2,
        paddingRight: theme.spacing.unit * 2,
    },
});

let reports_filter_obj_descr;

const OBG_DESCR_KEY = 'company_payments_filter';

let reportsFilters = {
    start_date_from: null,
    start_date_to: null,
};

const toLowerCase = value => String(value).toLowerCase();

const filterByCompany = (cellValue, e, row) => {
    const name = toLowerCase(row.company.company_name);
    return name.includes(toLowerCase(e.value));
};

const filterByPaymentDate = (cellValue, e, row) => {
    const dateStr = row.payment_date ? toItalianDateString(row.payment_date) : row.payment_date;
    return dateStr && dateStr.includes(e.value);
};

const tableColumnExtensions = [
    { columnName: 'payment_amount', align: 'right' },
];


class Company_Payments_Search extends ComponentEx {

    state = {
        anchorEl: null,
        open: false,
        editId: undefined,
        delDlgOpen: false,
        delId: undefined,
    };

    constructor() {
        super();
        this.handleDelete = this.handleDelete.bind(this);
        this.getRowId = row => row.company_payment_id;

        this.formChanged = this.formChanged.bind(this);
        this.appplyFilters = this.appplyFilters.bind(this);
        reports_filter_obj_descr = {
            from: {
                id: 'from',
                label: getTxt('Transaction Date from'),
                type: 'DATEONLY',
                validators: [],
            },
            to: {
                id: 'to',
                label: getTxt('Transaction Date to'),
                type: 'DATEONLY',
                validators: [],
            },
        };

        reportsFilters = {
            from: null,
            to: null,
        };

        addDescriptor(OBG_DESCR_KEY, reports_filter_obj_descr);
    }

    formChanged(field, value, type, prevVal) {
        reportsFilters[field] = value;
        const stateVal = {};
        this.state[field] = value;
        this.props.data.refetch({
            from: this.state.from,
            to: this.state.to,
        });
    }

    appplyFilters() {
        this.props.data.refetch({
            from: this.state.from,
            to: this.state.to,
        });
    }

    componentWillUnmount() {
        delete window.refetchCompanyPaymebts;
    }

    formSetValues = { func: undefined };

    componentDidMount() {
        $("table[class*='Table-headTable']").addClass(this.props.classes.tableHeader);
    }

    handleDelete(id) {

        const data = this.props.data;
        this.props.deleteCompanyPayment({
            variables: { id: this.state.delId },
            refetchQueries: [
                {
                    query: queries.allCompanyPaymentsQuery,
                    variables: { companyId: this.props.companyId },
                },
            ],
        }).then(
            (resp) => {

                data.refetch();
            }
        );
        this.setState({ delDlgOpen: false });
    }

    render() {


        if (this.props.quiet) {
            return (<LinearProgressEx loading={this.props.data.loading} />);
        }

        window.refetchCompanyPaymebts = this.props.data.refetch();
        const columns = [

            { name: 'insurance_company_id', title: getTxt('Company') },
            { name: 'payment_type_cd', title: getTxt('Payment Type') },
            { name: 'payment_state_cd', title: getTxt('Payment State') },
            { name: 'payment_date', title: getTxt('Payment Date') },
            { name: 'payment_amount', title: getTxt('Payment Amount') },
            { name: 'payment_notes', title: getTxt('Payment notes') },
            { name: 'payment_account', title: getTxt('Payment Account') },
            { name: 'files', title: getTxt('Documents') },
            { name: 'company_payment_id', title: getTxt('Actions') },
        ];

        createGridResizerCols(this.state, columns, {}, 180);

        const { classes } = this.props;

        let items = this.props.data.allCompanyPayments ? this.props.data.allCompanyPayments : [];
        const loading = this.props.data.loading;

        const caption = getTxt('Company Payments');
        const subtitle = getTxt('List of company payments.');

        const withSubtotal = [];
        if (items.length > 0) {
            let prevName;
            let total = 0;
            items.forEach((i) => {
                if (prevName && i.company.company_name !== prevName) {
                    withSubtotal.push({
                        company_payment_id: -1,
                        insurance_company_id: -1,
                        payment_type_cd: '',
                        payment_state_cd: '',
                        payment_date: '',
                        payment_amount: total,
                        payment_notes: '',
                        payment_account: '',
                        company: {
                            company_name: '',
                        },
                    });
                    total = 0;
                }
                prevName = i.company.company_name;
                const payment = Number.parseFloat(i.payment_amount);
                if (!isNaN(payment)) {
                    total += payment;
                }
                withSubtotal.push(i);
            });

            withSubtotal.push({
                company_payment_id: -1,
                insurance_company_id: -1,
                payment_type_cd: '',
                payment_state_cd: '',
                payment_date: '',
                payment_amount: total,
                payment_notes: '',
                payment_account: '',
                company: {
                    company_name: '',
                },
            });

            items = withSubtotal;
        }
        const this1 = this;
        return (

            <div className={classes.root}>
                <LinearProgressEx loading={loading} />

                {!this.props.noCaption && <RegularCard

                    headerColor="red"
                    cardTitle={caption}
                    cardSubtitle={subtitle}
                    content={
                        <div>
                            {<Button
                                color="primary"
                                className={classes.button}
                                onClick={() => { this.setState({ editId: 'new' }); }}
                            >{getTxt('Aggiungi Nuovo Versamenti')}</Button>}
                        </div>

                    }
                />}

                {this.props.showAlterAddBtn &&
                    <Button
                        color="primary"
                        className={classes.button}
                        onClick={() => { this.setState({ editId: 'new' }); }}
                    >{getTxt('Aggiungi Nuovo Versamenti')}</Button>}
                <div className={classes.filterForemRoot}>
                    <MaterialForm
                        dataSource={reportsFilters}
                        objType={OBG_DESCR_KEY}
                        onChange={this.formChanged}
                        setValues={this.formSetValues}
                    >

                        <c.field f="from" />
                        <c.field f="to" />

                    </MaterialForm>
                </div>
                <Grid

                    rows={items}
                    columns={columns}

                >

                    <CurrencyGridFormatter
                        for={['payment_amount']}
                    />

                    <FilteringState
                        defaultFilters={[{}]}
                    />

                    <SortingState
                        defaultSorting={[

                        ]}
                    />

                    <IntegratedFiltering
                        columnExtensions={[
                            { columnName: 'insurance_company_id', predicate: filterByCompany },
                            { columnName: 'payment_date', predicate: filterByPaymentDate },
                        ]}
                    />
                    <SortingState
                        defaultSorting={[]}
                    />
                    <IntegratedSorting
                        columnExtensions={[
                            // {
                            //     columnName: 'insurance_type_cd',
                            //     compare: (a, b) => {
                            //         const labelA = getLabel(DictionaryTypes.InsuranceType, a);
                            //         const labelB = getLabel(DictionaryTypes.InsuranceType, b);
                            //         if (labelA === labelB) {
                            //             return 0;
                            //         }
                            //         return (labelA < labelB) ? -1 : 1;
                            //     },
                            // },

                        ]}
                    />

                    <VirtualTable
                        messages={tableMessages}
                        columnExtensions={tableColumnExtensions}
                        rowComponent={({ row, ...restProps }) => {
                            if (row.insurance_company_id === -1) {
                                return (<VirtualTable.Row
                                    className={classes.totalsRow}
                                    {...restProps}
                                />);
                            }
                            return (<VirtualTable.Row {...restProps} />);
                        }}
                        cellComponent={({ row, column, style, ...props }) => {

                            if (column.name === 'insurance_company_id') {
                                return (<VirtualTable.Cell><Link to={`/company/view/${row.insurance_company_id}`} >{row.company.company_name}</Link></VirtualTable.Cell>);
                            }
                            if (column.name === 'payment_type_cd') {
                                return (<VirtualTable.Cell>{getLabel(DictionaryTypes.PublicPaymentType, row.payment_type_cd)}</VirtualTable.Cell>);
                            }

                            if (column.name === 'payment_state_cd') {
                                return (<VirtualTable.Cell> {getLabel(DictionaryTypes.InsuranceItemPaymentState, row.payment_state_cd)} </VirtualTable.Cell>);
                            }

                            if (column.name === 'payment_date') {
                                return (<VirtualTable.Cell>
                                    {row.payment_date ? toItalianDateString(row.payment_date) : row.payment_date}
                                </VirtualTable.Cell>);
                            }

                            if (column.name === 'payment_account') {
                                return (<VirtualTable.Cell>
                                    {getLabel(DictionaryTypes.PaymentAccount, row.payment_account)}
                                </VirtualTable.Cell>);
                            }

                            if (column.name === 'company_payment_id' && row.insurance_company_id !== -1) {
                                return (<VirtualTable.Cell>

                                    <Tooltip title={getTxt('Edit')}>
                                        <IconButton size="small" onClick={() => { this.setState({ editId: row.company_payment_id }); }} className={classes.inGridButton} >
                                            <Icon.Edit />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title={getTxt('Delete')}>

                                        <IconButton size="small" onClick={() => { this.setState({ delDlgOpen: true, delId: row.company_payment_id }); }} className={classes.inGridButton} >
                                            <Icon.Delete />
                                        </IconButton>
                                    </Tooltip>

                                </VirtualTable.Cell>);
                            }
                            if (column.name === 'company_payment_id' && row.insurance_company_id === -1) {
                                return (<VirtualTable.Cell>{''}</VirtualTable.Cell>);
                            }
                            if (column.name === 'payment_amount') {
                                return (<VirtualTable.Cell>{toDecimalString(row.payment_amount)}</VirtualTable.Cell>);
                            }
                            if (column.name === 'files') {
                                if (row.company_payment_id === -1) {
                                    return (<VirtualTable.Cell>{''}</VirtualTable.Cell>);
                                }
                                if (!row.files || (row.files && row.files.length === 0)) {
                                    return (<VirtualTable.Cell>
                                        <a
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                this1.setState({ onlyFilesEditId: row.company_payment_id });
                                            }} >{getTxt('no documents attached')}</a> </VirtualTable.Cell>);
                                }
                                if (row.files && row.files.length > 0) {
                                    return (<VirtualTable.Cell>
                                        <a
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                this1.setState({ onlyFilesEditId: row.company_payment_id });
                                            }} >{row.files.length + ' ' + getTxt('document(s) attached')}</a> </VirtualTable.Cell>);
                                }

                            }

                            return <VirtualTable.Cell {...props} />;
                        }}
                    />

                    <TableFilterRow
                        messages={filterRowMessages}
                        cellComponent={({ column, filter, onFilter, ...props }) => {
                            if (column.name === 'company_payment_id') {
                                return (
                                    <TableFilterRow.Cell filteringEnabled={true} >
                                        <span />
                                    </TableFilterRow.Cell>);
                            }
                            if (column.name === 'payment_type_cd') {
                                return (
                                    <c.gridColFilter noFilterVal={getTxt('Tutti')} setFilter={onFilter} >
                                        {getDictionary(DictionaryTypes.PublicPaymentType)}
                                    </c.gridColFilter>);
                            }
                            if (column.name === 'payment_state_cd') {
                                return (
                                    <c.gridColFilter noFilterVal={getTxt('Tutti')} setFilter={onFilter} >
                                        {getDictionary(DictionaryTypes.InsuranceItemPaymentState)}
                                    </c.gridColFilter>);
                            }
                            if (column.name === 'payment_account') {
                                return (
                                    <c.gridColFilter noFilterVal={getTxt('Tutti')} setFilter={onFilter} >
                                        {getDictionary(DictionaryTypes.PaymentAccount)}
                                    </c.gridColFilter>);
                            }
                            if (column.name === 'files') {
                                return (
                                    <TableFilterRow.Cell filteringEnabled >
                                        <span />
                                    </TableFilterRow.Cell>);
                            }



                            return <TableFilterRow.Cell onFilter={onFilter} filter={filter} {...props} />;
                        }}
                    />
                    <TableColumnResizing
                        columnWidths={this.state.columnWidths}
                        onColumnWidthsChange={(cols) => { this.setState({ columnWidths: cols }); }}
                        defaultColumnWidths={this.state.defColumnWidths}
                    />

                    <TableHeaderRow />

                </Grid>
                {
                    this.state.editId &&
                    <Company_Payment_Edit
                        id={this.state.editId}
                        realType={this.props.realType}
                        companyId={this.props.companyId}
                        AfterSubmit={((id) => {
                            this.setState({ editId: undefined });
                            if (id) {
                                this.setState({ paymentId: id, filesDlgOpen: true });
                            }
                            this.props.data.refetch();
                        })}

                    />
                }

                {
                    this.state.paymentId && this.state.filesDlgOpen &&
                    <Dialog onClose={() => { this.setState({ filesDlgOpen: false }); }} open={this.state.filesDlgOpen}>
                        <DialogTitle>{getTxt('Payment Documents.')}</DialogTitle>
                        <DialogContent>
                            <File_Search entityId={this.state.paymentId} />
                        </DialogContent>
                        <DialogActions>
                            <div className={classes.formButtons} >
                                <Button onClick={() => {
                                    this.setState({ filesDlgOpen: false, editId: this.state.paymentId });
                                }}
                                    className={classes.button}
                                    variant="contained">
                                    {getTxt('Back')}
                                </Button>
                                <Button onClick={() => this.setState({ filesDlgOpen: false })} className={classes.button} variant="contained" color="primary">
                                    {getTxt('Save')}
                                </Button>
                            </div>
                        </DialogActions>
                    </Dialog>
                }
                {
                    this.state.onlyFilesEditId &&
                    <Dialog onClose={() => { this.setState({ onlyFilesEditId: false }); }} open={this.state.onlyFilesEditId}>
                        <DialogTitle>{getTxt('Payment Documents.')}</DialogTitle>
                        <DialogContent>
                            <File_Search entityId={this.state.onlyFilesEditId} />
                        </DialogContent>
                        <DialogActions>
                            <div className={classes.formButtons} >
                                <Button onClick={() => {
                                    this.setState({ onlyFilesEditId: false });
                                }}
                                    className={classes.button}
                                    variant="contained">
                                    {getTxt('Cancel')}
                                </Button>
                                <Button onClick={() => this.setState({ onlyFilesEditId: false })} className={classes.button} variant="contained" color="primary">
                                    {getTxt('Save')}
                                </Button>
                            </div>
                        </DialogActions>
                    </Dialog>
                }

                <Dialog onClose={() => { this.setState({ delDlgOpen: false, delId: null }); }} open={this.state.delDlgOpen}>
                    <DialogTitle>{getTxt('Delete company Payment.')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <span> {getTxt('Company payment will be delete on server.')} </span>
                            <br />
                            <span > {getTxt('Are you sure do you want delete the company payment ?')} </span>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleDelete(this.state.delId)} color="primary">
                            {getTxt('Ok')}
                        </Button>
                        <Button onClick={() => { this.setState({ delDlgOpen: false, delId: null }); }} color="primary">
                            {getTxt('Annula')}
                        </Button>

                    </DialogActions>
                </Dialog>
            </div >
        );
    }


}

export default compose(

    graphql(queries.allCompanyPaymentsQuery, {
        options: {
            options: props => (
                {
                    variables:
                    {
                        companyId: props.companyId,
                    },
                    fetchPolicy: 'network-only',
                    errorPolicy: 'all',
                }),

        },
    }),

    graphql(queries.deleteCompanyPaymentMutation, {
        name: 'deleteCompanyPayment',
        options: {
            errorPolicy: 'all',
        },
    }),

)(withStyles(styles)(Company_Payments_Search));
