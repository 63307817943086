import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Typography } from '@material-ui/core';


const styles = theme => ({
    headerViewItem: {
        marginBottom: theme.spacing.unit * 1.5,
    },
    formHeaderFieldLabel: {
        lineHeight: '0.85em',
        color: theme.palette.text.disabled,
        fontWeight: 'bold',
    }
});

class HeaderViewItem extends Component {
    render() {
        const { classes } = this.props;
        return (
            <Grid sm={this.props.sm ? this.props.sm : 3} xs={12} item className={classes.headerViewItem}>
                <Typography variant="body2" className={classes.formHeaderFieldLabel} >{this.props.l}</Typography>
                <Typography variant="body2" id={this.props.id} >{this.props.children}</Typography>
            </Grid>
        );
    }
}

export default withStyles(styles, { withTheme: true })(HeaderViewItem);
