import React, { Component } from 'react';
import { getTxt, toItalianDateString } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';
import { graphql, compose } from 'react-apollo';
import { p } from 'components/form/Proto';
import $ from 'jquery';
import { Checkbox, Typography, makeStyles } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import c from '../../components/form/Components';
import { Button } from '@material-ui/core';
import GridEx from 'components/form/GridEx';
import DeleteIcon from '@material-ui/icons/Delete';
import { HashRouter, BrowserRouter, Route, Link, withRouter, useHistory, useParams } from 'react-router-dom';
import { TextField } from '@material-ui/core';
import { TableCell } from '@material-ui/core';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import * as Icon from '@material-ui/icons';
import {
    SortingState, SelectionState, FilteringState, GroupingState,
    IntegratedFiltering, IntegratedGrouping, IntegratedSorting, IntegratedSelection,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    VirtualTable, TableHeaderRow, TableFilterRow, TableSelection, TableGroupRow,
    GroupingPanel, DragDropProvider, TableColumnReordering, Toolbar, TableColumnResizing,
} from '@devexpress/dx-react-grid-material-ui';
import LinearProgressEx from '../../components/LinearProgressEx';
import * as queries from '../../modules/contract/contractQueries';
import { getLabel, DictionaryTypes, getDictionary } from '../../global/dictionaryCache';
import RegularCard from '../../components/dashboard/RegularCard';
import ComponentEx from '../../components/ComponentEx';
import { filterRowMessages, tableMessages, createGridResizerCols, randomString, createGridResizerColsHooksGrid, getConfigValue } from '../../global/appGlobal';
import { Tooltip } from '@material-ui/core';
import { authInfo } from 'global/auth';
import { MIN_TAKE, FILTERS_TIMEOUT, LOAD_MORE_ROW_VALUE, canLoadMore, getNextTake, MAX_TAKE, filtersPromptVisible } from '../client/Client_Search_BigData';
import { useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useEffect } from 'react';
import gql from 'graphql-tag';


import { sharedSearchQuery } from './sharedSearchQueries';

const LOAD_MORE_PROP = 'contract_number';
let filtersL = [];
let takeL = MIN_TAKE;
let filterTimeoutId;
function isLoadMoreRow(row) {
    return row[LOAD_MORE_PROP] === LOAD_MORE_ROW_VALUE;
}

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        margin: theme.spacing.unit * 3,
    },
    button: {
        margin: theme.spacing.unit,
    },

    badge: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        marginTop: 0,

    },
    gridRoot: {
        height: '100%',
    },

    tableHeader: {
        backgroundColor: theme.palette.background.paper,
    },

    _inGridButton: {
        width: 26,
        height: 20,
        marginRight: theme.spacing.unit * 2,
    },
}));

export default function SharedSearch() {


    const [delId, setDelId] = useState();
    const [delNumber, setDelNumber] = useState();
    const [myId] = useState(randomString(5, 'aA'));
    const filterRowId = 'filterRow_' + myId;
    const gridRootId = 'gridRoot_' + myId;
    const history = useHistory();
    const classes = useStyles();
    const { filter } = useParams();


    const [refetch, { loading, data }] = useLazyQuery(sharedSearchQuery, { client: window.apolloClient });

    useEffect(() => {
        refetch({ variables: { take: takeL, filters: filtersL, id: randomString(7, 'aA') } });
    }, []);

    let columns = [
        { name: 'customer_id', title: getTxt('Cliente') },
        { name: 'contract_number', title: getTxt('Numero Polizza') },
        { name: 'insurance_type_cd', title: getTxt('Tipo') },
        { name: 'contract_delivery_state', title: getTxt('Targa') },
        { name: 'contract_notes', title: getTxt('Server') },

    ];


    const defColumnWidths = createGridResizerColsHooksGrid(columns, {}, 250);
    const [сolumnWidths, setColumnWidths] = useState(defColumnWidths);

    let serverFilterItems = [];

    const items = data && data.sharedSearch ? data.sharedSearch : [];

    serverFilterItems = getConfigValue('partnerAgencies').map((i) => {
        return { label: i.url, value: i.url };
    });
    const myUrl = getConfigValue('client_url');
    serverFilterItems.push({ label: myUrl, value: myUrl });


    let insTypeCd;
    if (items.length > 0) {
        insTypeCd = items[0].insurance_type_cd;
    }

    const caption = getTxt('Ricerca Condivisa');
    const subtitle = getTxt('Interfaccia di ricerca condivisa tra agenzie partner');

    if (items.length > 0 && items.length > takeL && !isLoadMoreRow(items[items.length - 1])) {
        items.push({
            [LOAD_MORE_PROP]: LOAD_MORE_ROW_VALUE,
            customer_id: LOAD_MORE_ROW_VALUE,
        });
    }

    return (

        <div className={classes.root}>
            <LinearProgressEx loading={loading} />
            <RegularCard

                headerColor="red"
                cardTitle={caption}
                cardSubtitle={subtitle}
                content={
                    <div></div>
                }
            />
            <div
                id={gridRootId}
                style={{ position: 'relative' }}
                onChange={((e) => {
                    latestFilterValue = e.target.value;
                })}
            >
                <Grid

                    rows={items}
                    columns={columns}

                >
                    <FilteringState
                        defaultFilters={filtersL}
                        onFiltersChange={async (f, a) => {
                            filtersL = f;

                            if (filterTimeoutId) {
                                clearTimeout(filterTimeoutId);
                            }
                            filterTimeoutId = setTimeout(async () => {
                                filterTimeoutId = undefined;
                                filtersL = f;
                                await refetch({
                                    variables: {
                                        take: takeL,
                                        filters: filtersL,
                                        id: randomString(7, 'aA'),
                                    },
                                });

                            }, FILTERS_TIMEOUT);
                        }}
                    />

                    <SortingState
                        columnExtensions={[
                            { columnName: 'insurance_contract_id', sortingEnabled: false },
                        ]}
                        defaultSorting={[
                            { columnName: 'Cliente', direction: 'asc' },
                        ]}
                    />

                    <VirtualTable
                        messages={tableMessages}
                        rowComponent={({ ...props, row }) => {
                            if (isLoadMoreRow(row)) {
                                return (<VirtualTable.Row
                                    {...props}
                                    style={{ position: 'relative', height: 60 }}
                                >
                                    {canLoadMore(items.length, takeL, MAX_TAKE) &&
                                        <Button
                                            style={{
                                                width: 200,
                                                position: 'absolute',
                                                marginTop: 10,
                                                left: '50%',
                                                marginLeft: -100,
                                            }}
                                            variant='outlined'
                                            onClick={async () => {
                                                takeL = getNextTake([MIN_TAKE, MAX_TAKE], takeL);
                                                refetch({ variables: { take: takeL, filters: filtersL, id: randomString(7, 'aA') } });

                                            }}
                                        >
                                            {getTxt('Load More...')}

                                        </Button>
                                    }

                                    {filtersPromptVisible(items.length, takeL, MAX_TAKE) &&
                                        <Typography
                                            variant="body1"
                                            style={{
                                                whiteSpace: 'normal',
                                                width: 350,
                                                position: 'absolute',
                                                marginTop: 20,
                                                left: '50%',
                                                marginLeft: -175,

                                            }}
                                        >
                                            {getTxt('Please, specify filters to load more rows.')}
                                        </Typography>
                                    }

                                </VirtualTable.Row>);
                            }
                            return (<VirtualTable.Row {...props} />);
                        }}
                        cellComponent={({ row, column, style, ...props }) => {


                            if (column.name === 'contract_number' && !isLoadMoreRow(row)) {
                                if (row.contract_notes === myUrl) {
                                    return (
                                        <VirtualTable.Cell>
                                            <Link to={`/contract/view/${row.insurance_type_cd}/${row.insurance_contract_id}`} style={{ padding: '6px', color: '#9C27B0', fontWeight: 'bold' }} > {row.contract_number}</Link>
                                        </VirtualTable.Cell>
                                    );
                                } else {
                                    return <VirtualTable.Cell>
                                        <a target="_blank" rel="noopener noreferrer" href={`${row.contract_notes}#/contract/view/${row.insurance_type_cd}/${row.insurance_contract_id}`} style={{ padding: '6px', color: '#9C27B0', fontWeight: 'bold' }} > {row.contract_number}</a>
                                    </VirtualTable.Cell>
                                }
                            }
                            if (column.name === 'customer_id' && !isLoadMoreRow(row)) {
                                if (row.contract_notes === myUrl) {
                                    return (<VirtualTable.Cell>
                                        <Link to={`/customers/${row.customer.customer_type_cd.toLowerCase()}/${row.customer.customer_id}`} >{row.customer.display_name}</Link>
                                    </VirtualTable.Cell>);
                                } else {
                                    return (<VirtualTable.Cell>
                                        <a target="_blank" rel="noopener noreferrer" href={`${row.contract_notes}#/customers/${row.customer.customer_type_cd.toLowerCase()}/${row.customer.customer_id}`} >{row.customer.display_name}</a>
                                    </VirtualTable.Cell>);
                                }
                            }
                            if (column.name === 'contract_notes' && isLoadMoreRow(row)) {
                                return (<VirtualTable.Cell>
                                    {row.contract_notes}
                                </VirtualTable.Cell>);
                            }
                            if (column.name === 'contract_delivery_state' && !isLoadMoreRow(row)) {
                                if (row.ins_request_veicoli) {
                                    return (<VirtualTable.Cell> {row.ins_request_veicoli.vehicle_targa_matricola}</VirtualTable.Cell>);
                                }
                                return (<VirtualTable.Cell>{''}</VirtualTable.Cell>);

                            }



                            return <VirtualTable.Cell {...props} />;
                        }}
                    />

                    <TableFilterRow
                        messages={filterRowMessages}
                        rowComponent={({ ...props }) => {
                            return (<VirtualTable.Row id={filterRowId} className={filtersL.length > 0 ? 'filterRowActive' : ''} {...props} />);
                        }}
                        cellComponent={({ column, filter, onFilter, ...props }) => {
                            if (column.name === 'insurance_type_cd') {
                                return (
                                    <c.gridColFilter noFilterVal={getTxt('Tutti', '#094c57z')} setFilter={onFilter} f={'insurance_type_cd'} filters={filtersL} >
                                        {getDictionary(DictionaryTypes.InsuranceType)}
                                    </c.gridColFilter>);
                            }
                            if (column.name === 'contract_notes') {
                                return (
                                    <c.gridColFilter noFilterVal={getTxt('Tutti')} setFilter={onFilter} f={'contract_notes'} filters={filtersL} >
                                        {serverFilterItems}
                                    </c.gridColFilter>);
                            }

                            return <TableFilterRow.Cell onFilter={onFilter} filter={filter} {...props} />;
                        }}
                    />
                    <TableColumnResizing
                        columnWidths={сolumnWidths}
                        onColumnWidthsChange={(cols) => { setColumnWidths(cols); }}
                        defaultColumnWidths={defColumnWidths}
                    />
                    <TableHeaderRow />

                </Grid>

            </div>

        </div>
    );
}
