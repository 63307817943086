
import React, { Component } from 'react';
import { getTxt, randomString } from 'global/appGlobal';

import PropTypes from 'prop-types';


import $ from 'jquery';




const styles = theme => ({});

export default class MFormControl extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isDirty: false,
            value: this.props.v,
            errorMessage: undefined,
            labelShrinkAfterSetValue: undefined,
        };

        this.id = randomString(7, 'aA');
        this.formOnChange = undefined;
        this.isDirty = this.isDirty.bind(this);
        this.getValue = this.getValue.bind(this);
        this.setValue = this.setValue.bind(this);
        this.showErrors = this.showErrors.bind(this);
        this.hideErrors = this.hideErrors.bind(this);
        this.focus = this.focus.bind(this);
        this.hasErrors = this.hasErrors.bind(this);


        if (props.propDescriptor) {
            this.label = props.propDescriptor.label;
        }
        this.label = getTxt(props.en || props.l, props.it);

    }

    componentWillMount() {
        this.formOnChange = this.context.registerControl(this.props.f, {
            isDirty: this.isDirty,
            getValue: this.getValue,
            setValue: this.setValue,
            showErrors: this.showErrors,
            hideErrors: this.hideErrors,
            focus: this.focus,
            hasErrors: this.hasErrors,
            type: this.props.propType,
            id: this.id,
        });
    }

    componentWillUnmount() {
        this.context.unRegisterControl(this.props.f, this.id);
    }

    hasErrors() {
        return this.state.errorMessage !== undefined;
    }

    focus() {
        $(`#${this.id}`).focus();
    }

    isDirty() { return true; } //this.state.isDirty; }

    getValue() { return this.state.value; }

    getLabel() {
        let label;
        if (this.props.propDescriptor) {
            label = this.props.propDescriptor.label;
        }
        if (this.context.fields && this.context.fields.byId[this.props.f]) {
            const customField = this.context.fields.byId[this.props.f];
            if (customField && customField.l) {
                return customField.l;
            }
        }
        const fromMarkup = this.props.l;
        if (fromMarkup) {
            label = fromMarkup;
        }

        return label;
    }

    setValue(val, options) {
        if (val !== this.state.value) {
            this.setState({ value: val, labelShrinkAfterSetValue: true });
            this.formOnChange(this.props.f, val, this.props.type);
            if (options !== 'no focus') {
                this.focus();
            }

        }
    }

    showErrors(errors) {
        if (Array.isArray(errors)) {
            this.setState({ errorMessage: errors[0].message });
        } else {
            this.setState({ errorMessage: errors });
        }
    }

    hideErrors() {
        this.setState({ errorMessage: undefined });
    }
}

MFormControl.contextTypes = {
    onChange: PropTypes.func,
    registerControl: PropTypes.func,
    objType: PropTypes.string,
    dataSource: PropTypes.object,
    unRegisterControl: PropTypes.func,
    fields: PropTypes.Object,
};


