import React, { Component } from 'react';

import { graphql, compose } from 'react-apollo';

import { withStyles } from '@material-ui/core/styles';
import $ from 'jquery';
import { withRouter } from 'react-router-dom';

import ComponentEx from 'components/ComponentEx';
import { authInfo } from 'global/auth';
import { Button } from '@material-ui/core';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import * as Icon from '@material-ui/icons';


import { getTxt, toDecimalString } from '../../global/appGlobal';

import LinearProgressEx from '../../components/LinearProgressEx';
import { getInternalInsTypeCd } from '../../global/dictionaryCache';


import { IconButton } from '@material-ui/core';


import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import * as queries from '../../modules/guaranty/contractGuarantyQueries';
import RequestGuarantyEdit from '../../modules/request/RequestGuarantyEdit';
import PremioCalculator from '../../modules/contract/PremioCalculator';
import { requestBriefQuery } from '../../modules/request/requestQeries';

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        //margin: theme.spacing.unit * 2,
    },
    button: {
        margin: theme.spacing.unit,
    },

    tableHeader: {
        backgroundColor: theme.palette.background.paper,
    },
    menuItem: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& $text, & $icon': {
                color: theme.palette.common.white,
            },
        },
    },

    columnsWithButtons: {
        width: 1,
    },
    table: {
        width: 0,
    }
});

class RequestGuarantiesList extends ComponentEx {

    componentId = Date.now();

    constructor(props) {
        super(props);

        this.getRowId = row => row.customer_id;
        this.newClick = this.newClick.bind(this);
    }


    state = {
        editGuaranty: false,
        anchorEl: null,
        open: false,
        menuOpen: false,
        newRequestDlgOpen: false,

        delDlgOpen: false,
        delId: undefined,
    };


    newClick() {
        this.setState({ editGuaranty: 'new', editGuaranty_reqId: this.props.requestId });
    }

    handleRequestClose = () => {
        this.setState({ menuOpen: false });
    };

    componentWillReceiveProps(nextProps) {

        if (nextProps.data && (nextProps.requestId || nextProps.match.params.id)) {
            this.props.data.refetch();
        }
    }

    componentWillMount() {
        // if (!this.props.data.loading) {
        //     this.props.data.refetch();
        // }



    }

    componentWillUnmount() {
        delete window.refetchContractItemView_[this.componentId];
    }

    componentDidMount() {
        $("table[class*='Table-headTable']").addClass(this.props.classes.tableHeader);
    }

    handleDelete() {

        this.props.deleteGuaranty({
            variables: { id: this.state.delId },
            refetchQueries: [
                {
                    query: queries.allContractGuaranty,
                    variables: { requestId: this.props.requestId },
                },
                {
                    query: requestBriefQuery,
                    variables: {
                        id: this.props.requestId,
                        insType: getInternalInsTypeCd(this.props.insType),
                    },
                },
            ],
        }, this).then(
            () => {
                this.props.data.refetch();
            });
        this.setState({ delDlgOpen: false });
    }

    render() {
        const { classes } = this.props;

        let items = this.props.data.allContractGuaranty;
        if (!items) {
            items = [];
        } else {
            // const iTemp = [];
            // items.forEach((i) => {
            //     iTemp.push({...i});
            // });
            // items = iTemp;
        }


        if (!window.refetchContractItemView_) {
            window.refetchContractItemView_ = {};
        }
        window.refetchContractItemView_[this.componentId] = this.props.data.refetch;

        // let contractItem;
        // if (items.length > 0) {
        //     contractItem = items[0].contract_item;
        // }

        const isFinancial = this.props.contractItem && this.props.contractItem.contract_item_type.is_financial === 'true';

        // if (this.props.brokerPercent ) {
        //     items.forEach((i) => {
        //         const inOverrides = this.props.brokerPercent.find(pb => pb.guaranty_type_id === i.guaranty_type_id);
        //         if (inOverrides) {
        //             i.broker_percent = inOverrides.broker_percent;
        //         }
        //     });
        // }

        return (

            <div className={classes.root}>
                <LinearProgressEx loading={this.props.data.loading} />

                {(!this.props.noAddBtn && !authInfo.isCustomer()) && <Button color="primary" className={classes.button}

                    onClick={this.newClick}
                >{getTxt('Aggiungi nuova Guaranty')}</Button>}


                {
                    this.state.editGuaranty &&
                    <RequestGuarantyEdit
                        id={this.state.editGuaranty}
                        requestId={this.state.editGuaranty_reqId}
                        insType={this.props.insType}
                        contractItemId={this.props.contractItemId}
                        AfterSubmit={(() => {
                            this.setState({ editGuaranty: undefined, editGuaranty_reqId: undefined });
                            this.props.data.refetch();
                        })}

                    />
                }


                {(isFinancial || !this.props.inContract) && <Table className={classes.table} >
                    <TableHead>
                        <TableRow>
                            {this.props.requestState !== 'TRANSFERRED_TO_CONTRACT' && !this.props.noActions && <TableCell className={classes.columnsWithButtons}>Azioni</TableCell>}
                            <TableCell >{getTxt('Guaranty Type')}</TableCell>
                            {!this.props.inContract && <TableCell style={{ textAlign: 'right' }}>{getTxt('Massimale')}</TableCell>}
                            {!this.props.inContract && <TableCell style={{ textAlign: 'right' }}>{getTxt('Franchigia')}</TableCell>}
                            {!this.props.inContract && <TableCell style={{ textAlign: 'right' }}>{getTxt('Scoperto')}</TableCell>}
                            {this.props.inContract && <TableCell style={{ textAlign: 'right' }}>{getTxt('Importo')}</TableCell>}
                            {this.props.inContract && <TableCell style={{ textAlign: 'right' }}>{getTxt('Imposte')}</TableCell>}
                            {this.props.inContract && this.props.base_ins_type === 'VEICOLI' && <TableCell style={{ textAlign: 'right' }}>{getTxt('SSN')}</TableCell>}
                            {this.props.inContract && <TableCell style={{ textAlign: 'right' }}>{getTxt('% Agency')}</TableCell>}
                            {this.props.inContract && <TableCell style={{ textAlign: 'right' }}>{getTxt('% Broker')}</TableCell>}

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map(row => (
                            <TableRow key={row.contract_guaranty_id}>
                                {this.props.requestState !== 'TRANSFERRED_TO_CONTRACT' && !this.props.noActions && <TableCell>
                                    <IconButton
                                        size="small"
                                        onClick={() => {
                                            this.setState({
                                                editGuaranty: row.contract_guaranty_id, editGuaranty_reqId: row.insurance_request_id
                                            });
                                        }}
                                        className={classes.inGridButton}
                                    >
                                        <Icon.Edit />
                                    </IconButton>
                                    {!this.props.inContract &&
                                        <IconButton size="small" onClick={() => { this.setState({ delDlgOpen: true, delId: row.contract_guaranty_id }); }} className={classes.inGridButton} >
                                            <Icon.Delete />
                                        </IconButton>}
                                    {/* <Link to={`/guaranty/${row.insurance_request_id}/${row.contract_guaranty_id}/${this.props.contractId ? this.props.contractId : ''}`} >{row.contract_guaranty_id}</Link> */}
                                </TableCell>}
                                <TableCell><b> {row.guaranty_type.guaranty_name} </b></TableCell>
                                {!this.props.inContract && <TableCell style={{ textAlign: 'right' }}>{toDecimalString(row.maximum_amount, true)}</TableCell>}
                                {!this.props.inContract && <TableCell style={{ textAlign: 'right' }}>{toDecimalString(row.franchise_amount, true)}</TableCell>}
                                {!this.props.inContract && <TableCell style={{ textAlign: 'right' }}>{toDecimalString(row.overdraft_amount, true)}</TableCell>}

                                {this.props.inContract && <TableCell style={{ textAlign: 'right' }}>{toDecimalString(row.net_amount, true)}</TableCell>}
                                {this.props.inContract && <TableCell style={{ textAlign: 'right' }}>{toDecimalString(row.imposte_percent, true)}</TableCell>}
                                {this.props.inContract && this.props.base_ins_type === 'VEICOLI' && <TableCell style={{ textAlign: 'right' }}>{toDecimalString(row.ssn_percent)}</TableCell>}
                                {this.props.inContract && <TableCell style={{ textAlign: 'right' }}>{toDecimalString(row.agency_percent, true)}</TableCell>}
                                {this.props.inContract && <TableCell style={{ textAlign: 'right' }}>{toDecimalString(row.broker_percent, true)}</TableCell>}


                            </TableRow>
                        ), this)}
                    </TableBody>
                </Table>}

                {this.props.inContract && !this.props.noCalculator && this.props.contractItem &&
                    <PremioCalculator items={items} contractItem={this.props.contractItem} brokerPercent={this.props.brokerPercent} base_ins_type={this.props.base_ins_type} />}

                <Dialog onClose={() => { this.setState({ delDlgOpen: false }); }} open={this.state.delDlgOpen}>
                    <DialogTitle >{getTxt('Delete Guaranty type.')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <span>{getTxt('Guaranty type will be delete on server.')}</span>
                            <br />
                            <span >{getTxt('Are you sure do you want delete the Guaranty type?')}</span>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleDelete(this.state.delId)} color="primary">
                            {getTxt('Ok')}
                        </Button>
                        <Button onClick={() => { this.setState({ delDlgOpen: false }); }} color="primary">
                            {getTxt('Annula')}
                        </Button>

                    </DialogActions>
                </Dialog>
            </div >
        );
    }


}





export default compose(

    graphql(queries.allContractGuaranty, {
        options: {
            options: props =>
                (
                    {
                        variables: {
                            requestId: props.match.params.id ? props.match.params.id : props.requestId,
                            contractId: props.contractId,
                            onlyLatest: props.onlyLatest,
                            contractItemId: props.contractItemId,
                        },
                        fetchPolicy: 'network-only',
                        errorPolicy: 'all',
                    }
                ),


        },
    }),
    graphql(queries.deleteContractGuarantyMutation, {
        name: 'deleteGuaranty',
        options: {
            errorPolicy: 'all',
        },
    }),

)(withRouter(withStyles(styles)(RequestGuarantiesList)));
