import React, { Component } from 'react';
import { getTxt } from 'global/appGlobal';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import FormField from 'components/form/FormField';


const styles = theme => ({
    formCaption: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit * 2,
        width: '100%',
        position: 'relative',        
        height: 40,
    },
    formCaptionLabel: {
        position: 'absolute',
        //color: theme.palette.primary1,
        backgroundColor: 'transparent',
        left: 25,
        paddingLeft: 10,
        paddingRight: 10,
    },
});

class FormCaption extends FormField {

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange(value) {
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.formCaption} >
                <Typography

                    className={classes.formCaptionLabel}
                    variant="h4"
                >
                {this.props.children}

                </Typography>
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(FormCaption);
