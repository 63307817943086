import { TableFilterRow } from '@devexpress/dx-react-grid-material-ui';
import schema from 'async-validator';
import FormCaption from 'components/form/FormCaption';
//import FormField from 'components/form/FormField';
import GridEx from 'components/form/GridEx';
import { getTxt, randomString } from 'global/appGlobal';
import { getEntityDescriptor } from 'global/entityDescriptors';
import gql from 'graphql-tag';
import $ from 'jquery';
import { Button } from '@material-ui/core';
import { Checkbox } from '@material-ui/core';
import { FormControl, FormControlLabel, FormGroup } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { InputLabel } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import { Select } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
/* eslint-disable react/no-multi-comp */
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { Link } from 'react-router-dom';
import FormFieldHigh from '../../components/form/FormFieldHigh';
import ReadOnlyAdjustable from '../../components/form/ReadOnlyAdjustable';
import ReadOnlyInLine from '../../components/form/ReadOnlyInLine';
import ReadOnlyNewLine from '../../components/form/ReadOnlyNewLine';
import ReadOnlyLinkToCustomer from '../../components/form/ReadOnlyLinkToCustomer';
import ReadOnlyMultilinkToCustomer from '../../components/form/ReadOnlyMultilinkToCustomer';

const styles = theme => ({
    formRoot: {
        flexGrow: 1,
        width: '100%',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: '100%',
    },
    menu: {
        width: 200,
    },
    checkBox: {
        marginLeft: theme.spacing.unit,
    },
    brief: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit,
    },
    briefLabel: {
        whiteSpace: 'nowrap !important',
        overflow: 'hidden !important',
        textOverflow: 'ellipsis !important',
        marginBottom: theme.spacing.unit / 2,
    },
    briefValue: {
        whiteSpace: 'nowrap !important',
        overflow: 'hidden !important',
        textOverflow: 'ellipsis !important',
        fontWeight: 'bold',
    },
    formGroup: {
        marginTop: theme.spacing.unit * 5,
        marginBottom: theme.spacing.unit,
        width: '100%',
        height: '2px',
        //backgroundColor: theme.palette.text.primary,
        position: 'relative',
    },
    formGroupLabel: {
        ...theme.typography.button,
        position: 'absolute',
        backgroundColor: theme.palette.background.paper,
        top: -13,
        // left: 25,
        paddingLeft: 0,
        paddingRight: 10,
        color: theme.palette.text.secondary,
        fontWeight: '900',
    },

    gridFilterInput: {
        width: '100%',
    },
    formButtons: {
        float: 'right',
        margin: '15px',
    },
    button: {
        margin: theme.spacing.unit,
    },
    selectProgress: {
        position: 'absolute',
        margin: `${theme.spacing.unit * 3.5}px ${theme.spacing.unit * 2}px`,
    },
    groupRoot: {
        marginRight: theme.spacing.unit * 8,
    },
});



class FormField extends Component {
    constructor(props) {
        super(props);

        this.id = randomString(7, 'aA');
        this.onChange = this.onChange.bind(this);
        this.showError = this.showError.bind(this);
        this.hideError = this.hideError.bind(this);
        this.focus = this.focus.bind(this);
        this.validate = this.validate.bind(this);
        this.setDescriptor = this.setDescriptor.bind(this);
        this.setFormOnChange = this.setFormOnChange.bind(this);
        this.descriptor = undefined;
        this.rules = undefined;
        this.formOnChange = undefined;

        //let val = props.v;
        // if (this.props.type === 'date' && !this.props.v) {
        //     val = moment(props.v).format('YYYY-MM-DD') };
        // }
        this.state = {
            value: undefined,
            errorMessage: undefined,
            label: this.props.l,
            required: false,
        };
    }

    componentWillMount() {
        const data = this.context.dataSource;
        if (!this.props.v) {
            if (data && this.props.f) {
                this.setState({ value: this.context.dataSource[this.props.f] });
                this.context.connectChildControl(this.props.f,
                    {
                        showError: this.showError,
                        hideError: this.hideError,
                        focus: this.focus,
                        setDescriptor: this.setDescriptor,
                        validate: this.validate,
                        setFormOnChange: this.setFormOnChange,
                    });
            }
        } else {
            this.setState({ value: this.props.v });
        }

    }

    onChange(e) {
        this.setState({ value: e.target.value });

        if (this.props.onChange) {
            this.props.onChange(e.target.value);
        }
        if (this.formOnChange) {
            this.formOnChange(this.props.f, e.target.value);
        }
    }

    showError(errorMessage) {
        this.setState({ errorMessage });
    }

    hideError() {
        this.setState({ errorMessage: undefined });
    }

    validate() {
        if (!this.rules) return;
        const validator = new schema(this.rules);
        let errs;
        const val = $(`#${this.id}`).val();
        validator.validate({ value: val }, (errors) => {
            errs = errors;
        });
        return errs;
    }

    setFormOnChange(onChange) {
        this.formOnChange = onChange;
    }

    formOnChange(field, value) {
        if (this.props.onChange) {
            this.props.onChange(field, value);
        }
    }

    setDescriptor(descriptor) {
        this.descriptor = descriptor;
        if (!this.props.l && !this.props.it && !this.props.en) {
            this.setState({ label: descriptor.label });
        }

        if (descriptor.validators && descriptor.validators.length > 0) {
            const req = descriptor.validators.find(v => v.required);
            if (req) {
                this.setState({ required: true });
            }
            this.rules = { value: descriptor.validators };
        }
    }

    focus() {
        $(`#${this.id}`).focus();
    }

}

FormField.contextTypes = {
    dataSource: PropTypes.object,
    connectChildControl: PropTypes.func,
};

class P_Input_ extends FormField {

    render() {
        const { classes } = this.props;
        return (

            <GridEx item sm={this.props.sm || 6} xs={this.props.xs || 12}>
                <TextField

                    className={classes.textField}
                    label={this.state.label}
                    margin="normal"
                    {...this.props}
                    value={this.state.value}
                    disabled={this.props.d}
                    required={this.state.required}
                    onChange={this.onChange}
                    inputProps={{ id: this.id, name: this.props.f }}
                    helperText={this.state.errorMessage}
                    error={this.state.errorMessage ? true : false}
                />
            </GridEx>

        );
    }
}

P_Input_.contextTypes = {
    dataSource: PropTypes.object,
    connectChildControl: PropTypes.func,
};

class P_Select_ extends FormField {

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.formOnChange = undefined;
    }

    state = {
        value: undefined,
    };

    componentWillMount() {

        const data = this.context.dataSource;
        if (!this.props.v) {
            if (data && this.props.f) {
                let val = '';
                if (this.props.children && this.props.children.find) {
                    val = this.props.children.find(i => i.value === data[this.props.f]);
                    if (val) val = val.value;
                }
                if (!val) val = '';
                this.setState({ value: val });

                this.context.connectChildControl(this.props.f,
                    {
                        showError: this.showError,
                        hideError: this.hideError,
                        focus: this.focus,
                        setDescriptor: this.setDescriptor,
                        validate: this.validate,
                        setFormOnChange: this.setFormOnChange,
                    });
            }
        } else {
            this.setState({ value: this.props.v });
        }



    }


    onChange(e) {

        this.setState({ errorMessage: undefined });

        if (e.target && this.state.value !== e.target.value) {
            this.setState({ value: e.target.value });
            if (this.props.onChange) {
                this.props.onChange(e.target.value);
            }
            if (this.formOnChange) {
                this.formOnChange(this.props.f, e.target.value);
            }
        }

        this.setState({ errorMessage: undefined });
        const errors = this.validate();
        if (errors && errors.length > 0) {
            this.showError(errors[0].message);
        }


    }

    render() {
        const { classes } = this.props;



        return (

            <GridEx item sm={this.props.sm || 6} xs={this.props.xs || 12}>

                <TextField
                    select
                    label={this.props.l}
                    className={classes.textField}
                    SelectProps={{
                        MenuProps: {
                            className: classes.menu,
                        },
                    }}
                    margin="normal"
                    {...this.props}
                    value={this.state.value}
                    disabled={this.props.d}
                    required={this.props.r}
                    onChange={this.onChange}
                    InputProps={{ id: this.id, name: this.props.f }}
                    error={this.state.errorMessage ? true : false}
                >
                    {this.props.children && this.props.children.map(mi => (
                        <MenuItem key={mi.value} value={mi.value}>
                            {mi.label}
                        </MenuItem>
                    ))}
                </TextField>



            </GridEx>


        );
    }
}

class P_CheckBox_ extends FormField {
    render() {
        const { classes } = this.props;

        return (

            <GridEx item sm={this.props.sm || 6} xs={this.props.xs || 12}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                className={classes.checkBox}
                                onChange={this.onChange}
                                {...this.props}
                                disabled={this.props.d}
                                required={this.props.r}
                                checked={this.state.value}
                                name={this.props.f}
                                InputProps={{ id: this.id, name: this.props.f }}
                                error={this.state.errorMessage ? true : false}
                                helperText={this.state.errorMessage}
                            />
                        }
                        label={this.props.l}
                    />
                </FormGroup>
            </GridEx>


        );
    }
}

class LinkToBroker extends Component {

    render() {
        const { classes } = this.props;

        return (

            <GridEx item sm={this.props.sm || 6} xs={this.props.xs || 12}>
                <FormControl>
                    <InputLabel>
                        {this.props.l}
                    </InputLabel>
                    <Link
                        to={this.props.to}
                        {...this.props}
                        disabled={this.props.d}
                    >{this.props.v}</Link>
                </FormControl>
            </GridEx>


        );
    }
}

class FormButtons extends Component {

    render() {
        const { classes } = this.props;

        return (
            <GridEx item sm={this.props.sm || 6} xs={this.props.xs || 12}>
                <div className={classes.formButtons} >
                    {!this.props.noOkBtn && <Button
                        color="primary"
                        className={classes.button}
                        type="submit"
                        variant="contained"
                    > {this.props.okText ? this.props.okText : getTxt('Salva')}</Button>}
                    {!this.props.noCancelBtn && <Button
                        className={classes.button}
                        variant="contained"
                        onClick={() => {
                            if (this.props.onCancel) {
                                this.props.onCancel();
                            }
                        }}
                    >{this.props.cancelText ? this.props.cancelText : getTxt('Annulla')}</Button>}
                    {this.props.adtButtons && this.props.adtButtons}
                </div>

            </GridEx>


        );
    }
}

class P_Button_ extends Component {




    render() {
        const { classes } = this.props;

        return (

            <GridEx item sm={this.props.sm || 6} xs={this.props.xs || 12}>
                <Button {...this.props}
                    variant="contained"
                    disabled={this.props.d}
                >{this.props.l}</Button>

            </GridEx>


        );
    }
}

class P_Brief_ extends Component {
    render() {
        const { classes } = this.props;

        return (
            <GridEx item sm={this.props.sm || 6} xs={this.props.xs || 12}>
                <div className={classes.brief}>
                    <Typography
                        className={classes.briefLabel}
                        variant="button"
                    >
                        {this.props.label}
                    </Typography>
                    <div className={classes.briefValue} >{this.props.l}</div>
                </div>
            </GridEx>
        );
    }
}

class Group extends Component {

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.fields = { all: [], byId: {} };
        if (props.fields && props.fields.length && props.fields.length > 0) {
            this.fields.all = props.fields;
            props.fields.forEach((i) => {
                if (i.f) {
                    this.fields.byId[i.f] = i;
                }
            });
        }
    }

    onChange(value) {
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    render() {
        const { classes } = this.props;

        let visible = true;
        if (this.fields &&
            this.fields.all.length > 0 &&
            this.props.child &&
            this.props.child.length > 0) {

            visible = false;
            this.props.child.forEach((i) => {
                if (this.fields.byId[i]) {
                    visible = true;
                }
            }, this);
        }

        if (visible && this.props.pdf) {
            window.requestDetailsPdfData.push({
                caption: this.props.l,
                fields: [],
            });
        }

        if (visible) {
            return (
                <GridEx item sm={this.props.sm || 12} xs={this.props.xs || 12} spacing={this.props.spacing || 0} className={classes.groupRoot}>
                    <div className={classes.formGroup} >
                        <Typography
                            className={classes.formGroupLabel}
                            variant="inherit"

                        >
                            {this.props.l}
                        </Typography>
                    </div>
                </GridEx>
            );
        }
        return '';
    }
}



class Form extends Component {

    constructor(props) {
        super(props);
        this.formId = randomString(5, 'aA');
        this.fieldsControls = {};
        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.connectChildControl = this.connectChildControl.bind(this);
        this.showErrors = this.showErrors.bind(this);
        this.hideErrors = this.hideErrors.bind(this);
        this.typeDescriptor = getEntityDescriptor(this.props.objType);
    }

    getChildContext() {
        return { dataSource: this.props.dataSource, connectChildControl: this.connectChildControl };
    }

    onSubmit(e) {
        e.preventDefault();
        let isValid = true;
        const fields = Object.keys(this.fieldsControls);
        let allErrors = [];
        for (let i = 0; i < fields.length; i += 1) {
            const f = fields[i];
            const er = this.fieldsControls[f].validate();
            if (er) {
                isValid = false;
                er.forEach(e => e.field = f);
                allErrors = allErrors.concat(er);
            }
        }

        if (allErrors.length > 0) {
            this.showErrors(allErrors);
        }

        if (this.props.onSubmit && isValid) {
            const values = $(`#${this.formId}`).serializeArray();
            const obj = {};
            values.forEach((v) => {
                obj[v.name] = v.value;
            });
            this.props.onSubmit(e, { values, obj, showErrors: this.showErrors, hideErrors: this.hideErrors });
        }
    }

    showErrors(erros) {
        erros.forEach((err, index) => {
            if (index === 0) {
                this.fieldsControls[err.field].focus();
            }
            this.fieldsControls[err.field].showError(err.message);
        }, this);
    }

    hideErrors() {
        const fields = Object.keys(this.fieldsControls);
        for (let i = 0; i < fields.length; i += 1) {
            const f = fields[i];
            this.fieldsControls[f].showError(undefined);
            // if (er) {
            //     isValid = false;
            //     er.forEach(e => e.field = f);
            //     allErrors = allErrors.concat(er);
            // }
        }
    }

    connectChildControl(field, fieldControl) {
        this.fieldsControls[field] = fieldControl;
        fieldControl.setDescriptor(this.typeDescriptor[field]);
        fieldControl.setFormOnChange(this.onChange);
    }

    onChange(field, value) {
        if (this.props.onChange) this.props.onChange(field, value);
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.formRoot}>
                {this.props.l && (<FormCaption >  {this.props.l}  </FormCaption>)}
                <form onSubmit={this.onSubmit} id={this.formId}>
                    <Grid container spacing={24}>
                        {this.props.children}
                    </Grid>
                </form>
            </div>
        );
    }

}

Form.childContextTypes = {
    dataSource: PropTypes.object,
    connectChildControl: PropTypes.func,
};

export class DictionaryGridFilter extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    state = {
        value: undefined,
        items: undefined,
    };

    componentWillMount() {

        let items = this.props.children;
        if (items && this.props.noFilterVal) {
            items = [{ label: this.props.noFilterVal, value: this.props.noFilterVal }, ...items];
        }
        this.setState({ items });

        let v;
        if (items && items.find) {
            v = items.find(i => i.value === this.props.v);
            if (!v) {
                v = items.find(i => i.value === this.props.noFilterVal);
            }
            if (!v) {
                v = ' ';
            }
        }
        this.setState({ value: v.value });
    }


    onChange(e) {

        this.setState({ value: e.target.value });
        this.props.setFilter((e.target.value && (e.target.value !== this.props.noFilterVal)) ? { value: e.target.value } : null);
        if (this.props.onChange) {
            this.props.onChange(e.target.value);
        }
    }

    render() {
        const { classes } = this.props;

        let selected = this.state.value;
        if (this.props.f && this.props.filters) {
            const myFilter = this.props.filters.find(f => f.columnName === this.props.f);
            if (myFilter) {
                selected = myFilter.value;
            }
        }

        return (
            <TableFilterRow.Cell className={classes.cell}>
                <Select

                    select
                    className={classes.gridFilterInput}
                    SelectProps={{
                        MenuProps: {
                            className: classes.menu,
                        },
                        placeholder: getTxt('Filter...'),

                    }}
                    margin="normal"
                    {...this.props}
                    InputProps={{ name: this.props.f }}
                    value={selected}
                    placeholder={getTxt('Filter...')}
                    onChange={this.onChange}
                >
                    {this.state.items && this.state.items.map(mi => (
                        <MenuItem key={mi.value} value={mi.value}>
                            {mi.label}
                        </MenuItem>
                    ))}
                </Select>



            </TableFilterRow.Cell>
        );
    }
}



class CitySelect extends P_Select_ {

    render() {
        const { classes } = this.props;

        const loading = this.props.data.loading;
        const cities = this.props.data.citiesByProvince ? this.props.data.citiesByProvince : [];
        let selected = this.state.value !== '' ? this.state.value : this.context.dataSource[this.props.f];

        if (!loading) {
            const inList = cities.find(c => c.value === selected);
            if (inList) {
                selected = inList.value;
            } else {
                selected = '';
            }
        }



        return (

            <GridEx item sm={this.props.sm || 6} xs={this.props.xs || 12}>
                <div style={{ position: 'relative' }}>
                    {loading && <CircularProgress className={classes.selectProgress} size={30} />}
                    <TextField
                        select
                        label={this.props.l}
                        className={classes.textField}
                        SelectProps={{
                            MenuProps: {
                                className: classes.menu,
                            },
                        }}
                        margin="normal"
                        {...this.props}
                        InputProps={{ id: this.id, name: this.props.f }}
                        value={selected}
                        disabled={this.props.d}
                        required={this.props.r}
                        onChange={this.onChange}
                        error={this.state.errorMessage ? true : false}
                        helperText={this.state.errorMessage}
                    >
                        {cities.map(mi => (
                            <MenuItem key={mi.value} value={mi.value}>
                                {mi.label}
                            </MenuItem>
                        ))}
                    </TextField>

                </div>

            </GridEx >


        );
    }
}

const queryCitiesByProvince = gql`

query citiesByProvince($provinceCd: String!) {
    citiesByProvince(provinceCd: $provinceCd) {
        value
        label
    }
}

`;


const c = {};
c.group = withStyles(styles, { withTheme: true })(Group);
c.text = withStyles(styles, { withTheme: true })(P_Input_);
c.select = withStyles(styles, { withTheme: true })(P_Select_);
c.check = withStyles(styles, { withTheme: true })(P_CheckBox_);
c.btn = withStyles(styles, { withTheme: true })(P_Button_);
c.brief = withStyles(styles, { withTheme: true })(P_Brief_);
c.form = withStyles(styles, { withTheme: true })(Form);
c.linkToBroker = withStyles(styles, { withTheme: true })(LinkToBroker);
c.formButtons = withStyles(styles, { withTheme: true })(FormButtons);
c.field = FormFieldHigh;

c.gridColFilter = withStyles(styles, { withTheme: true })(DictionaryGridFilter);

c.readOnlyInLine = ReadOnlyInLine;
c.readOnlyNewLine = ReadOnlyNewLine;
c.readOnlyAdjustable = ReadOnlyAdjustable;
c.readOnlyLinkToCustomer = ReadOnlyLinkToCustomer;
c.readOnlyMultilinkToCustomer = ReadOnlyMultilinkToCustomer;

c.citiesSelect = graphql(queryCitiesByProvince,
    {
        options: props => (
            {
                variables: { provinceCd: props.provinceId },
                errorPolicy: 'all',
            }),
    })(withStyles(styles, true)(CitySelect));


export default c;
