import ComponentEx from 'components/ComponentEx';
import { getTxt } from 'global/appGlobal';
import { Dialog, DialogContent } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { EntityTypes, getEntityDescriptor } from 'global/entityDescriptors';
import { compose, graphql } from 'react-apollo';
import LinearProgressEx from '../../components/LinearProgressEx';
import c from '../../components/form/Components';
import MaterialForm from '../../components/form/MaterialForm';
import { DictionaryTypes, getDictionary, getLabel } from '../../global/dictionaryCache';
import { metadataQuery } from '../../home/Home';

import * as queries from '../../modules/contract/contractQueries';

let history;
let AfterSubmit;
const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing.unit * 2,
        margin: theme.spacing.unit * 2,
    },
});


class Contract_Item_Type_Edit extends ComponentEx {

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
      
    }

    state = {
        open: false,
    };

    formSetValues = { func: undefined };

    

    onSubmit(e, { obj, showErrors, hideErrors }) {
        const original = this.props.data.contract_item_type;
        obj.contract_item_type_id = original.contract_item_type_id;

        this.props.updateType({
            variables: { contractItemType: obj },
            refetchQueries: [
                {
                    query: queries.allContractItemTypesQuery,
                },
            ],
        }, this).then(
            (resp) => {
                hideErrors();
                if (resp.data.updateContractItemTypeMutation &&
                    resp.data.updateContractItemTypeMutation.validation &&
                    resp.data.updateContractItemTypeMutation.validation.length > 0) {
                    showErrors(resp.data.updateContractItemTypeMutation.validation);
                } else {
                    if (!AfterSubmit) {
                        history.goBack();
                    } else {
                        AfterSubmit(resp.data.updateContractItemTypeMutation.id);
                    }
                }
            });
    }

    render() {

        const { classes } = this.props;

        const loading = this.props.data.loading;
        const item = this.props.data.contract_item_type;
        history = this.props.history;
        AfterSubmit = this.props.AfterSubmit;
        

        const caption = getTxt('New Movement Type');

        return (


            <Dialog onClose={() => { this.props.AfterSubmit(); }} open >
                <DialogContent>
                    <LinearProgressEx loading={loading} />


                    <Typography variant="h4" color="inherit" noWrap={false}>{caption}</Typography>


                    {item &&

                        <MaterialForm
                            dataSource={item}
                            objType={EntityTypes.ContractItemType}
                            onSubmit={this.onSubmit}  
                        >
                            <c.field f="value" />
                            <c.field f="label" />
                            <c.field f="is_financial" />
                            <c.field f="is_negative" />
                            <c.formButtons sm={12} onCancel={() => { this.props.AfterSubmit(); }} />
                        </MaterialForm>

                    }

                </DialogContent>

            </Dialog>

        );
    }
}

export default compose(

    graphql(queries.сontractItemTypeByIdQuery, {
        options: props => (
            {
                variables: {
                    id: 'new',
                },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }
        ),
    }),

    graphql(queries.updateContractItemTypeMutation, {
        name: 'updateType',
        options: {
            errorPolicy: 'all',
           
        },
    }),

)(withStyles(styles)(Contract_Item_Type_Edit));
