
import React, { Component } from 'react';
import { getTxt, randomString } from 'global/appGlobal';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
//import FormField from 'components/form/FormField';
import GridEx from 'components/form/GridEx';
import { MenuItem } from '@material-ui/core';
import { Button } from '@material-ui/core';
import {
    FormLabel,
    FormControl,
    FormGroup,
    FormControlLabel,
    FormHelperText,
} from '@material-ui/core';
import { Checkbox } from '@material-ui/core';
import { Input, InputLabel } from '@material-ui/core';
import FormCaption from 'components/form/FormCaption';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import $ from 'jquery';

import { EntityTypes, getEntityDescriptor } from 'global/entityDescriptors';
import schema from 'async-validator';

import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

import NumberFormat from 'react-number-format';
import { Select } from '@material-ui/core';
import { HashRouter, BrowserRouter, Route, Link, withRouter } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';

import MFormControl from '../../components/form/MFormControl';

const styles = theme => ({
    link: {
        marginLeft: theme.spacing.unit,
        cursor: 'pointer',
    },
    disabledLink: {
        color: 'currentColor',
        cursor: 'default',
        opacity: 0.5,
        textDecoration: 'none',
    },
});

class MFormLinkToAtrcData extends MFormControl {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.linkClick = this.linkClick.bind(this);

        //this.onKeyDown = this.onKeyDown.bind(this);
    }

    linkClick() {
        const newWindow = window.open("", "_blank");
        newWindow.document.write(getTxt('Loading external data, please wait...'));
        this.props.getOneTimeToken({
            variables: { atrc: this.state.value },
        }, this).then(
            (resp) => {

                if (resp.data && resp.data.atrcOneTimeTokenMutation && resp.data.atrcOneTimeTokenMutation.token) {

                    newWindow.location.href = `${window.apiUrl}atrc?ott=${resp.data.atrcOneTimeTokenMutation.token}`;
                    newWindow.focus();
                }

            });
    }

    onChange(e) {
        this.setState({ value: e.target.value });
        this.setState({ isDirty: true });
        this.formOnChange(this.props.f, e.target.value, this.props.type);
    }

    componentDidMount() {
        if (this.props.max) {
            $('#' + this.id).attr('max', this.props.max);
        }
        if (this.props.maxlength) {
            $('#' + this.id).attr('maxlength', this.props.maxlength);
        }

        if (this.props.propDescriptor && this.props.propDescriptor.type === 'DECIMAL') {
            // $('#' + this.id).on('focus', function (e) {
            //     $(this).select();
            // });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.forseRefresh) {
            this.setState({ value: nextProps.v });
        }
    }

    render() {
        const { classes } = this.props;

        if (this.props.pdf) {
            const latestGroup = window.requestDetailsPdfData[window.requestDetailsPdfData.length - 1];
            latestGroup.fields.push({ label: this.getLabel(), value: this.props.href || '' });
        }

        return (
            <GridEx item sm={this.props.sm || 6} xs={this.props.xs || 12}>
                {this.state.value &&
                    <a href={this.props.href} className={this.props.viewMode ? '' : classes.link} style={{ cursor: 'pointer' }} onClick={this.linkClick} >{this.getLabel()}</a>
                }
            </GridEx >
        );
    }
}

MFormLinkToAtrcData.contextTypes = {
    onChange: PropTypes.func,
    registerControl: PropTypes.func,
    objType: PropTypes.string,
    dataSource: PropTypes.object,
    unRegisterControl: PropTypes.func,
    fields: PropTypes.Object,
};





export const atrcOneTimeTokenMutation = gql`

mutation atrcOneTimeTokenMutation($atrc: String) {
    atrcOneTimeTokenMutation(atrc: $atrc) {
        token
    }
  }

  `;



export default compose(

    graphql(atrcOneTimeTokenMutation, {
        name: 'getOneTimeToken',
        options: {
            errorPolicy: 'all',
            fetchPolicy: 'no-cache',
        },
    }),
)(withStyles(styles, { withTheme: true })(MFormLinkToAtrcData));

