
import formatCurrency from 'format-currency';
import moment from 'moment';
import $ from 'jquery';

export function getUrlParameter(sParam) {
    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : sParameterName[1];
        }
    }
}

export function getTxt(text) {

    const translationCheckStr = getConfigValue('translations_check_str');
    const checkStr = translationCheckStr ? translationCheckStr : '';

    let translated = text;
    if (window.translations && window.translations[text]) {
        translated = window.translations[text];
    }

    if (getUrlParameter('getTxt')) {
        return translated + checkStr;
    }
    return translated + checkStr;
}

export function getClientHeight() {
    if (navigator.userAgent.search("Opera") >= 0) {
        return window.innerHeight;
    }

    var v = 0, d = document, w = window;
    if ((!d.compatMode || d.compatMode == 'CSS1Compat') && !w.opera && d.documentElement && d.documentElement.clientHeight) { v = d.documentElement.clientHeight; }
    else if (d.body && d.body.clientHeight) { v = d.body.clientHeight; }
    else if (xDef(w.innerWidth, w.innerHeight, d.height)) {
        v = w.innerHeight;
        if (d.height > w.innerHeight) v -= 16;
    }
    return v;
};

export function getClientWidth() {
    if (document.offsetWidth)
        return document.offsetWidth;

    if (navigator.userAgent.search("Opera") >= 0) {
        return window.innerWidth;
    }

    var v = 0, d = document, w = window;
    if ((!d.compatMode || d.compatMode == 'CSS1Compat') && !w.opera && d.documentElement && d.documentElement.clientWidth) { v = d.documentElement.clientWidth; }
    else if (d.body && d.body.clientWidth) { v = d.body.clientWidth; }
    else if (xDef(w.innerWidth, w.innerHeight, d.height)) {
        v = w.innerWidth;
        if (d.width > w.innerWidth) v -= 16;
    }
    return v;
}

export function randomString(length, chars) {
    let mask = '';
    if (chars.indexOf('a') > -1) mask += 'abcdefghijklmnopqrstuvwxyzabcdefghijklmnopqrstuvwxyz';
    if (chars.indexOf('A') > -1) mask += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    if (chars.indexOf('#') > -1) mask += '0123456789012345678901234567890123456789';
    if (chars.indexOf('!') > -1) mask += '~`!@#$%^&*()_+-={}[]:";\'<>?,./|\\';
    let result = '';
    for (let i = length; i > 0; --i) result += mask[Math.floor(Math.random() * mask.length)];
    return result;
}

function ResizeService() {
    let rtime = new Date(1, 1, 2000, 12, 0, 0);
    let timeout = false;
    const delta = 300;
    const resizables = {};

    function resizeEnd() {
        timeout = false;
        //  const clW = GetClientWidth();
        //  const clH = GetClientHeight();

        Object.keys(resizables).forEach(key => resizables[key]());
    }

    function onClientResized(e) {

       if ($('input').is(':focus')) {
           // Nothing will happen
           // bug fix: when in mobile browsers virtual keyboard on grid filters appears and immediately hides. 
       } else {
            rtime = new Date();
            if (timeout === false) {
                timeout = true;
                setTimeout(resizeEnd, delta);
            }
       }


    }

    if (typeof window.addEventListener !== 'undefined') window.addEventListener('resize', onClientResized);
    else if (typeof window.attachEvent !== 'undefined') window.attachEvent('onresize', onClientResized);

    this.addResizable = (name, onresizeListener) => {
        resizables[name] = onresizeListener;
    };

    this.removeResizable = (name) => {
        delete resizables[name];
    };

    this.resizeRequest = (cb) => {
        resizeEnd();
        if (cb) cb();
    };
}

export const resizeService = new ResizeService();

export function formatCurrencyValue(val) {
    if (!val) return;
    return formatCurrency(val);
}

const config = {};

export function addServerConfig(serverConfig) {
    Object.keys(serverConfig).forEach((key) => { config[key] = serverConfig[key]; });
}

export function getConfigValue(key) {
    return config[key];
}

export function formatBytes(bytes, decimals) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function toItalianDateString(date, showTime) {

    if (date) {
        let d = new Date(date);
        d = new Date(d);
        let curr_date = d.getDate();
        if (curr_date < 10) {
            curr_date = '0' + curr_date;
        }
        let curr_month = d.getMonth() + 1;
        if (curr_month < 10) {
            curr_month = '0' + curr_month;
        }
        const curr_year = d.getFullYear();

        if (!showTime) {
            return curr_date + '/' + curr_month + '/' + curr_year;
        }

        let hours = d.getHours();
        if (hours < 10) {
            hours = '0' + hours;
        }

        let minutes = d.getMinutes();
        if (minutes < 10) {
            minutes = '0' + minutes;
        }

        d = moment.utc(date);
        if (!d) return date;

        hours = d.hours();
        if (hours < 10) {
            hours = '0' + hours;
        }

        minutes = d.minutes();
        if (minutes < 10) {
            minutes = '0' + minutes;
        }

        return (d.format('DD/MM/YYYY') + ' ' + hours + ':' + minutes);

    }
    return date;
}

export function toItalianDateString2(date, showTime) {

    if (date) {
        let d = new Date(date);
        d = new Date(d);
        let curr_date = d.getDate();
        if (curr_date < 10) {
            curr_date = '0' + curr_date;
        }
        let curr_month = d.getMonth() + 1;
        if (curr_month < 10) {
            curr_month = '0' + curr_month;
        }
        const curr_year = d.getFullYear();

        // if (!showTime) {
        //     return curr_date + '/' + curr_month + '/' + curr_year;
        // }

        let hours = d.getHours();
        if (hours < 10) {
            hours = '0' + hours;
        }

        let minutes = d.getMinutes();
        if (minutes < 10) {
            minutes = '0' + minutes;
        }

        d = moment.utc(date);
        if (!d) return date;

        hours = d.hours();
        if (hours < 10) {
            hours = '0' + hours;
        }

        minutes = d.minutes();
        if (minutes < 10) {
            minutes = '0' + minutes;
        }

        if (showTime) {
            return (d.format('DD/MM/YYYY') + ' ' + hours + ':' + minutes);
        }
        return d.format('DD/MM/YYYY');
    }
    return date;
}

export function toDecimalString(n, toFixed) {
    if (n || n === 0) {
        const parsed = Number.parseFloat(n);
        if (isNaN(parsed)) {
            return n;
        }
        return parsed.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }).replace('€', '').trim();
    }
    return n;
}



export const tableMessages = {
    noData: getTxt('No Data'),
};

export const filterRowMessages = {
    filterPlaceholder: getTxt('Filter...'),
};

export function createGridResizerCols(state, columns, initialWidths, defaultWidth) {
    if (state.defColumnWidths) {
        return;
    }

    const colsResizerDefsDefault = [];
    columns.forEach((c) => {


        colsResizerDefsDefault.push({
            columnName: c.name,
            width: (initialWidths && initialWidths[c.name]) ? initialWidths[c.name] : (defaultWidth ? defaultWidth : 180),
        });
    });

    state.defColumnWidths = colsResizerDefsDefault;
}

export function createGridResizerColsHooksGrid(columns, initialWidths, defaultWidth) {

    const colsResizerDefsDefault = [];
    columns.forEach((c) => {


        colsResizerDefsDefault.push({
            columnName: c.name,
            width: (initialWidths && initialWidths[c.name]) ? initialWidths[c.name] : (defaultWidth ? defaultWidth : 180),
        });
    });

    return colsResizerDefsDefault;
}