import React from 'react';
import { getTxt } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';





import ComponentEx from 'components/ComponentEx';


import { graphql, compose } from 'react-apollo';

import c from '../../components/form/Components';
import LinearProgressEx from '../../components/LinearProgressEx';
import { DictionaryTypes, getDictionary } from '../../global/dictionaryCache';
import MaterialForm from '../../components/form/MaterialForm';
import * as queries from '../../modules/guaranty/guarantyTypeQueries';

let history;

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing.unit * 2,
        margin: theme.spacing.unit * 2,
    },
});


class GuarantyTypeEdit extends ComponentEx {

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.formChanged = this.formChanged.bind(this);
    }

    state = {
        open: false,
    };

    formSetValues = { func: undefined };

    formChanged(field, value) {
        if (field === 'is_financial') {
            this.setState({ isFinancial: value });
            if (!value) {
                this.formSetValues.func({
                    default_franchise_amount: 0,
                    default_overdraft_amount: 0,
                    default_maximum_amount: 0,
                });
            }
        }

    }

    onSubmit(e, { obj, showErrors, hideErrors }) {
        const original = this.props.data.guarantyTypeById;
        obj.guaranty_type_id = original.guaranty_type_id;
        if (!obj.is_financial) {
            obj.default_franchise_amount = 0;
            obj.default_overdraft_amount = 0;
            obj.default_maximum_amount = 0;
        }

        this.props.updateGuarantyType({
            variables: { guarantyType: obj },
            refetchQueries: [
                {
                    query: queries.allGuarantyTypesQuery,
                },
                {
                    query: queries.guarantyTypeByIdQuery,
                    variables: { id: original.guaranty_type_id },
                },
            ],
        }, this).then(
            (resp) => {
                hideErrors();
                if (resp.data.updateGuarantyTypeMutation &&
                    resp.data.updateGuarantyTypeMutation.validation &&
                    resp.data.updateGuarantyTypeMutation.validation.length > 0) {
                    showErrors(resp.data.updateGuarantyTypeMutation.validation);
                } else {
                    history.goBack();
                }
            });
    }


    render() {

        const { classes } = this.props;

        const loading = this.props.data.loading;
        const item = this.props.data.guarantyTypeById;
        history = this.props.history;
        let caption;

        let hasIdParam;
        let isNew = false;
        if (this.props.id) {
            hasIdParam = true;
        }
        if (this.props.match.params && this.props.match.params.id) {
            hasIdParam = true;
        }
        if (this.props.match.params && this.props.match.params.id && this.props.match.params.id === 'new') {
            isNew = true;
        }

        if (hasIdParam) {
            caption = getTxt('Modifica Guaranty Type');
        }
        if (isNew) {
            caption = getTxt('Nuovo Guaranty Type');
        }

        let isFinancial;
        if (item) {
            isFinancial = this.state.isFinancial !== undefined ? this.state.isFinancial : item.is_financial;
        }
        if (isFinancial === 'true') {
            isFinancial = true;
        } else if (isFinancial === 'false') {
            isFinancial = false;
        }

        return (


            <div className={classes.root}>

                <Typography variant="h4" color="inherit" noWrap={false}>{caption}</Typography>
                <LinearProgressEx loading={loading} />

                {item &&
                    <div>
                        <MaterialForm
                            dataSource={item}
                            objType="guaranty_type"
                            onSubmit={this.onSubmit}
                            onChange={this.formChanged}
                            setValues={this.formSetValues}
                        >

                            {this.props.match.params.id === 'new' && !this.props.match.params.insType &&
                                <c.field l={getTxt('Insurance Type')} f="insurance_type_cd" ui="select" >
                                    {getDictionary(DictionaryTypes.InsuranceType)}
                                </c.field>
                            }

                            {this.props.match.params.id === 'new' && this.props.match.params.insType &&
                                <c.field l={getTxt('Insurance Type')} f="insurance_type_cd" ui="select" v={this.props.match.params.insType} d >
                                    {getDictionary(DictionaryTypes.InsuranceType)}
                                </c.field>
                            }

                            {this.props.match.params.id !== 'new' && !this.props.match.params.insType &&
                                <c.field l={getTxt('Insurance Type')} f="insurance_type_cd" ui="select" v={item.insurance_type_cd} d >
                                    {getDictionary(DictionaryTypes.InsuranceType)}
                                </c.field>
                            }

                            {this.props.match.params.id !== 'new' && this.props.match.params.insType &&
                                <c.field l={getTxt('Insurance Type')} f="insurance_type_cd" ui="select" v={this.props.match.params.insType} d >
                                    {getDictionary(DictionaryTypes.InsuranceType)}
                                </c.field>
                            }

                            <c.field f="guaranty_name" l={getTxt('Nome')} />

                            <c.field f="used_by_default" l={getTxt('Used by Default')} />
                            <c.field f="is_financial" l={getTxt('Is Financial')} value={isFinancial} />
                            {isFinancial && <c.field f="default_franchise_amount" l={getTxt('Default franchise amount')} />}
                            {isFinancial && <c.field f="default_maximum_amount" l={getTxt('Default maximum amount')} />}
                            {isFinancial && <c.field f="default_overdraft_amount" l={getTxt('Default overdraft amount')} />}

                            <c.formButtons sm={12} onCancel={() => { this.props.history.goBack(); }} />
                        </MaterialForm>

                    </div>
                }

            </div>




        );
    }
}




export default compose(
    graphql(queries.guarantyTypeByIdQuery, {
        options: props => (
            {
                variables: {
                    id: (!props.id ? (props.match.params.id ? props.match.params.id : 'new') : props.id),
                },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }
        ),
    }),
    graphql(queries.updateGuarantyTypeMutation, {
        name: 'updateGuarantyType',
        options: {
            errorPolicy: 'all',
           
        },
    }),
)(withStyles(styles)(GuarantyTypeEdit));
