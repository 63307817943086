import gql from 'graphql-tag';

export const adminProfileQuery = gql`
query adminProfileQuery {
    adminProfile{
            email
            password_new
            password_new2,
            user_SIC_ANIA,
            password_SIC_ANIA,
            code_SIC_ANIA,
        }
    }
`;

export const updateAdminProfileMutation = gql`

mutation updateAdminProfileMutation($admin: admin_profile_input!) {
    updateAdminProfileMutation(admin: $admin) {
      id
      validation {
        field
        message
      }
    }
  }
`;

