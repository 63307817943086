    import React, { Component } from 'react';
    import PropTypes from 'prop-types';

    export default class FormField extends Component {
        static defaultProps = {
            value: undefined,
            readOnly: false,
            children: [],
            dataSource: {},
        }

        constructor(props) {
            super(props);
            this.onChange = this.onChange.bind(this);
        }

        state = {
            value: undefined,
        };

        componentWillMount() {
            this.setState({ value: this.resolveValue(this.context.dataSource, this.props.field) });
        }

        onChange(e) {
            this.setState({ value: e.target.value });
            if (this.props.onChange) {
                this.props.onChange(e.target.value);
            }
        }

        resolveValue(source, prop) {
            if (!source) return undefined;
            if (!prop) return undefined;

            if (prop.indexOf('.') < 0) return source[prop];

            const splitted = prop.split('.');
            let current = source;
            Object.keys(splitted).forEach((i) => {
                const p = splitted[i].trim();
                current = current[p];
            });
            return current;
        }

        render() {
            return (
                <span>{'FormFieldBase'}</span>
            );
        }
    }

    FormField.contextTypes = {
        dataSource: PropTypes.object,
    };
