import React, { Component } from 'react';
import { getTxt, toDecimalString, toItalianDateString } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';
import { graphql, compose } from 'react-apollo';
import { Typography } from '@material-ui/core';
import gql from 'graphql-tag';
import { p } from 'components/form/Proto';
import $ from 'jquery';
import { HashRouter, BrowserRouter, Route, Link, withRouter } from 'react-router-dom';
import { Button } from '@material-ui/core';
import * as Icon from '@material-ui/icons';
import { authInfo, getToken } from 'global/auth';
import { ReportTypes } from 'global/reporting';
import ChartCard from 'components/dashboard/ChartCard';
import ChartistGraph from 'react-chartist';
var Chartist = require("chartist");
import {
    SortingState, SelectionState, FilteringState, GroupingState,
    IntegratedFiltering, IntegratedGrouping, IntegratedSorting, IntegratedSelection,
} from '@devexpress/dx-react-grid';
import classNames from 'classnames';
import {
    Grid,
    Table,
    VirtualTable, TableHeaderRow, TableFilterRow, TableSelection, TableGroupRow,
    GroupingPanel, DragDropProvider, TableColumnReordering, Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import LinearProgressEx from '../../components/LinearProgressEx';
import * as queries from '../../modules/contract/contractQueries';
import { getLabel, DictionaryTypes, getDictionary } from '../../global/dictionaryCache';
import RegularCard from '../../components/dashboard/RegularCard';
import MaterialForm from '../../components/form/MaterialForm';
import GridEx from 'components/form/GridEx';
import c from '../../components/form/Components';
import { } from '../../global/dictionaryCache';
import { addDescriptor } from '../../global/entityDescriptors';
import ComponentEx from '../../components/ComponentEx';
import { getReportDataQuery } from './reportQueries';
import { companiesAsDictionaryQuery } from '../company/CompanyQueries';
import { getYearandMonthLabel } from './ReportsRoot';


const styles = theme => ({
    root: {
        paddingLeft: 50,
    },
    table: {
        borderCollapse: 'collapse',
    }
});


class Monthly_Inbound_Report_print extends ComponentEx {

    state = {
        companies: undefined,
    }

    async getCompanies() {
        const { data } = await window.apolloClient.query({
            query: companiesAsDictionaryQuery,
            options: {
                fetchPolicy: 'network-only',
            },
        });

        this.setState({ companies: data.companyDictionary });
    }

    componentWillMount() {
        if (this.props.match.params.companyId !== 'false') {
            this.getCompanies();
        }
    }

    componentDidMount() {
        $('header').hide();
        $('header').next().hide();
    }

    render() {

        const { classes } = this.props;
        const items = this.props.data.reportData ? JSON.parse(this.props.data.reportData) : [];

        return (

            <div className={classes.root}>
                <LinearProgressEx loading={this.props.data.loading} />
                <div style={{ marginBottom: 30 }}>
                    <Typography variant="h4" noWrap>{getTxt('Monthly Cash Flow')}</Typography>
                </div>

                <table style={{ borderCollapse: 'collapse' }} id="printableTable">
                    <tr>
                        <th>{getTxt('Month')}</th>
                        <th>{getTxt('Client total')}</th>
                        <th>{getTxt('Broker total')}</th>
                        <th>{getTxt('Agency total')}</th>
                        <th>{getTxt('Broker Amount')}</th>
                        <th>{getTxt('Compny Amount')}</th>
                        <th>{getTxt('Customer Amount')}</th>
                    </tr>
                    {
                        items.length > 0 && items.map((row) => {
                            return (
                                <tr>
                                    <td>
                                        {getYearandMonthLabel(row.m_year, row.month_label)}
                                    </td>

                                    <td>
                                        {toDecimalString(row.contract_total_amount_client, true)}
                                    </td>
                                    <td>
                                        {toDecimalString(row.contract_total_amount_broker, true)}
                                    </td>
                                    <td>
                                        {toDecimalString(row.contract_total_amount_agency, true)}
                                    </td>

                                    <td>
                                        {toDecimalString(row.broker_payment_amount, true)}
                                    </td>
                                    <td>
                                        {toDecimalString(row.company_payment_amount, true)}
                                    </td>
                                    <td>
                                        {toDecimalString(row.customer_payment_amount, true)}
                                    </td>
                                </tr>
                            );
                        })
                    }
                    {
                        items.length === 0 &&
                        <tr>
                            <td colSpan="2">
                                {getTxt('No Data')}
                            </td>
                        </tr>
                    }
                </table>
                {}


            </div>
        );
    }
}


export default compose(
    graphql(getReportDataQuery, {
        options: props => (
            {
                variables: {
                    reportType: 'monthly-inbound-cash-flow',
                    params: JSON.stringify({
                        from: props.match.params.from !== 'false' ? props.match.params.from : '',
                        to: props.match.params.to !== 'false' ? props.match.params.to : '',
                        companyId: props.match.params.companyId !== 'false' ? props.match.params.companyId : '',
                    }),
                },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }
        ),
    }),

)(withRouter(withStyles(styles)(Monthly_Inbound_Report_print)));

