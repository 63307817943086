import React, { Component } from 'react';
import { getTxt, toItalianDateString } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';
import { graphql, compose } from 'react-apollo';
import { p } from 'components/form/Proto';
import $ from 'jquery';
import { Checkbox, Typography, makeStyles } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import c from '../../components/form/Components';
import { Button } from '@material-ui/core';
import GridEx from 'components/form/GridEx';
import DeleteIcon from '@material-ui/icons/Delete';
import { HashRouter, BrowserRouter, Route, Link, withRouter, useHistory, useParams } from 'react-router-dom';
import { TextField } from '@material-ui/core';
import { TableCell } from '@material-ui/core';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import * as Icon from '@material-ui/icons';
import {
    SortingState, SelectionState, FilteringState, GroupingState,
    IntegratedFiltering, IntegratedGrouping, IntegratedSorting, IntegratedSelection,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    VirtualTable, TableHeaderRow, TableFilterRow, TableSelection, TableGroupRow,
    GroupingPanel, DragDropProvider, TableColumnReordering, Toolbar, TableColumnResizing,
} from '@devexpress/dx-react-grid-material-ui';
import LinearProgressEx from '../../components/LinearProgressEx';
import * as queries from '../../modules/contract/contractQueries';
import { getLabel, DictionaryTypes, getDictionary } from '../../global/dictionaryCache';
import RegularCard from '../../components/dashboard/RegularCard';
import ComponentEx from '../../components/ComponentEx';
import { filterRowMessages, tableMessages, createGridResizerCols, randomString, createGridResizerColsHooksGrid } from '../../global/appGlobal';
import { Tooltip } from '@material-ui/core';
import { authInfo } from 'global/auth';
import { MIN_TAKE, FILTERS_TIMEOUT, LOAD_MORE_ROW_VALUE, canLoadMore, getNextTake, MAX_TAKE, filtersPromptVisible } from '../client/Client_Search_BigData';
import { useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useEffect } from 'react';
import gql from 'graphql-tag';
import { ReportTypes } from '../../global/reporting';
import { oneTimeTokenMutation } from '../broker/Broker_Balance_Search';
import Items_Select_Dialog from './Items_Select_Dialog';

const LOAD_MORE_PROP = 'contract_number';
let filtersL = [];
let takeL = MIN_TAKE;
let filterTimeoutId;
function isLoadMoreRow(row) {
    return row[LOAD_MORE_PROP] === LOAD_MORE_ROW_VALUE;
}

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        margin: theme.spacing.unit * 3,
    },
    button: {
        margin: theme.spacing.unit,
    },

    badge: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        marginTop: 0,

    },
    gridRoot: {
        height: '100%',
    },

    tableHeader: {
        backgroundColor: theme.palette.background.paper,
    },

    _inGridButton: {
        width: 26,
        height: 20,
        marginRight: theme.spacing.unit * 2,
    },
}));

export default function Client_Search_BigData({ customerId, brokerId, linkedTo, markIfLinketTo, isCustomerView, noDeleteBtn, insType, noCaption }) {


    const [delId, setDelId] = useState();
    const [delNumber, setDelNumber] = useState();
    const [myId] = useState(randomString(5, 'aA'));
    const filterRowId = 'filterRow_' + myId;
    const gridRootId = 'gridRoot_' + myId;
    const history = useHistory();
    const classes = useStyles();
    const { filter } = useParams();
    const [selectItemsDlg, setSelectItemsDlg] = useState(false);
    const [contractIdToInvoice, setContractIdToInvoice] = useState(false);
    const [selectedIdsToInvoice, setSelectedIdsToInvoice] = useState([]);
    const [generateInvoiceFor, setGenerateInvoiceFor] = useState(null);


    const [refetch, { loading, data }] = useLazyQuery(queries.allContractsQuery2, { client: window.apolloClient });
    const [deleteContract, { loading: loadingDelete }] = useMutation(queries.deleteContractMutation, {
        client: window.apolloClient,
        onCompleted: (data) => {
            refetch({ variables: { customerId, brokerId, linkedTo, markIfLinketTo, take: takeL, filters: filtersL, id: randomString(7, 'aA') } });
        },
        refetchQueries: [

        ],

    });

    useEffect(() => {
        refetch({ variables: { take: takeL, filters: filtersL, id: randomString(7, 'aA') } });
    }, []);

    async function handleDelete(contractId) {
        await deleteContract({
            variables: { contractId },
            refetchQueries: [
                {
                    query: queries.allContractsQuery,
                    variables: { contractId },
                },
            ],
        });
        setDelNumber(undefined);
        setDelId(undefined);
    }

    let columns = [
        { name: 'contract_number', title: getTxt('Numero Polizza') },
        { name: 'insurance_type_cd', title: getTxt('Tipo') },
    ];

    if (isCustomerView) {
        columns = columns.concat([
            { name: 'state_cd', title: getTxt('Stato') },
        ]);
    }

    columns = columns.concat([
        { name: 'customer_id', title: getTxt('Cliente') },
        { name: 'insurance_company_id', title: getTxt('Compagnia') },
        { name: 'owner_broker_id', title: getTxt('Collaboratore') },
        { name: 'contract_start_date', title: getTxt('Inizio') },
        { name: 'contract_end_date', title: getTxt('Scadenza') },
        { name: 'contract_delivery_state', title: getTxt('Targa') },
    ]);

    columns.push({ name: 'tacito_rinnovo', title: getTxt('Tacito Rinnovo') });
    columns.push({ name: 'contratto_soggetto_regolazione', title: getTxt('Contratto soggetto a regolazione') });

    if (noDeleteBtn !== true) {
        if (authInfo.isAgencyAdministrator() || authInfo.isMasterBroker() || authInfo.isBroker()) {
            columns.push({ name: 'insurance_contract_id', title: getTxt('Actions') });
        }
    }


    const defColumnWidths = createGridResizerColsHooksGrid(columns, {}, 250);
    const [сolumnWidths, setColumnWidths] = useState(defColumnWidths);



    let items = data && data.contracts2 ? data.contracts2 : [];

    let insTypeCd;
    if (items.length > 0) {
        insTypeCd = items[0].insurance_type_cd;
    }

    let caption = getTxt('Contracts');
    let subtitle = getTxt('List of insurance contracts.');
    if (filter === 'will-expiry-next-30-days') {
        subtitle = getTxt('List of insurance contracts that will expiry in the next 30 days.');
        caption = getTxt('Near to expiry Contracts');

        let today = new Date();
        today = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);

        let expiredDate = new Date(new Date().setDate(today.getDate() + 30));
        expiredDate = new Date(expiredDate.getFullYear(), expiredDate.getMonth(), expiredDate.getDate(), 0, 0, 0);

        items = items.filter((c) => {
            const endDate = new Date(c.contract_end_date);

            return (endDate > today && endDate < expiredDate);
        });
    }

    if (filter === 'expired-prev-30-days-not-paid') {
        subtitle = getTxt('List of insurance contracts that expired in the previous 30 days not yet paid.');
        caption = getTxt('Expired not paid Contracts');

        let today_ = new Date();
        today_ = new Date(today_.getFullYear(), today_.getMonth(), today_.getDate(), 0, 0, 0);

        let todayMinus30Days = new Date(new Date().setDate(today_.getDate() - 30));
        todayMinus30Days = new Date(todayMinus30Days.getFullYear(), todayMinus30Days.getMonth(), todayMinus30Days.getDate(), 0, 0, 0);

        items = items.filter((c) => {
            const endDate = new Date(c.contract_end_date);
            return (endDate > todayMinus30Days && endDate < today_);
        });
    }

    if (items.length > 0 && items.length > takeL && !isLoadMoreRow(items[items.length - 1])) {
        items.push({
            [LOAD_MORE_PROP]: LOAD_MORE_ROW_VALUE,
            customer_id: LOAD_MORE_ROW_VALUE,
        });
    }

    return (

        <div className={classes.root}>
            <LinearProgressEx loading={loading} />
            {!noCaption && <RegularCard

                headerColor="red"
                cardTitle={caption}
                cardSubtitle={subtitle}
                content={
                    <div>

                    </div>

                }
            />}
            <div
                id={gridRootId}
                style={{ position: 'relative' }}
                onChange={((e) => {
                    latestFilterValue = e.target.value;
                })}
            >
                <Grid

                    rows={items}
                    columns={columns}

                >
                    <FilteringState
                        defaultFilters={filtersL}
                        onFiltersChange={async (f, a) => {
                            filtersL = f;

                            if (filterTimeoutId) {
                                clearTimeout(filterTimeoutId);
                            }
                            filterTimeoutId = setTimeout(async () => {
                                filterTimeoutId = undefined;
                                filtersL = f;
                                await refetch({
                                    variables: {
                                        customerId,
                                        brokerId,
                                        linkedTo,
                                        markIfLinketTo,
                                        take: takeL,
                                        filters: filtersL,
                                        id: randomString(7, 'aA'),
                                    },
                                });

                            }, FILTERS_TIMEOUT);
                        }}
                    />

                    <SortingState
                        columnExtensions={[
                            { columnName: 'insurance_contract_id', sortingEnabled: false },
                        ]}
                        defaultSorting={[
                            { columnName: 'Cliente', direction: 'asc' },
                        ]}
                    />

                    <VirtualTable
                        messages={tableMessages}
                        rowComponent={({ ...props, row }) => {
                            if (isLoadMoreRow(row)) {
                                return (<VirtualTable.Row
                                    {...props}
                                    style={{ position: 'relative', height: 60 }}
                                >
                                    {canLoadMore(items.length, takeL, MAX_TAKE) &&
                                        <Button
                                            style={{
                                                width: 200,
                                                position: 'absolute',
                                                marginTop: 10,
                                                left: '50%',
                                                marginLeft: -100,
                                            }}
                                            variant='outlined'
                                            onClick={async () => {
                                                takeL = getNextTake([MIN_TAKE, MAX_TAKE], takeL);
                                                refetch({ variables: { take: takeL, filters: filtersL, id: randomString(7, 'aA') } });

                                            }}
                                        >
                                            {getTxt('Load More...')}

                                        </Button>
                                    }

                                    {filtersPromptVisible(items.length, takeL, MAX_TAKE) &&
                                        <Typography
                                            variant="body1"
                                            style={{
                                                whiteSpace: 'normal',
                                                width: 350,
                                                position: 'absolute',
                                                marginTop: 20,
                                                left: '50%',
                                                marginLeft: -175,

                                            }}
                                        >
                                            {getTxt('Please, specify filters to load more rows.')}
                                        </Typography>
                                    }

                                </VirtualTable.Row>);
                            }
                            return (<VirtualTable.Row {...props} />);
                        }}
                        cellComponent={({ row, column, style, ...props }) => {


                            if (column.name === 'contract_number' && !isLoadMoreRow(row)) {
                                //if (!authInfo.isCustomer()) {
                                return (
                                    <VirtualTable.Cell>
                                        <Link to={`/contract/view/${row.insurance_type_cd}/${row.insurance_contract_id}`} style={{ padding: '6px', color: '#9C27B0', fontWeight: 'bold' }} > {row.contract_number}</Link>
                                    </VirtualTable.Cell>
                                );
                                //} else {
                                //  return (<VirtualTable.Cell>{row.contract_number}</VirtualTable.Cell>);
                                //}
                            }
                            if (column.name === 'customer_id' && !isLoadMoreRow(row)) {
                                if (!authInfo.isCustomer()) {
                                    return (<VirtualTable.Cell>
                                        <Link to={`/customers/${row.customer.customer_type_cd.toLowerCase()}/${row.customer.customer_id}`} >{row.customer.display_name}</Link>
                                    </VirtualTable.Cell>);
                                } else {
                                    return (<VirtualTable.Cell>
                                        {row.customer.display_name}
                                    </VirtualTable.Cell>);
                                }
                            }
                            if (column.name === 'customer_id' && isLoadMoreRow(row)) {
                                return (<VirtualTable.Cell>
                                    {''}
                                </VirtualTable.Cell>);
                            }
                            if (column.name === 'insurance_company_id' && !isLoadMoreRow(row)) {
                                if (!authInfo.isCustomer() && !authInfo.isBroker()) {
                                    return (<VirtualTable.Cell>
                                        <Link to={`/company/view/${row.company.insurance_company_id}`}>{row.company.company_name}</Link>
                                    </VirtualTable.Cell>);
                                } else {
                                    return (<VirtualTable.Cell>
                                        {row.company.company_name}
                                    </VirtualTable.Cell>);
                                }
                            }
                            if (column.name === 'owner_broker_id' && !isLoadMoreRow(row)) {
                                if (!authInfo.isCustomer()) {
                                    return (<VirtualTable.Cell>
                                        <Link to={`/brokers/${row.broker.broker_id}`}>{row.broker.display_name}</Link>
                                    </VirtualTable.Cell>);
                                } else {
                                    return (<VirtualTable.Cell>
                                        {row.broker.display_name}
                                    </VirtualTable.Cell>);
                                }
                            }
                            if (column.name === 'insurance_type_cd' && !isLoadMoreRow(row)) {
                                return (<VirtualTable.Cell> {getLabel(DictionaryTypes.InsuranceType, row.insurance_type_cd)}</VirtualTable.Cell>);
                            }
                            if (column.name === 'ins_request_veicoli' && !isLoadMoreRow(row)) {
                                return (<VirtualTable.Cell> {row.ins_request_veicoli.vehicle_targa_matricola}</VirtualTable.Cell>);
                            }
                            if (column.name === 'contract_start_date' && !isLoadMoreRow(row)) {
                                return (<VirtualTable.Cell> {row.contract_start_date && toItalianDateString(row.contract_start_date)}</VirtualTable.Cell>);
                            }
                            if (column.name === 'contract_end_date' && !isLoadMoreRow(row)) {
                                return (<VirtualTable.Cell> {row.contract_end_date && toItalianDateString(row.contract_end_date)}</VirtualTable.Cell>);
                            }
                            if (isCustomerView && column.name === 'state_cd' && !isLoadMoreRow(row)) {
                                return (<VirtualTable.Cell> {row.state_cd ? row.state_cd : getTxt('Polizza')} </VirtualTable.Cell>);
                            }
                            if (column.name === 'tacito_rinnovo' && !isLoadMoreRow(row)) {
                                return (<VirtualTable.Cell> {row.tacito_rinnovo === 'true' ? getTxt('yes') : getTxt('no')}</VirtualTable.Cell>);
                            }
                            if (column.name === 'contratto_soggetto_regolazione' && !isLoadMoreRow(row)) {
                                return (<VirtualTable.Cell> {row.contratto_soggetto_regolazione === 'true' ? getTxt('yes') : getTxt('no')}</VirtualTable.Cell>);
                            }
                            if (column.name === 'insurance_contract_id' && !isLoadMoreRow(row)) {
                                return (<VirtualTable.Cell>

                                    {(authInfo.isAgencyAdministrator() || authInfo.isMasterBroker()) &&
                                        <Tooltip title={getTxt('Delete')}
                                            style={{ marginBottom: 0, marginTop: 0 }}
                                        >
                                            <IconButton

                                                size="small"
                                                onClick={() => {
                                                    setDelId(row.insurance_contract_id);
                                                    setDelNumber(row.contract_number);
                                                }}
                                                className={classes.inGridButton}
                                            >
                                                <Icon.Delete fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                    <Tooltip title={getTxt('Generazione Fattura')} style={{ marginLeft: 15, marginBottom: 0, marginTop: 0 }}>
                                        <IconButton
                                            size="small"
                                            onClick={async () => {
                                                setContractIdToInvoice(row.insurance_contract_id);
                                                setSelectItemsDlg(true);

                                                // const newWindow = window.open("", "_blank");
                                                // newWindow.document.write(getTxt('Generating PDF Report, please wait...'));

                                                // const resp = await window.apolloClient.mutate({
                                                //     mutation: oneTimeTokenMutation,
                                                //     options: {
                                                //         fetchPolicy: 'network-only',
                                                //     },
                                                // });

                                                // if (resp.data && resp.data.oneTimeTokenMutation && resp.data.oneTimeTokenMutation.token) {
                                                //     newWindow.location.href = `${window.apiUrl}files/reports?ott=${resp.data.oneTimeTokenMutation.token}&type=${ReportTypes.Invoice}&params=${JSON.stringify({ contractId: row.insurance_contract_id })}`;
                                                //     newWindow.focus();
                                                // }

                                            }}
                                            className={classes.inGridButton}
                                        >
                                            <Icon.PictureAsPdf fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </VirtualTable.Cell>);
                            }
                            if (column.name === 'contract_delivery_state' && !isLoadMoreRow(row)) {
                                if (row.ins_request_veicoli) {
                                    return (<VirtualTable.Cell> {row.ins_request_veicoli.vehicle_targa_matricola}</VirtualTable.Cell>);
                                }
                                return (<VirtualTable.Cell>{''}</VirtualTable.Cell>);

                            }
                            if (column.name === LOAD_MORE_PROP && isLoadMoreRow(row)) {
                                return (<VirtualTable.Cell>

                                </VirtualTable.Cell>);
                            }
                            return <VirtualTable.Cell {...props} />;
                        }}
                    />

                    <TableFilterRow
                        messages={filterRowMessages}
                        rowComponent={({ ...props }) => {
                            return (<VirtualTable.Row id={filterRowId} className={filtersL.length > 0 ? 'filterRowActive' : ''} {...props} />);
                        }}
                        cellComponent={({ column, filter, onFilter, ...props }) => {
                            if (column.name === 'insurance_type_cd') {
                                return (
                                    <c.gridColFilter noFilterVal={getTxt('Tutti', '#094c57z')} setFilter={onFilter} f={'insurance_type_cd'} filters={filtersL} >
                                        {getDictionary(DictionaryTypes.InsuranceType)}
                                    </c.gridColFilter>);
                            }

                            if (column.name === 'insurance_contract_id') {
                                return <TableCell onFilter={onFilter} filter={filter} {...props} key={column.name} >{''}</TableCell>;
                            }

                            if (column.name === 'tacito_rinnovo') {
                                return (
                                    <c.gridColFilter noFilterVal={getTxt('Tutti')} f={'tacito_rinnovo'} setFilter={onFilter} filters={filtersL}  >
                                        {
                                            [
                                                { label: getTxt('yes'), value: 'true' },
                                                { label: getTxt('no'), value: 'false' },
                                            ]
                                        }
                                    </c.gridColFilter>);
                            }
                            if (column.name === 'contratto_soggetto_regolazione') {
                                return (
                                    <c.gridColFilter noFilterVal={getTxt('Tutti')} f={'contratto_soggetto_regolazione'} setFilter={onFilter} filters={filtersL}  >
                                        {
                                            [
                                                { label: getTxt('yes'), value: 'true' },
                                                { label: getTxt('no'), value: 'false' },
                                            ]
                                        }
                                    </c.gridColFilter>);
                            }

                            return <TableFilterRow.Cell onFilter={onFilter} filter={filter} {...props} />;
                        }}
                    />
                    <TableColumnResizing
                        columnWidths={сolumnWidths}
                        onColumnWidthsChange={(cols) => { setColumnWidths(cols); }}
                        defaultColumnWidths={defColumnWidths}
                    />
                    <TableHeaderRow />

                </Grid>
                {/* {filtersL.length > 0 &&
                    <div style={{ position: 'absolute', top: 10, left: 10, zIndex: 999999 }}>

                        <Button
                            variant="contained"
                            style={{ backgroundColor: 'bisque' }}
                            className='filterRowActive'
                            onClick={() => {
                             
                                filtersL = [];
                                refetch({
                                    variables: {
                                        customerId,
                                        brokerId,
                                        linkedTo,
                                        markIfLinketTo,
                                        take: takeL,
                                        filters: filtersL,
                                        id: randomString(7, 'aA'),
                                    },
                                });

                            }}
                        >
                            {getTxt('Reset Filters')}
                        </Button>

                    </div>
                } */}
            </div>


            <Dialog onClose={() => { setDelId(undefined); setDelNumber(undefined); }} open={delId}>
                <DialogTitle >{getTxt('Delete Contract') + ' ' + delNumber}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <span>{getTxt('Contract will be delete on server.')}</span>
                        <br />
                        <span >{getTxt('Are you sure do you want delete the Contract?')}</span>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDelete(delId)} color="primary">
                        {getTxt('Ok')}
                    </Button>
                    <Button onClick={() => { setDelId(undefined); setDelNumber(undefined); }} color="primary">
                        {getTxt('Annula')}
                    </Button>

                </DialogActions>
            </Dialog>

            <Dialog onClose={() => { setSelectItemsDlg(false); setContractIdToInvoice(undefined); setSelectedIdsToInvoice([]); }} open={selectItemsDlg}>
                <DialogTitle >{getTxt('Seleziona il movimento per la generazione fattura')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {contractIdToInvoice &&
                            <Items_Select_Dialog
                                contractId={contractIdToInvoice}
                                noAddBtn
                                insType={insType}
                                onSelectedChanged={(selectedIds, forWhat) => {
                                    setSelectedIdsToInvoice(selectedIds);
                                    setGenerateInvoiceFor(forWhat);
                                }}
                            />
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {

                            const itemsIds = selectedIdsToInvoice;
                            const contractId = contractIdToInvoice;
                            itemsIds.forEach((id) => {
                                const newWindow = window.open("", "_blank");
                                newWindow.document.write(getTxt('Generating PDF Report, please wait...'));

                                const request = async () => {
                                    const resp = await window.apolloClient.mutate({
                                        mutation: oneTimeTokenMutation,
                                        options: {
                                            fetchPolicy: 'network-only',
                                        },
                                    });

                                    if (resp.data && resp.data.oneTimeTokenMutation && resp.data.oneTimeTokenMutation.token) {
                                        newWindow.location.href = `${window.apiUrl}files/reports?ott=${resp.data.oneTimeTokenMutation.token}&type=${ReportTypes.Invoice}&params=${JSON.stringify({ contractId, itemId: id, generateInvoiceFor })}`;
                                        newWindow.focus();
                                    }
                                };
                                request();

                            });

                            setSelectItemsDlg(false);
                            setContractIdToInvoice(undefined);
                            setSelectedIdsToInvoice([]);
                        }}
                        color="primary"
                    >
                        {getTxt('Generazione Fattura')}
                    </Button>
                    <Button onClick={() => { setSelectItemsDlg(false); setContractIdToInvoice(undefined); setSelectedIdsToInvoice([]); }} color="primary">
                        {getTxt('Annula')}
                    </Button>

                </DialogActions>
            </Dialog>

        </div>
    );

}
