import gql from 'graphql-tag';

export const allTranslationsListQuery = gql`
query {
    allTranslations {
        translation_id
        original
        alt_key
        translated
    }
}
`;

export const translationByIdQuery = gql`

query translationByIdQuery($id: ID!) {
    translationById(id: $id) {
        translation_id
        original
        alt_key
        translated
    }
   
}

`;


export const updateTranslationMutation = gql`

mutation updateTranslationMutation ($translation: translation_input! ) {
    updateTranslationMutation(translation: $translation){
        id
        validation {
            field
            message
        }
    } 
}

`;

export const deleteTranslationMutation = gql`

mutation deleteTranslationMutation ($id: ID! ) {
    deleteTranslationMutation(id: $id){
        id
        validation {
            field
            message
        }
    } 
}

`;
