import gql from 'graphql-tag';

export const allTypesQuery = gql`
query allTypesQuery($realType: String) {
    all_Public_Ins_TypesQuery (realType: $realType ) {
        public_ins_type_id 
        actual_ins_type_cd
        public_ins_type_cd
        public_ins_type_name
    }
}`;

export const typeByIdQuery = gql`

query typeByIdQuery($id: ID!, $customFields: Boolean) {
   public_Ins_Type_By_Id(id: $id, customFields: $customFields) {
        public_ins_type_id
        actual_ins_type_cd
        public_ins_type_cd
        public_ins_type_name 
        custom_fields
    }
   
}
  

`;

export const updateTypeMutation = gql`

mutation updateTypeMutation($type: public_ins_type_input!) {
    update_public_Ins_Type_Mutation(type: $type) {
      id
      validation {
        field
        message
      }
    }
  }

  `;


export const deleteTypeMutation = gql`

mutation deleteTypeMutation ($id: ID!) {
    delete_public_ins_Type_Mutation(id: $id){
        id
        validation {
            field
            message
        }
    } 
}

`;
