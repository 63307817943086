import React, { Component } from 'react';
import { getTxt, randomString } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import { Paper } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { AppBar } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { TextField } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';

import * as Icon from '@material-ui/icons';
import { Checkbox } from '@material-ui/core';

import GridEx from 'components/form/GridEx';

import { Button } from '@material-ui/core';
import moment from 'moment';
import { Badge } from '@material-ui/core';

import ComponentEx from 'components/ComponentEx';

import $ from 'jquery';
import { p } from 'components/form/Proto';
import {
    FormLabel,
    FormControl,
    FormGroup,
    FormControlLabel,
    FormHelperText,
} from '@material-ui/core';
import { LinearProgress } from '@material-ui/core';
import { graphql, compose } from 'react-apollo';

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';

import c from '../../components/form/Components';
import LinearProgressEx from '../../components/LinearProgressEx';
import { addDictionaries, getLabel, DictionaryTypes, getDictionary } from '../../global/dictionaryCache';
import MaterialForm from '../../components/form/MaterialForm';
import MFormText from '../../components/form/MFormText';
import {
    updateDictionaryMutation,
    allDictionariesQuery,
    dictionaryById,
    dictionaryItemByIdQuery,
    updateDictionaryItemMutation,
    allDictionaryItemsQuery
} from '../../modules/dictionary/dictionariesQueries';

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing.unit * 2,
        margin: theme.spacing.unit * 2,
    },
    checkBox: {
        marginLeft: theme.spacing.unit,
    },
});

let history;

class Dictionary_Item_Edit extends ComponentEx {

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.fornChanged = this.fornChanged.bind(this);
        this.id = randomString(7, 'aA');
    }

    state = {
        open: false,
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleRequestClose = () => {
        this.setState({ open: false });
    };

    fornChanged(field, value) {
    }

    onSubmit(e, { obj, showErrors, hideErrors }) {
        obj.dictionary_cd = this.props.match.params.dictionaryId;
        obj.dictionary_item_id = this.props.data.dictionaryItemById.dictionary_item_id;
        obj.dictionary_cd = this.props.data.dictionaryItemById.dictionary_cd ? this.props.data.dictionaryItemById.dictionary_cd : this.props.match.params.dictionaryId;

        history = this.props.history;

        if (!obj.label || obj.label.trim() === '') {
            obj.label = obj.value;
        }
        this.props.updateDictionaryItem({
            variables: { dictionaryItem: obj },
            refetchQueries: [
                {
                    query: allDictionaryItemsQuery,
                    variables: { dictionaryId: this.props.match.params.dictionaryId },
                },
            ],
        }, this).then(
            (resp) => {
                hideErrors();
                if (resp.data.updateDictionaryItemMutation &&
                    resp.data.updateDictionaryItemMutation.validation &&
                    resp.data.updateDictionaryItemMutation.validation.length > 0) {
                    showErrors(resp.data.updateDictionaryItemMutation.validation);
                } else {
                    if (resp.data.updateDictionaryItemMutation.dictionaries) {
                        addDictionaries(JSON.parse(resp.data.updateDictionaryItemMutation.dictionaries));
                    }

                    history.goBack();
                }
            });
    }


    render() {

        const { classes } = this.props;

        const loading = this.props.data.loading;
        const item = this.props.data.dictionaryItemById;

        let caption;

        if (item && item.dictionary_item_id === '-1') {
            caption = getTxt('Nuovo Dictionary Item');
        } else {
            caption = getTxt('Modifica Dictionary Item ');
        }
        return (

            <div className={classes.root}>

                <Typography variant="h4" color="inherit" noWrap={false}>{caption}</Typography>
                <LinearProgressEx loading={loading} />

                {!loading &&
                    <div>
                        <MaterialForm dataSource={item} objType="dictionary_item" onSubmit={this.onSubmit} onChange={this.fornChanged} >

                            <c.field l={getTxt('Key')} f="value" d={item.dictionary_item_id !== '-1'} />

                            <c.field l={getTxt('Label (optional)')} f="label" id={this.id} />

                            <c.field l={getTxt('Display Order')} f="display_order" />

                            <c.formButtons sm={12} onCancel={() => { this.props.history.goBack(); }} />
                        </MaterialForm>

                    </div>
                }

            </div>




        );
    }
}




export default compose(
    graphql(dictionaryItemByIdQuery, {
        options: props => (
            {
                variables: {
                    id: props.match.params.id ? props.match.params.id : -1,
                },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }
        ),
    }),

    graphql(updateDictionaryItemMutation, {
        name: 'updateDictionaryItem',
        options: {
            errorPolicy: 'all',
        },
    }),
)(withStyles(styles)(Dictionary_Item_Edit));
