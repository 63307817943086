import ComponentEx from 'components/ComponentEx';
import { getTxt } from 'global/appGlobal';
import { Dialog, DialogContent } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { EntityTypes, getEntityDescriptor } from 'global/entityDescriptors';
import { compose, graphql } from 'react-apollo';
import LinearProgressEx from '../../components/LinearProgressEx';
import c from '../../components/form/Components';
import MaterialForm from '../../components/form/MaterialForm';
import { DictionaryTypes, getDictionary, getLabel } from '../../global/dictionaryCache';
import { metadataQuery } from '../../home/Home';
import { authInfo, Roles } from '../../global/auth';

import * as queries from '../../modules/company/CompanyQueries';
import { Button } from '@material-ui/core';
import GridEx from 'components/form/GridEx';


let history;
let AfterSubmit;
const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing.unit * 2,
        margin: theme.spacing.unit * 2,
    },
    formButtons: {
        float: 'right',
        margin: '15px',
    },
    button: {
        margin: theme.spacing.unit,
    },
});


class Company_Payment_Edit extends ComponentEx {

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);

    }

    state = {
        open: false,
    };

    formSetValues = { func: undefined };



    onSubmit(e, { obj, showErrors, hideErrors }) {
        const original = this.props.data.companyPayment;
        obj.company_payment_id = original.company_payment_id;
        
        this.props.updatePayment({
            variables: { companyPayment: obj },
            refetchQueries: [
                
                {
                    query: queries.companyPaymentByIdQuery,
                    variables: { id: this.props.id },
                },
            ],
        }, this).then(
            (resp) => {
                hideErrors();
                if (resp.data.updateCompanyPaymentMutation &&
                    resp.data.updateCompanyPaymentMutation.validation &&
                    resp.data.updateCompanyPaymentMutation.validation.length > 0) {
                    showErrors(resp.data.updateCompanyPaymentMutation.validation);
                } else {
                    AfterSubmit(resp.data.updateCompanyPaymentMutation.id);
                    //window.apolloClient.resetStore();
                }
            });
    }

    render() {

        const { classes } = this.props;

        const loading = this.props.data.loading;
        const item = this.props.data.companyPayment;
        history = this.props.history;
        AfterSubmit = this.props.AfterSubmit;

        const caption = (item && item.company_payment_id !== '-1') ? getTxt('Edit Company Payment') : getTxt('New Company Payment');

        let allowCompanySelect = false;
        if (authInfo.isAgencyAdministrator() || authInfo.isMasterBroker()) {
            allowCompanySelect = true;
        }
        if (this.props.companyId) {
            allowCompanySelect = false;
        }
        if (item && item.company_payment_id !== '-1') {
            allowCompanySelect = false;
        }

        return (

            <Dialog onClose={() => { this.props.AfterSubmit(); }} open >
                <DialogContent>
                    <LinearProgressEx loading={loading} />


                    <Typography variant="h4" color="inherit" noWrap={false}>{caption}</Typography>

                    {item &&

                        <MaterialForm
                            dataSource={item}
                            objType={EntityTypes.CompanyPayment}
                            onSubmit={this.onSubmit}
                        >
                            {!this.props.companyId && <c.field f="insurance_company_id" d={!allowCompanySelect} ui="company_select" no_deleted />}
                            {this.props.companyId && <c.field f="insurance_company_id" d={!allowCompanySelect} ui="company_select" no_deleted v={this.props.companyId} />}

                            <c.field l={getTxt('Payment Type')} f="payment_type_cd" ui="select" >
                                {getDictionary(DictionaryTypes.PublicPaymentType)}
                            </c.field>
                            <c.field l={getTxt('Payment State')} f="payment_state_cd" ui="select" >
                                {getDictionary(DictionaryTypes.InsuranceItemPaymentState)}
                            </c.field>
                            <c.field f="payment_account" ui="select" >
                                {getDictionary(DictionaryTypes.PaymentAccount)}
                            </c.field>
                            <c.field l={getTxt('Payment Date')} f="payment_date" />
                            <c.field l={getTxt('Payment notes')} f="payment_notes" />
                            <c.field f="payment_amount" />


                            <GridEx item sm={12} xs={12}>
                                <div className={classes.formButtons} >
                                    <Button
                                        className={classes.button}
                                        style={{marginRight: 10}}
                                        variant="contained"
                                        onClick={() => {
                                            this.props.AfterSubmit();
                                        }}
                                    >{getTxt('Annulla')}</Button>
                                    <Button
                                        color="primary"
                                        className={classes.button}
                                        type="submit"
                                        variant="contained"
                                    > {getTxt('Next')}</Button>
                                </div>

                            </GridEx>
                        </MaterialForm>

                    }

                </DialogContent>

            </Dialog>

        );
    }
}

export default compose(

    graphql(queries.companyPaymentByIdQuery, {
        options: props => (
            {
                variables: {
                    id: props.id ? props.id : 'new',
                },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }
        ),
    }),

    graphql(queries.updateCompanyPaymentMutation, {
        name: 'updatePayment',
        options: {
            errorPolicy: 'all',
        },
    }),

)(withStyles(styles)(Company_Payment_Edit));
