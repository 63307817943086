
import React, { Component } from 'react';
import { getTxt, randomString } from 'global/appGlobal';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
//import FormField from 'components/form/FormField';
import GridEx from 'components/form/GridEx';
import { MenuItem } from '@material-ui/core';
import { Button } from '@material-ui/core';
import {
    FormLabel,
    FormControl,
    FormGroup,
    FormControlLabel,
    FormHelperText,
} from '@material-ui/core';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import { Checkbox } from '@material-ui/core';
import { Input, InputLabel, InputAdornment } from '@material-ui/core';
import FormCaption from 'components/form/FormCaption';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import $ from 'jquery';
import { EntityTypes, getEntityDescriptor } from 'global/entityDescriptors';
import schema from 'async-validator';
import  { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

import { Select } from '@material-ui/core';
import { HashRouter, BrowserRouter, Route, Link, withRouter } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

import MFormControl from '../../components/form/MFormControl';

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: '100%',
    },

    input: {
        marginTop: theme.spacing.unit * 2,
    },

    dialogText: {
        color: theme.palette.error.main,
    },

    calcCodeBtn: {
        position: 'absolute',
        right: 0,
        bottom: theme.spacing.unit,
    },
    calcProgress: {
        position: 'absolute',
        right: theme.spacing.unit * 13,
    },
});

function convertDate(date) {
    function pad(s) { return (s < 10) ? '0' + s : s; }
    const d = new Date(date);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/');
}

export const calcFiscalCodeQuery = gql`
query calcFiscalCode ($firstName: String, $lastName: String, $city: String, $date: String, $sex: String) {
    calcFiscalCode (firstName: $firstName, lastName: $lastName, city:$city, date: $date, sex: $sex )
}
`;

class MFormFiscalCodeText extends MFormControl {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.calculateFiscalCode = this.calculateFiscalCode.bind(this);
    }

    onChange(e) {
        this.setState({ value: e.target.value });
        this.setState({ isDirty: true });
        this.formOnChange(this.props.f, e.target.value, this.props.type);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.forseRefresh) {
            this.setState({ value: nextProps.v });
        }
    }
    componentDidMount() {
        if (this.props.max) {
            $('#' + this.id).attr('max', this.props.max);
        }
        if (this.props.maxlength) {
            $('#' + this.id).attr('maxlength', this.props.maxlength);
        }

    }

    async calculateFiscalCode() {

        let firstName = $(`input[name="${this.props.firstNameField}"]`).val();
        let lastName = $(`input[name="${this.props.lastNameField}"]`).val();
        let city = $(`input[name="${this.props.cityField}"]`).val();
        let _date = $(`input[name="${this.props.dateField}"]`).val();
        let sex = $(`input[name="${this.props.sexField}"]`).val();

        if (firstName) {
            firstName = firstName.trim();
        }
        if (lastName) {
            lastName = lastName.trim();
        }
        if (city) {
            city = city.trim();
        }
        if (_date) {
            _date = _date.trim();
        }
        if (sex) {
            sex = sex.trim();
        }

        if (!firstName || firstName === '' ||
            !lastName || lastName === '' ||
            !city || city === '' ||
            !_date || _date === '' ||
            !sex || sex === '') {
            this.setState({ generateFiscalValidationDlgOpen: true });
            return;
        }

        const asDate = new Date(Date.parse(_date));
        const d = convertDate(asDate);

        //const query = `?Nome=${firstName}&Cognome=${lastName}&ComuneNascita=${city}&DataNascita=${d}&Sesso=${sex}`;


        this.setState({ loading: true });

        const this1 = this;
        //try {
        async function runQuery() {

            const f = 0;

            const resp = await window.apolloClient.query({
                query: calcFiscalCodeQuery,
                options: {
                    fetchPolicy: 'network-only',
                },
                variables: {
                    firstName,
                    lastName,
                    city,
                    date: d,
                    sex,
                },
            });

            this1.setState({ loading: false });
            if (resp.data && resp.data.calcFiscalCode) {
                this1.setState({ value: resp.data.calcFiscalCode });
            }
        }

        await runQuery();



        //     $.ajax({
        //         type: 'GET',
        //         url: 'http://webservices.dotnethell.it//codicefiscale.asmx/CalcolaCodiceFiscale' + query,
        //         context: this,
        //         success: (data, textStatus, jqXHR) => {
        //             this.setState({ loading: false });

        //             var code = jqXHR.responseText.replace('<?xml version="1.0" encoding="utf-8"?>', '').
        //                 replace('<string xmlns="http://webservices.dotnethell.it/CodiceFiscale">', '').
        //                 replace('</string>', '').trim();

        //             this.setState({ value: code });
        //         },
        //         error: () => {
        //             this.setState({ loading: false });
        //         },
        //     });
        // } catch (err) {
        //     this.setState({ loading: false });
        // }

    }


    render() {
        const { classes } = this.props;
        return (

            <GridEx item sm={this.props.sm || 6} xs={this.props.xs || 12}>
                <FormControl className={classes.textField} >
                    <InputLabel
                        error={this.state.errorMessage ? true : false}
                        shrink
                    >
                        {this.getLabel()}
                    </InputLabel>
                    <Input
                        margin="normal"
                        {...this.props}
                        value={this.state.value}
                        disabled={this.props.d}
                        required={this.props.r}
                        onChange={this.onChange}

                        inputProps={
                            {
                                id: this.props.id ? this.props.id : this.id,
                                name: this.props.f,
                                placeholder: `${getTxt('Click')} '${getTxt('Calcola')}' ${getTxt('to calculate fiscal code using external WEB service')}`,
                                endAdornment: this.props.endAdornment,
                            }}

                        endAdornment={
                            <InputAdornment position="end">
                                {this.state.loading && <CircularProgress className={classes.calcProgress} size={30} />}
                                <Button variant="contained" size="small" onClick={this.calculateFiscalCode} className={classes.calcCodeBtn} >{getTxt('Calcola')}</Button>
                            </InputAdornment>
                        }
                    />
                    <FormHelperText error={this.state.errorMessage ? true : false} >{this.state.errorMessage}</FormHelperText>
                </FormControl>




                <Dialog
                    className={classes.dialog}
                    open={this.state.generateFiscalValidationDlgOpen}
                    onClose={() => {
                        this.setState({ serverError: undefined, generateFiscalValidationDlgOpen: false });
                    }}
                >
                    <DialogTitle> <span className={classes.dialogText}>{getTxt('Not enough data to calculate the fiscal code')}</span></DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <span className={classes.dialogText}>{getTxt('To calculate the fisal code need provide Nome, Cognome, Cita di Nascita, Data di Nascita and Sesso.')}</span>
                        </DialogContentText>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { this.setState({ generateFiscalValidationDlgOpen: false }); }} color="primary" >
                            {getTxt('Ok')}
                        </Button>

                    </DialogActions>
                </Dialog>

            </GridEx>

        );
    }
}

MFormFiscalCodeText.contextTypes = {
    onChange: PropTypes.func,
    registerControl: PropTypes.func,
    objType: PropTypes.string,
    dataSource: PropTypes.object,
    unRegisterControl: PropTypes.func,
    fields: PropTypes.Object,
};

export default (withStyles(styles, true)(MFormFiscalCodeText));
