import React, { Component } from 'react';
import { getTxt } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import { Paper } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { AppBar } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { TextField } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';

import * as Icon from '@material-ui/icons';
import { Checkbox } from '@material-ui/core';

import GridEx from 'components/form/GridEx';

import { Button } from '@material-ui/core';
import moment from 'moment';
import { Badge } from '@material-ui/core';

import ComponentEx from 'components/ComponentEx';

import { p } from 'components/form/Proto';

import { LinearProgress } from '@material-ui/core';
import { graphql, compose } from 'react-apollo';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';

import c from '../../components/form/Components';
import LinearProgressEx from '../../components/LinearProgressEx';
import { getLabel, DictionaryTypes, getDictionary, getInternalInsTypeCd } from '../../global/dictionaryCache';
import MaterialForm from '../../components/form/MaterialForm';
import MFormText from '../../components/form/MFormText';
import * as queries from '../../modules/guaranty/contractGuarantyQueries';
import { requestBriefQuery } from '../../modules/request/requestQeries';
import { getToken } from '../../global/auth';
import { updateStatisticsMutation } from '../dashboard/dashboardQueries';

let history;
let AfterSubmit;

const styles = theme => ({

});


class RequestGuarantyEdit extends ComponentEx {

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.formChanged = this.formChanged.bind(this);
    }

    state = {
        open: false,
    };

    formGetValues = { func: undefined };
    formSetValues = { func: undefined };

    formChanged(field, value, type, prevVal) {
        if (field === 'guaranty_type_id') {
            if (value) {
                const selectedGuaranty = this.props.data.contractGuarantyById.allGuaranties.find(g =>
                    g.guaranty_type_id + '' === value + '');
                if (selectedGuaranty && selectedGuaranty.is_financial === 'true') {
                    this.formSetValues.func({
                        franchise_amount: selectedGuaranty.default_franchise_amount,
                        maximum_amount: selectedGuaranty.default_maximum_amount,
                        overdraft_amount: selectedGuaranty.default_overdraft_amount,
                    });
                } else {
                    this.formSetValues.func({
                        franchise_amount: 0.00,
                        maximum_amount: 0.00,
                        overdraft_amount: 0.00,
                    });
                }

            }
        }
    }

    onSubmit(e, { obj, showErrors, hideErrors }) {
        const original = this.props.data.contractGuarantyById;
        obj.contract_guaranty_id = original.contract_guaranty_id;
        obj.insurance_request_id = this.props.requestId;

        const contractItemId = this.props.contractItemId;

        this.props.updateRequestGuaranty({
            variables: { requestGuaranty: obj },
            refetchQueries: [
                {
                    query: queries.allContractGuaranty,
                    variables: {
                        requestId: this.props.requestId,
                    },
                },
                {
                    query: queries.contractGuarantyByIdQuery,
                    variables: {
                        id: this.props.id,
                        reqId: this.props.requestId,
                        insType: getInternalInsTypeCd(this.props.insType),
                    },
                },
                {
                    query: requestBriefQuery,
                    variables: {
                        id: this.props.requestId,
                        insType: getInternalInsTypeCd(this.props.insType),
                    },
                },
            ],
        }, this).then(
            (resp) => {
                const i = contractItemId;
                hideErrors();
                if (resp.data.updateRequestGuarantyMutationName &&
                    resp.data.updateRequestGuarantyMutationName.validation &&
                    resp.data.updateRequestGuarantyMutationName.validation.length > 0) {
                    showErrors(resp.data.updateRequestGuarantyMutationName.validation);
                } else {

                    window.updateTotalsNeedFor = i;
                    if (!AfterSubmit) {
                        history.goBack();
                    } else {

                        this.props.updateStatistics({ variables: { brokerId: 0, companyId: 0, contractItemId } });

                        AfterSubmit();
                    }

                }
            });
    }


    render() {

        const { classes } = this.props;

        const loading = this.props.data.loading;
        const item = this.props.data.contractGuarantyById;
        history = this.props.history;
        AfterSubmit = this.props.AfterSubmit;
        let caption;

        if (item && item.contract_guaranty_id === '-1') {
            caption = getTxt('Nuovo Guaranty');
        } else {
            caption = getTxt('Modifica Guaranty');
        }


        let isFinancial;
        if (item) {
            isFinancial = this.state.isFinancial !== undefined ? this.state.isFinancial : item.is_financial;
        }
        if (isFinancial === 'true') {
            isFinancial = true;
        } else if (isFinancial === 'false') {
            isFinancial = false;
        }

        let guaranties = [];

        if (item && item.allGuaranties && item.allGuaranties.length > 0) {
            guaranties = item.allGuaranties.filter(g => !g.deleted)
                .map(i => ({ value: Number.parseInt(i.guaranty_type_id), label: i.guaranty_name }));
            //selectedGt = item.allGuaranties.find(g => g.guaranty_type_id + '' === item.guaranty_type_id + '');

        }

        return (


            <Dialog onClose={() => { this.props.AfterSubmit(); }} open >
                <DialogContent>
                    <LinearProgressEx loading={loading} />


                    <Typography variant="h4" color="inherit" noWrap={false}>{getTxt(caption)}</Typography>


                    {item &&
                        <div>
                            <MaterialForm
                                dataSource={item}
                                objType="contract_guaranty"
                                onSubmit={this.onSubmit}
                                onChange={this.formChanged}
                                getValues={this.formGetValues}
                                setValues={this.formSetValues}
                            >


                                <c.field sm="12" f="guaranty_type_id" ui="select" l={getTxt('Guaranty')} d={item.contract_guaranty_id !== '-1'} >
                                    {guaranties}
                                </c.field>

                                {item.insurance_contract_id && <c.group l={getTxt('Contract Guaranty Amounts')} />}
                                {item.insurance_contract_id && <c.field f="net_amount" l={getTxt('Premio Imponibile')} sm="12" />}
                                {item.insurance_contract_id && <c.field f="imposte_percent" l={getTxt('Imposte')} />}
                                {item.insurance_contract_id && item.base_ins_type === 'VEICOLI' &&
                                    <c.field f="ssn_percent" l={getTxt("SSN")} />}

                                {item.insurance_contract_id && <c.field f="agency_percent" l={getTxt("% Agency")} />}
                                {item.insurance_contract_id && <c.field f="broker_percent" l={getTxt("% Broker")} />}

                                {!item.insurance_contract_id && <c.group l={getTxt('Finantial')} />}
                                {!item.insurance_contract_id && <c.field f="maximum_amount" l={getTxt("Massimale")} />}
                                {!item.insurance_contract_id && <c.field f="franchise_amount" l={getTxt("Franchigia")} />}
                                {!item.insurance_contract_id && <c.field f="overdraft_amount" l={getTxt("Scoperto")} />}

                                <c.formButtons
                                    sm={12}
                                    onCancel={() => {
                                        if (!this.props.AfterSubmit) {
                                            this.props.history.goBack();
                                        } else {
                                            this.props.AfterSubmit();
                                        }
                                    }}
                                />
                            </MaterialForm>

                        </div>
                    }




                </DialogContent>

            </Dialog>


        );
    }
}




export default compose(
    graphql(queries.contractGuarantyByIdQuery, {
        options: props => (
            {
                variables: {
                    id: (!props.id ? (props.match.params.id ? props.match.params.id : 'new') : props.id),
                    reqId: (props.match && props.match.params.requestId) ? props.match.params.requestId : props.requestId,
                    insType: getInternalInsTypeCd(props.insType),
                },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }
        ),
    }),
    graphql(queries.updateRequestGuarantyMutationName, {
        name: 'updateRequestGuaranty',
        options: {
            errorPolicy: 'all',
          
        },
    }),
    graphql(updateStatisticsMutation, {
        name: 'updateStatistics',
        options: {

            errorPolicy: 'all',
        },
    }),

)(withStyles(styles)(RequestGuarantyEdit));
