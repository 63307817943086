import gql from 'graphql-tag';

export const allReferentsQuery = gql`

query AllReferentsQuery ($parentId: Int)  {
    allReferents (parentId: $parentId) {
            referent_id
            parent_id
            first_name
            last_name
            job_title
            phone
            mobile_phone
            email
            display_name
        },
    }
`;

export const referentByIdQuery = gql`

query referentByIdQuery($id: ID!) {
    referentById(id: $id) {
            referent_id
            parent_id
            first_name
            last_name
            job_title
            phone
            mobile_phone
            email
            display_name
    }
  }
  

`;

export const updateReferentMutation = gql`

mutation updateReferentMutation($referent: referent_input!) {
    updateReferentMutation(referent: $referent) {
      id
      validation {
        field
        message
      }
    }
  }

  `;


export const deleteReferentMutation = gql`

mutation deleteReferentMutation ($id: ID!) {
    deleteReferrent(id: $id){
        id
        validation {
            field
            message
        }
    } 
}

`;