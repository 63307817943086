import React, { Component } from 'react';
import { getTxt } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';
import { withTheme } from '@material-ui/core';

import { Typography } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';



import { Paper } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { AppBar } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { TextField } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import gql from 'graphql-tag';
import { graphql, compose } from 'react-apollo';
import { Tabs, Tab } from '@material-ui/core';
import * as Icon from '@material-ui/icons';
import GridEx from 'components/form/GridEx';

import { Button } from '@material-ui/core';
import moment from 'moment';
import { Badge } from '@material-ui/core';

import ComponentEx from 'components/ComponentEx';
import { queryGetCustomerById, customerBriefQuery, allCustomersQuery } from '../../modules/client/clientQueries';
import c from '../../components/form/Components';
import LinearProgressEx from '../../components/LinearProgressEx';
import { getLabel, DictionaryTypes, getDictionary, getValue } from '../../global/dictionaryCache';
import MaterialForm from '../../components/form/MaterialForm';
import MFormText from '../../components/form/MFormText';
import { authInfo, Roles } from '../../global/auth';

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing.unit * 2,
        //  margin: theme.spacing.unit * 2,
    },

    button: {
        margin: theme.spacing.unit,
    },

    badge: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        marginTop: 0,
    },

    gridRoot: {
        height: '100%',
    },


    formCaption: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit * 2,
        width: '100%',
        position: 'relative',
        height: 40,
    },
    formCaptionLabel: {
        position: 'absolute',
        backgroundColor: 'transparent',
        left: 25,
        paddingLeft: 10,
        paddingRight: 10,
    },

    rootPaper: theme.mixins.gutters({

        paddingTop: 0,
        paddingBottom: 0,
        marginTop: 0,// theme.spacing.unit * 3,

    }),

});


class Client_Propspect_Privat_Details_Edit extends ComponentEx {

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.fornChanged = this.fornChanged.bind(this);
    }

    state = {
        address_country_cd: undefined,
        address_province: undefined,
    };

    onSubmit(e, { obj, showErrors, hideErrors }) {

        obj.customer_id = this.props.data.customer.customer_id;
        if (!obj.customer_id) {
            obj.customer_id = this.props.data.customer.customer_id;
        }
        obj.customer_type_cd = 'PRIVATE_PROSPECT';


        this.props.updateCustomer({
            variables: { customer: obj },

            refetchQueries: [
                {
                    query: customerBriefQuery,
                    variables: { id: this.props.data.customer.customer_id },
                },
                {
                    query: queryGetCustomerById,
                    variables: { id: this.props.data.customer.customer_id },
                },
                {
                    query: allCustomersQuery,
                },
            ],
        }, this).then(
            (resp) => {
                hideErrors();
                const serverErrors = resp.data.updateCustomerMutation.validation;
                const id = resp.data.updateCustomerMutation.id;

                if (serverErrors && serverErrors.length > 0) {
                    showErrors(serverErrors);
                } else {
                    this.props.history.push(`/customers/prospect/private/${id}`);
                }
            });
    }

    fornChanged(field, value, type, prevVal) {
        if (field === 'address_country_cd') {
            //const prevCountry = getValue(DictionaryTypes.Country, prevVal);
            const country = getValue(DictionaryTypes.Country, value);

            if (country) {
                this.setState({ address_country_cd: country });
            }

            // if (!country) {
            //     return;
            // }
            // if ((prevCountry !== 'IT' && country === 'IT') ||
            //     (prevCountry === 'IT' && country !== 'IT')) {
            //     this.setState({ address_country_cd: country });
            // }
        }
        if (field === 'address_province') {
            //const prevProv = getValue(DictionaryTypes.ItalianProvinces, prevVal);
            const prov = getValue(DictionaryTypes.ItalianProvinces, value);

            if (prov) {
                this.setState({ address_province: prov });
            }


            // if (!prov) {
            //     return;
            // }
            // if (prevProv !== prov) {
            //     this.setState({ address_province: prov });
            // }
        }
    }

    render() {

        const { classes } = this.props;
        const loading = this.props.data.loading;
        const customer = this.props.data.customer;


        let address_country_cd;
        let address_province;
        if (customer) {
            address_country_cd = this.state.address_country_cd ? this.state.address_country_cd : customer.address_country_cd;
            address_province = this.state.address_province ? this.state.address_province : customer.address_province;
        }

        let caption;

        if (!this.props.match.params.id) {
            caption = 'Nuovo Prospect';
        } else {
            caption = 'Modifica Prospect';
        }

        return (

            <div className={classes.root}>
                <Typography variant="h4" color="inherit" noWrap={false}>{getTxt(caption)}</Typography>
                <LinearProgressEx loading={loading} />
                {!loading &&
                    <div>
                        <MaterialForm dataSource={customer} objType="customer" onSubmit={this.onSubmit} onChange={this.fornChanged}>

                            <c.group l="Dati personali" />
                            <c.field l="Codice Interno" f="customer_id" d />

                            <c.field l="Nome" f="first_name" />
                            <c.field l="Cognome" f="last_name" />

                            {customer.broker && (authInfo.isAgencyAdministrator() || authInfo.isMasterBroker()) && <c.field l="Collaboratore" f="owner_broker_id" ui="broker_select" no_deleted r />}
                            {customer.broker && (!authInfo.isAgencyAdministrator() && !authInfo.isMasterBroker()) && <c.text l="Collaboratore" v={customer.broker.display_name} d no_deleted />}
                            {!customer.broker && !authInfo.isSubBroker() && <c.field l="Collaboratore" f="owner_broker_id" ui="broker_select" r no_deleted />}
                            {!customer.broker && authInfo.isSubBroker() && <c.field l="Collaboratore" f="owner_broker_id" ui="broker_select" r d no_deleted />}




                            <c.group l="Indirizzo" />


                            <c.field
                                l="Nazione"
                                f="address_country_cd"
                                ui="auto-select"
                            >
                                {getDictionary(DictionaryTypes.Country)}
                            </c.field>
                            {address_country_cd === 'IT' &&
                                <c.field
                                    l={getTxt('Province')}
                                    f="address_province"
                                    ui="auto-select"
                                >
                                    {getDictionary(DictionaryTypes.ItalianProvinces)}
                                </c.field>}
                            {address_country_cd === 'IT' &&
                                <c.field
                                    provinceId={address_province}
                                    l="Città"
                                    f="address_city"
                                    ui="city-auto-select"
                                />}
                            {address_country_cd !== 'IT' &&
                                <c.field l="Città" f="address_city" />
                            }
                            <c.field l="CAP" f="address_cap" />
                            <c.field l="Indirizzo" f="address_street" />
                            <c.field l="Civico" f="address_street_number" />

                            <c.group l="Informazioni Contatti" />
                            <c.field l="Telefono" f="phone" />
                            <c.field l="Telefono Attività" f="phone_active" />
                            <c.field l="Cellulare" f="mobile_phone" />
                            <c.field l="Email" f="email" />
                            <c.field l="PEC" f="email_pec" />



                            <c.group l="Informazioni sul'Attività" />
                            <c.field l="Attività Svolta" f="business_occupation" ui="select" >
                                {getDictionary(DictionaryTypes.BusinessOccupation)}
                            </c.field>

                            <c.field f="internal_notes" sm={12} multiline />

                            <c.formButtons sm={12} onCancel={() => { this.props.history.goBack(); }} />
                        </MaterialForm>

                    </div>
                }


            </div>

        );
    }
}


const updateCustomerMutation = gql`

mutation UpdateCustomerMutation ($customer: customer_input!) {
    updateCustomerMutation(customer: $customer){
        id
        validation {
            field
            message
        }
    } 
}

`;

export default compose(
    graphql(queryGetCustomerById, {
        options: props => ({ variables: { id: props.match.params.id ? props.match.params.id : -1 } }),
    }),
    graphql(updateCustomerMutation, {
        name: 'updateCustomer',
        options: {
            errorPolicy: 'all',
        },
    }),
)(withStyles(styles)(Client_Propspect_Privat_Details_Edit));
