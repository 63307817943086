
import React, { Component } from 'react';
import { getTxt, randomString } from 'global/appGlobal';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
//import FormField from 'components/form/FormField';
import GridEx from 'components/form/GridEx';
import { MenuItem } from '@material-ui/core';
import { Button } from '@material-ui/core';
import {
    FormLabel,
    FormControl,
    FormGroup,
    FormControlLabel,
    FormHelperText,
} from '@material-ui/core';
import { Checkbox } from '@material-ui/core';
import { Input, InputLabel } from '@material-ui/core';
import FormCaption from 'components/form/FormCaption';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import $ from 'jquery';
import { EntityTypes, getEntityDescriptor } from 'global/entityDescriptors';
import schema from 'async-validator';

import  { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

import { Select } from '@material-ui/core';
import { HashRouter, BrowserRouter, Route, Link, withRouter } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';

import MFormControl from '../../components/form/MFormControl';

const styles = theme => ({
    checkBox: {
        marginLeft: theme.spacing.unit,
    },
});

class MFormLinkToEntity extends MFormControl {
    constructor(props) {
        super(props);

    }

    render() {
        const { classes } = this.props;
        return (

            <GridEx item sm={this.props.sm || 6} xs={this.props.xs || 12}>

                <FormControl className={classes.formControl}>
                    <InputLabel
                        // FormControlClasses={{
                        //     focused: classes.inputLabelFocused,
                        // }}
                    >
                        {this.getLabel()}
        </InputLabel>
        <Link to={this.props.to} >{this.props.text}</Link>
                </FormControl>

                {/* <FormGroup>
                    <FormControlLabel
                        control={
                            <Link to={this.props.to} >{this.props.text}</Link>
                        }
                        label={getTxt(this.props.en || this.props.l, this.props.it)}
                    />
                </FormGroup> */}
            </GridEx>

        );
    }
}

MFormLinkToEntity.contextTypes = {
    onChange: PropTypes.func,
    registerControl: PropTypes.func,
    objType: PropTypes.string,
    dataSource: PropTypes.object,
    unRegisterControl: PropTypes.func,
    fields: PropTypes.Object,
};

export default withStyles(styles, { withTheme: true })(MFormLinkToEntity);