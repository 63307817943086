import ComponentEx from 'components/ComponentEx';
import { getTxt } from 'global/appGlobal';
import { Dialog, DialogContent } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { compose, graphql } from 'react-apollo';
import LinearProgressEx from '../../components/LinearProgressEx';
import c from '../../components/form/Components';
import MaterialForm from '../../components/form/MaterialForm';
import { DictionaryTypes, getDictionary, getLabel } from '../../global/dictionaryCache';
import { metadataQuery } from '../../home/Home';
import Custom_Fields_Edit from '../../modules/publicInsType/Custom_Fields_Edit';
import * as queries from '../../modules/publicInsType/publicInsTypeQueries';

let history;
let AfterSubmit;

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing.unit * 2,
        margin: theme.spacing.unit * 2,
    },
});


class PublicInsTypeEdit extends ComponentEx {

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.formChanged = this.formChanged.bind(this);
    }

    state = {
        open: false,
    };

    formSetValues = { func: undefined };

    formChanged(field, value, type, prevVal) {
    }

    onSubmit(e, { obj, showErrors, hideErrors }) {
        const original = this.props.data.public_Ins_Type_By_Id;
        obj.public_ins_type_id = original.public_ins_type_id;

        this.props.updateType({
            variables: { type: obj },
            refetchQueries: [
                {
                    query: queries.allTypesQuery,
                },
                {
                    query: queries.typeByIdQuery,
                    variables: { id: original.public_ins_type_id },
                },
                {
                    query: metadataQuery,
                },
            ],
        }, this).then(
            (resp) => {
                hideErrors();
                if (resp.data.update_public_Ins_Type_Mutation &&
                    resp.data.update_public_Ins_Type_Mutation.validation &&
                    resp.data.update_public_Ins_Type_Mutation.validation.length > 0) {
                    showErrors(resp.data.update_public_Ins_Type_Mutation.validation);
                } else {
                    if (!AfterSubmit) {
                        history.goBack();
                    } else {
                        AfterSubmit(resp.data.update_public_Ins_Type_Mutation.id);
                    }
                }
            });
    }

    render() {

        const { classes } = this.props;

        const loading = this.props.data.loading;
        const item = this.props.data.public_Ins_Type_By_Id;
        history = this.props.history;
        AfterSubmit = this.props.AfterSubmit;
        let caption;


        if (item && item.public_ins_type_id === '-1') {
            caption = getTxt('Nuovo Insurance Type');
        } else {
            caption = getTxt('Modifica Insurance Type');
        }

        return (


            <Dialog onClose={() => { this.props.AfterSubmit(); }} open >
                <DialogContent>
                    <LinearProgressEx loading={loading} />


                    <Typography variant="h4" color="inherit" noWrap={false}>{caption}</Typography>


                    {item &&

                        <MaterialForm
                            dataSource={item}
                            objType="public_ins_type"
                            onSubmit={this.onSubmit}
                            onChange={this.formChanged}
                            setValues={this.formSetValues}
                        >

                            {item.public_ins_type_id === '-1' && !this.props.realType &&
                                <c.field l={getTxt('Actualins Insurance Type')} f="actual_ins_type_cd" ui="select" >
                                    {getDictionary(DictionaryTypes.InternalInsuranceType)}
                                </c.field>
                            }
                            {item.public_ins_type_id === '-1' && this.props.realType &&
                                <c.field l={getTxt('Actualins Insurance Type')} f="actual_ins_type_cd" ui="select" v={this.props.realType} d >
                                    {getDictionary(DictionaryTypes.InternalInsuranceType)}
                                </c.field>
                            }

                            {item.public_ins_type_id !== '-1' &&
                                <c.field l={getTxt('Actualins Insurance Type')} f="actual_ins_type_cd" d value={getLabel(DictionaryTypes.InsuranceType, item.actual_ins_type_cd)} />
                            }

                            <c.field f="public_ins_type_cd" l={getTxt('Insurance Type Key')} />
                            <c.field f="public_ins_type_name" l={getTxt('Insurance Type Name')} />

                            
                           

                            <c.formButtons sm={12} onCancel={() => { this.props.AfterSubmit(); }} />
                        </MaterialForm>


                    }

                </DialogContent>

            </Dialog>

        );
    }
}

export default compose(
    graphql(queries.typeByIdQuery, {
        options: props => (
            {
                variables: {
                    id: (!props.id ? (props.match.params.id ? props.match.params.id : 'new') : props.id),
                },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }
        ),
    }),
    graphql(queries.updateTypeMutation, {
        name: 'updateType',
        options: {
            errorPolicy: 'all',
           
        },
    }),
)(withStyles(styles)(PublicInsTypeEdit));
