import React from 'react';
import { graphql, compose } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import $ from 'jquery';
import { Link, useHistory, useParams } from 'react-router-dom';

import {
    SortingState, FilteringState, IntegratedFiltering, IntegratedSorting,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    VirtualTable, TableHeaderRow, TableFilterRow, TableColumnResizing,
} from '@devexpress/dx-react-grid-material-ui';

import ComponentEx from 'components/ComponentEx';
import { Button, makeStyles, Typography, TableCell } from '@material-ui/core';
import c from 'components/form/Components';
import { getTxt, toItalianDateString, filterRowMessages, tableMessages, createGridResizerCols, randomString, createGridResizerColsHooksGrid } from '../../global/appGlobal';
import LinkToCustomerById from '../../components/LinksToObjectById';
import LinearProgressEx from '../../components/LinearProgressEx';
import { getLabel, getDictionary, DictionaryTypes } from '../../global/dictionaryCache';
import { allRequestsQuery, deleteRequestMutation, allRequestsQuery2 } from '../../modules/request/requestQeries';
import { Menu, MenuItem } from '@material-ui/core';
import * as Icon from '@material-ui/icons';

import { Divider } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import RegularCard from '../../components/dashboard/RegularCard';
import { Tooltip, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { authInfo } from 'global/auth';
import NewRequestMenuItems from '../client/NewRequestMenuItems';
import { useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { MIN_TAKE, FILTERS_TIMEOUT, LOAD_MORE_ROW_VALUE, canLoadMore, getNextTake, MAX_TAKE, filtersPromptVisible } from '../client/Client_Search_BigData';
import { useEffect } from 'react';

const LOAD_MORE_PROP = 'insurance_type_cd';
let filtersL = [];
let takeL = 10;
let filterTimeoutId;
function isLoadMoreRow(row) {
    return row[LOAD_MORE_PROP] === LOAD_MORE_ROW_VALUE;
}

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        margin: theme.spacing.unit * 3,
    },
    button: {
        marginRight: theme.spacing.unit,
    },

    tableHeader: {
        backgroundColor: theme.palette.background.paper,
    },
    menuItem: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& $text, & $icon': {
                color: theme.palette.common.white,
            },
        },
    },
    text: {},
    icon: {},
}));


const toLowerCase = value => String(value).toLowerCase();

const filterByDisplayName = (row, e) => {
    const name = toLowerCase(row.display_name);
    return name.includes(toLowerCase(e.value));
};

const getRowId = row => row.customer_id;

export default function Request_Search_BigData({ noCaption }) {

    const [anchorEl, setAnchorEl] = useState();
    const [open, setOpen] = useState();
    const [menuOpen, setMenuOpen] = useState();
    const [newRequestDlgOpen, setNewRequestDlgOpen] = useState();
    const [addNewMenuOpen, setAddNewMenuOpen] = useState();
    const [addNewMenuAnchor, setAddNewMenuAnchor] = useState();
    const [delId, setDelId] = useState();
    const [delNumber, setDelNumber] = useState();
    const classes = useStyles();

    const history = useHistory();
    const { customerId, brokerId, filter } = useParams();


    const [refetch, { loading, data }] = useLazyQuery(allRequestsQuery2, { client: window.apolloClient });

    const [deleteRequest, { loading: loadingDelete }] = useMutation(deleteRequestMutation, {
        client: window.apolloClient,
        onCompleted: (data) => {
            refetch({ variables: { customerId: customerId, broker_id: brokerId, take: takeL, filters: filtersL, id: randomString(7, 'aA') } });
        },
        refetchQueries: [

        ],

    });

    useEffect(() => {
        refetch({ variables: { customerId: customerId, broker_id: brokerId, take: takeL, filters: filtersL, id: randomString(7, 'aA') } });
    }, []);


    function newClick(event) {
        if (!authInfo.isCustomer()) {
            setAnchorEl(event.currentTarget);
            setMenuOpen(true);
        } else {
            setAddNewMenuAnchor(event.currentTarget);
            setAddNewMenuOpen(true);
        }
    }

    function handleRequestClose() {
        setMenuOpen(false);
    };

    function createQuoteClick() {
        history.push('/underdev');
    }

    function handleDelete() {
        deleteRequest({
            variables: { requestId: delId },
            refetchQueries: [
                {
                    query: allRequestsQuery2,
                    variables: {
                        customerId: customerId,
                        broker_id: brokerId,
                    },
                },
            ],
        }).then(
            (resp) => {
                setDelId(undefined);
                setDelNumber(undefined);
            });
    }

    let requests = [];
    if (data && data.allRequests2) {
        data.allRequests2.typesPresents.forEach((reqTypeName) => {
            if (data.allRequests2[reqTypeName]) {
                requests = requests.concat(data.allRequests2[reqTypeName]);
            }

        });
    }

    const requestsExt = [];
    requests.forEach((r) => {
        requestsExt.push({ ...r, broker: r.customer.broker });

    });
    requests = requestsExt;

    let caption = getTxt('Requests');
    let subtitle = getTxt('List of insurance requests.');

    if (filter === 'received') {
        requests = requests.filter((r) => {
            return (r.state_cd !== 'TRANSFERRED_TO_CONTRACT' &&
                r.state_cd !== 'ALL_QUOTATIONS_REFUSED' &&
                r.state_cd !== 'REFUSED_BY_AGENCY');
        });

        subtitle = getTxt('List of received insurance requests.');
        caption = getTxt('Received Requests');
    }

    const columns = [
        { name: 'insurance_type_cd', title: getTxt('Tipo', '#m8d7dy2') },
    ];
    if (!customerId) {
        columns.push({ name: 'customer', title: getTxt('Cliente', '#36q9yv8') });
    }
    columns.push({ name: 'state_cd', title: getTxt('Stato', '#q023fte') });
    columns.push({ name: 'broker', title: getTxt('Collaboratore', '#8zmx3w0') });
    columns.push({ name: 'creation_date', title: getTxt('Data Ricezione', '#0tja6u7') });
    columns.push({ name: 'ins_contract_date', title: getTxt('Decorrenza Contratto') });
    if (authInfo.isAgencyAdministrator() || authInfo.isMasterBroker()) {
        columns.push({ name: 'insurance_request_id', title: getTxt('Actions') });
    }

    const defColumnWidths = createGridResizerColsHooksGrid(columns, {}, 250);
    const [сolumnWidths, setColumnWidths] = useState(defColumnWidths);

    if (requests.length > 0 && requests.length > takeL && !isLoadMoreRow(requests[requests.length - 1])) {
        requests.push({
            [LOAD_MORE_PROP]: LOAD_MORE_ROW_VALUE,
            customer_id: LOAD_MORE_ROW_VALUE,
        });
    }

    return (

        <div className={classes.root}>
            <LinearProgressEx loading={loading || loadingDelete} />

            {!noCaption && <RegularCard

                headerColor="red"
                cardTitle={caption}
                cardSubtitle={subtitle}
                content={
                    <div>
                        {<Button color="primary" className={classes.button}
                            onClick={newClick}
                        >{getTxt('Aggiungi nuova richiesta')}</Button>}
                    </div>

                }
            />}

            <Menu
                id="simple-menu"
                anchorEl={addNewMenuAnchor}
                open={addNewMenuOpen}
                onClose={() => { setAddNewMenuOpen(false); }}
            >
                <NewRequestMenuItems history={history} customerId={authInfo.loginInfo.userInfo.user_id} />
            </Menu>

            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={handleRequestClose}
            >
                <MenuItem className={classes.menuItem} disabled={true} >
                    {getTxt('Request for New Customer')}
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => { history.push('/customers/new/company/'); }}>{getTxt('Azienda')}</MenuItem>
                <MenuItem onClick={() => { history.push('/customers/new/private/'); }}>{getTxt('Privato')}</MenuItem>

                <MenuItem onClick={() => { history.push('/customers/new/prospect/private/'); }}>{getTxt('Prospect')}</MenuItem>

                <Divider />
                <MenuItem onClick={() => { history.push('/customers'); }}><Icons.Search />{getTxt('Cerca Cliente')}</MenuItem>

            </Menu>


            <Grid

                rows={requests}
                columns={columns}

            >
                <FilteringState
                    defaultFilters={filtersL}
                    onFiltersChange={async (f, a) => {
                        filtersL = f;

                        if (filterTimeoutId) {
                            clearTimeout(filterTimeoutId);
                        }
                        filterTimeoutId = setTimeout(async () => {
                            filterTimeoutId = undefined;
                            filtersL = f;
                            await refetch({
                                variables: {
                                    customerId: customerId,
                                    broker_id: brokerId,
                                    take: takeL,
                                    filters: filtersL,
                                    id: randomString(7, 'aA')
                                }
                            });

                        }, FILTERS_TIMEOUT);
                    }}
                />

                <SortingState
                    defaultSorting={[
                        { columnName: 'creation_date', direction: 'desc' },
                    ]}
                />

                <IntegratedSorting
                    columnExtensions={[
                        {
                            columnName: 'insurance_type_cd',
                            compare: (a, b) => {
                                const labelA = getLabel(DictionaryTypes.InsuranceType, a);
                                const labelB = getLabel(DictionaryTypes.InsuranceType, b);
                                if (labelA === labelB) {
                                    return 0;
                                }
                                return (labelA < labelB) ? -1 : 1;
                            },
                        },
                        {
                            columnName: 'customer',
                            compare: (a, b) => {
                                const labelA = a.display_name;
                                const labelB = b.display_name;
                                if (labelA === labelB) {
                                    return 0;
                                }
                                return (labelA < labelB) ? -1 : 1;
                            },
                        },
                        {
                            columnName: 'state_cd',
                            compare: (a, b) => {
                                const labelA = getLabel(DictionaryTypes.InsuranceRequestState, a);
                                const labelB = getLabel(DictionaryTypes.InsuranceRequestState, b);
                                if (labelA === labelB) {
                                    return 0;
                                }
                                return (labelA < labelB) ? -1 : 1;
                            },
                        },
                        {
                            columnName: 'broker',
                            compare: (a, b) => {
                                const labelA = a.display_name;
                                const labelB = b.display_name;
                                if (labelA === labelB) {
                                    return 0;
                                }
                                return (labelA < labelB) ? -1 : 1;
                            },
                        },
                        {
                            columnName: 'creation_date',
                            compare: (a, b) => {
                                try {
                                    const labelA = new Date(a);
                                    const labelB = new Date(b);
                                    if (labelA === labelB) {
                                        return 0;
                                    }
                                    return (labelA < labelB) ? -1 : 1;
                                } catch (e) {
                                    console.error('sort in grid by dates');
                                    return 0;
                                }

                            },
                        },
                        {
                            columnName: 'ins_contract_date',
                            compare: (a, b) => {
                                try {
                                    const labelA = new Date(a);
                                    const labelB = new Date(b);
                                    if (labelA === labelB) {
                                        return 0;
                                    }
                                    return (labelA < labelB) ? -1 : 1;
                                } catch (e) {
                                    console.error('sort in grid by ins_contract_date');
                                    return 0;
                                }

                            },
                        },
                    ]}
                />

                <VirtualTable
                    messages={tableMessages}
                    rowComponent={({ ...props, row }) => {
                        if (isLoadMoreRow(row)) {
                            return (<VirtualTable.Row
                                {...props}
                                style={{ position: 'relative', height: 60 }}
                            >
                                {canLoadMore(requests.length, takeL, MAX_TAKE) &&
                                    <Button
                                        style={{
                                            width: 200,
                                            position: 'absolute',
                                            marginTop: 10,
                                            left: '50%',
                                            marginLeft: -100,
                                        }}
                                        variant='outlined'
                                        onClick={async () => {
                                            takeL = getNextTake([MIN_TAKE, MAX_TAKE], takeL);
                                            refetch({ variables: { take: takeL, filters: filtersL, id: randomString(7, 'aA') } });

                                        }}
                                    >
                                        {getTxt('Load More...')}

                                    </Button>
                                }

                                {filtersPromptVisible(requests.length, takeL, MAX_TAKE) &&
                                    <Typography
                                        variant="body1"
                                        style={{
                                            whiteSpace: 'normal',
                                            width: 350,
                                            position: 'absolute',
                                            marginTop: 20,
                                            left: '50%',
                                            marginLeft: -175,

                                        }}
                                    >
                                        {getTxt('Please, specify filters to load more rows.')}
                                    </Typography>
                                }

                            </VirtualTable.Row>);
                        }
                        return (<VirtualTable.Row {...props} />);
                    }}
                    cellComponent={({ row, column, style, ...props }) => {
                        if (column.name === 'insurance_type_cd' && !isLoadMoreRow(row)) {
                            // if (!authInfo.isCustomer()) {
                            return (<VirtualTable.Cell> <Link style={{ padding: '6px', color: '#9C27B0', fontWeight: 'bold' }} to={`/requests/${row.insurance_type_cd}/view/${row.insurance_request_id}`} >{getLabel(DictionaryTypes.InsuranceType, row.insurance_type_cd) + ' ' + row.insurance_request_id}</Link> </VirtualTable.Cell>);
                            //} else {
                            //  return (<VirtualTable.Cell> {getLabel(DictionaryTypes.InsuranceType, row.insurance_type_cd) + ' ' + row.insurance_request_id}</VirtualTable.Cell>);
                            //}
                        }
                        if (column.name === 'broker' && !isLoadMoreRow(row)) {
                            if (!authInfo.isCustomer()) {
                                return (<VirtualTable.Cell><Link to={`/brokers/${row.customer.broker.broker_id}`} >{row.customer.broker.display_name}</Link></VirtualTable.Cell>);
                            } else {
                                return (<VirtualTable.Cell>{row.customer.broker.display_name}</VirtualTable.Cell>);
                            }
                        }
                        if (column.name === 'customer' && !isLoadMoreRow(row)) {
                            if (!authInfo.isCustomer()) {
                                return (<VirtualTable.Cell> <Link to={`/customers/${row.customer.customer_type_cd.toLowerCase()}/${row.customer.customer_id}`} >{row.customer.display_name}</Link> </VirtualTable.Cell>);
                            } else {
                                return (<VirtualTable.Cell> {row.customer.display_name}</VirtualTable.Cell>);
                            }
                        }
                        if (column.name === 'state_cd' && !isLoadMoreRow(row)) {
                            return (<VirtualTable.Cell> {getLabel(DictionaryTypes.InsuranceRequestState, row.state_cd)} </VirtualTable.Cell>);
                        }
                        if (column.name === 'creation_date' && !isLoadMoreRow(row)) {
                            return (<VirtualTable.Cell>
                                {row.creation_date ? toItalianDateString(row.creation_date) : row.creation_date}
                            </VirtualTable.Cell>);
                        }
                        if (column.name === 'ins_contract_date' && !isLoadMoreRow(row)) {
                            return (<VirtualTable.Cell>
                                {row.ins_contract_date ? toItalianDateString(row.ins_contract_date) : row.ins_contract_date}
                            </VirtualTable.Cell>);
                        }
                        
                        if (column.name === 'insurance_request_id' && !isLoadMoreRow(row)) {
                            return (<VirtualTable.Cell>

                                <Tooltip title={getTxt('Delete')}>
                                    <IconButton size="small" onClick={() => { setDelId(row.insurance_request_id); }} className={classes.inGridButton} >
                                        <Icon.Delete />
                                    </IconButton>
                                </Tooltip>

                            </VirtualTable.Cell>);
                        }
                        if (column.name === LOAD_MORE_PROP && isLoadMoreRow(row)) {
                            return (<VirtualTable.Cell />);
                        }

                        return <VirtualTable.Cell {...props} />;
                    }}
                />

                <TableFilterRow
                    messages={filterRowMessages}
                    rowComponent={({ ...props }) => {
                        return (<VirtualTable.Row className={filtersL.length > 0 ? 'filterRowActive' : ''} {...props} />);
                    }}
                    cellComponent={({ column, filter, onFilter, ...props }) => {
                        if (column.name === 'insurance_type_cd') {
                            return (
                                <c.gridColFilter noFilterVal={getTxt('Tutti')} setFilter={onFilter} f={'insurance_type_cd'} filters={filtersL} >
                                    {getDictionary(DictionaryTypes.InsuranceType)}
                                </c.gridColFilter>);
                        }
                        if (column.name === 'state_cd') {
                            return (
                                <c.gridColFilter noFilterVal={getTxt('Tutti')} setFilter={onFilter} f={'state_cd'} filters={filtersL}>
                                    {getDictionary(DictionaryTypes.InsuranceRequestState)}
                                </c.gridColFilter>);
                        }

                        if (column.name === 'insurance_request_id') {
                            return <TableCell>{''}</TableCell>;
                        }

                        return <TableFilterRow.Cell onFilter={onFilter} filter={filter} {...props} />;
                    }}
                />
                <TableColumnResizing
                    columnWidths={сolumnWidths}
                    onColumnWidthsChange={(cols) => { setColumnWidths(cols); }}
                    defaultColumnWidths={defColumnWidths}
                />
                <TableHeaderRow />

            </Grid>

            <Dialog onClose={() => { setDelId(undefined); setDelNumber(undefined); }} open={delId}>
                <DialogTitle >{getTxt('Delete Request')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <span>{getTxt('Request will be delete on server.')}</span>
                        <br />
                        <span >{getTxt('Are you sure do you want delete the Request?')}</span>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDelete(delId)} color="primary">
                        {getTxt('Ok')}
                    </Button>
                    <Button onClick={() => { setDelId(undefined); }} color="primary">
                        {getTxt('Annula')}
                    </Button>

                </DialogActions>
            </Dialog>

        </div >
    );
}
