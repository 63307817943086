import React, { Component } from 'react';
import { getTxt } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import { Paper } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { AppBar } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { TextField } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';

import * as Icon from '@material-ui/icons';
import { Checkbox } from '@material-ui/core';

import { Button } from '@material-ui/core';
import moment from 'moment';
import { Badge } from '@material-ui/core';

import ComponentEx from 'components/ComponentEx';



import { LinearProgress } from '@material-ui/core';
import gql from 'graphql-tag';
import { graphql, compose } from 'react-apollo';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import { EntityTypes, getEntityDescriptor } from 'global/entityDescriptors';

import c from '../../../components/form/Components';
import LinearProgressEx from '../../../components/LinearProgressEx';
import { getLabel, DictionaryTypes, getDictionary, getValue } from '../../../global/dictionaryCache';
import MaterialForm from '../../../components/form/MaterialForm';
import MFormText from '../../../components/form/MFormText';
import { get_Requests_ById_Query, allRequestsQuery } from '../../../modules/request/requestQeries';


import GridEx from 'components/form/GridEx';
import RequestGuarantiesList from '../../../modules/request/RequestGuarantiesList';
import { ReportTypes } from '../../../global/reporting';

const styles = theme => ({
});


class Other_View_Detail_Form extends ComponentEx {

    constructor(props) {
        super(props);
        this.generatePdf = this.generatePdf.bind(this);
        props.generatePdfFunc.func = this.generatePdf;
    }

    generatePdf() {
        const newWindow = window.open("", "_blank");
        newWindow.document.write(getTxt('Generating PDF, please wait...'));
        this.props.getOneTimeToken({

        }, this).then(
            (resp) => {

                if (resp.data && resp.data.oneTimeTokenMutation && resp.data.oneTimeTokenMutation.token) {
                    newWindow.location.href = `${window.apiUrl}files/reports?ott=${resp.data.oneTimeTokenMutation.token}&type=${ReportTypes.Other_View_Details}&params=${btoa(JSON.stringify(window.requestDetailsPdfData))}`;
                    newWindow.focus();
                }

            });
    }

    render() {

        const { classes } = this.props;

        const request = this.props.request;

        window.requestDetailsPdfData = [];
        return (

            <MaterialForm
                dataSource={request}
                objType={EntityTypes.InsuranceRequestOther}
                noFocusOnFirstCtrl
                spacing={12}
                fields={this.props.fields}
            >
                <c.group
                    pdf
                    l="Informazioni Aggiuntive"
                    fields={this.props.fields}
                    child={[
                        'additional_information',
                        'comments',
                    ]}
                />

                <c.readOnlyNewLine f="additional_information" multiline pdf />
                <c.readOnlyNewLine f="comments" multiline pdf />
                <c.readOnlyInLine l="" f="comments" sm={12} multiline d pdf />
            </MaterialForm>

        );
    }
}

export default withStyles(styles)(Other_View_Detail_Form);
