import gql from 'graphql-tag';

export const emailTmplateByIdQuery = gql`

query emailTmplateByIdQuery($id: ID!) {
    emailTemplate(id: $id) {
        email_template_id
        template_name
        subject
        body
        from
    }
}

`;

 

export const allEmailTemplatesQuery = gql`

query allEmailTemplatesQuery {
    allEmailTemplates {
        email_template_id
        template_name
        subject
        body
        from
    }
  }

`;


export const updateEmailTemplateMutation = gql`

mutation updateEmailTemplateMutation ($template: email_template_input!) {
    updateEmailTemplate(template: $template){
        id
        validation {
            field
            message
        }
       
    } 
}

`;


