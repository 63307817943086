import React, { Component } from 'react';
import { getTxt, toItalianDateString } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';
import { withTheme } from '@material-ui/core';

import { Typography } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';



import { Paper } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { AppBar } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { TextField } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import gql from 'graphql-tag';
import { graphql, compose } from 'react-apollo';
import { Tabs, Tab } from '@material-ui/core';
import * as Icon from '@material-ui/icons';
import GridEx from 'components/form/GridEx';

import { Button } from '@material-ui/core';
import moment from 'moment';
import { Badge } from '@material-ui/core';

import ComponentEx from 'components/ComponentEx';

import { queryGetCustomerById, customerBriefQuery, allCustomersQuery } from '../../modules/client/clientQueries';
import c from '../../components/form/Components';
import LinearProgressEx from '../../components/LinearProgressEx';
import { getLabel, DictionaryTypes, getDictionary } from '../../global/dictionaryCache';
import MaterialForm from '../../components/form/MaterialForm';
import MFormText from '../../components/form/MFormText';



const styles = theme => ({
    button: {
        // margin: theme.spacing.unit,
    },

    badge: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        marginTop: 0,
    },

    gridRoot: {
        height: '100%',
    },


    formCaption: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit * 2,
        width: '100%',
        position: 'relative',
        height: 40,
    },
    formCaptionLabel: {
        position: 'absolute',
        //color: theme.palette.primary1,
        backgroundColor: 'transparent',
        left: 25,
        paddingLeft: 10,
        paddingRight: 10,
    },

    rootPaper: theme.mixins.gutters({

        paddingTop: 0,
        paddingBottom: 0,
        marginTop: 0,// theme.spacing.unit * 3,

    }),

});



class Client_Privat_Details_View extends ComponentEx {
    state = {
        value: 0,
    };

    handleChange = (event, value) => {
        this.setState({ value });
    };


    render() {

        if (this.props.quiet) {
            return (<LinearProgressEx loading={this.props.data.loading} />);
        }

        const { classes } = this.props;
        const loading = this.props.data.loading;
        const customer = this.props.data.customer;

        let birth_country_cd;
        let birth_province;
        if (customer) {
            birth_country_cd = this.state.birth_country_cd ? this.state.birth_country_cd : customer.birth_country_cd;
            birth_province = this.state.birth_province ? this.state.birth_province : customer.birth_province;
        }

        let address_country_cd;
        let address_province;
        if (customer) {
            address_country_cd = this.state.address_country_cd ? this.state.address_country_cd : customer.address_country_cd;
            address_province = this.state.address_province ? this.state.address_province : customer.address_province;
        }

        return (

            <div>
                <LinearProgressEx loading={loading} />
                {!loading &&
                    <MaterialForm spacing={2} dataSource={customer} objType="customer" >

                        <GridEx item sm={4} xs={12}>
                            <MaterialForm spacing={12} dataSource={customer} objType="customer" >
                                <c.group l="Dati personali" d />

                                <c.readOnlyInLine l="Codice Interno" f="customer_id" d />
                                <c.readOnlyInLine l="Titolo" f="title_cd" v={getLabel(DictionaryTypes.Title, customer.title_cd)} d />

                                <c.readOnlyInLine l="Nome" f="first_name" />

                                <c.readOnlyInLine l="Cognome" f="last_name" d />
                                <c.readOnlyInLine l="Sesso" f="sex_cd" v={getLabel(DictionaryTypes.Sex, customer.sex_cd)} d />
                                <c.readOnlyInLine l="IBAN" f="bank_iban" d />
                                <c.readOnlyInLine l="Codice Fiscale" f="fiscal_code" />
                                {customer.broker && <c.readOnlyInLine l="Collaboratore" f="owner_broker_id" v={customer.broker.display_name} type="STRING" d />}
                            </MaterialForm>
                        </GridEx>

                        <GridEx item sm={4} xs={12}>
                            <MaterialForm spacing={12} dataSource={customer} objType="customer" >
                                <c.group l="Nascita" d />
                                <c.readOnlyInLine l="Data di Nascita" f="birth_date" v={customer.birth_date} />
                                <c.readOnlyInLine lxs={7} vxs={5} l="Nazione di Nascita" f="birth_country_cd" v={getLabel(DictionaryTypes.Country, customer.birth_country_cd)} d />
                                {birth_country_cd === 'IT' &&
                                    <c.readOnlyInLine l={getTxt('Province di Nascita')} f="birth_province" v={getLabel(DictionaryTypes.ItalianProvinces, customer.birth_province)} d />
                                }
                                <c.readOnlyInLine l="Città di Nascita" f="birth_city" d />
                            </MaterialForm>
                        </GridEx>

                        <GridEx item sm={4} xs={12}>
                            <MaterialForm spacing={12} dataSource={customer} objType="customer" >
                                <c.group l="Indirizzo" d />

                                <c.readOnlyInLine l="Nazione" f="address_country_cd" v={getLabel(DictionaryTypes.Country, customer.address_country_cd)} />
                                {address_country_cd === 'IT' &&
                                    <c.readOnlyInLine l="Provincia" f="address_province" v={getLabel(DictionaryTypes.ItalianProvinces, customer.address_province)} d />
                                }
                                {address_country_cd !== 'IT' &&
                                    <c.readOnlyInLine l={getTxt('Province')} f="address_province" />
                                }
                                <c.readOnlyInLine l="Città" f="address_city" d />
                                <c.readOnlyInLine l="CAP" f="address_cap" d />
                                <c.readOnlyInLine l="Indirizzo" f="address_street" d />
                                <c.readOnlyInLine l="Civico" f="address_street_number" d />
                            </MaterialForm>
                        </GridEx>


                        <GridEx item sm={4} xs={12}>
                            <MaterialForm spacing={12} dataSource={customer} objType="customer" >
                                <c.group l="Informazioni Contatti" d />
                                <c.readOnlyInLine l="Telefono" f="phone" d />
                                <c.readOnlyInLine l="Telefono Attività" f="phone_active" d />
                                <c.readOnlyInLine l="Cellulare" f="mobile_phone" d />
                                <c.readOnlyInLine l="Email" f="email" d />
                                <c.readOnlyInLine l="PEC" f="email_pec" d />
                            </MaterialForm>
                        </GridEx>

                        <GridEx item sm={4} xs={12}>
                            <MaterialForm spacing={12} dataSource={customer} objType="customer" >
                                <c.group l="Documenti personali" d />
                                <c.readOnlyInLine l="Tipo. doc. riconoscimento" f="id_card_type_cd" v={getLabel(DictionaryTypes.IdCardType, customer.id_card_type_cd)} d />
                                <c.readOnlyInLine l="Num. doc. riconoscimento" f="id_card" d />
                                <c.readOnlyInLine l="Ente Rilascio" f="id_card_release" d />
                                <c.readOnlyInLine l="Data di Rilascio" f="id_release_date" d />
                                <c.readOnlyInLine l="Data Scadenza" f="id_card_expiration_date" d />

                            </MaterialForm>
                        </GridEx>


                        <GridEx item sm={4} xs={12}>
                            <MaterialForm spacing={12} dataSource={customer} objType="customer" >
                                <c.group l="Informazioni Aggiuntive" d />
                                <c.readOnlyInLine l="Attività Svolta" f="business_occupation" v={getLabel(DictionaryTypes.BusinessOccupation, customer.business_occupation)} d />
                                <c.readOnlyInLine l="Componenti nucleo familiari" f="dependent_family_members" d />
                                <c.readOnlyInLine l={getTxt('Type of Property')} f="living_property_type" v={getLabel(DictionaryTypes.PropertyType, customer.living_property_type)} d />
                                <c.readOnlyInLine l={getTxt('Type of Pet')} f="type_of_pet" v={getLabel(DictionaryTypes.PetType, customer.type_of_pet)} d />

                                <c.readOnlyInLine l={getTxt('Communication Agree')} f="communication_agree" d />
                                <c.readOnlyInLine l="Non inviare comunicazioni automatiche" f="communication_unsubscribed" d />
                            </MaterialForm>
                        </GridEx>


                        <c.group l={getTxt('Notes')} d />
                        <GridEx item sm={12} xs={12}>
                            <MaterialForm spacing={12} dataSource={customer} objType="customer" >
                                <c.readOnlyInLine l="" f="internal_notes" d />
                            </MaterialForm>
                        </GridEx>


                    </MaterialForm>
                }
            </div>

        );
    }
}

export default graphql(queryGetCustomerById,
    {
        options: props => ({
            variables: { id: props.customerId },
            fetchPolicy: 'network-only',
            options: {
                errorPolicy: 'all',
            },
        }),
    })(withStyles(styles, true)(Client_Privat_Details_View));

