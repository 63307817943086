import React, { Component } from 'react';
import { getTxt, randomString } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';
import { withTheme } from '@material-ui/core';

import { Typography } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';



import { Paper } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { AppBar } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { TextField } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import gql from 'graphql-tag';
import { graphql, compose } from 'react-apollo';
import { Tabs, Tab } from '@material-ui/core';
import * as Icon from '@material-ui/icons';
import GridEx from 'components/form/GridEx';




import { Button } from '@material-ui/core';
import moment from 'moment';
import { Badge } from '@material-ui/core';
import { authInfo } from 'global/auth';

import ComponentEx from 'components/ComponentEx';

import { queryGetCustomerById, customerBriefQuery, allCustomersQuery } from '../../modules/client/clientQueries';
import c from '../../components/form/Components';
import LinearProgressEx from '../../components/LinearProgressEx';
import { getLabel, DictionaryTypes, getDictionary, getValue } from '../../global/dictionaryCache';
import MaterialForm from '../../components/form/MaterialForm';
import MFormText from '../../components/form/MFormText';
import * as queries from '../../modules/broker/brokerQueries';
import { getUserRoles, Roles } from '../../global/auth';

import { Dialog } from '@material-ui/core';
import { DialogTitle } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { DialogContentText } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing.unit * 2,
        margin: theme.spacing.unit * 2,
    },

    button: {
        margin: theme.spacing.unit,
    },

    badge: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        marginTop: 0,
    },

    gridRoot: {
        height: '100%',
    },


    formCaption: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit * 2,
        width: '100%',
        position: 'relative',
        height: 40,
    },
    formCaptionLabel: {
        position: 'absolute',
        backgroundColor: 'transparent',
        left: 25,
        paddingLeft: 10,
        paddingRight: 10,
    },

    rootPaper: theme.mixins.gutters({

        paddingTop: 0,
        paddingBottom: 0,
        marginTop: 0,// theme.spacing.unit * 3,

    }),

});

let history;
class Broker_Details_Edit extends ComponentEx {

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.fornChanged = this.fornChanged.bind(this);
        this.changePassword = this.changePassword.bind(this);
    }

    formGetValues = { func: undefined };

    state = {
        birth_country_cd: undefined,
        birth_province: undefined,
        address_country_cd: undefined,
        address_province: undefined,
    };

    onSubmit(e, { obj, showErrors, hideErrors }) {

        history = this.props.history;
        obj.broker_id = this.props.data.broker.broker_id;

        if (obj.birth_city === this.props.data.broker.birth_city) {
            delete obj.birth_city;
        }
        if (obj.address_city === this.props.data.broker.address_city) {
            delete obj.address_city;
        }

        const this1 = this;
        this.props.updateBroker({
            variables: { broker: obj },

            refetchQueries: [
                {
                    query: queries.allBrokersQuery,
                },
                {
                    query: queries.brokerByIdQuery,
                    variables: { id: this.props.data.broker.broker_id },
                },
            ],
        }, this).then(
            (resp) => {
                hideErrors();
                const serverErrors = resp.data.updateBrokerMutation.validation;
                const id = resp.data.updateBrokerMutation.id;

                if (serverErrors && serverErrors.length > 0) {
                    showErrors(serverErrors);
                } else {
                    if (window.refetchBrokerView) {
                        window.refetchBrokerView({ id });
                    }
                    if (!this1.props.isProfile) {
                        history.push(`/brokers/${id}`);
                    } else {
                        this1.setState({ okDlg: true });
                    }

                }
            });
    }

    changePassword(id) {
        const values = this.formGetValues.func();
    }

    fornChanged(field, value, type, prevVal) {
        if (field === 'birth_country_cd') {
            const country = getValue(DictionaryTypes.Country, value);
            if (country) {
                this.setState({ birth_country_cd: country });
            }
        }
        if (field === 'birth_province') {
            const prov = getValue(DictionaryTypes.ItalianProvinces, value);
            if (prov) {
                this.setState({ birth_province: prov });
            }
        }
        if (field === 'address_country_cd') {
            const country = getValue(DictionaryTypes.Country, value);

            if (country) {
                this.setState({ address_country_cd: country });
            }
        }
        if (field === 'address_province') {
            const prov = getValue(DictionaryTypes.ItalianProvinces, value);
            if (prov) {
                this.setState({ address_province: prov });
            }
        }
    }

    render() {

        const { classes } = this.props;
        const loading = this.props.data.loading;
        const broker = this.props.data.broker;
        let birth_country_cd;
        let birth_province;
        if (broker) {
            birth_country_cd = this.state.birth_country_cd ? this.state.birth_country_cd : broker.birth_country_cd;
            birth_province = this.state.birth_province ? this.state.birth_province : broker.birth_province;
        }

        let address_country_cd;
        let address_province;
        if (broker) {
            address_country_cd = this.state.address_country_cd ? this.state.address_country_cd : broker.address_country_cd;
            address_province = this.state.address_province ? this.state.address_province : broker.address_province;
        }

        let caption;

        if (this.props.match && this.props.match.params.id === 'new') {
            caption = getTxt('Nuovo Collaboratore');
        } else {
            caption = getTxt('Modifica Collaboratore');
        }

        if (this.props.isProfile) {
            caption = getTxt('Broker Profile');
        }

        // const userRoles = getUserRoles();
        // let brokersTypes = [];
        // if (userRoles.find((r) => { return (r === Roles.AgencyAdministrator || r === Roles.MasterBroker) })) {
        //     brokersTypes = getDictionary(DictionaryTypes.BrokerType);
        // }

        let brokersTypes = getDictionary(DictionaryTypes.BrokerType);
        if (authInfo.isMasterBroker()) {
            brokersTypes = brokersTypes.filter(i => i.value !== 'MasterBroker');
        }
        if (authInfo.isBroker()) {
            brokersTypes = brokersTypes.filter(i => i.value !== 'MasterBroker');
        }

        // if (authInfo.isAgencyAdministrator() || authInfo.isMasterBroker()) {
        //     brokersTypes = getDictionary(DictionaryTypes.BrokerType);
        // } else if (authInfo.isBroker()) {
        //     brokersTypes = getDictionary(DictionaryTypes.BrokerType).filter(i => i.value === 'SubBroker');
        // }

        return (

            <div className={classes.root}>
                <Typography variant="h4" color="inherit" noWrap={false}>{getTxt(caption)}</Typography>
                <LinearProgressEx loading={loading} />
                {!loading &&
                    <div>
                        <MaterialForm dataSource={broker} objType="broker" onSubmit={this.onSubmit} onChange={this.fornChanged} getValues={this.formGetValues}>
                            <c.group l={getTxt('Dati personali')} />
                            <c.field l={getTxt('Nome / Ragione Sociale')} f="first_name" />
                            <c.field l={getTxt('Cognome / Collaboratore')} f="last_name" />


                            {this.props.match && this.props.match.params.id === 'new' &&
                                <c.field l={getTxt('Password')} f="password" type="password" />
                            }
                            {this.props.match && this.props.match.params.id === 'new' &&
                                <c.field l={getTxt('Confirm Password')} f="password2" type="password" />
                            }

                            {this.props.match && this.props.match.params.id !== 'new' &&
                                <c.field l={getTxt('New Password')} f="password_new" type="password" />
                            }

                            {this.props.match && this.props.match.params.id !== 'new' &&
                                <c.field l={getTxt('Confirm New Password')} f="password_new2" type="password" />
                            }

                            {this.props.isProfile &&
                                <c.field l={getTxt('New Password')} f="password_new" type="password" />
                            }

                            {this.props.isProfile &&
                                <c.field l={getTxt('Confirm New Password')} f="password_new2" type="password" />
                            }

                            <c.field f="sex_cd" ui="select" >
                                {getDictionary(DictionaryTypes.Sex)}
                            </c.field>

                            {!authInfo.isBroker() && <c.field l={getTxt('Tupo')} f="broker_type_cd" ui="select" >
                                {brokersTypes}
                            </c.field>}
                            {authInfo.isBroker() && <c.field l={getTxt('Tupo')} f="broker_type_cd" d v={getValue(DictionaryTypes.BrokerType, broker.broker_type_cd)} />}

                            {!authInfo.isBroker() && <c.field l={getTxt('Stato')} f="state_cd" ui="select" >
                                {getDictionary(DictionaryTypes.BrokerState)}
                            </c.field>}
                            {authInfo.isBroker() && <c.field l={getTxt('Stato')} f="state_cd" d v={getValue(DictionaryTypes.BrokerState, broker.state_cd)} />}
                            <c.field l={getTxt('Codice Collaboratore')} f="company_name" />

                            <c.group l={getTxt('Address')} />
                            <c.field
                                l={getTxt('Luogo Nascita')}
                                f="birth_country_cd"
                                ui="auto-select"
                            >
                                {getDictionary(DictionaryTypes.Country)}
                            </c.field>
                            {birth_country_cd === 'IT' &&
                                <c.field
                                    l={getTxt('Province di Nascita')}
                                    f="birth_province"
                                    ui="auto-select"
                                    type={DictionaryTypes.ItalianProvinces}
                                >
                                    {getDictionary(DictionaryTypes.ItalianProvinces)}
                                </c.field>}
                            {birth_country_cd === 'IT' &&
                                <c.field
                                    provinceId={birth_province}
                                    l={getTxt('Città di Nascita')}
                                    f="birth_city"
                                    ui="city-auto-select"
                                />}
                            {birth_country_cd !== 'IT' &&
                                <c.field l={getTxt('Città di Nascita')} f="birth_city" />
                            }



                            <c.field
                                l={getTxt('Nazione')}
                                f="address_country_cd"
                                ui="auto-select"
                            >
                                {getDictionary(DictionaryTypes.Country)}
                            </c.field>


                            {address_country_cd === 'IT' &&
                                <c.field
                                    l={getTxt('Address Province')}
                                    f="address_province"
                                    ui="auto-select"
                                    type={DictionaryTypes.ItalianProvinces}
                                >
                                    {getDictionary(DictionaryTypes.ItalianProvinces)}
                                </c.field>}
                            {address_country_cd === 'IT' &&
                                <c.field
                                    provinceId={birth_province}
                                    l={getTxt('Address Città')}
                                    f="address_city"
                                    ui="city-auto-select"
                                />}
                            {address_country_cd !== 'IT' &&
                                <c.field l={getTxt('Address Città')} f="address_city" />
                            }
                            <c.field l={getTxt('CAP')} f="address_cap" />
                            <c.field l={getTxt('Indirizzo')} f="address_street" />
                            <c.field l={getTxt('Numero Civico')} f="number_street" />


                            <c.group l={getTxt('Informazioni Contatti')} />
                            <c.field l={getTxt('Telefono')} f="phone" />
                            <c.field l={getTxt('Cellulare')} f="mobile_phone" />
                            <c.field l={getTxt('Email')} f="email" />
                            <c.field l={getTxt('PEC')} f="email_pec" />
                            <c.field l={getTxt('Fax')} f="fax" />

                            <c.group l={getTxt('Informazioni Aggiuntive')} />

                            <c.field l={getTxt('Partita IVA')} f="vat_number" />
                            <c.field l={getTxt('Data di Nascita')} f="birth_date" />
                            <c.field
                                l="Codice Fiscale"
                                f="fiscal_code"
                                ui="fiscal_code"

                                labelShrink
                                firstNameField="first_name"
                                lastNameField="last_name"
                                cityField="birth_city"
                                dateField="birth_date"
                                sexField="sex_cd"
                            />
                            <c.field l={getTxt('IBAN')} f="bank_iban" ui="iban" />
                            <c.field l={getTxt('Data inizio accordo')} f="agreement_start_date" />
                            <c.field l={getTxt('Rui number')} f="rui_number" />
                            <c.field l={getTxt('Percentuale Ritenute')} f="withholdings_percent" />

                            <c.formButtons sm={12} onCancel={() => { this.props.history.goBack(); }} />
                        </MaterialForm>

                    </div>
                }


                <Dialog
                    className={classes.dialog}
                    open={this.state.okDlg}
                    onClose={() => {
                        this.setState({ okDlg: undefined });
                    }}
                >
                    <DialogTitle> <span className={classes.dialogText}>{getTxt('Broker Profile')}</span></DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <span className={classes.dialogText}>{getTxt('The broker profile saved successfully.')}</span>
                        </DialogContentText>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { this.setState({ okDlg: false }); }} color="primary" >
                            {getTxt('Ok')}
                        </Button>

                    </DialogActions>
                </Dialog>


            </div>

        );
    }
}

function getId(props) {
    if (!props.isProfile) {
        return props.match.params.id ? props.match.params.id : -1;
    }
    return -1;
}

export default compose(
    graphql(queries.brokerByIdQuery, {
        options: props => ({
            variables: { id: getId(props) },
            fetchPolicy: 'network-only',
            options: {
                errorPolicy: 'all',
            },
        }),
    }),

    graphql(queries.updateBrokerMutation, {
        name: 'updateBroker',
        options: {
            errorPolicy: 'all',
        },
    }),

)(withStyles(styles)(Broker_Details_Edit));
