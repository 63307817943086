import gql from 'graphql-tag';


const gridFields = `
insurance_request_id
insurance_type_cd
state_cd
creation_date
customer {
  customer_id
  display_name
  customer_type_cd
  broker {
    broker_id
    display_name
    broker_type_cd
  }
}
`;

export const allRequestsQuery = gql`

query allRequestsQuery($customerId: Int, $brokerId: Int, $stateFilter: String) {
  allRequests (customerId: $customerId, brokerId: $brokerId, stateFilter: $stateFilter) {  
    ins_request_veicoli {
        ${gridFields.replace('creation_date', ' creation_date ins_contract_date ')}
    }
  

    ins_request_cauzione {
        ${gridFields}
    }
    ins_request_cose {
        ${gridFields}
    }
    ins_request_persone {
        ${gridFields}
    }
    ins_request_other {
        ${gridFields}
    }


    typesPresents
  }
}`;


export const allRequestsQuery2 = gql`

query allRequestsQuery2($customerId: Int, $brokerId: Int, $take: Int, $filters: [GridFilters], $id: ID! ) {
  allRequests2 (customerId: $customerId, brokerId: $brokerId, take: $take, filters: $filters, id: $id) {  
    ins_request_veicoli {
        ${gridFields.replace('creation_date', ' creation_date ins_contract_date ')}
    }
  

    ins_request_cauzione {
        ${gridFields}
    }
    ins_request_cose {
        ${gridFields}
    }
    ins_request_persone {
        ${gridFields}
    }
    ins_request_other {
        ${gridFields}
    }


    typesPresents
  }
}`;

export const requestCommonFields = `
    insurance_request_id
    state_cd
    customer_id
    additional_information
    comments
    insurance_type_cd
    creation_date
`;

export const customerAndContractFields = `
customer {
    customer_id
    display_name
    customer_type_cd
    broker {
    broker_id
    display_name
    }
}
contract{
    insurance_contract_id
}
`;

export const viecoliFields = `

    vehicle_type
    vehicle_brand_and_model
    vehicle_cilinders
    vehicle_cv_ql
    vehicle_kw
    vehicle_immatricolazione
    vehicle_targa_matricola
    vehicle_value
    vehicle_approval
    vehicle_engine_type
    vehicle_use
    vehicle_used_by
    vehicle_expiry_limit
    vehicle_towable_weight
    vehicle_antitheft_type
    vehicle_alarm_satellite_alarm_brand_model
    prev_company
    prev_company_division
    prev_cv_prov
    prev_cu_ass
    prev_contract_expiry
    ins_maximum
    ins_km_annul
    ins_period
    ins_guide_type
    ins_convention_description
    ins_franchise
    ins_contract_date
    ins_opt_theft_and_fire
    ins_opt_accidents
    ins_opt_assistance
    ins_opt_gps
    ins_opt_bersani
    sic_atrc_data
`;


export const causioneFields = `
                beneficiary_name
                beneficiary_fiscal_code
                born_date
                citizenship
                passport_number
                issue_date
                expiration_date
                visa_number
                address_country_cd
                address_province
                address_city
                address_street
                address_street_number
                address_cap

                cig_number
                tender_start_date
                concessione_date
                proposal_date
                guarantee_duration_days
                guarantee_expiration
                execution_place
                work_total_amount
                discount_percentage
                guaranteed_percentage
                guaranteed_amount
                work_description
                
                co_obligated_customer
                authenticated_contract
                digital_contract
                ati_contract

`;

export const coseFields = `
            piani_fuori_terra
            
            numero_fabbricati
            numero_unita_abitative
            anno_di_costruzione
            anno_di_ricostruzione
            somma_assicurata
            franchigia_frontale

            quota_assicurata
            tipo_fabbricato
            indicizzazione
            inizio_lavori
            fine_lavori
            decorrenza_polizza_decennale
            committente
            appaltatore
            tipologia_lavori
            descrizione_lavori
            numero_contratto_appalto
            costo_complessivo_opera
            numero_questionario
            ubicazione_del_rischio
            valore_fabbricat
            valore_contenuto
            impianto_di_allarme
            contratto_soggetto_regolazione

`;

export const personeFields = `
        durata_contratto_in_anni
        persone_assicurate
        fumatore
        peso
        altezza
        pressione_arteriosa_min
        pressione_arteriosa_max
        patologie_in_essere
        patologie_nucleo_familiare
        beneficiario_caso_morte
        capitale_versato
        controvalore
        caricamenti
        capitale_investito
        prestazione_caso_morte
        vincolo
        pegno
        note_aggiuntive

`;

export const get_Requests_ById_Query = gql`

query get_Requests_ById_Query($id: ID!, $customerId: Int, $insType: String!, $publicInsType: String, $getMeta: Boolean) {
    get_request_by_id(id: $id, customerId: $customerId, insType: $insType, publicInsType: $publicInsType, getMeta: $getMeta) {
        ins_request_veicoli {
            ${requestCommonFields}
            ${viecoliFields}
            ${customerAndContractFields}
        }


        
        ins_request_cauzione {
                ${requestCommonFields}
                ${causioneFields}
                ${customerAndContractFields}
            }
        ins_request_cose {
                ${requestCommonFields}
                ${coseFields}
                ${customerAndContractFields}
        }
        ins_request_persone {
                ${requestCommonFields}
                ${personeFields}
                ${customerAndContractFields}
            }
        ins_request_other {
                ${requestCommonFields}
                ${customerAndContractFields}
            }
        guarantiesCount
        defaultGuarantiesCount
        fields
    }
  }
  

`;



export const requestBriefQuery = gql`

query get_Requests_ById_Query($id: ID!, $customerId: Int, $insType: String!, $publicInsType: String, $getMeta: Boolean) {
    get_request_by_id(id: $id, customerId: $customerId, insType: $insType, publicInsType: $publicInsType, getMeta: $getMeta) {
        ins_request_veicoli {
        ${requestCommonFields}
        ${customerAndContractFields}
    }

    ins_request_cauzione{
        ${requestCommonFields}
        ${customerAndContractFields}
    }

    ins_request_cose{
        ${requestCommonFields}
        ${customerAndContractFields}
    }
    ins_request_persone{
        ${requestCommonFields}
        ${customerAndContractFields}
    }
    ins_request_other{
        ${requestCommonFields}
        ${customerAndContractFields}
    }
    guarantiesCount
    defaultGuarantiesCount
    fields
    }
  }
  

`;



export const update_Request_Mutation = gql`

mutation UpdateRequestMutation($request: ins_request_input!) {
    update_Request_Mutation(request: $request) {
      id
      validation {
        field
        message
      }
    }
  }

  `;


export const deleteRequestMutation = gql`

mutation deleteRequestMutation ($requestId: ID!) {
    deleteRequest(requestId: $requestId){
        id
        validation {
            field
            message
        }
    } 
}

`;



