import React, { Component } from 'react';
import gql from 'graphql-tag';
import { graphql, compose } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import $ from 'jquery';

import ComponentEx from 'components/ComponentEx';
import {
    SortingState, SelectionState, FilteringState, GroupingState,
    IntegratedFiltering, IntegratedGrouping, IntegratedSorting, IntegratedSelection,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    VirtualTable, TableHeaderRow, TableFilterRow, TableSelection, TableGroupRow,
    GroupingPanel, DragDropProvider, TableColumnReordering, Toolbar, TableColumnResizing,
} from '@devexpress/dx-react-grid-material-ui';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Tooltip } from '@material-ui/core';
import { Select } from '@material-ui/core';
import c from 'components/form/Components';
import { Checkbox } from '@material-ui/core';
import { getToken } from 'global/auth';
import { ReportTypes } from 'global/reporting';
import { HashRouter, BrowserRouter, Route, Link, withRouter } from 'react-router-dom';
//import pdfMake from 'pdfmake';
import { getTxt, tableMessages, createGridResizerCols } from '../../global/appGlobal';
import LinkToCustomerById from '../../components/LinksToObjectById';
import LinearProgressEx from '../../components/LinearProgressEx';
import { getLabel, getDictionary, DictionaryTypes } from '../../global/dictionaryCache';
import { allCompaniesQuery, deleteCompanyMutation } from '../../modules/company/CompanyQueries';
import { Menu, MenuItem } from '@material-ui/core';
import RegularCard from '../../components/dashboard/RegularCard';
import { addAuthListener, authInfo, initFromCookie, logout } from 'global/auth';
import * as Icon from '@material-ui/icons';

function getIsSelected(id, selectedId, selectedIds) {
    if (selectedIds && selectedIds.length) {
        return selectedIds.find(i => i === id);
    }
    return id === selectedId + '';
}

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        margin: theme.spacing.unit * 3,

    },
    button: {
        marginRight: theme.spacing.unit,
    },

    tableHeader: {
        backgroundColor: theme.palette.background.paper,
    }
});


const toLowerCase = value => String(value).toLowerCase();
const filterByBroker = (value, filter) => {
    const name = toLowerCase(value.display_name);
    return name.includes(toLowerCase(filter.value));
};
const getBrokerPredicate = columnName => (columnName === 'owner_broker_id' ? filterByBroker : undefined);

class Company_Search extends ComponentEx {

    state = {
        anchorEl: null,
        open: false,
        selectedId: undefined,
        selectedIds: [],
        defColumnWidths: { address_province: 100 },
    };

    constructor(props) {
        super(props);

        this.tableCellTemplate = ({ row, column, style }) => {
            if (column.name === 'display_name') {
                return (<VirtualTable.Cell> <Link to={`/customers/${row.insurance_company_id}`}   >  {row.company_name}  </Link> </VirtualTable.Cell>);
            }
            return undefined;
        };

        this.getRowId = row => row.insurance_company_id;
        this.newClick = this.newClick.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    componentDidMount() {
        $("table[class*='Table-headTable']").addClass(this.props.classes.tableHeader);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ selectedId: nextProps.selectedId });
        this.setState({ selectedIds: nextProps.selectedIds ? JSON.parse(nextProps.selectedIds) : [] });
    }

    newClick(event) {
        this.setState({ open: true, anchorEl: event.currentTarget });
    }

    handleRequestClose = () => {
        this.setState({ open: false });
    };

    handleDelete() {
        this.props.deleteCompany({
            variables: { id: this.state.delId },
            refetchQueries: [
                {
                    query: allCompaniesQuery,
                },
            ],
        }, this).then(
            (resp) => {
                this.setState({ delId: undefined, delNumber: undefined });
            });
    }

    render() {
        const { classes } = this.props;

        let allCompanies = this.props.data.allCompanies ? this.props.data.allCompanies : [];
        allCompanies = allCompanies.filter(i => !i.is_deleted);

        let columns = [

            { name: 'company_name', title: getTxt('Nome', '#zx2793o') },
            { name: 'address_city', title: getTxt('Città', '#ncn5gek') },
            { name: 'address_province', title: getTxt('Province', '#7740j51') },
            { name: 'mobile_phone', title: getTxt('Mobile Phone', '#tmjcd9g') },
            { name: 'phone', title: getTxt('Phone', '#m3wenhf') },
            
        ];

        if (this.props.showSelects) {
            columns = [{ name: 'insurance_company_id', title: getTxt('Selected') }].concat(columns);
        }

        if (authInfo.isAgencyAdministrator() || authInfo.isMasterBroker()) {
            columns.push({ name: 'is_deleted', title: getTxt('Actions') });
        }

        createGridResizerCols(this.state, columns, {}, 280);

        const onSelectedChanged = this.props.onSelectedChanged ? this.props.onSelectedChanged.bind(this) : undefined;
        const setState = this.setState.bind(this);
        const state = this.state;
        const multiselect = this.props.multiselect;

        return (

            <div className={classes.root}>
                <LinearProgressEx loading={this.props.data.loading} />
                {!this.props.noCaption && <RegularCard

                    headerColor="red"
                    cardTitle={getTxt('Companies')}
                    cardSubtitle={getTxt('List of insurance companies')}
                    content={
                        <div>
                            {!this.props.noAddButton && (authInfo.isAgencyAdministrator() || authInfo.isMasterBroker()) &&
                                <Link to="/company/edit/new">
                                    <Button color="primary" className={classes.button} on >{getTxt('Add new Company')}</Button>
                                </Link>}
                        </div>

                    }
                />}





                <Grid

                    rows={allCompanies}
                    columns={columns}

                >


                    <FilteringState
                        defaultFilters={[{}]}
                    />

                    <SortingState
                        defaultSorting={[
                            { columnName: 'Nome', direction: 'asc' },
                        ]}
                    />

                    <IntegratedSorting />





                    <VirtualTable
                        messages={tableMessages}
                        cellComponent={({ row, column, style, ...props }) => {
                            if (column.name === 'insurance_company_id') {
                                return (<VirtualTable.Cell>
                                    <Checkbox
                                        onChange={(e, val) => {
                                            if (multiselect) {

                                                const without = state.selectedIds.filter(id => id !== row.insurance_company_id);

                                                if (val) {
                                                    without.push(row.insurance_company_id);
                                                }
                                                setState({ selectedIds: without });
                                                if (onSelectedChanged) {
                                                    onSelectedChanged(without);
                                                }

                                            } else {
                                                setState({ selectedId: val ? row.insurance_company_id : undefined });
                                                if (onSelectedChanged) {
                                                    onSelectedChanged(val ? row.insurance_company_id : undefined);
                                                }
                                            }


                                        }}
                                        checked={getIsSelected(row.insurance_company_id, state.selectedId, state.selectedIds)}
                                    />
                                </VirtualTable.Cell>);
                            }
                            if (column.name === 'company_name' && (authInfo.isAgencyAdministrator() || authInfo.isMasterBroker())) {
                                return (<VirtualTable.Cell><Link to={`/company/view/${row.insurance_company_id}`} >{row.company_name}</Link></VirtualTable.Cell>);
                            }
                            if (column.name === 'is_deleted' && (authInfo.isAgencyAdministrator() || authInfo.isMasterBroker())) {
                                return (<VirtualTable.Cell>

                                    <Tooltip title={getTxt('Delete')}>
                                        <IconButton size="small" onClick={() => { this.setState({ delId: row.insurance_company_id, delNumber: row.company_name }); }} className={classes.inGridButton} >
                                            <Icon.Delete fontSize="small" />
                                        </IconButton>
                                    </Tooltip>

                                </VirtualTable.Cell>);
                            }

                            return <VirtualTable.Cell {...props} />;
                        }}
                    />
                    <TableColumnResizing
                        columnWidths={this.state.columnWidths}
                        onColumnWidthsChange={(cols) => { this.setState({ columnWidths: cols }); }}
                        defaultColumnWidths={this.state.defColumnWidths}
                    />
                    <TableHeaderRow showSortingControls />

                </Grid>

                <Dialog onClose={() => { this.setState({ delId: undefined, delNumber: undefined }); }} open={this.state.delId}>
                    <DialogTitle >{getTxt('Cancella compagnia')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <span>{getTxt('La compagnia sarà cancellata sul server.')}</span>
                            <br />
                            <span >{getTxt('Sei sicuro di voler cancellare la compagnia?')}</span>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleDelete(this.state.delId)} color="primary">
                            {getTxt('Ok')}
                        </Button>
                        <Button onClick={() => { this.setState({ delId: undefined, delNumber: undefined }); }} color="primary">
                            {getTxt('Annula')}
                        </Button>

                    </DialogActions>
                </Dialog>

            </div >


        );
    }


}

export default compose(

    graphql(allCompaniesQuery, {
        options: {
            fetchPolicy: 'network-only',
            options: {
                errorPolicy: 'all',
            },
        },
    }),

   
    graphql(deleteCompanyMutation, {
        name: 'deleteCompany',
        options: {

            errorPolicy: 'all',
        },
    }),

)(withStyles(styles)(Company_Search));
