import React, { Component } from 'react';
import { getTxt } from 'global/appGlobal';

import { withStyles } from '@material-ui/core/styles';



import { graphql, compose } from 'react-apollo';

import { Paper } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { AppBar } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { TextField } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import TabContainer from 'components/TabContainer';
import * as queries from '../../modules/contract/contractQueries';
import Contract_General_Data_Edit from '../../modules/contract/Contract_General_Data_Edit';

import  { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

import { Tabs, Tab } from '@material-ui/core';



import * as Icon from '@material-ui/icons';
import { Checkbox } from '@material-ui/core';
//import TabsEx, { Tab } from 'global/components/tabs/TabsEx';


import { Button } from '@material-ui/core';

import LinearProgressEx from '../../components/LinearProgressEx';




import $ from 'jquery';
import CmdBar from 'components/form/CmdBar';


import GridEx from 'components/form/GridEx';


import { HashRouter, BrowserRouter, Route, Link, withRouter } from 'react-router-dom';

import MaterialForm from '../../components/form/MaterialForm';
import c from '../../components/form/Components';
import { getLabel, DictionaryTypes, getDictionary, getInternalInsTypeCd } from '../../global/dictionaryCache';
import ComponentEx from '../../components/ComponentEx';

function handleDelete() {
    // do nothing ('You clicked the delete icon.'); // eslint-disable-line no-alert
}


const styles = theme => ({


    chip: {
        margin: theme.spacing.unit,
    },
    badge: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        marginTop: 0,
    },

    gridRoot: {
        height: '100%',
    },

    row: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',

    },
    formCaption: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit * 2,
        width: '100%',
        position: 'relative',
        height: 40,
    },
    formCaptionLabel: {
        position: 'absolute',
        //color: theme.palette.primary1,
        backgroundColor: 'transparent',
        left: 25,
        paddingLeft: 10,
        paddingRight: 10,
    },

    rootPaper: theme.mixins.gutters({

        paddingTop: 0,
        paddingBottom: 0,
        marginTop: 0,// theme.spacing.unit * 3,

    }),

    formHeaderRoot: {
        padding: theme.spacing.unit * 2,
        position: 'relative',
    },

    formHeaderFieldLabel: {
        color: theme.palette.text.disabled,
        fontWeight: 'bold',

    },

    tableHeader: {
        backgroundColor: theme.palette.background.paper,
    }

});


class Contract_General_Data extends ComponentEx {

    state = {
        value: 0,
    };

    componentDidMount() {
        $("table[class*='Table-headTable']").addClass(this.props.classes.tableHeader);
    }

    componentWillUnmount() {
        delete window.refetchDatiContracti;
    }

    render() {

        const { classes } = this.props;
        const loading = this.props.data.loading;
        const contract = this.props.data.contract;
        window.refetchDatiContracti = this.props.data.refetch;

        return (

            <div>
                <LinearProgressEx loading={loading} />

                {
                    this.state.editId &&
                    <Contract_General_Data_Edit
                        id={this.state.editId}
                        AfterSubmit={(() => {
                            this.setState({ editId: undefined });
                        })}
                        insType={this.props.insType ? this.props.insType : this.props.match.params.insType}
                    />
                }

                {contract &&
                    <div>
                        <Button
                            color="primary"
                            className={classes.button}
                            onClick={() => {
                                this.setState({ editId: contract.insurance_contract_id });
                            }}
                        >
                            {getTxt('Edit')}
                        </Button>


                        <MaterialForm dataSource={contract} objType="contract">


                            <GridEx item sm={3} xs={12}>
                                <MaterialForm spacing={12} dataSource={contract} objType="contract" >
                                    <c.group l={getTxt('Tracciamento del Simpli di Polizza')} />
                                    <c.readOnlyInLine l={getTxt('Tracciamento')} f="contract_delivery_state" v={getLabel(DictionaryTypes.ContractDeliveryState, contract.contract_delivery_state)} d />
                                    <c.readOnlyInLine l={getTxt('Data Oper.')} f="contract_delivery_date" />
                                    <c.readOnlyInLine l={getTxt('Note di Consegna')} f="contract_delivery_note" sm={12} />
                                </MaterialForm>
                            </GridEx>


                            {/* <p.group l="Tracciamento del Simpli di Polizza" />
                            <c.field l="Tracciamento" f="contract_delivery_state" v={getLabel(DictionaryTypes.ContractDeliveryState, contract.contract_delivery_state)} d />
                            <c.field l="Data Oper." f="contract_delivery_date" d />
                            <c.field l="Note di Consegna" f="contract_delivery_note" sm={12} d /> */}

                        </MaterialForm>

                    </div>}

            </div>
        );
    }
}

export default compose(

    graphql(queries.contractByIdQuery, {
        options: props => (
            {
                variables: {
                    id: props.id,
                    insType: getInternalInsTypeCd(props.insType ? props.insType : props.match.params.insType),
                },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }
        ),
    }),

)(withStyles(styles)(Contract_General_Data));