import ComponentEx from 'components/ComponentEx';
import GridEx from 'components/form/GridEx';
import { withStyles } from '@material-ui/core/styles';
import File_Search from 'modules/file/File_Search';
import React from 'react';
import { getTxt } from 'global/appGlobal';
import { compose, graphql } from 'react-apollo';
import LinearProgressEx from '../../components/LinearProgressEx';
import c from '../../components/form/Components';
import MaterialForm from '../../components/form/MaterialForm';
import { DictionaryTypes, getLabel } from '../../global/dictionaryCache';
import * as queries from '../../modules/contract/contractQueries';

let history;
let contractId;
const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing.unit * 2,
       // margin: theme.spacing.unit * 2,
    },

});


class Contract_Item_Details_View extends ComponentEx {

    componentId = Date.now();

    componentWillUnmount() {
        delete window.refetchContractItemView[this.componentId];
    }

    render() {

        const { classes } = this.props;
        const loading = this.props.data.loading;
        const item = this.props.data.ContractItem;
        history = this.props.history;

        if (!window.refetchContractItemView) {
            window.refetchContractItemView = {};
        }
        window.refetchContractItemView[this.componentId] = this.props.data.refetch;

        const isFinancial = item && item.contract_item_type.is_financial === 'true';

        return (

            <div className={classes.root}>
                <LinearProgressEx loading={loading} />
                {item &&
                    <div>

                        <MaterialForm dataSource={item} objType="contract_item" onSubmit={this.onSubmit} onChange={this.fornChanged} noFocusOnFirstCtrl>



                            <GridEx item sm={3} xs={12}>
                                <MaterialForm spacing={12} dataSource={item} objType="contract_item" noFocusOnFirstCtrl >
                                    <c.group l={getTxt('Type and Dates')} />
                                    <c.readOnlyInLine l={getTxt('Type of Movementi')} f="item_type_cd" v={getLabel(DictionaryTypes.InsuranceItemType, item.item_type_cd)} />
                                    <c.readOnlyInLine l={getTxt('State')} f="state_cd" v={getLabel(DictionaryTypes.InsuranceItemState, item.state_cd)} />
                                    <c.readOnlyInLine l={getTxt('Start Date')} f="item_start_date" />
                                    <c.readOnlyInLine l={getTxt('End Date')} f="item_end_date" />
                                    <c.readOnlyInLine l={getTxt('Is Financial')} f="is_financial" />
                                    <c.readOnlyInLine l={getTxt('Notes')} f="item_notes" />
                                </MaterialForm>
                            </GridEx>
                            {isFinancial && <GridEx item sm={3} xs={12}>
                                <MaterialForm spacing={12} dataSource={item} objType="contract_item" noFocusOnFirstCtrl >
                                    <c.group l={getTxt('Total Amounts')} />
                                    <c.readOnlyInLine l={getTxt('Total Net Amount')} f="amount_netto" />
                                    <c.readOnlyInLine l={getTxt('Total Cliente Amount')} f="total_amount_client" />
                                    <c.readOnlyInLine l={getTxt('Agency Amount')} f="total_amount_agency" />
                                    <c.readOnlyInLine l={getTxt('Broker Amount')} f="total_amount_broker" />
                                </MaterialForm>
                            </GridEx>}
                            {isFinancial && <GridEx item sm={3} xs={12}>
                                <MaterialForm spacing={12} dataSource={item} objType="contract_item" noFocusOnFirstCtrl >
                                    <c.group l={getTxt('Payment information')} />
                                    <c.readOnlyInLine l={getTxt('Payment Type')} f="item_payment_type_cd" v={getLabel(DictionaryTypes.PublicPaymentType, item.item_payment_type_cd)} />
                                    <c.readOnlyInLine l={getTxt('Payment State')} f="item_payment_state_cd" v={getLabel(DictionaryTypes.InsuranceItemPaymentState, item.item_payment_state_cd)} />
                                    <c.readOnlyInLine l={getTxt('Payment Date')} f="item_payment_date" />
                                    <c.readOnlyInLine l={getTxt('Payment Notes')} f="payment_notes" />                                    
                                </MaterialForm>
                            </GridEx>}

                            {isFinancial && <GridEx item sm={3} xs={12}>
                                <MaterialForm spacing={12} dataSource={item} objType="contract_item" noFocusOnFirstCtrl >
                                    <c.group l={getTxt('Other Amounts')} />
                                    <c.readOnlyInLine l={getTxt('Autentica')} f="autentica" />
                                    <c.readOnlyInLine l={getTxt('Spese')} f="spese" />                                    
                                    <c.readOnlyInLine l={getTxt('Extra Broker %')} f="extra_broker_percent" />
                                    <c.readOnlyInLine l={getTxt('Diritti')} f="diritti" />
                                    <c.readOnlyInLine l={getTxt('Competenze')} f="competenze" />
                                    <c.readOnlyInLine l={getTxt('Accessori')} f="accessori" />
                                </MaterialForm>
                            </GridEx>}

                            <GridEx item sm={12} xs={12}>
                                <MaterialForm spacing={2} dataSource={item} objType="contract_item" noFocusOnFirstCtrl >
                                    <c.group l={getTxt('Movimento Documents')} />
                                    <File_Search entityId={item.contract_item_id} noFilters hideEmptyGrid />
                                </MaterialForm>
                            </GridEx>

                        </MaterialForm>


                    </div>
                }


            </div>

        );
    }
}


export default compose(



    graphql(queries.contractItemByIdQuery, {
        options: props => (
            {
                variables: {
                    id: props.id,
                    contractId: props.contractId,
                },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }
        ),
    }),

)(withStyles(styles)(Contract_Item_Details_View));
