
import GridEx from 'components/form/GridEx';
import { Button } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { FormControl, FormHelperText } from '@material-ui/core';
import { Input, InputAdornment, InputLabel } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import Search from '@material-ui/icons/Search';
import { graphql, compose } from 'react-apollo';
import MFormControl from '../../components/form/MFormControl';
import Client_Search from '../../modules/client/Client_Search';
import { customersAsDictionary } from '../../modules/client/clientQueries';
import { getTxt } from '../../global/appGlobal';
import { Grid } from '@material-ui/core';

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        paddingTop: theme.spacing.unit * 2,
        width: '100%',
        position: 'relative',
    },

    label: {
        marginTop: theme.spacing.unit,
    },

    delim: {
        marginRight: theme.spacing.unit,
    },

    input: {
        color: 'transparent',
    },

    dialogText: {
        color: theme.palette.error.main,
    },

    calcCodeBtn: {
        position: 'absolute',
        right: 0,
        bottom: theme.spacing.unit,
    },
    calcProgress: {
        position: 'absolute',
        right: theme.spacing.unit * 9,
        top: theme.spacing.unit - theme.spacing.unit * 2,
    },

    validationIconTrue: {
        position: 'absolute',
        right: theme.spacing.unit * 13,
        color: 'green',
    },

    validationIconFalse: {
        position: 'absolute',
        right: theme.spacing.unit * 13,
        color: theme.palette.error.main,
        cursor: 'pointer',
    },

    link: {
        position: 'absolute',
        top: theme.spacing.unit * 6,
        left: theme.spacing.unit,
    },
    iconSmall: {
        fontSize: 20,
    },

});

class MFormMultiLimkToCustomer extends MFormControl {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);

        //this.onKeyDown = this.onKeyDown.bind(this);
    }

    selectedId = undefined;

    onChange(e) {
        this.setState({ value: e.target.value });
        this.setState({ isDirty: true });
        this.formOnChange(this.props.f, e.target.value, this.props.type);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.forseRefresh) {
            this.setState({ value: nextProps.v });
        }
    }

    render() {
        const { classes, theme } = this.props;

        const me = this;
        const loading = this.props.data.loading;
        const customers = this.props.data.customersAsDictionary ? this.props.data.customersAsDictionary : [];

        let customersIds = [];
        if (this.state.value && this.state.value.length && this.state.value.length > 0) {
            customersIds = JSON.parse(this.state.value);
        }

        let text = '';
        let selectedCustomers = [];
        if (customers.length > 0 && customersIds.length > 0) {
            customersIds.forEach((id, index) => {
                const customer = customers.find(c => c.customer_id === id);
                if (customer) {
                    let link;
                    if (customer.customer_type_cd === 'PRIVATE_PROSPECT') {
                        link = `/customers/prospect/private/${customer.customer_id}`;
                    } else {
                        link = `/customers/${customer.customer_type_cd.toLowerCase()}/${customer.customer_id}`;
                    }
                    selectedCustomers.push({
                        link,
                        display_name: customer.display_name,
                    });
                    text += customer.display_name;
                    if (customersIds.length > 1 && index < customersIds.length) {
                        text += ',____ ';
                    }
                }

            });

        }

        return (

            <GridEx item sm={this.props.sm || 6} xs={this.props.xs || 12}>
                <FormControl className={classes.textField} >
                    <InputLabel
                        error={this.state.errorMessage ? true : false}
                        shrink
                        className={this.label}
                        required={this.props.r}
                    >
                        {this.getLabel()}
                    </InputLabel>
                    <Input
                        margin="normal"
                        {...this.props}
                        value={text}
                        disabled
                        required={this.props.r}
                        multiline
                        className={classes.input}
                        inputProps={
                            {
                                id: this.props.id ? this.props.id : this.id,
                                name: this.props.f,
                                endAdornment: this.props.endAdornment,
                            }}

                        endAdornment={
                            <InputAdornment position="end" style={{ zIndex: 9999 }}>
                                {loading && <CircularProgress className={classes.calcProgress} size={30} />}
                                <Button
                                    variant="contained"
                                    size="small"
                                    onClick={() => { this.setState({ findDlgOpen: true }); }}
                                    className={classes.calcCodeBtn}
                                    style={this.props.d ? { display: 'none' } : {}}
                                >
                                    <Search className={classes.iconSmall} />
                                </Button>
                            </InputAdornment>
                        }
                    />
                    <Grid container spacing={this.props.spacing || 24} className={classes.link} >
                        {/* <div className={classes.link}> */}
                        {selectedCustomers.map((selected, index) => {
                            return (
                                //  <GridEx item sm={this.props.sm || 6} xs={this.props.xs || 12}>
                                <span>
                                    <Link to={selected.link} >
                                        {selected ? selected.display_name : ''}
                                    </Link>
                                    {selectedCustomers.length > 1 && index < selectedCustomers.length && <span className={classes.delim} >,</span>}
                                </span>
                                // </GridEx>
                            );
                        })}
                        {/* </div> */}
                    </Grid>

                    <FormHelperText error={this.state.errorMessage ? true : false} >{this.state.errorMessage}</FormHelperText>
                </FormControl>

                <Dialog
                    className={classes.dialog}
                    open={this.state.findDlgOpen}
                    onClose={() => {
                        this.setState({ findDlgOpen: false });
                    }}
                >
                    <DialogTitle> <span>{getTxt('Find customer')}</span></DialogTitle>
                    <DialogContent>
                        <DialogContentText>

                            <Client_Search
                                noAddButton
                                noPdfButton
                                showSelects
                                multiselect
                                selectedIds={this.state.value}
                                onSelectedChanged={(c) => {
                                    if (c) {
                                        this.selectedId = c;
                                    } else {
                                        this.selectedId = undefined;
                                    }

                                }}
                            />

                        </DialogContentText>

                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                me.setState({ findDlgOpen: false });

                                if (me.selectedId) {
                                    me.setValue(JSON.stringify(me.selectedId));
                                }

                            }}
                            color="primary"
                        >
                            Ok
                        </Button>

                        <Button onClick={() => { this.setState({ findDlgOpen: false }); }} color="primary" >
                            Annulla
                           </Button>
                    </DialogActions>
                </Dialog>

            </GridEx>

        );
    }
}

MFormMultiLimkToCustomer.contextTypes = {
    onChange: PropTypes.func,
    registerControl: PropTypes.func,
    objType: PropTypes.string,
    dataSource: PropTypes.object,
    unRegisterControl: PropTypes.func,
    fields: PropTypes.Object,
};

export default compose(
    graphql(customersAsDictionary, {
        options: props => ({}),
    }),
)(withStyles(styles, { withTheme: true })(MFormMultiLimkToCustomer));
