import React, { Component } from 'react';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import $ from 'jquery';

import ComponentEx from 'components/ComponentEx';
import {
    SortingState, SelectionState, FilteringState, GroupingState,
    IntegratedFiltering, IntegratedGrouping, IntegratedSorting, IntegratedSelection,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    VirtualTable, TableHeaderRow, TableFilterRow, TableSelection, TableGroupRow,
    GroupingPanel, DragDropProvider, TableColumnReordering, Toolbar, TableColumnResizing,
} from '@devexpress/dx-react-grid-material-ui';
import { Button, Tooltip, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import * as Icon from '@material-ui/icons';
import { Select } from '@material-ui/core';
import c from 'components/form/Components';
import { Checkbox } from '@material-ui/core';
import { getToken } from 'global/auth';
import { ReportTypes } from 'global/reporting';
import { HashRouter, BrowserRouter, Route, Link, withRouter } from 'react-router-dom';
//import pdfMake from 'pdfmake';
import { getTxt, tableMessages, createGridResizerCols } from '../../global/appGlobal';
import LinkToCustomerById from '../../components/LinksToObjectById';
import LinearProgressEx from '../../components/LinearProgressEx';
import { getLabel, getDictionary, DictionaryTypes } from '../../global/dictionaryCache';
import { allCompaniesQuery, allCompanyIbansQueryName, allCompanyIbansQuery, deleteCompanyIbanMutation } from '../../modules/company/CompanyQueries';
import { Menu, MenuItem } from '@material-ui/core';
import RegularCard from '../../components/dashboard/RegularCard';
import { addAuthListener, authInfo, initFromCookie, logout } from 'global/auth';
import { compose } from 'redux';

function getIsSelected(id, selectedId, selectedIds) {
    if (selectedIds && selectedIds.length) {
        return selectedIds.find(i => i === id);
    }
    return id === selectedId + '';
}

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        margin: theme.spacing.unit * 3,

    },
    button: {
        marginRight: theme.spacing.unit,
    },

    tableHeader: {
        backgroundColor: theme.palette.background.paper,
    },
});


// const toLowerCase = value => String(value).toLowerCase();
// const filterByBroker = (value, filter) => {
//     const name = toLowerCase(value.display_name);
//     return name.includes(toLowerCase(filter.value));
// };
// const getBrokerPredicate = columnName => (columnName === 'owner_broker_id' ? filterByBroker : undefined);

class Company_Iban_Search extends ComponentEx {

    state = {
        anchorEl: null,
        open: false,
        selectedId: undefined,
        selectedIds: [],
    };

    constructor(props) {
        super(props);
        this.handleDelete = this.handleDelete.bind(this);
        this.tableCellTemplate = ({ row, column, style }) => {
            if (column.name === 'display_name') {
                return (<VirtualTable.Cell> <Link to={`/customers/${row.insurance_company_id}`}   >  {row.company_name}  </Link> </VirtualTable.Cell>);
            }
            return undefined;
        };

        this.getRowId = row => row.insurance_company_id;
        this.newClick = this.newClick.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ selectedId: nextProps.selectedId });
        this.setState({ selectedIds: nextProps.selectedIds ? JSON.parse(nextProps.selectedIds) : [] });
    }

    newClick(event) {
        this.setState({ open: true, anchorEl: event.currentTarget });
    }

    handleRequestClose = () => {
        this.setState({ open: false });
    };

    getCompaniesString = (conpanies) => {
        let str = '';
        for (let i = 0; i < conpanies.length; i += 1) {
            if (str.length > 30) {
                str += '...';
                return str;
            }
            str += conpanies[i].company_name;
            if ((i + 1) < conpanies.length) {
                str += ', ';
            }
        }
        return str;
    }

    handleDelete(id) {

        const data = this.props.data;
        this.props.deleteCompanyIbanMutation({
            variables: { id: this.state.delId },
            refetchQueries: [
                {
                    query: allCompanyIbansQuery,
                },
            ],
        }).then(
            (resp) => {

                //data.refetch();
            }
        );
        this.setState({ delId: false });
    }

    render() {
        const { classes } = this.props;

        const items = this.props.data.allCompanyIbans ? this.props.data.allCompanyIbans : [];

        const columns = [
            { name: 'iban', title: getTxt('IBAN') },
            { name: 'iban_id', title: getTxt('Companies') },
            { name: 'companies', title: getTxt('Actions') },
        ];

        createGridResizerCols(this.state, columns, { iban_id: 380 }, 280);
        const _this = this;
        return (

            <div className={classes.root}>
                <LinearProgressEx loading={this.props.data.loading} />
                {!this.props.noCaption && <RegularCard

                    headerColor="red"
                    cardTitle={getTxt('Agency Ibans')}
                    //cardSubtitle={getTxt('_____')}
                    content={
                        <div>
                            <Button
                                color="primary"
                                className={classes.button}
                                onClick={() => {
                                    _this.props.history.push('/iban/edit/new');
                                }}
                            >{getTxt('AGGIUNGI NUOVO IBAN')}</Button>
                        </div>

                    }
                />}

                <Grid
                    rows={items}
                    columns={columns}
                >

                    <FilteringState
                        defaultFilters={[{}]}
                    />

                    <SortingState
                        defaultSorting={[
                            {},
                        ]}
                    />

                    <IntegratedSorting />

                    <VirtualTable
                        messages={tableMessages}
                        cellComponent={({ row, column, style, ...props }) => {
                            if (column.name === 'iban') {
                                return (<VirtualTable.Cell><Link to={`/iban/edit/${row.iban_id}`} >{row.iban}</Link></VirtualTable.Cell>);
                            }
                            if (column.name === 'iban_id') {
                                return (
                                    <VirtualTable.Cell>
                                        {row.companies.map((i, index) => (
                                            <div style={{ display: 'inline' }}>
                                                <Link to={`company/view/${i.insurance_company_id}`}>{i.company_name}</Link>
                                                {(index + 1) < row.companies.length && <span style={{ marginRight: '7px' }} >,</span>}
                                            </div>
                                        ))}
                                    </VirtualTable.Cell>
                                );
                            }
                            if (column.name === 'companies') {
                                return (<VirtualTable.Cell>
                                    <Tooltip title={getTxt('Delete')}>
                                        <IconButton size="small" onClick={() => { this.setState({ delId: row.iban_id }); }} className={classes.inGridButton} >
                                            <Icon.Delete fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </VirtualTable.Cell>);
                            }
                            return <VirtualTable.Cell {...props} />;
                        }}
                    />
                    <TableColumnResizing
                        columnWidths={this.state.columnWidths}
                        onColumnWidthsChange={(cols) => { this.setState({ columnWidths: cols }); }}
                        defaultColumnWidths={this.state.defColumnWidths}
                    />
                    <TableHeaderRow />

                </Grid>


                <Dialog onClose={() => { this.setState({ delId: null }); }} open={this.state.delId}>
                    <DialogTitle>{getTxt('Delete company Iban.')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <span> {getTxt('Company Iban will be delete on server.')} </span>
                            <br />
                            <span > {getTxt('Are you sure do you want delete the company Iban ?')} </span>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleDelete(this.state.delId)} color="primary">
                            {getTxt('Ok')}
                        </Button>
                        <Button onClick={() => { this.setState({ delId: null }); }} color="primary">
                            {getTxt('Annula')}
                        </Button>

                    </DialogActions>
                </Dialog>


            </div >


        );
    }


}


export default compose(
    graphql(allCompanyIbansQuery, {
        options: {
            options: props => (
                {
                    variables: { parentId: props.match.params.parentId ? props.match.params.parentId : props.parentId },
                    fetchPolicy: 'network-only',
                    errorPolicy: 'all',
                }),
        },
    }),
    graphql(deleteCompanyIbanMutation, {
        name: 'deleteCompanyIbanMutation',
        options: {
            errorPolicy: 'all',
        },
    }),
)(withStyles(styles, true)(withRouter(Company_Iban_Search)));
