import React from 'react';
import PropTypes from 'prop-types';
// react plugin for creating charts
import ChartistGraph from 'react-chartist';
import * as Icon from '@material-ui/icons';
import {
    ContentCopy,
    Store,
    InfoOutline,
    Warning,
    DateRange,
    LocalOffer,
    Update,
    ArrowUpward,
    AccessTime,
    Accessibility,
    NoteAdd,
} from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { getTxt } from 'global/appGlobal';
import { graphql, compose } from 'react-apollo';

import StatsCard from 'components/dashboard/StatsCard';
import ChartCard from 'components/dashboard/ChartCard';
import TasksCard from 'components/dashboard/TasksCard';
import RegularCard from 'components/dashboard/RegularCard';
import Table from 'components/dashboard/Table';
import ItemGrid from 'components/dashboard/ItemGrid';
import { Menu, MenuItem } from '@material-ui/core';
import { Divider } from '@material-ui/core';
import { Link } from 'react-router-dom';
import * as Icons from '@material-ui/icons';

import {
    dailySalesChart,
    emailsSubscriptionChart,
    completedTasksChart
} from 'components/dashboard/variables/charts';

import dashboardStyle from 'assets/jss/material-dashboard-react/dashboardStyle';
import { operationalDashboardData } from '../dashboard/dashboardQueries';
import LinearProgressEx from '../../components/LinearProgressEx';
import ContractItemsReport from '../../modules/reports/ContractItemsReport';
import ComponentEx from '../../components/ComponentEx';
import { getDictionary, DictionaryTypes } from 'global/dictionaryCache';

const styles = theme => ({
    ...dashboardStyle,
    root: {
        backgroundColor: theme.palette.background.paper,
        paddingTop: theme.spacing.unit * 2,
        margin: theme.spacing.unit * 3,
        paddingBottom: theme.spacing.unit * 2,
    },
});

class Transit_Account_Type_List extends ComponentEx {
    state = {
        value: 0,
        menuOpen: false,
        anchorEl: undefined,
    };

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>

                { getDictionary(DictionaryTypes.PaymentAccount).map((i) => {
                    return (
                        <div key={i.value}>
                            <Link to={'/conti_transito/' + i.value} style={{ marginLeft: 30 }} >
                                {i.label}
                            </Link>
                            <br />
                            <br />
                        </div>
                    );
                })
                }

            </div>
        );
    }
}

export default withStyles(styles)(Transit_Account_Type_List);
