import React from 'react';
import PropTypes from 'prop-types';
// react plugin for creating charts
import ChartistGraph from 'react-chartist';
import * as Icon from '@material-ui/icons';
import {
    ContentCopy,
    Store,
    InfoOutline,
    Warning,
    DateRange,
    LocalOffer,
    Update,
    ArrowUpward,
    AccessTime,
    Accessibility,
    NoteAdd,
} from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
var Chartist = require("chartist");
import { Typography } from '@material-ui/core';
import { getTxt } from 'global/appGlobal';
import { graphql, compose } from 'react-apollo';

import StatsCard from 'components/dashboard/StatsCard';
import ChartCard from 'components/dashboard/ChartCard';
import TasksCard from 'components/dashboard/TasksCard';
import RegularCard from 'components/dashboard/RegularCard';
import Table from 'components/dashboard/Table';
import ItemGrid from 'components/dashboard/ItemGrid';
import { Menu, MenuItem } from '@material-ui/core';
import { Divider } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { Link } from 'react-router-dom';

import {
    dailySalesChart,
    emailsSubscriptionChart,
    completedTasksChart
} from 'components/dashboard/variables/charts';

import dashboardStyle from 'assets/jss/material-dashboard-react/dashboardStyle';
import { operationalDashboardData } from '../dashboard/dashboardQueries';
import LinearProgressEx from '../../components/LinearProgressEx';
import ComponentEx from '../../components/ComponentEx';

const styles = theme => ({
    ...dashboardStyle,
    root: {
        backgroundColor: theme.palette.background.paper,
        paddingTop: theme.spacing.unit * 2,
        margin: theme.spacing.unit * 3,
    },
});

class OperationalDashboard extends ComponentEx {
    state = {
        value: 0,
        menuOpen: false,
        anchorEl: undefined,
    };
    handleChange = (event, value) => {
        this.setState({ value });
    };

    handleChangeIndex = index => {
        this.setState({ value: index });
    };

    newClick(event) {
        this.setState({ menuOpen: true, anchorEl: event.currentTarget });
    }

    render() {
        const { classes } = this.props;
        const loading = this.props.data.loading;
        const data = {
            requestPerMonthCount: {},
            monthlyNewCustomers: {},
            monthlyAgencyPercentage: {},
            receivedClaims: 0,
        };

        if (this.props.data.operationalDashboardData) {


            data.receivedRequests = this.props.data.operationalDashboardData.receivedRequests;
            data.expiryInNext30Days = this.props.data.operationalDashboardData.expiryInNext30Days;
            data.expiredPrev30DayNotPaid = this.props.data.operationalDashboardData.expiredPrev30DayNotPaid;

            data.receivedClaims = this.props.data.operationalDashboardData.receivedClaims;
            data.clientDocsexparyNext60Days = this.props.data.operationalDashboardData.clientDocsexparyNext60Days;
        }


        let monthsByNumbers = {};
        monthsByNumbers[0] = getTxt("Jan");
        monthsByNumbers[1] = getTxt("Feb");
        monthsByNumbers[2] = getTxt("Mar");
        monthsByNumbers[3] = getTxt("Apr");
        monthsByNumbers[4] = getTxt("May");
        monthsByNumbers[5] = getTxt("Jun");
        monthsByNumbers[6] = getTxt("Jul");
        monthsByNumbers[7] = getTxt("Aug");
        monthsByNumbers[8] = getTxt("Sep");
        monthsByNumbers[9] = getTxt("Oct");
        monthsByNumbers[10] = getTxt("Nov");
        monthsByNumbers[11] = getTxt("Dec");

        //  monthsByNumbers = monthsByNumbers.concat(monthsByNumbers);

        let today = new Date();
        let todayMinus12Month = new Date(new Date().setFullYear(today.getFullYear() - 1));

        const requestPerMonthChartData = {
            labels: [],
            series: [[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],

        };

        const requestPerMonthChartOptions = {
            lineSmooth: Chartist.Interpolation.cardinal({
                tension: 0
            }),
            low: 0,
            high: 6000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
            chartPadding: {
                top: 0,
                right: 10,
                bottom: 0,
                left: 0
            }
        };

        let max = 0;
        let min = 0;

        // Object.keys(data.requestPerMonthCount).forEach((m) => {
        //     const month = Number.parseInt(m);
        //     const count = data.requestPerMonthCount[m];
        //     if (count > max) {
        //         max = count;
        //     }
        //     if (count < min) {
        //         min = count;
        //     }


        //     requestPerMonthChartData.series[0][month - 1] = count;
        // });

        // requestPerMonthChartOptions.low = 0;
        // requestPerMonthChartOptions.high = max > 0 ? max * 1.2 : max;

        // ///////////////////////////////////////////////////////////////////////////////////////////////////

        const monthCustomersChartData = {
            labels: [],
            series: [[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
        };
        const monthCustomersChartOptions = { ...requestPerMonthChartOptions };

        const todayMonth = new Date().getMonth();
        const todayYear = new Date().getFullYear();
        for (let i = todayMonth; i < 13; i += 1) {
            monthCustomersChartData.labels[i] = (monthsByNumbers[i - todayMonth]) + ' ' + todayYear;
        }
        for (let k = 0; k < todayMonth; k += 1) {
            monthCustomersChartData.labels[k] = (monthsByNumbers[k + todayMonth]) + ' ' + (todayYear - 1);
        }


        let maxMonthlyCustomers = 0;
        let minMonthlyCustomers = 0;

        Object.keys(data.monthlyNewCustomers).forEach((m) => {
            const splitted = m.split('_');
            const month = Number.parseInt(splitted[0]);
            const year = Number.parseInt(splitted[1]);

            const count = data.monthlyNewCustomers[m];
            if (count > max) {
                max = count;
            }
            if (count < min) {
                min = count;
            }


            if (year === todayYear) {
                monthCustomersChartData.series[0][month + todayMonth] = count;
            } else {
                monthCustomersChartData.series[0][month - todayMonth] = count;
            }

        });

        monthCustomersChartOptions.low = 0;
        monthCustomersChartOptions.high = max > 0 ? max * 1.2 : max;
        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        const agencyPercentChartData = {
            labels: [],
            series: [[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
        };
        const agencyPercentChartOptions = { ...requestPerMonthChartOptions };

        for (let i = todayMonth; i < 13; i += 1) {
            agencyPercentChartData.labels[i] = (monthsByNumbers[i - todayMonth]) + ' ' + todayYear;
        }
        for (let k = 0; k < todayMonth; k += 1) {
            agencyPercentChartData.labels[k] = (monthsByNumbers[k + todayMonth]) + ' ' + (todayYear - 1);
        }

        Object.keys(data.monthlyAgencyPercentage).forEach((m) => {
            const splitted = m.split('_');
            const month = Number.parseInt(splitted[0]);
            const year = Number.parseInt(splitted[1]);

            const count = data.monthlyAgencyPercentage[m];
            if (count > max) {
                max = count;
            }
            if (count < min) {
                min = count;
            }


            if (year === todayYear) {
                agencyPercentChartData.series[0][month + todayMonth] = count;
            } else {
                agencyPercentChartData.series[0][month - todayMonth] = count;
            }

        });

        agencyPercentChartOptions.low = 0;
        agencyPercentChartOptions.high = max > 0 ? max * 1.2 : max;



        return (
            <div className={classes.root}>



                {/* <Typography variant="h4" color="inherit" noWrap={false}>{getTxt('Dashboard', '#fz50q9o')}</Typography> */}
                <LinearProgressEx loading={loading} />
                <Grid container>
                    <ItemGrid xs={12} md={6}>
                        <Link to="/requests/filter/received">
                            <StatsCard
                                icon={Icon.AssignmentReturned}
                                iconColor="green"
                                title={getTxt('Received requests')}
                                description={data && data.receivedRequests ? data.receivedRequests : 0}
                                whiteSpace="normal"
                                statIcon={() => ''}
                            //statIconColor="danger"
                            //statLink={{ text: 'Aggiungi nuova', href: '#', onClick: (e) => this.newClick(e) }}
                            />
                        </Link>
                    </ItemGrid>

                    <ItemGrid xs={12} md={6}>
                        <Link to="/contracts/filter/will-expiry-next-30-days">
                            <StatsCard
                                icon={Icons.AssignmentLate}
                                iconColor='orange'
                                title={getTxt('Scadenze prossimi 30 giorni')}
                                description={data && data.expiryInNext30Days ? data.expiryInNext30Days : 0}
                                statIcon={() => ''}
                                whiteSpace="normal"
                            />
                        </Link>
                    </ItemGrid>

                    <ItemGrid xs={12} md={6}>
                        <Link to="/contracts/filter/expired-prev-30-days-not-paid">
                            <StatsCard
                                icon={Icons.AssignmentLate}
                                iconColor="red"
                                title={getTxt('Titoli arretrati ultimi 30 giorni')}
                                description={data && data.expiredPrev30DayNotPaid ? data.expiredPrev30DayNotPaid : 0}
                                statIcon={() => ''}
                                whiteSpace="normal"
                            />
                        </Link>
                    </ItemGrid>

                    <ItemGrid xs={12} md={6}>
                        <Link to="/claims/filter/received">
                            <StatsCard
                                icon={Icons.AssignmentLate}
                                iconColor="red"
                                title={getTxt('Received claims')}
                                description={data && data.receivedClaims}
                                statIcon={() => ''}
                                whiteSpace="normal"
                            />
                        </Link>
                    </ItemGrid>
                    <ItemGrid xs={12} md={3}></ItemGrid>
                    <ItemGrid xs={12} md={6}>
                        <Link to="/customers/filter/docs-expiry-next-60-days">
                            <StatsCard
                                icon={Icons.AssignmentLate}
                                iconColor='orange'
                                title={getTxt('Documenti in scadenza a 60 giorni')}
                                description={data && data.clientDocsexparyNext60Days ? data.clientDocsexparyNext60Days : 0}
                                statIcon={() => ''}
                                whiteSpace="normal"
                            />
                        </Link>
                    </ItemGrid>
                    <ItemGrid xs={12} md={3}></ItemGrid>

                </Grid>


            </div>
        );
    }
}


export default compose(
    graphql(operationalDashboardData, {
        options: props => ({

            fetchPolicy: 'network-only',
            options: {
                errorPolicy: 'all',
            },
        }),
    }),

)(withStyles(styles)(OperationalDashboard));
