import gql from 'graphql-tag';

export const allCompaniesQuery = gql`

query {
    allCompanies {
      insurance_company_id
      company_name
      company_billing_name
      vat_number
      address_country_cd
      address_province
      address_city
      address_street
      address_street_number
      address_cap
      phone
      mobile_phone
      fax
      email
      email_pec
      bank_iban
      bank_iban_2
      invoice_company_name
      invoice_address_country_cd
      invoice_address_province
      invoice_address_city
      invoice_address_street
      invoice_address_street_number
      invoice_address_cap
      invoice_email
      invoice_email_pec
      report_type_cd
      agency_code
      sub_code
      remittance_type_cd
      withholding_tax
        authentic_cost
        shipping_cost
        username_SIC_ania
        password_SIC_ania
        code_SIC_ania
        username_company_app
        password_company_app

        email_address
        certified_email_address
        phone_number
        sdi_code
        is_deleted
       }
    }
`;


export const companiesAsDictionaryQuery = gql`

query {
    companyDictionary {
            value
            label
            is_deleted
       }
    }
`;


export const getCompanyQuery = gql`

query  GetCompanyQuery($id: ID) {
    company(id: $id) {
        insurance_company_id
        company_name
        company_billing_name
        vat_number
        address_country_cd
        address_province
        address_city
        address_street
        address_street_number
        address_cap
        phone
        mobile_phone
        fax
        email
        email_pec
        bank_iban
        bank_iban_2
        invoice_company_name
        invoice_address_country_cd
        invoice_address_province
        invoice_address_city
        invoice_address_street
        invoice_address_street_number
        invoice_address_cap
        invoice_email
        invoice_email_pec
        report_type_cd
        agency_code
        sub_code
        remittance_type_cd
        withholding_tax
        authentic_cost
        shipping_cost
        username_SIC_ania
        password_SIC_ania
        code_SIC_ania
        username_company_app
        password_company_app
  
  
        email_address
        certified_email_address
        phone_number
        sdi_code
        is_deleted
    }
  }

`;


export const allInsCompGuaranty = gql`

query allInsCompGuaranty ($companyId: Int)  {
    allInsCompGuaranty (companyId: $companyId) {
        insurance_company_guaranty_id,
        guaranty_type_id,
        insurance_company_id,
        default_broker_percent,
        default_agency_percent,
        guaranty_type{
            guaranty_type_id,
            guaranty_name
            insurance_type_cd
        }
}
}

`;

export const companyBalanceQuery = gql`
query companyBalanceQuery($i: ID!, $companyId: Int, $from: String, $to: String) {
    companyBalance(i: $i, companyId: $companyId, from: $from, to: $to )
}`;


export const insCompGuarantyById = gql`

query insCompGuarantyById ($id: ID!)  {
    insCompGuarantyById (id: $id) {
        insurance_company_guaranty_id,
        guaranty_type_id,
        insurance_company_id,
        default_broker_percent,
        default_agency_percent,
        allGuaranties{
            guaranty_type_id,
            guaranty_name,
            insurance_type_cd
        }
}
}

`;




export const updateInsCompGuarantyMutation = gql`

mutation updateInsCompGuarantyMutation($insCompGuaranty: insurance_company_guaranty_input!) {
    updateInsCompGuarantyMutation(insCompGuaranty: $insCompGuaranty) {
      id
      validation {
        field
        message
      }
    }
  }

  `;


export const deleteInsCompGuarantyMutation = gql`

mutation deleteInsCompGuarantyMutation ($id: ID!) {
    deleteInsCompGuarantyMutation(id: $id){
        id
        validation {
            field
            message
        }
    } 
}

`;

export const allCompanyPaymentsQuery = gql`
query allCompanyPaymentsQuery($companyId: ID, $from: String, $to: String) {
    allCompanyPayments(companyId: $companyId, from: $from, to: $to  ){
        company_payment_id
        insurance_company_id
        payment_type_cd
        payment_state_cd
        payment_date
        payment_amount
        payment_notes
        payment_account
        company{
            company_name
        }
        files{
            document_id
        }
    }
}`;


export const companyPaymentByIdQuery = gql`

query companyPaymentByIdQuery($id: ID!) {
    companyPayment(id: $id) {
        company_payment_id
        insurance_company_id
        payment_type_cd
        payment_state_cd
        payment_date
        payment_amount
        payment_notes
        payment_account
        company{
            company_name
        }
    }
   
}`;

export const updateCompanyPaymentMutation = gql`

mutation updateCompanyPaymentMutation($companyPayment: company_payment_input!) {
    updateCompanyPaymentMutation(companyPayment: $companyPayment) {
      id
      validation {
        field
        message
      }
    }
  }`;

export const deleteCompanyPaymentMutation = gql`

mutation deleteCompanyPaymentMutation($id: ID) {
    deleteCompanyPaymentMutation(id: $id) {
      id
      validation {
        field
        message
      }
    }
  }`;

export const allCompanyIbansQuery = gql`
query allCompanyIbansQuery   {
   allCompanyIbans{
       iban_id
       iban
       companies {
           insurance_company_id,
           company_name
       }
   }
}
`;

export const companyIbanQuery = gql`
query companyIbanQuery ($id: ID)  {
    companyIban (id: $id) {
       iban_id
       iban
       companies {
           insurance_company_id,
           company_name
       }
   }
}
`;

export const updateCompanyIbanMutation = gql`

mutation updateCompanyIbanMutation($iban: companyIban_Input!) {
    updateCompanyIban(iban: $iban) {
      id
      validation {
        field
        message
      }
    }
  }`;

export const deleteCompanyIbanMutation = gql`

mutation deleteCompanyIbanMutation($id: ID) {
    deletedeleteCompanyIbanMutation(id: $id) {
      id
      validation {
        field
        message
      }
    }
  }`;

export const allCompanyFreeItemsQuery = gql`
query allCompanyFreeItemsQuery($companyId: ID) {
    allCompanyFreeItems(companyId: $companyId ){
        company_free_item_id
        insurance_company_id
        payment_date
        payment_amount
        payment_notes
        provvigioni
        company{
            company_name
        }
        files{
            document_id
        }
    }
}`;

export const deleteCompanyFreeItemMutation = gql`
mutation deleteCompanyFreeItemMutation($id: ID) {
    deleteCompanyFreeItemMutation(id: $id) {
      id
      validation {
        field
        message
      }
    }
  }`;

export const companyFreeItemByIdQuery = gql`

query companyFreeItemByIdQuery($id: ID!) {
    companyFreeItemById(id: $id) {
        company_free_item_id
        insurance_company_id
        payment_date
        payment_amount
        payment_notes
        provvigioni
        company{
            company_name
        }
    }
   
}`;

export const updateCompanyFreeItemMutation = gql`

mutation updateCompanyFreeItemMutation($companyFreeItem: company_free_item_input!) {
    updateCompanyFreeItemMutation(companyFreeItem: $companyFreeItem) {
      id
      validation {
        field
        message
      }
    }
  }`;


export const deleteCompanyMutation = gql`

mutation deleteCompanyMutation ($id: ID!) {
    deleteCompanyMutation(id: $id){
        id
        validation {
            field
            message
        }
    } 
}

`;