import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import { Typography } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';
import { Paper } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { AppBar } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { Tabs, Tab } from '@material-ui/core';
import * as Icon from '@material-ui/icons';
import { Checkbox } from '@material-ui/core';
import { Button } from '@material-ui/core';
import moment from 'moment';
import { Badge } from '@material-ui/core';
import { addAuthListener, authInfo, initFromCookie, logout } from 'global/auth';

import ItemGrid from 'components/Grid/ItemGrid';

import gql from 'graphql-tag';
import File_Search from 'modules/file/File_Search';
import { HashRouter, BrowserRouter, Route, Link, withRouter } from 'react-router-dom';
import $ from 'jquery';
import { getToken } from 'global/auth';
import { ReportTypes } from 'global/reporting';
import TabContainer from 'components/TabContainer';
import { Menu, MenuItem } from '@material-ui/core';
import { graphql, compose } from 'react-apollo';
import ComponentEx from 'components/ComponentEx';
import Contract_Search from 'modules/contract/Contract_Search';
import ReactSVG from 'react-svg';
import HeaderViewItem from 'components/HeaderViewItem';
import NavPills from 'components/NavPills/NavPills';


import CmdBarCustomBtns from '../../components/CmdBarCustomBtns';
import { getTxt } from '../../global/appGlobal';
import Request_Search from '../../modules/request/Request_Search';
import LinearProgressEx from '../../components/LinearProgressEx';
import Client_Privat_Details_View from '../../modules/client/Client_Privat_Details_View';
import { queryGetCustomerById, customerBriefQuery } from '../../modules/client/clientQueries';
import { getLabel, DictionaryTypes, getDictionary, getValue } from '../../global/dictionaryCache';
import NewRequestMenuItems from '../../modules/client/NewRequestMenuItems';
import RegularCard from '../../components/dashboard/RegularCard';
import Claim_Search from '../contract/claim/Claim_Search';
import Client_Payments_Search from '../client/Client_Payments_Search';
import Client_Contracts_Report from '../client/Client_Contracts_Report';
import Client_Balance_Search from './Client_Balance_Search';
import Email_To_Client_Dlg from './Email_To_Client_Dlg';
import { getReportDataQuery } from '../reports/reportQueries';
import GridEx from 'components/form/GridEx';

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.grey,
        margin: theme.spacing.unit * 3,
        marginTop: 0,
    },

    button: {
        margin: theme.spacing.unit,
    },

    badge: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        marginTop: 0,
    },

    gridRoot: {
        height: '100%',
    },


    formCaption: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit * 2,
        width: '100%',
        position: 'relative',
        height: 40,
    },
    formCaptionLabel: {
        position: 'absolute',
        backgroundColor: 'transparent',
        left: 25,
        paddingLeft: 10,
        paddingRight: 10,
    },

    rootPaper: theme.mixins.gutters({
        paddingTop: 0,
        paddingBottom: 0,
        marginTop: 0,
    }),

    formHeaderRoot: {
        padding: theme.spacing.unit * 2,
        position: 'relative',
    },

    formHeaderFieldLabel: {
        color: theme.palette.text.disabled,
        fontWeight: 'bold',
    },

    tableHeader: {
        backgroundColor: theme.palette.background.paper,
    },

    headerIcon: {
        width: theme.spacing.unit * 8,
        height: theme.spacing.unit * 9,
        marginRight: theme.spacing.unit * 2,
    },
    cmdBar: {
        position: 'absolute',
        right: theme.spacing.unit * 3,
    },
});

const tabsStateGlobalId = 'customer_view_privat';

class Client_Privat_View extends ComponentEx {
    state = {
        value: window[tabsStateGlobalId] ? window[tabsStateGlobalId] : 0,
    };

    componentWillUnmount() {
        window[tabsStateGlobalId] = this.state.value;
    }

    componentDidMount() {
        $("table[class*='Table-headTable']").addClass(this.props.classes.tableHeader);
    }

    handleChange = (event, value) => {
        this.setState({ value });
    };

    render() {

        const { classes } = this.props;
        const { value } = this.state;
        const loading = this.props.data.loading;
        const customer = this.props.data.customer;

        const buttons = [getTxt('Edit'), getTxt('Privacy PDF'), getTxt('PDF Incarico'), getTxt('Contracts PDF'), getTxt('Dichiarazione PDF'), getTxt('Email al cliente')];

        return (




            <div className={classes.root}>
                <LinearProgressEx loading={loading} />




                <RegularCard
                    headerColor="red"
                    cardTitle={customer && `${getLabel(DictionaryTypes.Title, customer.title_cd)} ${customer.display_name}`}
                    cardSubtitle={getTxt('Privat customer')}
                    content={

                        <Grid container>
                            <ItemGrid xs={12} sm={12} md={5}>
                                <HeaderViewItem l={getTxt('Contacts')}>
                                    <Typography variant="body1" >{customer && customer.email}</Typography>
                                    <Typography variant="body1" > {customer && customer.mobile_phone} </Typography>
                                </HeaderViewItem>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={12} md={3}>
                                <HeaderViewItem l={getTxt('Collaboratore')}>
                                    <Typography variant="body1" >{customer && customer.broker && customer.broker.email}</Typography>
                                    {customer && customer.broker && <Typography variant="body1" >
                                        <Link to={`/brokers/${customer.broker.broker_id}`}>{customer.broker.display_name}</Link>
                                    </Typography>}
                                </HeaderViewItem>
                            </ItemGrid>
                            <ItemGrid xs={12} sm={12} md={4}>
                                <CmdBarCustomBtns
                                    color="red"
                                    onClick={((btn, e) => {
                                        if (btn === getTxt('Edit')) {
                                            this.props.history.push(`/customers/private/edit/${this.props.match.params.id}`);
                                        }
                                        if (btn === 'New Req.') {
                                            this.setState({ addNewMenuOpen: true, addNewMenuAnchor: e.currentTarget });
                                        }
                                        if (btn === getTxt('Privacy PDF')) {

                                            const newWindow = window.open("", "_blank");
                                            newWindow.document.write(getTxt('Generating PDF, please wait...'));
                                            this.props.getOneTimeToken({

                                            }, this).then(
                                                (resp) => {
                                                    if (resp.data && resp.data.oneTimeTokenMutation && resp.data.oneTimeTokenMutation.token) {
                                                        newWindow.location.href = `${window.apiUrl}files/reports?ott=${resp.data.oneTimeTokenMutation.token}&type=${ReportTypes.PrivacyAgriment}&params=${JSON.stringify({ customerId: this.props.match.params.id })}`;
                                                        newWindow.focus();
                                                    }
                                                });
                                        }
                                        if (btn === getTxt('Contracts PDF')) {

                                            const newWindow = window.open("", "_blank");
                                            newWindow.document.write(getTxt('Generating PDF Report, please wait...'));
                                            this.props.getOneTimeToken({

                                            }, this).then(
                                                (resp) => {



                                                    if (resp.data && resp.data.oneTimeTokenMutation && resp.data.oneTimeTokenMutation.token) {
                                                        const params = { customerId: customer.customer_id };
                                                        newWindow.location.href = `${window.apiUrl}files/reports?ott=${resp.data.oneTimeTokenMutation.token}&type=${ReportTypes.CustomerContracts}&params=${JSON.stringify(params)}`;
                                                        newWindow.focus();
                                                    }


                                                });
                                        }
                                        if (btn === getTxt('PDF Incarico')) {

                                            const newWindow = window.open("", "_blank");
                                            newWindow.document.write(getTxt('Generating PDF, please wait...'));
                                            this.props.getOneTimeToken({

                                            }, this).then(
                                                (resp) => {
                                                    if (resp.data && resp.data.oneTimeTokenMutation && resp.data.oneTimeTokenMutation.token) {
                                                        newWindow.location.href = `${window.apiUrl}files/reports?ott=${resp.data.oneTimeTokenMutation.token}&type=${ReportTypes.Incarico}&params=${JSON.stringify({ customerId: this.props.match.params.id })}`;
                                                        newWindow.focus();
                                                    }
                                                });
                                        }
                                        if (btn === getTxt('Dichiarazione PDF')) {

                                            const newWindow = window.open("", "_blank");
                                            newWindow.document.write(getTxt('Generating PDF, please wait...'));
                                            this.props.getOneTimeToken({

                                            }, this).then(
                                                (resp) => {
                                                    if (resp.data && resp.data.oneTimeTokenMutation && resp.data.oneTimeTokenMutation.token) {
                                                        newWindow.location.href = `${window.apiUrl}files/reports?ott=${resp.data.oneTimeTokenMutation.token}&type=${ReportTypes.Dichiarazione}&params=${JSON.stringify({ customerId: this.props.match.params.id })}`;
                                                        newWindow.focus();
                                                    }
                                                });
                                        }
                                        if (btn === getTxt('Email al cliente')) {
                                            this.setState({ emailDlg: true });
                                        }

                                    })}
                                >{buttons}</CmdBarCustomBtns>
                                {/* getTxt('Edit');
                                    getTxt('Privacy PDF'); */}
                            </ItemGrid>
                        </Grid>





                    }

                />

                <NavPills
                    color="primary"

                    alignCenter
                    active={value}
                    onChange={this.handleChange}
                    tabs={[
                        {
                            tabButton: getTxt('Details'),
                            tabIcon: Icon.Dashboard,
                            tabContent: (
                                <TabContainer>

                                    <Button color="primary" className={classes.button} onClick={() => { this.props.history.push(`/customers/private/edit/${this.props.match.params.id}`) }} >
                                        {getTxt('Edit Details')}
                                    </Button>

                                    <Client_Privat_Details_View customerId={this.props.match.params.id} quiet={this.state.value !== 0} />
                                </TabContainer>
                            ),
                        },
                        {
                            tabButton: getTxt('Richiesti'),
                            tabIcon: Icon.Schedule,
                            tabContent: (
                                <TabContainer>

                                    <Menu
                                        id="simple-menu"
                                        anchorEl={this.state.addNewMenuAnchor}
                                        open={this.state.addNewMenuOpen}
                                        onClose={() => { this.setState({ addNewMenuOpen: false }); }}
                                    >
                                        {customer && <NewRequestMenuItems history={this.props.history} customerId={customer.customer_id} />}
                                    </Menu>

                                    <Button
                                        color="primary"
                                        className={classes.button}
                                        onClick={(e) => {
                                            this.setState({ addNewMenuOpen: true, addNewMenuAnchor: e.currentTarget });
                                        }}
                                    >
                                        {getTxt('Aggiungi nuova richiesta')}
                                    </Button>
                                    {customer && <Request_Search noCaption customerId={customer.customer_id} quiet={this.state.value !== 1} />}
                                </TabContainer>
                            ),
                        },
                        {
                            tabButton: getTxt('Polizze'),
                            tabIcon: Icon.List,
                            tabContent: (
                                <TabContainer>
                                    {customer &&

                                        <Grid container spacing={3} >
                                            <GridEx item sm="auto" xs={6}>

                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => {

                                                        const newWindow = window.open("", "_blank");
                                                        newWindow.document.write(getTxt('Generating PDF Report, please wait...'));
                                                        this.props.getOneTimeToken({

                                                        }, this).then(
                                                            (resp_ott) => {

                                                                if (resp_ott.data && resp_ott.data.oneTimeTokenMutation && resp_ott.data.oneTimeTokenMutation.token) {

                                                                    newWindow.location.href = `${window.apiUrl}files/reports?ott=${resp_ott.data.oneTimeTokenMutation.token}&type=${ReportTypes.CustomerContracts2}&params=${JSON.stringify({ customerId: customer.customer_id })}`;
                                                                    newWindow.focus();
                                                                }

                                                            });
                                                    }}
                                                >
                                                    {getTxt('Create PDF')}
                                                </Button>


                                            </GridEx>

                                            <GridEx item sm="auto" xs={6}>

                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => {

                                                        const newWindow = window.open("", "_blank");
                                                        newWindow.document.write(getTxt('Generazione del report XLS, attendere prego...'));
                                                        this.props.getOneTimeToken({

                                                        }, this).then(
                                                            (resp_ott) => {

                                                                if (resp_ott.data && resp_ott.data.oneTimeTokenMutation && resp_ott.data.oneTimeTokenMutation.token) {

                                                                    newWindow.location.href = `${window.apiUrl}files/reports?ott=${resp_ott.data.oneTimeTokenMutation.token}&type=${ReportTypes.CustomerContracts2}&params=${JSON.stringify({ customerId: customer.customer_id, xlsx: true })}`;
                                                                    newWindow.focus();
                                                                }

                                                            });
                                                    }}
                                                >
                                                    {getTxt('Genera XLS')}
                                                </Button>



                                            </GridEx>
                                        </Grid>
                                    }
                                    {customer && <Contract_Search noCaption customerId={customer.customer_id} isCustomerView quiet={this.state.value !== 2} />}
                                </TabContainer>
                            ),
                        },
                        {
                            tabButton: getTxt('Claims'),
                            tabIcon: Icon.DiscFull,
                            tabContent: (
                                <TabContainer>
                                    {customer &&
                                        <Claim_Search
                                            customerId={customer.customer_id}
                                            noCaption
                                            quiet={this.state.value !== 3}
                                        />
                                    }
                                </TabContainer>
                            ),
                        },
                        {
                            tabButton: getTxt('Documents'),
                            tabIcon: Icon.AttachFile,
                            tabContent: (
                                <TabContainer>
                                    {customer && <File_Search entityId={customer.customer_id} quiet={this.state.value !== 4} />}
                                </TabContainer>
                            ),
                        },
                        {
                            tabButton: getTxt('Payments'),
                            tabIcon: Icon.Payment,
                            tabContent: (
                                <TabContainer>
                                    {customer && <Client_Payments_Search noCaption showAlterAddBtn customerId={customer.customer_id} quiet={this.state.value !== 5} />}
                                </TabContainer>
                            ),
                        },
                        {
                            tabButton: getTxt('Balance'),
                            tabIcon: Icon.Payment,
                            tabContent: (
                                <TabContainer>
                                    {customer && <Client_Balance_Search noCaption showAlterAddBtn customerId={customer.customer_id} quiet={this.state.value !== 6} />}
                                </TabContainer>
                            ),
                        },

                    ]}
                />

                {customer && this.state.emailDlg &&
                    <Email_To_Client_Dlg
                        userId={customer.customer_id}
                        email={customer.email}
                        AfterSubmit={(data) => {
                            this.setState({ emailDlg: false });
                            if (data) {
                                const emailDescr = {
                                    customer_id: customer.customer_id,
                                    body: data.body,
                                    subject: data.subject,
                                    filesIds: data.files.map(f => f.document_id),
                                    reportTypes: [
                                        data.PDFIncarico ? ReportTypes.Incarico : null,
                                        data.ContractsPDF ? ReportTypes.CustomerContracts : null,
                                        data.PrivacyPDF ? ReportTypes.PrivacyAgriment : null,
                                        data.DichiarazionePDF ? ReportTypes.Dichiarazione : null,
                                    ],
                                };


                                this.props.sendEmailToCustomer({
                                    variables: { email: emailDescr },
                                }, this).then(
                                    (resp) => {


                                        window.showPopupInfo({
                                            variant: 'email_sent_to_customer_ok',
                                        });
                                        // const serverErrors = resp.data.sendEmailToCustomerMutation.validation;

                                        // if (serverErrors && serverErrors.length > 0) {

                                        // } else {

                                        // }
                                    });

                            }



                        }}
                    />
                }

            </div>
        );
    }
}

const oneTimeTokenMutation = gql`

mutation oneTimeTokenMutation  {
    oneTimeTokenMutation{
        token
    }
}
`;

const sendEmailToCustomerMutation = gql`

mutation sendEmailToCustomerMutation ($email: email_input!) {
    sendEmailToCustomerMutation(email: $email){
        id
        validation {
            field
            message
        }
    } 
}

`;

export default compose(
    graphql(oneTimeTokenMutation, {
        name: 'getOneTimeToken',
        options: {
            errorPolicy: 'all',

        },
    }),

    graphql(sendEmailToCustomerMutation, {
        name: 'sendEmailToCustomer',
        options: {
            errorPolicy: 'all',
        },
    }),

    graphql(customerBriefQuery, {
        options: props => ({
            variables: { id: props.match.params.id },
            fetchPolicy: 'network-only',
            options: {
                errorPolicy: 'all',
            },
        }),
    }),
)(withStyles(styles)(Client_Privat_View));
