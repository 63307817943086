import gql from 'graphql-tag';

export const allClaimsQuery = gql`

query AllClaimsQuery ($contractId: Int, $customerId: Int, $filter: String)  {
    claims (contractId: $contractId, customerId: $customerId, filter: $filter)  {
        claims {
            claim_id
            insurance_contract_id
            number
            incident_date_time
            description
            responsibility_type
            status
            registration_date
            claim_evidence_text
            counterpart_description
            counterpart_plate_number
            event_description
            liquidation_date
            amount_paid
            reserved_amount
            professiona_claim_evaluation_text
            tipologia_di_danno
            sinistro_multiplo
            riscontro_cliente
            contract {
                insurance_contract_id
                contract_number
                insurance_type_cd
                customer {
                    customer_id 
                    display_name
                    customer_type_cd
                    first_name
                    last_name
                }
            }
        }
        customerContractCount
    }
}

`;


export const claimByIdQuery = gql`

query claimByIdQuery($id: ID, $contractId: Int) {
    claim(id: $id, contractId: $contractId) {
        claim_id
        insurance_contract_id
        number
        incident_date_time
        description
        responsibility_type
        status
        registration_date
        claim_evidence_text
        counterpart_description
        counterpart_plate_number
        event_description
        liquidation_date
        amount_paid
        reserved_amount
        professiona_claim_evaluation_text
        tipologia_di_danno
        sinistro_multiplo
        riscontro_cliente
        contract {
                insurance_contract_id
                contract_number
                insurance_type_cd
                customer {
                    customer_id 
                    display_name
                    customer_type_cd
                    first_name
                    last_name
                }
            }
    }
}

`;

export const updateClaimMutation = gql`

mutation updateClaimMutation ($claim: claim_input) {
    updateClaimMutation(claim: $claim){
        id
        validation {
            field
            message
        }
    } 
}

`;

