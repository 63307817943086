import React, { Component } from 'react';
import { getTxt, toDecimalString, toItalianDateString } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';
import { graphql, compose } from 'react-apollo';
import { Typography } from '@material-ui/core';
import gql from 'graphql-tag';
import { p } from 'components/form/Proto';
import $ from 'jquery';
import { HashRouter, BrowserRouter, Route, Link, withRouter } from 'react-router-dom';
import { Button } from '@material-ui/core';
import * as Icon from '@material-ui/icons';
import { authInfo, getToken } from 'global/auth';
import { ReportTypes } from 'global/reporting';
import ChartCard from 'components/dashboard/ChartCard';
import ChartistGraph from 'react-chartist';
var Chartist = require("chartist");
import {
    SortingState, SelectionState, FilteringState, GroupingState,
    IntegratedFiltering, IntegratedGrouping, IntegratedSorting, IntegratedSelection,
} from '@devexpress/dx-react-grid';
import classNames from 'classnames';
import {
    Grid,
    Table,
    VirtualTable, TableHeaderRow, TableFilterRow, TableSelection, TableGroupRow,
    GroupingPanel, DragDropProvider, TableColumnReordering, Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import LinearProgressEx from '../../components/LinearProgressEx';
import * as queries from '../../modules/contract/contractQueries';
import { getLabel, DictionaryTypes, getDictionary } from '../../global/dictionaryCache';
import RegularCard from '../../components/dashboard/RegularCard';
import MaterialForm from '../../components/form/MaterialForm';
import GridEx from 'components/form/GridEx';
import c from '../../components/form/Components';
import { } from '../../global/dictionaryCache';
import { addDescriptor } from '../../global/entityDescriptors';
import ComponentEx from '../../components/ComponentEx';
import { getReportDataQuery } from './reportQueries';
import { companiesAsDictionaryQuery } from '../company/CompanyQueries';



const styles = theme => ({
    root: {
        paddingLeft: 50,
    },
    table: {
        borderCollapse: 'collapse',
    }
});


class ClaimReport_print extends ComponentEx {

    state = {
        companies: undefined,
    }

    async getCompanies() {
        const { data } = await window.apolloClient.query({
            query: companiesAsDictionaryQuery,
            options: {
                fetchPolicy: 'network-only',
            },
        });

        this.setState({ companies: data.companyDictionary });
    }

    componentWillMount() {
        if (this.props.match.params.companyId !== 'false') {
            this.getCompanies();
        }
    }

    componentDidMount() {
        $('header').hide();
        $('header').next().hide();
    }

    render() {

        const { classes } = this.props;
        const reportData = this.props.data.reportData ? JSON.parse(this.props.data.reportData) : null;

        let itemsStatus = reportData ? reportData.status : [];
        let itemsRespType = reportData ? reportData.responsibilityType : [];
        let itemsTipologia = reportData ? reportData.tipologia : [];

        let filtercompanyName;
        if (this.props.match.params.companyId !== 'false' && this.state.companies) {
            const company = this.state.companies.find(i => i.value.toString() === this.props.match.params.companyId.toString());
            filtercompanyName = company.label;
        }

        return (

            <div className={classes.root}>
                <LinearProgressEx loading={this.props.data.loading} />
                <div style={{ marginBottom: 30 }}>
                    <Typography variant="h4" noWrap>{getTxt('Claims')}</Typography>
                </div>

                <table style={{ marginBottom: 20 }}>
                    {this.props.match.params.from !== 'false' &&
                        <tr>
                            <td>
                                <Typography noWrap>{getTxt("Data d'inizio dell'incidente") + ': '}</Typography>
                            </td>
                            <td>
                                <Typography noWrap>{toItalianDateString(this.props.match.params.from)}</Typography>
                            </td>
                        </tr>
                    }
                    {this.props.match.params.to !== 'false' &&
                        <tr>
                            <td>
                                <Typography noWrap>{getTxt('Incident Date Time to') + ': '}</Typography>
                            </td>
                            <td>
                                <Typography noWrap>{toItalianDateString(this.props.match.params.to)}</Typography>
                            </td>
                        </tr>
                    }
                    {filtercompanyName &&
                        <tr>
                            <td>
                                <Typography noWrap>{getTxt('Company') + ': '}</Typography>
                            </td>
                            <td>
                                <Typography noWrap>{filtercompanyName}</Typography>
                            </td>
                        </tr>
                    }
                </table>



                <table style={{ borderCollapse: 'collapse' }} id="printableTable">
                    <tr>
                        <th>{getTxt('Status')}</th>
                        <th>{getTxt('Count')}</th>
                    </tr>
                    {
                        itemsStatus.length > 0 && itemsStatus.map((i) => {
                            return (
                                <tr>
                                    <td>
                                        {getLabel(DictionaryTypes.ClaimStatus, i.status)}
                                    </td>
                                    <td>
                                        {i.count}
                                    </td>
                                </tr>
                            );
                        })
                    }
                    {
                        itemsStatus.length === 0 &&
                        <tr>
                            <td colSpan="2">
                                {getTxt('No Data')}
                            </td>
                        </tr>
                    }
                </table>
                <br />
                <table style={{ borderCollapse: 'collapse' }} id="printableTable">
                    <tr>
                        <th>{getTxt('Responsibility Type')}</th>
                        <th>{getTxt('Count')}</th>
                    </tr>
                    {
                        itemsRespType.length > 0 && itemsRespType.map((i) => {
                            return (
                                <tr>
                                    <td>
                                        {getLabel(DictionaryTypes.ResponsibilityType, i.responsibility_type)}
                                    </td>
                                    <td>
                                        {i.count}
                                    </td>
                                </tr>
                            );
                        })
                    }
                    {
                        itemsRespType.length === 0 &&
                        <tr>
                            <td colSpan="2">
                                {getTxt('No Data')}
                            </td>
                        </tr>
                    }
                </table>

                <br />
                <table style={{ borderCollapse: 'collapse' }} id="printableTable">
                    <tr>
                        <th>{getTxt('Tipologia di Danno')}</th>
                        <th>{getTxt('Count')}</th>
                    </tr>
                    {
                        itemsTipologia.length > 0 && itemsTipologia.map((i) => {
                            return (
                                <tr>
                                    <td>
                                        {i.tipologia_di_danno ? i.tipologia_di_danno : 'non specificato'}
                                    </td>
                                    <td>
                                        {i.count}
                                    </td>
                                </tr>
                            );
                        })
                    }
                    {
                        itemsTipologia.length === 0 &&
                        <tr>
                            <td colSpan="2">
                                {getTxt('No Data')}
                            </td>
                        </tr>
                    }
                </table>

            </div>
        );
    }
}


export default compose(
    graphql(getReportDataQuery, {
        options: props => (
            {
                variables: {
                    reportType: 'claims',
                    params: JSON.stringify({
                        from: props.match.params.from !== 'false' ? props.match.params.from : '',
                        to: props.match.params.to !== 'false' ? props.match.params.to : '',
                        companyId: props.match.params.companyId !== 'false' ? props.match.params.companyId : ''
                    })
                },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }
        ),
    }),

)(withRouter(withStyles(styles)(ClaimReport_print)));

