import React, { Component } from 'react';
import { Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        padding: 8 * 3,
        backgroundColor: theme.palette.background.paper,
        border: 'px solid lightgrey ',
    },
});

class TabContainer extends Component {
    render() {
        const { classes } = this.props;
        return (
            <Paper className={classes.root}>{this.props.children}</Paper>
        );
    }
}

export default withStyles(styles, { withTheme: true })(TabContainer);