import React, { Component } from 'react';
import { getTxt } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import { Paper } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { AppBar } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { TextField } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';

import * as Icon from '@material-ui/icons';
import { Checkbox } from '@material-ui/core';

import GridEx from 'components/form/GridEx';

import { Button } from '@material-ui/core';
import moment from 'moment';
import { Badge } from '@material-ui/core';

import ComponentEx from 'components/ComponentEx';

import { p } from 'components/form/Proto';

import { LinearProgress } from '@material-ui/core';
import { graphql, compose } from 'react-apollo';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';

import c from '../../components/form/Components';
import LinearProgressEx from '../../components/LinearProgressEx';
import { getLabel, DictionaryTypes, getDictionary } from '../../global/dictionaryCache';
import MaterialForm from '../../components/form/MaterialForm';
import MFormText from '../../components/form/MFormText';
import * as queries from '../../modules/referent/referentQueries';

let history;

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing.unit * 2,
        margin: theme.spacing.unit * 2,
    },
});


class Referent_Edit extends ComponentEx {

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
    }

    state = {
        open: false,
    };

    onSubmit(e, { obj, showErrors, hideErrors }) {
        const originalQuote = this.props.data.referentById;
        obj.referent_id = originalQuote.referent_id;
        obj.parent_id = this.props.match.params.parentId;

        this.props.updateReferent({
            variables: { referent: obj },
            refetchQueries: [
                {
                    query: queries.allReferentsQuery,
                },
                {
                    query: queries.referentByIdQuery,
                    variables: { id: originalQuote.referent_id },
                },
            ],
        }, this).then(
            (resp) => {
                hideErrors();
                if (resp.data.updateReferentMutation &&
                    resp.data.updateReferentMutation.validation &&
                    resp.data.updateReferentMutation.validation.length > 0) {
                    showErrors(resp.data.updateReferentMutation.validation);
                } else {
                    history.goBack();
                }
            });
    }


    render() {

        const { classes } = this.props;

        const loading = this.props.data.loading;
        const item = this.props.data.referentById;
        history = this.props.history;
        let caption;

        let hasIdParam;
        if (this.props.id) {
            hasIdParam = true;
        }
        if (this.props.match.params && this.props.match.params.id) {
            hasIdParam = true;
        }

        if (hasIdParam) {
            caption = 'Modifica Referente';
        } else {
            caption = 'Nuovo Referente';
        }
        return (


            <div className={classes.root}>

                <Typography variant="h4" color="inherit" noWrap={false}>{getTxt(caption)}</Typography>
                <LinearProgressEx loading={loading} />

                {item &&
                    <div>
                        <MaterialForm dataSource={item} objType="referent" onSubmit={this.onSubmit} >

                            <c.field f="first_name" l="Nome" />
                            <c.field f="last_name" l="Cognome" />
                            <c.field f="job_title" l="Mansione" />
                            <c.field f="phone" l="Telefono" />
                            <c.field f="mobile_phone" l="Cellulare" />
                            <c.field f="email" l="Email" />
                            <c.formButtons sm={12} onCancel={() => { this.props.history.goBack(); }} />
                        </MaterialForm>

                    </div>
                }

            </div>




        );
    }
}




export default compose(
    graphql(queries.referentByIdQuery, {
        options: props => (
            {
                variables: {
                    id: (!props.id ? (props.match.params.id ? props.match.params.id : 'new') : props.id),
                },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }
        ),
    }),
    graphql(queries.updateReferentMutation, {
        name: 'updateReferent',
        options: {
            errorPolicy: 'all',
           
        },
    }),
)(withStyles(styles)(Referent_Edit));
