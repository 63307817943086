import { getTxt, resizeService, getClientHeight } from 'global/appGlobal';
import * as Icon from '@material-ui/icons';
import { Divider } from '@material-ui/core';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { addAuthListener, authInfo, initFromCookie, logout } from 'global/auth';
import React, { Component } from 'react';
import { Collapse } from '@material-ui/core';
import { ExpandLess } from '@material-ui/icons';
import { ExpandMore } from '@material-ui/icons';
import { getConfigValue } from '../../global/appGlobal';

const styles = theme => ({
    root: {
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit * 2,
        backgroundColor: 'rgb(90,90,90)',// jacob-color-change
        opacity: 1,

    },
    drawerHeader:
    {
        ...theme.mixins.toolbar,
        marginBottom: theme.spacing.unit,
    },
    nested: {
        paddingLeft: theme.spacing.unit,
    },
    menuIcon: {
        paddingLeft: 0,
        paddingRight: 0,
        marginLeft: 0,
        marginRight: 0,
        minWidth: theme.spacing.unit * 5,
    },
    menuText: {
        paddingLeft: 0,
        paddingRight: 0,
        marginLeft: 0,
        marginRight: 0,
    },
    loginDivider: {
        backgroundColor: theme.palette.background.paper,
        opacity: 0.5,
    },
});

class MainMenu extends Component {
    static defaultProps = {
        schema: [],
    }

    constructor(props) {
        super(props);
        this.onMenuClick = this.onMenuClick.bind(this);
        this.trimName = this.trimName.bind(this);
        // resizeService.addResizable('mainMenu', () => {
        //     const h = getClientHeight();
        //     document.getElementById('mainMenu').style.height = h + 'px';
        // })
    }

    state = {
        open: false,
        loginInfo: undefined,
        userMenuOpen: false,
        adminMenuOpen: false,
        paymentsMenuOpen: false,
        dashboardOpen: true,
    };

    componentWillMount() {
        initFromCookie(this.props.history);
        this.setState({ loginInfo: authInfo.loginInfo });

        addAuthListener((loginInfo) => {
            this.setState({ loginInfo });
        });

    }

    componentWillReceiveProps() {
        // const h = getClientHeight();
        // if (document.getElementById('mainMenu')) {
        //     document.getElementById('mainMenu').style.height = h + 'px';
        // }
    }

    onLogout() {
        logout();
    }

    onMenuClick(path) {
        if (this.props.onClose) {
            this.props.onClose();
        }
        this.props.history.push(path);
    }

    trimName(name) {
        if (name && name.length && name.length > 8) {
            return name.substr(0, 5) + '...';
        }
        return name;
    }

    render() {

        const { classes, theme } = this.props;

        return (
            <div id="mainMenu" className={classes.root}>
                <div className={classes.drawerHeader} />


                <List>

                    {/* <Divider key="3" className={classes.loginDivider} /> */}
                    {this.state.loginInfo &&

                        <ListItem button className={classes.nested} onClick={() => { this.setState({ userMenuOpen: !this.state.userMenuOpen }); }} >
                            <ListItemIcon className={classes.menuIcon} ><Icon.AccountCircle /></ListItemIcon>
                            <ListItemText
                                inset
                                primary={this.trimName(this.state.loginInfo.userInfo.displayName)}
                                className={classes.menuText}
                            />
                            {this.state.userMenuOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>



                    }
                    {this.state.loginInfo &&
                        <Collapse in={this.state.userMenuOpen} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {(authInfo.isMasterBroker() || authInfo.isBroker() || authInfo.isSubBroker()) && !authInfo.isAgencyAdministrator() &&
                                    <ListItem button onClick={() => { this.onMenuClick('/brokers/edit/' + authInfo.loginInfo.userInfo.user_id); }} >
                                        <ListItemIcon className={classes.menuIcon} ><Icon.Settings /></ListItemIcon>
                                        <ListItemText inset primary={getTxt('My Profile')} className={classes.menuText} />
                                    </ListItem>}
                                {(authInfo.isAgencyAdministrator()) &&
                                    <ListItem button onClick={() => { this.onMenuClick('/admin/profile'); }} >
                                        <ListItemIcon className={classes.menuIcon} ><Icon.Settings /></ListItemIcon>
                                        <ListItemText inset primary={getTxt('My Profile')} className={classes.menuText} />
                                    </ListItem>}
                                {(authInfo.isCompany()) &&
                                    <ListItem button onClick={() => { this.onMenuClick('/company/profile'); }} >
                                        <ListItemIcon className={classes.menuIcon} ><Icon.Settings /></ListItemIcon>
                                        <ListItemText inset primary={getTxt('My Profile')} className={classes.menuText} />
                                    </ListItem>}
                                {(authInfo.isCustomer()) &&
                                    <ListItem button onClick={() => { this.onMenuClick('/customer/profile'); }} >
                                        <ListItemIcon className={classes.menuIcon} ><Icon.Settings /></ListItemIcon>
                                        <ListItemText inset primary={getTxt('My Profile')} className={classes.menuText} />
                                    </ListItem>}
                            </List>
                            <List component="div" disablePadding>
                                <ListItem button onClick={() => { logout(); }} >
                                    <ListItemIcon className={classes.menuIcon} ><Icon.ExitToApp /></ListItemIcon>
                                    <ListItemText inset primary={getTxt('Logout', '#pmd83nd')} className={classes.menuText} />
                                </ListItem>
                            </List>

                        </Collapse>
                    }
                    <Divider key="4" className={classes.loginDivider} style={{ marginBottom: theme.spacing.unit * 2 }} />

                    {(authInfo.isAgencyAdministrator() || authInfo.isMasterBroker() || authInfo.isBroker() || authInfo.isSubBroker()) &&
                        <ListItem button className={classes.nested} onClick={() => { this.setState({ dashboardOpen: !this.state.dashboardOpen }); }} >
                            <ListItemIcon className={classes.menuIcon} ><Icon.Dashboard /></ListItemIcon>
                            <ListItemText inset primary={getTxt('Dashboard')} className={classes.menuText} />
                            {this.state.dashboardOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                    }
                    {(authInfo.isAgencyAdministrator() || authInfo.isMasterBroker()) &&
                        <Collapse in={this.state.dashboardOpen} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem button onClick={() => { this.onMenuClick('/dashboard/management'); }} >
                                    <ListItemIcon className={classes.menuIcon} ><Icon.Dashboard /></ListItemIcon>
                                    <ListItemText inset primary={getTxt('Management')} className={classes.menuText} />
                                </ListItem>
                            </List>
                            <List component="div" disablePadding>
                                <ListItem button onClick={() => { this.onMenuClick('/dashboard/operational'); }} >
                                    <ListItemIcon className={classes.menuIcon} ><Icon.Dashboard /></ListItemIcon>
                                    <ListItemText inset primary={getTxt('Operativa')} className={classes.menuText} />
                                </ListItem>
                            </List>
                        </Collapse>
                    }


                    {(authInfo.isBroker() || authInfo.isSubBroker()) &&
                        <Collapse in={this.state.dashboardOpen} timeout="auto" unmountOnExit>

                            <List component="div" disablePadding>
                                <ListItem button onClick={() => { this.onMenuClick('/dashboard/operational'); }} >
                                    <ListItemIcon className={classes.menuIcon} ><Icon.Dashboard /></ListItemIcon>
                                    <ListItemText inset primary={getTxt('Operativa')} className={classes.menuText} />
                                </ListItem>
                            </List>
                        </Collapse>
                    }

                    {(!authInfo.isCustomer()) &&
                        <ListItem button className={classes.nested} onClick={() => { this.onMenuClick('/customers/all'); }} >
                            <ListItemIcon className={classes.menuIcon}><Icon.AccountBox /></ListItemIcon>
                            <ListItemText inset primary={getTxt('Cliente', '#2q9257z')} className={classes.menuText} />
                        </ListItem>
                    }


                    <ListItem button className={classes.nested} onClick={() => { this.onMenuClick('/requests/all'); }} >
                        <ListItemIcon className={classes.menuIcon}><Icon.DirectionsCar /></ListItemIcon>
                        <ListItemText inset primary={getTxt('Richieste', '#60613w6')} className={classes.menuText} />
                    </ListItem>


                    {
                        <ListItem button className={classes.nested} onClick={() => { this.onMenuClick('/contracts/all'); }} >
                            <ListItemIcon className={classes.menuIcon}><Icon.Class /></ListItemIcon>
                            <ListItemText inset primary={getTxt('Polizze', '#cmuc3y8')} className={classes.menuText} />
                        </ListItem>
                    }
                    {
                        <ListItem button className={classes.nested} onClick={() => { this.onMenuClick('/claims'); }} >
                            <ListItemIcon className={classes.menuIcon}><Icon.Class /></ListItemIcon>
                            <ListItemText inset primary={getTxt('Claims')} className={classes.menuText} />
                        </ListItem>
                    }

                    {!authInfo.isCustomer() && !authInfo.isSubBroker() && !authInfo.isBroker() &&
                        <ListItem button className={classes.nested} onClick={() => { this.onMenuClick('/company/search'); }} >
                            <ListItemIcon className={classes.menuIcon}><Icon.Class /></ListItemIcon>
                            <ListItemText inset primary={getTxt('Companies')} className={classes.menuText} />
                        </ListItem>
                    }


                    {(authInfo.isAgencyAdministrator() || authInfo.isMasterBroker() || authInfo.isBroker()) &&
                        <ListItem button className={classes.nested} onClick={() => {
                            this.onMenuClick('/brokers');
                        }}
                        >
                            <ListItemIcon className={classes.menuIcon}><Icon.Person /></ListItemIcon>
                            <ListItemText inset primary={getTxt('Collaborate')} className={classes.menuText} />

                        </ListItem>
                    }


                    {/* <Collapse in={this.state.brokerMenuOpen} timeout="auto" unmountOnExit>



                     </Collapse> */}


                    {
                        !authInfo.isCustomer() &&
                        <ListItem button className={classes.nested} onClick={() => { this.setState({ paymentsMenuOpen: !this.state.paymentsMenuOpen }); }} >
                            <ListItemIcon className={classes.menuIcon} ><Icon.VerifiedUser /></ListItemIcon>
                            <ListItemText
                                inset
                                primary={getTxt('Payments')}
                                className={classes.menuText}
                            />
                            {this.state.adminMenuOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                    }


                    {<Collapse in={this.state.paymentsMenuOpen} timeout="auto" unmountOnExit >

                        {(authInfo.isAgencyAdministrator() || authInfo.isMasterBroker() || authInfo.isBroker()) &&
                            <List component="div" disablePadding>
                                <ListItem button onClick={() => { this.onMenuClick('/brokers/payments/all'); }} >
                                    <ListItemIcon className={classes.menuIcon}><Icon.Payment /></ListItemIcon>
                                    <ListItemText inset primary={getTxt('Broker')} className={classes.menuText} />
                                </ListItem>
                            </List>
                        }

                        {(authInfo.isAgencyAdministrator() || authInfo.isMasterBroker()) &&


                            <List component="div" disablePadding>
                                <ListItem button onClick={() => { this.onMenuClick('/companies/payments/all'); }} >
                                    <ListItemIcon className={classes.menuIcon}><Icon.Payment /></ListItemIcon>
                                    <ListItemText inset primary={getTxt('Company')} className={classes.menuText} />
                                </ListItem>

                            </List>

                        }

                        {
                            <List component="div" disablePadding>
                                <ListItem button onClick={() => { this.onMenuClick('/customers/payments/all'); }} >
                                    <ListItemIcon className={classes.menuIcon}><Icon.Payment /></ListItemIcon>
                                    <ListItemText inset primary={getTxt('Customer')} className={classes.menuText} />
                                </ListItem>
                            </List>
                        }

                    </Collapse>
                    }

                    {/*



                <List>
                    <ListItem button className={classes.nested} onClick={() => { this.onMenuClick('/settings'); }} >
                        <ListItemIcon className={classes.menuIcon}><Icon.Settings /></ListItemIcon>
                        <ListItemText inset primary={getTxt('Settings', '#65ybjsw')} className={classes.menuText} />
                    </ListItem>
                </List>

*/}

                    {(authInfo.isAgencyAdministrator() || authInfo.isMasterBroker() || authInfo.isBroker()) &&
                        <ListItem button className={classes.nested} onClick={() => { this.onMenuClick('/reports'); }} >
                            <ListItemIcon className={classes.menuIcon}><Icon.Print /></ListItemIcon>
                            <ListItemText inset primary={getTxt('Reports', '#8ioh2e8')} className={classes.menuText} />
                        </ListItem>
                    }

                    {(authInfo.isAgencyAdministrator() || authInfo.isMasterBroker() || authInfo.isBroker()) && getConfigValue('partnerAgencies').length > 0 &&
                        <ListItem button className={classes.nested} onClick={() => { this.onMenuClick('/shared-search'); }} >
                            <ListItemIcon className={classes.menuIcon}><Icon.Search /></ListItemIcon>
                            <ListItemText inset primary={getTxt('Ricerca Condivisa')} className={classes.menuText} />
                        </ListItem>
                    }


                    {(authInfo.isAgencyAdministrator() || authInfo.isMasterBroker()) &&
                        <ListItem button className={classes.nested} onClick={() => { this.setState({ adminMenuOpen: !this.state.adminMenuOpen }); }} >
                            <ListItemIcon className={classes.menuIcon} ><Icon.VerifiedUser /></ListItemIcon>
                            <ListItemText
                                inset
                                primary={getTxt('Admin')}
                                className={classes.menuText}
                            />
                            {this.state.adminMenuOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                    }




                    {(authInfo.isAgencyAdministrator() || authInfo.isMasterBroker()) &&
                        <Collapse in={this.state.adminMenuOpen} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem button onClick={() => { this.onMenuClick('/conti_transito'); }} >
                                    <ListItemIcon className={classes.menuIcon}><Icon.Payment /></ListItemIcon>
                                    <ListItemText inset primary={getTxt('Contabilità')} className={classes.menuText} />
                                </ListItem>
                            </List>
                            <List component="div" disablePadding>
                                <ListItem button onClick={() => { this.onMenuClick('/real_insurance_types'); }} >
                                    <ListItemIcon className={classes.menuIcon}><Icon.Class /></ListItemIcon>
                                    <ListItemText inset primary={getTxt('Real Ins. Types', '#aj2smh5')} className={classes.menuText} />
                                </ListItem>
                            </List>
                            <List component="div" disablePadding>
                                <ListItem button onClick={() => { this.onMenuClick('/insurance_types'); }} >
                                    <ListItemIcon className={classes.menuIcon}><Icon.Class /></ListItemIcon>
                                    <ListItemText inset primary={getTxt('User Ins. Types', '#1pcx1po')} className={classes.menuText} />
                                </ListItem>
                            </List>
                            <List component="div" disablePadding>
                                <ListItem button onClick={() => { this.onMenuClick('/payment_types'); }} >
                                    <ListItemIcon className={classes.menuIcon}><Icon.Payment /></ListItemIcon>
                                    <ListItemText inset primary={getTxt('User Paym. Types')} className={classes.menuText} />
                                </ListItem>
                            </List>
                            <List component="div" disablePadding>
                                <ListItem button onClick={() => { this.onMenuClick('/guaranty_types'); }} >
                                    <ListItemIcon className={classes.menuIcon}><Icon.Person /></ListItemIcon>
                                    <ListItemText inset primary={getTxt('Guaranty Types', '#d74y30s')} className={classes.menuText} />
                                </ListItem>
                            </List>
                            <List component="div" disablePadding>
                                <ListItem button onClick={() => { this.onMenuClick('/contract_items_types'); }} >
                                    <ListItemIcon className={classes.menuIcon}><Icon.Class /></ListItemIcon>
                                    <ListItemText inset primary={getTxt('Movement Types')} className={classes.menuText} />
                                </ListItem>
                            </List>
                            <List component="div" disablePadding>
                                <ListItem button onClick={() => { this.onMenuClick('/dictionaries'); }} >
                                    <ListItemIcon className={classes.menuIcon}><Icon.Subject /></ListItemIcon>
                                    <ListItemText inset primary={getTxt('Dictionaries', '#9n43tdr')} className={classes.menuText} />
                                </ListItem>
                            </List>


                            <List component="div" disablePadding>
                                <ListItem
                                    button
                                    onClick={() => { this.onMenuClick('/email_templates'); }}
                                >
                                    <ListItemIcon className={classes.menuIcon} ><Icon.Email /></ListItemIcon>
                                    <ListItemText inset primary={getTxt('Email Templates')} className={classes.menuText} />
                                </ListItem>
                            </List>
                            <List component="div" disablePadding>
                                <ListItem
                                    button
                                    onClick={() => { this.onMenuClick('/reports_header'); }}
                                >
                                    <ListItemIcon className={classes.menuIcon} ><Icon.Settings /></ListItemIcon>
                                    <ListItemText inset primary={getTxt('Dati Aziendali')} className={classes.menuText} />
                                </ListItem>
                            </List>

                            <List component="div" disablePadding>
                                <ListItem button onClick={() => { this.onMenuClick('/settings'); }} >
                                    <ListItemIcon className={classes.menuIcon}><Icon.Settings /></ListItemIcon>
                                    <ListItemText inset primary={getTxt('Settings')} className={classes.menuText} />
                                </ListItem>
                            </List>

                            <List component="div" disablePadding>
                                <ListItem button onClick={() => { this.onMenuClick('/changelog'); }} >
                                    <ListItemIcon className={classes.menuIcon}><Icon.Subject /></ListItemIcon>
                                    <ListItemText inset primary={getTxt('Change Log')} className={classes.menuText} />
                                </ListItem>
                            </List>

                        </Collapse>
                    }


                </List>

                <Divider key="2" />

            </div >
        );
    }
}

export default withStyles(styles, { withTheme: true })(MainMenu);
