import React from 'react';
import { graphql, compose } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import $ from 'jquery';
import { Link } from 'react-router-dom';

import {
    SortingState, FilteringState, IntegratedFiltering, IntegratedSorting,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    VirtualTable, TableHeaderRow, TableFilterRow,
} from '@devexpress/dx-react-grid-material-ui';
import gql from 'graphql-tag';
import GridEx from 'components/form/GridEx';
import ComponentEx from 'components/ComponentEx';
import { Button, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import c from 'components/form/Components';
import { getTxt, toItalianDateString, toDecimalString, tableMessages } from '../../global/appGlobal';
import LinkToCustomerById from '../../components/LinksToObjectById';
import LinearProgressEx from '../../components/LinearProgressEx';
import { getLabel, getDictionary, DictionaryTypes } from '../../global/dictionaryCache';
import { allRequestsQuery } from '../../modules/request/requestQeries';
import { Menu, MenuItem } from '@material-ui/core';
import CurrencyGridFormatter from 'components/CurrencyGridFormatter';
import { IconButton } from '@material-ui/core';

import * as Icons from '@material-ui/icons';
import RegularCard from '../../components/dashboard/RegularCard';
import * as queries from '../../modules/client/clientQueries';
import Customer_Payment_Edit from '../../modules/client/Client_Payment_Edit';
import { Number } from 'core-js';

import { companyBalanceQuery } from './CompanyQueries';
import { addDescriptor } from '../../global/entityDescriptors';
import { ReportTypes } from '../../global/reporting';
import MaterialForm from '../../components/form/MaterialForm';

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        margin: theme.spacing.unit * 3,
    },
    button: {
        marginRight: theme.spacing.unit,
    },

    tableHeader: {
        backgroundColor: theme.palette.background.paper,
    },
    menuItem: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& $text, & $icon': {
                color: theme.palette.common.white,
            },
        },
    },
    text: {},
    icon: {},
    totalsRow: {
        backgroundColor: theme.palette.grey['200'],
        fontWeight: 'bold',
    },
    filterForemRoot: {
        paddingLeft: theme.spacing.unit * 2,
        paddingRight: theme.spacing.unit * 2,
    },
});

let reports_filter_obj_descr;

const OBG_DESCR_KEY = 'company_balance_filter';

let reportsFilters = {
    start_date_from: null,
    start_date_to: null,
};

const tableColumnExtensions = [
    { columnName: 'total_amount_client', align: 'right' },
    { columnName: 'PaidAmount', align: 'right' },
];


class Company_Balance_Search extends ComponentEx {



    constructor() {
        super();
        this.formChanged = this.formChanged.bind(this);
        this.appplyFilters = this.appplyFilters.bind(this);
        reports_filter_obj_descr = {
            from: {
                id: 'from',
                label: getTxt('Transaction Date from'),
                type: 'DATEONLY',
                validators: [],
            },
            to: {
                id: 'to',
                label: getTxt('Transaction Date to'),
                type: 'DATEONLY',
                validators: [],
            },
        };

        reportsFilters = {
            from: null,
            to: null,
        };

        addDescriptor(OBG_DESCR_KEY, reports_filter_obj_descr);
        this.getRowId = row => row.customer_payment_id;
    }

    state = {
        anchorEl: null,
        open: false,
        editId: undefined,
        delDlgOpen: false,
        delId: undefined,
        from: null,
        to: null,
    };

    formSetValues = { func: undefined };

    formChanged(field, value, type, prevVal) {
        reportsFilters[field] = value;
        const stateVal = {};
        this.state[field] = value;
        this.props.data.refetch({
            i: Date.now(),
            companyId: this.props.companyId,
            from: this.state.from,
            to: this.state.to,
        });
    }

    appplyFilters() {
        this.props.data.refetch({
            i: Date.now(),
            companyId: this.props.companyId,
            from: this.state.from,
            to: this.state.to,
        });
    }


    render() {

        if (this.props.quiet) {
            return (<LinearProgressEx loading={this.props.data.loading} />);
        }

        const columns = [
            { name: 'TransactionDate', title: getTxt('Data Incasso') },
            { name: 'ItemType', title: getTxt('Ramo') },
            { name: 'item_type_cd', title: getTxt('Tipo Movimento') },
            { name: 'contract_number', title: getTxt('Numero Polizza') },
            { name: 'customer_name', title: getTxt('Client') },
            { name: 'total_amount_client', title: getTxt('Amount Company') },
            { name: 'PaidAmount', title: getTxt('Versamento') },
            { name: 'payment_notes', title: getTxt('Payment Notes') },
            { name: 'total_amount_agency', title: getTxt('Provvigioni') },
        ];

        const { classes } = this.props;

        const resp = this.props.data.companyBalance;

        const items = resp ? JSON.parse(resp) : [];
        const loading = this.props.data.loading;

        const caption = getTxt('Company Payments');
        const subtitle = getTxt('List of company payments.');

      
        let totalPremio = 0;
        let totalVersamento = 0;
        let totalProvigiony = 0;

        if (items.length > 0) {

            items.forEach((i) => {
                if (i.total_amount_client) {
                    totalPremio += Number.parseFloat(i.total_amount_client);
                }
                if (i.PaidAmount) {
                    totalVersamento += Number.parseFloat(i.PaidAmount);
                }
                if (i.total_amount_agency) {
                    totalProvigiony += Number.parseFloat(i.total_amount_agency);
                }

                if (i.item_type_cd) {
                    if (i.item_type_cd === 'Sostituzione' || i.item_type_cd === 'sostituzione' || i.item_type_cd === 'Sostituita') {
                        i.item_type_cd = 'Sost';
                    } else if (i.item_type_cd === 'Sospensione') {
                        i.item_type_cd = 'Sosp';
                    } else if (i.item_type_cd.length > 3) {
                        i.item_type_cd = i.item_type_cd.substr(0, 3);
                    }
                }
            });

            items.push({

                ID: -1,
                TransactionDate: '',
                ItemType: '',
                item_type_cd: '',
                contract_number: '',
                customer_name: '',
                total_amount_client: totalPremio,
                PaidAmount: totalVersamento,
                payment_notes: '',
                total_amount_agency: totalProvigiony,

            });
        }

        return (

            <div className={classes.root}>
                <LinearProgressEx loading={loading} />

                {!this.props.noCaption && <RegularCard

                    headerColor="red"
                    cardTitle={caption}
                    cardSubtitle={subtitle}
                    content={
                        <div>
                            {<Button
                                color="primary"
                                className={classes.button}
                                onClick={() => { this.setState({ editId: 'new' }); }}
                            >{getTxt('Add new Payment')}</Button>}
                        </div>

                    }
                />}
                <div className={classes.filterForemRoot}>
                    <MaterialForm
                        dataSource={reportsFilters}
                        objType={OBG_DESCR_KEY}
                        onChange={this.formChanged}
                        setValues={this.formSetValues}
                    >

                        <c.field f="from" />
                        <c.field f="to" />


                        <GridEx item sm={'auto'} xs={12}>

                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    reportsFilters.from = '';
                                    reportsFilters.to = '';


                                    this.formSetValues.func({
                                        from: '',
                                        to: '',
                                        companyId: '',
                                    });
                                    this.setState({
                                        from: '',
                                        to: '',
                                        companyId: '',
                                    });
                                    this.appplyFilters();
                                }}
                            >
                                {getTxt('Reset Filters')}
                            </Button>
                        </GridEx>
                        <GridEx item sm={'auto'} xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {

                                    const newWindow = window.open("", "_blank");
                                    newWindow.document.write(getTxt('Generating PDF Report, please wait...'));
                                    this.props.getOneTimeToken({

                                    }, this).then(
                                        (resp_ott) => {

                                            if (resp_ott.data && resp_ott.data.oneTimeTokenMutation && resp_ott.data.oneTimeTokenMutation.token) {

                                                newWindow.location.href = `${window.apiUrl}files/reports?ott=${resp_ott.data.oneTimeTokenMutation.token}&type=${ReportTypes.CompanyBalance}&params=${JSON.stringify({ from: this.state.from, to: this.state.to, companyId: this.props.companyId })}`;
                                                newWindow.focus();
                                            }

                                        });
                                }}
                            >
                                {getTxt('Create PDF')}
                            </Button>
                        </GridEx>
                        <GridEx item sm={'auto'} xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {

                                    const newWindow = window.open("", "_blank");
                                    newWindow.document.write(getTxt('Generazione del report XLS, attendere prego...'));
                                    this.props.getOneTimeToken({

                                    }, this).then(
                                        (resp_ott) => {

                                            if (resp_ott.data && resp_ott.data.oneTimeTokenMutation && resp_ott.data.oneTimeTokenMutation.token) {

                                                newWindow.location.href = `${window.apiUrl}files/reports?ott=${resp_ott.data.oneTimeTokenMutation.token}&type=${ReportTypes.CompanyBalance}&params=${JSON.stringify({ from: this.state.from, to: this.state.to, companyId: this.props.companyId, xlsx: true })}`;
                                                newWindow.focus();
                                            }

                                        });
                                }}
                            >
                                {getTxt('Genera XLS')}
                            </Button>

                        </GridEx>

                    </MaterialForm>
                </div>
                <Grid

                    rows={items}
                    columns={columns}

                >

                    <CurrencyGridFormatter
                        for={['total_amount_client', 'PaidAmount']}
                    />

                    <FilteringState
                        defaultFilters={[{}]}
                    />

                    <SortingState
                        defaultSorting={[

                        ]}
                    />

                    <IntegratedFiltering
                        columnExtensions={[

                        ]}
                    />
                    <SortingState
                        defaultSorting={[]}
                    />
                    <IntegratedSorting
                        columnExtensions={[
                        ]}
                    />

                    <VirtualTable
                        messages={tableMessages}
                        columnExtensions={tableColumnExtensions}
                        rowComponent={({ row, ...restProps }) => {
                            if (row.ID === -1) {
                                return (<VirtualTable.Row
                                    className={classes.totalsRow}
                                    {...restProps}
                                />);
                            }
                            return (<VirtualTable.Row {...restProps} />);
                        }}
                        cellComponent={({ row, column, style, ...props }) => {
                            if (column.name === 'TransactionDate') {
                                return (<VirtualTable.Cell>
                                    {toItalianDateString(row.TransactionDate)}
                                </VirtualTable.Cell>);
                            }

                            if (column.name === 'total_amount_agency') {
                                return (<VirtualTable.Cell>
                                    {toDecimalString(row.total_amount_agency)}
                                </VirtualTable.Cell>);
                            }

  

                            return <VirtualTable.Cell {...props} />;
                        }}
                    />

                    <TableHeaderRow />

                </Grid>

                <table className="controTotalTable">
                    <tr>
                        <td>Totale Premi</td>
                        <td>{toDecimalString(totalPremio)}</td>
                    </tr>
                    <tr>
                        <td>Totale Versamenti</td>
                        <td>{toDecimalString(totalVersamento)}</td>
                    </tr>
                    <tr>
                        <td>Differenza</td>
                        <td>{toDecimalString(totalVersamento - totalPremio)}  </td>
                    </tr>
                    <tr>
                        <td>Totale Provvigioni</td>
                        <td>{toDecimalString(totalProvigiony)} </td>
                    </tr>
                    
                </table>

            </div >
        );
    }


}

const oneTimeTokenMutation = gql`

mutation oneTimeTokenMutation  {
    oneTimeTokenMutation{
        token
    }
}
`;

export default compose(
    graphql(oneTimeTokenMutation, {
        name: 'getOneTimeToken',
        options: {
            errorPolicy: 'all',

        },
    }),

    graphql(companyBalanceQuery, {

        options: props => (
            {
                variables:
                {
                    i: Date.now(),
                    companyId: props.companyId,
                },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }),


    }),

)(withStyles(styles)(Company_Balance_Search));
