import { IntegratedSorting, SortingState } from '@devexpress/dx-react-grid';
import { Grid, Table, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import ComponentEx from 'components/ComponentEx';
import { getTxt } from 'global/appGlobal';
import { Button } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import GridEx from 'components/form/GridEx';
import { compose, graphql } from 'react-apollo';
import c from '../../components/form/Components';
import MaterialForm from '../../components/form/MaterialForm';
import LinearProgressEx from '../../components/LinearProgressEx';
import { DictionaryTypes, getDictionaryItem, getLabel } from '../../global/dictionaryCache';
import { getEntityDescriptor } from '../../global/entityDescriptors';
import * as queries from '../../modules/publicInsType/publicInsTypeQueries';
import PublicInsTypeEdit from '../../modules/publicInsType/PublicInsTypeEdit';
import { publicPaymentTypeByIdQuery } from './paymentTypeQueries';
import PublicPaymentTypeEdit from './PublicPaymentTypeEdit';

let history;
//let AfterSubmit;

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    button: {
        marginBottom: theme.spacing.unit * 3,
    },
});

class Public_Paym_Type_Details_View extends ComponentEx {

    state = {
        delId: undefined,
    };

    render() {
        const { classes } = this.props;

        // const loading = this.props.data.loading;
        //const item = this.props.data.publicPaymentTypeById;
        const item = this.props.item;
        history = this.props.history;

        return (

            <div className={classes.root} >
                {/* <LinearProgressEx loading={loading} /> */}
                {item &&
                    <div>
                        <Button
                            color="primary"
                            className={classes.button}
                            onClick={() => { this.setState({ editId: item.public_payment_type_id }); }}
                        >{getTxt('Edit Details')}</Button>

                        {
                            this.state.editId &&
                            <PublicPaymentTypeEdit
                                id={this.state.editId}
                                AfterSubmit={(() => {
                                    this.setState({ editId: undefined });
                                    this.props.data.refetch();
                                })}

                            />
                        }

                        <MaterialForm
                            dataSource={item}
                            objType="public_payment_type"
                        >
                            <GridEx item sm={3} xs={12}>
                                <MaterialForm spacing={12} dataSource={item} objType="public_payment_type" >
                                    <c.readOnlyInLine f="value" l={getTxt('Key')} />
                                    <c.readOnlyInLine f="label" l={getTxt('Label')} />
                                    <c.readOnlyInLine f="real_payment_type_value" l={getTxt('Actual Type')} v={getLabel(DictionaryTypes.PublicPaymentType, item.real_payment_type_value)} />
                                </MaterialForm>
                            </GridEx>
                        </MaterialForm>

                    </div>

                }

            </div>

        );
    }
}



export default withStyles(styles)(Public_Paym_Type_Details_View);
                                        
