import React, { Component } from 'react';
import { getTxt, toItalianDateString } from 'global/appGlobal';
import { withStyles } from '@material-ui/core/styles';
import { graphql, compose } from 'react-apollo';
import { p } from 'components/form/Proto';
import $ from 'jquery';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import c from '../../components/form/Components';
import { Button } from '@material-ui/core';
import GridEx from 'components/form/GridEx';
import DeleteIcon from '@material-ui/icons/Delete';
import { HashRouter, BrowserRouter, Route, Link, withRouter } from 'react-router-dom';
import { TextField } from '@material-ui/core';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import * as Icon from '@material-ui/icons';
import {
    SortingState, SelectionState, FilteringState, GroupingState,
    IntegratedFiltering, IntegratedGrouping, IntegratedSorting, IntegratedSelection,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    VirtualTable, TableHeaderRow, TableFilterRow, TableSelection, TableGroupRow,
    GroupingPanel, DragDropProvider, TableColumnReordering, Toolbar, TableColumnResizing,
} from '@devexpress/dx-react-grid-material-ui';
import LinearProgressEx from '../../components/LinearProgressEx';
import * as queries from '../../modules/contract/contractQueries';
import { getLabel, DictionaryTypes, getDictionary } from '../../global/dictionaryCache';
import RegularCard from '../../components/dashboard/RegularCard';
import ComponentEx from '../../components/ComponentEx';
import { filterRowMessages, tableMessages, createGridResizerCols } from '../../global/appGlobal';
import { Tooltip } from '@material-ui/core';
import { authInfo } from 'global/auth';
import { oneTimeTokenMutation } from '../broker/Broker_Balance_Search';
import { ReportTypes } from '../../global/reporting';
import Items_Select_Dialog from './Items_Select_Dialog';

function getClientPhone(client) {
    if (!client) {
        return '';
    }
    if (client.mobile_phone) {
        return client.mobile_phone;
    }
    if (client.phone) {
        return client.phone;
    }
    if (client.phone_active) {
        return client.phone_active;
    }
    if (client.phone) {
        return client.phone;
    }
    return '';
}

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        margin: theme.spacing.unit * 3,
    },
    button: {
        margin: theme.spacing.unit,
    },

    badge: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        marginTop: 0,

    },
    gridRoot: {
        height: '100%',
    },

    tableHeader: {
        backgroundColor: theme.palette.background.paper,
    },

    _inGridButton: {
        width: 26,
        height: 20,
        marginRight: theme.spacing.unit * 2,
    },
});

const toLowerCase = value => String(value).toLowerCase();

const filterBroker = (row, e, a) => {
    const name = toLowerCase(a.broker.display_name);
    return name.includes(toLowerCase(e.value));
};

const filterCompany = (row, e, a) => {
    const name = toLowerCase(a.company.company_name);
    return name.includes(toLowerCase(e.value));
};

const filterState = (row, e, a) => {
    let state = a.state_cd;
    if (state && state.toUpperCase) {
        state = state.toUpperCase();
    }
    if (state && (state === 'SOSTITUZIONE' || state === 'SOSTITUITO' || state === 'SOSTITUITA') && e.value === 'Sostituzione') {
        return true;
    }
    if (!state) {
        return false;
    }
    return state.toLowerCase().includes(toLowerCase(e.value));
};

const filterCustomer = (row, e, a) => {
    const name = toLowerCase(a.customer.display_name);
    return name.includes(toLowerCase(e.value));
};

const filterSolicito = (row, e, a) => {
    const str = a.customer.email + getClientPhone(a.customer);
    const s = toLowerCase(str);
    return s.includes(toLowerCase(e.value));
};

function isfromDashboard(m) {
    if (m && (m.params.filter === 'will-expiry-next-30-days' || m.params.filter === 'expired-prev-30-days-not-paid')) {
        return true;
    }
    return false;
}

class Contract_Search extends ComponentEx {

    constructor(props) {
        super(props);
        this.submitLinked = this.submitLinked.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.onSolicitedEmailChanged = this.onSolicitedEmailChanged.bind(this);
        this.onSolicitedPhoneChanged = this.onSolicitedPhoneChanged.bind(this);
    }

    state = {
        delId: undefined,
        delNumber: undefined,
    };

    componentDidMount() {
        $("table[class*='Table-headTable']").addClass(this.props.classes.tableHeader);
    }

    submitLinked(contracts) {

        const linkedIds = [];
        contracts.forEach((contract) => {
            if (this.state[contract.insurance_contract_id]) {
                linkedIds.push(contract.insurance_contract_id);
            }
        });

        this.props.updateLinkedContracts({
            variables: { linkedTo: this.props.markIfLinketTo, linkedIds },
            refetchQueries: [
                {
                    query: queries.allContractsQuery,
                    variables: { linkedTo: this.props.markIfLinketTo },
                },
                {
                    query: queries.allContractsQuery,
                    variables: { markIfLinketTo: this.props.markIfLinketTo },
                },
            ],
        }, this).then(
            (resp) => {

                if (this.props.afterSubmitLinked) {
                    this.props.afterSubmitLinked(resp);
                }
            });
    }

    handleDelete(contractId) {
        this.props.deleteContract({
            variables: { contractId: this.state.delId },
            refetchQueries: [
                {
                    query: queries.allContractsQuery,
                    variables: { contractId },
                },
            ],
        }, this).then(
            (resp) => {
                this.setState({ delId: undefined, delNumber: undefined });
                this.props.data.refetch();
            });
    }

    onSolicitedEmailChanged(contractId, value) {
        this.state.solicited[contractId].email = value;
        this.setState({ solicited: this.state.solicited });
        this.props.soliciteCustomer({
            variables: { contractId, email: this.state.solicited[contractId].email, phone: this.state.solicited[contractId].phone },
        });
    }

    onSolicitedPhoneChanged(contractId, value) {
        this.state.solicited[contractId].phone = value;
        this.setState({ solicited: this.state.solicited });
        this.props.soliciteCustomer({
            variables: { contractId, email: this.state.solicited[contractId].email, phone: this.state.solicited[contractId].phone },
        });
    }

    render() {

        if (this.props.quiet) {
            return (<LinearProgressEx loading={this.props.data.loading} />);
        }


        let caption = getTxt('Contracts');
        let subtitle = getTxt('List of insurance contracts.');
        let fromDashboard;
        let will_expiry_next_30_days = false;
        let expired_prev_30_days_not_paid = false;

        if (this.props.match && this.props.match.params.filter === 'will-expiry-next-30-days') {
            subtitle = getTxt('List of insurance contracts that will expiry in the next 30 days.');
            caption = getTxt('Near to expiry Contracts');
            fromDashboard = true;
            will_expiry_next_30_days = true;
        }

        if (this.props.match && this.props.match.params.filter === 'expired-prev-30-days-not-paid') {
            subtitle = getTxt('List of insurance contracts that expired in the previous 30 days not yet paid.');
            caption = getTxt('Expired not paid Contracts');
            fromDashboard = true;
            expired_prev_30_days_not_paid = true;
        }

        let columns = [];
        if (!this.props.noDeleteBtn && !fromDashboard) {
            if (authInfo.isAgencyAdministrator() || authInfo.isMasterBroker() || authInfo.isBroker()) {
                columns.push({ name: 'insurance_request_id', title: getTxt('Generazione Fattura') });
            }
        }

        columns = columns.concat([
            { name: 'contract_number', title: getTxt('Numero Polizza') },
            { name: 'insurance_type_cd', title: getTxt('Tipo') },
        ]);

        if (!fromDashboard) {
            columns = columns.concat([
                { name: 'contract_delivery_state', title: getTxt('Targa') },
            ]);
        }

        if (this.props.isCustomerView) {
            columns = columns.concat([
                { name: 'state_cd', title: getTxt('Stato') },
            ]);
        }

        columns = columns.concat([
            { name: 'customer_id', title: getTxt('Cliente') },
        ]);

        if (fromDashboard && (will_expiry_next_30_days || expired_prev_30_days_not_paid)) {
            columns = [{ name: 'Sollecito', title: getTxt('Sollecito') }].concat(columns);
        }

        columns = columns.concat([
            { name: 'insurance_company_id', title: getTxt('Compagnia') },
            { name: 'owner_broker_id', title: getTxt('Collaboratore') },

        ]);


        if (!isfromDashboard(this.props.match)) {
            columns = columns.concat([
                { name: 'contract_start_date', title: getTxt('Inizio') },
            ]);
        }

        columns = columns.concat([
            { name: 'contract_end_date', title: !isfromDashboard(this.props.match) ? getTxt('Scadenza') : 'Data Scadenza' },
        ]);
        if (fromDashboard) {
            columns = columns.concat([
                { name: 'contract_delivery_state', title: getTxt('Targa') },
            ]);
        }

        columns.push({ name: 'tacito_rinnovo', title: getTxt('Tacito Rinnovo') });
        columns.push({ name: 'contratto_soggetto_regolazione', title: getTxt('Contratto soggetto a regolazione') });


        if (!this.props.noDeleteBtn && !fromDashboard) {
            if (authInfo.isAgencyAdministrator() || authInfo.isMasterBroker()) {
                columns.push({ name: 'insurance_contract_id', title: getTxt('Actions') });
            }
        }


        const insType = this.props.insType ? this.props.insType : this.props.match.params.insType;
        const { classes } = this.props;
        let items = this.props.data.contracts ? this.props.data.contracts : [];

        let insTypeCd;
        if (items.length > 0) {
            insTypeCd = items[0].insurance_type_cd;
        }



        if (this.props.markIfLinketTo) {

            columns = [{ name: 'linked', title: getTxt('Linked') }].concat(columns);

            items.forEach((i) => {
                if (this.state[i.insurance_contract_id] === undefined) {
                    this.state[i.insurance_contract_id] = i.contract_link ? true : false;
                }
            }, this);
        }





        createGridResizerCols(this.state, columns, {}, 250);

        if (items.length > 0 && !this.state.solicited) {
            this.state.solicited = {};
            items.forEach((contract) => {
                this.state.solicited[contract.insurance_contract_id] = {
                    email: contract.customer_solicited_email === 'true',
                    phone: contract.customer_solicited_phone === 'true',
                };
            });
        }

        if (items.length > 0) {
            items.forEach((contract) => {
                if (!this.state.solicited) {
                    this.state.solicited[contract.insurance_contract_id] = {
                        email: contract.customer_solicited_email === 'true',
                        phone: contract.customer_solicited_phone === 'true',
                    };
                }

                if (!contract.state_cd) {
                    contract.state_cd = getTxt('Polizza');
                }
            });
        }

        if (fromDashboard) {
            if (items) {
                items.forEach((i) => {
                    i.Sollecito = i.insurance_contract_id;
                });
            }
        }
        const _this = this;
        return (

            <div className={classes.root}>
                <LinearProgressEx loading={this.props.data.loading} />
                {!this.props.noCaption && <RegularCard

                    headerColor="red"
                    cardTitle={caption}
                    cardSubtitle={subtitle}
                    content={
                        <div>

                        </div>

                    }
                />}
                <Grid

                    rows={items}
                    columns={columns}

                >
                    <FilteringState
                        defaultFilters={[{}]}
                    />

                    <SortingState
                        columnExtensions={[
                            { columnName: 'insurance_contract_id', sortingEnabled: false },
                        ]}
                        defaultSorting={[
                            { columnName: 'Cliente', direction: 'asc' },
                        ]}
                    />

                    <IntegratedFiltering columnExtensions={
                        [{ columnName: 'owner_broker_id', predicate: filterBroker },
                        { columnName: 'customer_id', predicate: filterCustomer },
                        { columnName: 'insurance_company_id', predicate: filterCompany },
                        { columnName: 'Sollecito', predicate: filterSolicito },
                        { columnName: 'state_cd', predicate: filterState },
                        ]}
                    />
                    <IntegratedSorting />

                    <VirtualTable
                        messages={tableMessages}
                        cellComponent={({ row, column, style, ...props }) => {

                            if (column.name === 'linked') {
                                return (
                                    <VirtualTable.Cell>
                                        <Checkbox
                                            onChange={(e, value) => {
                                                const newState = {};
                                                newState[row.insurance_contract_id] = value;
                                                this.setState(newState);
                                            }}
                                            checked={this.state[row.insurance_contract_id]}
                                            key={row.item_type_cd}
                                        />
                                    </VirtualTable.Cell>);
                            }

                            if (column.name === 'contract_number') {
                                return (<VirtualTable.Cell><Link to={`/contract/view/${row.insurance_type_cd}/${row.insurance_contract_id}`} style={{ padding: '6px', color: '#9C27B0', fontWeight: 'bold' }} > {row.contract_number}</Link> </VirtualTable.Cell>);
                            }
                            if (column.name === 'customer_id') {
                                return (<VirtualTable.Cell style={{ lineHeight: 0.3 }}>
                                    <Link
                                        to={`/customers/${row.customer.customer_type_cd.toLowerCase()}/${row.customer.customer_id}`} >
                                        {row.customer.display_name}
                                    </Link>
                                </VirtualTable.Cell>);
                            }
                            if (column.name === 'Sollecito' && fromDashboard) {
                                const phone = getClientPhone(row.customer);
                                const byEmail = (this.state.solicited && this.state.solicited[row.insurance_contract_id]) ?
                                    this.state.solicited[row.insurance_contract_id].email : false;
                                const byPhone = (this.state.solicited && this.state.solicited[row.insurance_contract_id]) ?
                                    this.state.solicited[row.insurance_contract_id].phone : false;
                                const contractId = row.insurance_contract_id;
                                return (<VirtualTable.Cell>
                                    <FormControlLabel
                                        style={{ display: 'block' }}
                                        control={
                                            <Checkbox
                                                style={{ paddingTop: 2, paddingBottom: 2 }}
                                                onChange={(e, value) => {
                                                    console.log(contractId);
                                                    console.log(value);
                                                    this.onSolicitedEmailChanged(row.insurance_contract_id, value);
                                                }}
                                                checked={byEmail}
                                            />
                                        }
                                        label={row.customer.email}
                                    />
                                    {phone &&
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    style={{ paddingTop: 2, paddingBottom: 2 }}
                                                    onChange={(e, value) => {
                                                        this.onSolicitedPhoneChanged(row.insurance_contract_id, value);
                                                    }}
                                                    checked={byPhone}
                                                />
                                            }
                                            label={phone}
                                        />
                                    }
                                </VirtualTable.Cell>);
                            }
                            if (column.name === 'insurance_company_id') {
                                if (!authInfo.isCustomer() && !authInfo.isBroker()) {
                                    return (<VirtualTable.Cell>
                                        <Link to={`/company/view/${row.company.insurance_company_id}`}>{row.company.company_name}</Link>
                                    </VirtualTable.Cell>);
                                } else {
                                    return (<VirtualTable.Cell>
                                        {row.company.company_name}
                                    </VirtualTable.Cell>);
                                }
                            }
                            if (column.name === 'owner_broker_id') {
                                return (<VirtualTable.Cell>
                                    <Link to={`/brokers/${row.broker.broker_id}`}>{row.broker.display_name}</Link>
                                </VirtualTable.Cell>);
                            }
                            if (column.name === 'insurance_type_cd') {
                                return (<VirtualTable.Cell> {getLabel(DictionaryTypes.InsuranceType, row.insurance_type_cd)}</VirtualTable.Cell>);
                            }
                            if (column.name === 'ins_request_veicoli') {
                                return (<VirtualTable.Cell> {row.ins_request_veicoli.vehicle_targa_matricola}</VirtualTable.Cell>);
                            }
                            if (column.name === 'contract_start_date') {
                                return (<VirtualTable.Cell> {row.contract_start_date && toItalianDateString(row.contract_start_date)}</VirtualTable.Cell>);
                            }
                            if (column.name === 'contract_end_date') {
                                return (<VirtualTable.Cell> {row.contract_end_date && toItalianDateString(row.contract_end_date)}</VirtualTable.Cell>);
                            }
                            if (this.props.isCustomerView && column.name === 'state_cd') {

                                let state = row.state_cd;
                                if (state && state.toUpperCase) {
                                    state = state.toUpperCase();
                                }
                                if (state && (state === 'SOSTITUZIONE' || state === 'SOSTITUITO' || state === 'SOSTITUITA')) {
                                    state = 'Sostituzione';
                                } else {
                                    state = row.state_cd;
                                }

                                return (<VirtualTable.Cell> {state || getTxt('Polizza')} </VirtualTable.Cell>);
                            }
                            if (column.name === 'tacito_rinnovo') {
                                return (<VirtualTable.Cell> {row.tacito_rinnovo === 'true' ? getTxt('yes') : getTxt('no')}</VirtualTable.Cell>);
                            }
                            if (column.name === 'contratto_soggetto_regolazione') {
                                return (<VirtualTable.Cell> {row.contratto_soggetto_regolazione === 'true' ? getTxt('yes') : getTxt('no')}</VirtualTable.Cell>);
                            }
                            if (column.name === 'insurance_request_id') {
                                return (<VirtualTable.Cell>


                                    <Tooltip title={getTxt('Generazione Fattura')} style={{ marginLeft: 15, marginBottom: 0, marginTop: 0 }}>
                                        <IconButton
                                            size="small"
                                            onClick={async () => {
                                                this.setState({ contractIdToInvoice: row.insurance_contract_id, selectItemsDlg: true });

                                                // const newWindow = window.open("", "_blank");
                                                // newWindow.document.write(getTxt('Generating PDF Report, please wait...'));

                                                // const resp = await window.apolloClient.mutate({
                                                //     mutation: oneTimeTokenMutation,
                                                //     options: {
                                                //         fetchPolicy: 'network-only',
                                                //     },
                                                // });

                                                // if (resp.data && resp.data.oneTimeTokenMutation && resp.data.oneTimeTokenMutation.token) {
                                                //     newWindow.location.href = `${window.apiUrl}files/reports?ott=${resp.data.oneTimeTokenMutation.token}&type=${ReportTypes.Invoice}&params=${JSON.stringify({ contractId: row.insurance_contract_id })}`;
                                                //     newWindow.focus();
                                                // }

                                            }}
                                            className={classes.inGridButton}
                                        >
                                            <Icon.PictureAsPdf fontSize="small" />
                                        </IconButton>
                                    </Tooltip>

                                </VirtualTable.Cell>);
                            }

                            if (column.name === 'insurance_contract_id') {
                                return (<VirtualTable.Cell>

                                    <Tooltip title={getTxt('Delete')}>
                                        <IconButton size="small" onClick={() => { this.setState({ delId: row.insurance_contract_id, delNumber: row.contract_number }); }} className={classes.inGridButton} >
                                            <Icon.Delete fontSize="small" />
                                        </IconButton>
                                    </Tooltip>


                                    {/* <Tooltip title={getTxt('Generazione Fattura')} style={{ marginLeft: 15, marginBottom: 0, marginTop: 0 }}>
                                        <IconButton
                                            size="small"
                                            onClick={async () => {
                                                const newWindow = window.open("", "_blank");
                                                newWindow.document.write(getTxt('Generating PDF Report, please wait...'));

                                                const resp = await window.apolloClient.mutate({
                                                    mutation: oneTimeTokenMutation,
                                                    options: {
                                                        fetchPolicy: 'network-only',
                                                    },
                                                });

                                                if (resp.data && resp.data.oneTimeTokenMutation && resp.data.oneTimeTokenMutation.token) {
                                                    newWindow.location.href = `${window.apiUrl}files/reports?ott=${resp.data.oneTimeTokenMutation.token}&type=${ReportTypes.Invoice}&params=${JSON.stringify({ contractId: row.insurance_contract_id })}`;
                                                    newWindow.focus();
                                                }

                                            }}
                                            className={classes.inGridButton}
                                        >
                                            <Icon.PictureAsPdf fontSize="small" />
                                        </IconButton>
                                    </Tooltip> */}

                                </VirtualTable.Cell>);
                            }
                            if (column.name === 'contract_delivery_state') {
                                if (row.ins_request_veicoli) {
                                    return (<VirtualTable.Cell> {row.ins_request_veicoli.vehicle_targa_matricola}</VirtualTable.Cell>);
                                }
                                return (<VirtualTable.Cell>{''}</VirtualTable.Cell>);

                            }

                            return <VirtualTable.Cell {...props} />;
                        }}
                    />

                    <TableFilterRow
                        messages={filterRowMessages}
                        cellComponent={({ column, filter, onFilter, ...props }) => {
                            if (column.name === 'insurance_type_cd') {
                                return (
                                    <c.gridColFilter noFilterVal={getTxt('Tutti', '#094c57z')} setFilter={onFilter} >
                                        {getDictionary(DictionaryTypes.InsuranceType)}
                                    </c.gridColFilter>);
                            }
                            if (column.name === 'insurance_contract_id') {
                                return (
                                    <TableFilterRow.Cell>
                                        <TextField margin="normal" style={{ margin: 0 }} disabled placeholder={getTxt('Filter...')} value={''} />
                                    </TableFilterRow.Cell>);
                            }
                            if (column.name === 'insurance_request_id') {
                                return (
                                    <TableFilterRow.Cell>
                                        <TextField margin="normal" style={{ margin: 0 }} disabled placeholder={getTxt('Filter...')} value={''} />
                                    </TableFilterRow.Cell>);
                            }
                            if (column.name === 'tacito_rinnovo') {
                                return (
                                    <c.gridColFilter noFilterVal={getTxt('Tutti')} f={'tacito_rinnovo'} setFilter={onFilter}   >
                                        {
                                            [
                                                { label: getTxt('yes'), value: 'true' },
                                                { label: getTxt('no'), value: 'false' },
                                            ]
                                        }
                                    </c.gridColFilter>);
                            }
                            if (column.name === 'contratto_soggetto_regolazione') {
                                return (
                                    <c.gridColFilter noFilterVal={getTxt('Tutti')} f={'contratto_soggetto_regolazione'} setFilter={onFilter}   >
                                        {
                                            [
                                                { label: getTxt('yes'), value: 'true' },
                                                { label: getTxt('no'), value: 'false' },
                                            ]
                                        }
                                    </c.gridColFilter>);
                            }

                            if (column.name === 'state_cd') {
                                const types = getDictionary(DictionaryTypes.InsuranceItemType).map(i => ({ label: i.label, value: i.label }));
                                types.push({ label: 'Polizza', value: 'Polizza' });
                                return (
                                    <c.gridColFilter noFilterVal={getTxt('Tutti')} setFilter={onFilter} >
                                        {types}
                                    </c.gridColFilter>);
                            }


                            return <TableFilterRow.Cell onFilter={onFilter} filter={filter} {...props} />;
                        }}
                    />
                    <TableColumnResizing
                        columnWidths={this.state.columnWidths}
                        onColumnWidthsChange={(cols) => { this.setState({ columnWidths: cols }); }}
                        defaultColumnWidths={this.state.defColumnWidths}
                    />
                    <TableHeaderRow showSortingControls />

                </Grid>
                {this.props.markIfLinketTo &&
                    <GridEx item sm={this.props.sm || 6} xs={this.props.xs || 12} style={{ float: 'right' }}>
                        <div className={classes.formButtons} >
                            <Button
                                color="primary"
                                className={classes.button}
                                onClick={() => this.submitLinked(items)}
                                variant="contained"
                            > {getTxt('Salva')}</Button>
                            <Button
                                className={classes.button}
                                variant="contained"
                                onClick={() => {
                                    if (this.props.afterSubmitLinked) {
                                        this.props.afterSubmitLinked();
                                    }
                                }}
                            >{getTxt('Annulla')}</Button>

                        </div>

                    </GridEx>
                }

                <Dialog onClose={() => { this.setState({ delId: undefined, delNumber: undefined }); }} open={this.state.delId}>
                    <DialogTitle >{getTxt('Delete Contract') + ' ' + this.state.delNumber}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <span>{getTxt('Contract will be delete on server.')}</span>
                            <br />
                            <span >{getTxt('Are you sure do you want delete the Contract?')}</span>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleDelete(this.state.delId)} color="primary">
                            {getTxt('Ok')}
                        </Button>
                        <Button onClick={() => { this.setState({ delId: undefined, delNumber: undefined }); }} color="primary">
                            {getTxt('Annula')}
                        </Button>

                    </DialogActions>
                </Dialog>


                <Dialog onClose={() => { this.setState({ selectItemsDlg: false, contractIdToInvoice: undefined, selectedIdsToInvoice: [] }); }} open={this.state.selectItemsDlg}>
                    <DialogTitle >{getTxt('Seleziona il movimento per la generazione fattura')}</DialogTitle>
                    <DialogContent>
                        {this.state.contractIdToInvoice &&
                            <DialogContentText>
                                <Items_Select_Dialog
                                    contractId={this.state.contractIdToInvoice}
                                    noAddBtn
                                    insType={insType}
                                    onSelectedChanged={(selectedIds, forWhat) => {
                                        this.setState({ selectedIdsToInvoice: selectedIds, generateInvoiceFor: forWhat });
                                    }}
                                />
                            </DialogContentText>
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {

                                const itemsIds = this.state.selectedIdsToInvoice;
                                const contractId = this.state.contractIdToInvoice;
                                itemsIds.forEach((id) => {
                                    const newWindow = window.open("", "_blank");
                                    newWindow.document.write(getTxt('Generating PDF Report, please wait...'));

                                    const request = async () => {
                                        const resp = await window.apolloClient.mutate({
                                            mutation: oneTimeTokenMutation,
                                            options: {
                                                fetchPolicy: 'network-only',
                                            },
                                        });

                                        if (resp.data && resp.data.oneTimeTokenMutation && resp.data.oneTimeTokenMutation.token) {
                                            newWindow.location.href = `${window.apiUrl}files/reports?ott=${resp.data.oneTimeTokenMutation.token}&type=${ReportTypes.Invoice}&params=${JSON.stringify({ contractId, itemId: id, generateInvoiceFor: _this.state.generateInvoiceFor })}`;
                                            newWindow.focus();
                                        }
                                    };
                                    request();
                                });

                                this.setState({ selectItemsDlg: false, contractIdToInvoice: undefined, selectedIdsToInvoice: [] });
                            }}
                            color="primary"
                        >
                            {getTxt('Generazione Fattura')}
                        </Button>
                        <Button onClick={() => { this.setState({ selectItemsDlg: false, contractIdToInvoice: undefined, selectedIdsToInvoice: [] }); }} color="primary">
                            {getTxt('Annula')}
                        </Button>

                    </DialogActions>
                </Dialog>


            </div>
        );
    }
}

export default compose(

    graphql(queries.soliciteCustomerMutation, {
        name: 'soliciteCustomer',
        options: {
            errorPolicy: 'all',
        },
    }),

    graphql(queries.deleteContractMutation, {
        name: 'deleteContract',
        options: {

            errorPolicy: 'all',
        },
    }),

    graphql(queries.updateContractLinkMutation, {
        name: 'updateLinkedContracts',
        options: {

            errorPolicy: 'all',
        },
    }),

    graphql(queries.allContractsQuery, {
        options: props => (
            {
                variables: {
                    customerId: props.customerId,
                    brokerId: props.brokerId,
                    linkedTo: props.linkedTo,
                    markIfLinketTo: props.markIfLinketTo,
                    filter30: props.match ? props.match.params.filter : null,
                },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            }
        ),
    }),
)(withRouter(withStyles(styles)(Contract_Search)));
