import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { getTxt } from 'global/appGlobal';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import {List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Checkbox } from '@material-ui/core';
import { getDictionary, DictionaryTypes } from 'global/dictionaryCache';
import LinearProgressEx from '../components/LinearProgressEx';

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        background: theme.palette.background.paper,
    },
    dialogActions: {
        paddingBottom: theme.spacing.unit * 2,
        paddingRight: theme.spacing.unit * 2,
    },
});

class ChangeStateDialog extends Component {

    constructor(props) {
        super(props);
        this.steps = getDictionary(props.type).sort((a, b) => {
            if (a.order && b.order) {
                if (a.order < b.order) return -1;
                if (a.order > b.order) return 1;
                return 0;
            }
            return 0;
        });
        this.state.selectedState = this.steps.find(step => step.value === props.state);
        this.state.loading = false;
    }

    state = {
        selectedState: undefined,
        open: false,
        loading: false,
    };

    render() {
        const { classes } = this.props;

        return (
            <Dialog onClose={this.props.onClose} open={this.props.open} >
                <LinearProgressEx loading={this.props.loading} />

                {/* <DialogTitle></DialogTitle> */}
                <DialogContent>
                    <div className={classes.root}>
                        <List>
                            {this.steps.map(step => (
                                <ListItem
                                    onClick={() => this.setState({ selectedState: step })}
                                    button
                                    key={step.value}
                                    className={classes.listItem}
                                >
                                    <Checkbox
                                        checked={this.state.selectedState === step}
                                        tabIndex={-1}
                                        disableRipple
                                    />
                                    <ListItemText primary={step.label} />
                                </ListItem>
                            ))}

                        </List>

                    </div>
                </DialogContent>
                <DialogActions className={classes.dialogActions} >
                    <Button
                        onClick={() => {
                            if (this.props.state !== this.state.selectedState.value && this.props.saveState) {
                                this.props.saveState(this.state.selectedState.value);
                            } else {
                                this.props.onClose();
                            }
                        }}
                        color="primary"
                        variant="contained"
                    >
                        {getTxt('Salva')}
                    </Button>
                    <Button onClick={() => { this.props.onClose(); }} variant="contained">
                        {getTxt('Annulla')}
                    </Button>
                </DialogActions>

            </Dialog>
        );
    }
}

export default withStyles(styles)(ChangeStateDialog);