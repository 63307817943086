import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import * as Icon from '@material-ui/icons';
import { Button } from '@material-ui/core';
import File_Search from 'modules/file/File_Search';
import $ from 'jquery';
import { authInfo } from 'global/auth';
import TabContainer from 'components/TabContainer';
import { graphql, compose } from 'react-apollo';
import ComponentEx from 'components/ComponentEx';
import NavPills from 'components/NavPills/NavPills';
import ItemGrid from 'components/Grid/ItemGrid';
import HeaderViewItem from 'components/HeaderViewItem';
import { getTxt } from '../../global/appGlobal';
import LinearProgressEx from '../../components/LinearProgressEx';

import { getCompanyQuery } from '../../modules/company/CompanyQueries';
import Company_Details_View from '../../modules/company/Company_Details_View';
import CmpanyGuarantySearch from '../../modules/company/CmpanyGuarantySearch';
import RegularCard from '../../components/dashboard/RegularCard';
import CmdBarCustomBtns from '../../components/CmdBarCustomBtns';
import BrokerPercentSearch from '../../modules/company/BrokerPercentSearch';
import Company_Payments_Search from '../../modules/company/Company_Payments_Search';
import Company_Balance_Search from './Company_Balance_Search';
import Company_Free_Items_Search from './Company_Free_Items_Search';


const styles = theme => ({
   root: {
        backgroundColor: theme.palette.grey,
        margin: theme.spacing.unit * 3,
        marginTop: 0,
    },

    button: {
        margin: theme.spacing.unit,
    },

    badge: {
        margin: `0 ${theme.spacing.unit * 2}px`,
        marginTop: 0,
    },

    gridRoot: {
        height: '100%',
    },


    formCaption: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit * 2,
        width: '100%',
        position: 'relative',
        height: 40,
    },
    formCaptionLabel: {
        position: 'absolute',
        backgroundColor: 'transparent',
        left: 25,
        paddingLeft: 10,
        paddingRight: 10,
    },

    rootPaper: theme.mixins.gutters({
        paddingTop: 0,
        paddingBottom: 0,
        marginTop: 0,
    }),

    formHeaderRoot: {
        padding: theme.spacing.unit * 2,
        position: 'relative',
    },

    formHeaderFieldLabel: {
        color: theme.palette.text.disabled,
        fontWeight: 'bold',
    },

    tableHeader: {
        backgroundColor: theme.palette.background.paper,
    },
    headerIcon: {
        width: theme.spacing.unit * 8,
        height: theme.spacing.unit * 9,
        marginRight: theme.spacing.unit * 2,
    },
    cmdBar: {
        position: 'absolute',
        right: theme.spacing.unit * 3,
    },
});

const tabsStateGlobalId = 'company_view_privat';

class Company_View extends ComponentEx {
    state = {
        value: window[tabsStateGlobalId] ? window[tabsStateGlobalId] : 0,
    };

    componentWillUnmount() {
        window[tabsStateGlobalId] = this.state.value;
    }

    componentDidMount() {
        $("table[class*='Table-headTable']").addClass(this.props.classes.tableHeader);
    }

    handleChange = (e, value) => {
        this.setState({ value });
    };

    render() {

        const { classes } = this.props;
        const { value } = this.state;
        const loading = this.props.data.loading;
        const company = this.props.data.company;

        const lastTabIndex = (authInfo.isAgencyAdministrator() || authInfo.isMasterBroker()) ? 5 : 4;

        const tabs = [
            {
                tabButton: getTxt('Details'),
                tabIcon: Icon.Dashboard,
                tabContent: (
                    <TabContainer>
                        {(authInfo.isAgencyAdministrator() || authInfo.isMasterBroker()) &&
                            <Button color="primary" className={classes.button} onClick={() => { this.props.history.push(`/company/edit/${this.props.match.params.id}`) }} >{getTxt('Edit Details')}</Button>
                        }
                        {company && <Company_Details_View companyId={company.insurance_company_id} history={this.props.history} quiet={this.state.value !== 0} />}
                    </TabContainer>
                ),
            },
            {
                tabButton: getTxt('Documents'),
                tabIcon: Icon.AttachFile,
                tabContent: (
                    <TabContainer>
                        {company && <File_Search
                            entityId={company.insurance_company_id}
                            openDialog={this.state.openAttFileDlg}
                            noDelete={(!authInfo.isAgencyAdministrator() && !authInfo.isMasterBroker())}
                            noAddBtn={(!authInfo.isAgencyAdministrator() && !authInfo.isMasterBroker())}
                            quiet={this.state.value !== 1}
                        />}
                    </TabContainer>
                ),
            },
            {
                tabButton: getTxt('Company Guaranties'),
                tabIcon: Icon.List,
                tabContent: (
                    <TabContainer>
                        {company && <CmpanyGuarantySearch companyId={company.insurance_company_id} quiet={this.state.value !== 2} />}
                    </TabContainer>
                ),
            },
            {
                tabButton: getTxt('Provvigioni'),
                tabIcon: Icon.CropSquare,
                tabContent: (
                    <TabContainer>
                        {company && <BrokerPercentSearch noCaption companyId={company.insurance_company_id} quiet={this.state.value !== 3} />}
                    </TabContainer>
                ),
            },


        ];

        if ((authInfo.isAgencyAdministrator() || authInfo.isMasterBroker())) {
            tabs.push({
                tabButton: getTxt('Versamenti'),
                tabIcon: Icon.Payment,
                tabContent: (
                    <TabContainer>
                        {company && <Company_Payments_Search noCaption showAlterAddBtn companyId={company.insurance_company_id} quiet={this.state.value !== 4} />}
                    </TabContainer>
                ),
            });
        }

        tabs.push({
            tabButton: getTxt('Movimenti Liberi'),
            tabIcon: Icon.Payment,
            tabContent: (
                <TabContainer>
                    {company &&
                        <Company_Free_Items_Search
                            quiet={this.state.value !== 5}
                            noCaption
                            showAlterAddBtn={authInfo.isAgencyAdministrator() && !authInfo.isMasterBroker()}
                            companyId={company.insurance_company_id}
                        />}
                </TabContainer>
            ),
        },);


        tabs.push({
            tabButton: getTxt('Balance'),
            tabIcon: Icon.Payment,
            tabContent: (
                <TabContainer>
                    {company && <Company_Balance_Search noCaption showAlterAddBtn companyId={company.insurance_company_id} quiet={this.state.value !== 6} />}
                </TabContainer>
            ),
        });



        return (
            <div className={classes.root}>
                <LinearProgressEx loading={loading} />

                <RegularCard

                    headerColor="red"
                    cardTitle={company && company.company_name}
                    cardSubtitle={getTxt('Incurance Company')}
                    content={
                        <Grid container>
                            <ItemGrid xs={12} sm={12} md={2}>
                                <HeaderViewItem l={getTxt('Contacts')}>
                                    <Typography variant="body1" >
                                        {company && company.email}
                                    </Typography>
                                    <Typography variant="body1" > {company && company.mobile_phone} </Typography>

                                </HeaderViewItem>
                            </ItemGrid>

                            {authInfo.isAgencyAdministrator() && authInfo.isMasterBroker() &&
                                <ItemGrid xs={12} sm={12} md={10}>
                                    <CmdBarCustomBtns
                                        color="red"
                                        onClick={((btn) => {
                                            if (btn === 'Edit') {
                                                this.props.history.push(`/company/edit/${this.props.match.params.id}`);
                                            }

                                        })}
                                    >{['Edit']}</CmdBarCustomBtns>

                                </ItemGrid>
                            }
                        </Grid>
                    }
                />

                <NavPills
                    color="warning"
                    onChange={this.handleChange}
                    alignCenter
                    active={value}
                    tabs={tabs}
                />
            </div>
        );
    }
}

export default compose(
    graphql(getCompanyQuery, {
        options: props => ({
            variables: { id: props.match.params.id },
            fetchPolicy: 'network-only',
            options: {
                errorPolicy: 'all',
            },
        }),
    }),
)(withStyles(styles)(Company_View));
