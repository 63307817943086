import React from 'react';
import { HashRouter, BrowserRouter, Route, Link, withRouter } from 'react-router-dom';

export default function LinkToCustomerById(props) {
    const customer = { ...props.customer };
    let displayName;
    if (customer.customer_type_cd === 'PRIVATE') {
        displayName = `${customer.first_name} ${customer.last_name}`;
    } else {
        displayName = `${customer.company_name}`;
    }

    return (
        <Link to={`/customers/${customer.customer_id}`} ><span>{displayName}</span></Link>
    );
}

